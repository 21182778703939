import ReactDOM from 'react-dom'
import 'typeface-poppins'
import './i18n'
import './styles/app-base.css'
import './styles/app-components.css'
import './styles/app-utilities.css'
import App from 'app/App'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/compat/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import secureLocalStorage from 'react-secure-storage'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

const messaging = getMessaging()

export const requestToken = async (setTokenFound) => {
	return await getToken(messaging, {
		vapidKey: process.env.REACT_APP_FIREBASE_NOTIFICATION,
	})
		.then((currentToken) => {
			if (currentToken) {
				secureLocalStorage.setItem('deviceToken', currentToken)
				setTokenFound(true)
				return currentToken
				// Track the token -> client mapping, by sending to backend server
				// show on the UI that permission is secured
			} else {
				console.log('No registration token available. Request permission to generate one.')
				setTokenFound(false)
				// shows on the UI that permission is required
			}
		})
		.catch((err) => {
			// console.log('An error occurred while retrieving token. ', err)
			// catch error while creating client token
		})
}

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload)
		})
	})

ReactDOM.render(<App />, document.getElementById('root'))

// reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.registerServiceWorker()
serviceWorker.unregister()
