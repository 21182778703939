import { Typography, Grid, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import React from 'react'
import { capitalizeTemplateName } from 'utils'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		paddingTop: 16,
	},
}))

const ViewTemplateDetails = (props) => {
	const classes = useStyles()
	const { open, setOpen, viewMsg, setViewMsg } = props

	const onClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpen(false)
			setViewMsg({})
		}
	}

	const replacedBody = viewMsg?.body?.replace(/{{\d+}}/g, (match) => {
		const index = parseInt(match.match(/\d+/)[0]) - 1

		if (index >= 0 && index < viewMsg.msg_body_type.length) {
			const replacement = viewMsg.msg_body_type[index]

			if (replacement !== '') {
				return replacement
			}
		}

		return match
	})

	return (
		<CustomDialog open={open} headerText={`View message`} onClose={onClose} maxWidth='md' hideCloseBtn hideSaveBtn hideSaveAndAddBtn hideDivider>
			<Grid container xs={12} className={classes.mainContainer}>
				{/* {_.isArray(viewMsg?.message) ? (
					_.map(viewMsg?.message, (item, i) => (
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Grid
								container
								sx={{
									borderRadius: '6px',
									background: '#E5FFEF',
									boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
									paddingInline: '16px',
									paddingBlock: '10px',
									alignItems: 'center',
									'& .MuiTypography-h5': {
										color: '#0062DD',
										fontSize: 16,
										fontWeight: 500,
									},
									'& .MuiTypography-body1': {
										color: '#727272',
										fontSize: '14px',
									},
								}}
							>
								<Grid item xs={8.5} lg={8.5} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
									<Avatar src={'${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Communication/Icon/WhatsApp_template.svg'} variant='circular' />
									<Typography variant='h5'>{capitalizeTemplateName(item?.templateName)}</Typography>
								</Grid>
								<Grid item xs={3.5} lg={3.5}>
									<Typography variant='body1'>
										Sent Date{' '}
										<span style={{ fontSize: 14, color: '#000' }}>
											{`${item?.created_on ? customDateFormat(item?.created_on, dateFormatForViewer) : '-'}  & ${moment(`${item?.created_on}`).format(
												'LT'
											)}`}
										</span>
									</Typography>
								</Grid>
								<div
									style={{
										marginTop: 12,
										backgroundImage: `url(${'${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Consult/WhatsApp_Background.png?updatedAt=1688464164556&ik-s=a65c94ebb356194f7ff9d3fcf57ca6ee2f5ea43d'})`,
										backgroundSize: 'cover',
										backgroundPosition: 'intial',
										overflow: 'hidden',
										borderRadius: 4,
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<div style={{ padding: 14, background: '#2DBD96' }}>
										<Typography style={{ color: '#fff' }}>Message</Typography>
									</div>
									<div
										style={{
											margin: '12px 12px 18px 294px',
											padding: 14,
											borderRadius: '0px 12px 12px',
											background: '#fff',
											minHeight: 300,
											maxHeight: 300,
											overflow: 'hidden',
											overflowY: 'auto',
										}}
									>
										<Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
											{item?.body}
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>
					))
				) : */}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Grid
						container
						sx={{
							borderRadius: '6px',
							background: '#E5FFEF',
							boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
							paddingInline: '16px',
							paddingBlock: '10px',
							alignItems: 'center',
							'& .MuiTypography-h5': {
								color: '#0062DD',
								fontSize: 16,
								fontWeight: 500,
							},
							'& .MuiTypography-body1': {
								color: '#727272',
								fontSize: '14px',
							},
						}}
					>
						<Grid item xs={8.5} lg={8.5} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
							<Avatar src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Communication/Icon/WhatsApp_template.svg`} variant='circular' />
							<Typography variant='h5'>{capitalizeTemplateName(viewMsg?.templateName)}</Typography>
						</Grid>
						<Grid item xs={3.5} lg={3.5}>
							<Typography variant='body1'>
								Sent Date <span style={{ fontSize: 14, color: '#000' }}>{viewMsg?.deliveryTime}</span>
							</Typography>
						</Grid>
						<div
							style={{
								marginTop: 12,
								backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Consult/WhatsApp_Background.png?updatedAt=1688464164556&ik-s=a65c94ebb356194f7ff9d3fcf57ca6ee2f5ea43d`})`,
								backgroundSize: 'cover',
								backgroundPosition: 'intial',
								overflow: 'hidden',
								borderRadius: 4,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<div style={{ padding: 14, background: '#2DBD96' }}>
								<Typography style={{ color: '#fff' }}>Message</Typography>
							</div>
							<div
								style={{
									margin: '12px 12px 18px 294px',
									padding: 14,
									borderRadius: '0px 12px 12px',
									background: '#fff',
									minHeight: 300,
									maxHeight: 300,
									overflow: 'hidden',
									overflowY: 'auto',
								}}
							>
								<Typography
									variant='body1'
									style={{ whiteSpace: 'pre-line' }}
									dangerouslySetInnerHTML={{
										__html: viewMsg?.headerMedia?.headerText ? viewMsg.headerMedia.headerText + '\n\n' : '',
									}}
								/>

								<Typography variant='body1' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: replacedBody }} />
							</div>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}

export default ViewTemplateDetails
