import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setEmail: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.GENERATE_EMAIL}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	setUserEmail: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.GENERATE_USER_EMAIL}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	saveActiveDevices: (userType, identityUuid, publicKey, ivKey, token) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.UPDATE_DEVICES}`, result, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				path: { userType, identityUuid },
			},
		})
	},
}
