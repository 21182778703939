import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import Care from './tenantTypeSelection/care'
import Fitness from './tenantTypeSelection/fitness'
import Mind from './tenantTypeSelection/mind'
import Spa from './tenantTypeSelection/spa'
import Sports from './tenantTypeSelection/sports'
import WelcomeScreenForm from './welcomeScreenForm'
import Individual from './tenantTypeSelection/individualType'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import _ from 'lodash'

const Root = styled('div')(({ theme }) => ({
	color: theme.palette.primary.contrastText,

	'& .Register-rightSection': {},

	'& .Register-leftSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .CardContent': {
		paddingBlock: 10,
		paddingInline: 0,
	},
	'& .right-content': {
		fontWeight: 'bold',
	},
}))
const Main = styled('div')(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		display: 'flex',
		flexDirection: 'column',
	},
}))

function WelcomeCare({ history }) {
	const states = useSelector((state) => state?.Authentication || {})
	const { isAuthenticated, deviceId, groupId } = states

	useEffect(() => {
		if (isAuthenticated || deviceId === '' || deviceId === undefined) {
			history.push('/')
		}
	}, [history, isAuthenticated, deviceId])

	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				<Grid container justifyContent={'center'} spacing={2}>
					<Grid item>
						<Main>
							{/* eslint-disable-next-line prettier/prettier */}
							<div
								initial={{ opacity: 0, scale: 0.6 }}
								animate={{ opacity: 1, scale: 1 }}
								className='flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden'
							>
								<Card className='Register-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0' square>
									<CardContent className='CardContent flex flex-col items-center justify-center w-full py-96 max-w-320'>
										<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
											<WelcomeScreenForm />
										</div>
									</CardContent>
								</Card>
								{_.isEmpty(groupId) ? <Individual /> : null}
								{groupId === 'fyi6pmtm' ? <Care /> : null}
								{groupId === 'e7z11j8m' ? <Fitness /> : null}
								{groupId === '2nzdfwug' ? <Mind /> : null}
								{groupId === 'ztyxtevg' ? <Sports /> : null}
								{groupId === 'irokb9b8' ? <Spa /> : null}
							</div>
						</Main>
					</Grid>
				</Grid>
			</Root>
		</>
	)
}

export default WelcomeCare
