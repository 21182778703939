import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setOtp: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.VALIDATE_OTP}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	setUserOtp: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.VALIDATE_USER_OTP}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	updateTentIdentity: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.patch(`${API_ENDPOINTS.UPDATE_TENTENT_IDENTITY}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, key: `${ivKey}` },
		})
	},
	resendOtp: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.RESEND_OTP}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	resenduserOtp: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.RESEND_OTP_USER}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	getActiveList: (identityUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_PRACTISE}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { identityUuid } },
		})
	},
	changeLoginDetails: (identityUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_CHANGE_LOGIN_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { identityUuid } },
		})
	},
}
