import { Typography, Grid, TextField, Autocomplete, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import useStyles from './style'
import Button from '../../sharedComponents/Button/index'
import SlotsView from './SlotsViewEdit/index'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showLoader, hideLoader } from '../../../services/loader/action'
import profiletable from '../../../services/ProfileSetup/ProfileTable/api'
import establishmentApi from '../../../services/ProfileSetup/EstablishmentDetails/api'
import timingApi from '../../../services/ProfileSetup/TimingAndPayments/api'
import { useFormik, FormikProvider } from 'formik'
import ButtonComp from '../../sharedComponents/Button/index'
import * as Yup from 'yup'
import { showMessage } from 'app/store/fuse/messageSlice'
import { decryption, getResponseMessage } from 'utils'

function TimingandPaymentViewandEdit({ history, match, moveNextTab, moveBackTab }) {
	const classes = useStyles()
	const action = _.get(match, 'params.action', '')
	const uuid = _.get(match, 'params.uuid')
	const mastuuid = _.get(match, 'params.mastuuid')
	const isViewMode = _.isEqual(action, 'view')
	const isEditMode = _.isEqual(action, 'edit')
	const state = useSelector((state) => state.Authentication)
	const errorJson = useSelector((state) => state.errorMessages)
	const { groupName, groupId, identityUuid } = state
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || '')
	const dispatch = useDispatch()
	const [practiceList, setPracticeList] = useState([])
	const [practiceLoading, setPracticeLoading] = useState(false)
	const [specialityLoading, setSpecialityLoading] = useState(false)
	const [specialityList, setSpecialityList] = useState([])
	const [practiceId, setPracticeId] = useState('')
	const [details, setDetails] = useState([])
	const [timingData, setTimingData] = useState({})
	const [timingLoading, setTimingLoading] = useState(false)
	const [currentDay, setCurrentDay] = useState('')
	const [slotData, setSlotData] = useState([])
	const [profileUUid, setProfileUuid] = useState('')

	const onSubmit = (e) => {
		dispatch(showLoader('Loading please wait...'))
		const details = _.map(e?.details, (slot) => {
			const currentSpeciality = _.find(specialityList, (o) => _.isEqual(o?.specialityName, slot?.specialityName))
			return {
				consultantionFees: parseInt(slot?.consultantionFees),
				dayOn: [slot?.dayOn],
				allDay: 'false',
				sessionStartTime: slot?.sessionStartTime,
				sessionEndTime: slot?.sessionEndTime,
				venueSpace:
					_.isEqual(groupName, 'fitness') ||
					_.isEqual(groupId, 'e7z11j8m') ||
					_.isEqual(groupName, 'wellness') ||
					_.isEqual(groupId, '2nzdfwug') ||
					_.isEqual(groupName, 'sports') ||
					_.isEqual(groupId, 'ztyxtevg')
						? slot?.venueSpace
						: null,
				consultantionDuration: _.isEqual(groupName, 'sports') || _.isEqual(groupId, 'ztyxtevg') ? null : slot?.sessionEndTime,
				specialityId: currentSpeciality?.specialityUuid || e?.specialityName?.specialityUuid,
			}
		})
		const body = {
			uuid: profileUUid || e?.details[0].tentUserProfileTimingsPaymentsHeaderUuid,
			isPaymentMandatory: e?.paymentMandatory || 'false',
			details,
		}

		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
			moveNextTab()
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		timingApi.updateTimingData(body).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			practiceName: '',
			specialityName: '',
			paymentMandatory: 'true',
			details: [],
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			practiceName: Yup.object().nullable().required('Required'),
			specialityName: Yup.object().nullable().required('Required'),
			details: Yup.array()
				.min(1)
				.of(
					Yup.object().shape({
						sessionStartTime: Yup.string().ensure().required('Required'),
						sessionEndTime: Yup.string().required('Required'),
						consultantionFees: Yup.string().required('Required'),
						venueSpace:
							_.isEqual(groupName, 'fitness') ||
							_.isEqual(groupId, 'e7z11j8m') ||
							_.isEqual(groupName, 'wellness') ||
							_.isEqual(groupId, '2nzdfwug') ||
							_.isEqual(groupName, 'sports') ||
							_.isEqual(groupId, 'ztyxtevg')
								? Yup.string().required('Required')
								: null,
						consultantionDuration: _.isEqual(groupName, 'sports') || _.isEqual(groupId, 'ztyxtevg') ? null : Yup.string().required('Required'),
					})
				)
				.required('Required'),
		}),
		onSubmit: onSubmit,
	})

	useEffect(() => {
		const onSuccess = (res) => {
			setTimingLoading(false)
			setDetails(res?.data?.data[mastuuid]?.details || [])
			setProfileUuid(res?.data?.data?.uuid)
			formik?.setFieldValue('paymentMandatory', `${res?.data?.data?.isPaymentMandatory}`)
			formik?.setFieldValue('details', res?.data?.data[mastuuid]?.details)
		}
		const onFailure = (err) => {
			setTimingLoading(false)
			setDetails([])
		}
		setTimingLoading(true)
		!_.isEmpty(mastuuid) && timingApi.getTimingData(mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	useEffect(() => {
		setPracticeLoading(true)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				setPracticeLoading(false)
				setPracticeList(successData?.data?.data)
			}
		}
		const onFailure = (err) => {
			setPracticeLoading(false)
			setPracticeList([])
		}
		profiletable.getPracticeList(identityUuid).then(onSuccess, onFailure)
	}, [dispatch, identityUuid])

	useEffect(() => {
		if (practiceLoading || practiceLoading) {
			dispatch(showLoader('Loading please wait...'))
		} else {
			dispatch(hideLoader())
		}
	}, [timingLoading, practiceLoading, dispatch])

	useEffect(() => {
		const onSuccess = (res) => {
			setSpecialityLoading(false)
			if (res?.data.status === 'success') {
				setSpecialityLoading(false)
				setSpecialityList(res.data.data)
			} else {
				setSpecialityLoading(false)
				setSpecialityList([])
			}
		}
		const onFailure = (err) => {
			setSpecialityLoading(false)
		}
		if (!_.isEmpty(practiceId)) {
			setSpecialityLoading(true)
			establishmentApi.getSpeciality(practiceId).then(onSuccess, onFailure)
		}
	}, [practiceId])

	const onSpecialityChange = (value) => {
		formik?.setFieldValue('specialityName', value)
		const filteredData = _.filter(details, (o) => _.isEqual(o?.specialityName, value?.specialityName))
		setTimingData(filteredData)
	}

	const onDayChange = (day) => {
		setCurrentDay(day)
	}

	const onClickEdit = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastuuid}?tabId=timingAndPayments`)
	}

	useEffect(() => {
		if (!_.isEmpty(currentDay)) {
			const currentSlot = _.filter(timingData, (o) => _.isEqual(o?.dayOn, _.toLower(currentDay)))
			setSlotData(currentSlot)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentDay, timingData])

	return (
		<FormikProvider value={formik}>
			{/* <ProfileStatus profilePercentage={profilePercentage} /> */}
			<Grid container className={classes.timingpaymentsMain}>
				{/* Start: academy Grid Section */}
				<Grid container className={classes.timingpaymentsMainOne} justifyContent={'space-between'}>
					<Grid item lg={3} sm={12}>
						<Typography pb={1} className={classes.star}>
							Select your parctice
						</Typography>
						<Autocomplete
							disablePortal
							id='combo-box-demo'
							value={formik?.values?.practiceName}
							onChange={(e, value) => {
								formik?.setFieldValue('practiceName', value)
								setPracticeId(value?.mast_tent_type_uuid)
							}}
							getOptionLabel={(option) => option?.practice_name || ''}
							getOptionSelected={(option, value) => option?.practice_name === value?.practice_name}
							options={practiceList}
							size='small'
							fullWidth
							renderInput={(params) => (
								<TextField
									error={formik.touched.practiceName && formik.errors.practiceName}
									helperText={formik.touched.practiceName && formik.errors.practiceName && formik.errors.practiceName}
									color='secondary'
									{...params}
									placeholder='Hospital Name'
								/>
							)}
						/>
					</Grid>
					{isViewMode && progressStatus !== 'SUB' ? (
						<Grid item lg={1} sm={12} className={classes.editButton}>
							<Button onClick={onClickEdit}>Edit Profile</Button>
						</Grid>
					) : null}
				</Grid>
				{/* End: academy Grid Section */}
				{/* Start: Sports Grid Section */}
				<Grid container pt={1} className={classes.timingpaymentsMainOne}>
					<Grid item lg={3} sm={12}>
						<Typography pb={1} className={classes.star}>
							Select your speciality
						</Typography>
						<Autocomplete
							loading={specialityLoading}
							id='combo-box-demo'
							value={formik?.values?.specialityName}
							options={specialityList}
							getOptionLabel={(option) => option?.specialityName || ''}
							getOptionSelected={(option, value) => option?.specialityName === value?.specialityName}
							onChange={(e, value) => {
								onSpecialityChange(value)
							}}
							size='small'
							fullWidth
							renderInput={(params) => (
								<TextField
									error={formik.touched.specialityName && formik.errors.specialityName}
									helperText={formik.touched.specialityName && formik.errors.specialityName && formik.errors.specialityName}
									color='secondary'
									{...params}
									placeholder='Select Speciality'
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid container pt={1} className={classes.timingpaymentsMainOne}>
					<Grid item lg={3} sm={12}>
						<Typography pb={1} className={classes.star}>
							Payment mandatory for booking
						</Typography>
						<RadioGroup
							row
							aria-label='gender'
							name='row-radio-buttons-group'
							value={formik?.values?.paymentMandatory}
							onChange={(e) => {
								formik?.setFieldValue('paymentMandatory', e.target.value)
							}}
						>
							<FormControlLabel value='true' control={<Radio />} label='Yes' />
							<FormControlLabel value='false' control={<Radio />} label='No' />
						</RadioGroup>
					</Grid>
				</Grid>
				{/* End: Sports Grid Section */}
				{/* Start: Slots Grid Section */}
				<Grid container pt={1}>
					<Grid item lg={8} sm={12}>
						<Typography pb={1} className={classes.star}>
							Slots
						</Typography>
						<SlotsView
							onDayChange={onDayChange}
							slotData={slotData}
							isViewMode={isViewMode}
							formik={formik}
							currentDay={currentDay}
							isEditMode={isEditMode}
						/>
					</Grid>
					<Grid item lg={4} sm={12}></Grid>
				</Grid>
				{/* End: Slots Grid Section */}
				{isViewMode && progressStatus !== 'SUB' ? (
					<div className={classes.editButtonMob}>
						<Button onClick={onClickEdit}>Edit Profile</Button>
					</div>
				) : null}
			</Grid>
			{isEditMode && (
				<div class={classes.buttonMain}>
					<ButtonComp onClick={moveBackTab}>Back</ButtonComp>
					<ButtonComp onClick={formik?.handleSubmit}>Next</ButtonComp>
				</div>
			)}
		</FormikProvider>
	)
}
export default TimingandPaymentViewandEdit
