import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideMessage } from 'app/store/fuse/messageSlice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	customSuccess: {
		// backgroundColor: `#8cc637 !important`,
		color: '#ffffff !important',
		fontFamily: 'poppins',
	},
	customFailure: {
		backgroundColor: `#D2042D !important`,
		color: '#ffffff !important',
		fontFamily: 'poppins',
	},
}))

function FuseMessage(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector(({ fuse }) => fuse.message.state)
	const options = useSelector(({ fuse }) => fuse.message.options)

	useEffect(() => {
		if (state) {
			const toastOptions = {
				onClose: () => dispatch(hideMessage()),
				className: options.variant === 'success' ? classes.customSuccess : options.variant === 'error' ? classes.customFailure : '',
				// className: options.variant === 'error' ? classes.customFailure : '',
				// ...options,
			}

			switch (options.variant) {
				case 'success':
					toast.success(options.message, toastOptions)
					break
				case 'error':
					toast.error(options.message, toastOptions)
					break
				case 'info':
					toast.info(options.message, toastOptions)
					break
				case 'warning':
					toast.warn(options.message, toastOptions)
					break
				default:
					toast(options.message, toastOptions)
					break
			}

			dispatch(hideMessage())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, options, dispatch])

	return null
}

export default memo(FuseMessage)
