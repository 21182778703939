import { List, Typography, ListItemButton, ListItemText, Skeleton, useMediaQuery } from '@mui/material'
import { React } from 'react'
import ButtonComp from 'app/sharedComponents/Button'
import NewPlan from '../../Modal/CreateNewPlan'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import _ from 'lodash'
import useStyles from './style'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, PERMISSION_TYPE, FEATURES_LIST } from '../../../../../../constants'
import { useTheme } from '@mui/styles'

function ListMangement({ history, onListClick, listOne, open, setOpen, createPlan, planUuid, loading }) {
	const classes = useStyles()
	const communicationPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.COMMUNICATION_PLAN)
	const theme = useTheme()

	return (
		<>
			<Typography variant='h6' className={classes.commText}>
				Communication plans
			</Typography>
			{/* {loading ? (
				<Skeleton width='100%' height='75px' />
			) : (
				<>
					{_.get(communicationPerms, PERMISSION_TYPE.CREATE, false) && (
						<ButtonComp onClick={createNewPlan} customStyle={classes.createPlanBtn}>
							{!isTab && <AddCircleIcon />}
							Create new communication plan
						</ButtonComp>
					)}
				</>
			)} */}

			{loading && _.isEmpty(listOne) ? (
				<>
					{_.range(5)?.map((data) => (
						<Skeleton width='100%' height='50px' />
					))}
				</>
			) : !_.isEmpty(listOne) ? (
				<List>
					{listOne?.map((data) => (
						<ListItemButton
							onClick={() => {
								onListClick(data?.planUuid)
							}}
							key={data.planUuid}
							selected={planUuid === data.planUuid}
						>
							<ListItemText>
								<Typography variant='h6' className={classes.commPlanText}>
									{data?.title}
								</Typography>
								<Typography
									variant='h6'
									className={
										data?.planMappingPatientCount === 'never used'
											? classes.patientsStatusTextOne
											: data?.planMappingPatientCount === 'not in use'
											? classes.patientsStatusTextTwo
											: classes.patientsStatusText
									}
								>
									{data?.planMappingPatientCount}
								</Typography>
							</ListItemText>
						</ListItemButton>
					))}
				</List>
			) : (
				<img alt='noplsn' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/noplanone.png`} className={classes.noPlanImage}></img>
			)}
			{/* <NewPlan open={open} setOpen={setOpen} createPlan={createPlan} /> */}
		</>
	)
}

export default ListMangement
