import Drive from './Drive'

const DriveConfig = {
	routes: [
		{
			path: '/drive',
			component: Drive,
		},
	],
}

export default DriveConfig
