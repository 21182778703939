import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getPracticeDetails: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_PRACTICE_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getSpecializationList: (tentTypeId) => {
		return axios.get(API_ENDPOINTS.GET_SPECIALITY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentTypeId } },
		})
	},
	addPracticeDetails: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(API_ENDPOINTS.PRACTICE_DETAILS_POST, result, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}` },
		})
	},
	getPracticeDetailsList: (identityUuid, params) => {
		return axios.get(API_ENDPOINTS.PRACTICE_DETAILS_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { identityUuid } },
			params: { ...params },
		})
	},
	updatePracticeDetails: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.patch(`${API_ENDPOINTS.PRACTICE_DETAILS_PUT}`, result, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				key: `${ivKey}`,
			},
		})
	},
	practiceDetailsDelete: (editUuid) => {
		return axios.delete(`${API_ENDPOINTS.PRACTICE_DETAILS_DELETE}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { editUuid },
			},
		})
	},
	addPracticeSpeciality: (publicKey, ivKey, mastuuid, tentTypeUuid) => {
		const result = { data: publicKey }
		return axios.post(API_ENDPOINTS.POST_SPECIALITY, result, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}`, path: { mastuuid, tentTypeUuid } },
		})
	},
	getPracticeSpeciality: (params) => {
		return axios.get(API_ENDPOINTS.GET_PRACTICE_SPECIALITY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
