import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'
import secureLocalStorage from 'react-secure-storage'

const CancelToken = axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAddAppointment: (dataType, params) => {
		return axios.get(API_ENDPOINTS.APPOINTMENT_ADD, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
		})
	},

	getAppointmentList: (dataType, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.APPOINTEMENT_GET, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
		})
	},
	addAppointment: (data) => {
		const deviceToken = secureLocalStorage.getItem('deviceToken')
		return axios.post(
			`${API_ENDPOINTS.APPOINTMENT_POST}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
					deviceToken: deviceToken,
				},
			}
		)
	},
	addSerialAppointment: (data) => {
		const deviceToken = secureLocalStorage.getItem('deviceToken')
		return axios.post(
			`${API_ENDPOINTS.APPOINTMENT_SERIAL_POST}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
					deviceToken: deviceToken,
				},
			}
		)
	},
	appointmentUpdate: (data) => {
		return axios.put(
			`${API_ENDPOINTS.APPOINTMENT_UPDATE}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'X-SECURITY': csrf(),
					'Content-Type': 'application/json',
				},
			}
		)
	},

	appointmentCheckinEngageCheckout: (data) => {
		return axios.put(
			`${API_ENDPOINTS.APPOINTMENT_CHECKIN_ENGAGE_CHECKOUT}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'X-SECURITY': csrf(),
					'Content-Type': 'application/json',
				},
			}
		)
	},

	cancelAppointment: (data) => {
		return axios.put(
			`${API_ENDPOINTS.APPOINTMENT_CANCEL}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'X-SECURITY': csrf(),
					'Content-Type': 'application/json',
				},
			}
		)
	},
	cancelSerialAppointment: (data) => {
		return axios.put(
			`${API_ENDPOINTS.APPOINTMENT_SERIAL_CANCEL}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'X-SECURITY': csrf(),
					'Content-Type': 'application/json',
				},
			}
		)
	},
	deleteCancelAppointment: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_APPOINTMENT_CANCEL}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
	deleteSerialAppointment: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_SERIAL_APOINTMENMT}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
	getCustumerList: (tentMasterUuid) => {
		return axios.get(API_ENDPOINTS.CUST_LIST_APPOINTMENT, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentMasterUuid } },
		})
	},
	googleMeetingLinkGeneration: (data, mastUuid) => {
		return axios.post(API_ENDPOINTS.GOOGLE_MEET_LINK_APPOINTMENT, data, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastUuid } },
		})
	},
	zoomMeetingLinkGeneration: (data) => {
		return axios.post(API_ENDPOINTS.ZOOM_MEET_LINK_APPOINTMENT, data, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
		})
	},
}
