import { Typography } from '@mui/material'

function ProfilePage() {
	return (
		<div>
			<Typography variant='h5'>Profile</Typography>
		</div>
	)
}

export default ProfilePage
