import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setLogout: (deviceId, data, identityUuid) => {
		return axios.post(
			`${API_ENDPOINTS.LOGOUT_API}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
					path: { deviceId, identityUuid },
				},
			}
		)
	},
}
