import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		background: themesConfig.lyfngo.palette.tertiary.light,
		boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.10)`,
		// height: 108,
	},
	leftHead: {
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'center',
		padding: '16px 24px',
	},
	leftHeadTop: {
		display: 'flex',
		alignItems: 'center',
		gap: 16,
	},
	mainLogo: {
		'& .MuiAvatar-root': {
			height: 48,
			width: 48,
		},
	},
	tentName: {
		fontWeight: 600,
		color: '#212121',
	},
	specialization: {
		color: '#727272',
		fontSize: '13px',
		fontWeight: 500,
	},
	specializationCategories: {
		color: '#000',
		marginLeft: '8px',
	},
	specializationCategoriesMore: {
		display: 'inline-block',
		color: '#727272',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	specializationCategoriesMoreListitem: {
		padding: '6px 16px',
	},
	rightHead: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '24px 0',
		background: themesConfig.lyfngo.palette.tertiary.main,
	},
	leftHeadSpecialization: {},
	middleHead: {
		padding: '16px 0',
	},
	middleHeadRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	middleHeadNav: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		margin: '4px 8px',
		'&. MuiTypography-root': {
			color: 'red',
		},
	},
	middleHeadNavDesc: {
		fontWeight: 500,
	},
	middleHeadIcon: {
		background: themesConfig.lyfngo.palette.tertiary.main,
		color: themesConfig.lyfngo.palette.light.main,
		padding: '5px',
		'&:hover': {
			background: themesConfig.lyfngo.palette.tertiary.main,
			color: themesConfig.lyfngo.palette.light.main,
		},
		'&:disabled': {
			background: themesConfig.lyfngo.palette.tertiary.main,
			color: themesConfig.lyfngo.palette.light.main,
		},
	},
	middleHeadPreviewIcon: {
		background: themesConfig.lyfngo.palette.primary.main,
		color: themesConfig.lyfngo.palette.light.main,
		'&:hover': {
			background: themesConfig.lyfngo.palette.primary.main,
			color: themesConfig.lyfngo.palette.light.main,
		},
	},
	middleHeadNavLink: {
		color: themesConfig.lyfngo.palette.primary.main,
		fontWeight: 600,
		background: 'transparent !important',
		// borderBottom: `1px solid ${themesConfig.lyfngo.palette.primary.main} !important`,
		borderBottom: 'none !important',
		textDecorationColor: `2px solid ${themesConfig.lyfngo.palette.primary.main}`,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	ContentCopyIcon: {
		color: themesConfig.lyfngo.palette.tertiary.main,
		fontSize: '16px',
		cursor: 'pointer',
	},
	actionIcons: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiButtonBase-root': {
			background: themesConfig.lyfngo.palette.light.main,
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
}))
export default useStyles
