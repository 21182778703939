import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useStyles from '../style'
import { Grid, Typography, TextField, IconButton, Button, FormHelperText, InputAdornment } from '@mui/material'
import { FieldArray, ErrorMessage, FormikProvider } from 'formik'
import _ from 'lodash'
import CustomTextFieldWithCountryCode from 'app/sharedComponents/CountryCodeAdornment'

const SlotData = ({ isSmall, formik, data, countryCode, maxMobileLen, minMobileLen, countryCodeList, setMinMobileLen, setMaxMobileLen }) => {
	const classes = useStyles()
	return (
		<>
			<Grid container className={classes.emailandPhone} spacing={isSmall ? 0 : 5} lg={12} justifyContent='space-between'>
				<Grid item lg={6} md={6} sm={12}>
					<Typography className={classes.labels} pt={2}>
						Email Address
					</Typography>
					{/* <Grid item>
						<div className={classes.emailcontainer}>
							<div className={classes.addmail}>
								<TextField
									color='secondary'
									className={classes.textfield}
									value={data?.tentUserEmail}
									disabled
									size='small'
									fullWidth
									placeholder='Enter email address'
									InputProps={{ className: classes.input }}
								/>
							</div>
						</div>
					</Grid> */}
					<FormikProvider value={formik}>
						<FieldArray
							name='emailAddress'
							render={(arrayHelpers) => {
								return (
									<>
										{_.map(formik?.values?.emailAddress, (data, index) => {
											return (
												<Grid item>
													<div className={classes.emailcontainer}>
														<div className={classes.addmail}>
															<TextField
																id={`emailAddress[${index}].emailId`}
																name={`emailAddress[${index}].emailId`}
																color='secondary'
																className={classes.textfield}
																value={formik?.values?.emailAddress[index]?.emailId}
																onChange={formik.handleChange}
																size='small'
																fullWidth
																placeholder='Enter email address'
																InputProps={{ className: classes.input }}
															/>

															{index === 1 && !isSmall ? (
																<div className={classes.cancelExperience}>
																	<IconButton
																		className={classes.cancelExperience}
																		onClick={() => {
																			arrayHelpers.remove(index)
																		}}
																	>
																		<HighlightOffIcon />
																	</IconButton>
																</div>
															) : index === 1 && isSmall ? (
																<Button
																	className={classes.cancelBtn}
																	onClick={() => {
																		arrayHelpers.remove(index)
																	}}
																>
																	Remove
																</Button>
															) : null}
														</div>
														<ErrorMessage component={FormHelperText} error name={`emailAddress[${index}].emailId`} />
													</div>
												</Grid>
											)
										})}
										<div className={classes.addExperience}>
											<Button
												className={classes.addExperiencebtn}
												disabled={formik?.values?.emailAddress?.length > 1 ? true : false}
												onClick={(e) => {
													arrayHelpers.push()
												}}
											>
												+ Add Email
											</Button>
										</div>
									</>
								)
							}}
						/>
					</FormikProvider>
				</Grid>
				<Grid item lg={6} md={6} sm={12}>
					<Typography className={classes.labels} pt={2}>
						Mobile Number
					</Typography>
					{/* <Grid item>
						<div className={classes.emailcontainer}>
							<div className={classes.addmail}>
								<TextField
									color='secondary'
									className={classes.textfield}
									value={data?.tentUserPhone}
									size='small'
									disabled
									fullWidth
									placeholder='Enter mobile number'
									inputProps={{ maxLength: phoneNumberLength }}
									InputProps={{
										startAdornment: (
											<InputAdornment
												position='start'
												sx={{
													'& .MuiSvgIcon-root': {
														color: '#000',
													},
												}}
											>
												<Typography variant='body1'>{countryCode}</Typography>
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>
					</Grid> */}
					<FormikProvider value={formik}>
						<FieldArray
							name='phoneNumber'
							render={(arrayHelpers) => {
								return (
									<>
										{_.map(formik?.values?.phoneNumber, (data, index) => {
											return (
												<Grid item>
													<div className={classes.emailcontainer}>
														<div className={classes.addmail}>
															{/* <TextField
																id={`phoneNumber[${index}].phone`}
																name={`phoneNumber[${index}].phone`}
																color='secondary'
																className={classes.textfield}
																value={formik?.values?.phoneNumber[index]?.phone}
																onChange={formik.handleChange}
																size='small'
																fullWidth
																placeholder='Enter mobile number'
																inputProps={{ maxLength: maxMobileLen, minLength: minMobileLen }}
																InputProps={{
																	// className: classes.input,
																	startAdornment: (
																		<InputAdornment
																			position='start'
																			sx={{
																				'& .MuiSvgIcon-root': {
																					color: '#000',
																				},
																			}}
																		>
																			<Typography variant='body1'>{countryCode}</Typography>
																		</InputAdornment>
																	),
																}}
															/> */}
															<div className={classes.textfield}>
																<CustomTextFieldWithCountryCode
																	id={`phoneNumber[${index}].phone`}
																	name={`phoneNumber[${index}].phone`}
																	// id={index}
																	// name={index}
																	size='small'
																	type='tel'
																	value={formik?.values?.phoneNumber[index]?.phone}
																	onChange={formik.handleChange}
																	placeholder='Enter mobile number'
																	fullWidth
																	color='secondary'
																	variant='outlined'
																	countryCodeValue={_.isEqual(index, 0) ? formik?.values?.tentUserCountryCode : formik?.values?.tentSecCountryCode}
																	countryCodeOnChange={(e, newValue) => {
																		if (_.isEqual(index, 0)) {
																			formik?.setFieldValue(`tentUserCountryCode`, newValue)
																			setMinMobileLen((prevState) => [newValue?.minimumLength, prevState[1]])
																		} else {
																			formik?.setFieldValue(`tentSecCountryCode`, newValue)
																			setMinMobileLen((prevState) => [prevState[0], newValue?.minimumLength])
																		}
																	}}
																	countryCodeList={countryCodeList}
																/>
															</div>
															{index === 1 && !isSmall ? (
																<div className={classes.cancelExperience}>
																	<IconButton
																		className={classes.cancelExperience}
																		onClick={() => {
																			arrayHelpers.remove(index)
																			formik?.setFieldValue(`tentSecCountryCode`, null)
																		}}
																	>
																		<HighlightOffIcon />
																	</IconButton>
																</div>
															) : index === 1 && isSmall ? (
																<Button
																	className={classes.cancelBtn}
																	onClick={() => {
																		arrayHelpers.remove(index)
																		formik?.setFieldValue(`tentSecCountryCode`, null)
																	}}
																>
																	Remove
																</Button>
															) : null}
														</div>
														<ErrorMessage component={FormHelperText} error name={`phoneNumber[${index}].phone`} />
													</div>
												</Grid>
											)
										})}

										<div className={classes.addExperience}>
											<Button
												className={classes.addExperiencebtn}
												disabled={formik?.values?.phoneNumber?.length > 1 ? true : false}
												onClick={(e) => {
													setMinMobileLen((prevState) => [prevState[0], formik?.values?.tentUserCountryCode?.minimumLength])
													formik?.setFieldValue(`tentSecCountryCode`, formik?.values?.tentUserCountryCode)
													arrayHelpers.push()
												}}
											>
												+ Add phonenumber
											</Button>
										</div>
									</>
								)
							}}
						/>
					</FormikProvider>
				</Grid>
			</Grid>
		</>
	)
}

export default SlotData
