import { authRoles } from 'app/auth'
import ModuleSelection from './ModuleSelection'

const ModuleConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/moduleselection',
			component: ModuleSelection,
		},
	],
}

export default ModuleConfig
