import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, ButtonGroup, Grid, Skeleton, Tooltip, Typography } from '@mui/material'
import OutlineFilter from 'app/sharedComponents/SubSearch'
import useStyles from './style'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import history from '@history'
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import DietPlanApi from 'services/DietPlan/api'
import { DataGridPro } from '@mui/x-data-grid-pro'

const headerButtons = [
	{ id: 'today', title: 'Today' },
	{ id: 'tommrow', title: 'Tomorrow' },
	{ id: 'sevendays', title: '7 Days' },
]

function CustomToolbar() {
	return (
		<Box
			sx={{
				'& .MuiButton-root': {
					background: '#0062DD !important',
					color: '#fff !important',
					':hover': {
						background: '#0062DD',
						color: '#fff !important',
					},
				},
				padding: '6px',
			}}
		>
			<GridToolbarColumnsButton />
		</Box>
	)
}

const SkeletonColumns = [
	{
		field: 'clientname',
		headerName: 'Client name',
		width: 180,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
	{
		field: 'clientid',
		headerName: 'Client ID',
		width: 150,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
	{ field: 'mobilenumber', headerName: 'Mobile number', width: 240, renderCell: (params) => <Skeleton width='100%' height='40px' /> },

	{
		field: 'dietplanname',
		headerName: 'Diet plan name',
		width: 240,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
	{
		field: 'assigneddate',
		headerName: 'Assigned date',
		width: 180,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
	{
		field: 'expirydate',
		headerName: 'Expiry date',
		width: 180,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
	{
		field: 'status',
		headerName: 'Status',
		width: 150,
		renderCell: (params) => <Skeleton width='100%' height='40px' />,
	},
]

const SkeletonRows = _.map(_.range(5), (item, idx) => ({
	id: idx,
	clientname: '',
	clientid: '',
	mobilenumber: '',
	dietplanname: '',
	assigneddate: '',
	expirydate: '',
	status: '',
}))

const DietPlanDetails = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const mastTentUuid = useSelector((state) => state?.Authentication?.mastuuid || '')
	const [period, setPeriod] = useState('sevendays')
	const [loading, setLoading] = useState(true)
	console.log('loading', loading)
	const [pageSize, setPageSize] = useState(5)
	const [pageParams, setPageParams] = useState({ page: 0, customerName: null })
	const [totalCount, setTotalCount] = useState(0)
	const [clientSearchValue, setClientSearchValue] = useState('')
	const [clientSearchParams, setClientSearchParams] = useState({
		customerName: null,
	})
	const [dietPlanData, setDietPlanData] = useState([])
	console.log(';dietPlanData', dietPlanData)
	const cancelExecutor = useRef()

	const onSearch = _.debounce(
		(value) => {
			setClientSearchParams({
				...clientSearchParams,
				customerName: value,
			})
		},
		[300]
	)

	const handleChangePage = (event) => {
		setPageParams({ page: event })
	}

	const tableColumn = [
		{
			field: 'clientname',
			headerName: 'Client name',
			width: 180,
			// sortable: false,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.clientname}>
						<Typography align='right' className='ml-12' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{params.row.clientname}
						</Typography>
					</Tooltip>
				)
			},
		},
		{
			field: 'clientid',
			headerName: 'Client ID',
			width: 150,
			// sortable: false,
		},
		{
			field: 'mobilenumber',
			headerName: 'Mobile number',
			// sortable: false,
			width: 240,
		},
		{
			field: 'dietplanname',
			headerName: 'Diet plan name',
			width: 240,
			// sortable: false,
		},
		{
			field: 'assigneddate',
			headerName: 'Assigned date',
			width: 180,
			// sortable: false,
			renderCell: (params) => {
				return <Typography>{(params?.row?.assigneddate).split(' ')[0]}</Typography>
			},
		},
		{
			field: 'expirydate',
			headerName: 'Expiry date',
			width: 180,
			// sortable: false,
			renderCell: (params) => {
				return <Typography>{(params?.row?.expirydate).split(' ')[0]}</Typography>
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			// sortable: false,
			width: 150,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.status === 'Expired' ? (
							<Typography style={{ color: 'red' }}>{params?.row?.status}</Typography>
						) : (
							<Typography style={{ color: 'green' }}>{params?.row?.status}</Typography>
						)}
					</>
				)
			},
		},
	]

	const tableRow = _.map(dietPlanData, (item, index) => ({
		id: index,
		clientname: item?.custName,
		clientid: item?.custCustomId,
		mobilenumber: item?.mobileNo,
		dietplanname: item?.planName,
		assigneddate: item?.startDate,
		expirydate: item?.endDate,
		custUuid: item?.custUuid,
		status: item?.totalDays,
	}))

	console.log('tableRow', tableRow)

	const handleOnCellClick = (params) => {
		history.push(`/clientinfo/${params?.row?.custUuid}?tabId=dietplan`)
		dispatch(customer_Uuid(`${params?.row?.custUuid}`))
	}

	const getMembershipDetails = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setDietPlanData(res?.data?.data)
			setTotalCount(res?.data?.totalCount)
		}
		const onFailure = (err) => {
			console.log(err)
			setDietPlanData([])
			setLoading(false)
		}
		let selectedPeriod
		if (_.isEqual(period, 'sevendays')) {
			selectedPeriod = 7
		} else if (_.isEqual(period, 'tommrow')) {
			selectedPeriod = 1
		} else if (_.isEqual(period, 'today')) {
			selectedPeriod = 0
		}
		!_.isEmpty(mastTentUuid) &&
			DietPlanApi.getDietPlanListForDashboard(
				mastTentUuid,
				{ size: pageSize, page: pageParams?.page, searchKey: clientSearchValue, days: selectedPeriod },
				cancelExecutor
			).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastTentUuid, clientSearchParams, pageParams, pageSize, period])

	useEffect(() => {
		getMembershipDetails()
	}, [getMembershipDetails])

	return (
		<div className={classes.root}>
			<Grid xs={12} container className={classes.sectionHead}>
				<Grid xs={4} item>
					<Typography variant='h6'>Diet plan expiry</Typography>
				</Grid>
				<Grid xs={8} item className={classes.sectionRight}>
					<div className={classes.select_payment_type}>
						<OutlineFilter
							value={clientSearchValue}
							onChange={(e, value) => {
								setClientSearchValue(e.target.value)
								onSearch(e.target.value)
							}}
							placeholder={'Search client list'}
						/>
					</div>
					<ButtonGroup color='primary' aria-label='medium' className={classes.grouped_button}>
						{headerButtons.map((item) => (
							<Button
								key={item.id}
								className={period === item.id ? classes.button_active : classes.button}
								style={{ borderRadius: 18, transition: '0.7s all' }}
								onClick={() => setPeriod(item.id)}
							>
								{item.title}
							</Button>
						))}
					</ButtonGroup>
				</Grid>
			</Grid>
			<Box className={classes.container}>
				{_.isEqual(loading, true) && (
					<div style={{ height: '55vh' }}>
						<DataGrid
							columns={SkeletonColumns}
							rows={SkeletonRows}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							paginationModel={pageParams}
							rowsPerPageOptions={[5, 10, 25]}
							pagination={true}
							paginationMode='server'
							onPageChange={handleChangePage}
							disableColumnMenu
							disableSelectionOnClick
							rowCount={totalCount}
						/>
					</div>
				)}
				{!_.isEmpty(tableRow) ? (
					<div style={{ height: '55vh' }}>
						<DataGrid
							columns={tableColumn}
							rows={tableRow}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							paginationModel={pageParams}
							rowsPerPageOptions={[5, 10, 25]}
							pagination={true}
							paginationMode='server'
							onPageChange={handleChangePage}
							// disableColumnMenu
							components={{
								Toolbar: CustomToolbar,
							}}
							disableSelectionOnClick
							onCellClick={handleOnCellClick}
							rowCount={totalCount}
						/>
					</div>
				) : (
					<div className={classes.emptyRecordBox}>
						<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/No_dietplan_expiry.png`} alt='No dietplan found' />
					</div>
				)}
			</Box>
		</div>
	)
}

export default DietPlanDetails
