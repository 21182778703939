import Chart from 'react-apexcharts'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { currencyCode } from 'utils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBlock: 22,
		'& .apexcharts-legend': {
			padding: 0,

			'& .apexcharts-legend-series': {
				alignItems: 'baseline',
				margin: 0,
				display: 'flex',
				gap: 12,
			},

			'& .apexcharts-legend-marker': {},

			'& .legendContainer': {
				minWidth: 240,
				paddingBlockEnd: 8,
				'& .seriesRow': {
					display: 'flex',
					// justifyContent: 'space-between',
				},
			},
		},

		'& .apexcharts-graphical': {
			border: '1px solid green',
			'& .apexcharts-datalabel-value': {
				color: 'green',
			},
			'& .apexcharts-text apexcharts-datalabel-value': {
				color: 'green',
			},
		},

		'& .name': {
			fontSize: '1.32rem',
			color: 'rgb(107, 114, 128)',
			fontFamily: 'poppins',
			fontWeight: 400,
			minWidth: 174,
		},
		'& .value': {
			fontSize: '1.32rem',
			color: 'rgb(107, 114, 128)',
			fontFamily: 'poppins',
			fontWeight: 400,
		},
		'& .percentage': {
			fontSize: '1.32rem',
			color: '#000',
			fontFamily: 'poppins',
			fontWeight: 600,
		},
	},
	fallbackUI: {
		height: 300,
		width: '100%',
		background: '#fff',
	},
}))

function TotalSalesChart({ dashboardData, saleType, isCareFlow }) {
	const classes = useStyles()
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const allSales = dashboardData?.TotalSales
	const apptSales = dashboardData?.AppointmentSales
	const otherSales = dashboardData?.OtherSales

	const Labels = _.isEqual(saleType, 'all')
		? ['Appointments', 'Membership', 'Quick sale', ...(isCareFlow ? ['Pharma'] : [])]
		: _.isEqual(saleType, 'appointments')
		? ['In-person', 'Online Consultation', 'Home Visit']
		: _.isEqual(saleType, 'otherSales')
		? ['Membership', 'Quick sale', ...(isCareFlow ? ['Pharma'] : [])]
		: ['']

	const Series = _.isEqual(saleType, 'all')
		? [
				allSales?.appointmentValue || 0,
				allSales?.membershipValue || 0,
				allSales?.quickSalesValue || 0,
				...(isCareFlow ? [allSales?.pharmaValue || 0] : []),
		  ]
		: _.isEqual(saleType, 'appointments')
		? [apptSales?.inpValue || 0, apptSales?.onlValue || 0, apptSales?.hvtValue || 0]
		: _.isEqual(saleType, 'otherSales')
		? [otherSales?.membershipValue || 0, otherSales?.quickSalesValue || 0, ...(isCareFlow ? [otherSales?.pharmaValue || 0] : [])]
		: [0]

	const Colors = _.isEqual(saleType, 'all')
		? ['#4A9AFF', '#FF83C6', '#68C8FF', '#75E252']
		: _.isEqual(saleType, 'appointments')
		? ['#887CE3', '#FCB0BA', '#FFC107']
		: _.isEqual(saleType, 'otherSales')
		? ['#FF83C6', '#68C8FF', '#75E252']
		: ['']

	const chartSettings = {
		options: {
			chart: {
				// height: 226,
				type: 'donut',
				foreColor: 'rgb(107, 114, 128)',
				fontSize: '1.4rem',
			},
			labels: Labels,
			colors: Colors,
			legend: {
				show: true,
				// position: 'right',
				formatter: function (seriesName, opts) {
					let value = [opts.w.globals.series[opts.seriesIndex]]
					let gettingPercent = [opts.w.globals.seriesPercent[opts.seriesIndex][0]]
					let percentage = parseFloat(gettingPercent[0]).toFixed(2)
					return `
					<div class="legendContainer">
						<div class="seriesRow">
							<p class="name">${seriesName}</p>
							<p class="value"> ${currencyCode(value, currencyType)}</p>
						</div>
						<p class="percentage">${Number(percentage) >= 0 ? percentage : 0}%</p>
					</div>`
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 0,
			},
			plotOptions: {
				pie: {
					expandOnClick: true,
					donut: {
						size: '74%',
						background: '#fff',

						labels: {
							show: true,
							name: {
								show: true,
							},

							value: {
								show: true,
								fontSize: '16px',
								color: '#828583',
							},
							total: {
								show: true,
								label: 'Total',
								color: '#000',
								fontSize: '18px',
								showAlways: true,
								fontWeight: 600,
								formatter: function (w) {
									return (
										`${currencyType}` +
										' ' +
										w.globals.seriesTotals.reduce((a, b) => {
											let abb = a + b
											return Math.round(abb * 100) / 100
										}, 0)
									)
								},
							},
						},
					},
				},
			},
			animations: {
				enabled: true,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 180,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
			responsive: [
				{
					breakpoint: 6560,
					options: {
						chart: {
							width: 700,
							height: 252,
							type: 'donut',
						},
						legend: {
							position: 'right',
						},
					},
				},
				{
					breakpoint: 1750,
					options: {
						chart: {
							width: 600,
							height: 252,
							type: 'donut',
						},
						legend: {
							position: 'right',
						},
					},
				},
				{
					breakpoint: 1300,
					options: {
						chart: {
							width: 560,
							height: 252,
							type: 'donut',
						},
						legend: {
							position: 'right',
						},
					},
				},
				{
					breakpoint: 1150,
					options: {
						chart: {
							width: 560,
							height: 388,
							type: 'donut',
						},
						legend: {
							position: 'bottom',
						},
					},
				},
				{
					breakpoint: 940,
					options: {
						chart: {
							width: 420,
							height: 500,
							type: 'donut',
						},
						legend: {
							position: 'bottom',
						},
					},
				},
				{
					breakpoint: 900,
					options: {
						chart: {
							width: 580,
							height: 500,
							type: 'donut',
						},
						legend: {
							position: 'right',
						},
					},
				},
				{
					breakpoint: 660,
					options: {
						chart: {
							width: 420,
							height: 500,
							type: 'donut',
						},
						legend: {
							position: 'bottom',
						},
					},
				},
			],
		},
	}

	return (
		<div className={classes.root}>
			<Chart options={chartSettings.options} series={Series} type='donut' width='100%' />
		</div>
	)
}
export default TotalSalesChart
