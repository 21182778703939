import { makeStyles } from '@mui/styles'
import React from 'react'
import themesConfig from 'app/fuse-configs/themesConfig'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '12px',
		alignItems: 'center',
		position: 'sticky',
		zIndex: 999,
		background: themesConfig.greyDark.palette.primary.main,
		'& .MuiIconButton-root': {
			background: '#fff',
			borderRadius: '10px',
		},
		[theme.breakpoints.down('md')]: {
			top: 52,
		},
	},
}))
const Header = ({ setFeedbackFilters, feedbackFilter }) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<ModuleTitle>Chat History</ModuleTitle>
		</div>
	)
}

export default Header
