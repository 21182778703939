import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getReportData: (reportType, publicKey, ivKey) => {
		const result = { data: publicKey }

		return axios.post(API_ENDPOINTS.GET_REPORTS_DATA, result, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				'Content-Type': 'application/json',
				path: { reportType },
			},
		})
	},
	// getReportData: (reportType, publicKey, ivKey, params) => {
	// 	const result = { data: publicKey }

	// 	return axios.post(API_ENDPOINTS.GET_REPORTS_DATA, result, {
	// 		headers: {
	// 			isAuthRequired: true,
	// 			'X-SECURITY': csrf(),
	// 			key: `${ivKey}`,
	// 			'Content-Type': 'application/json',
	// 			path: { reportType },
	// 		},
	// 		params: params,
	// 	})
	// },
	sendMailViaExcel: (body) => {
		return axios.post(API_ENDPOINTS.SEND_MAIL_EXCEL_REPORTS, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
}
