import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	delete: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_API}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
	deleteOwner: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_OWNER}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
}
