import Avatar from '@mui/material/Avatar'
import { Button, MenuItem, useMediaQuery } from '@mui/material'
import Icon from '@mui/material/Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Logout } from '../../session/actions'
import history from '@history'
import _ from 'lodash'
import { getImgUrl, getResponseMessage } from 'utils'
import moment from 'moment'
import LogoutApi from '../../../services/Logout/api'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { makeStyles } from '@mui/styles'
import Prompt from '../../sharedComponents/Prompt'
import { showPrompt, closePrompt } from '../../../services/Prompt/actions'
import { showMessage } from 'app/store/fuse/messageSlice'
import themesConfig from 'app/fuse-configs/themesConfig'
import { setBuyNow } from 'services/SubscriptionRedux/action'
import secureLocalStorage from 'react-secure-storage'
import { useTheme } from '@mui/material/styles'
import { updateCurrentIndex } from 'services/sidemenu/action'

const useStyles = makeStyles((theme) => ({
	rootPopper: {
		'& .MuiPopover-paper': {
			display: 'flex',
			width: 300,
			flexDirection: 'column',
			borderRadius: 12,
			gap: 4,
		},
		'& .MuiAvatar-root': {
			width: 30,
			height: 30,
		},
		' .MuiTypography-root': {
			color: '#000',
			fontWeight: '600',
		},
		'& .MuiMenuItem-root': {
			marginInline: 12,
			borderRadius: 4,
		},
		'& .MuiMenuItem-root:hover': {
			background: '#E4F0FF',
			color: '#0062DD',
		},
		'& .MuiMenuItem-root:hover .MuiIcon-root': {
			color: '#0062DD',
		},
		'& .MuiMenuItem-root:hover .MuiSvgIcon-root': {
			color: '#0062DD',
		},
	},
	menuTitle: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		'& .MuiTypography-root': {
			textAlign: 'end',
		},
	},
	emailScroll: {
		display: 'flex',
		paddingInlineStart: 24,
		paddingBlockEnd: 12,
		'& .MuiTypography-root': {
			lineHeight: '200%',
		},
	},
	avatarContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiAvatar-root': {
			background: themesConfig.lyfngo.palette.primary.main,
			color: themesConfig.lyfngo.palette.light,
			width: 50,
			height: 50,
		},
	},
	menuBtn: {
		'&:hover': {
			background: themesConfig.lyfngo.palette.light.main,
		},
	},
}))
function UserMenu() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const userName = useSelector((state) => state?.dynamicMenu?.currentPractice?.tentUserFirstName)
	const profilepic = useSelector((state) => state?.dynamicMenu?.userData?.tentUserProfilePicUuid)
	const { groupName, userRole } = mainmenuState
	const state = useSelector((state) => state.Authentication)
	const { userEmail, deviceId, BearerToken, identityUuid } = state
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const isCare = _.isEqual(groupName, 'health care')
	const errorJson = useSelector((states) => states.errorMessages || {})
	const rightMenu = useSelector((state) => state?.dynamicMenu?.list?.rightMenu || [])
	const [userMenu, setUserMenu] = useState(null)
	const [loading, setLoading] = useState(false)
	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget)
	}
	const userMenuClose = () => {
		setUserMenu(null)
	}
	const onClickAddPractice = () => {
		isCare
			? history.push({ pathname: '/setting', search: '?tabId=practicedetails', state: { openPractice: true } })
			: history.push({ pathname: '/setting', search: '?tabId=establishmentdetails', state: { openPractice: true } })
		userMenuClose()
		dispatch(updateCurrentIndex('setting'))
	}
	const LogoutUser = () => {
		setLoading(true)
		const body = {
			status: false,
			logoutTime: moment().format(),
			isRememberMyMail: secureLocalStorage.getItem('checked') || false,
		}
		const onSuccess = (res) => {
			setLoading(false)
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorJson, 'OTP sent successfully'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
			secureLocalStorage.clear()
			dispatch(
				Logout({
					deviceId: null,
					rememberMail: res?.data?.data?.tentUserIdentityEmail,
					rememberCheck: res?.data?.data?.isRememberMyMail,
				})
			)
			history.push('/')
		}
		const onFailure = (err) => {
			setLoading(true)
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		LogoutApi.setLogout(deviceId, body, identityUuid).then(onSuccess, onFailure)
	}
	if (loading) {
		return 'Logging Out ...'
	}
	return (
		<>
			{/* {loading ? <FuseSplash /> : null} */}
			<div className='border-#0000001A-600'>
				<Button className={classes.menuBtn} onClick={userMenuClick} color='inherit'>
					{!isMobile && (
						<div className={classes.menuTitle}>
							<Typography style={{ color: '#000', fontWeight: '600' }} noWrap>
								{!_.isEmpty(userName) ? userName : 'Hello, User'}
							</Typography>
							<Typography>{userRole}</Typography>
						</div>
					)}

					<Avatar
						className='md:mx-4 w-45 h-45'
						src={profilepic !== 'null' || '' || null ? getImgUrl(profilepic, BearerToken) : ''}
						alt={userName}
						style={{ background: '#0062DD', color: '#FFF' }}
					/>
				</Button>
			</div>
			<Popover
				open={Boolean(userMenu)}
				className={classes.rootPopper}
				anchorEl={userMenu}
				onClick={userMenuClose}
				onClose={userMenuClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 59,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
			>
				{userEmail === '' ? (
					<>
						<MenuItem component={Link} to='/' role='button'>
							<ListItemIcon className='min-w-40'>
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary='Login' />
						</MenuItem>
						<MenuItem component={Link} to='/register' role='button'>
							<ListItemIcon className='min-w-40'>
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary='Register' />
						</MenuItem>
					</>
				) : (
					<>
						<div className={classes.emailScroll}>
							<div className={classes.avatarContainer}>
								<Avatar
									className='md:mx-4'
									src={profilepic !== 'null' || '' || null ? getImgUrl(profilepic, BearerToken) : ''}
									alt={userName}
									sx={{ height: 50, width: 50 }}
								/>
							</div>
							<div>
								<Typography style={{ color: '#000', fontWeight: '600' }}>{!_.isEmpty(userName) ? userName : 'Hello, User'}</Typography>
								<Typography component='span'>{userEmail}</Typography>
							</div>
						</div>

						{_.map(
							rightMenu || [],
							(menu, idx) =>
								menu?.display && (
									<>
										{menu?.mastMenuUrl !== 'setting' ? (
											<MenuItem key={idx} component={Link} to={`/${menu?.mastMenuUrl}`} onClick={userMenuClose} role='button'>
												<ListItemIcon className='min-w-40'>
													<Icon>{menu?.mastMenuDefaulticon}</Icon>
												</ListItemIcon>
												<ListItemText primary={menu?.mastMenuName} />
											</MenuItem>
										) : (
											<MenuItem
												onClick={() => {
													dispatch(setBuyNow(true))
													onClickAddPractice()
												}}
												role='button'
											>
												<ListItemIcon className='min-w-40'>
													<Icon>note_add</Icon>
												</ListItemIcon>
												<ListItemText primary={menu?.mastMenuName} />
											</MenuItem>
										)}
									</>
								)
						)}

						<MenuItem
							onClick={(e) =>
								dispatch(
									showPrompt(
										'Are you sure you want to Logout ?',
										'Yes',
										'No',
										() => {
											dispatch(closePrompt())
											LogoutUser()
											userMenuClose()
										},
										() => dispatch(closePrompt())
									)
								)
							}
						>
							<ListItemIcon className='min-w-40'>
								<LogoutOutlinedIcon size='small' />
							</ListItemIcon>
							<ListItemText primary='Logout' />
						</MenuItem>
					</>
				)}
			</Popover>
			<Prompt />
		</>
	)
}

export default UserMenu
