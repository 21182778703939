import Leads from './Leads'

const LeadsConfig = {
	routes: [
		{
			path: '/leads',
			component: Leads,
		},
	],
}

export default LeadsConfig
