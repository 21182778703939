import { React } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Grid, TextField } from '@mui/material'
import useStyles from './style'
import CustomDialog from 'app/sharedComponents/CustomDialog'

export default function EditPlanModal({ match, open4, setOpen4, handleClose4, initialField, updatePlanName, getPlanList, planName }) {
	const classes = useStyles()

	const onSubmit = (e) => {
		handleClose4()
		updatePlanName(e)
	}
	const formik = useFormik({
		initialValues: {
			planName: initialField,
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			planName: yup
				.string()
				.required('Please enter the plan name')
				.min(4, 'Plan name is minimum 4 characters')
				.max(50, 'Plan name is maximum 50 characters'),
		}),
		onSubmit: onSubmit,
	})
	handleClose4 = (e, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpen4(false)
			formik?.resetForm()
		}
	}
	return (
		<CustomDialog
			onClose={handleClose4}
			open={open4}
			headerText='Edit communication plan'
			formikValues={formik}
			onSubmit={() => formik?.handleSubmit()}
			maxWidth='sm'
		>
			<Grid container className={classes.editPlanMain}>
				<Grid item lg={12} md={12} sm={12}>
					<TextField
						id='planName'
						name='planName'
						type='text'
						size='small'
						fullWidth
						label='Enter the plan name*'
						variant='outlined'
						color='secondary'
						value={formik?.values?.planName}
						onChange={(e, value) => {
							formik?.setFieldValue('planName', e.target.value.replace(/[^A-Za-z0-9 ]/gi, ''))
						}}
						error={formik.touched.planName && formik.errors.planName}
						helperText={formik.touched.planName && formik.errors.planName && formik.errors.planName}
					/>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}
