import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#fff',
		color: '#383838',
		minWidth: 36,
		borderRadius: 6,
		'&:hover': {
			background: '#fff',
		},
	},
}))

export default function CustomIconButton({ children, id = 'iconButton', name = 'iconButton', disabled = false, onClick = () => {} }) {
	const classes = useStyles()
	return (
		<Button id={id} name={name} size='medium' className={classes?.root} disabled={disabled} onClick={onClick}>
			{children || ''}
		</Button>
	)
}
