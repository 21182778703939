import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	mainContent: {},
	mainContain: {
		display: 'flex',
		background: '#FFF0E0 0% 0% no-repeat padding-box',
		borderRadius: 11,
		opacity: 1,
		padding: 14,
	},
	mainFeild: {
		// display: 'grid',
		// gridTemplateColumns: '1fr 1fr 1fr 0.8fr',
		// gap: 20,
		// paddingBlock: 20,
		// maxWidth: '80%',
		// [theme.breakpoints.down('xs')]: {
		// 	gridTemplateColumns: '1fr',
		// 	alignItems: 'center',
		// 	maxWidth: '100%',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	gridTemplateColumns: '1fr',
		// 	alignItems: 'center',
		// 	maxWidth: '100%',
		// },
	},

	allContain: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		paddingBlockEnd: 24,
	},
	footListItem: {
		display: 'flex',
		gap: 50,
		[theme.breakpoints.down('sm')]: {
			gap: 6,
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	cancelBtn: {
		color: 'red',
	},
	listItemCont: {
		display: 'flex',
		flexDirection: 'column',
		gap: 6,
		paddingBlock: 12,
	},
	uploadImage: {},
	buttonMain: {
		display: 'flex',
		gap: 16,
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	addExperiencebtn: {
		color: '#0088ff',
	},
	addExperience: {
		display: 'flex',
		paddingTop: 10,
	},
	cancelExperience: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: 26,
			position: 'absolute',
			color: 'red',
			cursor: 'pointer',
		},
	},
	profList: {
		display: 'flex',
		alignItems: 'center',
	},
	uploadedWrapper: { height: '130px' },
	uploadedBlob: { maxWidth: '100%', height: '100%' },
}))
export default useStyles
