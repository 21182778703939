import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import themesConfig from 'app/fuse-configs/themesConfig'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

const useStyles = makeStyles((theme) => ({
	root: {
		background: themesConfig.lyfngo.palette.light.main,
		color: themesConfig.lyfngo.palette.tertiary.main,
		minWidth: 36,
		borderRadius: 6,
		'&:hover': {
			background: themesConfig.lyfngo.palette.light.main,
		},
	},
}))

export default function CustomFilterButton({ children, id = 'iconButton', name = 'iconButton', disabled = false, onClick = () => {} }) {
	const classes = useStyles()
	return (
		<Button id={id} name={name} size='medium' className={classes?.root} disabled={disabled} onClick={onClick}>
			<FilterAltOutlinedIcon />
		</Button>
	)
}
