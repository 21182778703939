import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getMicrositeMenuData: (mastuuid) => {
		return axios.get(`${API_ENDPOINTS.GET_MICROSITE_MENU}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	postMicrositeMenuData: (body, mastuuid, headerId) => {
		return axios.post(`${API_ENDPOINTS.POST_MICROSITE_MENU}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid, headerId } },
		})
	},
	getAssignedMicrositeMenuData: (mastuuid, profileHeaderUuid) => {
		const path = `files/profiles/microSite/getMicrositeMenu/:mastuuid/:profileHeaderUuid`
		const urlWithParams = path.replace(':mastuuid', mastuuid).replace(':profileHeaderUuid', profileHeaderUuid)
		return axios.get(urlWithParams, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
}
