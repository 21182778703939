import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getProfileTable: (mastuuid) => {
		return axios.get(API_ENDPOINTS.PROFILE_TABLE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getPracticeList: (identityUuid) => {
		return axios.get(API_ENDPOINTS.PROFILE_PRACTICELIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { identityUuid } },
		})
	},
	getProfileUserList: (mastuuid) => {
		return axios.get(API_ENDPOINTS.PROFILE_USERLIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
}
