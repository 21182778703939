import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	newAddArticleMain: {
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 1100,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
			[theme.breakpoints.down('450')]: {
				width: '100%',
			},
		},
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: '#000',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#000',
			border: '1px solid',
		},
	},
	cancelButton: {
		background: '#EFEFEF',
		color: 'black',
		paddingBlock: 4,
		paddingInline: 20,
		'&:hover': {
			background: '#EFEFEF',
			color: 'black',
		},
	},
	newAddDetails: {
		'& .MuiSvgIcon-root': {
			fill: '#000',
		},
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	dayText: {
		fontSize: 14,
		color: '#414A58',
	},
	txt: {
		fontSize: theme.spacing(1.6),
		marginTop: theme.spacing(2.5),
	},
	wrapperClass: {
		padding: '1rem',
		border: '1px solid #ccc',
		// minHeight: 300,
		'& .rdw-image-modal': {
			left: -183,
		},
	},
	editorClass: {
		backgroundColor: '#fff',
		border: '0px solid #999',
	},
	toolbarClass: {
		border: '0px solid #ccc',
	},
	btnActions: {
		'& .MuiDialogActions-root': {
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.up('xs')]: {
				justifyContent: 'space-between',
			},
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'space-between',
			},
			[theme.breakpoints.up('md')]: {
				justifyContent: 'flex-end',
			},
		},
	},
	selectDayWeek: {
		'& .MuiSvgIcon-root': {
			fill: '#000',
		},
		[theme.breakpoints.up('xs')]: {
			gap: 16,
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			gap: 16,
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			gap: 16,
			display: 'flex',
		},
		'& .MuiAutocomplete-root': {
			[theme.breakpoints.up('xs')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.up('sm')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.up('md')]: {
				paddingBlock: 0,
			},
		},
	},
}))
export default useStyles
