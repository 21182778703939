import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	profileTable: {
		// height: 460,
		minHeight: '70vh',
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		marginBlock: 12,
		'& .MuiDataGrid-iconSeparator ': {
			display: 'none',
		},
		'& .MuiDataGrid-columnHeaderTitleContainer': {
			padding: '0px 0px',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '600',
		},
		'& .MuiDataGrid-columnHeaderWrapper': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: '#EBF4FF',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF',
		},
		'& .MuiDataGrid-cell': {
			border: 'none',
			color: '#000',
		},
		'& .MuiDataGrid-root': {
			border: 'none',
			'& .MuiDataGrid-cell--withRendere': {
				[theme.breakpoints.down('sm')]: {
					display: 'block !important',
				},
			},
			'& .MuiDataGrid-footerContainer': {
				borderBottomLeftRadius: 8,
				borderBottomRightRadius: 8,
				background: '#ffffff',
			},
			'& .MuiDataGrid-window': {
				overflowX: 'hidden',
			},
		},
		[theme.breakpoints.down('md')]: {
			height: 200,
			width: '100%',
			paddingInline: 12,
			paddingBlock: 10,
		},
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		background: '#fff',
		paddingBlock: 8,
		paddingInline: 12,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		'& .MuiSvgIcon-root': {
			cursor: 'pointer',
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
	},
	colorContain: {
		color: '#000',
	},
	messageText: {
		fontSize: 14,
		color: '#2A2A2A',
		fontWeight: 500,
	},
	groupText: {
		fontSize: 12,
	},
	customTooltip: {
		backgroundColor: '#6B7280',
		maxWidth: '300px',
		fontSize: 12,
		color: '#fff',
	},
	arrow: {
		color: '#6B7280',
	},
	noData: {
		background: '#fff',
		width: '100%',
		display: 'grid',
		placeItems: 'center',
		paddingBlock: 20,
	},
}))
export default useStyles
