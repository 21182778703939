/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
import { API_ENDPOINTS } from '../../constants/index'
import { csrf } from 'utils'

export default {
	getNotifications: (params) => {
		return axios.get(API_ENDPOINTS.GET_NOTIFICATION_LIST, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
			},
			params: params,
		})
	},

	readNotification: (body) => {
		return axios.post(API_ENDPOINTS.READ_NOTIFICATION, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},

	clearOneNotification: (body) => {
		return axios.post(API_ENDPOINTS.CLEAR_ONE_NOTIFICATION, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},

	clearAllNotifications: (body) => {
		return axios.post(API_ENDPOINTS.CLEAR_ALL_NOTIFICATION, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},
}
