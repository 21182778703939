import Button from '@mui/material/Button'
import { React } from 'react'
function CustomStartBtn({ id, name, variant, color, disabled, children, onClick, startIcon, className }) {
	return (
		<Button variant={variant} id={id} name={name} color={color} disabled={disabled} className={className} onClick={onClick} startIcon={startIcon}>
			{children}
		</Button>
	)
} 
export default CustomStartBtn
