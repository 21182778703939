import Task from './Task'

const TaskConfig = {
	routes: [
		{
			path: '/LeadsManagement/Task',
			component: Task,
		},
	],
}

export default TaskConfig
