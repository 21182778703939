import Consultation from './'

const ConsultationConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true,
				},
				toolbar: {
					display: true,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: true,
				},
				rightSidePanel: {
					display: true,
				},
			},
		},
	},
	routes: [
		{
			path: '/consult',
			component: Consultation,
		},
	],
}

export default ConsultationConfig
