import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import themesConfig from 'app/fuse-configs/themesConfig'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
import OutlineFilter from 'app/sharedComponents/OutlineFilter'
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '12px',
		alignItems: 'center',
		position: 'sticky',
		// top: 64,
		zIndex: 999,
		background: themesConfig.greyDark.palette.primary.main,
		'& .MuiIconButton-root': {
			background: '#fff',
			borderRadius: '10px',
		},
		[theme.breakpoints.down('md')]: {
			top: 52,
		},
	},
}))
const Header = ({ setFeedbackFilters, feedbackFilter }) => {
	const classes = useStyles()
	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})

	return (
		<div className={classes.root}>
			<ModuleTitle>Feedback</ModuleTitle>
			<OutlineFilter onClick={setFeedbackFilters} isOpen={feedbackFilter}>
				<FilterAltOutlinedIcon />
			</OutlineFilter>
		</div>
	)
}

export default Header
