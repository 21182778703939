import { ACTION_TYPES } from '../../../constants'

const initialState = {
	isFetchingMenu: false,
	userData: {},
	list: [],
	features: [],
	practiceListGlobal: [],
	isFetchingPracticeList: false,
	currentPractice: {},
	currentModule: null,
}

const dynamicMenuReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.DYNAMIC_MENU_FETCHING: {
			return {
				...state,
				isFetchingMenu: true,
			}
		}
		case ACTION_TYPES.DYNAMIC_MENU_SUCCESS: {
			return {
				...state,
				isFetchingMenu: false,
				userData: action?.payload?.userData,
				list: action?.payload?.list,
				features: action?.payload?.features,
				trialDays: action?.payload?.userData?.daysLeft || 0,
			}
		}
		case ACTION_TYPES.DYNAMIC_MENU_UPDATE_TRIAL: {
			return {
				...state,
				trialDays: action?.payload || 0,
			}
		}
		case ACTION_TYPES.PRACTICE_LIST_FETCHING: {
			return {
				...state,
				practiceListGlobal: action?.payload || [],
			}
		}
		case ACTION_TYPES.IS_FETCHING_PRACTICE_LIST: {
			return {
				...state,
				isFetchingPracticeList: action?.payload,
			}
		}
		case ACTION_TYPES.CURRENT_PRACTICE_DATA: {
			return {
				...state,
				currentPractice: action?.payload || {},
			}
		}
		case ACTION_TYPES.UPDATE_CURRENT_MODULE: {
			return {
				...state,
				currentModule: action?.payload || [],
			}
		}
		case ACTION_TYPES.UPDATE_USERDATA: {
			return {
				...state,
				userData:
					{
						...state.userData,
						defaultIntegrationForOnlineMeeting: action?.payload?.defaultIntegrationForOnlineMeeting,
						defaultIntegrationForPayment: action?.payload?.defaultIntegrationForPayment,
					} || {},
			}
		}
		default:
			return state
	}
}

export default dynamicMenuReducer
