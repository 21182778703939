/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
	{
		dayNo: 1,
		day: 'Day 1',
	},
	{
		dayNo: 2,
		day: 'Day 2',
	},
	{
		dayNo: 3,
		day: 'Day 3',
	},
	{
		dayNo: 4,
		day: 'Day 4',
	},
	{
		dayNo: 5,
		day: 'Day 5',
	},
	{
		dayNo: 6,
		day: 'Day 6',
	},
	{
		dayNo: 7,
		day: 'Day 7',
	},
]
