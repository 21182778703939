//Error Boundary
// function ErrorFallback({ error, resetErrorBoundary }) {
// 	return (
// 		<div role='alert'>
// 			<p>Something went wrong:</p>
// 			<pre>{error.message}</pre>
// 			<button onClick={resetErrorBoundary}>Try again</button>
// 		</div>
// 	)
// }
// export default ErrorFallback

import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
	tryAgain: {
		marginTop: 14,
		backgroundColor: '#2EB2FF !important',
		color: '#fff',
		fontSize: 16,
		borderRadius: 20,
		paddingInline: 22,
	},
}))

function ErrorFallback({ error, resetErrorBoundary }) {
	const classes = useStyles()
	// console.log('error.message ErrorFallback', error.message)

	return (
		<div className='flex flex-col flex-1 items-center justify-center p-16' role='alert'>
			<div className='max-w-512 text-center'>
				<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}>
					<img width='350' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/error.svg`} alt='something went wrong' />
				</motion.div>
			</div>
			<Button variant='contained' className={classes.tryAgain} onClick={resetErrorBoundary}>
				Try again
			</Button>
		</div>
	)
}

export default ErrorFallback
