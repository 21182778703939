/* eslint-disable prettier/prettier */
import * as React from 'react'
import { Grid, Typography, Button, IconButton, useTheme, useMediaQuery, FormHelperText, Box, Autocomplete, TextField, Chip } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import _, { isEmpty } from 'lodash'
import { useFormik, FormikProvider, FieldArray, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import QualificationApi from '../../../../services/Qualification/api'
import LookupApi from '../../../../services/lookup/api'
import ButtonComp from '../../../sharedComponents/Button'
import educationdetails from '../../../../services/ProfileSetup/educationdetails/api'
import { hideLoader, showLoader } from 'services/loader/action'
import { getResponseMessage } from '../../../../utils'
import useStyles from './style'
import { setEducationData } from 'services/ProfileSetup/list/actions'
import AdvancedAutocomplete from '../../../sharedComponents/AdvancedAutocomplete'
import GetEstablishmentDetails from 'services/ProfileSetup/EstablishmentDetails/api'
import secureLocalStorage from 'react-secure-storage'

function EducationDetails({ moveNextTab, moveBackTab, match }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.GroupUser)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const cancelExecutor = useRef()
	const [collegeNameOptions, setCollegeNameOptions] = useState([])
	const [qualificationOptions, setQualificationOptions] = useState([])
	const [yearOptions, setYearOptions] = useState([])
	const [loading, setLoading] = useState(true)
	const [specialityChip, setSpecialityChip] = useState([])
	const [isEmptyCheck, setIsEmptyCheck] = useState([])
	const [specializationOptions, setSpecializationOptions] = useState([])
	// const listData = useSelector((state) => state?.profileSetup?.educationDetails?.educationList || [])
	const [listData, setListData] = useState([])
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus)
	const theme = useTheme()
	const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const tentTypeId = useSelector((state) => state?.Authentication?.tentTypeId || secureLocalStorage.getItem('tentTypeId'))
	const userType = useSelector((state) => state?.Authentication?.userType || secureLocalStorage.getItem('userType'))

	const action = _.get(match, 'params.action', '')
	// const isEmptyCheck = _.isEqual(action, 'edit')

	const onSubmit = (e) => {
		submitEducationDetails(e)
	}
	const formikSchema = yup.object().shape({
		education: yup
			.array()
			.min(1)
			.of(
				yup.object().shape({
					degreeName: yup.string('value must be a string').required('Degree is required').max(50, 'Max 50 characters only'),
					collegeName: yup.string('value must be a string').required('College name is required').max(50, 'Max 50 characters only'),
					completedYear: yup.string().nullable().required('Completed year is required'),
					specialization: !_.isEmpty(isEmptyCheck)
						? yup.array().nullable()
						: yup.array().min(1, 'Please add any one').nullable().required('Please add any one'),
					specializationChip: !_.isEmpty(isEmptyCheck)
						? yup.array().nullable()
						: yup.array().min(1, 'Please add any one').nullable().required('Please add any one'),
				})
			)
			.required('Please enter the mandatory field'),
	})

	const formik = useFormik({
		initialValues: {
			education: !_.isEmpty(listData)
				? listData
				: [
						{
							degreeName: '',
							collegeName: '',
							completedYear: null,
							specialization: [],
							specializationChip: [],
						},
				  ],
		},
		enableReinitialize: true,
		validationSchema: formikSchema,
		onSubmit: onSubmit,
	})

	const [clgparams, setClgparams] = useState({
		limit: 20,
		offset: 1,
		search: formik?.values?.collegeName,
	})
	const [yearparams, setYearparams] = useState({
		limit: 10,
		offset: 1,
		search: '',
	})

	const onCollegeSearch = _.debounce(
		(e, value) => {
			setClgparams({
				...clgparams,
				search: value,
			})
		},
		[100]
	)
	const onYearSearch = _.debounce(
		(e, value) => {
			setYearparams({
				...yearparams,
				search: value,
			})
		},

		[300]
	)
	const getQualificationDegreeName = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLoading(false)
				setQualificationOptions(res?.data?.data)
			} else {
				setLoading(false)
				setQualificationOptions([])
			}
		}
		const onFailure = (err) => {
			setLoading(false)
			setQualificationOptions([])
		}
		QualificationApi.getQualificationName().then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data.status === 'success') {
				setSpecializationOptions(res?.data?.data || [])
			} else {
				setSpecializationOptions([])
			}
		}
		const onFailure = (err) => {
			setSpecializationOptions([])
		}
		GetEstablishmentDetails.getSpeciality(tentTypeId).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// if (isEmptyCheck) {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setIsEmptyCheck(res?.data?.data)
				if (_.isEmpty(res?.data?.data)) {
					formik?.setFieldValue('education', [
						{
							degreeName: '',
							collegeName: '',
							completedYear: '',
							specialization: [],
						},
					])
				} else {
					let tempArr = []
					_.map(res?.data?.data || [], (item) => {
						tempArr.push({
							degreeName: item?.degreeName,
							collegeName: item?.collegeName,
							completedYear: item?.completedYear,
							specialization: item?.specialization,
							specializationChip: item?.specialization,
							isAmend: false,
							progressStatus: progressStatus || 'IPG',
							profileEducationUuid: item?.profileEducationUuid || null,
							parentId: null,
						})
					})
					formik?.setFieldValue('education', tempArr)
				}
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}
		educationdetails.getEdudetails(userType, profileHeaderUuid).then(onSuccess, onFailure)
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, progressStatus, profileHeaderUuid])

	const getCollegeNameCode = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLoading(false)
				setCollegeNameOptions(res.data.data)
			}
		}
		const onFailure = (err) => {
			setCollegeNameOptions([])
		}
		LookupApi.getLookupNew('college', { ...clgparams }, cancelExecutor).then(onSuccess, onFailure)
	}, [clgparams])

	const getCompletionYear = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLoading(false)
				setYearOptions(res.data.data)
			}
		}
		const onFailure = (err) => {}

		LookupApi.getLookup('getLookup', { ...yearparams, mastLookupType: 'YER' }).then(onSuccess, onFailure)
	}, [yearparams])

	useEffect(() => {
		getQualificationDegreeName()
	}, [getQualificationDegreeName])

	useEffect(() => {
		getCompletionYear()
		getCollegeNameCode()
	}, [getCompletionYear, getCollegeNameCode])

	const submitEducationDetails = (e) => {
		dispatch(showLoader('Loading please wait...'))
		let body = []
		_.map(formik?.values?.education, (education) => {
			body.push({
				degreeName: education?.degreeName,
				collegeName: education?.collegeName,
				completedYear: education?.completedYear,
				specialization: education?.specialization,
				isAmend: false,
				progressStatus: progressStatus || 'IPG',
				profileEducationUuid: education?.profileEducationUuid || null,
				parentId: null,
			})
		})

		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				moveNextTab()
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res?.data?.status === 'failure') {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		_.isEmpty(isEmptyCheck)
			? educationdetails.setEducation(body, userType, profileHeaderUuid).then(onSuccess, onFailure)
			: educationdetails.updateEducationDetails(body, userType, profileHeaderUuid).then(onSuccess, onFailure)
	}
	useEffect(() => {
		setListData(formik?.values?.education || [])
	}, [formik?.values?.education])

	const onSpecialityDelete = (formik, educationIndex, specializationIndex) => () => {
		formik.setFieldValue((values) => {
			const newValues = { ...values }
			const education = newValues.education.slice()
			if (Array.isArray(education) && education[educationIndex]) {
				const educationItem = { ...education[educationIndex] }

				if (Array.isArray(educationItem.specialization)) {
					educationItem.specialization = educationItem.specialization.filter((_, index) => index !== specializationIndex)
				}

				if (Array.isArray(educationItem.specializationChip)) {
					educationItem.specializationChip = educationItem.specializationChip.filter((_, index) => index !== specializationIndex)
				}

				education[educationIndex] = educationItem
			}

			newValues.education = education
			return newValues
		})
	}

	const deleteEducation = (deleteUuid, arrayHelpers, index) => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				arrayHelpers.remove(index)
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}
		educationdetails.deleteEducation(deleteUuid).then(onSuccess, onFailure)
	}
	return (
		<FormikProvider value={formik}>
			<FieldArray
				name='education'
				render={(arrayHelpers) => (
					<div className={classes.mainContainer}>
						{_.map(formik?.values?.education, (detail, index) => (
							<Grid container key={index} className={classes.container}>
								<Grid item container spacing={2} lg={10}>
									<Grid item lg={4} sm={12} className={classes.mainList}>
										<Typography pt={1} variant='body1'>
											Qualification *
										</Typography>
										<TextField
											id={`education[${index}].degreeName`}
											name={`education[${index}].degreeName`}
											fullWidth
											size='small'
											type='text'
											color='secondary'
											value={formik?.values?.education[index]?.degreeName}
											onChange={(e, value) => {
												formik?.setFieldValue(`education[${index}].degreeName`, e.target.value || '')
											}}
											placeholder='Qualification'
											InputProps={{ className: classes.input }}
										/>
										<ErrorMessage component={FormHelperText} error name={`education[${index}].degreeName`} />
									</Grid>
									<Grid item lg={4} sm={12} className={classes.mainList}>
										<Typography pt={1} variant='body1'>
											College *
										</Typography>
										<TextField
											id={`education[${index}].collegeName`}
											name={`education[${index}].collegeName`}
											fullWidth
											size='small'
											type='text'
											color='secondary'
											value={formik?.values?.education[index]?.collegeName}
											onChange={(e, value) => {
												formik?.setFieldValue(`education[${index}].collegeName`, e.target.value || '')
											}}
											placeholder='Enter college name'
											InputProps={{ className: classes.input }}
										/>
										<ErrorMessage component={FormHelperText} error name={`education[${index}].collegeName`} />
									</Grid>
									<Grid item lg={4} sm={12} className={classes.mainList}>
										<Typography pt={1} variant='body1'>
											Completed Year*
										</Typography>
										<AdvancedAutocomplete
											name={`education[${index}].completedYear`}
											options={yearOptions}
											loading={loading}
											value={formik?.values?.education[index].completedYear}
											getOptionLabel={(option) => option?.mastLookupValue || ''}
											getOptionSelected={(option, value) => option?.mastLookupValue === value?.mastLookupValue || ''}
											onChange={(e, value) => {
												formik?.setFieldValue(`education[${index}].completedYear`, value?.mastLookupValue || '')
												setYearparams((prevState) => ({ ...prevState, search: value?.mastLookupValue || '' }))
											}}
											onInputChange={onYearSearch}
											placeholder='completed year'
										/>
										<ErrorMessage component={FormHelperText} error name={`education[${index}].completedYear`} />
									</Grid>
									<Grid container md={12} className={classes.addSpeciality}>
										{/* {!_.isEmpty(formik?.values?.education[index].specializationChip) && (
											<Grid item lg={12}>
												<Box
													mb={1}
													mt={1}
													sx={{
														'& > :not(:last-child)': { mr: 1, background: 'antiquewhite !important' },
														'& > *': { mr: 2, background: 'antiquewhite !important' },
													}}
													style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}
												>
													{!_.isEmpty(formik?.values?.education[index].specializationChip) &&
														formik?.values?.education[index].specializationChip.map((v, chipIndex) => (
															<Chip key={chipIndex} label={v.specialityName} onDelete={onSpecialityDelete(formik, index, chipIndex)} />
														))}
												</Box>
											</Grid>
										)} */}
										<Grid item lg={4} sm={12} pb={1} sx={{ paddingLeft: '16px' }} className={classes.mainList}>
											<Typography pt={1} variant='body1'>
												Specialisation *
											</Typography>
											<Box>
												<Autocomplete
													multiple
													id={`education[${index}].specialization`}
													name={`education[${index}].specialization`}
													options={specializationOptions}
													getOptionLabel={(option) => option?.specialityName || ''}
													value={formik?.values?.education[index].specializationChip || []}
													filterSelectedOptions={true}
													disableCloseOnSelect
													fullWidth
													size='small'
													noOptionsText='No options'
													className={classes.searchCategoriey}
													isOptionEqualToValue={(option, value) => option?.specialityName === value?.specialityName}
													onChange={(e, value) => {
														formik?.setFieldValue(`education[${index}].specialization`, value || [])
														formik?.setFieldValue(
															`education[${index}].specializationChip`,
															value.map((item) => ({ specialityName: item.specialityName }))
														)
													}}
													renderTags={(selected, getTagProps) =>
														selected.map((option, index) => <Chip key={index} label={option.specialityName} {...getTagProps({ index })} />)
													}
													renderInput={(params) => <TextField {...params} color='secondary' variant='outlined' />}
												/>
											</Box>
											{/* <AdvancedAutocomplete
												name={}
												options={specializationOptions}
												id={`education[${index}].specialization`}
												loading={loading}
												value={}
												defaultValue={specializationOptions?.splName}
												className={classes.autocomplete}
												getOptionLabel={(option) => option?.splName || ''}
												getOptionSelected={(option, value) => option?.splName === value?.splName || ''}
												onChange={(e, value) => {
													formik?.setFieldValue(`education[${index}].specialization`, value?.splName || '')
												}}
												placeholder='Specialisation'
											/> */}
											<ErrorMessage component={FormHelperText} error name={`education[${index}].specialization`} />
										</Grid>
									</Grid>
								</Grid>

								<div className={classes.cancelExperience}>
									{!isMobileView ? (
										formik?.values?.education?.length > 1 ? (
											<IconButton
												onClick={() => {
													isEmptyCheck && formik?.values?.education[index]?.profileEducationUuid
														? deleteEducation(formik?.values?.education[index]?.profileEducationUuid, arrayHelpers, index)
														: arrayHelpers.remove(index)
												}}
											>
												<HighlightOffIcon />
											</IconButton>
										) : (
											''
										)
									) : (
										<Button
											className={classes.cancelBtn}
											onClick={() => {
												isEmptyCheck && formik?.values?.education[index]?.profileEducationUuid
													? deleteEducation(formik?.values?.education[index]?.profileEducationUuid, arrayHelpers, index)
													: arrayHelpers.remove(index)
											}}
										>
											Remove
										</Button>
									)}
								</div>
							</Grid>
						))}
						<div className={classes.addExperience}>
							<Button
								className={classes.addExperiencebtn}
								disabled={formik?.values?.education?.length === 4 ? true : false}
								type='button'
								onClick={() => {
									arrayHelpers.push({
										degreeName: '',
										collegeName: '',
										completedYear: '',
										specialization: [],
										specializationChip: [],
									})
									setClgparams({
										limit: 20,
										offset: 1,
										search: '',
									})
									setYearparams({
										limit: 20,
										offset: 1,
										search: '',
									})
								}}
							>
								+ Add Education & Specialisation
							</Button>
							<Typography>* complete your previous Education details</Typography>
						</div>
					</div>
				)}
			/>
			<div className={classes.mainButton}>
				<ButtonComp onClick={() => moveBackTab()}>Back</ButtonComp>
				<ButtonComp onClick={formik.handleSubmit}>Save & Next</ButtonComp>
			</div>
		</FormikProvider>
	)
}
export default EducationDetails
