import { ACTION_TYPES } from '../../constants'

const initialState = {
	isReminderOpen: false,
	editReminderData: null,
	isBlockCalendarOpen: false,
	editBlockCalendarData: null,
	isCancelAppointmentOpen: false,
	cancelAppointmentData: null,
	cancelAppointmentRefresh: null,
	reminderRefresh: null,
	blockCalendarRefresh: null,
}

const ReminderReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.ADD_REMINDER_MODAL: {
			return {
				...state,
				isReminderOpen: action.payload,
			}
		}

		case ACTION_TYPES.EDIT_REMINDER_DATA: {
			return {
				...state,
				editReminderData: action.payload,
			}
		}

		case ACTION_TYPES.ADD_BLOCK_CALENDAR_MODAL: {
			return {
				...state,
				isBlockCalendarOpen: action.payload,
			}
		}

		case ACTION_TYPES.EDIT_BLOCKCALENDAR_DATA: {
			return {
				...state,
				editBlockCalendarData: action.payload,
			}
		}

		case ACTION_TYPES.CANCEL_APPOINTMENT_MODAL: {
			return {
				...state,
				isCancelAppointmentOpen: action.payload,
			}
		}

		case ACTION_TYPES.CANCEL_APPOINTMENT_DATA: {
			return {
				...state,
				cancelAppointmentData: action.payload,
			}
		}

		case ACTION_TYPES.CANCEL_APPOINTMENT_REFRESH: {
			return {
				...state,
				cancelAppointmentRefresh: action.payload,
			}
		}
		case ACTION_TYPES.REMINDER_REFRESH: {
			return {
				...state,
				reminderRefresh: action.payload,
			}
		}
		case ACTION_TYPES.BLOCK_CALENDAR_REFRESH: {
			return {
				...state,
				blockCalendarRefresh: action.payload,
			}
		}

		default: {
			return state
		}
	}
}

export default ReminderReducer
