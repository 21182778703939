import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFeedbackList: (params) => {
		return axios.get(API_ENDPOINTS.FEEDBACK_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	deleteFeedbackDetails: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_FEEDBACK}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
	postFeedbackDetails: (data) => {
		return axios.put(`${API_ENDPOINTS.POST_FEEDBACK}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	getfeedbackDetails: (mastUuid, params) => {
		return axios.get(API_ENDPOINTS.GET_FFEDBACK_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid } },
			params: params,
		})
	},
	getClientfeedbackDetails: (mastuuid, custuuid, tentuseruuid) => {
		return axios.get(API_ENDPOINTS.GET_CLIENT_FEEDBACK, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, custuuid, tentuseruuid } },
		})
	},
}
//Get url: /services/feedback/microsite/getfeedback/ktt4xypq/y1coezi0
