import LoginConfig from 'app/main/login/LoginConfig'
import RegisterConfig from 'app/main/register/RegisterConfig'
import OtpConfig from 'app/main/otp/otpConfig'
import ForgotPasswordConfig from 'app/main/forgot-password/ForgotPasswordPageConfig'
import ModuleSelectionConfig from 'app/main/moduleSelection/moduleConfig'
import groupSelection from './groupSelection/groupSelectionConfig'
import WelcomeConfig from './welcomeScreen/WelcomeConfig'
import EmailConfig from './EmailVerificationModal/EmailVerificationModalConfig'
import ProfileConfig from './ProfileSetup/profilesetupconfig'
import ResetPassword from './ResetPassword/resetConfig'
import ResetModel from './ResetPassword/ResetModel/ResetModelConfig'
import ResetSuccessful from './ResetSuccessful/ResetSuccessfulConfig'
import ChangeEmail from './ChangeEmail/changeEmailConfig'
import Successmail from './SuccessEmail/successmailconfig'
import EmailSuccessful from './EmailSuccessful/EmailSuccessfulConfig'
import ForgetpassTokenVerification from './ResetPassword/ResetModel/ResetModelConfig'
import ModuleConfig from 'app/main/moduleSelection/moduleConfig'
import Error404 from './404/404Config'
import Error403 from './403/403Config'
import ProfileTableConfig from './ProfileSetup/Profiletable/profileConfig'
import StepperViewConfig from './ProfileSetup/StepperView/StepperSlideConfig'
import OtpVerificationConfig from './OtpVerification/OtpVerificationConfig'
import TermsConfig from './TermsandConditions/TermsandConditionsConfig'
const MainPagesConfig = [
	LoginConfig,
	ModuleConfig,
	RegisterConfig,
	OtpConfig,
	ForgotPasswordConfig,
	ModuleSelectionConfig,
	groupSelection,
	WelcomeConfig,
	EmailConfig,
	ProfileConfig,
	Error404,
	Error403,
	ResetPassword,
	ResetSuccessful,
	EmailSuccessful,
	ChangeEmail,
	ForgetpassTokenVerification,
	ResetModel,
	Successmail,
	TermsConfig,
	ProfileTableConfig,
	StepperViewConfig,
	OtpVerificationConfig,
]

export default MainPagesConfig
