import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		borderRadius: 10,
		overflow: 'hidden',
	},

	container: {
		// '& .MuiDataGrid-iconSeparator ': {
		// 	display: 'none',
		// },
		'& .MuiDataGrid-columnHeaderTitle': {
			color: '#000',
			fontWeight: 500,
		},
		'& .MuiDataGrid-cell': {
			color: '#000',
		},
		'& .MuiDataGrid-row': {
			cursor: 'pointer',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF20',
		},
		'& .MuiDataGrid-toolbarContainer': {
			justifyContent: 'flex-start',
			paddingBlock: 8,
			paddingInline: 8,
			gap: 36,
			'& .MuiButton-root': {
				backgroundColor: '#0062DD',
				borderRadius: 8,
				paddingInline: 16,
			},
		},
	},
	sectionHead: {
		background: '#E1F4FF',
		padding: 14,
	},
	grouped_button: {
		borderRadius: 18,
		height: 'fit-content',
		background: '#fff',

		'& .MuiButton-outlinedPrimary': {
			paddingInline: 20,
			border: '0px',
			height: 32,
		},
		'& .Mui-disabled': {
			background: '#fff',
			color: '#000',
		},
	},
	button: {
		width: 126,
		color: '#727272',
		'&:hover': {
			color: '#727272',
		},
	},
	button_active: {
		width: 126,
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
			color: '#FFF',
		},
	},
	sectionRight: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	select_payment_type: {
		width: 238,
		marginRight: 24,
		'& .MuiOutlinedInput-root': {
			backgroundColor: '#fff',
			'& fieldset': {
				border: 'none',
			},
		},
	},
	emptyRecordBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& img': {
			objectFit: 'cover',
			width: 312,
			marginTop: 12,
		},
	},
}))

export default useStyles
