import { React } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Typography, Grid, Autocomplete, TextField, Tooltip } from '@mui/material'
import useStyles from './style'
import _ from '@lodash'
import Weeks from '../../../../../../model/Communication/Weeks/data'
import Days from '../../../../../../model/Communication/Days/data'
import OperationHours from '../../../../../../model/Communication/OperationHours/data'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import PlanReminder from '../../../../../../services/Communication/Reminder/api'
import moment from 'moment'
import { getResponseMessage } from 'utils'
import CustomDialog from 'app/sharedComponents/CustomDialog'

export default function AddReminder({
	open1,
	handleClose1,
	setOpen1,
	setReminderList,
	setReminderName,
	setShowData,
	match,
	setCount,
	count,
	onRemindAddSuccess,
	getPlanIndividualDetails,
}) {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const { uuid } = state
	const errMessages = useSelector((state) => state?.errorMessages || {})
	const dispatch = useDispatch()
	const planUuid = _.get(match, 'params.id', '')
	const timezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezoneOffset || '')

	const longText = `
    Dr<name> has sent you a reminder <Reminder text>
`
	const onSubmit = (e) => {
		submitReminderList(e)
		getPlanIndividualDetails()
	}
	//Formik Validation
	const formik = useFormik({
		initialValues: {
			selectWeek: [],
			selectDay: [],
			operationTime: '',
			remindPatient: '',
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			selectWeek: yup.object().nullable().required('Please choose a week'),
			selectDay: yup.object().nullable().required('Please choose a day'),
			operationTime: yup.string().nullable().required('Please choose a scheduled time'),
			remindPatient: yup
				.string()
				.required('Please enter the patient about')
				.matches(/^[^<>#$]+$/, 'Cannot contain (<>#$) special characters in the reminder field')
				.min(5, 'Reminder text length should be atleast 5 characters')
				.max(255, 'patient about is maximum 255 charcters only'),
		}),
		// eslint-disable-next-line no-undef
		onSubmit: onSubmit,
	})
	//start : Plan Reminder-POST //
	const submitReminderList = (e) => {
		const formData3 = new FormData()
		formData3.append('tentUserId', uuid)
		formData3.append('reminderTitle', e?.remindPatient)
		formData3.append('weekNo', e?.selectWeek?.WeekNo)
		formData3.append('dayNo', e?.selectDay?.dayNo)
		formData3.append('planReminderTime', moment(e?.operationTime, 'h:mm:ss A').format(`HH:mm:ss${timezone}`))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			formik?.resetForm()
			if (res?.data?.status === 'success') {
				assignReminder(res?.data?.data?.planReminderUuid)
			} else if (res.data.status === 'failure') {
				setOpen1(true)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res.data.data, errMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader('Creating reminder please wait...'))
		PlanReminder.createPlanReminder(formData3).then(onSuccess, onFailure)
	}
	//End : Plan Reminder -POST //
	//start : Assign Reminder
	const assignReminder = (planReminderUuid) => {
		const body = {
			planId: planUuid,
			tentUserId: uuid,
			planReminderId: planReminderUuid,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				setOpen1(false)
				formik.resetForm()
				onRemindAddSuccess()
				setCount(0)
				dispatch(
					showMessage({
						message: getResponseMessage(res.data, errMessages, 'Plan reminder created successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res.data.status === 'failure') {
				setOpen1(true)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res.data, errMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader('Assigning reminder please wait...'))
		PlanReminder.assignPlanReminder(body).then(onSuccess, onFailure)
	}

	const onClose = (e, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			formik?.resetForm()
			handleClose1()
		}
	}

	return (
		<CustomDialog
			onClose={onClose}
			open={open1}
			headerText='Add reminder'
			formikValues={formik}
			maxWidth='md'
			onSubmit={(e) => {
				setReminderList((prevState) => [...prevState, formik?.values])
				formik.handleSubmit(e)
			}}
		>
			<Grid container spacing={1} className={classes.newReminderDetails}>
				<Grid item lg={12} md={12} sm={12}>
					<Typography className={classes.txt}>
						Add reminders about upcoming tests, follow-ups, medicine intake, exercise routines or anything else to your communication plan.
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Typography variant='h6' className={classes.dayText}>
						Select day and time to schedule your reminder
					</Typography>
				</Grid>
				{/* start: select week and day */}
				<Grid item lg={12} md={12} sm={12}>
					<Grid container className={classes.selectWeekandDay} justifyContent='flex-start'>
						<Grid item lg={3} md={3} sm={12}>
							<Autocomplete
								id='selectweek'
								name='selectweek'
								size='small'
								clearIcon=''
								fullWidth
								options={Weeks}
								getOptionLabel={(option) => option?.week || ''}
								value={formik?.values?.selectWeek}
								onChange={(e, value) => {
									formik?.setFieldValue('selectWeek', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select week *'
										color='secondary'
										error={formik.touched.selectWeek && formik.errors.selectWeek}
										helperText={formik.touched.selectWeek && formik.errors.selectWeek && formik.errors.selectWeek}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={3} md={3} sm={12}>
							<Autocomplete
								id='selectday'
								name='selectday'
								size='small'
								fullWidth
								clearIcon=''
								options={Days}
								getOptionLabel={(option) => option?.day || ''}
								value={formik?.values?.selectDay}
								onChange={(e, value) => {
									formik?.setFieldValue('selectDay', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select day *'
										color='secondary'
										error={formik.touched.selectDay && formik.errors.selectDay}
										helperText={formik.touched.selectDay && formik.errors.selectDay && formik.errors.selectDay}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2.5} md={2.5} sm={12}>
							<Autocomplete
								id='operationtime'
								name='operationtime'
								size='small'
								fullWidth
								clearIcon=''
								options={OperationHours}
								value={formik?.values?.operationTime}
								onChange={(e, value) => {
									formik?.setFieldValue('operationTime', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Time *'
										color='secondary'
										error={formik.touched.operationTime && formik.errors.operationTime}
										helperText={formik.touched.operationTime && formik.errors.operationTime && formik.errors.operationTime}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2} md={2} sm={12}></Grid>
					</Grid>
				</Grid>
				{/* end: select week and day */}
				<Grid item lg={12} md={12} sm={12}>
					<Grid container justifyContent='space-between'>
						<Grid item lg={3} md={3} sm={12}></Grid>
						<Grid item lg={3} md={3} sm={12}></Grid>
						<Grid item lg={3} md={3} sm={12}></Grid>
						<Grid item lg={2.5} md={2.5} sm={12} classNam={classes.smsPart}>
							<Tooltip title={longText} arrow placement='top' classes={{ tooltip: classes.tooltip }}>
								<Typography variant='h6' className={classes.smsText}>
									View SMS format
								</Typography>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<TextField
						id='remindpatient'
						name='remindpatient'
						variant='outlined'
						placeholder='Remind the patient about *'
						multiline
						fullWidth
						focused={false}
						value={formik?.values?.remindPatient}
						inputProps={{ maxLength: 255 }}
						onChange={(e, value) => {
							formik?.setFieldValue('remindPatient', e.target.value)
							setCount(e.target.value.length)
						}}
						size='small'
						rows={5}
						rowsmax={10}
						color='secondary'
						error={formik.touched.remindPatient && formik.errors.remindPatient}
						helperText={formik.touched.remindPatient && formik.errors.remindPatient && formik.errors.remindPatient}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent='space-between'>
				<Grid item lg={3} md={3} sm={12}></Grid>
				<Grid item lg={3} md={3} sm={12}></Grid>
				<Grid item lg={3} md={3} sm={12}></Grid>
				<Grid item lg={3} md={3} sm={12}>
					<Typography className={classes.messageCount}>
						{count}
						<span>(255 characters)</span>
					</Typography>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}
