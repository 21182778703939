import { ACTION_TYPES } from '../../../../constants'

const initialState = {
	isPracticeDetailsCount: null,
}

const PracticeDetails = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.PRACTICE_DETAILS_COUNT: {
			return {
				...state,
				isPracticeDetailsCount: action?.payload,
			}
		}

		default:
			return state
	}
}

export default PracticeDetails
