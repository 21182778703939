import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const temp = typeof window !== 'undefined' ? secureLocalStorage.getItem('AppointmentUuid') : null

const initialState = {
	appointment_Uuid: temp ? temp : {},
	currentApptData: null,
}
const appointmentUuidData = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.APPOINTMENT_ID: {
			return {
				...state,
				appointment_Uuid: action.payload,
			}
		}

		case ACTION_TYPES.CURRENT_APPOINTMENT_DATA: {
			return {
				...state,
				currentApptData: action.payload,
			}
		}

		default:
			return state
	}
}

export default appointmentUuidData
