import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	businessNameContainer: {
		width: '70%',
		paddingInline: 12,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	TextFieldContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	CustomButton: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		borderRadius: 21,
		textTransform: 'none',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		boxShadow: 'none',
		// gap: 4,
		'&:hover': {
			backgroundColor: themesConfig.lyfngo.palette.primary.dark,
		},
	},
	chipContainer: {
		height: '18vh',
		overflow: 'scroll',
		display: 'flex',
		gap: 6,
		flexWrap: 'wrap',
		'& .MuiChip-outlined': {
			border: '2px solid #0062DD',
			color: '#000',
			height: 28,
			backgroundColor: '#fff',
			'& .MuiChip-icon': {
				color: '#0062DD',
			},
		},
		'& .MuiChip-contained': {
			border: '2px solid #0062DD',
			color: '#fff',
			backgroundColor: '#0062DD',
			height: 28,
			'& .MuiChip-icon': {
				color: '#fff',
			},
		},
	},
	chipVisiablityContainer: {
		visibility: 'hidden',
	},
	emptySpecialization: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 500,
	},
	checkboxOwner: {
		display: 'flex',
		alignItems: 'center',
		gap: 6,
		'& .MuiFormControlLabel-root': {
			marginRight: 0,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0062DD',
		},
	},
	checkboxOwnerContainer: {
		display: 'flex',
		gap: 16,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
}))

export default useStyles
