import {
	Grid,
	TextField,
	Typography,
	LinearProgress,
	IconButton,
	Skeleton,
	RadioGroup,
	FormControl,
	FormControlLabel,
	Radio,
	Stack,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import AISiteMapApi from 'services/ProfileSetup/AiSitemap/api'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { getResponseMessage } from 'utils'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CustomBtn from 'app/sharedComponents/Button'
import _ from 'lodash'
import Dropzone from '../../../main/ProfileSetup/StepperView/Dropzone2/dropzone'
import sendUploadFileApi from 'services/fileupload/PostApi'
import fileUploadDetailsApi from 'services/fileupload/GetApi'
import themesConfig from 'app/fuse-configs/themesConfig'
import moment from 'moment'
import { hideLoader, showLoader } from 'services/loader/action'
import ProfileToolTip from 'app/sharedComponents/ProfileToolTip'

const useStyles = makeStyles((theme) => ({
	buttonMain: {
		display: 'flex',
		gap: 16,
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		padding: '30px',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	readyStyle: {
		color: '#8CC63F',
		fontWeight: 600,
	},
	loadingStyle: {
		color: '#0062DD',
		fontWeight: 600,
	},
	failedStyle: {
		color: 'red',
		fontWeight: 600,
	},
	syncContain: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
}))
const AiSiteMapIntegration = ({ history, moveBackTab, moveNextTab }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const errorMessages = useSelector((states) => states?.errorMessages || {})
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const [AISiteMapStatus, setAISiteMapStatus] = useState('')
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState(null)
	const [sync, setSync] = useState(false)
	const [loading, setLoading] = useState(false)
	const [GetSiteMapData, setGetSiteMapData] = useState({})
	const [AibotUrl, setAibotUrl] = useState('')
	const { mastuuid, uuid } = state
	const initialValues = {
		sitemapUrl: '',
		botName: '',
		disclaimerText: null,
		appointment_enabled: true,
		faq_enabled: true,
	}

	const validationSchema = Yup.object({
		sitemapUrl: Yup.string().required('URL is required'),
	})

	const AiSiteMapformik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			// if (setSync === false) {
			postAIbotDetails(values)
			// }
			// postSiteMapURL(values)
		},
	})

	const postSiteMapURL = () => {
		const body = {
			sit_url: AibotUrl,
			mast_tent_uuid: mastuuid,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				getAIStatus()
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		AISiteMapApi.postSiteMapUrl(body).then(onSuccess, onFailure)
	}

	const postAIbotDetails = (value) => {
		const body = {
			chat_bot_name: value?.botName,
			mast_tent_uuid: mastuuid,
			doc_drive_uuid: establishmentFileResponse,
			Disclimer: value?.disclaimerText,
			appointment_enabled: value?.appointment_enabled === 'yes' ? true : false,
			faq_enabled: value?.faq_enabled === 'yes' ? true : false,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
			getAibotDetails()
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		AISiteMapApi.postAiBotDetails(body).then(onSuccess, onFailure)
	}

	const params = { client_id: mastuuid }
	const getAIStatus = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setLoading(false)
				setAISiteMapStatus(res?.data?.data?.status)
				setGetSiteMapData(res?.data?.data)
				AiSiteMapformik.setFieldValue('sitemapUrl', res?.data?.data?.site_url ? res?.data?.data?.site_url : '')
				setAibotUrl(res?.data?.data?.site_url ? res?.data?.data?.site_url : '')
			}
		}
		const onFailure = (err) => {
			setLoading(false)
			console.log('ee', err)
		}
		AISiteMapApi.getAISitemapStatus({ ...params }).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	const getAibotDetails = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				AiSiteMapformik.setFieldValue('botName', res?.data?.data?.chat_bot_name ? res?.data?.data?.chat_bot_name : '')
				AiSiteMapformik.setFieldValue('disclaimerText', res?.data?.data?.Disclimer ? res?.data?.data?.Disclimer : null)
				AiSiteMapformik.setFieldValue('appointment_enabled', res?.data?.data?.appointment_enabled ? 'yes' : 'no')
				AiSiteMapformik.setFieldValue('faq_enabled', res?.data?.data?.faq_enabled ? 'yes' : 'no')
				setEstablishmentFileResponse(res?.data?.data?.doc_drive_uuid || null)
			}
		}
		const onFailure = (err) => {
			setLoading(false)
			console.log('ee', err)
		}
		AISiteMapApi.getAibotDetails(mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])
	useEffect(() => {
		getAIStatus()
		getAibotDetails()
	}, [getAIStatus, getAibotDetails])

	const getFileCategoryList = useCallback(() => {
		if (uuid) {
			const onSuccess = (res) => {
				if (res?.data?.status === 'SUCCESS') {
					setFileCategoryList(res?.data?.data)
				} else {
					setFileCategoryList([])
				}
			}
			const onFailure = (err) => {
				setFileCategoryList([])
			}
			fileUploadDetailsApi.getFileUploadDetails(uuid).then(onSuccess, onFailure)
		}
	}, [uuid])

	useEffect(() => {
		getFileCategoryList()
	}, [getFileCategoryList])

	const displayEstablishmentImage = (acceptedFiles) => {
		dispatch(showLoader('Uploading image please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setEstablishmentFileResponse(res?.data?.data?.uuid)
			} else {
				setEstablishmentFileResponse()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err.response.data, errorMessages, 'Invalid File Name'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'MEMEBERSHIP')
		let categoryUuid = identityCategoryId?.uuid
		var formData2 = new FormData()
		formData2.append('FILE', acceptedFiles[0])
		sendUploadFileApi.sendUploadFile(mastuuid, uuid, categoryUuid, formData2).then(onSuccess, onFailure)
	}

	return (
		<>
			<form onSubmit={AiSiteMapformik.handleSubmit}>
				{loading ? (
					<>
						<Grid container spacing={2} sx={{ alignItems: 'center' }}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
								<Skeleton width={100} height={100} />
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Skeleton width={80} height={40} />
									<Skeleton width={150} height={20} />
									<Skeleton width={150} height={20} />
								</div>
							</Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton width={50} height={40} />
								<Skeleton width={'100%'} height={35} />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton width={50} height={40} />
								<Skeleton width={'100%'} height={35} />
								<Skeleton width={300} height={35} />
							</Grid>
							<Grid item xs={12} sm={5} sx={{ display: 'flex' }} alignItems={'center'}>
								<Skeleton width={250} height={35} />
							</Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex' }}></Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', gap: 24 }}>
								<Skeleton width={100} height={45} style={{ borderRadius: 30 }} />
								<Skeleton width={100} height={45} style={{ borderRadius: 30 }} />
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Grid container spacing={2} sx={{ alignItems: 'center' }}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<Typography className={classes.domainLabel}>AI Bot Profile </Typography>
								<Dropzone
									imgResponse={establishmentFileResponse}
									setEstablishmentFileResponse={setEstablishmentFileResponse}
									displayUploadImage={displayEstablishmentImage}
									dropzonestyle={classes.practiceImage}
								/>
							</Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<Typography className={classes.domainLabel}>Bot Name</Typography>
								<TextField
									size='small'
									fullWidth
									id='botName'
									placeholder='Enter Bot Name'
									value={AiSiteMapformik.values.botName}
									onChange={(e) => AiSiteMapformik.setFieldValue('botName', e.target.value)}
									onBlur={AiSiteMapformik.handleBlur}
									error={AiSiteMapformik.touched.botName && Boolean(AiSiteMapformik.errors.botName)}
									helperText={AiSiteMapformik.touched.botName && AiSiteMapformik.errors.botName}
									color='secondary'
								/>
								<Typography className={classes.domainLabel}>Disclaimer</Typography>
								<TextField
									size='small'
									fullWidth
									id='disclaimerText'
									placeholder='Enter Your Disclaimer Text'
									value={AiSiteMapformik.values.disclaimerText}
									onChange={(e) => AiSiteMapformik.setFieldValue('disclaimerText', e.target.value)}
									onBlur={AiSiteMapformik.handleBlur}
									error={AiSiteMapformik.touched.disclaimerText && Boolean(AiSiteMapformik.errors.disclaimerText)}
									helperText={AiSiteMapformik.touched.disclaimerText && AiSiteMapformik.errors.disclaimerText}
									color='secondary'
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<Stack>
									<Stack direction={'row'} gap={1} alignItems={'center'}>
										<Typography className={classes.domainLabel}>Enable Appointment Booking for your AI bot ?</Typography>
										{/* <ProfileToolTip titleName={`You can opt for booking appointments and human agent chats!`}>
											<span className={classes.aiHelper}>
												<HelpOutlineOutlinedIcon />
											</span>
										</ProfileToolTip> */}
									</Stack>
									<Stack>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-radio-buttons-group-label'
												name='radio-buttons-group'
												value={AiSiteMapformik?.values?.appointment_enabled}
												onChange={(event) => {
													AiSiteMapformik?.setFieldValue('appointment_enabled', event.target.value)
												}}
											>
												<FormControlLabel value={'yes'} control={<Radio />} label='Yes' />
												<FormControlLabel value={'no'} control={<Radio />} label='No' />
											</RadioGroup>
										</FormControl>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<Stack>
									<Stack direction={'row'} gap={1} alignItems={'center'}>
										<Typography className={classes.domainLabel}>Enable FAQ for your AI bot ?</Typography>
									</Stack>
									<Stack>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-radio-buttons-group-label'
												name='radio-buttons-group'
												value={AiSiteMapformik?.values?.faq_enabled}
												onChange={(event) => {
													AiSiteMapformik?.setFieldValue('faq_enabled', event.target.value)
												}}
											>
												<FormControlLabel value={'yes'} control={<Radio />} label='Yes' />
												<FormControlLabel value={'no'} control={<Radio />} label='No' />
											</RadioGroup>
										</FormControl>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<Typography className={classes.domainLabel}>URL *</Typography>
								<TextField
									size='small'
									fullWidth
									id='sitemapUrl'
									placeholder='Enter Your URL'
									value={AiSiteMapformik.values.sitemapUrl}
									onChange={(e) => {
										AiSiteMapformik.setFieldValue('sitemapUrl', e.target.value)
										setAibotUrl(e?.target?.value)
									}}
									onBlur={AiSiteMapformik.handleBlur}
									error={AiSiteMapformik.touched.sitemapUrl && Boolean(AiSiteMapformik.errors.sitemapUrl)}
									helperText={AiSiteMapformik.touched.sitemapUrl && AiSiteMapformik.errors.sitemapUrl}
									color='secondary'
								/>
								{AISiteMapStatus && (
									<>
										{_.isEqual(AISiteMapStatus, 'LOAD_IN_PROGRESS') && (
											<LinearProgress
												sx={{
													'& .MuiLinearProgress-bar': {
														backgroundColor: '#0062DD',
													},
												}}
											/>
										)}
										<Typography variant='subtitle1' fontWeight={500}>
											Status:
											<span
												className={
													_.isEqual(AISiteMapStatus, 'READY')
														? classes?.readyStyle
														: _.isEqual(AISiteMapStatus, 'LOAD_IN_PROGRESS')
														? classes?.loadingStyle
														: _.isEqual(AISiteMapStatus, 'Failed')
														? classes.failedStyle
														: null
												}
											>
												{_.isEqual(AISiteMapStatus, 'READY')
													? ' AI Chatbot is Activated'
													: _.isEqual(AISiteMapStatus, 'LOAD_IN_PROGRESS')
													? ' Loading Please Wait...'
													: _.isEqual(AISiteMapStatus, 'Failed')
													? ' There was an error submitting your URL. Please try again'
													: null}
											</span>
										</Typography>
									</>
								)}
							</Grid>
							{AiSiteMapformik.values.sitemapUrl && (
								<Grid item xs={12} sm={1} sx={{ display: 'flex' }} alignItems={'flex-end'}>
									<CustomBtn onClick={() => postSiteMapURL()}>Load</CustomBtn>
								</Grid>
							)}
							<Grid item xs={12} sm={5} sx={{ display: 'flex' }} alignItems={'flex-end'}>
								{AISiteMapStatus !== 'LOAD_IN_PROGRESS' && AiSiteMapformik.values.sitemapUrl && (
									<div className={classes.syncContain}>
										<IconButton
											onClick={() => {
												setSync(true)
												AiSiteMapformik.handleSubmit()
											}}
										>
											<AutorenewIcon icon={'sync'} style={{ color: themesConfig.greyDark.palette.primary.main }} />
										</IconButton>

										<Typography>Last fetched data on {moment(GetSiteMapData?.update_data?.end_date).format('MMM DD, h:mm a')}</Typography>
									</div>
								)}
							</Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex' }}></Grid>
							<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', gap: 24 }}>
								<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
								<CustomBtn type='submit' onClick={() => setSync(false)}>
									Save & Next
								</CustomBtn>
							</Grid>
						</Grid>
					</>
				)}
			</form>
		</>
	)
}

export default AiSiteMapIntegration
