import { Avatar, Grid, IconButton, Link, Popover, Switch, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import { decryption, getImgUrl, getResponseMessage } from 'utils'
import _ from 'lodash'
import profileStatusApi from '../../../../../services/ProfileSetup/profileStatus/api'
import GetEstablishmentDetails from '../../../../../services/ProfileSetup/EstablishmentDetails/api'
import { ContentCopy, EditNote, Person, PersonalVideo, Public, Smartphone } from '@mui/icons-material'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import { useHistory } from 'react-router'
import { groupUserNameAction, individualTentUserId } from 'services/ProfileSetup/groupUser/actions'
import secureLocalStorage from 'react-secure-storage'
import { setProfileStatus } from 'services/ProfileSetup/list/actions'
import Preview from '../../MicroSetup/Preview'
import MicroSetupApi from 'services/MicroSetup/api'

const careEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Care.svg`
const NonCareEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Non_Care.svg`

const ProfileHeader = ({ listData, getProfileTablelist, userProfileDetails }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const bearer = useSelector((state) => state?.Authentication?.BearerToken)
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid } = state
	const { userName } = state
	const { groupName, docDriveUuid } = mainmenuState
	const isCare = _.isEqual(groupName, 'health care')
	const owner = listData.find((item) => item.isOwner === true) || null
	const [specialization, setSpecialization] = useState([])
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [openPreviewModal, setOpenPreviewModal] = useState(false)
	const [previewData, setPreviewData] = useState({})
	const [specialityDetails, SetspecialityDetails] = useState([])
	const [profileTentList, setProfileTentList] = useState([])
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState([])
	const [selectPreview, setSelectPreview] = useState('web')

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform: 'translateX(16px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			},
			'&.Mui-disabled .MuiSwitch-thumb': {
				color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 22,
			height: 22,
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	}))

	const handleCopyContent = (previewData) => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)
		navigator.clipboard.writeText(`https://${userProfileDetails.domainName}`)
	}

	const saveActiveStatus = (row, e) => {
		const body = {
			profileHeaderUuid: row?.profileHeaderUuid,
			isActive: e.target.checked,
		}
		dispatch(showLoader('Updating please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				getProfileTablelist()
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		profileStatusApi.updateProfileToggleStatus(body).then(onSuccess, onFailure)
	}

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setSpecialization(res?.data?.data?.specialitesDTOS || [])
		}
		const onFailure = (res) => {
			dispatch(hideLoader())
		}
		GetEstablishmentDetails.getEstablishmentDetails(mastuuid).then(onSuccess, onFailure)
	}, [dispatch, mastuuid])

	const onClickEdit = (row, e) => {
		e.stopPropagation()
		dispatch(
			groupUserNameAction({
				groupUserName: _.toLower(row?.role),
			})
		)
		dispatch(individualTentUserId(row?.tentUserUuid))
		secureLocalStorage.setItem('groupUserName', _.toLower(row?.role) || '')
		secureLocalStorage.setItem('profileCurrentTentUserId', row?.tentUserUuid || '')
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(setProfileStatus(res?.data?.data || {}))
			secureLocalStorage.setItem('profileStatus', JSON.stringify(res?.data?.data || {}))
			if (!_.isEqual(_.toLower(row?.role), 'owner')) {
				history.push('/profilesetup?tabId=personalDetails')
			} else {
				history.push('/profilesetup?tabId=microsetup')
			}
			// const { action } = row
			// history.push(`profilesetup/edit/${action}/${mastuuid}`)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		const isNotOwner = !_.isEqual(_.toLower(row?.role), 'owner')
		profileStatusApi.getProfileStatus(row?.profileHeaderUuid, mastuuid, isNotOwner, { isOwner: row?.isOwner }).then(onSuccess, onFailure)
	}

	const onPreviewModalClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpenPreviewModal(false)
		}
	}

	const getMicroSiteDta = () => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setPreviewData(res?.data?.data)
			setEstablishmentFileResponse(res?.data?.data?.banner?.docDriveUuid || [])
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			setPreviewData({})
		}
		MicroSetupApi.getSiteData(mastuuid, uuid).then(onSuccess, onFailure)
	}

	useEffect(() => {
		getMicroSiteDta()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	const GetSpecialityDetails = () => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			const successData = decryption(res)
			if (successData.status === 'success') {
				SetspecialityDetails(successData.data)
			}
		}
		const onFailure = (err) => {
			SetspecialityDetails([])
			dispatch(hideLoader())
		}
		MicroSetupApi.getSpecialityDetails(mastuuid).then(onSuccess, onFailure)
	}

	const getProfileTentUserList = () => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				setProfileTentList(successData?.data?.tentUserServiceDTO)
			}
		}
		const onFailure = (err) => {
			setProfileTentList([])
		}
		MicroSetupApi.getProfileTentUserDetails(mastuuid, {
			getSpecialist: 'All Specialists',
			userName: '',
			getFacility: 'All Facilities',
		}).then(onSuccess, onFailure)
	}

	const previewFormik = {
		values: {
			colorCode: previewData?.themeColor || '',
			email: previewData?.domainName || '',
			copyright: previewData?.copyright || '',
			fb: previewData?.media?.fb || '',
			twitter: previewData?.media?.x || '',
			insta: previewData?.media?.insta || '',
			thread: previewData?.media?.thread || '',
			youtube: previewData?.media?.youtube || '',
			linkedin: previewData?.media?.linkedin || '',
			whatsApp: previewData?.media?.whatsApp || '',
		},
	}

	return (
		<Grid container className={classes.headerContainer}>
			<Preview
				openPreviewModal={openPreviewModal}
				onPreviewModalClose={onPreviewModalClose}
				previewData={previewData}
				formik={previewFormik}
				specialityDetails={specialityDetails}
				profileTentList={profileTentList}
				establishmentFileResponse={establishmentFileResponse}
				selectPreview={selectPreview}
				setSelectPreview={setSelectPreview}
				displayDomain={true}
			/>
			<Grid item md={6.5} sm={6} className={classes.leftHead}>
				<div className={classes.leftHeadTop}>
					<div className={classes.mainLogo}>
						{docDriveUuid ? (
							<Avatar src={getImgUrl(docDriveUuid, bearer)} alt={mainmenuState.tentName} variant='circular' />
						) : (
							<Avatar className='md:mx-4 w-40 h-40' src={isCare ? careEmptyIcon : NonCareEmptyIcon} alt={userName} />
						)}
					</div>
					<div className={classes.leftHeadSpecialization}>
						<Typography className={classes.tentName} sx={{ fontSize: '1.5rem' }}>
							{mainmenuState?.tentName || ''}
						</Typography>
					</div>
				</div>
				<div className=''>
					{specialization.length ? (
						<>
							<Typography className={classes.specialization} pt={1}>
								Specialisation
								<span className={classes.specializationCategories}>
									{specialization.slice(0, 4).map((item, index) => (
										<React.Fragment key={index}>
											{index > 0 && ', '}
											{item.specialityName}
										</React.Fragment>
									))}
								</span>
								{specialization.length > 4 && (
									<>
										<Typography
											className={classes.specializationCategoriesMore}
											aria-owns={open ? 'mouse-over-popover' : undefined}
											aria-haspopup='true'
											onMouseEnter={handlePopoverOpen}
											onMouseLeave={handlePopoverClose}
										>
											+{specialization.length - 4} More
										</Typography>
										<Popover
											id='mouse-over-popover'
											sx={{
												pointerEvents: 'none',
											}}
											open={open}
											anchorEl={anchorEl}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left',
											}}
											onClose={handlePopoverClose}
											disableRestoreFocus
										>
											{specialization.slice(4, specialization.length).map((item, index) => (
												<div className={classes.specializationCategoriesMoreListitem} key={index}>
													{item.specialityName}
												</div>
											))}
										</Popover>
									</>
								)}
							</Typography>
						</>
					) : (
						' '
					)}
				</div>
			</Grid>
			<Grid item md={4} sm={4.5} className={classes.middleHead}>
				<div className={classes.middleHeadRow}>
					<div className={classes.middleHeadNav}>
						<IconButton disabled size='small' className={classes.middleHeadIcon}>
							<Person />
						</IconButton>
						<Typography className={classes.middleHeadNavDesc}>{userProfileDetails.profilesSize} Profiles</Typography>
					</div>
					<div className={classes.middleHeadNav}>
						<IconButton
							size='small'
							className={classes.middleHeadIcon}
							onClick={() => {
								setOpenPreviewModal(true)
								setSelectPreview('web')
								GetSpecialityDetails()
								getProfileTentUserList()
							}}
						>
							<PersonalVideo />
						</IconButton>
						<IconButton
							size='small'
							className={classes.middleHeadPreviewIcon}
							onClick={() => {
								setOpenPreviewModal(true)
								setSelectPreview('mobile')
								GetSpecialityDetails()
								getProfileTentUserList()
							}}
						>
							<Smartphone />
						</IconButton>
						<Typography className={classes.middleHeadNavDesc}>Preview</Typography>
					</div>
					{owner && (
						<div className={classes.middleHeadNav}>
							<Typography className={classes.middleHeadNavDesc}>{owner.isActive ? 'Active' : 'InActive'}</Typography>
							<IOSSwitch defaultChecked={owner.isActive} onChange={(e) => saveActiveStatus(owner, e)} />
						</div>
					)}
				</div>
				{userProfileDetails.domainName && (
					<div className={classes.middleHeadNav} style={{ marginTop: '6px' }}>
						<IconButton disabled size='small' className={classes.middleHeadIcon}>
							<Public />
						</IconButton>
						<Link href={`https://${userProfileDetails.domainName}`} target='_blank' className={classes.middleHeadNavLink}>
							<Typography className={classes.middleHeadNavLink}>
								https://www.
								{userProfileDetails?.domainName.length > 25
									? `${userProfileDetails?.domainName.substring(0, 25)}...`
									: userProfileDetails?.domainName}
							</Typography>
						</Link>
						<IconButton onClick={handleCopyContent}>
							<ContentCopy className={classes.ContentCopyIcon} />
						</IconButton>
					</div>
				)}
			</Grid>
			<Grid item xs={2.5} sm={1.5} className={classes.rightHead}>
				<div className={classes.actionIcons}>
					<Tooltip title='Edit' arrow>
						<IconButton size='small' disabled={owner ? false : true} onClick={(e) => onClickEdit(owner, e)}>
							<EditNote />
						</IconButton>
					</Tooltip>
				</div>
			</Grid>
		</Grid>
	)
}

export default ProfileHeader
