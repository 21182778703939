import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import secureLocalStorage from 'react-secure-storage'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFileUploadDetails: () => {
		return axios.get(API_ENDPOINTS.FILE_CATEGORY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	deleteProfileFile: (driveUuid, tentUserUuid) => {
		// const tentUserUuid = secureLocalStorage.getItem('uuid')
		return axios.delete(`${API_ENDPOINTS.FILE_DELETE}/${driveUuid}`, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf() },
			data: {
				type: 'TNT',
				uuid: tentUserUuid,
			},
		})
	},
	deleteOwnerProfile: (driveUuid, tentUserUuid) => {
		return axios.delete(`${API_ENDPOINTS.FILE_DELETE}/${driveUuid}`, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf() },
			data: {
				type: 'TNT',
				uuid: tentUserUuid,
			},
		})
	},
	deleteFile: (driveUuid) => {
		const tentUserUuid = secureLocalStorage.getItem('uuid')
		return axios.delete(`${API_ENDPOINTS.FILE_DELETE}/${driveUuid}`, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf() },
			data: {
				type: 'TNT',
				uuid: tentUserUuid,
			},
		})
	},
}
