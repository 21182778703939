// RichTextEditor.js
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import the styles
import 'react-quill/dist/quill.bubble.css'
import './RichTextEditor.css'
import { FormControl, InputLabel } from '@mui/material'
import _ from 'lodash'

const RichTextEditor = ({ value, onChange, theme, label, placeholder, disabled, maxLength, image }) => {
	const [characterCount, setCharacterCount] = useState(0)

	const modules = {
		toolbar: [
			[
				{ header: '1' },
				{ header: '2' },
				// { font: [] }
			],
			[{ size: [] }],
			[
				'bold',
				'italic',
				'underline',
				'strike',
				//  'blockquote'
			],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			[
				// 'link',
				image && 'image',
				//  'video'
			],
			// [{ font: [] }],
			// [{ align: [] }],
			[{ color: [] }, { background: [] }],
			['clean'],
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	}

	const formats = [
		'header',
		'font',
		'size',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'color',
		'background',
		// 'link',
		image && 'image',
		// 'video',
	]

	const handleChange = (content, delta, source, editor) => {
		let text = editor.getText().trim()
		let html = editor.getHTML()

		const tempDiv = document.createElement('div')
		tempDiv.innerHTML = html

		const imgTagExists = tempDiv.getElementsByTagName('img').length > 0

		if (text || imgTagExists) {
			if (!disabled && (!maxLength || text.length <= maxLength)) {
				onChange(html)
			}
		} else {
			onChange('')
		}
	}

	useEffect(() => {
		if (value) {
			const tempDiv = document.createElement('div')
			tempDiv.innerHTML = value
			const text = tempDiv.textContent || tempDiv.innerText || ''
			const imgTagExists = tempDiv.getElementsByTagName('img').length
			const totalLength = text.length + imgTagExists
			setCharacterCount(totalLength)
		} else if (_.isEmpty(value)) {
			setCharacterCount(0)
		}
	}, [value])

	return (
		<FormControl className='custom-editor-container' variant='outlined' fullWidth>
			{label && <InputLabel shrink>{label}</InputLabel>}
			<ReactQuill
				className='custom-editor'
				placeholder={placeholder}
				value={value}
				onChange={handleChange}
				modules={modules}
				formats={formats}
				theme={theme}
				readOnly={disabled}
			/>
			{maxLength && <p className='counter'>{`${characterCount}/${maxLength}`}</p>}
		</FormControl>
	)
}

export default RichTextEditor
