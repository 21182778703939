// MyLocation.js
import React, { useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { makeStyles } from '@mui/styles'
import { useGoogleMaps } from 'app/sharedComponents/GoogleMapsProvider'

const useStyles = makeStyles((theme) => ({
	containerStyle: {
		[theme.breakpoints.down('sm')]: {
			minHeight: '200px',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '535px',
			minHeight: '200px',
		},
		[theme.breakpoints.up('md')]: {
			minWidth: '535px',
			minHeight: '200px',
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: '465px',
			minHeight: (props) => (props?.height ? props?.height : '200px'),
		},
	},
}))

function MyLocation(props) {
	const { zoom, center, onDragEnded, height } = props
	const classes = useStyles({ height })
	const [markerPosition, setMarkerPosition] = useState(center)
	const { isLoaded } = useGoogleMaps()

	const onLoad = (marker) => console.log('Test marker----', marker, center)

	if (!isLoaded) {
		return <div>Loading...</div>
	}

	return (
		<GoogleMap mapContainerClassName={classes.containerStyle} center={center} zoom={zoom}>
			<Marker onLoad={onLoad} position={center} draggable={true} onDragEnd={onDragEnded} />
		</GoogleMap>
	)
}
export default React.memo(MyLocation)
