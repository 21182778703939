import { Typography } from '@mui/material'

function PrimePage() {
	return (
		<div>
			<Typography variant='h5'>Prime</Typography>
		</div>
	)
}

export default PrimePage
