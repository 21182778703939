import { ACTION_TYPES } from '../../../constants'

const initialState = {
	getNotification: [],
	trigger: false,
	cHead: null,
	allActive: [],
	attendingActive: [],
	idleActive: [],
}
const sseListenReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SSE_LISTENER: {
			return {
				...state,
				getNotification: action.payload,
			}
		}
		case ACTION_TYPES.SOCKET_ALERT_CONNECTION: {
			return {
				...state,
				trigger: action.payload,
			}
		}
		case ACTION_TYPES.SOCKET_CONNECTED_CHEAD: {
			return {
				...state,
				cHead: action.payload,
			}
		}
		// case ACTION_TYPES.CHAT_ALL_ACTIVE: {
		// 	return {
		// 		...state,
		// 		allActive: action.payload,
		// 	}
		// }
		// case ACTION_TYPES.CHAT_ATTENDING_ACTIVE: {
		// 	return {
		// 		...state,
		// 		attendingActive: action.payload,
		// 	}
		// }
		// case ACTION_TYPES.CHAT_IDLE_ACTIVE: {
		// 	return {
		// 		...state,
		// 		idleActive: action.payload,
		// 	}
		// }
		default:
			return state
	}
}

export default sseListenReducer
