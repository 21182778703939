import { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Popover, IconButton, Tooltip, Box, Grid } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import _ from 'lodash'
import themesConfig from 'app/fuse-configs/themesConfig'
import FullscreenToggle from '../FullscreenToggle'
import history from '@history'
import { usePermissions } from 'hooks/usePermissions'
import { FEATURES_LIST, PERMISSION_TYPE, ROLE_MODULES } from '../../../constants'

const useStyles = makeStyles((theme) => ({
	rootPopper: {
		'& .MuiPopover-paper': {
			width: 240,
		},
	},

	boxContainer: {
		paddingInline: 12,
		'& .MuiGrid-item': {
			textAlign: 'center',
			borderRadius: 10,
			margin: 4,
			'&:hover': {
				background: '#0062DD20',
				cursor: 'pointer',
			},
		},
	},

	badge: {
		'& .MuiBadge-colorSecondary': {
			background: themesConfig.lyfngo.palette.secondary.main,
			color: themesConfig.lyfngo.palette.light.main,
		},
	},

	iconBtn: {
		'&:hover .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.primary.main,
		},
		'&:hover': {
			// background: themesConfig.lyfngo.palette.light.main,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 'lighter',
			'&:hover': {
				color: themesConfig.lyfngo.palette.primary.main,
			},
		},
	},
	optionBtn: {
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 'lighter',
		},
	},
}))

function MoreOptionsHeader({ restart, tourRestart }) {
	const classes = useStyles()

	const [userMenu, setUserMenu] = useState(null)
	const walletModulePermission = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.WALLET)

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget)
	}

	const userMenuClose = () => {
		setUserMenu(null)
	}

	return (
		<>
			<Tooltip title='More options' followCursor>
				<IconButton onClick={userMenuClick} className={classes.iconBtn} size='large'>
					<AppsIcon />
				</IconButton>
			</Tooltip>
			<Popover
				open={Boolean(userMenu)}
				className={classes.rootPopper}
				anchorEl={userMenu}
				onClose={userMenuClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
					},
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-6',
				}}
			>
				<Box className={classes.boxContainer}>
					<Grid container xs={12}>
						<Tooltip title={restart ? 'Restart the tour' : 'Help tour'} followCursor>
							<Grid item xs={3.5} onClick={() => tourRestart('start')}>
								<IconButton className={classes.optionBtn}>
									<AutoStoriesOutlinedIcon />
								</IconButton>
							</Grid>
						</Tooltip>

						<Grid item xs={3.5}>
							<FullscreenToggle />
						</Grid>

						<Grid item xs={3.5} onClick={() => history.push('/helpcenter')}>
							<Tooltip title='Help center' followCursor>
								<IconButton className={classes.optionBtn}>
									<LiveHelpOutlinedIcon />
								</IconButton>
							</Tooltip>
						</Grid>

						{_.get(walletModulePermission, PERMISSION_TYPE.VIEW, false) && (
							<Tooltip title='Wallet' followCursor>
								<Grid item xs={3.5} onClick={() => history.push('/wallet')}>
									<IconButton className={classes.optionBtn}>
										<AccountBalanceWalletOutlinedIcon disabled={history.location.pathname === '/wallet'} />
									</IconButton>
								</Grid>
							</Tooltip>
						)}
					</Grid>
				</Box>
			</Popover>
		</>
	)
}
export default MoreOptionsHeader
