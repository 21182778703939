import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Avatar } from '@mui/material'
import { showLoader, hideLoader } from '../../../../../services/loader/action'
import awardDetailsApi from '../../../../../services/ProfileSetup/AwardsDetails/api'
import _ from 'lodash'
import { getImgUrl } from 'utils'
import Button from '../../../../sharedComponents/Button'
import useStyles from './style'

const AwardsViewMode = ({ match, history }) => {
	const classes = useStyles()
	const [awardDetails, setAwardDetails] = useState([])
	const [memberDetails, setMemberDetails] = useState([])
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { uuid, userType, BearerToken } = state
	const mastUuid = _.get(match, 'params.mastuuid', '')
	const Uuid = _.get(match, 'params.uuid', '')
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || '')

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			let tempAwardsArr = []
			let tempMemArr = []
			if (res?.data?.status === 'success') {
				if (!_.isEmpty(res?.data?.data?.awardsList || [])) {
					_.map(res?.data?.data?.awardsList || [], (item) => {
						tempAwardsArr.push({
							awards: item?.awardName,
							awardYear: item?.awardYear,
							attachment: item?.supportingDocUuid.pop(),
							uuid: item?.profileAwardUuid,
						})
					})
				}
				if (!_.isEmpty(res?.data?.data?.membershipList || [])) {
					_.map(res?.data?.data?.membershipList || [], (item) => {
						tempMemArr.push({
							member: item?.membership,
							attachment: item?.supportingDocUuid.pop(),
							uuid: item?.profileAwardUuid,
						})
					})
				}
				setAwardDetails(tempAwardsArr)
				setMemberDetails(tempMemArr)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		awardDetailsApi.AwardDetails(uuid, userType).then(onSuccess, onFailure)
	}, [dispatch, userType, uuid])

	const editProfileNavigation = () => {
		history.push(`/profilesetup/edit/${Uuid}/${mastUuid}?tabId=awardsAndRec`)
	}
	return (
		<Grid container className={classes.mainContainer}>
			<Grid item lg={11} sm={12}>
				{!_.isEmpty(awardDetails) && (
					<Typography variant='h6' fontWeight='medium'>
						Awards
					</Typography>
				)}
				{awardDetails.map((item) => {
					return (
						<Grid container pt={1}>
							<Grid item container className={classes.container}>
								<Grid item lg={2}>
									<Typography className={classes.label}>Award name</Typography>
								</Grid>
								<Grid item lg={3}>
									<Typography className={classes.value}>{item.awards}</Typography>
								</Grid>
							</Grid>
							<Grid item container pt={1} className={classes.container}>
								<Grid item lg={2}>
									<Typography className={classes.label}>Year</Typography>
								</Grid>
								<Grid item lg={3}>
									<Typography className={classes.value}>{item.awardYear}</Typography>
								</Grid>
							</Grid>
							<Grid container pt={1}>
								<Avatar variant='square' src={getImgUrl(item?.attachment, BearerToken)} className={classes.uploadedImages} />
							</Grid>
						</Grid>
					)
				})}
				{!_.isEmpty(memberDetails) && (
					<Typography variant='h6' fontWeight='medium' pt={2}>
						Members
					</Typography>
				)}
				{memberDetails.map((item) => {
					return (
						<Grid container>
							<Grid item pt={1} container className={classes.container}>
								<Grid item lg={2}>
									<Typography className={classes.label}>Member name</Typography>
								</Grid>
								<Grid item lg={3}>
									<Typography className={classes.value}>{item.member}</Typography>
								</Grid>
							</Grid>
							<Grid container pt={1}>
								<Avatar variant='square' src={getImgUrl(item?.attachment, BearerToken)} className={classes.uploadedImages} />
							</Grid>
						</Grid>
					)
				})}
			</Grid>
			{progressStatus !== 'SUB' ? (
				<Grid item lg={1}>
					<Button
						onClick={() => {
							editProfileNavigation()
						}}
					>
						Edit Profile
					</Button>
				</Grid>
			) : null}
		</Grid>
	)
}

export default AwardsViewMode
