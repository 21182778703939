import React, { useEffect, useReducer, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { IconButton, Typography, Tooltip, Skeleton, Avatar, Icon, TablePagination } from '@mui/material'
import useStyles from './style'
import MessageModal from './MessageModal'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import smsApi from '../../../../../services/Communication/api'
import { initialState, reducer } from './reducer'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage, customDateFormat, capitalizeTemplateName } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import moment from 'moment'
import { closePrompt, showPrompt } from 'services/Prompt/actions'
import ViewDetails from './ViewDetails'
import ViewTemplateDetails from './ViewTemplateDetails'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { useRef } from 'react'
import consultApi from 'services/fileupload/PostApi'
import { clickLeadsDetails, clickPatientDetails } from 'services/sidemenu/action'
import { getWhatsappTemplate } from 'services/WhatsappCenter/action'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import secureLocalStorage from 'react-secure-storage'

const loadingRow = _.map(_.range(7), (item, idx) => ({
	id: idx + 1,
	recipients: '',
	message: '',
	deliveryTime: '',
	smsdelivery: '',
}))
const loadingColumn = [
	{
		field: 'templateName',
		headerName: 'Template Name',
		minWidth: 190,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'deliveryTime',
		headerName: 'Delivery date & time',
		minWidth: 180,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'type',
		headerName: 'Type',
		minWidth: 150,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'status',
		headerName: 'Status',
		minWidth: 150,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'sent',
		headerName: 'Sent',
		minWidth: 150,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'delivered',
		headerName: 'Delivered',
		minWidth: 120,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'seen',
		headerName: 'Seen',
		minWidth: 200,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
	{
		field: 'actions',
		headerName: 'Actions',
		minWidth: 120,
		renderCell: (params) => <Skeleton width='90%' height='40px' />,
	},
]

function PracticeListTable(props) {
	const classes = useStyles()
	const { openModal, handleClose, setTemplateType, templateType, viewWhatsappMsg, setViewWhatsappMsgText, setOpenSms, isView, history, match } = props
	const reduxDispatch = useDispatch()
	// const [pageSize, setPageSize] = React.useState(10)
	const userData = useSelector((state) => state?.Authentication)
	const { mastuuid, uuid } = userData
	const { groupName } = useSelector((state) => state?.dynamicMenu?.userData || {})
	const isCare = _.isEqual(groupName, 'health care')
	const [state, dispatch] = useReducer(reducer, initialState)
	const [templateOption, setTemplateOption] = useState([])
	const [loading, setLoading] = React.useState(false)
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	const [whatsAppUserList, setWhatsappUserList] = useState([])
	const errorJson = useSelector((state) => state?.errorMessages)
	const [metaTemplateVal, setMetaTemplateVal] = useState({
		template_name: '',
		content: '',
	})
	// selecting all patients/clients state
	const [checkAll, setCheckAll] = useState(false)
	const [patientsData, setPatientsData] = useState(null)
	const [patientSearchValue, setPatientSearchValue] = useState('')
	const [patientSearchParams, setPatientSearchParams] = useState({
		name: null,
	})
	const [message, setMessage] = useState([])
	const [headerMessage, setHeaderMessage] = useState([])
	const fileInputRef = useRef(null)
	const [mediaUuid, setMediaUuid] = useState('')
	const [open, setOpen] = useState(false)
	const [viewMsg, setViewMsg] = useState({})

	const handleFileChange = (files) => {
		let formData = new FormData()
		let uploadedData = files.target.files
		_.map(uploadedData, (file) => {
			formData.append('FILE', file)
		})
		reduxDispatch(showLoader('Loading please wait...'))
		const onSucces = (res) => {
			reduxDispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setMediaUuid(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			reduxDispatch(hideLoader())
			reduxDispatch(
				showMessage({
					message: err?.response?.data,
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		consultApi.sendUploadFile(mastuuid, uuid, 'y3o838wt', formData).then(onSucces, onFailure)
	}

	const handleAvatarClick = () => {
		fileInputRef.current.click()
	}

	const removeMediaUuid = () => {
		setMediaUuid('')
	}

	// ############################################################################################
	// NEW PAGINATION CODE
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [totalCount, setTotalCount] = useState(0)

	// console.log('rowsPerPage', rowsPerPage, 'page', page)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value))
		setPage(0)
	}

	// ############################################################################################

	const onTemplateChange = (event) => {
		setTemplateType(event.target.value)
		setCheckAll(false)
		dispatch({ type: 'resetState' })
		setMediaUuid('')
	}

	const getSmsList = () => {
		let params = {
			page: page,
			limit: rowsPerPage,
		}
		setLoading(true)
		const onSuccess = (res) => {
			setWhatsappUserList(res?.data?.data || [])
			setTotalCount(res?.data?.totalCount || 0)
			setLoading(false)
		}
		const onFailure = (err) => {
			setWhatsappUserList([])
			setLoading(false)
		}
		smsApi.sentWhatsAppMessages(mastuuid, params).then(onSuccess, onFailure)
	}

	useEffect(() => {
		getSmsList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, page, rowsPerPage])

	useEffect(() => {
		if (openModal && isView === false && mastuuid) {
			reduxDispatch(showLoader('Loading templates please wait...'))
			const onSuccess = (res) => {
				reduxDispatch(hideLoader())
				if (res?.data?.Status === 'success') {
					setTemplateOption(res?.data?.data)
				}
			}
			const onFailure = (err) => {
				reduxDispatch(hideLoader())
			}
			smsApi.getWhatsappTemplate(mastuuid).then(onSuccess, onFailure)
		}
	}, [isView, openModal, mastuuid, reduxDispatch])

	const rows = _.map(whatsAppUserList || [], (item, idx) => {
		return {
			id: idx + 1,
			templateName: item?.templateName || '',
			deliveryTime: `${item?.created_on ? customDateFormat(item?.created_on, dateFormatForViewer) : '-'}  & ${moment(`${item?.created_on}`).format(
				'LT'
			)}`,
			type: item?.type,
			sent: item?.sent,
			delivered: item?.delivered,
			seen: item?.seen,
			failed: item?.failed,
			triggered: item?.totalTrigger,
			success: item?.success,
			status: item?.status || [],
			edit: item?.senderId || '',
			mainId: item?.mainId,
			message: item?.Message || [],
			groupName: item?.tent_group_name,
			messageStatus: item?.messageStatus,
			body: item?.body,
			headerMedia: item?.headerMedia,
		}
	})

	const columns = [
		{
			field: 'templateName',
			headerName: 'Template Name',
			minWidth: 200,
			sortable: true,
			filterable: false,
			cellClassName: 'AlignLeftSide',
			renderCell: (params) => (
				<Tooltip title={capitalizeTemplateName(params.value)} arrow>
					<p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{capitalizeTemplateName(params.value)}</p>
				</Tooltip>
			),
		},
		{
			field: 'deliveryTime',
			headerName: 'Delivery Date & Time',
			sortable: true,
			filterable: false,
			minWidth: 190,
		},
		{
			field: 'type',
			headerName: 'Type',
			sortable: true,
			filterable: false,
			minWidth: 120,
		},
		{
			field: 'triggered',
			headerName: 'Triggered',
			sortable: true,
			filterable: false,
			minWidth: 120,
			renderCell: (params) => <div style={{ color: '#000', fontWeight: 600 }}>{params.value}</div>,
		},
		{
			field: 'sent',
			headerName: 'Sent',
			sortable: true,
			filterable: false,
			minWidth: 120,
			renderCell: (params) => <div style={{ color: '#000', fontWeight: 600 }}>{params.value}</div>,
		},
		{
			field: 'delivered',
			headerName: 'Delivered',
			sortable: true,
			filterable: false,
			minWidth: 120,
			cellClassName: 'AlignLeftSide',
			renderCell: (params) => <div style={{ color: '#1D74E1', fontWeight: 600 }}>{params.value}</div>,
			// renderCell: (params) => (
			// 	<div>
			// 		<Typography variant='body1'>
			// 			<span>
			// 				<FiberManualRecordIcon
			// 					style={{
			// 						fontSize: 14,
			// 						color: params?.value === 'failed' ? 'red' : '#1AC698',
			// 						paddingRight: 4,
			// 					}}
			// 				/>
			// 			</span>
			// 			{params?.value === 'failed' ? 'Failed' : 'Success'}
			// 		</Typography>
			// 	</div>
			// ),
		},
		{
			field: 'seen',
			headerName: 'Seen',
			sortable: true,
			filterable: false,
			minWidth: 120,
			renderCell: (params) => <div style={{ color: '#29EA5F', fontWeight: 700 }}>{params.value}</div>,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			filterable: false,
			minWidth: 250,
			renderCell: (params) => (
				<div style={{ display: 'flex', gap: 4, justifyContent: 'flex-start' }}>
					{params?.row?.messageStatus === 'pending' ? (
						<Typography variant='body1' sx={{ fontWeight: 500, paddingRight: 4 }}>
							<span>
								<FiberManualRecordIcon
									style={{
										fontSize: 18,
										color: '#F2711C',
										paddingRight: 4,
									}}
								/>
							</span>
							{'In-Progress '}
						</Typography>
					) : (
						<>
							{params?.row?.success >= 0 && (
								<Typography variant='body1' sx={{ fontWeight: 500, paddingRight: 4 }}>
									<span>
										<FiberManualRecordIcon
											style={{
												fontSize: 18,
												color: '#29EA5F',
												paddingRight: 4,
											}}
										/>
									</span>
									{'Success ' + params?.row?.success}
								</Typography>
							)}
							{params?.row?.failed >= 0 && (
								<Typography variant='body1' sx={{ fontWeight: 500 }}>
									<span>
										<FiberManualRecordIcon
											style={{
												fontSize: 18,
												color: '#FC6E6E',
												paddingRight: 4,
											}}
										/>
									</span>
									{'Failed ' + params?.row?.failed}
								</Typography>
							)}
						</>
					)}
				</div>
			),
		},
		{
			field: 'actions',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			cellClassName: 'AlignLeftSide',
			minWidth: 120,
			renderCell: (params) => {
				return (
					<div style={{ display: 'flex', gap: 6 }}>
						<Tooltip title='View Template' arrow>
							<IconButton
								size='medium'
								className={classes.iconView}
								onClick={() => {
									setOpen(true)
									setViewMsg(params?.row)
								}}
							>
								<VisibilityRoundedIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title='Delete' arrow>
							<IconButton
								size='medium'
								className={classes.iconDelete}
								onClick={(e) =>
									reduxDispatch(
										showPrompt(
											'Are you sure you want to delete?',
											'Yes',
											'No',
											() => onClickDelete(params?.row, e),
											() => reduxDispatch(closePrompt())
										)
									)
								}
							>
								<Icon fontSize='medium'>delete</Icon>
							</IconButton>
						</Tooltip>
					</div>
				)
			},
		},
	]

	const handleOnCellClick = (params) => {
		setViewWhatsappMsgText(params?.row)
		// setIsView(true)
		// _.isEqual(params.field, 'actions') ? setOpenSms(false) : setOpenSms(true)
		secureLocalStorage.setItem('selectedTemplate', params?.row)
		reduxDispatch(getWhatsappTemplate(params?.row))
		!_.isEqual(params?.field, 'actions') && history.replace(`/communication/template/view/${params?.row?.mainId}`)
	}

	const onClickDelete = (data) => {
		reduxDispatch(showLoader('Loading templates please wait...'))
		const onSuccess = () => {
			reduxDispatch(closePrompt())
			reduxDispatch(hideLoader())
			getSmsList()
		}
		const onFailure = () => reduxDispatch(hideLoader())
		smsApi.deleteTemplateMsg(data?.mainId).then(onSuccess, onFailure)
	}

	//Resend message
	// const onClickResend = (item) => {
	// 	let id = 'Groups' === item?.type ? { GroupID: [item?.edit] } : 'Staff' === item?.type ? { TentUID: [item?.edit] } : { CustID: [item?.edit] }
	// 	let groupSeperated = isCare ? 'Patient' : 'Client'
	// 	const body = {
	// 		type: 'Groups' === item?.type ? item?.type : item?.type === 'Staff' ? 'Staff' : groupSeperated,
	// 		...id,
	// 		Mast_tenID: mastuuid,
	// 		body: item?.message[item?.message?.length - 1]?.body,
	// 		templateName: item?.message[item?.message?.length - 1]?.templateName,
	// 		msg_body_type: item?.message[item?.message?.length - 1]?.msg_body_type,
	// 		lang: item?.message[item?.message?.length - 1]?.lang,
	// 		headerMedia: {
	// 			headerType: item?.message[item?.message?.length - 1]?.headerMedia?.headerType || '',
	// 			headerUrl: item?.message[item?.message?.length - 1]?.headerMedia?.headerUrl || '',
	// 			headerVariables: item?.message[item?.message?.length - 1]?.headerMedia?.headerVariables || '',
	// 			headerText: item?.message[item?.message?.length - 1]?.headerMedia?.headerText || '',
	// 		},
	// 	}
	// 	reduxDispatch(showLoader('Sending Messages please wait...'))
	// 	const onSuccess = (res) => {
	// 		reduxDispatch(hideLoader())
	// 		if (res?.data?.stats === 'sucess') {
	// 			reduxDispatch(closePrompt())
	// 			reduxDispatch(clickLeadsDetails({}))
	// 			reduxDispatch(clickPatientDetails({}))
	// 			getSmsList()
	// 			reduxDispatch(
	// 				showMessage({
	// 					message: getResponseMessage(res?.data, errorJson, 'Message sent successfully'),
	// 					autoHideDuration: 3000,
	// 					anchorOrigin: {
	// 						vertical: 'top',
	// 						horizontal: 'right',
	// 					},
	// 					variant: 'success',
	// 				})
	// 			)
	// 		} else {
	// 			reduxDispatch(
	// 				showMessage({
	// 					message: getResponseMessage(res?.data, errorJson),
	// 					autoHideDuration: 3000,
	// 					anchorOrigin: {
	// 						vertical: 'top',
	// 						horizontal: 'right',
	// 					},
	// 					variant: 'error',
	// 				})
	// 			)
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		reduxDispatch(hideLoader())
	// 		reduxDispatch(
	// 			showMessage({
	// 				message: getResponseMessage(err?.response?.data, errorJson),
	// 				autoHideDuration: 3000,
	// 				anchorOrigin: {
	// 					vertical: 'top',
	// 					horizontal: 'right',
	// 				},
	// 				variant: 'error',
	// 			})
	// 		)
	// 	}
	// 	smsApi.sendWhatsAppMessage(body).then(onSuccess, onFailure)
	// }

	const onSubmit = (e) => {
		let ids
		if ((_.isEqual(templateType, 'Patients') || _.isEqual(templateType, 'Clients')) && _.isEqual(checkAll, true)) {
			ids = { CustID: [patientsData?.totalCount] }
		} else if (_.isEqual(templateType, 'Patients') || _.isEqual(templateType, 'Clients')) {
			ids = { CustID: _.map(state?.patientList, (patient) => patient?.custUuid).filter(Boolean) }
		} else if (_.isEqual(templateType, 'Groups')) {
			ids = { GroupID: _.map(state?.groupList, (grp) => grp?.groupUuid).filter(Boolean) }
		} else if (_.isEqual(templateType, 'Staff')) {
			ids = { TentUID: _.map(state?.staffList, (staff) => staff?.tentUserUuid).filter(Boolean) }
		}

		let headerVal = headerMessage && headerMessage.length > 0 ? Object.values(headerMessage[0])[0] : ''
		let groupSeperated = isCare ? 'Patient' : 'Client'
		const body = {
			...ids,
			selectAll: (_.isEqual(templateType, 'Patient') || _.isEqual(templateType, 'Client')) && _.isEqual(checkAll, true),
			type: _.isEqual(templateType, 'Groups') ? 'Groups' : _.isEqual(templateType, 'Staff') ? 'Staff' : groupSeperated,
			Mast_tenID: mastuuid,
			body: replacedContent ? replacedContent.replaceAll('\n', ' ') : '',
			templateName: metaTemplateVal?.template_name ? metaTemplateVal?.template_name : '',
			msg_body_type: message ? message.map((obj) => obj[Object.keys(obj)[0]]) : [],
			lang: metaTemplateVal ? metaTemplateVal?.lang : 'en',
			headerMedia: {
				headerType: formik?.values?.header || '',
				headerUrl: mediaUuid?.filePath || '',
				headerVariables: headerVal,
				headerText: replacedHeaderContent ? replacedHeaderContent.replaceAll('\n', ' ') : '',
			},
		}

		reduxDispatch(showLoader('Sending Messages please wait...'))

		const onSuccess = (res) => {
			reduxDispatch(hideLoader())
			if (res?.data?.stats === 'sucess') {
				reduxDispatch(clickLeadsDetails({}))
				reduxDispatch(clickPatientDetails({}))
				handleModalClose()
				setPage(0)
				getSmsList()
				reduxDispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Message sent successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else {
				reduxDispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			reduxDispatch(hideLoader())
			reduxDispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		replacedContent && smsApi.sendWhatsAppMessage(body).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			header: '',
			headerText: '',
			addMeassage: '',
			footer: '',
			recipients: [],
			messageTemplate: '',
		},
		validationSchema: yup.object({
			header: yup.string(),
			messageTemplate: yup.string(),
			addMeassage: yup
				.string()
				.nullable()
				.required('Please enter the message')
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the message')
				.max(250, 'message is maximum 250 character'),
			footer: yup.string(),
			recipients: yup.array().min(1).required('Please add atleat one Recipent'),
			headerText: yup
				.string()
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the message')
				.max(60, 'message is maximum 60 character'),
		}),
		onSubmit: onSubmit,
	})

	const handleModalClose = () => {
		formik?.resetForm()
		handleClose()
		dispatch({ type: 'resetState' })
		setCheckAll(false)
		setPatientsData(null)
		setPatientSearchParams({ name: null })
		setPatientSearchValue('')
		setMessage([])
		setHeaderMessage([])
		setTemplateOption([])
		setMediaUuid('')
		setMetaTemplateVal({
			template_name: '',
			content: '',
		})
	}

	// Function to replace placeholders with corresponding values
	const replacePlaceholders = (text, replacements) => {
		return text.replace(/{{(\d+)}}/g, (match, capture) => {
			const index = parseInt(capture, 10) - 1
			return replacements[index] || match
		})
	}
	const replacedContent = replacePlaceholders(
		formik?.values?.addMeassage,
		message.map((obj) => obj[Object.keys(obj)[0]])
	)

	// Function to replace placeholders with corresponding values for headerValues
	const replaceHeaderText = (text, replacements) => {
		return text.replace(/{{(\d+)}}/g, (match, capture) => {
			const index = parseInt(capture, 10) - 1
			return replacements[index] || match
		})
	}
	const replacedHeaderContent = replaceHeaderText(
		formik?.values?.headerText,
		headerMessage.map((obj) => obj[Object.keys(obj)[0]])
	)

	return (
		// <div className={classes.table_container_draft}>
		<div className={classes.profileTable} style={{ height: '65vh' }}>
			{loading ? (
				<div style={{ height: 500 }}>
					<DataGrid rows={loadingRow} columns={loadingColumn} />
				</div>
			) : !_.isEmpty(whatsAppUserList) ? (
				<>
					<DataGrid rows={rows} columns={columns} onCellClick={handleOnCellClick} />
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							border: '1px solid rgb(224 224 224)',
						}}
					>
						<TablePagination
							component='div'
							count={totalCount}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={handleChangePage}
							rowsPerPageOptions={[10, 20, 50, 100]}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
				</>
			) : (
				<div className={classes.noData}>
					<Avatar src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png`} variant='square' sx={{ width: 310, height: 258 }} />
					<Typography variant='subtitle1'>You haven't sent any messages</Typography>
				</div>
			)}

			<MessageModal
				history={history}
				match={match}
				isCare={isCare}
				open={openModal}
				onModalClose={handleModalClose}
				isView={isView}
				formik={formik}
				mastuuid={mastuuid}
				templateType={templateType}
				onTemplateChange={onTemplateChange}
				state={state}
				dispatch={dispatch}
				checkAll={checkAll}
				setCheckAll={setCheckAll}
				patientsData={patientsData}
				setPatientsData={setPatientsData}
				patientSearchValue={patientSearchValue}
				setPatientSearchValue={setPatientSearchValue}
				patientSearchParams={patientSearchParams}
				setPatientSearchParams={setPatientSearchParams}
				message={message}
				setMessage={setMessage}
				replacedContent={replacedContent}
				templateOption={templateOption}
				metaTemplateVal={metaTemplateVal}
				setMetaTemplateVal={setMetaTemplateVal}
				fileInputRef={fileInputRef}
				handleFileChange={handleFileChange}
				handleAvatarClick={handleAvatarClick}
				mediaUuid={mediaUuid}
				setMediaUuid={setMediaUuid}
				removeMediaUuid={removeMediaUuid}
				replacedHeaderContent={replacedHeaderContent}
				headerMessage={headerMessage}
				setHeaderMessage={setHeaderMessage}
			/>
			<ViewDetails
				isView={isView}
				showViewPopup={openModal}
				viewWhatsappMsg={viewWhatsappMsg}
				setOpenSms={setOpenSms}
				setViewWhatsappMsgText={setViewWhatsappMsgText}
				dateFormatForViewer={dateFormatForViewer}
			/>
			<ViewTemplateDetails open={open} setOpen={setOpen} viewMsg={viewMsg} setViewMsg={setViewMsg} />
		</div>
	)
}
export default PracticeListTable
