import { Card, Button } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ButtonStyle = styled('Button')(({ theme }) => ({
	width: '100%',
	'& .btn': {
		color: '#FFFFFF',
		backgroundColor: '#414A58',
		'&:hover': {
			backgroundColor: '#1b2330',
		},
	},
}))
function MailConfirmPage() {
	const state = useSelector((state) => state.Authentication)
	const { userName } = state
	return (
		<div className='flex flex-col flex-auto items-center justify-center p-16 sm:p-32'>
			<div className='flex flex-col items-center justify-center w-full'>
				<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
					<Card className='w-full max-w-384'>
						<CardContent className='flex flex-col items-center justify-center p-16 sm:p-24 md:p-32'>
							<div className='m-32'>
								<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Mar-Business_18.png`} alt='successMail' />
							</div>

							<Typography className='text-center mb-16 font-semibold'>Please verify E-mail to start free trial</Typography>

							<Typography className='text-center mb-16 w-full' color='textSecondary'>
								We have sent a verification link to <b>{userName}</b>
								<Link to='/'> change email id</Link>
							</Typography>
							<ButtonStyle>
								<Button type='submit' variant='contained' className='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit'>
									Submit
								</Button>
							</ButtonStyle>
						</CardContent>
					</Card>
				</motion.div>
			</div>
		</div>
	)
}

export default MailConfirmPage
