import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import TotalAppointmentsChart from './TotalAppointmentsChart'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
		marginBlockEnd: 14,
		padding: 14,
		borderRadius: 10,
		width: '100%',
		overflow: 'hidden',
	},
}))

function TotalAppointments({ getDateRange, dashboardData }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Typography variant='h6'>Total Appointments</Typography>
			<TotalAppointmentsChart dashboardData={dashboardData} />
		</div>
	)
}

export default TotalAppointments
