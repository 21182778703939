import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { showLoader, hideLoader } from '../../../../../services/loader/action'
import educationdetails from '../../../../../services/ProfileSetup/educationdetails/api'
import Button from 'app/sharedComponents/Button'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'

const useStyles = makeStyles({
	container: {
		paddingInline: 36,
		paddingBlock: 18,
	},
	label: {
		fontSize: 14,
	},
	value: {
		color: '#2A2A2A',
		fontSize: 14,
		fontWeight: 500,
	},
})
const EducationDetails = ({ match, history }) => {
	const mastUuid = _.get(match, 'params.mastuuid', '')
	const uuid = _.get(match, 'params.uuid', '')
	const dispatch = useDispatch()
	const classes = useStyles()
	const [educationListData, setEducationListData] = useState([])
	const getProfileEducationDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setEducationListData(res?.data?.data)
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}
		educationdetails.getEdudetails(uuid).then(onSuccess, onFailure)
	}, [uuid, dispatch])
	useEffect(() => {
		getProfileEducationDetails()
	}, [getProfileEducationDetails])

	const navigation = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastUuid}?tabId=educationSpecialisation`)
	}
	return (
		<Grid container className={classes.container}>
			<Grid item lg={10} pb={3}>
				{!_.isEmpty(educationListData) &&
					_.map(educationListData, (item) => {
						return (
							<>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Qualication
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.degreeName}
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											College
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.collegeName}
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Completed year
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.completedYear}
										</Typography>
									</Grid>
								</Grid>
								<Grid item container pb={4}>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Specialisation
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.specialization}
										</Typography>
									</Grid>
								</Grid>
							</>
						)
					})}
			</Grid>
			<Grid item lg={2}>
				<Button onClick={navigation}>Edit profile</Button>
			</Grid>
		</Grid>
	)
}

export default EducationDetails
