import React from 'react'

import {
	Autocomplete,
	Chip,
	Grid,
	IconButton,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
	// createFilterOptions,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import clsx from 'clsx'
import CustomDatePicker from 'app/sharedComponents/CustomDatePicker'
import moment from 'moment'
import _ from 'lodash'
import CustomTextFieldWithCountryCode from 'app/sharedComponents/CountryCodeAdornment'
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined'
import ApptDateRangePicker from './ApptDateRangePicker'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './formStyles'
import ClientSearcher from './ClientSearcher'
import CopyIcon from 'app/sharedComponents/CopyIcon'
import LinkIcon from '@mui/icons-material/Link'
import { showMessage } from 'app/store/fuse/messageSlice'
import { setApptPrepopulationData } from 'services/Appointments/action'
const CareAppointment = (props) => {
	const classes = useStyles()

	const {
		apptState,
		apptDispatch,
		formik,
		isSpecialistLogin,
		countryCodeList,
		setGoogleIntegrateLink,
		setCountryCodeLength,
		setCountryCodeMinLength,
		countryCode,
		setCountryCode,
		getClientId,
		clientState,
		clientDispatch,
		isEditAppt,
		preSelectedClient,
		//
		isCareFlow,
		performClientSearch,
		googleMeetlinkGeneration,
		googleIntegrateLink,
		setSelectTentUserId,
		timeSlotLoading,
	} = props
	const countryTimezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezone || '')
	const dispatch = useDispatch()
	const isPaymentMandatory = formik?.values?.paymentMandatory
	// const filterOptions = createFilterOptions({
	// 	// matchFrom: 'start',
	// 	stringify: (option) =>
	// 		option.custName +
	// 		option.custCustomId +
	// 		option.custMobileNo +
	// 		option.custEmail +
	// 		option.custAge +
	// 		option.custDob +
	// 		option.custGender +
	// 		option.postalCode +
	// 		option.custBloodGroup +
	// 		option.address1, // make it one for it
	// })
	const handleCopyContent = () => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)
		navigator.clipboard.writeText(`${googleIntegrateLink}`)
	}
	return (
		<Grid container style={{ padding: 22, justifyContent: 'space-between' }} className={classes.root}>
			<Grid item xs={5.75}>
				<ClientSearcher
					clientState={clientState}
					clientDispatch={clientDispatch}
					performClientSearch={performClientSearch}
					getClientId={getClientId}
					formik={formik}
					isCareFlow={isCareFlow}
					countryCodeList={countryCodeList}
					setCountryCode={setCountryCode}
					setCountryCodeLength={setCountryCodeLength}
					setCountryCodeMinLength={setCountryCodeMinLength}
					preSelectedClient={preSelectedClient}
					isEditAppt={isEditAppt}
				/>
			</Grid>

			{/* <Grid item xs={5.75}>
				<Autocomplete
					id='patientSearch'
					disablePortal
					noOptionsText={'Search or Add Patients'}
					options={clientState?.clientsList}
					disabled={isEditAppt || !_.isEmpty(preSelectedClient)}
					getOptionLabel={(option) => {
						if (typeof option === 'string') {
							return option
						}
						if (option.inputValue) {
							return option.inputValue
						}
						return option.custName
					}}
					filterOptions={(options, params) => {
						const { inputValue } = params
						const filtered = filterOptions(options, params)
						if (inputValue.trim() !== '') {
							let format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.0-9<>/?]*$/
							// let numnericFormat = /^[0-9]+$/

							if (!inputValue.match(format)) {
								filtered.push({
									inputValue,
									custName: `Add "${inputValue.replace(/[^A-Za-z ]/gi, '')}"`,
								})
							}
							//  else if (inputValue.match(numnericFormat)) {
							// 	let [phoneNumber] = inputValue
							// 	filtered.push({
							// 		inputValue,
							// 		custName: `Add mobile number "${phoneNumber}"`,
							// 	})
							// }
						}
						return filtered
					}}
					value={searchText}
					onChange={(event, value) => {
						if (value?.inputValue) {
							clientDispatch({
								type: 'setIsNewClient',
								data: true,
							})
							getClientId()
							formik?.setFieldValue('clientName', value?.inputValue)
						} else {
							clientDispatch({
								type: 'setIsNewClient',
								data: false,
							})
							formik?.setFieldValue('clientName', value)
							formik?.setFieldValue('clientId', value?.custCustomId)
							formik?.setFieldValue('custMobile', value?.custMobileNo)
							formik?.setFieldValue('clientEmail', value?.custEmail)
							// setting the  country code and countryCodeLength
							let code = _.find(countryCodeList, { mastLookupKey: value?.custCountryCode })
							setCountryCode(code)
							setCountryCodeLength(parseInt(code?.mastLookupValue))
							setCountryCodeMinLength(parseInt(code?.minimumLength))
						}
					}}
					onInputChange={(event, newInputValue) => {
						let filteredValue = newInputValue.replace(/[^A-Za-z0-9 ]/gi, '')
						setSearchText(filteredValue)
						clientSearcher(filteredValue)
					}}
					renderOption={(props, option) => (
						<li {...props}>
							<div className={classes.clients_list_item}>
								<Avatar
									alt='client'
									src={option?.custThumbNailPath || 'assets/images/icon/user-logo.png'}
									style={{ height: '34px', width: '34px', marginRight: 10 }}
								/>
								<div>
									<Typography>
										{option.custName} {option?.custCustomId ? `(${option?.custCustomId})` : ''}
									</Typography>
									<Typography>
										{option.custCountryCode} {option.custMobileNo}
									</Typography>
								</div>
							</div>
						</li>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							color='secondary'
							size='small'
							label='Search by name / Mobile number / Add patient'
							placeholder='Example - John doe or 9876543210'
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{clientState?.clientsLoading ? <CircularProgress color='inherit' className={classes.circular_progress} size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</Grid> */}

			<Grid item xs={5.75} className={classes.mobileField}>
				<CustomTextFieldWithCountryCode
					id='custMobile'
					name='custMobile'
					size='small'
					type='tel'
					disabled={isEditAppt || !clientState?.isNewClient || !_.isEmpty(preSelectedClient)} // if the client is not new we are making non-editable
					value={formik?.values?.custMobile}
					onChange={(e) => {
						formik?.setFieldValue('custMobile', e.target.value)
					}}
					onBlur={formik?.handleBlur}
					label='Mobile number  *'
					color='secondary'
					variant='outlined'
					countryCodeValue={countryCode}
					countryCodeOnChange={(e, newValue) => {
						setCountryCode(newValue)
						setCountryCodeLength(parseInt(newValue?.mastLookupValue))
						setCountryCodeMinLength(parseInt(newValue?.minimumLength))
					}}
					disableCountryCode={isEditAppt || !clientState?.isNewClient || !_.isEmpty(preSelectedClient)} // if the client is not new we are making non-editable
					countryCodeList={countryCodeList}
					error={formik.touched.custMobile && formik.errors.custMobile}
					helperText={formik.touched.custMobile && formik.errors.custMobile && formik.errors.custMobile}
				/>
			</Grid>
			<Grid item xs={5.75}>
				{_.isEqual(clientState?.clientStatus, 'Customer') && (
					<Typography className={clsx(classes.patId)} style={{ marginTop: 18 }}>
						Patient ID <span> {formik?.values?.clientId || ''}</span>
					</Typography>
				)}
			</Grid>
			<Grid item xs={5.75} className={classes.normalTextFeild} style={{ marginBlock: 6 }}>
				<TextField
					id='clientEmail'
					label='E-mail'
					color='secondary'
					size='small'
					className='my-8'
					fullWidth
					disabled={isEditAppt || !clientState?.isNewClient} // if the client is not new we are making non-editable
					value={formik?.values?.clientEmail}
					onChange={(event) => formik?.setFieldValue('clientEmail', event.target.value)}
					error={formik.errors.clientEmail}
					helperText={formik.errors.clientEmail && formik.errors.clientEmail}
				/>
			</Grid>
			<Grid item xs={5.75}>
				<Autocomplete
					id='appointmentMode'
					name='appointmentMode'
					options={apptState?.appointmentModes}
					disabled={isEditAppt}
					disableClearable='true'
					autoHighlight
					size='small'
					getOptionLabel={(option) => option?.LabelTxt || ''}
					isOptionEqualToValue={(option, value) => option?.value === value?.value}
					value={formik?.values?.appointmentMode}
					onChange={(e, value) => {
						formik?.setFieldValue('appointmentMode', value)
						setGoogleIntegrateLink(null)
					}}
					noOptionsText={'No appointment modes found'}
					onBlur={formik.handleBlur}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Mode of appointment *'
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.appointmentMode && formik.errors.appointmentMode}
							helperText={formik.touched.appointmentMode && formik.errors.appointmentMode}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={5.75}>
				<Autocomplete
					id='specialistName'
					name='specialistName'
					options={apptState?.specialistList}
					disableClearable
					disabled={isEditAppt || isSpecialistLogin}
					getOptionLabel={(option) => `${option?.tentUserSalutation ? option?.tentUserSalutation + '.' : ''} ${option?.tentUserFirstName}` || ''}
					isOptionEqualToValue={(option, value) => option?.tentUserFirstName === value?.tentUserFirstName}
					value={formik?.values?.specialistName}
					onChange={(e, value) => {
						formik?.setFieldValue('specialistName', value)
						formik?.setFieldValue('paymentMandatory', value?.isPaymentMandatory)
						setSelectTentUserId(value?.tentUserUuid)
						// formik?.setFieldValue('date', moment(new Date()).format('YYYY-MM-DD'))
					}}
					onBlur={formik.handleBlur}
					noOptionsText={!formik?.values?.appointmentMode ? 'Please select a appointment mode' : 'No Specialists found'}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Specialist name *'
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.specialistName && formik.errors.specialistName}
							helperText={formik.touched.specialistName && formik.errors.specialistName}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={5.75}>
				<Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Grid item xs={9.8} className={clsx(classes.dateSelect)}>
						{apptState?.serial ? (
							<ApptDateRangePicker
								value={apptState?.pickerValue}
								setValue={(newDateRange) => {
									apptDispatch({
										type: 'setPickerValue',
										data: newDateRange,
									})
									if (!isEditAppt) {
										dispatch(
											setApptPrepopulationData({
												date: newDateRange,
											})
										)
									}
									// dispatch(setAppointmentDate(null))
									formik?.setFieldValue('date', newDateRange.at(0))
								}}
							/>
						) : (
							<CustomDatePicker
								id='date-for-appointment'
								label='Select date for appointment *'
								value={formik?.values?.date}
								// onAccept={(newdate) => formik?.setFieldValue('date', newdate)}
								onAccept={(newdate) => {
									// dispatch(setAppointmentDate(null))
									formik?.setFieldValue('date', newdate)
									if (!isEditAppt) {
										dispatch(
											setApptPrepopulationData({
												date: newdate,
											})
										)
									}
								}}
								minDate={new Date(moment().format('YYYY-MM-DD'))}
								maxDate={new Date(moment().add(1, 'Y').format('YYYY-MM-DD'))}
							/>
						)}
					</Grid>
					<Grid item xs={2}>
						<Tooltip title={apptState?.serial ? 'Book appointment' : 'Book session'} arrow placement='top'>
							<IconButton
								disabled={isEditAppt}
								onClick={() => {
									apptDispatch({
										type: 'setSerial',
										data: !apptState?.serial,
									})
								}}
							>
								<SwapHorizontalCircleOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{apptState?.serial && (
						<>
							<Stack direction='row' spacing={1} style={{ paddingBlock: 4 }}>
								{_.map(apptState?.pickerDaysSet, (item, i) => (
									<Chip
										key={i}
										label={item?.label}
										clickable
										variant={item?.isSelected ? 'filled' : 'outlined'}
										className={classes.chip}
										onClick={(e) => {
											const editedData = { id: item?.id, label: item?.label, isSelected: !item?.isSelected }
											const changeArr = apptState?.pickerDaysSet.map((u) => (u.id !== editedData.id ? u : editedData))
											_.map(apptState?.chipDays, (o) => {
												if (_.isEqual(o?.id, item?.id)) {
													//Actual state change
													let isSelectedReplace = { id: item?.id, label: item?.label, isSelected: !item?.isSelected }
													let removeOriginalArr = apptState?.pickerDaysSet.map((u) => (u.id !== isSelectedReplace.id ? u : isSelectedReplace))
													apptDispatch({
														type: 'setPickerDaysSet',
														data: removeOriginalArr,
													})

													//onClick data edit
													let removedData = _.filter(apptState?.chipDays, (u) => !_.isEqual(u?.id, item?.id))
													apptDispatch({
														type: 'setChipDays',
														data: _.uniqBy(removedData, 'id'),
													})
												} else if (!item?.isSelected === o?.isSelected && item?.id !== o?.id) {
													apptDispatch({
														type: 'setPickerDaysSet',
														data: changeArr,
													})
													apptDispatch({
														type: 'setChipDays',
														data: [...apptState?.chipDays, { id: item?.id, label: item?.label, isSelected: !item?.isSelected }],
													})
												}
											})
											if (_.isEmpty(apptState?.chipDays)) {
												apptDispatch({
													type: 'setPickerDaysSet',
													data: changeArr,
												})
												apptDispatch({
													type: 'setChipDays',
													data: [...apptState?.chipDays, { id: item?.id, label: item?.label, isSelected: !item?.isSelected }],
												})
											}
											!_.isEmpty(apptState?.chipDays) &&
												apptDispatch({
													type: 'setSerialErr',
													data: '',
												})
										}}
									/>
								))}
							</Stack>
							{!_.isEmpty(apptState?.serialErr) ? <Typography className='text-red-600 text-xs mx-8'>{apptState?.serialErr}</Typography> : ''}{' '}
						</>
					)}
				</Grid>
			</Grid>
			<Grid item xs={5.75}>
				<Typography className={clsx(classes.paymentTypeBox, 'mb-8')}>
					Choose payment type
					<div>
						<RadioGroup
							aria-labelledby='selecting-fees-calc-method'
							value={formik?.values?.feesCalcMethod}
							onChange={(event) => {
								formik?.setFieldValue('feesCalcMethod', event.target.value)
							}}
						>
							<FormControlLabel
								value='specialistConsultationFees'
								control={<Radio />}
								label='Specialist'
								disabled={!isPaymentMandatory || isEditAppt}
							/>
							<FormControlLabel value='pricingCatlog' control={<Radio />} label='Procedure' disabled={!isPaymentMandatory || isEditAppt} />
						</RadioGroup>
					</div>
				</Typography>
			</Grid>
			{/* ROW - 5 */}
			<Grid item xs={5.75} style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Grid item xs={7.25}>
					<Autocomplete
						id='timeSlot'
						name='timeSlot'
						size='small'
						options={apptState?.timeSlotList}
						disableClearable='true'
						autoHighlight
						onBlur={formik.handleBlur}
						getOptionLabel={(option) => moment(option?.startTime, 'HH:mm').format('hh:mm A')}
						isOptionEqualToValue={(option, value) => option?.startTime === value?.startTime}
						value={formik?.values?.timeSlot}
						onChange={(e, value) => formik?.setFieldValue('timeSlot', value)}
						renderInput={(params) => (
							<TextField
								{...params}
								size='small'
								color='secondary'
								label={`Select time* ${countryTimezone}`}
								className='my-8'
								fullWidth
								variant='outlined'
								error={!timeSlotLoading && _.isEmpty(formik?.values?.timeSlot) && formik?.touched?.timeSlot && formik.errors.timeSlot}
								helperText={!timeSlotLoading && _.isEmpty(formik?.values?.timeSlot) && formik?.touched?.timeSlot && formik.errors.timeSlot}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4.25} className={classes.normalTextFeild}>
					<TextField
						id='duration'
						name='duration'
						label='Duration'
						className='my-8'
						size='small'
						color='secondary'
						disabled={true}
						value={formik?.values?.timeSlot?.intervalInMins ? `${formik?.values?.timeSlot?.intervalInMins} Mins` : ''}
						variant='outlined'
						fullWidth
					/>
				</Grid>
			</Grid>
			<Grid item xs={5.75}>
				<Autocomplete
					id='procedureCategory'
					name='procedureCategory'
					options={apptState?.procedureList}
					autoHighlight
					disabled={isEditAppt}
					size='small'
					disableClearable={isPaymentMandatory}
					onBlur={formik.handleBlur}
					getOptionLabel={(option) => option?.tentProcedureCatalogName || ''}
					isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
					value={formik?.values?.procedureCategory}
					onChange={(e, value) => formik?.setFieldValue('procedureCategory', value)}
					noOptionsText={'No procedures found'}
					renderInput={(params) => (
						<TextField
							{...params}
							label={isPaymentMandatory ? 'Procedure category *' : 'Procedure category'}
							color='secondary'
							size='small'
							className='my-8'
							error={formik.touched.procedureCategory && formik.errors.procedureCategory}
							helperText={formik.touched.procedureCategory && formik.errors.procedureCategory}
						/>
					)}
				/>
			</Grid>
			{/* ROW - 6 */}
			<Grid item xs={5.75}>
				<Autocomplete
					id='speciality'
					name='speciality'
					options={apptState?.specialityList}
					disabled={isEditAppt}
					disablePortal
					autoHighlight
					size='small'
					getOptionLabel={(option) => option?.specialityName || ''}
					isOptionEqualToValue={(option, value) => option?.specialityName === value?.specialityName}
					value={formik?.values?.speciality}
					onChange={(e, value) => formik?.setFieldValue('speciality', value)}
					noOptionsText={'No speciality found'}
					renderInput={(params) => <TextField {...params} label='Select Speciality' className='mt-10' color='secondary' size='small' />}
				/>
			</Grid>
			<Grid item xs={5.75} className={classes.normalTextFeild}>
				<TextField
					id='Notes'
					label='Notes'
					color='secondary'
					className='mt-10'
					disabled={isEditAppt}
					size='small'
					fullWidth
					multiline
					minRows={3}
					value={formik?.values?.notes}
					onChange={(event) => formik?.setFieldValue('notes', event.target.value)}
					error={formik.errors.notes}
					helperText={formik.errors.notes && formik.errors.notes}
				/>
			</Grid>
			{/* ROW - 7 */}
			{!apptState?.serial && (
				<>
					<Grid item xs={5.75}>
						<Typography className={clsx(classes.patId, 'mb-8 mt-8')}>
							Payment mandatory
							<ToggleButtonGroup
								className={classes.togglebtngrp}
								exclusive
								disabled={isEditAppt}
								value={formik?.values?.paymentMandatory}
								onChange={(e, value) => {
									formik?.setFieldValue('paymentMandatory', value)
								}}
							>
								<ToggleButton value={true} disabled={formik?.values?.paymentMandatory}>
									Yes
								</ToggleButton>
								<ToggleButton value={false} disabled={!formik?.values?.paymentMandatory}>
									No
								</ToggleButton>
							</ToggleButtonGroup>
						</Typography>
					</Grid>
					{formik?.values?.appointmentMode?.LabelTxt === 'Online' && (
						<Grid item xs={5.75} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Grid item xs={12} className={classes?.meetingLinkContainer}>
								<Grid item xs={9.8}>
									<TextField
										id='meetinglink'
										color='secondary'
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										className='my-16'
										size='small'
										label='Meeting Link'
										disabled={true}
										value={googleIntegrateLink}
									/>
								</Grid>

								{googleIntegrateLink && (
									<Grid item xs={1}>
										<CopyIcon
											onClick={() => {
												handleCopyContent()
											}}
										/>
									</Grid>
								)}

								<Grid item xs={1}>
									<Tooltip title={'Meeting Link'} arrow placement='top'>
										<IconButton
											disabled={!_.isEmpty(googleIntegrateLink) || _.isEqual(formik?.values?.custMobile, '')}
											onClick={() => {
												googleMeetlinkGeneration()
											}}
										>
											<LinkIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</Grid>
	)
}

export default CareAppointment
