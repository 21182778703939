import { ACTION_TYPES } from '../../../constants'

export const setSubscription = (payload) => ({
	type: ACTION_TYPES.SET_SUBSCRIPTION,
	payload,
})

export const setBuyNow = (payload) => ({
	type: ACTION_TYPES.SET_BUY_NOW,
	payload,
})
// export const showTentUuid = (payload) => ({
// 	type: ACTION_TYPES.SUBSCRIPTION_TENTUSER_UUID,
// 	payload,
// })
