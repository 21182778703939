import React from 'react'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		cursor: 'pointer',
		marginInlineStart: 4,

		'& .MuiSvgIcon-root': {
			fontSize: 16,
			fill: '#2EB2FF',
		},
	},
}))
const CopyIcon = ({ onClick }) => {
	const classes = useStyles()
	return (
		<div className={classes?.root} onClick={onClick}>
			<ContentCopyOutlinedIcon />
		</div>
	)
}

export default CopyIcon
