import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getProfileStatus: (uuid, mastuuid, isNotOwner, params) => {
		return axios.get(API_ENDPOINTS.GET_PROFILE_STATUS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid, mastuuid, isNotOwner } },
			params: { ...params },
		})
	},
	getProfilePercentage: (userType, uuid) => {
		return axios.get(API_ENDPOINTS.GET_PROFILE_PERCENTAGE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	updateProfileStatus: (data) => {
		return axios.put(API_ENDPOINTS.UPDATE_PROFILE_STATUS, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	addProfile: (uuid, mastuuid, data) => {
		return axios.post(API_ENDPOINTS.ADD_PROFILE, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { uuid, mastuuid } },
		})
	},
	updateProfileToggleStatus: (data) => {
		return axios.patch(`${API_ENDPOINTS.UPDATE_ACTIVE_STATUS}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
}
