import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	accountGetDetail: (uuid) => {
		return axios.get(API_ENDPOINTS.GET_ACCOUNT, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	accountPostDetail: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.patch(API_ENDPOINTS.UPDATE_ACCOUNTDETAIL, result, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}` },
		})
	},
	addressGetDetail: (uuid) => {
		return axios.get(API_ENDPOINTS.GET_ADDRESS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	addressPostDetail: (body) => {
		return axios.post(API_ENDPOINTS.UPDATE_ADDRESSDETAIL, body, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf() },
		})
	},
}
