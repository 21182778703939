import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAppointmentSpecialityList: (params) => {
		return axios.get(API_ENDPOINTS.APPOINTMENT_SPECIALITY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getAppointmentModes: (params) => {
		return axios.get(API_ENDPOINTS.APPOINTEMENT_MODES, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getTentUserApptDetails: (mastuuid, params) => {
		return axios.get(API_ENDPOINTS.TENT_USER__APPOINTMENT_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
			params: { ...params },
		})
	},

	getSpecialityListBasedOnTentUser: (params) => {
		return axios.get(API_ENDPOINTS.GET_SPECIALITY_lIST_BASED_ON_TENTUSER, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getTimeSlot: (params) => {
		return axios.get(API_ENDPOINTS.GET_TIME_SLOT_CALENDAR, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getSpaSpeciality: (params) => {
		return axios.get(API_ENDPOINTS.GET_SPA_SPECIALITY_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getNonCareSpeciality: (params) => {
		return axios.get(API_ENDPOINTS.GET_SPA_SPECIALITY_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getSpaDuration: (params) => {
		return axios.get(API_ENDPOINTS.GET_SPA_DURATION, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getDuration: (params) => {
		return axios.get(API_ENDPOINTS.GET_SPA_DURATION, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getPriceList: (data) => {
		return axios.post(`${API_ENDPOINTS.GET_PRICE_LIST_CALENDAR}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
}
