/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import LookupApi from '../../../../services/lookup/api'
import lookupApi from '../../../../services/welcomeCare/api'
const Lookup = (props) => {
	const { setCountryOptions, setCityOptions, countryParams, cityParams } = props
	const cancelExecutor = useRef()
	useEffect(() => {
		const onSuccess = (res) => {
			setCountryOptions(res?.data?.data || [])
		}
		const onFailure = () => {
			setCountryOptions([])
		}
		lookupApi.getCountry('getLookup', { ...countryParams }, cancelExecutor).then(onSuccess, onFailure)
	}, [countryParams, setCountryOptions])

	useEffect(() => {
		const onSuccess = (res) => {
			setCityOptions(res.data.data)
		}
		const onFailure = () => {
			setCityOptions([])
		}
		LookupApi.getLookup('cities', { ...cityParams }, cancelExecutor).then(onSuccess, onFailure)
	}, [cityParams])
	return <></>
}

export default Lookup
