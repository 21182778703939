import { yupResolver } from '@hookform/resolvers/yup'
import { Icon, InputAdornment, Checkbox, Typography, Grid, IconButton, FormControlLabel, FormGroup, Button, Divider } from '@mui/material'
import ButtonComm from '../../../sharedComponents/Button'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import TextField from '@mui/material/TextField'
import useStyle from './style'
import _ from 'lodash'
import { Login, updateDeviceId, PhoneNumberLength, updateBearerToken, Register } from '../../../session/actions'
import { detect } from 'detect-browser'
import loginApi from '../../../../services/login/api'
import { showMessage } from 'app/store/fuse/messageSlice'
import CountryApi from '../../../../services/register/api'
import { useHistory } from 'react-router-dom'
import { hideLoader, showLoader } from 'services/loader/action'
import { encryption, decryption, getResponseMessage, failureLogin, encodeText } from 'utils'
import moment from 'moment'
import { setVerifyModal } from 'services/VerficationModal/actions'
import EmailVerificationModal from 'app/main/EmailVerificationModal/EmailVerificationModal'
import LoopIcon from '@mui/icons-material/Loop'
import secureLocalStorage from 'react-secure-storage'
import DynamicMenuApi from '../../../../services/DynamicMenu/api'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import { getMenuFeatures } from 'utils'
import OtpApi from 'services/otp/api'
import { Logout } from '../../../session/actions'
import GoogleIcon from 'images/logos/Google_logo.svg'
import { motion } from 'framer-motion'
import { setOpenOtpModal } from 'services/otpModal/action'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import themesConfig from 'app/fuse-configs/themesConfig'
import { Link } from 'react-router-dom'
import { closePrompt, showPrompt } from 'services/Prompt/actions'
import RegisterApi from '../../../../services/register/api'
function Loginform() {
	const dispatch = useDispatch()
	const [countryCode, setCountryCode] = useState([])
	const [showCaptcha, setShowCaptcha] = useState(false)
	const [phoneNumberLength, setPhoneNumberLength] = useState('')
	const cancelExecutor = useRef()
	const [checked, setChecked] = useState(true)
	const [deviceData, setDeviceData] = useState({})
	const [captchaCode, setCaptchaCode] = useState({
		captchaValue: '',
		code: '',
		id: '',
		captchaKey: '',
	})
	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	})
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}
	const errorJson = useSelector((states) => states?.errorMessages || {})
	const classes = useStyle()
	const history = useHistory()
	const [loginDetails, setLoginDetails] = useState({
		username: secureLocalStorage.getItem('username') || '',
		password: '',
		isEmail: secureLocalStorage.getItem('isEmail') || '',
		isPractice: secureLocalStorage.getItem('isPractice') || '',
		groupId: secureLocalStorage.getItem('groupId') || '',
		isWelcome: secureLocalStorage.getItem('isWelcome'),
		uuid: secureLocalStorage.getItem('uuid') || '',
		userRole: secureLocalStorage.getItem('userRole') || '',
		identityUuid: secureLocalStorage.getItem('identityUuid') || '',
		owner: secureLocalStorage.getItem('ownerStatus') || '',
		isModuleSelected: secureLocalStorage.getItem('isModuleSelected') || '',
	})
	const errorMessages = useSelector((state) => state?.errorMessages)

	const { uuid, isPractice, groupId, isEmail, isWelcome, emailId, userRole, isModuleSelected } = loginDetails
	const browserName = secureLocalStorage.getItem('browserName')
	const BearerToken = useSelector((states) => states?.Authentication.BearerToken)
	const isRememberMail = useSelector((states) => states?.Authentication.rememberMail) || secureLocalStorage.getItem('rememberMail')
	const currentCountryCode = useSelector((states) => states?.GeoInfo?.countryCode)
	const geoInfo = useSelector((states) => states?.GeoInfo?.geoInfo)
	const getDialCodeDetails = () => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				const code = _.orderBy(res?.data?.data, 'mastLookupKey', 'desc')
				setCountryCode(code)
			} else {
				setCountryCode('')
			}
		}
		const onFailure = (err) => {}
		CountryApi.getCountryCode().then(onSuccess, onFailure)
	}
	useEffect(() => {
		if (BearerToken) {
			navigationDetails(uuid, isPractice, groupId, isEmail, isWelcome, emailId, userRole, isModuleSelected)
		}
		getDialCodeDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getCaptcha = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setCaptchaCode({
					captchaValue: res?.data?.data?.captcha || '',
					code: res?.data?.data?.code || '',
					id: res?.data?.data?.id || '',
					captchaKey: res?.data?.data?.captchaKey || '',
				})
			}
		}
		const onFailure = (err) => {
			setCaptchaCode({
				code: '',
				captchaValue: '',
			})
		}
		loginApi.getCaptcha(cancelExecutor).then(onSuccess, onFailure)
	}, [])
	useEffect(() => {
		let requiredValidationNumber = currentCountryCode.substring(1)
		let foundCountryCode = _.find(countryCode, { mastLookupKey: requiredValidationNumber })

		setPhoneNumberLength(foundCountryCode?.mastLookupValue || '')
		secureLocalStorage.setItem('phoneNumberLength', phoneNumberLength)
		dispatch(
			PhoneNumberLength({
				phoneNumberLength: phoneNumberLength,
			})
		)
	}, [countryCode, currentCountryCode, dispatch, phoneNumberLength])
	const validationType = 'non'
	const [errorMessage, setErrorMessage] = useState('')
	const showPasswordField = false
	const browser = detect()

	secureLocalStorage.setItem('browserName', browser?.name)

	const passReq = yup.string().required('Please enter your password.')
	// .min(8, 'Password should contain minimum 8 characters')
	// .max(25, 'Password should contain maximum 25 characters')
	// .trim('Spaces are not allowed')
	// .strict(true, 'Spaces are not allowed')
	// .matches(
	// 	// eslint-disable-next-line no-useless-escape
	// 	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
	// 	'Must contain one uppercase, one lowercase, one number and one special case character'
	// )
	// const passNoreq = yup.string('Enter Password')
	const Email = yup
		.string('Please enter your Email id')
		.required('Please enter your Email id.')
		.test('email or phn', 'Please enter your valid Email id.', function (value) {
			// eslint-disable-next-line no-useless-escape
			const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
			// const phoneRegex = /^([1-9][0-9]{1,15}\s*)?$/
			const isValidEmail = emailRegex.test(value)
			// const isValidPhone = phoneRegex.test(value)
			if (!isValidEmail) {
				return false
			}
			return true
		})
	// const PhoneNumber = yup
	// 	.string(' Please Enter your Email/Mobile number')
	// 	.matches(/^([1-9][0-9]*)?$/, 'Please enter valid mobile number') // .matches(/^([0-9][1-9]*)?$/, 'Please Enter a Valid Number')
	// 	.min(7, 'Minimum 7 digits and maximum of 15 digits can be entered')
	// 	.required('Mobile number is required')
	// 	.test('email or phn', 'Enter Valid Mobile number', function (value) {
	// 		const phoneRegex = /^([1-9][0-9]*)?$/
	// 		const isValidPhone = phoneRegex.test(value)
	// 		if (!isValidPhone) {
	// 			return false
	// 		}
	// 		return true
	// 	})
	const schema = yup.object().shape({
		// eslint-disable-next-line no-undef
		// password: validationType === 'OTP' ? passNoreq : passReq,
		// email: validationType === 'OTP' ? PhoneNumber : Email,
		password: passReq,
		email: Email,
		captcha:
			showCaptcha === true &&
			yup.string().required('Enter captcha').min(5, `Min ${captchaCode?.code.length} characters`).matches(/^\S*$/, 'Invalid captcha'),
	})
	const defaultValues = {
		email: isRememberMail ? isRememberMail : '',
		password: '',
		captcha: '',
	}
	const { control, formState, handleSubmit, setValue, setError } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	})
	const { errors } = formState
	const handleKeyDown = (e) => {
		// if (e.key === ' ' && formik?.values?.name.length === 0) {
		// 	e.preventDefault()
		// }
	}
	function onSubmit(data) {
		setLoginDetails((prevState) => ({
			...prevState,
			username: data.email.toLowerCase(),
			password: data.password,
		}))
		submitLoginForm(data, 'lyfngo')
	}
	const submitRegisterForm = (data) => {
		dispatch(showLoader('Loading please wait...'))

		const body = {
			email: _.map(data, (item) => item?.email)?.toString(),
			userType: 'TNT',
			isSignUp: true,
			signUpViaName: 'google',
		}

		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			let dataCountry = _.map(countryCode, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
			let initialCountryCode = _.find(dataCountry, { mastLookupKey: currentCountryCode })
			if (successData?.newOwner === false) {
				dispatch(hideLoader())
				dispatch(
					Register({
						uuid: successData?.data?.uuid,
						userType: successData?.data?.userType,
						mobileNo: successData?.userMobile,
						emailId: successData?.userEmail,
						userEmail: successData?.userEmail,
						countryCode: initialCountryCode?.mastLookupKey,
						identityUuid: successData?.data?.identityUuid,
						isWhatsappOtp: successData?.isWhatsApp,
						countryName: initialCountryCode?.country,
						countryNameAbbreviation: initialCountryCode?.countryCode,
						BearerToken: successData?.bearer,
					})
				)

				secureLocalStorage.setItem('AidiBearer', successData?.bearer)
				secureLocalStorage.setItem('uuid', successData?.data?.uuid)
				secureLocalStorage.setItem('userType', successData?.data?.userType)
				secureLocalStorage.setItem('mobileNo', successData?.userMobile)
				secureLocalStorage.setItem('emailId', successData?.userEmail)
				secureLocalStorage.setItem('userEmail', successData?.userEmail)
				secureLocalStorage.setItem('countryCode', initialCountryCode?.mastLookupKey)
				secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
				secureLocalStorage.setItem('newOwner', successData?.newOwner)
				secureLocalStorage.setItem('isWhatsappOtp', successData?.isWhatsApp)
				secureLocalStorage.setItem('countryName', initialCountryCode?.country)
				secureLocalStorage.setItem('countryNameAbbreviation', initialCountryCode?.countryCode)
				updateDeviceDetails(
					successData?.data?.userType,
					successData?.data?.uuid,
					successData?.isPractice,
					successData?.groupId,
					successData?.isEmail,
					successData?.isMobile,
					isWelcome,
					successData?.userEmail,
					successData?.userRole,
					successData?.data?.identityUuid,
					successData?.owner,
					successData?.isModuleSelected,
					successData?.signUpViaName
				)
				history.push('/register?tab=setupbusiness')
			} else if (res.data.status === 'failure') {
				dispatch(hideLoader())
			}
		}

		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		RegisterApi.setRegisterNew(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const submitLoginForm = (data, value) => {
		dispatch(showLoader('Loading please wait...'))
		const passwordEnc = validationType !== 'OTP' ? encryption(data?.password) : ''
		const body = _.isEqual(value, 'lyfngo')
			? {
					username: data.email.toLowerCase(),
					password: passwordEnc?.plainText ? passwordEnc?.plainText + ':' + passwordEnc?.publicKey : passwordEnc,
					userType: 'TNT',
					validationType,
					code: data?.captcha,
					isPasswordEncoded: true,
					id: captchaCode?.id,
					captchaKey: captchaCode?.captchaKey,
					signUpViaName: 'lyfngo',
			  }
			: {
					username: _.map(data, (item) => item?.email)?.toString(),
					userType: 'TNT',
					validationType: 'non',
					code: '',
					id: '',
					captchaKey: '',
					signUpViaName: 'google',
			  }
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success') {
				dispatch(hideLoader())
				secureLocalStorage.setItem('aidivaTenantUserEmail', data?.email)
				secureLocalStorage.setItem('AidiBearer', successData?.bearer)
				secureLocalStorage.setItem('userType', successData?.data?.userType)
				secureLocalStorage.setItem('uuid', successData?.data?.uuid)
				secureLocalStorage.setItem('isOtp', successData?.isOtp)
				secureLocalStorage.setItem('isEmail', successData?.isEmail)
				secureLocalStorage.setItem('isPractice', successData?.isPractice)
				secureLocalStorage.setItem('groupId', successData?.groupId)
				secureLocalStorage.setItem('isWelcome', successData?.isWelcome)
				secureLocalStorage.setItem('isMobile', successData?.isMobile)
				secureLocalStorage.setItem('userName', successData?.tentUserFirstName || 'Hello, User')
				secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
				secureLocalStorage.setItem('ownerStatus', successData?.owner)
				secureLocalStorage.setItem('isPinSet', successData?.isPinSet)
				secureLocalStorage.setItem('isModuleSelected', successData?.isModuleSelected)
				secureLocalStorage?.setItem('signUpViaName', successData?.signUpViaName)
				secureLocalStorage?.setItem('mastTentTypeUuid', successData?.mastTentTypeUuid)

				let dataCountry = _.map(countryCode, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let initialCountryCode = _.find(dataCountry, { mastLookupKey: successData?.countryCode ? successData?.countryCode : currentCountryCode })
				dispatch(
					Login({
						isMobile: successData?.isMobile,
						uuid: successData?.data?.uuid,
						isEmail: successData?.isEmail,
						isPractice: successData?.isPractice,
						isOtp: successData?.isOtp,
						BearerToken: successData?.bearer,
						groupId: successData?.groupId,
						userType: successData?.data?.userType,
						userEmail: successData?.userEmail,
						mastuuid: successData?.data?.tentMastUuid,
						emailId: successData?.userEmail,
						mobileNo: successData?.userMobile,
						userRole: successData?.userRole,
						userName: successData?.tentUserFirstName || 'Hello, User',
						profilepic: successData?.profileUuid || '',
						// countryCode: successData?.countryCode || '',
						isTokenValid: successData?.isTokenValid || '',
						identityUuid: successData?.data?.identityUuid || '',
						owner: successData?.owner,
						isPinSet: successData?.isPinSet,
						isModuleSelected: successData?.isModuleSelected,
						countryCode: initialCountryCode?.mastLookupKey,
						countryName: initialCountryCode?.country,
						countryNameAbbreviation: initialCountryCode?.countryCode,
						signUpViaName: successData?.signUpViaName,
						mastTentTypeUuid: successData?.mastTentTypeUuid,
					})
				)

				secureLocalStorage.setItem('userRole', successData?.userRole)
				secureLocalStorage.setItem('profilepic', successData?.profileUuid)
				secureLocalStorage.setItem('countryCode', initialCountryCode?.mastLookupKey || '')
				let phoneNumber = _.isEqual(successData?.signUpViaName, 'lyfngo') && successData?.userMobile
				secureLocalStorage.setItem('countryName', initialCountryCode?.country)
				secureLocalStorage.setItem('countryNameAbbreviation', initialCountryCode?.countryCode)
				secureLocalStorage.setItem('phoneNumberLength', phoneNumber.length)
				secureLocalStorage.setItem('isTokenValid', successData?.isTokenValid)
				secureLocalStorage.setItem('localErrorCaptcha', false)
				dispatch(
					PhoneNumberLength({
						phoneNumberLength: phoneNumber.length,
					})
				)
				// if (
				// 	(successData?.isMobile === false || successData?.isMobile === 'false') &&
				// 	(successData?.isPractice === false || successData?.isPractice === 'false') &&
				// 	(successData?.isModuleSelected === false || successData?.isModuleSelected === 'false')
				// ) {
				// 	sendOtpIndivi(
				// 		successData?.userMobile,
				// 		successData?.userEmail,
				// 		successData?.data?.uuid,
				// 		successData?.countryCode,
				// 		successData?.data?.userType
				// 	)
				// }
				// else if (successData.isOtp === true || successData.isOtp === 'true') {
				// 	secureLocalStorage.setItem('otp', res?.data?.data?.otp)
				// 	dispatch(
				// 		showMessage({
				// 			message: 'OTP sent successfully',
				// 			autoHideDuration: 3000,
				// 			anchorOrigin: {
				// 				vertical: 'top',
				// 				horizontal: 'right',
				// 			},
				// 			variant: 'success',
				// 		})
				// 	)
				// 	history.push('/otp')
				// }
				updateDeviceDetails(
					successData?.data?.userType,
					successData?.data?.uuid,
					successData?.isPractice,
					successData?.groupId,
					successData?.isEmail,
					successData?.isMobile,
					isWelcome,
					successData?.userEmail,
					successData?.userRole,
					successData?.data?.identityUuid,
					successData?.owner,
					successData?.isModuleSelected,
					successData?.signUpViaName
				)
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				setErrorMessage(getResponseMessage(successData, errorJson))
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
			if (failureData.message === 'ERR_LIMIT') {
				dispatch(hideLoader())
				setShowCaptcha(true)
				getCaptcha()
				setValue('captcha', '')
			}
			if (failureData.message === 'ERR_CAPTCHA') {
				dispatch(hideLoader())
				setShowCaptcha(true)
				getCaptcha()
				setValue('captcha', '')
			}
		}
		loginApi.setLogin(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const sendOtpIndivi = (mobileNo, email, uuid, countryCode, userType) => {
		// setCounter(counter + 1)
		// secureLocalStorage.setItem('resendOtpBtn', counter)
		// if (counter === 2) {
		// 	setDisable(true)
		// 	setCheckColor(!checkColor)
		// } else {
		// 	setDisable(false)
		// }
		dispatch(showLoader('Loading please wait...'))
		const body = {
			countryCode: countryCode,
			mobileNo: mobileNo,
			email: email,
			userType: userType,
			uuid: uuid,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			// setOtpNumber('')
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(setOpenOtpModal({ open: true }))
				let successMessage = successData.message
				if (successMessage === 'suc_sendOtp') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson, 'OTP sent successully'),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
				}
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		OtpApi.resendOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const roleNavigated = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		let uuid = secureLocalStorage.getItem('uuid')
		let mastUuid = secureLocalStorage.getItem('mastuuid')
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData:
							{ ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer, isConsult: true } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(dynamicMenuFetching())
		DynamicMenuApi.getDynamicMenu(uuid, mastUuid).then(onSuccess, onFailure)
	}, [dispatch, history])

	// Login with OTP
	// const onChangeOtp = (e) => {
	// 	setLoginDetails((prevState) => ({
	// 		...prevState,
	// 		password: '',
	// 	}))
	// 	setShowPasswordField(e.target.checked)
	// 	if (e.target.checked === true) {
	// 		setValidationType('OTP')
	// 	} else {
	// 		setValidationType('non')
	// 	}
	// }

	const navigationDetails = useCallback(
		(isPractice, isMobile, isModuleSelected) => {
			if (
				(isModuleSelected === true || isModuleSelected === 'true') &&
				(isPractice === true || isPractice === 'true') &&
				(isMobile === true || isMobile === 'true')
			) {
				roleNavigated()
			} else if (
				(isModuleSelected === false || isModuleSelected === 'false') &&
				(isPractice === false || isPractice === 'false') &&
				(isMobile === true || isMobile === 'true')
			) {
				history.push('/register?tab=setupbusiness')
			} else if (
				(isModuleSelected === false || isModuleSelected === 'false') &&
				(isPractice === true || isPractice === 'true') &&
				(isMobile === true || isMobile === 'true')
			) {
				history.push('/register?tab=moduleselection')
			} else {
				history.push('/')
			}
		},
		[history, roleNavigated]
	)

	useEffect(() => {
		const onSuccess = (res) => {
			setDeviceData(res?.data)
		}
		const onFailure = (err) => {
			console.log(err)
			setDeviceData({})
		}
		loginApi.getDeviceData().then(onSuccess, onFailure)
	}, [])

	// const [generate, setGenerate] = useState(false)
	const updateDeviceDetails = (
		userType,
		uuid,
		isPractice,
		groupId,
		isEmail,
		isMobile,
		isWelcome,
		email,
		userRole,
		identityUuid,
		owner,
		isModuleSelected,
		signUpViaName
	) => {
		const os = browser.os
		const osName = os.split(' ')
		const deviceName = ''
		dispatch(showLoader('Loading please wait...'))
		const body = {
			browserName: browser.name,
			deviceId: '',
			deviceName,
			deviceToken: '',
			ipAddress: deviceData?.ipAddress || '',
			locCity: deviceData?.cityName || '',
			locCountry: deviceData?.countryName || '',
			locRegion: deviceData?.regionName || '',
			loginTime: moment().format(),
			logoutTime: '',
			model: '',
			osName: os,
			osVersion: osName[1],
			qrStatus: false,
			sessionDetails: '',
			status: true,
			version: '',
			tentUserUuid: uuid,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				dispatch(hideLoader())
				// setGenerate(true)
				secureLocalStorage.setItem('AidiDeviceId', successData.data.deviceUuid)
				secureLocalStorage.setItem('AidiBearer', successData?.data?.Bearer || '')
				if (_.isEqual(owner, true)) {
					if (_.isEqual(signUpViaName, 'google')) {
						if (!isPractice && !isModuleSelected) {
							history.push('/register?tab=setupbusiness')
						} else if (isPractice && !isModuleSelected) {
							history.push('/register?tab=moduleselection')
						} else {
							roleNavigated()
						}
					} else if (uuid === '' || uuid === undefined) {
						history.push('/')
					} else if (isMobile && !isPractice && !isModuleSelected) {
						history.push('/register?tab=setupbusiness')
					} else if (isMobile && isPractice && !isModuleSelected) {
						history.push('/register?tab=moduleselection')
					} else if (isMobile && isPractice && isModuleSelected) {
						roleNavigated()
					}
				} else if (_.isEqual(owner, false)) {
					roleNavigated()
				}
				dispatch(updateDeviceId(successData.data.deviceUuid))
				dispatch(updateBearerToken(successData?.data?.Bearer || ''))
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				dispatch(
					showMessage({
						message: getResponseMessage(failureData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			)
		}
		loginApi.saveActiveDevices(userType, identityUuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	//Remember me
	const isPreserveLogin = (e) => {
		if (e.target.checked === true) {
			setChecked(e.target.checked)
			secureLocalStorage.setItem('checked', e.target.checked)
		} else {
			setChecked(e.target.checked)
			secureLocalStorage.removeItem('rememberMail')
			secureLocalStorage.removeItem('checked')
			dispatch(
				Logout({
					rememberMail: null,
					rememberCheck: false,
				})
			)
		}
	}
	const signin = async () => {
		try {
			// setLoading(true)
			return firebase
				.auth()
				.signInWithPopup(new firebase.auth.GoogleAuthProvider())
				.then((response) => {
					// setUser(response.user?.multiFactor?.user?.providerData)
					submitLoginForm(response.user?.multiFactor?.user?.providerData, 'google')
				})
		} finally {
			// setLoading(false)
		}
	}
	const signup = async () => {
		try {
			// setLoading(true)
			return firebase
				.auth()
				.signInWithPopup(new firebase.auth.GoogleAuthProvider())
				.then((response) => {
					// setUser(response.user?.multiFactor?.user?.providerData)
					submitRegisterForm(response.user?.multiFactor?.user?.providerData)
				})
		} finally {
			// setLoading(false)
		}
	}
	useEffect(() => {
		secureLocalStorage.setItem('checked', checked)
	}, [checked])

	return (
		<>
			<div className='w-full'>
				<form className='flex flex-col justify-center w-full' onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name='email'
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className='mb-16'
								type='text'
								name='email'
								color='secondary'
								size='small'
								label='Email id *'
								error={!!errors.email}
								helperText={errors?.email?.message}
								inputProps={{
									maxLength: validationType === 'OTP' ? 15 : 50,
								}}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{/* <IconButton> */}
											<Icon className='text-20' color='action'>
												person
											</Icon>
											{/* </IconButton> */}
										</InputAdornment>
									),
								}}
								variant='outlined'
							/>
						)}
					/>
					{!showPasswordField ? (
						<Controller
							name='password'
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className='mb-16 '
									type='password'
									name='password'
									label='Enter Password *'
									variant='outlined'
									size='small'
									color='secondary'
									inputProps={{ maxLength: 25 }}
									disabled={validationType === 'OTP' ? 1 : 0}
									error={validationType === 'OTP' ? '' : !!errors.password}
									helperText={validationType === 'OTP' ? '' : errors?.password?.message}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										className: 'pr-12',
										type: values.showPassword ? 'text' : 'password',
										endAdornment:
											browserName === 'chrome' || browserName === 'firefox' ? (
												<InputAdornment position='end' className={classes.visibility} style={{ cursor: 'pointer' }}>
													{values.showPassword ? (
														<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
															visibility
														</Icon>
													) : (
														<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
															visibility_off
														</Icon>
													)}
												</InputAdornment>
											) : (
												<InputAdornment position='end' className={classes.visibility} style={{ cursor: 'pointer' }}>
													{values.showPassword ? (
														<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
															visibility
														</Icon>
													) : (
														<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
															visibility_off
														</Icon>
													)}
												</InputAdornment>
											),
									}}
								/>
							)}
						/>
					) : (
						<TextField
							className='mb-16'
							type='password'
							name='password'
							label='Password'
							color='secondary'
							disabled
							InputProps={{
								className: 'visibility pr-12',
								endAdornment:
									browserName === 'chrome' || browserName === 'firefox' ? (
										<InputAdornment position='end' className={classes.visibility} style={{ cursor: 'pointer' }}>
											{values.showPassword ? (
												<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
													visibility
												</Icon>
											) : (
												<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
													visibility_off
												</Icon>
											)}
										</InputAdornment>
									) : (
										<InputAdornment position='end' className={classes.visibility} style={{ cursor: 'pointer' }}>
											{values.showPassword ? (
												<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
													visibility
												</Icon>
											) : (
												<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
													visibility_off
												</Icon>
											)}
										</InputAdornment>
									),
							}}
							variant='outlined'
						/>
					)}
					<div className={classes.remember}>
						{/* <Typography className='flex items-center'>
							<Checkbox color='secondary' name='loginwithotp' id='loginwithotp' onChange={onChangeOtp} />
							<Typography>Login with mobile OTP</Typography>
						</Typography> */}
						<FormGroup>
							<FormControlLabel
								control={<Checkbox color='secondary' name='loginwithotp' id='loginwithotp' checked={checked} onChange={isPreserveLogin} />}
								label='Remember my email'
							/>
						</FormGroup>
						<Typography
							variant='body1'
							onClick={() => history.push('/forgotpassword')}
							style={{ color: '#0062DD', textDecoration: 'underline', cursor: 'pointer' }}
						>
							Forgot password?
							{/* <Link to='/forgotpassword'>Forgot password?</Link> */}
						</Typography>
					</div>
					{showCaptcha && captchaCode?.captchaValue && (
						<Grid container lg={12} spacing={1} pt={1} px={2} className={classes.captchaContainer} alignItems='center' justifyContent='space-between'>
							<Grid item lg={5} style={{ display: 'flex' }}>
								<img src={`data:image/png;base64,${captchaCode?.captchaValue}`} alt='captcha' />
								<IconButton
									className={classes.reloadCaptcha}
									onClick={() => {
										getCaptcha()
										setValue('captcha', '')
										setError('captcha', '')
									}}
								>
									<LoopIcon />
								</IconButton>
							</Grid>

							<Grid item lg={6}>
								<Controller
									name='captcha'
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											type='text'
											className={classes.captchaField}
											name='captcha'
											color='secondary'
											label='Captcha *'
											error={errors?.captcha?.message}
											onKeyDown={handleKeyDown}
											helperText={errors?.captcha?.message}
											inputProps={{
												maxLength: captchaCode?.code?.length,
												wordSpacing: 2,
											}}
											variant='outlined'
											size='small'
										/>
									)}
								/>
							</Grid>
						</Grid>
					)}

					<motion.div whileTap={{ scale: 0.95, transform: 'transition .3s ease' }} style={{ width: '100%' }}>
						<ButtonComm type='submit' variant='contained' customStyle='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit'>
							Submit
						</ButtonComm>
					</motion.div>
					<motion.div whileTap={{ scale: 0.95, transform: 'transition .3s ease' }} style={{ width: '100%' }}>
						<Button size='medium' className={classes.googleBtn} onClick={() => signin()}>
							<span>
								<img src={GoogleIcon} alt='google-icon' width={30} height={30} />
							</span>
							Login with Google
						</Button>
					</motion.div>

					<div style={{ marginBlockStart: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<div style={{ width: 128, background: '#c3c3c3', height: 1, marginInlineEnd: 8 }}></div>
						or
						<div style={{ width: 128, background: '#c3c3c3', height: 1, marginInlineStart: 8 }}></div>
					</div>
					<div className='flex flex-col items-center justify-center'>
						<div>
							<span className='font-semibold mr-8'> Don't have an account?</span>
							<Link className='font-500' to='/register' style={{ color: themesConfig?.lyfngo?.palette?.primary?.main }}>
								Sign up
							</Link>
						</div>
					</div>
					<motion.div whileTap={{ scale: 0.95, transform: 'transition .3s ease' }} style={{ width: '100%' }}>
						<Button size='medium' className='googleBtn' onClick={() => signup()}>
							<span>
								<img src={GoogleIcon} alt='google-icon' width={30} height={30} />
							</span>
							Sign up with Google
						</Button>
					</motion.div>
					<Typography className='mt-8' variant='body1' align='center' color='error'>
						{errorMessage}
					</Typography>
				</form>
			</div>
			<EmailVerificationModal history={history} />
		</>
	)
}

export default Loginform
