import { useTimeout } from '@fuse/hooks'
import PropTypes from 'prop-types'
import { useState } from 'react'
import clsx from 'clsx'
import Lottie from 'react-lottie'
import Loading from '../../../lottieFiles/loading1.json'

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: Loading,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}

function FuseLoading(props) {
	const [showLoading, setShowLoading] = useState(!props.delay)

	useTimeout(() => {
		setShowLoading(true)
	}, props.delay)

	return (
		<div className={clsx('flex flex-1 flex-col items-center justify-center p-24', !showLoading && 'hidden')}>
			{/* <Lottie options={defaultOptions} height={180} width={260} /> */}
			<Lottie options={defaultOptions} height={200} width={280} />
		</div>
	)
}

FuseLoading.propTypes = {
	delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

FuseLoading.defaultProps = {
	delay: false,
}

export default FuseLoading
