import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import ProfileTab from '../ProfileTab'
const useStyles = makeStyles((theme) => ({
	addProfileMain: {
		'& .MuiDialog-paper': {
			alignItems: 'center',
			maxWidth: 460,
			paddingBlock: 20,
			gap: 28,
		},
	},
}))
export default function AddProfile({ open, handleClickOpen, handleClose, isOwnerLive }) {
	const classes = useStyles()
	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title' className={classes.addProfileMain}>
				<DialogTitle style={{ color: '#263238', fontSize: '14px' }} id='responsive-dialog-title'>
					{'Create Profile for'}
				</DialogTitle>
				<ProfileTab isOwnerLive={isOwnerLive} />
			</Dialog>
		</div>
	)
}
