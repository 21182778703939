import OtpVerification from './index'

const OtpVerificationConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: '/otpverification',
			component: OtpVerification,
		},
	],
}

export default OtpVerificationConfig
