import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	accordionMain: {},
	weektext: {
		textTransform: 'uppercase',
		fontSize: 14,
		fontWeight: 500,
	},
	dayText: {
		fontSize: 13,
		fontWeight: 500,
	},
	timeText: {
		fontSize: 13,
		fontWeight: 500,
	},
	tittleText: {
		fontSize: 13,
		fontWeight: 500,
	},
	accordionSummary: {
		paddingBlock: '6px !important',
	},
	weekDetails: {
		display: 'flex',
		gap: 12,
		padding: 12,
		borderLeft: '4px solid #088424',
		alignItems: 'center',
		background: '#08842433',
		color: '#088424',
		maxWidth: 'fit-content',
	},
	weekDetailsOne: {
		display: 'flex',
		gap: 12,
		padding: 12,
		borderLeft: '4px solid #847808',
		alignItems: 'center',
		background: '#84780833',
		color: '#847808',
		maxWidth: 'fit-content',
	},
	countDetailssection: {
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	total: {
		fontSize: 13,
		color: '#000000',
		fontWeight: 500,
	},
	accordionSection: {
		padding: 16,
	},
	dividerColor: {
		background: 'rgb(112, 112, 112,0.5)',
	},
	dayDetails: {
		alignItems: 'center',
		borderBottom: '0.5px dashed rgb(112, 112, 112,0.5)',
		'&:last-child ': {
			borderBottom: 'none',
		},
	},
	scheduleWrapper: {
		borderLeft: '0.5px solid rgb(112, 112, 112,0.5)',
		[theme.breakpoints.down('sm')]: {
			borderLeft: 'none',
		},
	},
}))
export default useStyles
