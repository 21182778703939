import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		borderRadius: 4,
		border: '1px solid #727272',
	},
	gridMain: {
		borderRadius: 28,
		// width: 380,
		border: '1px solid #727272',
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 24,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
	},
	advanceAutoComplete: {
		'& .MuiAutocomplete-inputRoot': {
			color: 'rgb(0,0,0,0.7)',
			'& .MuiAutocomplete-input': {},
		},
		'& .MuiOutlinedInput-root': {
			padding: 0,
		},
	},
	gridMainTwo: {
		alignItems: 'center',
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},

	popperStyle: {
		zIndex: 100,
		'& .MuiAutocomplete-listbox': {
			background: '#fff',
			padding: '12px 4px',
		},
	},
	searchAutoComplete: {
		'& .MuiAutocomplete-inputRoot': {
			paddingLeft: 0,
			color: 'rgb(0,0,0,0.7)',
			'& .MuiAutocomplete-input': {
				paddingLeft: 0,
			},
		},
	},
	profilePic: {
		borderRadius: '100%',
		width: 34,
		height: 34,
	},
	searchOptionLabel: {
		paddingBlock: 4,
		paddingInline: 4,
		fontSize: 14,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: 4,
		'&:hover': {
			background: 'rgb(217, 217, 217,0.6)',
		},
	},
	profileId: {
		fontStyle: 'italic',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	clients_list_item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
}))
export default useStyles
