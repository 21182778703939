import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getTaxes: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_BILLING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},

	getCategories: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_CATEGORIES, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},

	getProductsList: (mastuuid, searchParams = false) => {
		return axios.get(API_ENDPOINTS.GET_PRODUCT_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
			params: { ...searchParams },
		})
	},

	addNewProduct: (data, mastuuid) => {
		return axios.post(`${API_ENDPOINTS.ADD_PRODUCT}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				path: { mastuuid },
			},
		})
	},

	updateProduct: (data, itemUuid) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_PRODUCT}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				path: { itemUuid },
			},
		})
	},

	deleteProduct: (itemUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_PRODUCT}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				path: { itemUuid },
			},
		})
	},
}
