import { Typography, Grid } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import useStyles from './style'
import _ from 'lodash'
import GetPlanList from '../../../../../services/Communication/Plan/api'
import ListManage from './ListManagement/ListManagement'
import PlanDetails from './PlanDetails/index'
import { getResponseMessage } from '../../../../../../src/utils'
import { showLoader, hideLoader } from 'services/loader/action'
import { useSelector, useDispatch } from 'react-redux'
import { closePrompt } from '../../../../../../src/services/Prompt/actions'
import { showMessage } from 'app/store/fuse/messageSlice'
import UpdatePlan from '../../../../../../src/services/Communication/Plan/api'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, PERMISSION_TYPE, FEATURES_LIST } from '../../../../../constants'
function TreatCommunicationPage({ history, match, planName, setListOne, listOne, setOpen, open }) {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const { mastuuid } = state
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const dispatch = useDispatch()
	const [profileKey, setprofileKey] = useState(false)
	const [isClick, setIsClick] = useState(false)
	const [planDetails, setPlanDetails] = useState([])
	const [plan, setPlan] = useState({})
	const planUuid = _.get(match, 'params.id', '')
	const [detailsLoading, setDetailsLoading] = useState(false)
	const [listLoading, setListLoading] = useState(false)
	const [count, setCount] = useState(0)
	const communicationPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.COMMUNICATION_PLAN)

	//Start : Plan Deatils
	const getPlanIndividualDetails = useCallback((id) => {
		const onSuccess = (res) => {
			setDetailsLoading(false)
			if (res?.data?.status === 'success') {
				setPlanDetails(res?.data?.data)
				setPlan((prevState) => ({ ...prevState, articlesCount: res.data.articlesCount, remindersCount: res.data.remindersCount }))
			} else {
				setPlanDetails([])
			}
		}
		const onFailure = () => {
			setDetailsLoading(false)
			setPlanDetails([])
		}
		setDetailsLoading(true)
		if (id) {
			GetPlanList.getPlanListDetails({ planId: id }).then(onSuccess, onFailure)
		}
	}, [])
	//End : Plan Deatils
	const onListClick = (id) => {
		history.replace(`/communication/view/${id}`)
		setIsClick(true)
		setprofileKey(true)
	}
	//Start : GET plan details //
	const getPlanList = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setListLoading(false)
				setListOne(res?.data?.data)
			}
		}
		const onFailure = () => {
			setListOne([])
			setListLoading(false)
		}
		setListLoading(true)
		GetPlanList.getPlanDetails(mastuuid).then(onSuccess, onFailure)
	}, [mastuuid])

	useEffect(() => {
		getPlanIndividualDetails(planUuid)
	}, [getPlanIndividualDetails, planUuid])

	useEffect(() => {
		getPlanList()
	}, [getPlanList])

	//End : GET plan details  //
	// Start : Create plan //
	const createPlan = (e) => {
		const formData1 = new FormData()
		formData1.append('tentId', mastuuid)
		formData1.append('title', e?.planName)
		dispatch(showLoader('Creating a plan please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())

			if (res?.data?.status === 'success') {
				setOpen(false)
				setListOne((prevState) => [
					{ mastTentUuid: mastuuid, planMappingPatientCount: 'never used', planUuid: res?.data?.data?.planUuid, title: e?.planName },
					...prevState,
				])
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Plan created Successfully!'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.createPlanName(formData1).then(onSuccess, onFailure)
	}
	// End : Create plan //
	//Start : Edit plan name //
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updatePlanName = useCallback((e) => {
		dispatch(showLoader('Updating a plan name please wait...'))
		const formData2 = new FormData()
		formData2.append('Uuid', planUuid)
		formData2.append('title', e?.planName)

		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				const tempList = [...listOne]
				const currentIdx = _.findIndex(tempList, { planUuid })
				tempList[currentIdx] = { ...tempList[currentIdx], title: e?.planName }
				setListOne(tempList)
				setPlanDetails((prevState) => ({ ...prevState, title: e.planName }))
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.planNameUpdate(formData2).then(onSuccess, onFailure)
	})
	//End : Edit plan name //
	//Start: copy plan name
	const duplicatePlanName = (id) => {
		dispatch(showLoader('Loading please wait...'))
		const data = {
			planId: planUuid,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				getPlanList()
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.planNameDuplication(data).then(onSuccess, onFailure)
	}
	//End: copy plan name
	//start: delete plan
	const deletePlanName = () => {
		dispatch(showLoader('Loading please wait...'))
		const data = {
			uuid: planUuid,
		}
		const onSuccess = (res, err) => {
			dispatch(hideLoader())
			dispatch(closePrompt())
			if (res?.data?.status === 'success') {
				let removedList = _.filter(listOne, (o) => o?.planUuid !== planUuid)
				setListOne(removedList)
				history.push('/communication')
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.planDelete(data).then(onSuccess, onFailure)
	}

	const onArticleAddSuccess = () => {
		getPlanList()
		planUuid && getPlanIndividualDetails(planUuid)
	}

	//end: delete plan
	//Start : Stop Plan//
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const stopAllPlan = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const data = {
			planId: planUuid,
		}
		const onSuccess = (res, err) => {
			dispatch(hideLoader())
			dispatch(closePrompt())
			if (res?.data?.status === 'success') {
				getPlanList()
				getPlanIndividualDetails(planUuid)
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Plan stoped successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.planStop(data).then(onSuccess, onFailure)
	})
	//End : Stop Plan//
	return (
		<>
			<Grid container spacing={2} className={classes.treatmentCommunicationMainTwo} justifyContent='space-between'>
				<Grid item lg={3.2} md={3.2} sm={12} className={classes.detailsPart} style={{ paddingTop: 0 }}>
					<div className={classes.createCommunictaionpart}>
						<ListManage
							listOne={listOne}
							loading={listLoading}
							history={history}
							open={open}
							setOpen={setOpen}
							createPlan={createPlan}
							isClick={isClick}
							profileKey={profileKey}
							plans={plan}
							onListClick={(id) => onListClick(id)}
							planUuid={planUuid}
						/>
					</div>
				</Grid>
				<Grid item lg={8.5} md={8.5} sm={12} className={classes.detailsCommunictaionpart}>
					<div className={classes.detailsHeader}>
						{!_.isEmpty(planUuid) ? (
							<>
								{_.get(communicationPerms, PERMISSION_TYPE.VIEW, false) && (
									<PlanDetails
										listOne={listOne}
										updatePlanName={updatePlanName}
										getPlanList={getPlanList}
										duplicatePlanName={duplicatePlanName}
										deletePlanName={deletePlanName}
										isClick={isClick}
										match={match}
										getPlanIndividualDetails={getPlanIndividualDetails}
										planDetails={planDetails}
										setPlanDetails={setPlanDetails}
										plans={plan}
										stopAllPlan={stopAllPlan}
										onArticleAddSuccess={onArticleAddSuccess}
										onRemindAddSuccess={onArticleAddSuccess}
										onReminderUpdateSuccess={onArticleAddSuccess}
										loading={detailsLoading}
										planUuid={planUuid}
										planName={planName}
										count={count}
										setCount={setCount}
									/>
								)}
							</>
						) : (
							<>
								{/* {_.isEmpty(listOne) ? (
									<Typography variant='h6' className={classes.addPlanMsg}>
										Please create a new communication plan
									</Typography>
								) : (
									<div className={classes.addPlanMsg}>
										<img
											width='200'
											height='200'
											src='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png'
											srcset='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png 100w, ${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png.png 40w 40h, ${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png 100w'
											alt='Choose plan'
										/>
										<Typography variant='h6' className={classes.choosePlan}>
											Please choose a plan name to view the details
										</Typography>
									</div>
								)} */}
								<Grid item lg={12} md={12} sm={12} className={classes.detailsCommunictaionpart}>
									{_.isEmpty(listOne) ? (
										<Typography variant='h6' className={classes.addPlanMsg}>
											Please create a new communication plan
										</Typography>
									) : (
										<div className={classes.addPlanMsg}>
											<img
												src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png`}
												className={classes.choosePlanImage}
												alt='Choose plan'
											/>
											<Typography variant='h6' className={classes.choosePlan}>
												Please choose a plan name to view the details
											</Typography>
										</div>
									)}
								</Grid>
							</>
						)}
					</div>
				</Grid>
			</Grid>
			{/* <Grid container>
				<Grid item lg={12} sm={12} md={12} className={classes.treatmentCommunicationMainTwo}>
					<Grid container justifyContent='space-between' className={classes.detailsPart}>
						<Grid item lg={2.8} md={2.8} sm={12} className={classes.createCommunictaionpart}>
							<ListManage
								listOne={listOne}
								loading={listLoading}
								history={history}
								open={open}
								setOpen={setOpen}
								createPlan={createPlan}
								isClick={isClick}
								profileKey={profileKey}
								plans={plan}
								onListClick={(id) => onListClick(id)}
								planUuid={planUuid}
							/>
						</Grid>
						{!_.isEmpty(planUuid) ? (
							<Grid item lg={9} md={9} sm={12} className={classes.detailsCommunictaionpart}>
								<Grid container alignItems='center' justifyContent='space-between' className={classes.detailsHeader}>
									{_.get(communicationPerms, PERMISSION_TYPE.VIEW, false) && (
										<PlanDetails
											listOne={listOne}
											updatePlanName={updatePlanName}
											getPlanList={getPlanList}
											duplicatePlanName={duplicatePlanName}
											deletePlanName={deletePlanName}
											isClick={isClick}
											match={match}
											getPlanIndividualDetails={getPlanIndividualDetails}
											planDetails={planDetails}
											setPlanDetails={setPlanDetails}
											plans={plan}
											stopAllPlan={stopAllPlan}
											onArticleAddSuccess={onArticleAddSuccess}
											onRemindAddSuccess={onArticleAddSuccess}
											onReminderUpdateSuccess={onArticleAddSuccess}
											loading={detailsLoading}
											planUuid={planUuid}
											planName={planName}
											count={count}
											setCount={setCount}
										/>
									)}
								</Grid>
							</Grid>
						) : (
							<Grid item lg={9} md={9} sm={12} className={classes.detailsCommunictaionpart}>
								{_.isEmpty(listOne) ? (
									<Typography variant='h6' className={classes.addPlanMsg}>
										Please create a new communication plan
									</Typography>
								) : (
									<div className={classes.addPlanMsg}>
										<img
											width='200'
											height='200'
											src='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png'
											srcset='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png 100w, ${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png.png 100w, ${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png 100w'
											alt='Choose plan'
										/>
										<Typography variant='h6' className={classes.choosePlan}>
											Please choose a plan name to view the details
										</Typography>
									</div>
								)}
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid> */}
		</>
	)
}
export default TreatCommunicationPage
