import { React } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Grid, TextField } from '@mui/material'
import useStyles from './style'
import CustomDialog from 'app/sharedComponents/CustomDialog'

export default function CreateNewPlan({ open, createPlan, setOpen }) {
	const classes = useStyles()
	const onSubmit = (e) => {
		createPlan(e)
		formik.resetForm()
	}
	const formik = useFormik({
		initialValues: {
			planName: '',
		},
		validationSchema: yup.object({
			planName: yup
				.string()
				.min(4, 'Plan name is minimum 4 characters')
				.max(50, 'Plan name is maximum 50 characters')
				.required('Please enter the plan name'),
		}),
		onSubmit: onSubmit,
	})
	const handleClose = (e, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpen(false)
			formik?.resetForm()
		}
	}
	return (
		<div>
			<CustomDialog
				onClose={handleClose}
				open={open}
				headerText='Create communication plan'
				maxWidth='sm'
				formikValues={formik}
				onSubmit={formik.handleSubmit}
			>
				<div className={classes.newPlanMain}>
					<Grid container>
						<Grid item lg={12} md={12} sm={12}>
							<TextField
								id='planname'
								name='planname'
								multiline
								type='text'
								size='small'
								fullWidth
								label='Enter the plan name*'
								variant='outlined'
								color='secondary'
								value={formik?.values?.planName}
								onChange={(e, value) => {
									formik?.setFieldValue('planName', e.target.value.replace(/[^A-Za-z0-9 ]/gi, ''))
								}}
								error={formik.touched.planName && formik.errors.planName}
								helperText={formik.touched.planName && formik.errors.planName && formik.errors.planName}
							/>
						</Grid>
					</Grid>
				</div>
			</CustomDialog>
		</div>
	)
}
