import { Card, CardContent, Button, Typography, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { showMessage } from 'app/store/fuse/messageSlice'
import OtpInput from 'react-otp-input'
import history from '@history'
import { useDispatch, useSelector } from 'react-redux'
import OtpApi from '../../../services/otp/api'
import { hideLoader, showLoader } from 'services/loader/action'
import { encryption, decryption, failureLogin, getResponseMessage } from 'utils'
import secureLocalStorage from 'react-secure-storage'
import themesConfig from 'app/fuse-configs/themesConfig'

const Root = styled('div')(({ theme }) => ({
	background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,

	'& .Otp-leftSection': {
		[theme.breakpoints.down('sm')]: {
			borderRadius: '2rem',
		},
		[theme.breakpoints.down('md')]: {
			borderRadius: '2rem',
		},
	},
	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	'& .cardContent': {
		margin: '0px auto',
		paddingInline: '5.4rem',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	'& .numbercontent': {
		justifyContent: 'center',
		alignItems: 'center',
	},
	'& .text': {
		margin: '16px 0',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid #6b7280',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .resendButton': {
		cursor: 'pointer',
		color: themesConfig.greyDark.palette.primary.link,
	},
	'& .backButton': {
		background: themesConfig.greyDark.palette.primary.main,
		marginInline: '20px',
		'& .MuiSvgIcon-root': {
			fill: '#fff',
		},
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.link,
		},
	},
}))
const ButtonStyle = styled('Button')(() => ({
	'& .btn': {
		color: '#FFFFFF',
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		width: '195px',
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.link,
		},
	},
}))

function OtpModule() {
	const [otpNumber, setOtpNumber] = useState('')
	const [otpError, setOtpError] = useState('')
	const dispatch = useDispatch()
	const state = useSelector((states) => states.Authentication)
	const errorJson = useSelector((states) => states.errorMessages || {})
	const { uuid, userType, userEmail, identityUuid } = state
	const handleChange = (value) => setOtpNumber(value)
	// eslint-disable-next-line no-undef

	const submitOtpConfirmation = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			otpNumber: otpNumber,
			sentEmail: false,
			userType: userType,
			uuid: identityUuid,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson, 'OTP verified  Successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				history.push('/resetpassword')
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.setOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const validate = () => {
		if (otpNumber === '') {
			setOtpError('Please enter OTP')
		} else if (otpNumber.length !== 4) {
			setOtpError('Enter a valid otp')
		} else if (otpNumber.length === 4) {
			setOtpError('')
			submitOtpConfirmation()
		}
	}
	const [counter, setCounter] = useState(0)
	const [disable, setDisable] = useState(false)
	const [checkColor, setCheckColor] = useState('')
	const colorStyle = { color: 'e22c34' }
	const resendOtp = () => {
		setCounter(counter + 1)
		secureLocalStorage.setItem('resendOtpBtn', counter)
		if (counter === 2) {
			setDisable(true)
			setCheckColor(!checkColor)
		} else {
			setDisable(false)
		}
		dispatch(showLoader('Loading please wait...'))
		const body = {
			userName: userEmail,
			userType: 'TNT',
			isForgotPassword: true,
			uuid: identityUuid,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			setOtpNumber('')
			dispatch(hideLoader())
			if (successData.status === 'success') {
				let successMessage = successData.message
				if (successMessage === 'suc_otpSent') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson, 'OTP resent successully'),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
				}
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.resendOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const handleClick = () => {
		history.goBack()
	}
	const onSubmit = (e) => {
		e.preventDefault()
		validate()
	}

	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				{/* eslint-disable-next-line prettier/prettier */}
				<div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='flex w-full max-w-400 md:max-w-3xl rounded-20  overflow-hidden justify-center '
				>
					<Card className='Otp-leftSection flex flex-col w-full max-w-360 items-start justify-evenly shadow-0 p-16' square>
						<IconButton className='backButton' onClick={handleClick}>
							<ArrowBackIcon />
						</IconButton>
						<CardContent className='cardContent flex flex-col items-start justify-center w-full max-w-320'>
							<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-start mb-8'>
									<div>
										<Typography className='text-20 font-semibold logo-text' color='#6b7280'>
											OTP Verification
										</Typography>
									</div>
								</div>
							</div>
							<Typography className='numbercontent' justifyContent='center' alignItems='center' variant='caption' color='#6b7280'>
								Enter the OTP sent to
							</Typography>
							<Typography className='numbercontent' justifyContent='center' alignItems='center' fontWeight='bold' variant='caption' color='#6b7280'>
								{userEmail}
							</Typography>
							<form onSubmit={(e) => onSubmit(e)}>
								<div className='flex'>
									<OtpInput
										name='otp'
										type='password'
										value={otpNumber}
										onChange={handleChange}
										className='otp-input flex justify-center'
										numInputs={4}
										isInputNum={true}
										separator={<span>&nbsp;&nbsp;&nbsp;</span>}
									/>
								</div>
								{otpError ? (
									<Typography align='center' color='error'>
										{otpError}
									</Typography>
								) : null}
								<Typography variant='body1' align='left' className='mt-8 flex items-center whitespace-nowrap'>
									Didn't receive OTP?{' '}
									<Button className='resendButton' style={checkColor ? colorStyle : null} onClick={resendOtp} disabled={disable ? true : false}>
										Resend OTP
									</Button>
								</Typography>
								<ButtonStyle>
									<Button type='submit' variant='contained' className='btn w-165 mx-auto mt-16' aria-label='Otp' value='submit' onClick={onSubmit}>
										Verify & Proceed
									</Button>
								</ButtonStyle>
							</form>
						</CardContent>
					</Card>

					<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
						<div className='max-w-320'>
							<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
								<img src='assets/images/otp/Otp screen.svg' alt='logo' />
							</div>
						</div>
					</div>
				</div>
			</Root>
		</>
	)
}

export default OtpModule
