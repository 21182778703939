import * as React from 'react'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import moment from 'moment'
import { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { NavigateNext, NavigateBefore } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { customDateFormat } from 'utils'

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		display: 'flex',
		alignItems: 'center',
		'& fieldset': {
			border: 'none',
			borderRadius: 0,
			paddingBlock: 6,
		},
	},
	DatePieckercontainer: {
		maxWidth: 140,
		borderTop: '1px solid rgb(196 196 196)',
		borderBottom: '1px solid rgb(196 196 196)',
	},
	prevIconButton: {
		border: '1px solid rgb(196 196 196)',
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
		paddingBlock: 0.5,
		'& .MuiIconButton-root': {
			paddingBlock: 6,
		},
	},
	nextIconButton: {
		border: '1px solid rgb(196 196 196)',
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		paddingBlock: 0.5,
		'& .MuiIconButton-root': {
			paddingBlock: 6,
		},
	},
}))

export default function SingleDatePicker({ getDateRangeForPrint, getDateRange }) {
	const classes = useStyles()
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	const [value, setValue] = useState(new Date())

	useEffect(() => {
		getDateRange([moment(value).format('YYYY-MM-DD')])
		getDateRangeForPrint(customDateFormat(new Date(value), dateFormatForViewer))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])
	return (
		<div className={classes.root}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<div className={classes.container}>
					<div className={classes.prevIconButton}>
						<IconButton
							aria-label='prev'
							size='medium'
							onClick={() => {
								setValue(moment(value).subtract(1, 'days'))
							}}
						>
							<NavigateBefore />
						</IconButton>
					</div>

					<div className={classes.DatePieckercontainer}>
						<DatePicker
							views={['day']}
							value={value}
							inputFormat={dateFormat}
							disableFuture={true}
							onChange={(newValue) => {
								setValue(newValue)
							}}
							renderInput={(params) => <TextField size='small' color='primary' {...params} helperText={null} />}
						/>
					</div>

					<div className={classes.nextIconButton}>
						<IconButton
							aria-label='next'
							size='medium'
							onClick={() => {
								setValue(moment(value).add(1, 'days'))
							}}
							disabled={moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') ? true : false}
						>
							<NavigateNext />
						</IconButton>
					</div>
				</div>
			</LocalizationProvider>
		</div>
	)
}
