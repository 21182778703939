import { makeStyles } from '@mui/styles'
import { Autocomplete, TextField } from '@mui/material'
import { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateMastUuid, updateEmail, updateTentUserUuid, setUserName, updateOwner } from '../../../../../session/actions'
import PracticeList from '../../../../../../services/CalendarApi/PracticeList/api'
import _ from 'lodash'
import secureLocalStorage from 'react-secure-storage'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiInputBase-root ': {
			color: '#FFFFFF',
			'& .MuiSvgIcon-root ': {
				color: '#FFFFFF',
			},
		},
		'& .MuiAutocomplete-endAdornment': {
			position: 'relative',
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qvsu1l-MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
			paddingRight: 26,
		},
	},
}))

function PracticeDropdown(props) {
	const { setCurrentPracticeData = () => {} } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid } = state
	const practicelist = useSelector((state) => state.dynamicMenu?.practiceListGlobal)
	const [practiceName, setPracticeName] = useState('')

	useEffect(() => {
		if (!_.isEmpty(practicelist)) {
			let currentPractice = _.find(practicelist, { masterTenantUuid: mastuuid, tentUserUuid: uuid })
			setPracticeName(currentPractice || {})
			setCurrentPracticeData(currentPractice)
			secureLocalStorage.setItem('upiId', currentPractice?.upiId)
			secureLocalStorage.setItem('upiName', currentPractice?.upiName)
		}
	}, [mastuuid, practicelist, setCurrentPracticeData, uuid])

	return (
		<div className={classes.root}>
			<Autocomplete
				id='combo-box-demo'
				disablePortal
				options={practicelist}
				onChange={(e, value) => {
					setPracticeName(value)
					setCurrentPracticeData(value)
					dispatch(setUserName(value?.tentUserFirstName))
					dispatch(updateEmail(value?.tentUserEmail))
					dispatch(updateMastUuid({ mastuuid: value?.masterTenantUuid || mastuuid, xeroStatus: value?.xeroConfigStatus || false }))
					dispatch(updateTentUserUuid(value?.tentUserUuid || uuid))
					dispatch(updateOwner(value?.owner))
					secureLocalStorage.setItem('upiId', value?.upiId)
					secureLocalStorage.setItem('upiName', value?.upiName)
				}}
				getOptionLabel={(option) => option.practiceName || ''}
				value={practiceName}
				getOptionSelected={(option, value) => {
					return option?.practiceName === value?.practiceName
				}}
				className={`${classes.profileEstablishment} practice`}
				sx={{ width: 210 }}
				clearIcon=''
				renderInput={(params) => (
					<TextField
						{...params}
						variant='standard'
						size='small'
						placeholder='Establishment Name'
						className={classes.establishmentName}
						InputProps={{ ...params.InputProps, disableUnderline: true }}
						InputLabelProps={{ shrink: false }}
					/>
				)}
			/>
		</div>
	)
}

export default PracticeDropdown
