import React from 'react'
import useStyles from './style'
import { useSelector } from 'react-redux'
import { FormControlLabel, Checkbox, Box, TextField, Typography, Skeleton } from '@mui/material'
import _ from 'lodash'

const Children = ({ name, id, phone, onCheckboxChange, classes, checked }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
			<div className={classes.patientText}>
				<FormControlLabel label={`${name} ${name && '-'} ${id}`} control={<Checkbox checked={checked} onChange={() => onCheckboxChange({})} />} />
				<Typography>{phone ? phone : '-'}</Typography>
			</div>
		</Box>
	)
}

function Patients({ patientList, state, dispatch, loading, checkAll, setCheckAll, filterTxt, setFilterTxt, templateType, list, setList }) {
	const classes = useStyles()
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName || {})
	const isCare = _.isEqual(groupName, 'health care')
	const isCareContent = isCare ? 'Patients' : ' Clients'

	const onFilterChange = (e) => {
		setFilterTxt(e.target.value)
	}
	React.useEffect(() => {
		const filterdList = _.filter(
			patientList,
			(o) =>
				_.includes(_.toLower(o?.custName), filterTxt) ||
				_.includes(_.toUpper(o?.custName), filterTxt) ||
				_.includes(_.toLower(o?.custCustomId), filterTxt) ||
				_.includes(o?.custMobileNo, filterTxt)
		)
		setList(filterdList)
	}, [filterTxt, patientList, setList])

	const onCheckboxChange = (item, idx) => {
		let isExist = _.findIndex(state?.patientList, (o) => o?.custUuid === item?.custUuid) > -1
		if (isExist) {
			let removedData = _.filter(state?.patientList, (o) => o?.custUuid !== item?.custUuid)
			dispatch({
				type: 'setPatientList',
				data: removedData,
			})
		} else {
			let addedData = [...state?.patientList, { ...item }]
			dispatch({
				type: 'setPatientList',
				data: addedData,
			})
		}
	}

	React.useEffect(() => {
		!_.isEmpty(state?.patientList) && setCheckAll(state?.patientList?.length === patientList.length)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.patientList, patientList])
	const toggleCheckAll = (e) => {
		if (!checkAll === true) {
			dispatch({
				type: 'setPatientList',
				data: patientList,
			})
		} else {
			dispatch({
				type: 'setPatientList',
				data: [],
			})
		}
		setCheckAll(!checkAll)
	}

	return (
		<div className={classes.patientsMain} justifyContent='space-between'>
			<div className={classes.listSection}>
				{!loading && !_.isEmpty(list) && (
					<FormControlLabel
						label='Select All'
						control={<Checkbox id='selectallpatinet' name='selectallpatient' checked={checkAll} onChange={toggleCheckAll} />}
					/>
				)}
				{loading ? (
					<>
						<Skeleton variant='text' height={60} />
						<Skeleton variant='text' height={50} width={'60%'} />
						<Skeleton variant='text' height={50} width={'60%'} />
					</>
				) : (
					<TextField
						fullWidth
						size='small'
						id='searchpatienttextfield'
						name='searchpatienttextfield'
						value={filterTxt}
						onChange={onFilterChange}
						label='Search by name/mobile.no/ID'
						color='secondary'
					/>
				)}
				<div className={classes.listItem}>
					{!loading && !_.isEmpty(list) ? (
						_.map(list, (patient, idx) => {
							const isChecked = _.findIndex(state?.patientList, (o) => o?.custUuid === patient?.custUuid) > -1
							return (
								<Children
									name={patient?.custName}
									id={patient?.custCustomId}
									phone={patient?.custMobileNo}
									onCheckboxChange={() => onCheckboxChange(patient, idx)}
									classes={classes}
									checked={isChecked}
								/>
							)
						})
					) : loading ? (
						<></>
					) : (
						<Typography align='center' className='mt-10'>
							No {isCareContent} Found
						</Typography>
					)}
				</div>
			</div>
		</div>
	)
}

export default Patients
