import { ACTION_TYPES } from '../../../constants'

const initialState = {
	procedureDetails: [],
	drugDetails: [],
	practiceDetails: [],
	openPracticeModal: false,
	instructionDetails: [],
	commissionDetails: [],
	importDropdownList: {},
	drugInstructionDetails: [],
	communicationsFilter: false,
	selectedIntegration: {},
	isUpdateIntegration: false,
}

const SettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.ADD_PROCEDURE_CATALOG: {
			return {
				...state,
				procedureDetails: action.payload,
			}
		}
		case ACTION_TYPES.ADD_COMMISSION_CATALOG: {
			return {
				...state,
				commissionDetails: action.payload,
			}
		}
		case ACTION_TYPES.GET_IMPORT_DROPDOWN: {
			return {
				...state,
				importDropdownList: action.payload,
			}
		}
		case ACTION_TYPES.ADD_DRUG_CATALOG: {
			return {
				...state,
				drugDetails: action.payload,
			}
		}
		case ACTION_TYPES.ADD_PRACTICE_DETAILS: {
			return {
				...state,
				practiceDetails: action.payload,
			}
		}
		case ACTION_TYPES.HANDLE_PRACTICE_MODAL: {
			return {
				...state,
				openPracticeModal: action.payload,
			}
		}
		case ACTION_TYPES.ADD_DIET_INSTRUCTION_CATALOG: {
			return {
				...state,
				instructionDetails: action.payload,
			}
		}
		case ACTION_TYPES.ADD_DRUG_INSTRUCTION: {
			return {
				...state,
				drugInstructionDetails: action.payload,
			}
		}
		case ACTION_TYPES.OPEN_COMMUNICATION_FILTER: {
			return {
				...state,
				communicationsFilter: action.payload,
			}
		}
		case ACTION_TYPES.SELECTED_INTEGRATION: {
			return {
				...state,
				selectedIntegration: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_INTEGRATION: {
			return {
				...state,
				isUpdateIntegration: action.payload,
			}
		}
		default:
			return state
	}
}

export default SettingsReducer
