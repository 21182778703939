import { React, useState, useEffect } from 'react'
import useStyles from './style'
import { FormControlLabel, Checkbox, Box, Typography, Skeleton } from '@mui/material'
import _ from 'lodash'

function Groups({ groupList, state, dispatch, loading }) {
	const classes = useStyles()
	const [groupedList, setGroupedList] = useState([])
	const [keys, setkeys] = useState([])

	useEffect(() => {
		if (!_.isEmpty(groupList)) {
			let grpBy = _.groupBy(groupList, 'type')
			setGroupedList(grpBy)
			let grpKey = _.keys(_.groupBy(groupList, 'type'))
			setkeys(grpKey)
		} else {
			setGroupedList([])
			setkeys([])
		}
	}, [groupList])

	const handleCheckboxChange = (grp) => {
		if (state?.groupList.some((item) => item.groupUuid === grp?.groupUuid)) {
			// removing item,  when click is made on already selected item
			let unSelectingTheSelectedGrp = state?.groupList.filter((s) => s?.groupUuid !== grp?.groupUuid)
			// setSelectedStudents(unSelectingTheSelectedGrp)
			dispatch({
				type: 'setgroupList',
				data: unSelectingTheSelectedGrp,
			})
		} else {
			// adding to the seleted groups
			let selectedGrps = [...state?.groupList, grp]
			dispatch({
				type: 'setgroupList',
				data: selectedGrps,
			})
		}
	}

	return (
		<div className={classes.groupsMain}>
			<div className={classes.listSection}>
				{!loading && !_.isEmpty(keys) ? (
					_.map(keys, (key, idx) => {
						return (
							<>
								<Typography key={idx} className={classes.filterText}>
									{key || 'Custom'}
								</Typography>
								{_.map(groupedList[key], (grp) => (
									<Box key={grp?.groupUuid} sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
										<div className={classes.groupText}>
											<FormControlLabel
												control={
													<Checkbox
														checked={state?.groupList.some((item) => item.groupUuid === grp?.groupUuid)}
														onChange={() => handleCheckboxChange(grp)}
													/>
												}
												label={grp?.tentGroupName || ''}
											/>
											<Typography>{grp?.groupCount}</Typography>
										</div>
									</Box>
								))}
							</>
						)
					})
				) : loading ? (
					<div style={{ paddingInline: 12 }}>
						<Skeleton variant='text' height={60} />
						<Skeleton variant='text' height={50} width={'60%'} />
						<Skeleton variant='text' height={50} width={'60%'} />
						<Skeleton variant='text' height={60} />
						<Skeleton variant='text' height={50} width={'60%'} />
						<Skeleton variant='text' height={50} width={'60%'} />
					</div>
				) : (
					<Typography align='center' className='mt-20' sx={{ color: 'gray', fontSize: 16 }}>
						No Groups Found
					</Typography>
				)}
			</div>
		</div>
	)
}

export default Groups
