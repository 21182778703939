import { ACTION_TYPES } from '../../../../constants'

export const upcomingAppointmentData = (payload) => {
	return {
		type: ACTION_TYPES.UPCOMING_DATAA,
		payload,
	}
}
export const patientVisitCount = (payload) => {
	return {
		type: ACTION_TYPES.PATIENT_VISIT_COUNT_LIST,
		payload,
	}
}
export const pastAppointmentDataAction = (payload) => {
	return {
		type: ACTION_TYPES.PAST_APPOINTMENT_DATA,
		payload,
	}
}
