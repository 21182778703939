import { ACTION_TYPES } from '../../../constants'

export const setCustomerList = (updatedFunction) => ({
	type: ACTION_TYPES.CUSTOMER_LIST,
	payload: updatedFunction,
})
export const setGlobalView = (payload) => ({
	type: ACTION_TYPES.GLOBAL_GRID_VIEW,
	payload,
})
export const setCustomerListEmpty = (payload) => ({
	type: ACTION_TYPES.CUSTOMER_LIST_EMPTY,
	payload,
})
export const openAddClient = (payload) => ({
	type: ACTION_TYPES.OPEN_ADD_CLIENT_MODAL,
	payload,
})
export const closeAddClient = (payload) => ({
	type: ACTION_TYPES.CLOSE_ADD_CLIENT_MODAL,
	payload,
})
export const openAddPatient = (payload) => ({
	type: ACTION_TYPES.OPEN_ADD_PATIENT_MODAL,
	payload,
})
export const closeAddPatient = (payload) => ({
	type: ACTION_TYPES.CLOSE_ADD_PATIENT_MODAL,
	payload,
})
export const setCustomerData = (payload) => ({
	type: ACTION_TYPES.SET_CUSTOMER_DATA,
	payload,
})
export const setUpcomingAppointment = (payload) => ({
	type: ACTION_TYPES.SET_UPCOMING_APPOINTMENT,
	payload,
})
export const setPastAppointment = (payload) => ({
	type: ACTION_TYPES.SET_PAST_APPOINTMENT,
	payload,
})
export const setPracticeListName = (payload) => ({
	type: ACTION_TYPES.SET_PRACTICE_LIST_NAME,
	payload,
})
export const setGlobalAdvancedFilter = (payload) => ({
	type: ACTION_TYPES.GLOBAL_ADVANCED_FILTER,
	payload,
})
