import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Badge, TextField, Typography, Button } from '@mui/material'
import { Videocam, Home, Apartment } from '@mui/icons-material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import themesConfig from 'app/fuse-configs/themesConfig'
import moment from 'moment'
import _ from 'lodash'
import history from '@history'
import { addAppointmentModal } from 'services/Appointments/action'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
		marginBlockEnd: 14,
		padding: 14,
		borderRadius: 10,
		display: 'flex',
		gap: 12,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	calenderSection: {
		maxWidth: 332,
		'& .MuiCalendarPicker-root': {
			'& .Mui-selected': {
				backgroundColor: themesConfig.greyDark.palette.primary.main,
				color: '#FFF',
			},
			'& .PrivatePickersSlideTransition-root': {
				minHeight: 240,
			},
		},
	},

	buttonContainer: {
		textAlign: 'center',
		marginTop: -12,
		paddingInlineEnd: 22,
		'& .MuiButton-root': {
			borderRadius: 12,
		},
		'& .MuiButton-containedPrimary': {
			backgroundColor: '#2EB2FF',
			color: '#FFF',
		},
	},
	appointmentsList: {
		background: '#FFF',
		width: '100%',
		borderRadius: 20,
		boxShadow: '0px 3px 20px #00000012',
		paddingInline: 20,
		paddingBlock: 16,
		overflowY: 'scroll',
		maxHeight: 360,
	},
	appointmentsListNoappt: {
		display: 'flex',
		justifyContent: 'center',
	},
	appointmentCard: {
		display: 'flex',
		gap: 12,
		marginBlockEnd: 6,
	},
	appointmentCardDetails: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	appointmentCardText: {
		'& .MuiLink-root': {
			color: '#ABB2B9 !important',
		},
		'& .MuiTypography-body1': {
			fontWeight: 500,
		},
		'& .MuiTypography-body2': {
			color: '#ABB2B9',
			'& span': {
				marginInlineStart: 14,
			},
		},
		'& a': {
			color: '#ABB2B9',
		},
	},
	appointmentCardIconBox: {
		background: '#EBEDEF',
		padding: 6,
		height: 'fit-content',
		borderRadius: '50%',
		'& .MuiSvgIcon-root': {
			fontSize: 18,
		},
	},
	appointmentCardBadge: {
		'& .MuiBadge-colorPrimary': {
			background: '#28a745',
		},
		'& .MuiBadge-colorSecondary': {
			background: '#e22c24',
		},
		'& .MuiBadge-colorInfo': {
			background: '#0000FF',
		},
		'& .MuiBadge-colorSuccess': {
			background: '#17A2B8',
		},
		'& .MuiBadge-colorWarning': {
			background: '#ffc107',
		},
	},
	noAppointments: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiTypography-subtitle1': {
			color: '#718499',
			textAlign: 'center',
		},
	},
	recordLink: {
		color: '#ABB2B9',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}))

function AppointmentCalender({ dashboardData, getDatetoParent }) {
	const classes = useStyles()
	const [value, setValue] = useState(new Date())
	const dispatch = useDispatch()
	const calenderData = dashboardData?.calendar
	const viewRecord = (custUuid) => {
		//
		history.push({
			pathname: `/patientemr/view/${custUuid}`,
		})
	}

	return (
		<div className={classes.root}>
			<div className={classes.calenderSection}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<StaticDatePicker
						displayStaticWrapperAs='desktop'
						openTo='day'
						value={value}
						onChange={(newValue) => {
							setValue(newValue)
							getDatetoParent(moment(newValue).format('YYYY-MM-DD'))
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
				</LocalizationProvider>
				<div className={classes.buttonContainer}>
					<Button
						color='primary'
						variant='contained'
						onClick={() => {
							dispatch(addAppointmentModal(true))
						}}
					>
						Add appointment
					</Button>
				</div>
			</div>
			<div className={`${classes.appointmentsList} ${_.isEmpty(calenderData) && classes.appointmentsListNoappt}`}>
				{!_.isEmpty(calenderData) ? (
					calenderData.map((item) => (
						<div className={classes.appointmentCard} key={item?.appointmentUuid}>
							<div className={classes.appointmentCardBadge}>
								<Badge
									color={
										item?.appointmentType === 'engagedApp'
											? 'primary'
											: item?.appointmentType === 'missedApp'
											? 'secondary'
											: item?.appointmentType === 'completedApp'
											? 'info'
											: item?.appointmentType === 'upcomingApp'
											? 'success'
											: item?.appointmentType === 'canceledApp'
											? 'warning'
											: item?.appointmentType
									}
									variant='dot'
									overlap='circular'
								></Badge>
							</div>
							<div className={classes.appointmentCardDetails}>
								<div className={classes.appointmentCardText}>
									<Typography variant='body1'>{item?.custName}</Typography>
									<Typography variant='body2'>
										{moment(`${item?.scheduledOn}T${item?.scheduledTime}`).format('LT')} <span>{item?.scheduledPeriod} mins</span>
									</Typography>
									{item?.appointmentType === 'completedApp' ? (
										<Typography
											className={classes.recordLink}
											onClick={() => {
												viewRecord(item?.custUuid)
											}}
										>
											View Record
										</Typography>
									) : null}
								</div>
								<div className={classes.appointmentCardIconBox}>
									{item?.category === 'INP' ? (
										<Apartment />
									) : item?.category === 'ONL' ? (
										<Videocam />
									) : item?.category === 'VAC' ? (
										<Videocam />
									) : item?.category === 'HVT' ? (
										<Home />
									) : (
										item?.category
									)}
								</div>
							</div>
						</div>
					))
				) : (
					<div className={classes.noAppointments}>
						<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/No_appointment_yet.svg`} alt='no appointments'></img>
					</div>
				)}
			</div>
		</div>
	)
}

export default AppointmentCalender
