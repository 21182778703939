import { ACTION_TYPES } from '../../constants'

export const setShowCanceledAppts = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_CANCELLED_APPOINTMENTS,
		payload,
	}
}
export const setShowComptdAppts = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_COMPLETED_APPOINTMENTS,
		payload,
	}
}

export const addAppointmentModal = (payload) => {
	return {
		type: ACTION_TYPES.ADD_APPOINTMENT_MODAL,
		payload,
	}
}

export const appointmentRefresh = (payload) => {
	return {
		type: ACTION_TYPES.APPOINTMENT_REFRESH,
		payload,
	}
}

export const SetCountryCodesList = (payload) => {
	return {
		type: ACTION_TYPES.SET_COUNTRY_CODES,
		payload,
	}
}

export const setEditApptData = (payload) => {
	return {
		type: ACTION_TYPES.EDIT_APPOINTMENT,
		payload,
	}
}

export const apptForSelectedClient = (payload) => {
	return {
		type: ACTION_TYPES.APPOINTMENT_FOR_SELECTED_CLIENT,
		payload,
	}
}

export const setApptOpenedFrom = (payload) => {
	return {
		type: ACTION_TYPES.WHERE_APPOINTMENT_OPENED_FROM,
		payload,
	}
}

export const setApptPrepopulationData = (payload) => {
	return {
		type: ACTION_TYPES.APPOINTMENT_PREPOPULATION_DATA,
		payload,
	}
}
export const setAppointmentDate = (payload) => {
	return {
		type: ACTION_TYPES.APPOINTMENT_DATE,
		payload,
	}
}
