export const initialOptions = {
	membershipPaymentStatusOptions: [
		{ label: 'Paid', value: 'paid' },
		{ label: 'Pending', value: 'pending' },
	],
	membershipStatusOptions: [
		{ label: 'Active', value: 'Active' },
		{ label: 'Inactive', value: 'InActive' },
		{ label: 'Expired', value: 'Expired' },
	],
	paymentStatusOptions: [
		{
			uuid: 'paid',
			tentStatusCatalogName: 'Paid',
		},
		{
			uuid: 'pending',
			tentStatusCatalogName: 'Pending',
		},
	],
	quickSaleOptions: [
		{
			uuid: 'service',
			tentSaleCatalogName: 'Service',
		},
		{
			uuid: 'product',
			tentSaleCatalogName: 'Product',
		},
		{
			uuid: 'membership',
			tentSaleCatalogName: 'Membership',
		},
	],
	paymentCancelledOptions: [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	],
	paymentsOnOptions: ['Billed date', 'Appointment date'],
	doctorOptions: [],
	taxOptions: [],
	membershipOptions: [],
	productOptions: [],
	distributorOptions: [],
	commissionTypeOptions: [],
	bloodGroupOptions: [],
	referredByOptions: [],
	patientGroupOptions: [],
	procedureCategoriesOptions: [],
	paymentVendorOptions: [],
}

export const optionsReducer = (state = initialOptions, action) => {
	switch (action.type) {
		case 'setMembershipOptions': {
			return { ...state, membershipOptions: action.payload }
		}
		case 'setProductOptions': {
			return { ...state, productOptions: action.payload }
		}
		case 'setDistributorOptions': {
			return { ...state, distributorOptions: action.payload }
		}
		case 'setCommissionTypeOptions': {
			return { ...state, commissionTypeOptions: action.payload }
		}
		case 'setDoctorOptions': {
			return { ...state, doctorOptions: action.payload }
		}
		case 'setReferredByOptions': {
			return { ...state, referredByOptions: action.payload }
		}
		case 'setPatientGroupOptions': {
			return { ...state, patientGroupOptions: action.payload }
		}
		case 'setProcedureCategoriesOptions': {
			return { ...state, procedureCategoriesOptions: action.payload }
		}
		case 'setPaymentvendorOptions': {
			return { ...state, paymentVendorOptions: action.payload }
		}
		case 'setTaxOptions': {
			return { ...state, taxOptions: action.payload }
		}
		case 'setBloodGroupOptions': {
			return { ...state, bloodGroupOptions: action.payload }
		}
		case 'resetOptions': {
			return initialOptions
		}
		default:
			return state
	}
}

export const initialFilters = {
	membership: [],
	memberShipPaymentOption: null,
	membershipStatus: [],
	paymentCancelled: { label: 'No', value: false },
}

export const selectedFiltersReducer = (state = initialOptions, action) => {
	switch (action.type) {
		case 'setMembership': {
			return { ...state, membership: action.payload }
		}
		case 'setMemberShipPaymentOption': {
			return { ...state, memberShipPaymentOption: action.payload }
		}
		case 'setMembershipStatus': {
			return { ...state, membershipStatus: action.payload }
		}
		case 'setCancelledPayments': {
			return { ...state, paymentCancelled: action.payload }
		}
		case 'reset':
			return initialFilters

		default:
			return state
	}
}
