import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getSeoDetails: (uuid) => {
		return axios.get(`${API_ENDPOINTS.GET_SEO}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	postSeoDetails: (data) => {
		return axios.post(`${API_ENDPOINTS.POST_SEO}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	putSeoDetails: (data) => {
		return axios.put(`${API_ENDPOINTS.PUT_SEO}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
}
