import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	modelAlignment: {
		padding: 12,
	},
	ProfilePic: {
		flexDirection: 'row',
		alignItems: 'start',
		paddingInline: 12,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			paddingInline: 0,
		},
	},
	mainContain: {
		'& .MuiDialog-paper': {
			minWidth: '54%',
			minHeight: '45vh',
			maxHeight: '94vh',
			[theme.breakpoints.down('lg')]: {
				maxHeight: '75vh',
				minHeight: '60vh',
			},
		},
		'& .MuiAccordion-root': {
			boxShadow: 'none',
			border: '0.5px solid rgb(112, 112, 112,0.5)',
		},
		'& .MuiAccordionDetails-root': {
			padding: 0,
		},
		'& .MuiDialogContent-root': {
			padding: 12,
		},
		'& .MuiDialogTitle-root': {
			fontSize: 12,
			color: '#fff',
			// paddingInline: 34,
			background: themesConfig.greyDark.palette.primary.main,
			'& .MuiSvgIcon-root': {
				color: '#fff',
			},
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 10,
		},
	},
	addMoreContainer: {
		paddingBlock: 24,
	},
	buttonMain: {
		'& .MuiButton-root': {
			background: '#f9f8f9 0% 0% no-repeat padding-box',
			border: '1px solid #acafb6',
			borderRadius: 20,
			gap: 8,
			[theme.breakpoints.down('xs')]: {
				display: 'contents',
			},
			[theme.breakpoints.down('md')]: {
				display: 'contents',
			},
		},
		'& .MuiSvgIcon-root': {
			fontSize: 24,
			color: themesConfig.greyDark.palette.primary.main,
		},
	},
	fieldText: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: 28,
	},
	patientIdClass: { marginTop: 36 },
	fieldTextContain: {
		display: 'grid',
		gap: 16,
		gridTemplateColumns: '1fr 1fr',
		paddingInline: 20,
		paddingBlock: 20,
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '1fr',
			paddingInline: 10,
			paddingBlock: 10,
		},
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr',
			paddingInline: 20,
			paddingBlock: 20,
		},
	},
	checktext: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		'& .MuiFormControlLabel-root': {
			position: 'relative',
			top: 14,
			height: 7,
		},
	},
	titleContent: {
		display: 'flex',
		alignItems: 'center',
		paddingInline: 20,
		paddingBlockStart: 18,
		paddingBlockEnd: 10,
		gap: 12,
		background: themesConfig.greyDark.palette.primary.main,
		'& .MuiTypography-root': {
			color: '#FFFFFF',
			fontSize: 16,
		},
		'& .MuiSvgIcon-root': {
			color: '#FFFFFF',
		},
	},
	yearTextfield: {
		display: 'grid',
		gridTemplateColumns: '1fr 0.4fr 1fr',
		gap: 14,
	},
	showFields: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	btnContain: {
		display: 'flex',
		justifyContent: 'right',
		padding: 10,
		gap: 12,
	},
	buttonStyle: {
		'& .MuiButton-root': {
			background: '#EFEFEF !important',
			borderRadius: '19px !important',
			color: '#000 !important',
		},
	},
	radioButton: {
		'& .MuiFormControlLabel-root': {
			height: 22,
		},
	},
	userLogo: {
		width: '100%',
		height: '100%',
		borderRadius: '100%',
	},
	userAvatar: {
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
		},
	},
	filedValue: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: 35,
	},
	mainLogo: {
		display: 'flex',
		gap: 12,
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},

	fotcontain: {
		paddingInline: 20,
		paddingBlockEnd: 10,
	},
	buttonList: {
		paddingInline: 20,
		'& .MuiButton-root': {
			color: '#2EB2FF',
		},
	},
	displayCont: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	ButtonClass: {
		'& .MuiButton-root': {
			color: '#2EB2FF',
		},
	},
	showFieldsData: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		paddingInline: 24,
	},
	cancelBtn: {
		color: themesConfig.greyDark.palette.primary.main,
	},
	emailAlignment: {
		paddingTop: 16,
	},
	digitPreferContainer: {
		display: 'flex',
	},
}))
export default useStyles
