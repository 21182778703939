import Communication from './Communication'

const CommunicationConfig = {
	routes: [
		{
			path: '/communication',
			component: Communication,
		},
	],
}

export default CommunicationConfig
