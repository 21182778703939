import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	AddTiming: (body) => {
		return axios.post(API_ENDPOINTS.ADD_CALENDAR_TIMING, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	GetSpecialityBasedFilter: (mastuuid, groupId) => {
		const boolean = groupId === 'irokb9b8' ? true : false
		return axios.get(API_ENDPOINTS.SPECIALITY_BASED_FILTER, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, boolean } },
		})
	},
	GetUserIdTiming: (mastuuid, groupId) => {
		const boolean = groupId === 'irokb9b8' ? true : false
		return axios.get(API_ENDPOINTS.GET_TENTID_BASED_TIMING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, boolean } },
		})
	},
	GetIndividualUserIdTiming: (uuid, groupId) => {
		const boolean = groupId === 'irokb9b8' ? true : false

		return axios.get(API_ENDPOINTS.GET_INDIVIDUAL_BASED_TIMING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid, boolean } },
		})
	},
	UpdateGroup: (data) => {
		return axios.put(API_ENDPOINTS.UPDATE_CALENDAR_TIMING, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	DeleteCalendar: (data) => {
		return axios.delete(API_ENDPOINTS.DELETE_CALENDAR_TIMING, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),

				'Content-Type': 'application/json',
				// path: { editUuid },
			},
			data,
		})
	},
}
