import React from 'react'
import CustomBtn from '../Button'
import CancelBtn from '../CancelButton'
import CustomContent from '../CustomContent'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	rootContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingInline: 16,
	},
	footerContainer: {
		display: 'flex',
		gap: 8,
	},
}))
const CustomDialogAction = ({ onClose, onSubmit, disabled, SubmitBtnText, totalExpenses, tableData, editMode }) => {
	const classes = useStyles()
	return (
		<div className={classes?.rootContainer}>
			<CustomContent totalExpenses={totalExpenses} tableData={tableData} editMode={editMode} />
			<div className={classes?.footerContainer}>
				<CancelBtn onClick={onClose}>Cancel</CancelBtn>
				<CustomBtn onClick={onSubmit} disabled={disabled}>
					{SubmitBtnText ? SubmitBtnText : 'Save'}
				</CustomBtn>
			</div>
		</div>
	)
}

export default CustomDialogAction
