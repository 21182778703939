import _ from 'lodash'
import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const editleadData = secureLocalStorage.getItem('editLeadsData')

const initialState = {
	leads_list_data: [],
	leadsListEmpty: [],
	leads_edit_list_data: [editleadData],
	leadsListView: 'list',
	leadsAdvancedFilter: false,
}
const leadsAction = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.LEADS_LIST_DATA: {
			let updatedCustomerList

			if (typeof action.payload === 'function') {
				updatedCustomerList = action.payload(state.leads_list_data)
			} else {
				updatedCustomerList = action.payload
			}
			return {
				...state,
				leads_list_data: _.uniqBy(updatedCustomerList, 'pros_id'),
			}
		}
		case ACTION_TYPES.LEADS_LIST_EMPTY: {
			return {
				...state,
				leadsListEmpty: action.payload,
			}
		}
		case ACTION_TYPES.LEADS_EDIT_LIST_DATA: {
			return {
				...state,
				leads_edit_list_data: action.payload,
			}
		}
		case ACTION_TYPES.LEADS_GRID_VIEW: {
			return {
				...state,
				leadsListView: action.payload,
			}
		}
		case ACTION_TYPES.LEADS_ADVANCED_FILTER: {
			return {
				...state,
				leadsAdvancedFilter: action.payload,
			}
		}
		default:
			return state
	}
}

export default leadsAction
