import moment from 'moment'

export const careInitialData = {
	clientName: null,
	clientId: '',
	custMobile: '',
	custEmail: '',
	tentUserId: '',
	appointmentMode: null,
	specialistName: null,
	feesCalcMethod: 'specialistConsultationFees',
	custSms: false,
	custMail: false,
	tentSms: false,
	tentMail: false,
	category: null,
	date: moment(new Date()).format('YYYY-MM-DD'),
	timeSlot: null,
	duration: null,
	procedureCategory: null,
	speciality: [],
	addMore: false,
	clientEmail: '',
	notes: '',
	paymentMandatory: false,
	membershipName: null,
	membershipValidity: null,
	membershipSession: '',
	membershipStartDate: moment(new Date()).format('YYYY-MM-DD 00:00:00') || null,
	membershipEndDate: null,
}

export const nonCareInitialData = {
	clientName: null,
	clientId: '',
	custMobile: '',
	custEmail: '',
	tentUserId: '',
	appointmentMode: null,
	specialistName: null,
	facility: null,
	custSms: false,
	custMail: false,
	tentSms: false,
	tentMail: false,
	category: null,
	date: moment(new Date()).format('YYYY-MM-DD'),
	timeSlot: null,
	duration: '',
	price: '',
	space: '1',
	addMore: false,
	clientEmail: '',
	notes: '',
	feesCalcMethod: 'facilityPrice',
	paymentMandatory: false,
}
