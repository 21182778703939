import CustomDialog from 'app/sharedComponents/CustomDialog'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, Button, Chip, Divider, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import CopyrightIcon from '@mui/icons-material/Copyright'
import themesConfig from 'app/fuse-configs/themesConfig'
import { ArrowDropDown, DomainAdd, Event, FiberManualRecord, Home, Language, PersonalVideo, Search, Smartphone, Videocam } from '@mui/icons-material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { getImgUrl } from 'utils'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Swipper from './Swipper'
import CustomTentCard from './CustomCard'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		paddingTop: 50,
	},
	dominContainer: {
		width: '100%',
		height: 44,
		background: themesConfig.lyfngo.palette.tertiary.light,
		position: 'absolute',
		top: 56,
		left: 0,
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		zIndex: 66,
	},
	domainUrl: {
		display: 'flex',
		alignItems: 'center',
		margin: '0 24px',
	},
	toggleButtonGroup: {
		border: 0,
		borderRadius: 0,
		backgroundColor: themesConfig.lyfngo.palette.tertiary.light,
		boxShadow: '2px 0px 2px 0px #ccc',
	},
	toggleButton: {
		border: 0,
		borderRadius: 0,
		color: themesConfig.lyfngo.palette.tertiary.main,
		'&.Mui-selected': {
			color: '#fff',
			backgroundColor: themesConfig.lyfngo.palette.tertiary.main,
			'&:hover': {
				backgroundColor: themesConfig.lyfngo.palette.tertiary.main,
			},
		},
	},
	practiceHeader: {
		paddingBlock: 12,
		display: 'flex',
		justifyContent: 'space-between',
	},
	practiceName: {
		display: 'flex',
		alignItems: 'center',
		gap: 12,
		'& .MuiTypography-subtitle1': {
			fontWeight: 600,
		},
	},
	loginBtn: {
		borderRadius: '20px 20px 0 20px',
		padding: '10px 28px 10px 28px',
		border: (props) => `1px solid ${props.colorTheme}`,
		'& .MuiTypography-subtitle1': {
			color: (props) => props.colorTheme,
			fontWeight: 500,
		},
	},
	swipperContainer: {
		paddingBlock: 12,
	},
	checked: {
		fontWeight: 500,
		color: (props) => props.colorTheme,
		background: themesConfig.lyfngo.palette.light.main,
		border: (props) => `0.5px solid ${props.colorTheme}`,
	},
	sec11: {
		display: 'flex',
		gap: 12,
		width: '100%',
		maxWidth: 600,
		paddingBottom: 4,
		'& .MuiChip-root': {
			borderRadius: '20px 20px 0 20px',
		},
		overflowX: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	searchContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		paddingBlock: '6px',
		'& .MuiOutlinedInput-root': {
			borderRadius: '20px 20px 0 20px',
			// border: (props) => `0.5px solid ${props.colorTheme}`,
			'& .MuiSvgIcon-root': {
				color: (props) => props.colorTheme,
			},
		},

		'& .MuiOutlinedInput-notchedOutline': {
			'& .MuiSvgIcon-root': {
				color: (props) => props.colorTheme,
			},
		},
	},
	footerContainer: {
		display: 'flex',
		paddingBlockStart: 24,
		justifyContent: 'space-between',
	},
	copyrightContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	socialIconsContainer: {
		display: 'flex',
		gap: 12,
		// '& img': {
		// 	cursor: 'pointer',
		// },
	},
	lyfngoContainer: {
		display: 'flex',
		gap: 12,
		alignItems: 'center',
	},
	mobileContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		paddingTop: 50,
	},
	mobilePreview: {
		position: 'relative',
	},
	mobileScreen: {
		position: 'absolute',
		width: 218,
		height: 430,
		top: 40,
		left: 12,
		borderRadius: '0 0 26px 26px',
		overflowX: 'hidden',
		overflowY: 'scroll',

		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	mobilepracticeName: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		padding: 6,
		position: 'fixed',
		width: 218,
		zIndex: 66,
		background: '#fff',
		'& .MuiAvatar-root': {
			width: 30,
			height: 30,
		},
		'& .MuiTypography-subtitle1': {
			fontSize: 13,
			fontWeight: 500,
		},
	},
	appointmentModeContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		marginBlock: 4,
	},
	mobileLoginContainer: {
		position: 'sticky',
		bottom: 0,
		width: '100%',
		background: '#fff',
	},
	mobileloginBtn: {
		borderRadius: '20px 20px 0 20px',
		padding: '2px 42px',
		margin: '12px 16px',
		border: (props) => `1px solid ${props.colorTheme}`,
		'& .MuiTypography-subtitle1': {
			color: (props) => props.colorTheme,
			fontWeight: 500,
			textAlign: 'center',
		},
	},
	bookBtn: {
		border: (props) => `1px solid ${props?.colorTheme}`,
		'& .MuiButton-text': {
			color: (props) => props?.colorTheme,
			padding: '2px 24px',
		},
		textTransform: 'capitalize',
		fontFamily: 'poppins',
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		padding: '4	px',
		borderRadius: '20px 20px 0px 20px',
		margin: '4px 0',
		'&.MuiSvgIcon-root': {
			color: (props) => props?.colorTheme,
			display: 'flex',
			alignItems: 'center',
		},
		'&:hover': {
			background: (props) => props?.colorTheme,
			transition: '.5s ease',
			'& .MuiSvgIcon-root': {
				color: '#fff',
				transition: '.5s ease',
			},
			'& .MuiButton-text': {
				color: '#FFF',
			},
		},
		'& .MuiSvgIcon-root': {
			color: (props) => props?.colorTheme,
			marginInlineEnd: 6,
		},
	},
	mobileFooter: {
		display: 'flex',
		flexDirection: 'column',
		gap: 6,
	},
	mobileFooterIcons: {
		width: 24,
	},
}))

const Preview = ({
	openPreviewModal,
	onPreviewModalClose,
	previewData,
	formik,
	establishmentFileResponse,
	specialityDetails,
	profileTentList,
	selectPreview,
	setSelectPreview,
	displayDomain,
}) => {
	const classes = useStyles({ colorTheme: formik?.values?.colorCode })
	const state = useSelector((state) => state.Authentication)
	const currentPractice = useSelector((state) => state?.dynamicMenu?.currentPractice || {})
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName || {})
	const isCare = _.isEqual(groupName, 'health care')
	const careEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Care.svg`
	const NonCareEmptyIcon = `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Header/Non_Care.svg`
	const { BearerToken } = state
	var envDomainName = process.env.REACT_APP_PROFILE_DOMAIN
	// const [selectPreview, setSelectPreview] = useState(previewMobile)
	const handleTogglePreview = (event, newAlignment) => {
		setSelectPreview(newAlignment)
	}

	return (
		<CustomDialog
			hideDivider
			hideSaveBtn
			hideCloseBtn
			headerText={'Microsite Setup Preview'}
			open={openPreviewModal}
			onClose={onPreviewModalClose}
			maxWidth='1024px'
		>
			<div className={classes.dominContainer}>
				<div className=''>
					<ToggleButtonGroup
						className={classes.toggleButtonGroup}
						value={selectPreview}
						size='medium'
						exclusive
						onChange={handleTogglePreview}
						aria-label='select preview'
					>
						<ToggleButton className={classes.toggleButton} value='web' aria-label='web preview'>
							<PersonalVideo />
						</ToggleButton>
						<ToggleButton className={classes.toggleButton} value='mobile' aria-label='mobile preview'>
							<Smartphone />
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<div className={classes.domainUrl}>
					<Language sx={{ color: themesConfig.lyfngo.palette.tertiary.main }} />
					<Typography ml={1}>{displayDomain ? `https://${formik?.values?.email}` : `https://${formik?.values?.email}${envDomainName}`}</Typography>
				</div>
			</div>
			{selectPreview === 'web' ? (
				<div className={classes.mainContainer}>
					<div className={classes.practiceHeader}>
						<div className={classes.practiceName}>
							<Avatar
								className='md:mx-4 w-40 h-40'
								src={
									currentPractice?.docDriveUuid !== null
										? getImgUrl(currentPractice?.docDriveUuid, BearerToken)
										: isCare
										? careEmptyIcon
										: NonCareEmptyIcon
								}
								alt='practice_img'
							/>
							<Typography variant='subtitle1'>{currentPractice?.practiceName}</Typography>
						</div>
						<div className={classes.loginBtn}>
							<Typography variant='subtitle1'>Login/signup</Typography>
						</div>
					</div>
					<Divider />
					<div className={classes.swipperContainer}>
						<Swipper previewData={previewData} BearerToken={BearerToken} formik={formik} establishmentFileResponse={establishmentFileResponse} />
					</div>

					<div className={classes.searchContainer}>
						<div style={{ display: 'flex', gap: 12 }}>
							{specialityDetails.length > 5 && (
								<div>
									<KeyboardArrowLeftIcon className={classes.moveIcon} />
								</div>
							)}

							<div className={classes.sec11}>
								<Chip label='All Specialists' variant='outlined' className={classes.checked}></Chip>
								{_.map(specialityDetails, (item, index) => (
									<Chip key={item?.specialityUuid} label={item?.specialityName} className={classes.notChecked} variant='outlined' />
								))}
							</div>
							{specialityDetails.length > 5 && (
								<div>
									<KeyboardArrowRightIcon className={classes.moveIcon} />
								</div>
							)}
						</div>
						<div>
							<TextField
								name='searchOptions'
								id='searchOptions'
								className={classes.inputBaseColor}
								fullWidth
								size='small'
								color='secondary'
								// value={value}
								// onChange={onChange}
								placeholder='search by Doctors'
								sx={{
									'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: formik?.values?.colorCode,
									},
									'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: formik?.values?.colorCode,
									},
									'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
										borderColor: formik?.values?.colorCode,
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Search />
										</InputAdornment>
									),
								}}
							/>
						</div>
					</div>

					<CustomTentCard profileTentList={profileTentList} colorTheme={formik?.values?.colorCode} />

					<Divider />
					<div className={classes.footerContainer}>
						<div className={classes.copyrightContainer}>
							<Typography variant='subtitle1'>
								<CopyrightIcon /> : <span style={{ fontWeight: 600 }}>{new Date().getFullYear()}</span> {formik?.values?.copyright} Pvt. Ltd
							</Typography>
						</div>
						<div className={classes.socialIconsContainer}>
							{formik?.values?.fb && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/facebook_icon.svg`} alt='C' />}
							{formik?.values?.twitter && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/x_icon.svg`} alt='C' />}
							{formik?.values?.insta && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/instagram_icon.svg`} alt='C' />}
							{formik?.values?.thread && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/thread_icon.svg`} alt='C' />}
							{formik?.values?.youtube && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/youtube_icon.svg`} alt='C' />}
							{formik?.values?.linkedin && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/linkedin_icon.svg`} alt='C' />}
							{formik?.values?.whatsApp && <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/whatsapp_icon.svg`} alt='C' />}
						</div>
						<div className={classes.lyfngoContainer}>
							<Typography variant='subtitle1' fontWeight={500}>
								Powered by
							</Typography>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/LYFnGO_Logo_.svg`} alt='C' />
						</div>
					</div>
				</div>
			) : (
				<div className={classes.mobileContainer}>
					<div className={classes.mobilePreview}>
						<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Preview_mobile.svg`} alt='mobile' style={{ height: 480 }} />
						<div className={classes.mobileScreen}>
							<div className={classes.mobilepracticeName}>
								<Avatar
									className=''
									src={
										currentPractice?.docDriveUuid !== null
											? getImgUrl(currentPractice?.docDriveUuid, BearerToken)
											: isCare
											? careEmptyIcon
											: NonCareEmptyIcon
									}
									alt='practice_img'
								/>
								<Typography variant='subtitle1'>{currentPractice?.practiceName}</Typography>
							</div>
							<div style={{ marginTop: 44 }}>
								<Divider />
								<div className={classes.mobileCardContainer} style={{ padding: '0 8px' }}>
									<div className=''>
										<div className=''>
											<TextField
												name='searchOptions'
												id='searchOptions'
												className={classes.inputBaseColor}
												size='small'
												color='secondary'
												value={'All Specialist'}
												// onChange={onChange}
												sx={{
													width: 300,
													paddingBlock: 5,
													'& .MuiOutlinedInput-root': {
														// border: `0.5px solid ${formik?.values?.colorCode} !important`,
														color: formik?.values?.colorCode,
														width: '200px',
														borderRadius: '20px 20px 0 20px',
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
													'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
													'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<ArrowDropDown sx={{ color: formik?.values?.colorCode }} />
														</InputAdornment>
													),
												}}
											/>
										</div>
										<div className=''>
											<TextField
												name='searchOptions'
												id='searchOptions'
												className={classes.inputBaseColor}
												size='small'
												color='secondary'
												// value={value}
												// onChange={onChange}
												placeholder='search by Doctors'
												sx={{
													width: 300,
													paddingBlock: 5,
													'& .MuiOutlinedInput-root': {
														// border: `0.5px solid ${formik?.values?.colorCode} !important`,
														width: '200px',
														borderRadius: '20px 20px 0 20px',
													},
													'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
													'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
													'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
														borderColor: formik?.values?.colorCode,
													},
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<Search sx={{ color: formik?.values?.colorCode }} />
														</InputAdornment>
													),
												}}
											/>
										</div>
									</div>
									{!_.isEqual(profileTentList, []) ? (
										<div>
											{profileTentList?.map((item, index) => (
												<div
													key={item?.tentUserDTO?.tentUserUuid}
													className={classes.cardContainer}
													style={{ marginBottom: '16px', border: '1px solid #ccc', borderRadius: '20px', padding: 4, overflow: 'hidden' }}
												>
													<div className='' style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
														<Avatar src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/avatar.svg`} sx={{ height: 40, width: 40 }} />
														<div className=''>
															<Typography variant='h6' fontSize={14} fontWeight={500}>
																{item?.tentUserDTO?.tentUserSalutation} {item?.tentUserDTO?.tentUserName}
															</Typography>
															<Typography variant='subtitle1' sx={{ fontSize: '11px', color: '#727272' }}>
																{item?.tentUserDTO?.tentUserEducation?.map(
																	(data2, index) => `${data2}${index === item?.tentUserDTO?.tentUserEducation?.length - 1 ? '' : ', '}`
																)}
															</Typography>
														</div>
													</div>
													<div>
														<div className='' style={{ marginTop: 1 }}>
															{item?.tentUserDTO?.specialityListDTO?.length > 3 ? (
																<>
																	<Typography variant='subtitle1' fontSize={12} fontWeight={600}>
																		{`${(item?.tentUserDTO?.specialityListDTO || [])[0]?.specialityName}`},{' '}
																		{`${(item?.tentUserDTO?.specialityListDTO || [])[1]?.specialityName}`},{' '}
																		{`${(item?.tentUserDTO?.specialityListDTO || [])[2]?.specialityName}`},{' '}
																		<span style={{ color: formik?.values?.colorCode }}>
																			+ {(item?.tentUserDTO?.specialityListDTO || [])?.length - 3} more
																		</span>
																	</Typography>
																</>
															) : (
																<Typography variant='subtitle2' className={classes.dropDownTxt} fontSize={12}>
																	{(item?.tentUserDTO?.specialityListDTO || [])?.map((i, idx) => (
																		<>
																			{i?.specialityName || ''}
																			{idx + 1 === item?.tentUserDTO?.specialityListDTO?.length ? '' : ', '}
																		</>
																	))}
																</Typography>
															)}
															{item?.tentUserDTO?.tentUserExperience && (
																<Typography variant='subtitle1'>{`${item?.tentUserDTO?.tentUserExperience} Years of Experience`}</Typography>
															)}
														</div>
														{item?.tentUserDTO?.aboutUs && (
															<div className={classes.descriptionWrapper} style={{ marginTop: 8 }}>
																<Typography variant='body1' sx={{ fontWeight: 600, fontSize: 13 }}>
																	Description
																</Typography>
																<Typography variant='subtitle1' sx={{ fontSize: 11 }}>
																	{item?.tentUserDTO?.aboutUs?.length > 100
																		? `${item?.tentUserDTO?.aboutUs?.substring(0, 100)}...`
																		: item?.tentUserDTO?.aboutUs}
																	{item?.tentUserDTO?.aboutUs?.length > 100 && <span style={{ color: formik?.values?.colorCode }}>View More</span>}
																</Typography>
															</div>
														)}
														<div
															className={classes.availContainer}
															style={{
																display: 'flex',
																gap: 6,
																marginTop: 6,
															}}
														>
															<FiberManualRecord sx={{ color: '#72C63F' }} />
															<Typography variant='body1' fontSize={12}>
																Available at{' '}
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Sun') ? classes.isAvail : classes.isNotAvail}
																>
																	Sun
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Mon') ? classes.isAvail : classes.isNotAvail}
																>
																	Mon
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Tue') ? classes.isAvail : classes.isNotAvail}
																>
																	Tue
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Wed') ? classes.isAvail : classes.isNotAvail}
																>
																	Wed
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Thu') ? classes.isAvail : classes.isNotAvail}
																>
																	Thu
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Fri') ? classes.isAvail : classes.isNotAvail}
																>
																	Fri
																</span>
																,
																<span
																	style={{ color: '#72C63F' }}
																	className={item?.timeSlots?.includes('Sat') ? classes.isAvail : classes.isNotAvail}
																>
																	Sat
																</span>
															</Typography>
														</div>
														<div className={classes.appointmentModeContainer}>
															{item?.appointmentMode?.find((data) => data === 'In-person') && (
																<Typography variant='subtitle1' sx={{ display: 'flex', gap: '6px', fontSize: 13, paddingBlock: 2 }}>
																	<DomainAdd style={{ color: '#008DDF' }} />
																	In-person
																</Typography>
															)}
															{item?.appointmentMode?.find((data) => data === 'Online') && (
																<Typography variant='subtitle1' sx={{ display: 'flex', gap: '6px', fontSize: 13, paddingBlock: 2 }}>
																	<Videocam style={{ color: '#00A010' }} />
																	online
																</Typography>
															)}
															{item?.appointmentMode?.find((data) => data === 'Home') && (
																<Typography variant='subtitle1' sx={{ display: 'flex', gap: '6px', fontSize: 13, paddingBlock: 2 }}>
																	<Home style={{ color: '#0066E5' }} />
																	Home Service
																</Typography>
															)}
														</div>

														<div className='' style={{ display: 'flex', justifyContent: 'center' }}>
															<div className={classes.bookBtn}>
																<Button>
																	<Event />
																	Instant Booking
																</Button>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<img alt='no docters' src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/No_doctors_found.svg`} width={300} />
										</div>
									)}
									<div className={classes.mobileFooter}>
										<Divider />
										<div
											className={classes.mobilesocialIconsContainer}
											style={{ display: 'flex', gap: 4, alignItems: 'center', justifyContent: 'center', img: { width: 20, height: 20 } }}
										>
											{formik?.values?.fb && (
												<img
													className={classes.mobileFooterIcons}
													src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/facebook_icon.svg`}
													alt='C'
												/>
											)}
											{formik?.values?.twitter && (
												<img className={classes.mobileFooterIcons} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/x_icon.svg`} alt='C' />
											)}
											{formik?.values?.insta && (
												<img
													className={classes.mobileFooterIcons}
													src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/instagram_icon.svg`}
													alt='C'
												/>
											)}
											{formik?.values?.thread && (
												<img className={classes.mobileFooterIcons} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/thread_icon.svg`} alt='C' />
											)}
											{formik?.values?.youtube && (
												<img className={classes.mobileFooterIcons} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/youtube_icon.svg`} alt='C' />
											)}
											{formik?.values?.linkedin && (
												<img
													className={classes.mobileFooterIcons}
													src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/linkedin_icon.svg`}
													alt='C'
												/>
											)}
											{formik?.values?.whatsApp && (
												<img
													className={classes.mobileFooterIcons}
													src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/whatsapp_icon.svg`}
													alt='C'
												/>
											)}
										</div>
										<Typography sx={{ textAlign: 'center', fontSize: 10, mt: 1 }}>
											<span>
												<CopyrightIcon sx={{ fontSize: 14 }} /> : {new Date().getFullYear()}
											</span>
											<span> {formik?.values?.copyright} Pvt. Ltd</span>
										</Typography>
										<div className={classes.lyfngoContainer} style={{ justifyContent: 'center' }}>
											<Typography fontWeight={500} fontSize={12}>
												Powered by
											</Typography>
											<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/LYFnGO_Logo_.svg`} alt='C' style={{ height: 24 }} />
										</div>
									</div>
								</div>
							</div>
							<div className={classes.mobileLoginContainer}>
								<Divider />
								<div className={classes.mobileloginBtn}>
									<Typography variant='subtitle1'>Login/signup</Typography>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</CustomDialog>
	)
}

export default Preview
