import React from 'react'
import { Avatar, Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NavigateNext } from '@mui/icons-material'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { currencyCode, customDateFormat, getImgUrl } from 'utils'
import History from '@history/@history'
import secureLocalStorage from 'react-secure-storage'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		border: '1px solid #727272',
		borderRadius: 10,
		overflow: 'hidden',
		width: '100%',
		marginBottom: 14,
		[theme.breakpoints.up('xs')]: {
			width: '49%',
		},
		[theme.breakpoints.up('xl')]: {
			width: '32%',
		},
	},
	clientImg: {
		padding: 8,
	},
	clientDetails: {
		padding: 8,
		display: 'block',
	},
	clientName: {
		alignItems: 'center',
		'& .MuiTypography-body1': {
			fontWeight: 500,
			fontSize: 16,
			color: '#000',
		},
		'& .MuiIconButton-root': {
			marginLeft: 6,
		},
	},
	clientId: {
		fontSize: 14,
		color: '#000',
		'& span': {
			marginLeft: 4,
			color: '#0062DD',
			fontWeight: 500,
		},
	},
	clientMembership: {
		fontWeight: 500,
		fontSize: 14,
		color: '#004397',
	},
	clientBill: {
		fontSize: 12,
		color: '#727272',
		'& span': {
			marginLeft: 4,
			color: '#000',
			fontWeight: 500,
		},
	},
}))

const ClientInfoCard = ({ isCareFlow, cardType, data }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const bearer = useSelector((states) => states?.Authentication?.BearerToken)
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	const handleNavClick = (custUuid) => {
		secureLocalStorage.setItem('custUuid', `${custUuid}`)
		dispatch(customer_Uuid(`${custUuid}`))
		History.push(`/clientinfo/${custUuid}`)
		// if (isCareFlow) {
		// 	secureLocalStorage.setItem('custUuid', `${custUuid}`)
		// 	dispatch(customer_Uuid(`${custUuid}`))
		// 	History.push(`/patientinfo/${custUuid}`)
		// } else {
		// 	secureLocalStorage.setItem('custUuid', `${custUuid}`)
		// 	dispatch(customer_Uuid(`${custUuid}`))
		// 	History.push(`/clientinfo/${custUuid}`)
		// }
	}

	return (
		<div className={classes.cardRoot}>
			<Grid container xs={12} style={{ height: '100%' }}>
				<Grid
					item
					xs={0.2}
					style={{
						backgroundColor: _.isEqual(cardType, 'emr')
							? '#4A9AFF'
							: _.isEqual(cardType, 'membership')
							? '#FF83C6'
							: _.isEqual(cardType, 'quicksale')
							? '#68C8FF'
							: _.isEqual(cardType, 'pharma')
							? '#75E252'
							: '#ffff',
					}}
				></Grid>
				<Grid item xs={2} xl={2.5} className={classes.clientImg}>
					<Avatar
						alt='client profile'
						sx={{ height: 72, width: 72, borderRadius: 2 }}
						src={data?.docDriveUuid ? getImgUrl(data?.docDriveUuid, bearer) : `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
					/>
				</Grid>
				<Grid item xs={9.5} xl={9.25} className={classes.clientDetails}>
					<Grid container xs={12}>
						<Grid container xs={12} className={classes.clientName}>
							<Grid item xs={10}>
								<Typography>{data?.custName || ''}</Typography>
							</Grid>
							<Grid item xs={2}>
								<IconButton size='small' onClick={() => handleNavClick(data?.custUuid)}>
									<NavigateNext />
								</IconButton>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography className={classes.clientId}>
								{isCareFlow ? 'Patient' : 'Client'} ID <span>{data?.custCustomId || '-'}</span>
							</Typography>
						</Grid>
						{_.isEqual(cardType, 'membership') && (
							<Grid item xs={12}>
								<Typography className={classes.clientMembership}>{data?.membershipName || '-'}</Typography>
							</Grid>
						)}
						<Grid item xs={6}>
							<Typography className={classes.clientBill}>
								Bill date <span>{moment.utc(data?.billDate).format(dateFormatForViewer)}</span>
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography className={classes.clientBill}>
								Balance <span> {currencyCode(data?.totalBalance || 0, currencyType)}</span>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

export default ClientInfoCard
