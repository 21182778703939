import { useState, useRef, useEffect } from 'react'
import {
	ListItem,
	ListItemText,
	ListItemIcon,
	TextField,
	Autocomplete,
	Checkbox,
	Typography,
	Divider,
	Button,
	FormHelperText,
	IconButton,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CancelIcon from '@mui/icons-material/Cancel'
import ButtonComp from '../../../sharedComponents/Button'
import { useFormik } from 'formik'
import _ from 'lodash'
import secureLocalStorage from 'react-secure-storage'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import profiletable from '../../../../services/ProfileSetup/ProfileTable/api'
import TimingAndPayment from '../../../../services/ProfileSetup/TimingAndPayments/api'
import { decryption, getResponseMessage } from 'utils'
import useStyles from '../TimingAndPayments/style'
import SlotMapping from './SlotMapping'
import ScheduleDays from './ScheduleDays'
import establishmentApi from '../../../../services/ProfileSetup/EstablishmentDetails/api'
import FecilityMapping from './FecilitiesMapping'
import SpaAndWellness from './SpaAndWellness'
import { setTimingData, setTimingFormData } from '../../../../services/ProfileSetup/list/actions'
import { TimePicker } from '@mui/lab'
import moment from 'moment'

export function usePrevious(value) {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export default function TimingandPayments({ moveNextTab, moveBackTab }) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const classes = useStyles()
	const dispatch = useDispatch()
	const validationType = useRef('')
	const [reqPayment, setReqPayment] = useState(false)
	const [slotBookingDay, setSlotBookingDay] = useState([
		{
			day: 'Sunday',
			label: 'sun',
			isSelected: false,
		},
		{
			day: 'Monday',
			label: 'mon',
			isSelected: false,
		},
		{
			day: 'Tuesday',
			label: 'tue',
			isSelected: false,
		},
		{
			day: 'Wednesday',
			label: 'wed',
			isSelected: false,
		},
		{
			day: 'Thursday',
			label: 'thu',
			isSelected: false,
		},
		{
			day: 'Friday',
			label: 'fri',
			isSelected: false,
		},
		{
			day: 'Saturday',
			label: 'sat',
			isSelected: false,
		},
	])
	const [allDaySelected, setallDaySelected] = useState(false)
	const [selectedDays, setSelectedDays] = useState([])
	const [practicelist, setPracticeList] = useState([])
	const [hideSlotFormik, setHideSlotFormik] = useState(false)
	const [hideFecilityForm, setHideFeciltityForm] = useState(false)
	const errorMessages = useSelector((state) => state?.errorMessages)
	const state = useSelector((state) => state.Authentication)
	const timingData = useSelector((state) => state?.profileSetup?.timingAndPayments?.timingData)
	const timingFormData = useSelector((state) => state?.profileSetup?.timingAndPayments?.timingFormData)
	const { uuid, groupName, groupId, identityUuid } = state
	const [splArr, setSplArray] = useState([])
	const [slotbooking, setSlotBook] = useState(timingData ? timingData : [])
	const [tenantType, setTenantType] = useState([])
	const [timings, setTimings] = useState([])
	const [specialityLoading, setSpecialityLoading] = useState(false)
	const [practiceName, setPracticeName] = useState({})
	const prevPractice = usePrevious(practiceName)
	const [copyAllDay, setCopyAllDay] = useState(false)
	const tentTypeId = useSelector((state) => state?.Authentication?.tentTypeId || secureLocalStorage.getItem('tentTypeId'))
	const timeZone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezoneOffset || '')

	useEffect(() => {
		let tempArr = []
		_.map(slotBookingDay, (day) => {
			if (day?.isSelected) {
				tempArr.push(day)
			}
		})
		setallDaySelected(tempArr?.length === 7 ? true : false)
	}, [slotBookingDay])

	const handleAllDay = (position) => {
		setallDaySelected(!allDaySelected)
		let tempArr = []
		_.map(slotBookingDay, (item, idx) => {
			if (_.isEqual(allDaySelected, false)) {
				tempArr.push({ ...item, isSelected: true })
			} else {
				tempArr.push({ ...item, isSelected: false })
			}
		})
		setSlotBookingDay(tempArr)
		formik?.setFieldValue('schedule', _.isEqual(allDaySelected, false) ? tempArr : null)
	}

	const handleOnChange = (idx) => {
		let tempArr = []
		_.map(slotBookingDay, (item, itemIdx) => {
			if (_.isEqual(idx, itemIdx)) {
				tempArr.push({ ...item, isSelected: allDaySelected ? true : !item.isSelected })
			} else {
				tempArr.push({ ...item, isSelected: allDaySelected ? false : item?.isSelected })
			}
			const isSelected = _.findIndex(tempArr, (o) => o?.isSelected === true) > -1
			formik?.setFieldValue('schedule', isSelected ? tempArr : null)
			setSlotBookingDay(tempArr)
		})
	}

	const buttonHandler = (data) => {
		setReqPayment(data)
	}

	const removeRow = (idx, item) => {
		setTenantType((prevState) => [{ ...item?.speciality }, ...prevState])
		const values = [...splArr]
		values.splice(idx, 1)
		setSplArray(values)
		setTimings(values)
	}

	const removeRow1 = (index, item) => {
		_.map(item?.schedule || [], (schdle) => {
			const itemIndex = _.findIndex(selectedDays, { day: schdle?.day })
			selectedDays.splice(itemIndex, 1)
		})
		const values = [...slotbooking]
		values.splice(index, 1)
		setSlotBook(values)
	}
	const slotFormikValidationSchema = yup.object({
		startTime: yup.string().nullable().required('Required.'),
		endTime: yup.string().nullable().required('Required.'),
		price: yup.string().max(4, 'maximum 4 integer are allowed').required('Required.'),
		space: yup.string().required('Required.'),
		venueSpace: _.isEqual(groupName, 'fitness', 'mind') ? yup.string().required('Required.') : null,
	})
	const slotBookingFormik = useFormik({
		initialValues: {
			startTime: timingFormData?.startTime || null,
			endTime: timingFormData?.endTime || null,
			price: timingFormData?.price || '',
			space: timingFormData?.space || '30 mins',
			venueSpace: timingFormData?.venueSpace || '',
		},
		enableReinitialize: true,
		validationSchema: !hideSlotFormik ? slotFormikValidationSchema : null,
		onSubmit: (e) => {
			if (validationType.current === 'slotValidation') {
				formik?.handleSubmit()
				onSlotTiming(e)
			} else if (validationType.current === 'addFecilty') {
				formik?.handleSubmit()
				addFacility(e)
			}
		},
	})
	const formikSchema = yup.object({
		practiceName: copyAllDay ? yup.object().nullable() : yup.object().nullable().required('Practice name is required'),
		practiceSpeciality: yup.object().nullable().required('Practise speciality is required'),
		schedule: yup.array().min(1, 'Please select atleast one day').nullable().required('Please select atleast one day'),
	})
	const formik = useFormik({
		initialValues: {
			practiceName: '',
			practiceSpeciality: null,
			schedule: [],
		},
		enableReinitialize: true,
		validationSchema: !hideFecilityForm ? formikSchema : null,
		onSubmit: (e) => saveTimingData(e),
	})

	const onSlotTiming = (e) => {
		if (hideSlotFormik) {
			setHideSlotFormik(false)
			slotBookingFormik.resetForm()
			dispatch(setTimingFormData({}))
		} else {
			const selectedDay = _.filter(slotBookingDay, (o) => o?.isSelected === true)
			if (_.isEmpty(formik?.errors) && !_.isEmpty(selectedDay)) {
				setSlotBook((prevState) => [
					...prevState,
					{
						startTime: e.startTime,
						endTime: e.endTime,
						price: e.price,
						space: e.space,
						venueSpace: e.venueSpace,
						schedule: selectedDay,
					},
				])
				setSelectedDays((prevState) => [...prevState, ...selectedDay])
				slotBookingFormik?.resetForm()
				dispatch(setTimingFormData({}))
				let tempArr = []
				_.map(slotBookingDay, (item, itemIdx) => {
					tempArr.push({ ...item, isSelected: false })
				})
				formik?.setFieldValue('schedule', null)
				setSlotBookingDay(tempArr)
			} else {
				formik?.handleSubmit()
			}
		}
	}
	const resetSlotBooking = () => {
		let tempArr = []
		_.map(slotBookingDay, (item, itemIdx) => {
			tempArr.push({ ...item, isSelected: false })
		})
		formik?.setFieldValue('schedule', null)
		setSlotBookingDay(tempArr)
		setallDaySelected(false)
	}
	const addFacility = (data) => {
		if (_.isEmpty(formik?.errors)) {
			let selectedDays = _.filter(slotBookingDay, (o) => o?.isSelected === true)
			const addedData = [
				...splArr,
				{
					speciality: formik?.values?.practiceSpeciality || '',
					practiceName: formik?.values?.practiceName || '',
					slots: [
						...slotbooking,
						{
							startTime: slotBookingFormik?.values?.startTime,
							endTime: slotBookingFormik?.values?.endTime,
							price: slotBookingFormik?.values?.price,
							space: slotBookingFormik?.values?.space,
							venueSpace: slotBookingFormik?.values?.venueSpace,
							schedule: selectedDays,
						},
					],
				},
			]
			setSplArray(addedData)
			const currentPracticeData = _.filter(addedData, (o) => _.isEqual(o?.practiceName?.practice_name, formik?.values?.practiceName?.practice_name))
			setTimings(currentPracticeData)
			formik.setFieldValue('practiceSpeciality', null)
			formik?.setFieldValue('schedule', [])
			resetSlotBooking()
			let resetArr = []
			const filteredSpeciality = _.filter(tenantType, (o) => o?.specialityName !== formik?.values?.practiceSpeciality?.specialityName)
			setTenantType(filteredSpeciality)
			_.map(slotBookingDay, (item) => {
				resetArr.push({ ...item, isSelected: false })
			})
			slotBookingFormik.resetForm()
			dispatch(setTimingFormData({}))
			setSlotBookingDay(resetArr)
			setallDaySelected(false)
			setSelectedDays([])
			setSlotBook([])
		} else {
			formik?.handleSubmit()
		}
	}

	const formSubmitData = (splArr) => {
		return new Promise((resolve, reject) => {
			const wholeData = hideFecilityForm
				? [...splArr]
				: [
						...splArr,
						{
							speciality: formik?.values?.practiceSpeciality || '',
							practiceName: formik?.values?.practiceName || '',
							slots: hideSlotFormik
								? [...slotbooking]
								: [
										...slotbooking,
										{
											startTime: slotBookingFormik?.values?.startTime,
											endTime: slotBookingFormik?.values?.endTime,
											price: slotBookingFormik?.values?.price,
											space: slotBookingFormik?.values?.space,
											venueSpace: slotBookingFormik?.values?.venueSpace,
											schedule: _.filter(formik?.values?.schedule, (scdle) => scdle?.isSelected && scdle?.label),
										},
								  ],
						},
				  ]
			resolve(wholeData)
		})
	}

	const saveTimingData = (e) => {
		if (validationType.current === 'submitForm' && _.isEmpty(slotBookingFormik?.errors)) {
			formSubmitData(splArr).then((wholeData) => {
				const body = _.map(wholeData, (item) => {
					return {
						tentUserId: uuid,
						tentId: item?.practiceName?.master_tenant_uuid,
						isPaymentMandatory: `${reqPayment}`,
						details: _.map(item?.slots, (slot) => ({
							consultantionFees: parseInt(slot?.price),
							dayOn: _.map(slot?.schedule, (day) => day?.label),
							allDay: `${slot?.schedule?.length >= 7}`,
							sessionStartTime: `${moment(slot?.startTime).format('HH:mm:ss')}${timeZone}`,
							sessionEndTime: `${moment(slot?.endTime).format('HH:mm:ss')}${timeZone}`,
							venueSpace: slot?.venueSpace,
							consultantionDuration: slot?.space,
							specialityId: item?.speciality?.specialityUuid,
						})),
					}
				})

				let copiedBody = []
				_.map(practicelist, (item) => {
					_.map(wholeData, (item1) => {
						copiedBody.push({
							tentUserId: uuid,
							tentId: item.master_tenant_uuid,
							isPaymentMandatory: `${reqPayment}`,
							details: _.map(item1?.slots, (slot) => ({
								consultantionFees: parseInt(slot?.price),
								dayOn: _.map(slot?.schedule, (day) => day?.label),
								allDay: `${slot?.schedule?.length >= 7}`,
								sessionStartTime: `${moment(slot?.startTime).format('HH:mm:ss')}${timeZone}`,
								sessionEndTime: `${moment(slot?.endTime).format('HH:mm:ss')}${timeZone}`,
								venueSpace: slot?.venueSpace,
								consultantionDuration: slot?.space,
								specialityId: item1?.speciality?.specialityUuid,
							})),
						})
					})
				})

				const onSuccess = (res) => {
					dispatch(hideLoader())
					if (res?.data?.status === 'success') {
						moveNextTab()
						dispatch(
							showMessage({
								message: getResponseMessage(res),
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'success',
							})
						)
					} else {
						dispatch(
							showMessage({
								message: getResponseMessage(res),
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					}
				}
				const onFailure = (err) => {
					dispatch(hideLoader())
					dispatch(
						showMessage({
							message: getResponseMessage(err?.response?.data, errorMessages),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'error',
						})
					)
				}

				dispatch(showLoader('Loading please wait...'))
				let finData = copyAllDay ? copiedBody : body
				TimingAndPayment.saveTimingList(finData).then(onSuccess, onFailure)
			})
		}
	}

	useEffect(() => {
		const onSuccess = (res) => {
			setSpecialityLoading(false)
			if (res?.data.status === 'success') {
				setSpecialityLoading(false)
				setTenantType(res.data.data)
			} else {
				setSpecialityLoading(false)
				setTenantType([])
			}
		}
		const onFailure = (err) => {
			setSpecialityLoading(false)
		}

		establishmentApi.getSpeciality(tentTypeId).then(onSuccess, onFailure)
	}, [tentTypeId])

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				dispatch(hideLoader())
				setPracticeList(successData?.data?.data)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		profiletable.getPracticeList(identityUuid).then(onSuccess, onFailure)
	}, [dispatch, identityUuid])

	const onSpecialityChange = (e, value) => {
		formik?.setFieldValue('practiceSpeciality', value)
	}

	const isDataExist = (value) => {
		return new Promise((resolve, reject) => {
			let existData = _.findIndex(splArr, (o) => _.isEqual(o?.practiceName?.practice_name, value?.practice_name)) > -1
			let isSpecialityExist =
				_.findIndex(splArr, (o) => _.isEqual(o?.practiceSpeciality?.specialityName, formik?.values?.practiceSpeciality?.specialityName)) > -1
			let slotValid =
				!_.isEmpty(slotBookingFormik?.values?.startTime) &&
				!_.isEmpty(slotBookingFormik?.values?.endTime) &&
				!_.isEmpty(slotBookingFormik?.values?.price) &&
				!_.isEmpty(slotBookingFormik?.values?.space)
			let arrData = []
			if (existData && isSpecialityExist) {
				arrData = [...splArr]
			} else {
				arrData =
					!_.isEmpty(formik?.values?.practiceSpeciality) && !isSpecialityExist
						? slotValid && !_.isEmpty(selectedDays)
							? [
									...splArr,
									{
										speciality: formik?.values?.practiceSpeciality || {},
										practiceName: prevPractice || {},
										slots: [
											...slotbooking,
											{
												startTime: slotBookingFormik?.values?.startTime,
												endTime: slotBookingFormik?.values?.endTime,
												price: slotBookingFormik?.values?.price,
												space: slotBookingFormik?.values?.space,
												schedule: selectedDays,
											},
										],
									},
							  ]
							: [...splArr]
						: [...splArr]
			}

			resolve(arrData)
		})
	}

	const onPracticeChange = (e, value) => {
		slotBookingFormik.handleSubmit()
		isDataExist(value).then((arrData) => {
			setSplArray(arrData)
			const currentPracticeData = _.filter(arrData, (o) => _.isEqual(o?.practiceName?.practice_name, value?.practice_name))
			setTimings(currentPracticeData)

			secureLocalStorage.setItem('tentTypeId', value?.mast_tent_type_uuid)
			setSlotBook([])
			setPracticeName(value)
			slotBookingFormik?.resetForm()
			dispatch(setTimingFormData({}))
			formik?.setFieldValue('practiceName', value)
			formik?.setFieldValue('practiceSpeciality', null)
			let resetArr = []
			_.map(slotBookingDay, (item) => {
				resetArr.push({ ...item, isSelected: false })
			})
			setSlotBookingDay(resetArr)
		})
	}

	useEffect(() => {
		dispatch(setTimingData(slotbooking))
	}, [dispatch, slotbooking])

	useEffect(() => {
		dispatch(setTimingFormData(slotBookingFormik?.values))
	}, [dispatch, slotBookingFormik?.values])

	useEffect(() => {
		if (_.isEmpty(slotbooking)) {
			dispatch(
				setTimingFormData({
					startTime: null,
					endTime: null,
					price: '',

					space: '30 mins',
					schedule: '',
				})
			)
			setHideSlotFormik(false)
		}
	}, [dispatch, slotbooking])

	useEffect(() => {
		_.isEmpty(timings) && setHideFeciltityForm(false)
		setHideSlotFormik(false)
	}, [timings])

	const getSpecialityTitle = (groupName, groupId) => {
		return _.isEqual(groupName, 'fitness') || _.isEqual(groupId, 'e7z11j8m')
			? 'Select your fitness speciality'
			: _.isEqual(groupName, 'wellness') || _.isEqual(groupId, '2nzdfwug')
			? 'Select your mind speciality'
			: _.isEqual(groupName, 'sports') || _.isEqual(groupId, 'ztyxtevg')
			? 'Select your sport speciality'
			: 'Select your speciality'
	}

	return (
		<>
			{_.isEqual(groupName, 'spa') || _.isEqual(groupId, 'irokb9b8') ? (
				''
			) : (
				<div className={classes.MainContain}>
					<div className={classes.firstContent}>
						<div className={classes.AutocompleteField}>
							<Autocomplete
								id='country-select-demo'
								options={practicelist}
								autoHighlight
								disableClearable={true}
								getOptionLabel={(option) => option?.practice_name || ''}
								value={formik?.values?.practiceName}
								onChange={onPracticeChange}
								renderInput={(params) => (
									<TextField
										{...params}
										disabled={copyAllDay}
										className={classes.textField}
										color='secondary'
										size='small'
										placeholder='Practice name'
										error={formik.touched.practiceName && formik.errors.practiceName}
										helperText={formik.touched.practiceName && formik.errors.practiceName && formik.errors.practiceName}
										inputProps={{
											...params.inputProps,
										}}
									/>
								)}
							/>
						</div>

						<div className={classes.secContent}>
							<Typography variant='h6' className={classes.bookingsContent}>
								Slot bookings for:
							</Typography>

							<div className={classes.dayContain}>
								<ListItem role='listitem' button onClick={handleAllDay} disabled={!_.isEmpty(selectedDays)}>
									<ListItemText primary='Schedule for' />
									<ListItemIcon>
										<ListItemText primary='All Day' />
										<Checkbox
											checked={allDaySelected}
											inputProps={{
												'aria-label': 'all items selected',
											}}
											color='default'
											icon={<CancelIcon fontSize='medium' />}
											checkedIcon={<CheckCircleIcon fontSize='medium' />}
										/>
									</ListItemIcon>
								</ListItem>
								<Divider />
								{/* schedule days mapping */}
								<ScheduleDays
									classes={classes}
									slotBookingDay={slotBookingDay}
									handleOnChange={handleOnChange}
									selectedDays={selectedDays}
									hasData={!_.isEmpty(slotbooking)}
								/>
							</div>
							{formik?.touched?.schedule && formik?.errors?.schedule && (
								<FormHelperText className={'mb-10'} error>
									{formik?.errors?.schedule}
								</FormHelperText>
							)}
						</div>
					</div>
					<div className={classes.flexroot}>
						<div className={classes.contFlex}>
							<div className={classes.finalList}>
								<div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
									<Checkbox
										color='default'
										icon={<CheckCircleOutlineIcon fontSize='medium' />}
										value={copyAllDay}
										onChange={() => setCopyAllDay((prevState) => !prevState)}
										checkedIcon={<CheckCircleIcon fontSize='medium' />}
									/>
									<Typography>Copy for all establishments </Typography>
								</div>
								<div className={classes.PaymentField}>
									<Typography>Payment mandatory for booking</Typography>
									<div className={classes.ButtonField}>
										<Button onClick={() => buttonHandler(true)} className={reqPayment ? 'active' : 'deactive'} variant='contained'>
											Yes
										</Button>
										<Button onClick={() => buttonHandler(false)} className={!reqPayment ? 'active' : 'deactive'} variant='contained'>
											No
										</Button>
									</div>
								</div>
							</div>
							{/*facility mapping */}
							<FecilityMapping classes={classes} splArr={timings} removeRow={removeRow} />
							{!hideFecilityForm ? (
								<div className={classes.thirdCont}>
									<div className={classes.autoMainList}>
										<Typography>{getSpecialityTitle(groupName, groupId)}</Typography>
										<div className={classes.slotautoMain}>
											<div className={classes.slotEveryHours}>
												<Autocomplete
													id='country-select-demo'
													options={tenantType}
													loading={specialityLoading}
													autoHighlight
													noOptionsText='No Options found'
													getOptionLabel={(option) => option?.specialityName || ''}
													getOptionSelected={(option, value) => option?.specialityName === value?.specialityName}
													value={formik?.values?.practiceSpeciality}
													onChange={(e, value) => {
														onSpecialityChange(e, value)
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															className={classes.textField}
															color='secondary'
															size='small'
															placeholder='Speciality type'
															error={formik.touched.practiceSpeciality && formik.errors.practiceSpeciality}
															helperText={formik.touched.practiceSpeciality && formik.errors.practiceSpeciality && formik.errors.practiceSpeciality}
															inputProps={{
																...params.inputProps,
															}}
														/>
													)}
												/>
												<div className={classes.listIemMain}>
													<Button
														className={classes.addExperiencebtn1}
														onClick={(e) => {
															validationType.current = 'addFecilty'
															formik.handleSubmit(e)
															slotBookingFormik.handleSubmit(e)
														}}
													>
														+ Add another speciality
													</Button>
												</div>
												{!_.isEmpty(timings) && (
													<IconButton
														onClick={() => {
															setHideFeciltityForm(true)
															setHideSlotFormik(true)
														}}
													>
														<HighlightOffIcon style={{ color: 'red' }} />
													</IconButton>
												)}
											</div>
										</div>
									</div>

									<div className={classes.listItem}>
										<div className={classes.mainTextFild}>
											<Typography>Slots:</Typography>
											<SlotMapping slotbooking={slotbooking} classes={classes} removeRow={removeRow1} />
											{!hideSlotFormik && (
												<div className={classes.mainSlot1}>
													<div>
														<Typography className={'mb-5'}>Starts at</Typography>
														<TimePicker
															id='startTime'
															name='startTime'
															placeholder='9.00  AM'
															className={classes.timePicker}
															value={slotBookingFormik?.values?.startTime}
															onChange={(value) => {
																slotBookingFormik?.setFieldValue(`startTime`, value)
															}}
															renderInput={(params) => <TextField size='small' color='secondary' sx={{ width: 130 }} {...params} />}
														/>
														{slotBookingFormik.touched.startTime && slotBookingFormik.errors.startTime && (
															<FormHelperText error>{slotBookingFormik.errors.startTime}</FormHelperText>
														)}
													</div>
													<Typography className={classes.label}>to</Typography>
													<div>
														<Typography className={'mb-5'}>Ends at</Typography>

														<TimePicker
															id='endTime'
															name='endTime'
															placeholder='9.30  AM'
															className={classes.timePicker}
															value={slotBookingFormik?.values?.endTime}
															onChange={(value) => {
																slotBookingFormik?.setFieldValue(`endTime`, value)
															}}
															renderInput={(params) => <TextField size='small' color='secondary' sx={{ width: 130 }} {...params} />}
														/>
														{slotBookingFormik.touched.endTime && slotBookingFormik.errors.endTime && (
															<FormHelperText error>{slotBookingFormik.errors.endTime}</FormHelperText>
														)}
													</div>
													<Typography className={classes.label}>at</Typography>
													<div>
														<Typography className={'mb-5'}>Price</Typography>
														<TextField
															id='price'
															name='price'
															color='secondary'
															variant='outlined'
															sx={{ width: 130 }}
															size='small'
															value={slotBookingFormik?.values?.price}
															onChange={slotBookingFormik.handleChange}
															placeholder='Rs _ _ _'
															error={slotBookingFormik.touched.price && slotBookingFormik.errors.price}
															helperText={slotBookingFormik.touched.price && slotBookingFormik.errors.price}
														/>
													</div>
													<Typography className={classes.label}>::</Typography>
													{_.isEqual(groupName, 'fitness') || _.isEqual(groupId, 'e7z11j8m') ? (
														<div>
															<Typography className={'mb-5'}>Space</Typography>
															<TextField
																id='venueSpace'
																name='venueSpace'
																color='secondary'
																size='small'
																variant='outlined'
																sx={{ width: 130 }}
																value={slotBookingFormik?.values?.venueSpace}
																onChange={slotBookingFormik.handleChange}
																placeholder='*3'
																error={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
																helperText={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
															/>
														</div>
													) : _.isEqual(groupName, 'mind') || _.isEqual(groupId, '2nzdfwug') ? (
														<div>
															<Typography className={'mb-5'}>Space</Typography>
															<TextField
																id='venueSpace'
																name='venueSpace'
																color='secondary'
																size='small'
																variant='outlined'
																sx={{ width: 130 }}
																value={slotBookingFormik?.values?.venueSpace}
																onChange={slotBookingFormik.handleChange}
																placeholder='*3'
																error={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
																helperText={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
															/>
														</div>
													) : _.isEqual(groupName, 'sports') || _.isEqual(groupId, 'ztyxtevg') ? (
														<div>
															<Typography className={'mb-5'}>Space</Typography>
															<TextField
																id='venueSpace'
																name='venueSpace'
																color='secondary'
																size='small'
																variant='outlined'
																sx={{ width: 130 }}
																value={slotBookingFormik?.values?.venueSpace}
																onChange={slotBookingFormik.handleChange}
																placeholder='*3'
																error={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
																helperText={slotBookingFormik.touched.venueSpace && slotBookingFormik.errors.venueSpace}
															/>
														</div>
													) : (
														''
													)}
													{_.isEqual(groupName, 'sports') || _.isEqual(groupId, 'ztyxtevg') ? (
														''
													) : (
														<div>
															<Typography className={'mb-5'}>Hours</Typography>
															<Autocomplete
																id='space'
																name='space'
																options={hours}
																value={slotBookingFormik?.values?.space}
																getOptionLabel={(option) => option}
																getOptionSelected={(option, value) => option === value}
																disableClearable={true}
																onChange={(e, value) => {
																	slotBookingFormik.setFieldValue('space', value)
																}}
																sx={{ width: 130 }}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		error={slotBookingFormik.touched.space && slotBookingFormik.errors.space}
																		helperText={slotBookingFormik.touched.space && slotBookingFormik.errors.space}
																		size='small'
																	/>
																)}
															/>
														</div>
													)}

													{!_.isEmpty(slotbooking) && (
														<div>
															<IconButton className={classes.cancelExperience} onClick={() => setHideSlotFormik(true)}>
																<HighlightOffIcon style={{ color: 'red' }} />
															</IconButton>
														</div>
													)}
												</div>
											)}

											<div className={classes.addExperience}>
												<Button
													className={classes.addExperiencebtn}
													disabled={selectedDays?.length === 7 || allDaySelected}
													onClick={(e) => {
														validationType.current = 'slotValidation'
														formik.handleSubmit(e)

														slotBookingFormik.handleSubmit(e)
													}}
												>
													+ Add Time Slots
												</Button>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className={classes.addExperience}>
									<Button
										className={classes.addExperiencebtn}
										disabled={selectedDays?.length === 7}
										onClick={(e) => {
											setHideFeciltityForm(false)
											setHideSlotFormik(false)
											formik.resetForm()
											slotBookingFormik.resetForm()
											dispatch(setTimingFormData({}))
											dispatch(setTimingData([]))
										}}
									>
										+ Add another facility
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			{_.isEqual(groupName, 'spa') || _.isEqual(groupId, 'irokb9b8') ? <SpaAndWellness /> : ''}
			<div className={classes.buttonMain}>
				<ButtonComp onClick={() => moveBackTab()}>Back</ButtonComp>
				<ButtonComp
					onClick={(e) => {
						validationType.current = 'submitForm'
						slotBookingFormik?.handleSubmit(e)
						formik?.handleSubmit(e)
					}}
				>
					Next
				</ButtonComp>
			</div>
		</>
	)
}

const hours = ['30 mins', '60 mins', '90 mins', '120 mins']
