import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	groupSelection: () => {
		return axios.get(`${API_ENDPOINTS.GROUP_SELECTION}`, {
			headers: { 'Content-Type': 'application/json' },
		})
	},
}
