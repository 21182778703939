import { ACTION_TYPES } from '../../../../constants'

const initialState = {
	getCustomerMaster: '',
	customer_list_data: {},
	specialistDataList: '',
}
const customerUuidAction = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.CUSTOMER_DATA: {
			return {
				...state,
				customer_list_data: {},
			}
		}
		case ACTION_TYPES.CUSTOMER_LIST_DATA: {
			return {
				...state,
				customer_list_data: action.payload,
			}
		}
		case ACTION_TYPES.GETCUST_MASTER: {
			return {
				...state,
				getCustomerMaster: action.payload,
			}
		}
		case ACTION_TYPES.SPECIALIST_LIST_DATA: {
			return {
				...state,
				specialistDataList: action.payload,
			}
		}
		default:
			return state
	}
}

export default customerUuidAction
