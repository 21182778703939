import { ACTION_TYPES } from '../../../constants/index'

const initialValues = {
	importData: [],
}

const getImportData = (state = initialValues, action) => {
	switch (action.type) {
		case ACTION_TYPES.IMPORT_DATA: {
			return {
				...state,
				importData: action.payload,
			}
		}
		default:
			return state
	}
}

export default getImportData
