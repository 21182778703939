/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
	{
		WeekNo: 1,
		week: 'Week 1',
	},
	{
		WeekNo: 2,
		week: 'Week 2',
	},
	{
		WeekNo: 3,
		week: 'Week 3',
	},
	{
		WeekNo: 4,
		week: 'Week 4',
	},
	{
		WeekNo: 5,
		week: 'Week 5',
	},
	{
		WeekNo: 6,
		week: 'Week 6',
	},
	{
		WeekNo: 7,
		week: 'Week 7',
	},
	{
		WeekNo: 8,
		week: 'Week 8',
	},
	{
		WeekNo: 9,
		week: 'Week 9',
	},
	{
		WeekNo: 10,
		week: 'Week 10',
	},
	{
		WeekNo: 11,
		week: 'Week 11',
	},
	{
		WeekNo: 12,
		week: 'Week 12',
	},
	{
		WeekNo: 13,
		week: 'Week 13',
	},
	{
		WeekNo: 14,
		week: 'Week 14',
	},
	{
		WeekNo: 15,
		week: 'Week 15',
	},
	{
		WeekNo: 16,
		week: 'Week 16',
	},
	{
		WeekNo: 17,
		week: 'Week 17',
	},
	{
		WeekNo: 18,
		week: 'Week 18',
	},
	{
		WeekNo: 19,
		week: 'Week 19',
	},
	{
		WeekNo: 20,
		week: 'Week 20',
	},
	{
		WeekNo: 21,
		week: 'Week 21',
	},
	{
		WeekNo: 22,
		week: 'Week 22',
	},
	{
		WeekNo: 23,
		week: 'Week 23',
	},
	{
		WeekNo: 24,
		week: 'Week 24',
	},
	{
		WeekNo: 25,
		week: 'Week 25',
	},
	{
		WeekNo: 26,
		week: 'Week 26',
	},
	{
		WeekNo: 27,
		week: 'Week 27',
	},
	{
		WeekNo: 28,
		week: 'Week 28',
	},
	{
		WeekNo: 29,
		week: 'Week 29',
	},
	{
		WeekNo: 30,
		week: 'Week 30',
	},
	{
		WeekNo: 31,
		week: 'Week 31',
	},
	{
		WeekNo: 32,
		week: 'Week 32',
	},
	{
		WeekNo: 33,
		week: 'Week 33',
	},
	{
		WeekNo: 34,
		week: 'Week 34',
	},
	{
		WeekNo: 35,
		week: 'Week 35',
	},
	{
		WeekNo: 36,
		week: 'Week 36',
	},
	{
		WeekNo: 37,
		week: 'Week 37',
	},
	{
		WeekNo: 38,
		week: 'Week 38',
	},
	{
		WeekNo: 39,
		week: 'Week 39',
	},
	{
		WeekNo: 40,
		week: 'Week 40',
	},
	{
		WeekNo: 41,
		week: 'Week 41',
	},
	{
		WeekNo: 42,
		week: 'Week 42',
	},
	{
		WeekNo: 43,
		week: 'Week 43',
	},
	{
		WeekNo: 44,
		week: 'Week 44',
	},
	{
		WeekNo: 45,
		week: 'Week 45',
	},
	{
		WeekNo: 46,
		week: 'Week 46',
	},
	{
		WeekNo: 47,
		week: 'Week 47',
	},
	{
		WeekNo: 48,
		week: 'Week 48',
	},
	{
		WeekNo: 49,
		week: 'week 49',
	},
	{
		WeekNo: 50,
		week: 'Week 50',
	},
	{
		WeekNo: 51,
		week: 'Week 51',
	},
	{
		WeekNo: 52,
		week: 'Week 52',
	},
]
