import * as React from 'react'
import { makeStyles } from '@mui/styles'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import OutlinedInput from '@mui/material/OutlinedInput'
// import OutlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses'

const useStyles = makeStyles((theme) => ({
	timePicker: {
		// border: '1px solid #ccc',
		borderRadius: 2,
	},
	outlinedInput: {
		width: (props) => (props?.fullWidth ? '100%' : props?.isGroup ? 130 : 162),
		'& :hover': {
			border: '1px solid #c4c4c4',
		},
		'& .Mui-focused': {
			'& fieldset': {
				borderColor: '#979797',
			},
		},
	},
}))
const DefaultTimePicker = (props) => {
	const { value, onChange, disabled, id, isGroup, label, needLabel, fullWidth, minTime, maxTime } = props
	const classes = useStyles({ isGroup, fullWidth })
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<TimePicker
				label={label ? label : needLabel ? '' : 'Time *'}
				// name={id ? id : 'time'}
				// id={id ? id : 'time'}
				value={value}
				// className={classes.timePicker}
				// minTime={minTime}
				// disabled={disabled}
				minTime={minTime}
				maxTime={maxTime}
				onChange={(newValue) => onChange(newValue)}
				renderInput={(params) => <OutlinedInput {...params} size='small' classes={{ root: classes.outlinedInput }} />}
				slotProps={{
					textField: {
						size: 'small',
						color: 'secondary',
						sx: {
							borderRadius: 2,
							'& .MuiOutlinedInput-root': {
								// backgroundColor: '#fff',
							},
							'& .MuiOutlinedInput-notchedOutline': {
								border: '1px solid #c4c4c4',
								'& :hover': {
									border: '1px solid #c4c4c4',
								},
							},
							fieldset: {
								borderRadius: 2,
								'& .Mui-focused': {
									border: '1px solid #979797',
								},
							},
							// [`.${OutlinedInputClasses.root}`]: {
							// 	height: 20,
							// 	width: isGroup ? 130 : 162,
							// 	'& .Mui-focused': {
							// 		border: '1px solid #979797',
							// 	},
							// },
						},
					},
					popper: {
						sx: {
							'& .MuiList-root': {
								backgroundColor: '#ffffff',
							},
							'& .MuiMenuItem-root': {
								'&.Mui-selected': {
									backgroundColor: '#E5F6FF',
									color: '#000',
								},
								color: '#000',
							},
							'& .MuiSvgIcon-root': {
								'&.MuiSvgIcon-fontSizeMedium': {
									backgroundColor: '#04395E',
									color: 'white',
								},
								color: 'white',
							},
							'& .MuiDialogActions-root': {
								display: 'none',
							},
						},
					},
				}}
			/>
		</LocalizationProvider>
	)
}

export default DefaultTimePicker
