import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	root: {
		paddingInline: 30,
		paddingBlock: 20,
		'& .MuiAvatar-root': {
			width: 100,
			height: 100,
			[theme.breakpoints.down('sm')]: {
				width: 82,
				height: 82,
			},
		},
		[theme.breakpoints.down('sm')]: {
			paddingInline: 12,
		},
	},
	establishmentviewMain: {
		background: '#fff',
		height: '72vh',
		paddingBlock: 30,
		paddingInline: 30,
		borderRadius: 12,
		[theme.breakpoints.down('sm')]: {
			paddingBlock: 12,
			paddingInline: 12,
			flexDirection: 'column',
		},
	},
	datapart: {
		[theme.breakpoints.down('sm')]: {
			gap: 16,
		},
	},
	userTextMessage: {
		maxHeight: 60,
		background: '#FFF0E0 0% 0% no-repeat padding-box',
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		paddingBlock: 8,
		paddingInline: 8,
		borderRadius: 12,
		[theme.breakpoints.down('sm')]: {
			marginBlockStart: 0,
			marginInlineStart: 0,
		},
	},
	establishmentDetails: {
		display: 'flex',
		flexDirection: 'column',
		gap: 4,
	},
	establishmentName: {
		color: themesConfig.greyDark.palette.primary.main,
		fontFamily: 'poppins',
		fontWeight: 500,
		fontSize: 16,
	},
	addressDetails: {
		display: 'flex',
		'& .MuiTypography-root': {
			color: '#636669',
			fontFamily: 'poppins',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	avatar: {
		width: '110px',
		height: '110px',
	},
	icon: {
		fontSize: '20px',
	},
	editBtn: {
		paddingLeft: 0,
	},
	contactDetails: {
		color: '#636669',
		'& .MuiTypography-root': {
			color: '#636669',
			fontFamily: 'poppins',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	edit: {
		display: 'flex',
		cursor: 'pointer',
		gap: 6,
		'& .MuiTypography-root': {
			color: '#636669',
			fontFamily: 'poppins',
		},
		'& .MuiSvgIcon-root': {
			fill: '#E22C24',
		},
	},
	editIcon: {
		padding: 0,
	},
	addEstablishment: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		paddingBlock: 12,
		'& .MuiSvgIcon-root': {
			fill: '#E22C24',
		},
	},
	buttonMain: {
		paddingBlock: 20,
		justifyContent: 'space-between',
	},
}))
export default useStyles
