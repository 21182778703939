import LeadsConfig from './LeadManagement/Leads/LeadsConfig'
import TaskConfig from './LeadManagement/Task/TaskConfig'
import MemberConfig from './LeadManagement/Member/Memberconfig'
import CommunicationConfig from './communication/ComminationConfig'
import ConsultationConfig from './Consultation/config'
import ReportConfig from './report/ReportConfig'
import DriveConfig from './integration/drive/DriveConfig'
import OnlineFollowUpConfig from './integration/onlineFollowUp/OnlineFollowUpConfig'
import AidivaaPrimeConfig from './integration/aidivaaPrime/AidivaaPrimeConfig'
import ProfileConfig from './profile/ProfileConfig'
import PrimeConfig from './prime/PrimeConfig'
import LoginOldConfig from './LoginOld/LoginOldConfig'

const appsConfigs = [
	LeadsConfig,
	TaskConfig,
	MemberConfig,
	CommunicationConfig,
	ConsultationConfig,
	ReportConfig,
	DriveConfig,
	OnlineFollowUpConfig,
	AidivaaPrimeConfig,
	ProfileConfig,
	PrimeConfig,
	LoginOldConfig,
]

export default appsConfigs
