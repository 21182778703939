import React from 'react'
import { makeStyles } from '@mui/styles'
import { TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateRangePicker from '@mui/lab/DateRangePicker'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	datePickerContainer: {
		'& .Mui-focused': {
			color: 'inherit',
		},
		'& fieldset': {
			border: 'none',
		},
		'& input': {
			fontWeight: 400,
			fontSize: '1.34rem',
		},
		'& label': {
			background: '#FFF',
			paddingInline: 4,
		},
	},
	datePickerContainerBorder: {
		border: '1px solid rgb(189 189 189)',
		borderRadius: 10,
	},
}))

function ApptDateRangePicker({ value, setValue }) {
	const classes = useStyles()
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)

	return (
		<div className={classes.datePickerContainer}>
			<div className={classes.datePickerContainerBorder}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateRangePicker
						id='Date_Range_Picker'
						name='Date_Range_Picker'
						startText={'Select period'}
						endText={''}
						calendars={1}
						value={value}
						inputFormat={dateFormat}
						minDate={new Date()}
						onChange={(newValue) => {
							setValue(newValue)
						}}
						disablePast
						renderInput={(startProps, endProps) => (
							<React.Fragment>
								<TextField size='small' {...startProps} />
								<span> to </span>
								<TextField size='small' {...endProps} />
							</React.Fragment>
						)}
					/>
				</LocalizationProvider>
			</div>
		</div>
	)
}

export default ApptDateRangePicker
