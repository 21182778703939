import Member from './Member'

const MemberConfig = {
	routes: [
		{
			path: '/member',
			component: Member,
		},
	],
}

export default MemberConfig
