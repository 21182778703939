import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const setDataToLocalStorage = (
	userName,
	userType,
	deviceId,
	isPractice,
	isEmail,
	groupId,
	AidiBearer,
	isWelcome,
	mobileNo,
	userEmail,
	mastuuid,
	currentUser,
	tentTypeId,
	resendOtpButton,
	userRole,
	tenantName,
	countryCode,
	countryName,
	countryNameAbbreviation,
	planReminderUuid,
	profilepic,
	isTokenValid,
	isNewUser,
	identityUuid,
	owner,
	newOwner,
	isPinSet,
	signUpViaName,
	mastTentTypeUuid
) => {
	secureLocalStorage.setItem('isPractice', isPractice)
	secureLocalStorage.setItem('userType', userType)
	secureLocalStorage.setItem('AidiDeviceId', deviceId)
	secureLocalStorage.setItem('userName', userName)
	secureLocalStorage.setItem('isEmail', isEmail)
	secureLocalStorage.setItem('groupId', groupId)
	secureLocalStorage.setItem('AidiBearer', AidiBearer)
	secureLocalStorage.setItem('isWelcome', isWelcome)
	secureLocalStorage.setItem('isAuthenticated', !!AidiBearer && isWelcome)
	secureLocalStorage.setItem('mobileNo', mobileNo)
	secureLocalStorage.setItem('userEmail', userEmail)
	secureLocalStorage.setItem('mastuuid', mastuuid)
	secureLocalStorage.setItem('currentUser', currentUser)
	secureLocalStorage.setItem('tentTypeId', tentTypeId)
	secureLocalStorage.setItem('userRole', userRole)
	secureLocalStorage.setItem('planReminderUuid', planReminderUuid)
	secureLocalStorage.setItem(
		'groupName',
		groupId === 'fyi6pmtm'
			? 'health care'
			: groupId === 'irokb9b8'
			? 'spa'
			: groupId === '2nzdfwug'
			? 'wellness'
			: groupId === 'ztyxtevg'
			? 'sports'
			: groupId === 'e7z11j8m'
			? 'fitness'
			: ''
	)
	secureLocalStorage.setItem('resendOtpButton', resendOtpButton)
	secureLocalStorage.setItem('tenantName', tenantName)
	secureLocalStorage.setItem('countryCode', countryCode)
	secureLocalStorage.setItem('countryNameAbbreviation', countryNameAbbreviation)
	secureLocalStorage.setItem('countryName', countryName)
	secureLocalStorage.setItem('profilepic', profilepic)
	secureLocalStorage.setItem('isTokenValid', isTokenValid)
	secureLocalStorage.setItem('isNewUser', isNewUser)
	secureLocalStorage.setItem('identityUuid', identityUuid)
	secureLocalStorage.setItem('newOwner', newOwner)
	secureLocalStorage.getItem('ownerStatus', owner)
	secureLocalStorage.getItem('isPinSet', isPinSet)
	secureLocalStorage?.setItem('signUpViaName', signUpViaName)
	secureLocalStorage?.setItem('mastTentTypeUuid', mastTentTypeUuid)
}
const userInfoReducer = (
	state = {
		isAuthenticated: secureLocalStorage.getItem('isAuthenticated') === true || false,
		mobileNo: secureLocalStorage.getItem('mobileNo') || '',
		isMobile: secureLocalStorage.getItem('isMobile') === true || false,
		uuid: secureLocalStorage.getItem('uuid') || '',
		userType: secureLocalStorage.getItem('userType') || '',
		isEmail: secureLocalStorage.getItem('isEmail') === true || false,
		emailId: secureLocalStorage.getItem('aidivaTenantUserEmail') || '',
		isPractice: secureLocalStorage.getItem('isPractice') === true || false,
		isWelcome: secureLocalStorage.getItem('isWelcome') === true || false,
		isOtp: secureLocalStorage.getItem('isOtp') === true || false,
		isWhatsappOtp: secureLocalStorage.getItem('isWhatsappOtp') === true || false,
		BearerToken: secureLocalStorage.getItem('AidiBearer') || null,
		groupId: secureLocalStorage.getItem('groupId') || '',
		deviceId: secureLocalStorage.getItem('AidiDeviceId') || '',
		mastuuid: secureLocalStorage.getItem('mastuuid') || '',
		userEmail: secureLocalStorage.getItem('userEmail') || '',
		AidiPracticeDetails: secureLocalStorage.getItem('AidiPracticeDetails') || [],
		resetPasswordMail: secureLocalStorage.getItem('resetPasswordMail') || '',
		phoneNumberLength: secureLocalStorage.getItem('phoneNumberLength') || '',
		currentUser: secureLocalStorage.getItem('currentUser') || '',
		tentTypeId: secureLocalStorage.getItem('tentTypeId') || '',
		groupName: secureLocalStorage.getItem('groupName') || '',
		resendOtpButton: secureLocalStorage.getItem('resendOtpButton') || '',
		userRole: secureLocalStorage.getItem('userRole') || '',
		tenantName: secureLocalStorage.getItem('tenantName') || '',
		countryCode: secureLocalStorage.getItem('countryCode') || '',
		countryName: secureLocalStorage.getItem('countryName') || '',
		countryNameAbbreviation: secureLocalStorage.getItem('countryNameAbbreviation') || '',
		planReminderUuid: secureLocalStorage.getItem('planReminderUuid') || '',
		userName: secureLocalStorage.getItem('userName') || '',
		profilepic: secureLocalStorage.getItem('profilepic' || ''),
		isTokenValid: secureLocalStorage.getItem('isTokenValid' || ''),
		isNewUser: secureLocalStorage.getItem('isNewUser') === true || false,
		identityUuid: secureLocalStorage.getItem('identityUuid') || '',
		newOwner: secureLocalStorage.getItem('newOwner') || '',
		owner: secureLocalStorage.getItem('ownerStatus') || '',
		xeroStatus: secureLocalStorage.getItem('xeroStatus') === 'true' || false,
		zohoStatus: secureLocalStorage.getItem('zohoStatus') === 'true' || false,
		isPinSet: secureLocalStorage.getItem('isPinSet') === true || false,
		isPinSetStatus: secureLocalStorage.getItem('isPinSetStatus') === true || false,
		isModuleSelected: secureLocalStorage.getItem('isModuleSelected') === true || false,
		signUpViaName: secureLocalStorage?.getItem('signUpViaName') || '',
		mastTentTypeUuid: secureLocalStorage?.getItem('mastTentTypeUuid') || '',
	},
	action
) => {
	switch (action.type) {
		case ACTION_TYPES.AUTH_REGISTER: {
			return {
				...state,
				userType: action.payload.userType,
				uuid: action.payload.uuid,
				mobileNo: action.payload.mobileNo,
				emailId: action.payload.emailId,
				userEmail: action.payload.userEmail,
				countryCode: action.payload.countryCode,
				countryNameAbbreviation: action.payload.countryNameAbbreviation,
				countryName: action.payload.countryName,
				identityUuid: action.payload.identityUuid,
				newOwner: action.payload.newOwner,
				BearerToken: action.payload.BearerToken,
				isWhatsappOtp: action.payload.isWhatsappOtp,
			}
		}
		case ACTION_TYPES.AUTH_LOGIN: {
			const isWelcome =
				(action.payload.isPractice && action.payload.isModuleSelected && action.payload.isMobile) ||
				(action.payload.signUpViaName === 'google' && action.payload.isPractice && action.payload.isModuleSelected) ||
				action.payload.owner === false
					? true
					: false
			const isAuthenticated = isWelcome && !!action.payload.BearerToken
			const groupName =
				action.payload.groupId === 'fyi6pmtm'
					? 'health care'
					: action.payload.groupId === 'irokb9b8'
					? 'spa'
					: action.payload.groupId === '2nzdfwug'
					? 'wellness'
					: action.payload.groupId === 'ztyxtevg'
					? 'sports'
					: action.payload.groupId === 'e7z11j8m'
					? 'fitness'
					: ''

			setDataToLocalStorage(
				action.payload.userName,
				action.payload.userType,
				action.payload.deviceId,
				action.payload.isPractice,
				action.payload.isEmail,
				action.payload.groupId,
				action.payload.BearerToken,
				isWelcome,
				action.payload.mobileNo,
				action.payload.userEmail,
				action.payload.mastuuid,
				action.payload.currentUser,
				action.payload.tentTypeId,
				groupName,
				action.payload.resendOtpButton,
				action.payload.userRole,
				action.payload.tenantName,
				action.payload.profilepic,
				action.payload.countryCode,
				action.payload.countryName,
				action.payload.countryNameAbbreviation,
				action.payload.isTokenValid,
				false,
				action.payload.identityUuid,
				action.payload.owner,
				action.payload.isPinSet,
				action.payload.isModuleSelected,
				action.payload.signUpViaName,
				action.payload.mastTentTypeUuid
			)
			return {
				...state,
				isAuthenticated,
				BearerToken: action.payload.BearerToken,
				isMobile: action.payload.isMobile,
				isEmail: action.payload.isEmail,
				emailId: action.payload.emailId,
				isPractice: action.payload.isPractice,
				userType: action.payload.userType,
				isWelcome: isWelcome,
				isOtp: action.payload.isOtp,
				isWhatsappOtp: action.payload.isWhatsappOtp,
				uuid: action.payload.uuid,
				groupId: action.payload.groupId,
				groupName,
				userEmail: action.payload.userEmail,
				mobileNo: action.payload.mobileNo,
				mastuuid: action.payload.mastuuid,
				userRole: action.payload.userRole,
				userName: action.payload.userName,
				profilepic: action.payload.profilepic,
				countryCode: action.payload.countryCode,
				countryNameAbbreviation: action.payload.countryNameAbbreviation,
				countryName: action.payload.countryName,
				isTokenValid: action.payload.isTokenValid,
				isNewUser: false,
				identityUuid: action.payload.identityUuid,
				owner: action.payload.owner,
				isPinSet: action.payload.isPinSet,
				isModuleSelected: action.payload.isModuleSelected,
				signUpViaName: action.payload.signUpViaName,
				mastTentTypeUuid: action.payload.mastTentTypeUuid,
			}
		}
		case ACTION_TYPES.SET_PIN_STATUS: {
			return {
				...state,
				isPinSetStatus: true,
			}
		}
		case ACTION_TYPES.UPDATE_MAIL: {
			return {
				...state,
				emailId: action.payload.emailId,
			}
		}
		case ACTION_TYPES.AUTH_OTP: {
			if (state.isOtp) {
				secureLocalStorage.setItem('isAuthenticated', !!action.payload.bearer && state?.isWelcome)
				return {
					...state,
					emailId: action.payload.emailId,
					bearerToken: action.payload.bearer,
					isAuthenticated: state?.isWelcome && action.payload.bearer,
				}
			} else {
				return {
					...state,
					emailId: action.payload.emailId,
					bearerToken: action.payload.bearer,
					isEmail: action.payload.isEmail,
				}
			}
		}
		case ACTION_TYPES.AUTH_RESETPASSWORD: {
			return {
				...state,
				resetPasswordMail: action?.payload?.resetPasswordMail,
				message: action.payload.message,
			}
		}
		case ACTION_TYPES.UPDATE_DEVICE_ID: {
			return {
				...state,
				deviceId: action.id,
			}
		}
		case ACTION_TYPES.UPDATE_PRACTICEDETAILS: {
			secureLocalStorage.setItem('AidiPracticeDetails', action.payload)
			return {
				...state,
				AidiPracticeDetails: action.payload,
			}
		}
		case ACTION_TYPES.AUTH_LOGOUT: {
			secureLocalStorage.clear()
			secureLocalStorage.setItem('rememberMail', action.payload.rememberMail)
			secureLocalStorage.setItem('checked', action.payload.rememberMail)
			return {
				isAuthenticated: false,
				mobileNo: '',
				isMobile: false,
				uuid: '',
				userType: '',
				isEmail: false,
				emailId: '',
				isPractice: false,
				isModuleSelected: false,
				isWelcome: false,
				isOtp: false,
				isWhatsappOtp: false,
				BearerToken: null,
				groupId: '',
				deviceId: '',
				mastuuid: '',
				isNewUser: false,
				rememberMail: action.payload.rememberMail || '',
				rememberCheck: action.payload.rememberCheck || true,
			}
		}
		case ACTION_TYPES.AUTH_FORGOTPASSWORD: {
			return {
				...state,
				emailId: action.payload.emailId,
			}
		}
		case ACTION_TYPES.AUTH_GROUPSELECTION: {
			const groupId = action.payload.groupId
			return {
				...state,
				groupId: groupId,
				groupName:
					groupId === 'fyi6pmtm'
						? 'health care'
						: groupId === 'irokb9b8'
						? 'spa'
						: groupId === '2nzdfwug'
						? 'wellness'
						: groupId === 'ztyxtevg'
						? 'sports'
						: groupId === 'e7z11j8m'
						? 'fitness'
						: '',
			}
		}
		case ACTION_TYPES.UPDATE_ISWELCOME: {
			secureLocalStorage.setItem('isWelcome', true)
			secureLocalStorage.setItem('isAuthenticated', true)
			return {
				...state,
				isWelcome: action.isWelcome,
				isAuthenticated: true,
			}
		}
		case ACTION_TYPES.AUTH_ESTSELECTION: {
			return {
				...state,
				isPractice: action.payload.isPractice,
				mastuuid: action.payload.mastuuid,
				mastTentTypeUuid: action.payload.mastTentTypeUuid,
				userRole: action.payload.userRole,
			}
		}
		case ACTION_TYPES.CURRENT_USER: {
			return {
				...state,
				currentUser: action.payload.currentUser,
			}
		}
		case ACTION_TYPES.MOBILE_NUMBER_LENGTH: {
			return {
				...state,
				phoneNumberLength: action.payload.phoneNumberLength,
			}
		}
		case ACTION_TYPES.TENANT_TYPE: {
			return {
				...state,
				tentTypeId: action.payload.tentTypeId,
			}
		}
		case ACTION_TYPES.RESENDOTP_BUTTON: {
			return {
				...state,
				resendOtpButton: action.payload.resendOtpButton,
			}
		}
		case ACTION_TYPES.TENANT_NAME: {
			return {
				...state,
				tenantName: action.payload.tenantName,
			}
		}
		case ACTION_TYPES.UPDATE_MASTER_UUID: {
			secureLocalStorage.setItem('mastuuid', action.payload?.mastuuid || action.payload)
			secureLocalStorage.setItem('xeroStatus', action?.payload?.xeroStatus || false)
			secureLocalStorage.setItem('zohoStatus', action?.payload?.zohoStatus || false)
			return {
				...state,
				mastuuid: action.payload?.mastuuid || action?.payload,
				xeroStatus: action?.payload?.xeroStatus || false,
				zohoStatus: action?.payload?.zohoStatus || false,
			}
		}
		case ACTION_TYPES.UPDATE_TENTUSER_UUID: {
			secureLocalStorage.setItem('uuid', action.payload)
			return {
				...state,
				uuid: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_OWNER_STATUS: {
			secureLocalStorage.setItem('ownerStatus', action.payload)
			return {
				...state,
				owner: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_EMAIL: {
			return {
				...state,
				userEmail: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_MOBILE_NUMBER: {
			return {
				...state,
				mobileNo: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_EMAIL_SUCCESS: {
			secureLocalStorage.setItem('groupId', action?.payload?.groupUuid)
			secureLocalStorage.setItem('isEmail', action?.payload?.isEmail)
			secureLocalStorage.setItem('mobileNo', action?.payload?.userMobile)
			secureLocalStorage.setItem('userEmail', action.payload?.userEmail)
			secureLocalStorage.setItem('email', action.payload?.userEmail)
			secureLocalStorage.setItem('uuid', action?.payload?.uuid)
			secureLocalStorage.setItem('isMobile', action.payload.isMobile)
			secureLocalStorage.setItem('userType', 'TNT')
			// secureLocalStorage.setItem('AidiBearer', action?.payload?.queryValue)
			return {
				...state,
				userEmail: action.payload?.userEmail,
				emailId: action.payload?.userEmail,
				mobileNo: action?.payload?.userMobile,
				uuid: action?.payload?.uuid,
				isEmail: action?.payload?.isEmail,
				isMobile: action?.payload?.isMobile,
				groupId: action?.payload?.groupUuid,
				userType: 'TNT',
				// BearerToken: action?.payload?.queryValue,
			}
		}
		case ACTION_TYPES.USER_MENU: {
			return {
				...state,
				userName: action?.payload,
			}
		}
		case ACTION_TYPES.UPDATE_BEARER: {
			return {
				...state,
				BearerToken: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_PROFILEPIC: {
			return {
				...state,
				profilepic: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_NEW_USER: {
			return {
				...state,
				isNewUser: action.payload,
			}
		}
		default:
			return state
	}
}
export default userInfoReducer
