import { JaaSMeeting } from '@jitsi/react-sdk'
import { Box, Button, Typography, Modal, IconButton, Avatar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReconnectingWebSocket from 'reconnecting-websocket'
import { setCallerActive, setJitsiConnect } from 'services/JitsiConsult/actions'
import CallEndIcon from '@mui/icons-material/CallEnd'
import CallIcon from '@mui/icons-material/Call'
import { motion } from 'framer-motion'
import jitsiAPIs from '../../../services/JitsiConsult/api'
import _ from 'lodash'
import history from '@history'
const useStyles = makeStyles((props) => ({
	modalStyle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 358,
		background: '#fff',
		// border: `2px solid ${themeConfig.palette.lyfngo.primary.main}`,
		boxShadow: 24,
		padding: 4,
		borderRadius: 8,
		height: '436px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	callHeader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& .MuiAvatar-root': {
			width: '112px',
			height: '112px',
		},
	},
}))

const CallingPrompt = () => {
	const currentPath = history?.location?.pathname
	const currentSearch = history?.location?.search
	const classes = useStyles()
	const dispatch = useDispatch()
	const authData = useSelector((state) => state?.Authentication)
	const { mastuuid, uuid } = authData
	const jitsiData = useSelector((state) => state?.JistConsult)
	const { activeCall, jitsiRequestData } = jitsiData
	const dynamicMenuData = useSelector((state) => state?.dynamicMenu?.userData)
	const { isJitsiEnabled, jitsiAppId, userRole } = dynamicMenuData
	const [openCaller, setOpenCaller] = useState(false)
	// const [activeCall, setActiveCall] = useState(false)
	const socketRef = useRef(null)

	// Receive user socket
	useEffect(() => {
		if (uuid && isJitsiEnabled && !_.isEqual(userRole, 'Owner') && !_.isEqual(userRole, 'Admin')) {
			const socketUrl = `${process.env.REACT_APP_JITSI_CONNECT + uuid}`
			const userReqSocket = new ReconnectingWebSocket(socketUrl)
			socketRef.current = userReqSocket

			userReqSocket.onopen = () => {
				console.log('WebSocket connected')
			}

			userReqSocket.onmessage = (event) => {
				try {
					const json = JSON.parse(event.data)
					if (json.type === 'call_request') {
						setOpenCaller(true)
						dispatch(setJitsiConnect(json))
						console.log('Incoming call request', json)
					}
				} catch (err) {
					console.error('Error parsing message', err)
				}
			}

			userReqSocket.onerror = (error) => {
				console.error('WebSocket error:', error)
			}

			userReqSocket.onclose = () => {
				console.log('WebSocket connection closed')
			}

			return () => {
				userReqSocket.close() // Clean up the WebSocket connection
			}
		}
	}, [uuid, isJitsiEnabled, dispatch, mastuuid, userRole])

	//Socket receving user request
	// useEffect(() => {
	// 	userReqSocket.onmessage = function (event) {
	// 		const json = JSON.parse(event.data)
	// 		try {
	// 			if ((json.event = 'data')) {
	// 				if (json?.type === 'call_request') {
	// 					setOpenCaller(true)
	// 					dispatch(setJitsiConnect(json))
	// 					console.log('testing incm msg', json)
	// 				}
	// 			}
	// 		} catch (err) {
	// 			console.log(err)
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])
	const handleTriggerJitsi = (status) => {
		const data = {
			status: status,
			roomName: jitsiRequestData?.data?.roomName,
			tent_user_uuid: uuid,
			from: 'flash',
			appointmentUuid: jitsiRequestData?.data?.appointmentUuid,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOpenCaller(false)
				if (status === 'call_accept') {
					dispatch(setCallerActive(true))
					// setActiveCall(true)
				} else {
					if (currentPath !== '/setting' && currentSearch !== '?tabId=callogs') {
						history.push('/setting?tabId=callogs')
					}
				}
			}
		}
		const onFailure = (err) => {
			console.log('err', err)
		}
		jitsiAPIs.getCallAccept(data).then(onSuccess, onFailure)
	}

	const handleEndCall = () => {
		setOpenCaller(false)
		dispatch(setCallerActive(false))
		if (currentPath !== '/setting' && currentSearch !== '?tabId=callogs') {
			history.push('/setting?tabId=callogs')
		}
	}

	const handleEndJitsi = () => {
		dispatch(setCallerActive(false))
		setOpenCaller(false)
		console.log('triggered event end')
		if (currentPath !== '/setting' && currentSearch !== '?tabId=callogs') {
			history.push('/setting?tabId=callogs')
		}
	}

	return (
		<>
			{activeCall ? (
				<JaaSMeeting
					getIFrameRef={(iframeRef) => {
						iframeRef.style.position = 'absolute'
						iframeRef.style.height = 'calc(100vh - 120px)'
						iframeRef.style.width = '100%'
						iframeRef.style.top = '30px'
						iframeRef.style.zIndex = 999999
					}}
					appId={jitsiAppId || jitsiRequestData?.data?.appId}
					roomName={'Consult' || jitsiRequestData?.data?.roomName}
					jwt={jitsiRequestData?.data?.jwt}
					configOverwrite={{
						disableThirdPartyRequests: true,
						disableLocalVideoFlip: true,
						prejoinPageEnabled: false,
						toolbarButtons: [
							'camera',
							'chat',
							'closedcaptions',
							'desktop',
							'download',
							'embedmeeting',
							'etherpad',
							'feedback',
							'filmstrip',
							'fullscreen',
							'hangup',
							'help',
							'highlight',
							'microphone',
							'noisesuppression',
							'participants-pane',
							'profile',
							'raisehand',
							'security',
							'select-background',
							'settings',
							'shareaudio',
							'sharedvideo',
							'shortcuts',
							'tileview',
							'toggle-camera',
							'videoquality',
							'whiteboard',
						],
					}}
					interfaceConfigOverwrite={{
						VIDEO_LAYOUT_FIT: 'nocrop',
						MOBILE_APP_PROMO: false,
						TILE_VIEW_MAX_COLUMNS: 4,
					}}
					onReadyToClose={handleEndJitsi}
				/>
			) : (
				<Modal
					open={openCaller}
					onClose={(event, reason) => {
						if (reason === 'backdropClick') {
							return null
						} else {
							handleEndCall()
						}
					}}
					aria-labelledby='calling-modal-title'
					aria-describedby='calling-modal-description'
					disableEscapeKeyDown
					// hideBackdrop
				>
					<Box className={classes.modalStyle}>
						<div className={classes.callHeader}>
							<Typography id='calling-modal-title' variant='h5' component='h2' gutterBottom>
								Incoming call
							</Typography>
							<Typography id='calling-modal-description' sx={{ fontSize: '18px', fontWeight: 500 }}>
								{jitsiRequestData?.data?.custName}
							</Typography>
							<Avatar src={jitsiRequestData?.data?.custLogo} alt={jitsiRequestData?.data?.custName} sx={{ width: 112, height: 112 }} />
						</div>
						<Box sx={{ mt: 4, display: 'flex', gap: '52px' }}>
							<motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9, y: -5 }} transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
								<Button
									sx={{ borderRadius: '50%', width: '60px', height: '60px' }}
									variant='contained'
									color='success'
									onClick={() => handleTriggerJitsi('call_accept')}
								>
									<CallIcon />
								</Button>
							</motion.div>
							<motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9, y: -5 }} transition={{ type: 'spring', stiffness: 400, damping: 10 }}>
								<Button
									sx={{ borderRadius: '50%', width: '60px', height: '60px' }}
									variant='contained'
									color='error'
									onClick={() => {
										handleTriggerJitsi('call_reject')
									}}
								>
									<CallEndIcon />
								</Button>
							</motion.div>
						</Box>
					</Box>
				</Modal>
			)}
		</>
	)
}

export default CallingPrompt
