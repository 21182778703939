import { Typography } from '@mui/material'

function LeadsPage() {
	return (
		<div>
			<Typography variant='h5'>Leads</Typography>
		</div>
	)
}

export default LeadsPage
