import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	MainContain: {
		display: 'flex',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	AutocompleteField: {
		display: 'flex',
		// width: 240,
		'& .MuiAutocomplete-root': {
			width: 240,
		},
		[theme.breakpoints.down('md')]: {
			'& .MuiAutocomplete-root': {
				width: '100%',
			},
		},
	},
	finalList: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	flexDirection: 'column',
		// },
	},
	PaymentField: {
		display: 'flex',
		flexDirection: 'row',
		color: '#000000',
		alignItems: 'center',
		opacity: 0.5,
		gap: 12,
	},
	ButtonField: {
		display: 'flex',
		gap: 8,

		'& .MuiButton-root': {
			color: '#000',
			borderRadius: '2px',
			border: '1px solid gray',
			'&.active': {
				backgroundColor: '#000',
				border: '1px solid gray',
				color: '#fff',
			},
		},
	},
	mainSlot1: {
		display: 'flex',
		gap: 12,
		// justifyContent: 'center',
		alignItems: 'center',
		'& .muiltr-3sosnl-MuiInputBase-input-MuiOutlinedInput-input': {
			padding: '9.5px 10px',
			textAlign: 'center',
		},
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	sechudleMap: {
		display: 'flex',
		gap: 24,
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	secContent: {
		display: 'flex',
		flexDirection: 'column',
		width: '25%',
		[theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			width: '25%',
		},
	},
	bookingsContent: {
		paddingBlock: 10,
		fontSize: 14,
	},
	dayContain: {
		border: '1px solid #E9E8E9',
		borderRadius: 7,
		opacity: 1,
		'& .MuiListItemText-root': {
			marginTop: 12,
		},
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItem-root': {
			paddingRight: 0,
		},
	},
	slotautoMain: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		justifyContent: 'space-between',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},
	autoMainList: {
		display: 'flex',
		flexDirection: 'column',
		width: '75%',
		paddingBlock: 36,
		[theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			width: '75%',
		},
	},
	slotEveryHours: {
		display: 'flex',
		gap: 16,
		alignItems: 'end',
		'& .MuiAutocomplete-root': {
			width: 240,
		},
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	listIemMain: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	addExperiencebtn1: {
		cursor: 'pointer',
		color: '#000000',
		opacity: 0.8,
		whiteSpace: 'pre',
	},
	label: {
		width: '100%',
		textAlign: 'center',
	},
}))

export default useStyles
