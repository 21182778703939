import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	mindmaincard: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		gap: '10px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mindcard1: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${'https://ik.imagekit.io/LyfngoDev/B2B_Flash/Icons/image%20(3).png'})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	mindcard2: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${'https://ik.imagekit.io/LyfngoDev/B2B_Flash/Icons/image%20(7).png'})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	mindcard3: {
		minWidth: 120,
		minHeight: 120,
		backgroundImage: `url(${'https://ik.imagekit.io/LyfngoDev/B2B_Flash/Icons/image%20(8).png'})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	mindcard4: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${'https://ik.imagekit.io/LyfngoDev/B2B_Flash/Icons/image%20(4).png'})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	mindcard5: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${'https://ik.imagekit.io/LyfngoDev/B2B_Flash/Icons/image%20(5).png'})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	content: {
		transform: 'translate(4px, 99px)',
		color: '#fff',
	},
}))
function Mind() {
	const classes = useStyles()
	return (
		<Grid container className={classes.mindmaincard}>
			<Grid item className={classes.mindcard1}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Meditation
				</Typography>
			</Grid>
			<Grid item className={classes.mindcard2}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Gym
				</Typography>
			</Grid>
			<Grid item className={classes.mindcard3}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Home Service
				</Typography>
			</Grid>
			<Grid item className={classes.mindcard4}>
				<Typography className={classes.content} justifyContent='center' align='bottom' textAlign='center'>
					Yoga Coach
				</Typography>
			</Grid>
			<Grid item className={classes.mindcard5}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Equipments
				</Typography>
			</Grid>
		</Grid>
	)
}
export default Mind
