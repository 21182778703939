import { React, useState, useEffect } from 'react'
import useStyles from './style'
import { FormControlLabel, Checkbox, Box, Typography, Skeleton } from '@mui/material'
import _ from 'lodash'

const Children = ({ classes, handleChange, name, checked, groupCount }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
			<div className={classes.groupText}>
				<FormControlLabel label={name} control={<Checkbox checked={checked} onChange={(event) => handleChange()} name={name} />} />
				{/* <Typography>{_.toString(groupCount)} Patient(s)</Typography> */}
			</div>
		</Box>
	)
}

function Groups({ groupList, state, dispatch, loading }) {
	const classes = useStyles()
	const [groupedList, setGroupedList] = useState([])
	const [keys, setkeys] = useState([])

	useEffect(() => {
		if (!_.isEmpty(groupList)) {
			let grpBy = _.groupBy(groupList, 'type')
			setGroupedList(grpBy)
			let grpKey = _.keys(_.groupBy(groupList, 'type'))
			setkeys(grpKey)
		}
	}, [groupList])

	const handleGroupCheck = (grp, key, grpIdx) => {
		const isExist = _.findIndex(state?.groupList, (o) => o?.uuid === grp?.uuid) > -1
		if (isExist) {
			let removedData = _.filter(state?.groupList, (o) => !_.isEqual(o?.uuid, grp?.uuid))
			dispatch({
				type: 'setgroupList',
				data: removedData,
			})
		} else {
			let addedData = [...state?.groupList, { ...grp }]
			dispatch({
				type: 'setgroupList',
				data: addedData,
			})
		}
	}

	return (
		<div className={classes.groupsMain}>
			<div className={classes.listSection}>
				{!loading && !_.isEmpty(keys) ? (
					_.map(keys, (key, idx) => {
						return (
							<>
								<Typography key={idx} className={classes.filterText}>
									{key || 'Custom'}{' '}
								</Typography>
								{_.map(groupedList[key], (grp, grpIdx) => {
									const isChecked = _.findIndex(state?.groupList, (o) => o?.uuid === grp?.uuid) > -1
									return (
										<Children
											key={grp?.uuid}
											name={grp?.tentGroupName}
											groupCount={grp?.groupCount}
											checked={isChecked}
											classes={classes}
											handleChange={() => handleGroupCheck(grp, key, grpIdx)}
										/>
									)
								})}
							</>
						)
					})
				) : loading ? (
					<div style={{ paddingInline: 12 }}>
						<Skeleton variant='text' height={60} />
						<Skeleton variant='text' height={50} width={'60%'} />
						<Skeleton variant='text' height={50} width={'60%'} />
					</div>
				) : (
					<Typography align='center' className='mt-10'>
						No Groups Found
					</Typography>
				)}
			</div>
		</div>
	)
}

export default Groups
