import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
import { Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import { decryption, getResponseMessage } from 'utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import DashBoardAPI from 'services/DashBoard/DashBoardList/api'
import Analytics from './Analytics'
import TotalSales from './TotalSales'
import TopPerformers from './TopPerformers'
import RevenueTimeline from './RevenueTimeline'
import DateRangeSelector from '../DateRangeSelector'
import MemberShipDetails from './MemberShipDetails'
import PaymentWidget from './PaymentWidget'
import _ from 'lodash'
import moment from 'moment'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
import DietPlanDetails from './DietplanDetails'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#fff',
	},
	header: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		position: 'sticky',
		top: 64,
		paddingInline: 20,
		paddingBlock: 10,
		zIndex: 12,
		[theme.breakpoints.down('md')]: {
			top: 50,
		},
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		padding: 14,
	},
}))

export default function EstablishmentOwner() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { mastuuid } = state
	const { mastTentTypeName } = useSelector((state) => state?.dynamicMenu?.userData || {})
	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})
	const isCareFlow = _.isEqual(mastTentTypeName, 'Hospital') || _.isEqual(mastTentTypeName, 'Clinic') || _.isEqual(mastTentTypeName, 'Ayurveda')
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const [dashboardData, setDashBoardData] = useState('')
	const [mastDataId, setMastDataId] = useState('')
	const [saleType, setSaleType] = useState('all')
	const [medeAccessRole, setMedeAccessRole] = useState([])
	const [selectedDateRange, setSelectedDateRange] = useState(`${moment().subtract(1, 'M').format('DD-MM-YYYY')}~${moment().format('DD-MM-YYYY')}`)
	useEffect(() => {
		if (!_.isEmpty(mastDataId) && !_.isEmpty(selectedDateRange)) {
			dispatch(showLoader('Loading please wait...'))
			const onSuccess = (res) => {
				const successData = decryption(res)
				if (successData?.status === 'SUCCESS') {
					dispatch(hideLoader())
					setDashBoardData(successData?.data || {})
				} else if (successData?.status === 'FAILURE') {
					dispatch(hideLoader())
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'error',
						})
					)
				}
			}
			const onFailure = (err) => {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(err?.response?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			DashBoardAPI.getDashBoard(mastuuid, selectedDateRange, mastDataId).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, selectedDateRange, mastDataId])

	useEffect(() => {
		DashBoardAPI.getPerformersRoles(mastuuid).then(
			(res) => {
				if (res?.data?.status === true) {
					setMedeAccessRole([
						{ mastRoleDescription: 'All', mastRoleName: 'All', mastRoleUuid: 'all', mastTentTypeId: null, tentTypeName: null },
						...res?.data?.data,
					])
				}
			},
			() => setMedeAccessRole([])
		)
	}, [mastuuid])

	return (
		<Grid container xs={12} className={classes.root}>
			<Grid item xs={12} className={classes.header}>
				<div className={classes.sectionLeft}>
					<ModuleTitle>{currentModule?.mastMenuName || ''}</ModuleTitle>
				</div>

				<div className={classes.sectionRight}>
					<DateRangeSelector getDateRange={(date) => setSelectedDateRange(date)} />
				</div>
			</Grid>

			<Grid container xs={12} spacing={2} className={classes.container}>
				<Grid item xs={12} md={6}>
					<Analytics dashboardData={dashboardData} isCareFlow={isCareFlow} />
				</Grid>
				<Grid item xs={12} md={6}>
					<TotalSales dashboardData={dashboardData} isCareFlow={isCareFlow} saleType={saleType} setSaleType={setSaleType} />
				</Grid>
				<Grid item xs={12} md={6}>
					<RevenueTimeline dashboardData={dashboardData} />
				</Grid>
				<Grid item xs={12} md={6}>
					<TopPerformers dashboardData={dashboardData} isCareFlow={isCareFlow} getMedeAccessRole={medeAccessRole} setMastDataId={setMastDataId} />
				</Grid>
				<Grid item xs={12}>
					<MemberShipDetails />
				</Grid>
				{!isCareFlow && (
					<Grid item xs={12}>
						<DietPlanDetails />
					</Grid>
				)}
				<Grid item xs={12}>
					<PaymentWidget isCareFlow={isCareFlow} />
				</Grid>
			</Grid>
		</Grid>
	)
}
