import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDashBoard: (mastuuid, selectedDateRange, mastDataId) => {
		return axios.get(API_ENDPOINTS.GET_DASHBOARD_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, selectedDateRange, mastDataId } },
		})
	},
	getPerformersRoles: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_USER_ROLES, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	paymentpending: (mastUuid, period, params) => {
		return axios.get(API_ENDPOINTS.DASHBOARD_PAYMENT_PENDING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid, period } },
			params: params,
		})
	},
}
