import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	RegisterDetails: (uuid) => {
		return axios.get(API_ENDPOINTS.GET_REGISTER, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	PostRegisterDetails: (data, uuid, userType) => {
		return axios.post(`${API_ENDPOINTS.POST_REGISTER}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	updateRegistrationDetails: (data, uuid, userType) => {
		return axios.put(`${API_ENDPOINTS.REG_DETAILS_UPDATE}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	deleteEducation: (tentUserProfileRegistrationId) => {
		return axios.delete(`${API_ENDPOINTS.REG_DETAILS_DELETE}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, 'X-SECURITY': csrf(), path: { tentUserProfileRegistrationId } },
		})
	},
}
