import { styled } from '@mui/material/styles'
import { Badge } from '@mui/material'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

export const useStyles = makeStyles((theme) => ({
	headerSection: {
		background: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		paddingInline: 20,
		paddingBlock: 12,
		position: 'sticky',
		top: 64,
		zIndex: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
	},
	root: {
		display: 'flex',
		height: '100%',
		padding: theme.spacing(1),
	},
	heightStyle: {
		height: '100%',
		borderRadius: 10,
	},
	mobile_heightStyle: {
		borderRadius: 0,
	},
	searchBox: {
		width: '100%',
		marginBottom: '0px',
		background: '#FFFF',
		border: '1px solid #DBE5ED',
		position: 'relative',
		borderRadius: '30px',
		'& .MuiOutlinedInput-root': {
			height: '40px',
		},
		'& .MuiTextField-root': {
			marginTop: '14px',
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: '5px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '0px',
		},
		marginBlock: 4,
	},
	activeChat_card: {
		display: 'flex',
		alignItems: 'center',
		'&:hover, &:focus, &:active': {
			// boxShadow: '3px 3px 10px 3px #00000012',
			boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px 0px, rgba(0, 0, 0, 0.15) 0px 0px 1px 0px',
			borderRadius: '4px',
			alignItems: 'center',
			cursor: 'pointer',
		},
	},
	activeChatSkeleton: {
		width: '250px',
		height: '60px',
		margin: '10px',
		backgroundColor: '#f5f4f6',
		borderRadius: '4px',
	},
	chatLoadingSkeleton: {
		width: '100%',
		height: '60px',
		marginBlock: '10px',
		backgroundColor: '#f5f4f6',
		borderRadius: '4px',
	},
	activeChatItem: {
		boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 0px, rgba(0, 0, 0, 0.25) 0px 0px 1px 0px',
		borderRadius: '4px',
		alignItems: 'center',
		cursor: 'pointer',
	},
	borderBottom: {
		borderBottom: '1px solid #D7DBDD',
	},

	activechat_msgContent: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '160px',
		marginTop: '3px',
	},
	activechat_unreadCount: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		textAlign: 'center',
		color: '#FFFFFF',
		width: '16px',
		height: '16px',
		opacity: 1,
		borderRadius: '50px',
		marginTop: '4px',
		fontSize: '10px',
		'& .MuiBadge-badge': {
			top: '9px',
			right: '8px',
			fontSize: '9px',
		},
	},
	chatArea_CardContent: {
		// padding: '16px 8px 14px 16px !important',
		// paddingBottom: '14px !important',
		background: 'transparent linear-gradient(139deg, #FFFF 0%, #FFFFF 100%) 0% 0% no-repeat padding-box',
		opacity: 1,
		boxShadow: '0px 3px 6px #00000026',
		backdropFilter: 'blur(25px)',
		// boxShadow: '0px 3px 20px #00000012',
		// borderRadius: '13px',
		// alignItems: 'center',
		// '& .MuiCardContent-root:last-child': {
		//     paddingBottom: '14px'
		// }
	},
	screenDash: {
		maxHeight: 250,
		maxWidth: 250,
		paddingBottom: 12,
	},
	msg_area: {
		padding: '10px 15px 10px 20px',
		overflow: 'auto',
		margin: '0px 8px 8px 0px',
		[theme.breakpoints.down('md')]: {
			height: '70vh',
		},
		[theme.breakpoints.up('md')]: {
			height: '410px',
		},
		[theme.breakpoints.up('lg')]: {
			height: 459,
		},
	},
	active_scrollWrap: {
		// height: '55vh',
		// overflow: 'auto',
		// padding: '5px 0px',
		// margin: '5px',
	},

	chatArea_name: {
		color: '#2A2A2A',
		fontSize: '18px',
		font: 'normal normal medium 18px/27px Poppins',
		lineHeight: '21px',
		alignSelf: 'center',
		opacity: 0.8,
	},
	mobile_topHeader: {
		background: 'transparent linear-gradient(274deg, #FAFAFA40 0%, #FFFFFFCC 100%) 0% 0% no-repeat padding-box',
		border: '1px solid #f3f3f380',
		backdropFilter: 'blur(25px)',
		position: 'static',
		height: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '12px 10px',
		zIndex: 1,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	topHeader: {
		borderRadius: '17px 17px 0px 0px',
		background: 'transparent linear-gradient(274deg, #FAFAFA40 0%, #FFFFFFCC 100%) 0% 0% no-repeat padding-box',
		border: '1px solid #f3f3f380',
		backdropFilter: 'blur(25px)',
		position: 'static',
		height: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		boxShadow: '0 10px 10px -10px #00000020',
		padding: '10px 20px',
		zIndex: 1,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	sentimentIcon: {
		fontSize: '24px',
		color: ' #AFBBC6',
		cursor: 'pointer',
		'&:hover': {
			color: '#7047EA',
		},
	},
	attachFileIcon: {
		transform: 'rotate(45deg)',
		fontSize: '24px',
		color: '#AFBBC6',
		cursor: 'pointer',
		'&:hover': {
			color: '#7047EA',
		},
	},
	navigationOutlinedIcon: {
		transform: 'rotate(-45deg)',
		fontSize: '16px',
		color: '#FFFFFF',
		cursor: 'pointer',
		margin: '0px 0px 5px 5px',
	},
	sendIconLayer: {
		background: themesConfig.greyDark.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		width: '32px',
		height: '32px',
		justifyContent: 'center',
		borderRadius: '8px',
		opacity: 1,
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.main,
		},
	},
	disabledSendIcon: {
		background: '#ff7f90',
		cursor: 'default',
		'&:hover': {
			background: '#ff7f90',
		},
	},
	leftMessageContent: {
		background: '#F5F4F6',
		maxWidth: '500px',
		width: 'auto',
		padding: '10px',
		borderRadius: '10px 10px 10px 0px',
		color: '#2A2A2A',
		opacity: 0.8,
		fontFamily: 'poppins',
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '20px',
	},
	rightMessageContent: {
		background: '#887CE3',
		color: '#FFFFFF',
		maxWidth: '400px',
		width: 'auto',
		padding: '10px',
		borderRadius: '10px 10px 0px 10px',
		opacity: 1,
		fontFamily: 'poppins',
		fontWeight: '400',
		fontSize: '12px',
		lineHeight: '20px',
	},
	rightMessageImageContent: {
		backgroundColor: '#887CE3',
		maxWidth: '400px',
		width: 'auto',
		border: '2px solid #887CE3',
		borderRadius: '5px',
		height: '100px',
	},
	leftMessageImageContent: {
		backgroundColor: '#F0F1F4',
		maxWidth: '400px',
		width: 'auto',
		border: '2px solid #F0F1F4',
		borderRadius: '5px',
		height: '100px !important',
	},
	rightMessageDocumentContent: {
		display: 'flex',
		background: '#887CE3',
		borderRadius: '10px 10px 0px 10px',
		padding: '10px',
	},
	leftMessageDocumentContent: {
		display: 'flex',
		background: '#F0F1F4',
		borderRadius: '10px 10px 10px 0px',
		padding: '10px',
	},
	videoLinkDoc: {
		backgroundColor: 'transparent !important',
		borderBottom: 'transparent !important',
		alignSelf: 'center',
		textDecoration: 'unset !important',
	},
	textLinkView: {
		color: '#0500A2 !important',
		fontWeight: '500 !important',
		background: 'transparent !important',
		borderBottom: '1px solid #0500A2 !important',
		textDecoration: 'unset !important',
	},
	chatDetailsVideo: {
		backgroundColor: 'transparent !important',
		borderBottom: 'transparent !important',
		alignSelf: 'center',
		width: '45.33%',
		margin: '5px',
	},
	activeFileIcon: {
		fontSize: '14px',
		margin: '0px 5px',
	},
	documentText: {
		color: '#2A2A2A',
		fontSize: '12px',
		marginLeft: '5px',
		marginRight: '5px',
		alignSelf: 'center',
	},
	emojiFont: {
		fontSize: '20px !important',
		padding: '10px 5px !important',
	},
	rightMsg_wrap: {
		flexDirection: 'row-reverse',
		display: 'flex',
		justifyContent: 'end',
	},
	msgWrap: {
		display: 'flex',
		paddingTop: '0px',
	},
	sysMsg: {
		backgroundColor: '#F4F5F8',
		padding: '5px 10px',
		font: '12px/18px Poppins',
		color: '#2A2A2A',
		opacity: '0.59',
	},
	callMessageType: {
		margin: 'auto',
		display: 'flex',
		//  backgroundColor: "#dcd8f5",
		color: '#000000',
		padding: '5px 10px',
		borderRadius: '4px',
	},
	missedCallTxt: {
		font: '12px/18px Poppins',
		color: '#000000',
		opacity: '1',
		margin: '2px 0px 0px 0px',
	},
	disabledCss: {
		'& .Mui-disabled': {
			backgroundColor: '#2EB2FF !important',
			boxShadow: '0px 0px 3px #00000012',
			color: '#FFF',
		},
	},
	timeDisplayFont: {
		fontSize: '10px',
		margin: '5px 0px 10px !important',
		font: 'Sofia Pro',
		color: '#AFBBC6',
		opacity: 1,
	},
	floatRight: {
		float: 'right',
	},
	timeStorageDisplayFont: {
		fontSize: '10px',
		font: 'Sofia Pro',
		color: '#AFBBC6',
		opacity: 1,
		display: 'flex',
		justifyContent: 'space-between',
	},
	imgContent: {
		float: 'left',
		display: 'flex',
		margin: '0px 5px',
	},
	textEnd: {
		textAlign: 'end',
	},
	IconBtnSq: {
		backgroundColor: '#F0F1F4',
		height: '35px',
		padding: '5px',
		borderRadius: '12px',
		marginRight: '10px',
		cursor: 'pointer',
		'&:hover, &:focus, &:active': {
			background: '#F0F1F4',
		},
	},
	btnOutlined: {
		font: 'normal normal medium 14px/22px Sofia Pro',
		background: '#FFF',
		color: '#2EB2FF',
		cursor: 'pointer',
		border: '1px solid #2EB2FF',
		borderRadius: '0px',
		'&:hover, &:focus, &:active': {
			border: '1px solid #2EB2FF',
			color: '#2EB2FF',
		},
	},
	mobileCallIcon: {
		display: 'grid',
		placeItems: 'center',
	},
	mobile_back: {
		marginTop: 7,
		marginRight: 6,
		[theme.breakpoints.up('xs')]: {
			display: 'unset',
		},
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	patientProfileImg: {
		[theme.breakpoints.up('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	buttonBgCss: {
		background: '#2EB2FF',
		color: '#FFF',
		cursor: 'pointer',
		height: '35px',
		boxShadow: '0px 0px 3px #00000012',
		borderRadius: '18px',
		opacity: 1,
		textTransform: 'capitalize',

		'&:hover, &:focus, &:active': {
			background: '#2EB2FF',
			color: '#FFF',
		},
	},

	btnTxtCss: {
		fontSize: '12px',
		marginLeft: '10px',
	},
	font20: {
		fontSize: '20px',
	},
	font16: {
		fontSize: '16px',
	},
	footer: {
		padding: '0px 10px 10px',
	},
	accordianSummary: {
		padding: '0px 15px',
		minHeight: '30px !important',
		'&  .MuiAccordionSummary-content': {
			margin: '0px',
		},
		'& .MuiAccordionSummary-root.Mui-expanded': {
			minHeight: '30px !important',
		},
	},
	pink_iconBg: {
		backgroundColor: '#fce8f2',
		color: '#E51D84',
		padding: '10px',
	},
	skyBlue_iconBg: {
		backgroundColor: '#e5f0fc',
		color: '#036CE3',
		padding: '10px',
	},
	blue_iconBg: {
		backgroundColor: '#e9ecf8',
		color: '#2A4AB9',
		padding: '10px',
	},
	fileIcon: {
		fontSize: '20px',
	},
	chat_heading: {
		fontSize: '12px',
		color: '#535354',
		opacity: 1,
		fontFamily: 'Poppins',
	},
	chat_subHead: {
		width: '60px',
		fontSize: '12px',
		color: '#2A2A2A',
		fontFamily: 'Poppins',
		alignSelf: 'center',
		textAlign: 'end',
	},
	chat_fileCount: {
		fontSize: '10px',
		color: '#2A2A2A',
		opacity: 0.4,
		fontFamily: 'Poppins',
		float: 'left',
	},
	alignSelf: {
		alignSelf: 'center',
	},
	noRecords: {
		fontSize: '14px',
		color: '#4B5155',
		textAlign: 'center',
		padding: '10px 45px',
		opacity: 0.5,
	},
	accordianWrap: {
		paddingTop: '20px',
		cursor: 'pointer',
	},
	chatDetailsWrap: {
		height: '70vh',
		overflow: 'auto',
		padding: '15px',
	},
	flex: {
		display: 'flex',
	},
	ArrowIcon: {
		color: '#AFBBC6',
		fontSize: '15px',
		marginLeft: '10px',
	},
	HeadingTxt: {
		// font: 'normal medium 18px Poppins',
		color: '#2A2A2A',
		fontSize: '15px',
		lineHeight: '30px',
	},
	chatDtHeader: {
		borderBottom: '1px solid #f3f3f380',
		padding: '16px',
		display: 'flex',
		alignItems: 'center',
	},
	docImage: {
		borderRadius: '6px',
		height: '80px',
		maxWidth: 'calc(10.33% - 8px)',
		margin: '4px',
		cursor: 'pointer',
		[theme.breakpoints.up('xs')]: {
			width: 'unset',
		},
		[theme.breakpoints.up('lg')]: {
			width: '33.33%',
		},
	},
	imgDisplay: {
		borderRadius: '6px',
		[theme.breakpoints.up('xs')]: {
			height: 72,
			width: 72,
		},
		[theme.breakpoints.up('lg')]: {
			height: '100%',
			width: '100%',
		},
	},
	noContentWrap: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		flexDirection: 'column',
		alignItems: 'center',
	},
	noContentMsg: {
		// top: '30%',
		// position: 'absolute',
	},
	swiperContainer: {
		height: 476,
		'& .swiper-button-next': {
			right: 10,
			backgroundImage: `url(${'/images/icons/arrow_right1.svg'})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% auto',
			backgroundPosition: 'center',
			padding: 26,
			'&::after': {
				display: 'none',
			},
			[theme.breakpoints.down('sm')]: {
				top: 420,
				padding: 16,
			},
		},
		'& .swiper-button-prev': {
			left: 10,
			backgroundImage: `url(${'/images/icons/arrow_left1.svg'})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% auto',
			backgroundPosition: 'center',
			padding: 26,
			'&::after': {
				display: 'none',
			},
			[theme.breakpoints.down('sm')]: {
				top: 420,
				padding: 16,
			},
		},
	},
	slide: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
	},
	dialogLayer: {
		overflow: 'hidden',
		// top: '60px !important',
		marginTop: '10px',
		'& .MuiDialogContent-root': {
			padding: '0px',
		},
	},
	imgLayer: {
		width: '100%',
		height: '100%',
	},
	activeIcon: {
		color: '#7047EA',
	},
	// inputLayer: {
	// 	'& .emoji-mart': {
	// 		borderRadius: '10px',
	// 		boxShadow: '0px 0px 10px #00000014',
	// 		background: '#FFFFFF',
	// 		position: 'absolute',
	// 		left: 0,
	// 		right: 0,
	// 		bottom: '50px',
	// 	},
	// 	'& .emoji-mart-preview': {
	// 		display: 'none',
	// 	},
	// },
	dialogLayout: {
		'& .MuiDialog-container': {
			// height: 'unset',
		},
		'& .MuiDialog-paper': {
			marginLeft: '64px',
			borderRadius: '0px !important',
		},
	},
	date: {
		color: 'grey',
		fontSize: '12px',
		paddingBottom: '10px',
		fontWeight: '500',
	},
	linkData: {
		marginBottom: '8px',
		wordBreak: 'break-all',
		'& p:first-child': {
			background: '#3531B21A',
			borderRadius: '10px 10px 10px 0px',
			padding: '12px 12px',
			marginBottom: '5px',
			fontWeight: '500',
			width: 'fit-content',
			fontSize: '12px',
			'& a': {
				color: '#0500A2',
				background: 'unset',
				borderBottom: '1px solid #0500A2',
				'&:hover': {
					background: 'unset',
				},
			},
		},
	},
	smallText: {
		fontSize: '8px',
		fontWeight: '500',
		color: '#AFBBC6',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignLeft: {
		textAlign: 'left',
	},
	marginLeftAuto: {
		marginLeft: 'auto',
	},
	docData: {
		width: 'fit-content',
		marginBottom: '16px',
		flexWrap: 'nowrap',
		'& div:first-child': {
			padding: '10px',
		},
	},
	docCust: {
		borderRadius: '10px 10px 10px 0px',
		background: '#F5F4F6',
		color: '#535353',
		'& p': {
			paddingLeft: '10px',
			paddingRight: '40px',
			maxWidth: '135px',
		},
	},
	docTenet: {
		borderRadius: '10px 10px 0px 10px',
		background: '#887CE3',
		color: '#FFFFFF',
		'& p': {
			paddingLeft: '17px',
			paddingRight: '22px',
			maxWidth: '135px',
		},
	},
	iconBtn: {
		display: 'none',
		color: 'transparent',
		border: 'transparent',
		borderRadius: '50%',
		width: '30px',
		height: '30px',
		fontSize: '17px',
	},
	custDownload: {
		'&:hover': {
			'& button': {
				display: 'inherit',
				color: '#887CE3',
				border: '2px solid #887CE3',
			},
		},
	},
	tenetDownload: {
		'&:hover': {
			'& button': {
				display: 'inherit',
				color: '#FFFFFF',
				border: '2px solid #FFFFFF',
			},
		},
	},
	downloadItem: {
		cursor: 'pointer',
		whiteSpace: 'nowrap',
		width: 'fit-content',
	},
	downloadOfflineIcon: {
		color: '#FFF',
		fontSize: '40px',
		left: '25px',
		top: '25px',
		position: 'absolute',
	},
	chatAreaSkeletonCircle: {
		alignSelf: 'center',
		borderRadius: '50px',
		'& div': {
			width: '50px',
		},
	},
	chatAreaSkeletonRectangle: {
		margin: '15px',
		background: '#F5F4F6',
		borderRadius: '8px 8px 8px 0px',
	},
	reverseDisplay: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	aidivaBg2: {
		width: '35px',
		height: '35px',
		background: '#FFFFFF',
		borderRadius: '50%',
		[theme.breakpoints.up('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	aidivaBg1: {
		width: '35px',
		height: '35px',
		background: '#FFFFFF',
		borderRadius: '50%',
	},
	aidivaImg: {
		width: '18px',
		marginTop: '0px',
		marginLeft: '8px',
		alignSelf: 'center',
		textAlign: 'center',
		paddingTop: '8px',
	},
	whatsAppIcon: {},
	photoLayerHeader: {
		borderBottom: '1px solid grey',
		'& h2': {
			fontSize: '20px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	btnStyle: {
		background: '#7047EA',
		color: 'white',
		width: 'fit-content',
		paddingTop: '10px',
		marginBottom: '10px',
		borderRadius: '4px',
		'&:hover': {
			background: '#7047EA',
		},
	},
	menuListAction: {
		[theme.breakpoints.up('xs')]: {
			fontSize: '12px',
			paddingInline: 12,
			paddingBlock: 0,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: '14px',
			padding: '5px 12px',
		},
	},
	cancelBtn: {
		color: 'red',
		width: 'fit-content',
		paddingTop: '10px',
		marginBottom: '10px',
		border: '1px solid #f0f0f0',
		borderRadius: '4px',
		marginRight: '10px',
	},
	noContentStyle: {
		color: 'gray',
		fontStyle: 'italic',
	},
	lastMsgTime: {
		marginLeft: '8px !important',
		alignSelf: 'self-start',
		marginTop: '4px !important',
	},
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}))
