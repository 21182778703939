import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import RevenueChart from './RevenueChart'
import { currencyCode } from '../../../../../../../src/utils'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		padding: 14,
		borderRadius: 10,
		width: '100%',
		overflow: 'hidden',
	},

	totalprice: {
		display: 'flex',
		'& .MuiTypography-subtitle1': {
			maxWidth: 'fit-content',
			background: '#f0eeff',
			paddingBlock: 6,
			paddingInline: 14,
			borderRadius: 8,
			'& span': {
				fontWeight: 600,
			},
		},
	},
	sectionHead: {
		display: 'flex',
		alignItems: 'center',
		gap: 20,
	},
}))

function RevenueTimeline({ dashboardData }) {
	const classes = useStyles()
	const TotalAmount = dashboardData?.LineGraphValue?.TotalRevenue
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)

	return (
		<div className={classes.root}>
			<div className={classes.sectionHead}>
				<Typography variant='h6'>Revenue chart</Typography>
				<div className={classes.totalprice}>
					<Typography variant='subtitle1'>
						Total:<span> {currencyCode(TotalAmount, currencyType)}</span>
					</Typography>
				</div>
			</div>
			<RevenueChart dashboardData={dashboardData} />
		</div>
	)
}

export default RevenueTimeline
