import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	listItemCont: {
		display: 'flex',
		flexDirection: 'column',
		gap: 3,
		paddingBottom: 12,
	},
	practiceImage: {
		border: '0px solid',
		paddingInline: 0,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'row',
			gap: 16,
		},
	},
	flexCenter: {
		display: 'flex',
		alignItem: 'center',
		[theme.breakpoints.down('sm')]: {
			'& .MuiSvgIcon-root': {
				fontSize: '1.7rem',
			},
		},
	},
	listFont: {
		fontSize: '13px',
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	skincontent: {
		background: 'rgb(248 218 179)',
		width: '100%',
		padding: '15px',
		fontFamily: 'Poppins',
		borderRadius: 14,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	addExperiencebtn: {
		color: '#0088ff',
	},
	gridContainer: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	gridItems: {
		paddingTop: '0 !important',
		'& .MuiGrid-item': {
			[theme.breakpoints.down('sm')]: {
				paddingBlock: 4,
			},
		},
		'& .Mui-checked': {
			color: themesConfig.greyDark.palette.primary.main,
		},
	},
	eligibleColor: {
		cursor: 'pointer',
		fontWeight: 500,
		color: (props) =>
			props?.availbleuserName === true ? '#0D9E00' : props?.availbleuserName === false ? 'red' : themesConfig.lyfngo.palette.primary.main,
		'& .MuiSvgIcon-root': {
			color: (props) =>
				props?.availbleuserName === true ? '#0D9E00' : props?.availbleuserName === false ? 'red' : themesConfig.lyfngo.palette.primary.main,
		},
	},
	addmail: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			gap: 0,
			alignItems: 'flex-start',
		},
	},
	cancelBtn: {
		color: 'red',
		paddingLeft: 0,
	},
	addExperience: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			fontSize: 12,
		},
	},
	listAuto: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	textfield: {
		margin: '8px 0px',
		maxWidth: 354,
	},
	cancelExperience: {
		color: themesConfig.greyDark.palette.primary.link,
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'space-between',
	},
	genderError: {
		marginTop: 12,
		fontSize: 11,
	},
	emailandPhone: {
		paddingInlineStart: 24,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			paddingInlineStart: 16,
			paddingTop: '0px !important',
		},
	},
	languagePreffered: {
		paddingInlineStart: 24,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			paddingInlineStart: 16,
			marginTop: 10,
			paddingTop: '0px !important',
		},
	},
	headerText: {
		marginBottom: 14,
	},
	chip: {
		backgroundColor: 'antiquewhite',
		margin: '8px 4px 0 0',
	},
	months: {
		paddingTop: 12,
	},
	errorMessage: {
		marginInline: '16px',
		color: '#f94c61',
	},
	placeAutoComplete: {
		paddingBlock: 8,
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 4,
		},
		'& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderRadius: 4,
			borderColor: 'red',
		},
		'& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
			borderRadius: 4,
			borderColor: 'red',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-input': {
			borderRadius: '4px',
		},
	},
}))
export default useStyles
