import { ACTION_TYPES } from '../../../constants'

export const getAdvancedSearchOptions = (payload) => ({
	type: ACTION_TYPES.ADVANCED_SEARCH_OPTIONS,
	payload,
})

export const addAdvancedSearchValue = (payload) => ({
	type: ACTION_TYPES.ADVANCED_SEARCH_VALUE,
	payload,
})

export const getSearchOptions = (payload) => ({
	type: ACTION_TYPES.SEARCH_OPTIONS,
	payload,
})

export const addSearchInput = (payload) => ({
	type: ACTION_TYPES.SEARCH_INPUT,
	payload,
})

export const addSearchValue = (payload) => ({
	type: ACTION_TYPES.SEARCH_VALUE,
	payload,
})
