import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getTemplateList: (tentType) => {
		return axios.get(`${API_ENDPOINTS.GET_TEMPLATE_LIST}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { tentType },
			},
		})
	},
	getWhatsappTemplate: (mastuuid) => {
		return axios.get(`${API_ENDPOINTS.WHATSAPP_TEMPLATE}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { mastuuid },
			},
		})
	},
	templatStatusList: (mainId, params) => {
		return axios.get(`${API_ENDPOINTS.GET_TEMPLATES_STATUS_LIST}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { mainId },
			},
			params: params,
		})
	},
	sendSms: (body) => {
		return axios.post(`${API_ENDPOINTS.SEND_SMS}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},
	getSmsList: (tentType) => {
		return axios.get(`${API_ENDPOINTS.GET_SMS_LIST}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { tentType },
			},
		})
	},

	deleteTemplateMsg: (senderId) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_TEMPLATE_MESSAGE}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				path: { senderId },
			},
			data: {},
		})
	},

	sendWhatsAppMessage: (body) => {
		return axios.post(`${API_ENDPOINTS.COMMUNICATION_WHATSAPP_CENTER_POST}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},

	sentWhatsAppMessages: (mastuuid, params) => {
		return axios.get(`${API_ENDPOINTS.GET_WHATSAPP_LIST}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'Access-Control-Request-Headers': '*', path: { mastuuid } },
			params: params,
		})
	},

	getWalletHistory: (mastTentUuid, params) => {
		return axios.get(`${API_ENDPOINTS.WALLET_HISTORY_GET}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'Access-Control-Request-Headers': '*', path: { mastTentUuid } },
			params: params,
		})
	},
}
