import secureLocalStorage from 'react-secure-storage'

const userType = secureLocalStorage.getItem('userType')

export const API_ENDPOINTS = {
	PRE_REGISTER: '/api/preRegister',
	REGISTER_NEW: '/api/auth/signup/v2',
	DEVICE_REGISTER: 'files/users/account/saveDeviceToken/:deviceUuid',
	REGISTER: '/api/auth/signup',
	LOGIN: '/v1/signin',
	COUNTRY_CODE: `/lookup/index/mastLookupType/:dataType`,
	COUNTRY_CODE_MODE: `/files/communication/mobile_codes`,
	SUPSCRIPTION_COUNTRY: 'files/communication/subscriptioncountries/fetch',
	LOOKUP: `/lookup/:dataType`,
	COUNTRY: `/lookup/:dataType`,
	LANGUAGE: `/lookup:dataType`,
	UPDATE_DEVICES: `/api/account/v1/saveActiveDevices/:userType/:identityUuid`,
	RESETPASSWORD: '/v1/forgotPassword',
	UPDATEFORGETPASSWORD: '/v1/resetPassword',
	ERROR_MESSAGE: 'files/ms-communication/data/i18n-en.json',
	GET_CAPTCHA: '/api/auth/getCaptcha',
	FREE_IPAPI: 'https://freeipapi.com/api/json',
	//OTP
	VALIDATE_OTP: '/v1/validateOTP',
	VALIDATE_USER_OTP: '/api/auth/validateOTPUser',
	UPDATE_TENTENT_IDENTITY: '/api/tenant/updateTenantIdentity',
	GET_PRACTISE: `api/tenant/getPracticeList/:identityUuid`,
	GET_TIME_ACCESSED: 'api/tenant/saveUserLoginTime/:tentUserUuid',
	GET_CHANGE_LOGIN_DETAILS: `/api/tenant/getTenantIdentity/:identityUuid`,
	RESEND_OTP: '/v1/sendOTP',
	RESEND_OTP_USER: '/api/auth/sendOtpUser',
	LOGOUT_API: `/api/account/Logout/:deviceId/:identityUuid`,
	// GRPUPSELECTION
	GENERATE_EMAIL: '/v1/generateEmailToken',
	GENERATE_USER_EMAIL: 'api/auth/generateEmailTokenUser',
	GROUP_SELECTION: 'welcomeapi/tentGroupList/index/',
	// MODULE
	MODULE_SELECTION: 'files/ms-b2b/subscription/modules',
	// MODULE_SELECTION_NEW: 'files/ms-cms/Module/Getmodule',
	MODULE_SELECTION_NEW: 'api/getModuleSelection/:mastTentTypeUuid',
	POST_MODULES_SELECTION: 'files/users/saveModuleSelection/:mastUuid/:identityUuid/:tentUserUuid',
	//WELCOME SCREEN
	TENANTTYPE: '/welcomeapi/tentType/get/mastTentGroupId/:dataType',
	TENANTTYPEPRACTICE: '/welcomeapi/tentType/get',
	WELCOME_SCREEN: '/api/tenantMaster/saveTenantMaster/:uuid',
	WELCOME_SCREEN_IDENTITY: '/api/tenant/saveTenantUser/:identityUuid',
	// DYNAMIC_MENU: `/api/auth/getDynamicMenu/e2rgnm1i/ipsuj7o`,
	DYNAMIC_MENU: `/api/auth/getDynamicMenu/:tentMasterUuid/:userUuid`,
	PRACTICESPECIALITY: '/lookup/:dataType',
	// PHONE
	VERIFY_EMAIL: '/v1/verifyEmailToken',
	VERIFYUSEREMAIL: '/api/auth/verifyEmailTokenUser',
	CHANGE_MAIL_ID: '/api/tenant/changeEmail',
	CHANGE_USER_EMAIL: '/api/tenant/changeEmailUser',
	//PROFLE MICROSETUP
	DOMAIN_VALIDATE: 'files/profiles/microSite/domainAvailable/:mastuuid/:uuid',
	SAVE_SITEUP: 'files/profiles/microSite/save/:mastuuid/:uuid',
	UPDATE_SITEUP: 'files/profiles//microSite/update/:mastuuid/:uuid',
	GET_SITE_DATA: 'files/profiles/microSite/get/:mastuuid/:uuid',

	//B2C PROFILE API'S
	GET_PROFILE_SPECIALITY: '/b2c/getSpeciality/:mastuuid',
	GET_COMPLETE_PROFILE_TENT_USERS: '/b2c/getSpecialist/:mastuuid',
	//PROFILE SCREEN
	QUALIFICATION: `/welcomeapi/degree/index`,
	SPECIALIZATION: `/welcomeapi/specialisation/index/degId/:dataType`,
	TUBETIMINGPAYMENT: 'files/ms-calendar-appointment/tupeTimings/venue',
	GET_TIMING_DATA: '/files/ms-calendar-appointment/tupeTimings/getVenue/tentId/:tentId',
	UPDATE_TIMING_DATA: '/files/ms-calendar-appointment/tupeTimings/updateVenue',
	USER_UPDATE_TIMING_DATA: '/files/ms-calendar-appointment/tupeTimings/updateVenueUser',
	//profileupload
	PROFILE_TABLE: `files/profiles/findAllCreatedProfiles/:mastuuid`,
	PROFILE_PRACTICELIST: `api/tenant/getPracticeList/:identityUuid`,
	PROFILE_USERLIST: `files/profiles/getProfileUserList/:mastuuid`,
	//profilestatus API for In progress
	PROFILE_STATUS: `files/profiles/saveProfileStatus/:uuid/:mastuuid`,
	// PROFILE_PIC:`/uploadfile/file/application/upload/b2b/${userType}/${tentUserUuid}/INDENTITY`
	FILE_CATEGORY: 'files/file/getCategoryList',
	// FILE_POST: 'files/file/application/upload/jy6pc02p/7p2vbu8k/5ce7mb03',
	FILE_POST: `files/file/application/upload/:tentMasterUuid/:tentUserUuid/:categoryUuid`,
	MULTI_FILE_UPLOAD: 'files/file/application/uploadMultiple/:mastuuid/:uuid/:categoryUuid',
	FILE_DELETE: `files/file/application/delete`,
	FILE_CLIENT: `files/file/application/share`,
	//REGISTRATION
	REG_DETAILS_POST: `/files/profiles/saveRegistration`,
	REG_DETAILS_DELETE: `files/profiles/deleteProfileRegistration/:tentUserProfileRegistrationId`,
	REG_DETAILS_UPDATE: '/files/profiles/updateRegistratiom/:userType/:uuid',
	//PROFILESETUP_GET_ROLE
	GET_ROLE: '/api/tenantMaster/getRoles/:mastuuid/true',
	// PROFILEBASESETUP
	GET_REGISTER: 'files/profiles/getProfileRegistration/userType/TNT/uuid/:uuid',
	POST_REGISTER: `files/profiles/saveRegistration/:userType/:uuid`,
	GET_PRACTICE: `files/profiles/getProfileDetailsById/:uuid`,
	UPDATE_PRACTICE: 'files/profiles/updateProfileDetails',
	USER_NAME_AVAILABLE_CHECK: 'files/profiles/userNameAvailableCheck/:tentUserId',
	//EDUCATIONAL_DETAILS
	EDU_DETAILS: `/files/profiles/getEducation/userType/:userType/uuid/:uuid`,
	EDUCATION_DETAILS: `files/profiles/saveEducation/:userType/:uuid`,
	UPDATE_EDUCATION: `files/profiles/updateEducation/:userType/:uuid`,
	DELETE_EDUCATION: `files/profiles/deleteProfileEducation/:profileEducationUuid`,
	//ESTABLISHMENT DETAILS
	ESTABLISHMENT_CITY: `/lookup/cities`,
	GET_ESTABLISHMENT: `files/profiles/getEstablishmentDetailsById/:mastuuid`,
	GET_SPECIALITY: `lookup/SpecialityType/tentTypeId/:tentTypeId`,
	GET_FACILITIES: 'welcomeapi/facilities/index',
	POST_FACILITIES: `/files/profiles/saveFacilities/:uuid`,
	UPDATE_ESTABLISHMENT: `files/profiles/updateEstablishmentDetails/:uuid`,
	//SERVICE & EXPERIENCE
	SERVICE_EXPERIENCE_GET: `files/profiles/getServiceExperience/userType/:userType/uuid/:uuid`,
	SAVE_SERVICE_EXPERIENCE: `files/profiles/saveExperience/:userType/:uuid`,
	UPDATE_SERVICE_EXPERIENCE: `files/profiles/updateExperience/:userType/:uuid`,
	DELETE_SERVICE: `files/profiles/deleteProfileExperience/:profileExpUuid`,
	//awards&accerdation
	GET_AWARDS: 'files/profiles/getProfileAwards/userType/:userType/uuid/:uuid',
	POST_AWARD: 'files/profiles/saveAwards/:userType/:uuid',
	PUT_AWARD: `/files/profiles/updateAwards/:userType/:uuid`,
	DOWNLOAD_FILE: 'files/file/download',
	DELETE_AWARD: 'files/profiles/deleteProfileAwards/:profileAwardUuid',
	// Search Engine Optimization
	POST_SEO: 'files/profiles/saveProfileSEO',
	PUT_SEO: 'files/profiles/updateProfileSEO',
	GET_SEO: 'files/profiles/getProfileSEO/:uuid',
	//AI Site map
	AI_SITEMAP_STATUS: 'files/communication/microbot/get/parser/status',
	POST_SITE_MAP_URL: 'files/communication/microbot/trigger/parser',
	POST_AI_BOT_DETAILS: 'files/communication/aichat/detatils/update',
	GET_AI_BOT_DETAILS: 'files/communication/aichat/detatils/get/:mastUuid',
	//Menu Selection
	GET_MICROSITE_MENU: 'files/profiles/microSite/getMasterMenuData/:mastuuid',
	POST_MICROSITE_MENU: 'files/profiles/microSite/saveMicrositeMenu/:mastuuid/:headerId',
	GET_ASSIGNED_MICROSITE_MENU: 'files/profiles/microSite/getMicrositeMenu/:mastuuid/:profileHeaderUuid',
	// Group selection
	GET_PROFILE_STATUS: '/files/profiles/getProfileStatus/:uuid/:mastuuid/:isNotOwner',
	UPDATE_PROFILE_STATUS: '/files/profiles/updateProfileStatus',
	ADD_PROFILE: '/files/profiles/saveProfileStatus/:uuid/:mastuuid',
	GET_PROFILE_PERCENTAGE: '/files/profiles/get/percentage/:userType/:uuid',
	UPDATE_ACTIVE_STATUS: '/files/profiles/updateActiveStatus',
	// Calendar Api
	// CALENDER CARE
	GET_DOCTORLIST_BASED_ON_SPECIALITY_AND_APPT_MODE: 'files/ms-communication/tentUserDetails/appointmentUsers',
	GET_SPECIALITY_lIST_BASED_ON_TENTUSER: 'files/ms-communication/tentUserDetails/getSpecialityByTentUser',
	APPOINTMENT_ADD: `files/ms-calendar-appointment/appointment/appointmentReminders/:dataType`,

	// Feedback GET API
	FEEDBACK_LIST: `files/ms-b2b/faqAnswerMaster/index/`,
	DELETE_FEEDBACK: `files/node/feedback/tnt/delete`,
	POST_FEEDBACK: `files/node/feedback/tnt/publish/feedback`,
	GET_FFEDBACK_DETAILS: `files/node/feedback/tnt/getfeedback/:mastUuid`,
	GET_CLIENT_FEEDBACK: 'files/node/feedback/tnt/feed/:mastuuid/:custuuid/:tentuseruuid',
	// Quick Sale API
	GET_ALL_SALE_LIST: 'files/charting/QuickSales/getDropdown',
	GET_DRAFT_AND_BILLING: 'files/charting/QuickSales/quickSaleResponse',
	GENERATE_SALE_BILL: 'files/charting/QuickSales/save/billing/generateBill',
	SAVE_SALE_DRAFT: 'files/charting/QuickSales/save/billing/draft',
	DELETE_SALE_DRAFT: 'files/charting/QuickSales/deleteDraft/:billingOrderUuid',
	GET_SALE_ORDER_NUMBER: 'files/charting/QuickSales/quicksale/getInvoiceid/:mastTentUuid',
	GET_INVOICE_RECEIPT: 'files/charting/BillingOrder/showBillingInvoice/Quicksales',

	//HELP CENTER
	GET_TOUR_VIDEO: `files/communication/helpcenter/:mastuuid`,
	// new get Api
	APPOINTEMENT_GET: 'files/ms-calendar-appointment/appointment/calendarAppointments/:dataType',
	APPOINTMENT_POST: 'files/ms-calendar-appointment/appointment/add',
	APPOINTMENT_SERIAL_POST: 'files/ms-calendar-appointment/sessionAppointment/add',
	POST_BLOCK_APPOINTMENT: 'files/ms-calendar-appointment/calendar/add',
	BLOCK_DELETE: 'files/ms-calendar-appointment/calendar/delete',
	BLOCK_UPDATE: 'files/ms-calendar-appointment/calendar/update',
	APPOINTMENT_CANCEL: 'files/ms-calendar-appointment/appointment/cancel',
	APPOINTMENT_SERIAL_CANCEL: 'files/ms-calendar-appointment/sessionAppointment/cancel',
	APPOINTMENT_UPDATE: 'files/ms-calendar-appointment/appointment/reschedule',
	APPOINTMENT_CHECKIN_ENGAGE_CHECKOUT: 'files/ms-calendar-appointment/appointment/visitStatus',
	REMINDER_POST: 'files/ms-calendar-appointment/reminder/add',
	REMINDER_UPDATE: 'files/ms-calendar-appointment/reminder/update',
	REMINDER_DELETE: 'files/ms-calendar-appointment/reminder/delete',
	DOCTOR_LIST: `api/tenantMaster/getTenantUserByTenantMasterUuid/:tentMasterUuid`,
	DOCTOR_LIST_ESTABLISHMENT: 'welcomeapi/tentUserDetails/index',
	DELETE_APPOINTMENT_CANCEL: 'files/ms-calendar-appointment/appointment/delete',
	DELETE_SERIAL_APOINTMENMT: 'files/ms-calendar-appointment/sessionAppointment/delete',
	GET_TENANT_APPOINTMENT_MODES: 'files/ms-calendar-appointment/appointment/appointmentModes',
	SPA_SPECIALITY_DROP: 'welcomeapi/tentSpeciality/index',
	AUTO_SEARCH_PATIENT_LIST_POST: 'api/customer/saveCustomerMaster/:uuid',
	APPOIMTMENT_PRICE_AUTO_FIELD: 'files/ms-calendar-appointment/appointment/pricingBySpeciality',
	VIEW_CATEGORY: `files/ms-calendar-appointment/category/index/tentId/:tentMasterUuid`,
	DELETE_CATEGORY: 'files/ms-calendar-appointment/category/delete',
	POST_CATEGORY: 'files/ms-calendar-appointment/category/add',
	CSRF: 'files/users/auth/csrf',
	CUST_LIST_APPOINTMENT: 'api/customer/getCustomerList/:tentMasterUuid',
	CARD_VIEW: 'files/ms-calendar-appointment/appointment/appointmentStatus/:dataType',
	//discount type
	DISCOUNT_TYPE_SYMBOLS: 'files/charting/BillingOrder/get/tentCurrency/:mastTentUuid',

	//custom print
	CUSTOM_PRINT: 'files/ms-communication/send/subSend',
	//Add Record Vital
	VITAL_SIGNS_POST: 'files/charting/api/vitalSign',
	VITAL_SIGNS_GET: 'files/charting/api/vitalSign/:appointmentUuid',
	NON_CARE_VITAL_SIGNS_GET: 'files/charting/api/vitalSign/getByVitalSign/:vitalSignUuid',
	VITAL_SIGNS_DELETE: 'files/charting/api/vitalSign/:vitalSignUuid',
	VITAL_SIGNS_PUT: 'files/charting/api/vitalSign/:vitalSignUuid',

	//Add Record Treatment Plans
	TREATMENT_SIGNS_GET: 'files/charting/api/treatmentPlan/:appointmentUuid',
	TREATMENT_GET: 'files/settings/api/treatmentCatalog/:mastuuid',
	TREATMENT_SIGNS_POST: 'files/charting/api/treatmentPlan/:appointmentUuid',
	TREATMENT_SIGNS_PUT: 'files/charting/api/treatmentPlan/:appointmentUuid',
	TREATMENT_SIGNS_DELETE: 'files/charting/api/treatmentPlan/:treatmentPlanUuid',
	TREATMENT_ALL_DELETE: 'files/charting/api/treatmentPlan/deleteAll',

	//Add Record Clinical Notes
	CLINICAL_NOTES_GET: 'files/charting/api/clinicalNotes/:appointmentUuid',
	CLINICAL_NOTES_POST: 'files/charting/api/clinicalNotes/:appointmentUuid',
	CLINICAL_NOTES_DELETE: 'files/charting/api/clinicalNotes/:notesCatalogUuid',
	CLINICAL_NOTES_PUT: 'files/charting/api/clinicalNotes/:appointmentUuid',

	//Add Record  Prescriptions
	PRESCRIPTION_GET: 'files/charting/api/ePrescribe/:appointmentUuid',
	PRESCRIPTION_POST: 'files/charting/api/ePrescribe/:appointmentUuid',
	PRESCRIPTION_PUT: 'files/charting/api/ePrescribe/:appointmentUuid',
	PRESCRIPTION_DELETE: 'files/charting/api/ePrescribe/:ePrescribeUuid',
	DRUGNAME: 'files/settings/get/drug-catalog/for/tentMaster/:mastuuid/false',
	PRESCRIPTION_ALL_DELETE: 'files/charting/api/ePrescribe/deleteAll',
	PRESCRIPTION_DELETE_ALL: `files/settings/chartTemp/deleteAllPrescription`,
	PRESCRIPTION_GET_SEARCH_DATA: `files/settings/drugMapLookup/get/16ogosoa`,
	E_PRESCRIPTION_LAST_DETAILS: `files/charting/api/appointment/loadPreviousPrescriptions`,
	DRUG_PRESCRIBE: `files/settings/get/drug-catalog/for/tentMaster/:mastuuid/false`,
	//addRecord AyurVedic
	AYURVEDIC_DROPDOWN: `files/charting/api/ePrescribe/ayurvedic/getDropdown`,
	AYURVEDIC_DROPDOWNN: `files/charting/api/ePrescribe/ayurvedic/getTemplateAndInventoryDropdown/:mastTentUuid/:appointmentUuid`,
	E_PRESCRIPTION_DROPDOWN: `files/charting/api/ePrescribe/getTemplateInventory/dropdown/for/:mastTentUuid/:tentUserUuid`,
	AYURVEDIC_ADD_INSTRUCTION: `files/settings/mastInstructions/add`,

	AYURVEDIC_POST: `files/charting/api/ePrescribe/ayurvedic/savePrescription/:appointmentUuid`,
	AYURVEDIC_GET: `files/charting/api/ePrescribe/ayurvedic/getPrescriptions/:appointmentUuid`,
	AYURVEDIC_INSTRUCTION: `files/charting/api/ePrescribe/ayurvedic/getLifeStyleDropdown/:mastuuid`,
	AYURVEDIC_DRUG_INSTRUCTION: `files/charting/api/ePrescribe/ayurvedic/getInstructionsList/:mastuuid`,
	AYURVEDIC_PUT: `files/charting/api/ePrescribe/ayurvedic/updatePrescription/:appointmentUuid`,
	AYURVEDIC_DELETE: `files/charting/api/ePrescribe/ayurvedic/deletePrescriptions`,
	AYURVEDIC_LAST_DETAILS: `files/charting/api/appointment/getLastPrescription`,
	//ayurvedic row delte
	AYURVEDIC_DELETE_TEMPLATE_DATA: 'files/settings/chartTemp/deleteAllPrescription',

	//addrecord laborder
	LABORDER_GET: 'files/settings/labCatalog/labCatalog/findAll/:mastuuid',
	LABORDERS_GET: 'files/charting/api/labTest/:appointmentUuid',
	LABORDERS_POST: 'files/charting/api/labTest/:appointmentUuid',
	LABORDERS_PUT: 'files/charting/api/labTest/:appointmentUuid',
	LABORDERS_DELETE: 'files/charting/api/labTest/:labTestUuid',
	FOLLOWUP: 'files/charting/api/labTest/followUp/:appointmentUuid',
	LAB_ORDERS_ALL_DELETE: 'files/charting/api/labTest/deleteAll',
	LABORDERS_DELETE_ALL: `files/settings/chartTemp/deleteAllLaborder`,

	//addrecord procedure
	PROEDURE_SIGNS_GET: 'files/charting/api/completeProcedure/:appointmentUuid',
	PROCEDURE_GET: 'files/settings/api/treatmentCatalog/:mastuuid',
	PROCEDURE_SIGNS_PUT: 'files/charting/api/ePrescribe/:appointmentUuid',
	PROCEDURE_SIGNS_POST: 'files/charting/api/completeProcedure/:appointmentUuid',
	PROCEDURE_SIGNS_DELETE: 'files/charting/api/completeProcedure/:compProcedureUuid',
	PROCEDURE_ALL_DELETE: 'files/charting/api/completeProcedure/deleteAll',

	//addrecord fileng
	FILE_GET: 'files/charting/api/files/:appointmentUuid',
	DELETE_ALL_FILES: 'files/charting/api/files/deleteAll',
	GET_TIME_SLOT_CALENDAR: 'files/ms-calendar-appointment/appointment/timeSlotNonCare',
	GET_SPA_SPECIALITY_LIST: 'files/ms-calendar-appointment/appointment/nonCareSpecialityList',
	GET_SPA_DURATION: 'files/ms-calendar-appointment/appointment/SpaAppointmenetDuration',
	GET_PRICE_LIST_CALENDAR: 'files/ms-calendar-appointment/appointment/pricingBySpeciality',
	//Files conseent Form
	GET_CONSENT_FOR_APPUUID: 'files/settings/consentform/getAllConsentForm/:tentUuid/:appointmentUuid',
	GET_CONSENT_FOR_NONCARE: `files/settings/consentform/getAllConsentFormNonCare/:tentUuid`,
	GET_CONSENT_FORM_FOR_CUSTOMER: `files/settings/consentform/findConsentFormByCustomer/:custUuid`,
	SAVE_CONSENT_FORM: 'files/settings/consentform/saveConsentForm',
	SAVE_CONSENT_FORM_NON_CARE: 'files/settings/consentform/saveConsentFormNonClient',
	DELETE_CONSENTFORM_NON_CARE: `files/settings/consentform/:custConsentFormUuid`,
	GET_LIST_CONSENT: 'files/settings/consentform/findConsentForm/:appointmentUuid',

	SEND_CONSENT_FORM: 'files/settings/consentform/sendConsentForm',

	// Calender Appoinmtent modes for all groups
	APPOINTEMENT_MODES: 'files/ms-calendar-appointment/appointment/appointmentModes',
	TENT_USER__APPOINTMENT_DETAILS: 'files/communication/dashboardTopPerformer/:mastuuid',

	//Patient
	BLOOD_GROUP: 'welcomeapi/lookup/:dataType',
	TUT_DETAILS: 'welcomeapi/lookup/:dataType',
	SAVE_PATIENT_DETAILS: 'api/customer/saveCustomerMaster/:uuid',
	UPDATE_PATIENT_DETAILS: 'api/customer/updateCustomerMaster',
	NON_CARE_SPECIALITY: 'files/ms-communication/tentSpeciality/index',
	PATIENT_ID_DETAILS: 'api/customer/getPatientid/:uuid',
	PATIENT_CLIENT_STATUS_DETAILS: 'api/customer/getAllCustomerStatus/:custUuid',
	GET_PATIENT_VIST_COUNT: '/api/customer/getCustomerMasterVistCount/:custUuid',
	GROUP_GET_DETAILS: 'files/settings/findAll/tentGroup/:tentMastUuid',
	UPLOAD_API_PATIENT: 'files/file/application/upload/:tentMastUuid/:tentUserUuid/:categoryUuid',
	SAVE_RELATIONSHIP_DETAILS: 'api/customer/saveCustomerRelationship/:custUuid',
	CUSTOMER_LIST: 'api/customer/getCustomerList/:uuid',
	CUSTOMER_STATUS_LIST: 'api/customer/withAppointmentStatus/getCustomerList/:uuid',
	SAVE_GROUP_DETAILS: 'files/settings/tentGroups/add/customer/:custUuid',
	CUSTOMER_MEDICAL_HISTORY: 'api/med/medicalhist/TNT/:uuid',
	CUST_MEDICAL_HISTORY: 'api/med/medicalhist/cust/:custUuid',
	SAVE_MEDICAL_HISTORY: 'api/med/saveMedicalhist/:uuid/:custUuid',
	SAVE_CLIENT_TAGS: 'api/customer/saveCustomerTags/:uuid/:custUuid',
	CUSTOMER_TAGS_DATA: 'api/customer/getMasterTags/:uuid',
	SAVE_CUSTOMER_BY_MOBILE_NUMBER: 'api/customer/getCustomersByMobNo',
	SPECIFIC_CUSTOMER_DETAILS: 'api/customer/getCustomerMaster/:custUuid',
	GET_TENTUSER_BY_ROLE: 'api/tenantMaster/getTentUserByRole/:mastuuid/all',
	GET_TEMPLATE_LIST: '/welcomeapi/template/index/mastTemplateType/:tentType/mastCommMode/sms',
	WHATSAPP_TEMPLATE: 'files/communication/center/whatsapp/template/:mastuuid',
	GET_TEMPLATES_STATUS_LIST: 'files/communication/center/whatsapp/single/group/:mainId',
	GET_SMS_LIST: '/welcomeapi/smsLog/index/mastTentUuid/:tentType/mode/sms',
	DELETE_TEMPLATE_MESSAGE: 'files/communication/center/whatsapp/:senderId',
	SEND_SMS: '/welcomeapi/template/send',
	TODAYS_APPOINTMENT: 'files/ms-calendar-appointment/appointment/:dataType',
	AVAILABLE_TIME_SLOTS: 'files/ms-calendar-appointment/appointment/:dataType',
	OCCUPATION_API: 'api/customer/getOccupation/:mastuuid',
	GET_COMP_PROCEDURE_DATA: 'files/settings/api/treatmentCatalog/findCompletedProc/:appointmentUuid/:mastUuid',
	GET_TREATMENT_PLAN_DATA: 'files/settings/api/treatmentCatalog/findTreatmentPlan/:appointmentUuid/:mastUuid',
	VIEW_RECORD_HISTORY: 'files/charting/api/appointment/getReviewHistory',
	VIEW_RECORD_HISTORY_NEW: 'files/charting/api/appointment/getCustomerAppDetails/:mastuuid/:custUuid',
	VIEW_FILE_RECORD_HISTORY: 'files/file/documentshare/getDocuments/tentMaster/:tentMastUuid/customer/:custUuid',
	CUSTOMER_LIST_VIEW: 'api/customer/FilterOnCustomerList/:mastuuid',
	CUSTOMER_WHATAPP_TEMPLATE_LIST: 'api/customer/getCustomersForWaTemplate/:mastuuid',
	GET_INSURANCE_NAME: 'files/communication/lookuptype',
	GET_CUSTOMER_BILLING_HISTORY: 'files/charting/BillingOrder/consolidatedViewBilling/:mastTentUuid/:custUuid',
	//Share Records -> Patient Module
	SHARE_RECORDS: 'files/ms-communication/Invoice/chartingShareOff',
	SHARE_BILL: 'files/ms-communication/Invoice/billingShareOff',
	GET_SPECIALITY_BILLING_DATA: 'files/charting/api/appointment/getFacilityByAppId/:appointmentUuid',
	//Add Appointment
	GET_TENT_USER_DETAILS: 'welcomeapi/tentUserDetails/index',

	//Billing
	GET_BILLING_DROPDOWN_DETAILS: `files/settings/api/treatmentCatalog/findBilling/:appointmentUuid/:mastTentUuid`,
	GET_BILLING_DROPDOWN_DETAILS_PHARMACIST: `files/products/findAllDrugList/inventory-items/for/tentMaster/:mastTentUuid`,
	GET_BILLING_PROCEDURE_DETAILS: 'files/settings/api/treatmentCatalog/findProcedureOnNonCare/:mastTentUuid/:custUuid',
	//prepopulate data
	GET_PRESCRIPTION_BILL_DATA: 'files/charting/api/ePrescribe/getPrepopulateNotification/forPrescription/:appointmentUuid/:dataType',
	// View Draft
	GET_VIEW_BILLING_DETAILS: `files/charting/BillingOrder/viewDraftBill/onProcedure/:mastTentUuid/:custUuid`,
	SAVE_DRAFT_BILLING: 'files/charting/BillingOrder/saveDraft/onProcedure/:appointmentUuid',
	DELETE_VIEW_DRAFT_BILLING: `files/charting/BillingOrder/deleteDraft/onProcedure/:billingOrderUuid`,
	UPDATE_DRAGT_BILLING: 'files/charting/BillingOrder/saveDraft/onProcedure/:billingOrderUuid',
	//Settings
	// Account settings
	// ACTIVEDEVICES
	GET_ACTIVEDEVICES: `api/account/getActiveDevicesList/:identityUuid`,
	GET_PRACTICE_SPECIALITY: `api/tenantMaster/getSpeciality`,
	POST_SPECIALITY: `api/tenantMaster/saveCustomSpeciality/:mastuuid/:tentTypeUuid`,
	ADD_PROCEDURE: 'files/settings/api/treatmentCatalog/:mastuuid',
	EDIT_PROCEDURE: 'files/settings/api/treatmentCatalog/:editiUuid',
	DELETE_PROCEDURE: 'files/settings/api/treatmentCatalog/:editiUuid',
	GET_DRUGS_TYPE: `files/settings/findAll/drugType/:mastuuid`,
	ADD_DRUGS_TYPE: `files/settings/save/drugType`,
	GET_DRUGS_UNIT: `files/settings/findAll/drugUnit/:mastuuid`,
	GET_CATE_NAME: `files/settings/get/itemCategory/dropdown/:mastuuid`,
	ADD_DRUGS_UNIT: `files/settings/save/drugUnit`,
	ADD_DRUG: 'files/settings/save/drug-catalog/for/tentMaster/:mastuuid',
	GET_DRUG: 'files/settings/get/drug-catalog/for/tentMaster/:mastuuid/true',
	DELETE_DRUG: 'files/settings/delete/drug-catalog/:editiUuid',
	GET_PROCEDURE: 'files/settings/api/treatmentCatalog/:mastuuid',
	DRUG_INSTRUCTION_ADD: 'files/settings/mastInstructions/add',
	DRUG_INSTRUCTION_UPDATE: 'files/settings/mastInstructions/update',
	DRUG_INSTRUCTION_DELETE: 'files/settings/mastInstructions/delete/:listUuid',
	DRUG_INSTRUCTION_GET: 'files/settings/mastInstructions/get/:mastuuid',
	//Diet Lifestyle
	DIET_LIFESTYLE_ADD: `files/charting/api/ePrescribe/ayurvedic/saveLifeStyleDropdown`,
	DIET_LIFESTYLE_UPATE: `files/charting/api/ePrescribe/ayurvedic/updateLifeStyleDropdown`,
	DIET_LIFESTYLE_GET: `files/charting/api/ePrescribe/ayurvedic/getLifeStyleDropdown/:mastuuid`,
	DIET_LIFESTYLE_DELETE: `files/charting/api/ePrescribe/ayurvedic/deleteLifeStyleDropdown/:listUuid`,
	ADD_BILLING: 'files/settings/taxManagement/:mastuuid',
	GET_BILLING: 'files/settings/taxManagementByTentId/:mastuuid',
	GET_ACTIVE_BILLING: 'files/settings/taxManagementByTentIdForSettings/:mastuuid',
	EDIT_BILLING: 'files/settings/taxManagement/:taxUuid',
	ADD_PAYMENT: 'files/settings/paymentMode/tentMaster/:mastuuid/tentUser/:uuid',
	GET_PAYMENT: 'files/settings/paymentMode/tentMaster/:mastuuid',
	EDIT_PAYMENT: 'files/settings/paymentMode/:editiUuid',
	DELETE_PAYMENT: 'files/settings/paymentMode/:editiUuid',
	PAYMENT_MODE: 'files/ms-communication/lookup/index/mastLookupType/PYT',
	ADD_PATIENTGROUP: 'files/settings/save/tentGroup/for/tentMaster/:mastuuid',
	UPDATE_PATIENTGROUP: 'files/settings/update/tentGroup/:editiUuid',
	GET_PATIENTGROUP: 'files/settings/findAll/tentGroup/:mastuuid',
	DELETE_PATIENTGROUP: 'files/settings/delete/tentGroup/:editiUuid',
	MEMBER_GROUP: 'files/settings/find/patientsByTentGroup/:mastuuid',
	AUTO_ID_GET: 'files/settings/autoId/:mastuuid',
	AUTO_ID_POST: 'files/settings/autoId/:mastuuid',
	DATA_SECURITY_GET: 'files/settings/dataSec/:mastuuid',
	DATA_SECURITY_PATCH: 'files/settings/dataSec/:mastuuid',
	COMMUNICATION_GET: 'files/ms-communication/commNotifications/index/tentId/:mastuuid/notificationFor/:notificationStatus',
	COMMUNICATION_PUT_TENT: 'files/ms-communication/commNotifications/updateTent',
	COMMUNICATION_PUT_CUST: 'files/ms-communication/commNotifications/update',
	GET_COMMISSION_TYPE: 'files/communication/commission/management/get/:mastTentUuid',
	GET_COMMUNICATION_LIST: 'files/communication/commnotifications/:mastuuid/:userType',
	GET_STATUS_UPDATE: 'files/communication/commnotifications/comstatus',
	POST_COMMUNICATION_DATA: 'files/communication/commnotifications/add/:mastuuid',
	DELETE_COMMUNICATION_DATA: 'files/communication/commnotifications/delete',
	SINGLE_COMMUNICATION_GET: 'files/communication/commnotifications/:mastuuid/:userType/:settingUuid',
	TEMPLATE_COMMUNICATION_GET: 'files/communication/commnotifications/templateid',
	COMMUNICATION_TIME_LOOKUP: 'files/communication/lookuptype',
	COMMUNICATION_CATEGORY_LIST: 'files/communication/commnotifications/category',
	//--------------------- SETTING CALENDAR ------------------------//

	SPECIALITY_BASED_FILTER: 'files/ms-calendar-appointment/tupeTimings/getSpeciality/tentId/:mastuuid/isSpa/:boolean',
	GET_TENTID_BASED_TIMING: 'files/ms-calendar-appointment/tupeTimings/getTimings/tentId/:mastuuid/isSpa/:boolean',
	GET_INDIVIDUAL_BASED_TIMING: 'files/ms-calendar-appointment/tupeTimings/getTimings/tentUserId/:uuid/isSpa/:boolean',
	ADD_CALENDAR_TIMING: 'files/ms-calendar-appointment/tupeTimings/venue',
	UPDATE_CALENDAR_TIMING: 'files/ms-calendar-appointment/tupeTimings/updateVenue',
	DELETE_CALENDAR_TIMING: 'files/ms-calendar-appointment/tupeTimings/delete',
	// Communication sms
	ADD_CLINICAL: 'files/settings/api/mastClinicalNotes/:mastuuid',
	GET_ALL_CATEGORY: 'files/settings/api/mastClinicalNotes/getAllCategory',
	GET_CLINICAL: 'files/settings/api/mastClinicalNotes/:mastuuid',
	EDIT_CLINICAL: 'files/settings/api/mastClinicalNotes/:editiUuid',
	DELETE_CLINICAL: 'files/settings/api/mastClinicalNotes/:editiUuid',
	SEARCH_CATEGORY: 'files/settings/api/mastClinicalNotes/searchByCategory/:categoryUuid/:mastuuid',
	LAST_CLINICAL: 'files/charting/api/clinicalNotes/getLastClinicalNotes',

	//--------------------- WHATSAPP BOT TEMPLATE CUSTOMIZE ------------------------//
	CUSTOMIZE_LIST: 'files/communication/fetchcutomizedtemplete/:mastuuid/:language',
	CUSTOMIZE_ADD: 'files/communication/addcutomizedtemplete',
	CUSTOMIZE_UPDATE: 'files/communication/updatecutomizedtemplete',
	CUSTOMIZE_DELETE: 'files/communication/deletecutomizedtemplete/:editiUuid',
	CUSTOMIZE_LANGUAGE: 'api/tenantMaster/getLanguageList',
	//--------------------- EMAIL TEMPLATE CUSTOMIZE ------------------------//
	EMAIL_FOOTER_GET: 'files/charting/termsandcondition/get/:mastuuid',
	EMAIL_FOOTER_UPDATE: 'files/charting/termsandcondition/update',

	// Delete
	DELETE_API: `api/auth/deleteUser`,
	DELETE_OWNER: 'api/auth/deleteOwner',
	//SetPIN
	POST_SET_PIN: 'api/auth/v1/B2B/setPin',
	POST_VALIDATE_PIN: 'api/auth/v1/B2B/validatePin',
	POST_CHANGE_PIN: 'api/auth/v1/B2B/changePin',
	// Notification Settings
	NOTIFICATIONS_GET: `api/account/getNotification/:userType/:uuid`,
	UPDATE_NOTIFICATIONS: `api/account/saveNotification/:userType/:uuid`,
	// Confirm password
	// RESET_PASSWORD: `api/auth/resetPassword`,
	CHANGE_PASSWORD: `api/tenant/changePassword`,
	//getMedeAccessRole
	GET_MEDE_ACCESSROLE: 'api/tenantMaster/getMedeAccessRole/:mastuuid',
	//charting details for a single appointment
	GET_CHARTING_RECORDS_FOR_APPOINTMENT: 'files/charting/api/appointment/getChartingDetails/:appointmentUuid',
	//ACCOUNT SETTINGS-CART
	GET_CARTLIST: `files/ms-b2b/tentCarts/cartList/tentUserId/:tentUuid`,
	GET_ORDER_SUMMARY: `files/ms-b2b/tentOrders/orderSummary/tentUserId/:tentUuid/tentOrderHeaderId/:orderUuid`,
	DELETE_CARTLIST: `files/ms-b2b/tentCarts/delete`,
	GET_GSTN: `api/tenantMaster/getTenantMaster/:mastuuid`,
	// Billing address
	ADD_ADDRESS: `files/ms-b2b/billingAddress/Add`,
	GET_ADDRESS: `files/ms-b2b/billingAddress/index/tentId/:mastuuid`,
	///profile/deleteSubscriptionAddress
	DELETE_ADDRESS: `files/profiles/deleteSubscriptionAddress/:mastAddressUuid`,
	UPDATE_ADDRESS: 'files/ms-b2b/billingAddress/update',
	UPDATE_TENANT: 'files/profiles/saveOrUpdateSubscriptionAddress',
	//account
	GET_ACCOUNT: '/api/tenant/getTenant/:uuid',
	UPDATE_ACCOUNTDETAIL: '/api/tenant/updateTenant',
	UPDATE_ADDRESSDETAIL: '/files/profiles/saveOrUpdateAddress',
	GET_ADDRESS_STORE: `/files/profiles/getAddress/userType/${userType}/uuid/:uuid`,
	//subscription -store
	GET_PRACTICELIST: `api/tenant/getPracticeList/:identityUuid`,
	GET_CURRENT_PALNLIST: `files/ms-b2b/subscription/plan/tentId/:mastTentUuid`,
	GET_NEW_PALN: `files/ms-b2b/subscription/subPlan`,
	UPDATE_CARTDETAIL: `files/ms-b2b/tentCarts/add`,
	GET_INVOICE_SUMMARY: `files/ms-b2b/tentOrders/invoiceSummary`,
	GET_INVOICE_DETAILS: `files/ms-b2b/tentOrders/orderDetails/tentUserId/:uuid/tentOrdersId/:orderUuid`,
	//Communication
	CREATEPLAN_POST: '/welcomeapi/plan/add',
	PLANLIST_GET: '/welcomeapi/plan/index/tentId/:mastuuid',
	PLANDETAILS_GET: '/welcomeapi/planMapping/index',
	PLANNAME_UPDATE: '/welcomeapi/plan/update',
	PLANNAME_DUPLICATE: '/welcomeapi/planMapping/planDuplication',
	PLANNAME_DELETE: '/welcomeapi/plan/delete',
	PLAN_STOP: '/welcomeapi/planUserMapping/stopPlan',
	PLAN_REMINDER_POST: '/welcomeapi/planReminder/add',
	ASSIGN_REMINDER_POST: '/welcomeapi/planReminder/saveNew',
	PLAN_REMINDER_GET: `welcomeapi/planReminder/index/planReminderUuid/:planReminderUuid`,
	PLAN_REMINDER_DELETE: '/welcomeapi/planReminder/delete',
	PLAN_REMINDER_PUT: '/welcomeapi/planReminder/update',
	PLAN_ARTICLE_POST: '/welcomeapi/article/add/',
	ASSIGN_ARTICLE_POST: '/welcomeapi/article/saveNew',
	PLAN_ARTICLE_GET: `welcomeapi/article/index/articleUuid/:articleUuid`,
	PLAN_ARTICLE_PUT: '/welcomeapi/article/update',
	PLAN_ARTILCE_DELETE: '/welcomeapi/article/delete',

	COMMUNICATION_WHATSAPP_CENTER_POST: '/files/communication/center/whatsapp',
	GET_WHATSAPP_LIST: `files/communication/center/whatsapp/:mastuuid`,

	//billing
	GET_INVOICE: '/files/charting/Invoice/getInvoiceid/:mastuuid',
	GET_RECEIPT: '/files/charting/Payment/getRecieptid/:mastuuid',
	DRUG_CATLOG_GET: `files/settings/api/treatmentCatalog/:mastuuid`,
	PAYMENT_MODES: `files/settings/paymentMode/tentMaster/:mastuuid`,

	GET_CUSTOMER_DETAILS: 'api/customer/getCustomerMaster/:custUuid',
	DELETE_CUSTOMER_DETAILS: 'api/auth/deleteUser',
	TAX_VALUE_GET: 'files/settings/taxManagementByTentId/:mastuuid',
	SAVE_BILLING_ORDER_ITEM: 'files/charting/BillingOrder/add/:appointmentUuid',
	SAVE_INVOICE_ORDER_ITEM: 'files/charting/Invoice/add/:billingOrderUuid/:tentUserUuid',
	SAVE_PAYMENT_ORDER_ITEM: 'files/Payment/add/:appintmentUuid',
	SAVE_REFUND_ORDER_ITEM: 'files/charting/Invoice/refundInvoice/:tentUuid',
	GET_BILLING_HISTORY: 'files/charting/BillingOrderItem/getBillingHistory/:custUuid',
	GET_SHOW_BILL: 'files/charting/BillingOrder/getConsolidatedBillForApp/:appointmentUuid?module=emr',
	GET_SHOW_PARMA_BILL: `files/charting/BillingOrder/getConsolidatedBillForApp/:appointmentUuid?module=pharma`,
	SAVE_BILLING: 'files/charting/BillingOrder/saveBilling/:appointmentUuid',
	SAVE_BILLING_PHARMACIST: 'files/charting/BillingOrder/saveInventoryBilling/:appointmentUuid',
	UPDATE_BILLING: 'files/charting/Invoice/pendingInvoice/:tentUuid',
	CANCEL_PAYMENT: 'files/charting/Payment/paymentCancellation/:uuid',
	DELETE_MEDICAL_HISTORY: 'api/med/medicalhist/deleteCust/:medhistUuid',
	CANCEL_INVOICE: 'files/charting/Invoice/update',
	UPI_PAYMENT_CONFIRMATION: 'files/charting/BillingOrder/confirmPayment',
	//Save all billing
	SAVE_ALL_BILLING: 'files/charting/BillingOrder/saveAllBilling/:appointmentUuid',
	//Google contacts inport API
	GOOGLE_IMPORT_CONATACT: 'files/communication/auth',
	GOOGLE_MEET_LINK_APPOINTMENT: 'files/communication/meeting/googlemeet/:mastUuid',
	ZOOM_MEET_LINK_APPOINTMENT: 'files/communication/zoom/meeting/create',
	// client Treatment
	POST_TREATMENT_PLAN: 'files/communication/treatmentplan/add',
	UPDATE_TREATMENT_PLAN: `files/communication/treatplan/update/:treatmentUuid`,
	GET_TREATMENT_DROPDOWN: `files/communication/treatplan/getdropdown/:custUuid`,
	GET_TREATMENT_PLAN_DETAILS: `files/communication/treatplan/get/:treatmentUuid`,
	DELETE_TREATMENT_PLAN: `files/communication/treatplan/delete/:treatmentUuid`,
	GET_COMPLETE_STATUS: `files/communication/treatplan/completed`,
	// patient schedule communication
	GET_PLAN_NAME: 'welcomeapi/plan/planWeek/tentId/:mastUuid',
	GET_PLAN_DETAILS: 'welcomeapi/planMapping/index',
	SAVE_COMMUNICATION: 'welcomeapi/planUserMapping/add',
	GET_SCHEDULED_COMMUNICATION: 'welcomeapi/planUserMapping/get',
	STOP_SCHEDULED_COMMUNICATION: 'welcomeapi/planUserMapping/delete',
	// GET_TIME_DURATION: 'files/ms-calendar-appointment/appointment/timeSlot',
	GET_TIME_DURATION: 'files/ms-calendar-appointment/appointment/careTimeSlot',

	//setting-charting
	GET_CHARTINGDETAIL: `files/settings/chartTemp/tenant/:mastuuid`,
	DELETE_CHARTING: 'files/settings/chartTemp/:chartingTemplateUuid',
	ADD_TEMPLATE: 'files/settings/chartTemp/add',
	GET_DIAGNOSES: `files/settings/api/mastClinicalNotes/get/:mastuuid`,
	ADD_DIAGNOSES: 'files/settings/api/mastClinicalNotes/add',
	PUT_TEMPLATE: 'files/settings/chartTemp/:chartingTemplateUuid',
	GET_TEMPLATE: 'files/settings/chartTemp/:chartingTemplateUuid',
	// Public holiday

	GET_PUBLIC_HOLIDAY_LIST: 'files/node/holiday/tnt/list/:mastuuid',
	POST_PUBLIC_HOLIDAY: 'files/node/holiday/tnt/create/:mastuuid',
	UPDATE_PUBLIC_HOLIDAY: 'files/node/holiday/tnt/update/:holidayuuid',
	DELETE_PUBLIC_HOLIDAY: 'files/node/holiday/tnt/delete',
	// Recipe
	GET_RECIPE_LIST: 'files/events/recipe/getRecipeList/:mastuuid',
	POST_RECIPE_DETAILS: 'files/events/recipe/save/:mastuuid',
	UPDATE_RECIPE_DETAILS: 'files/events/recipe/update/:mastuuid/:recipeUuid',
	DELETE_RECIPE_DETAILS: 'files/events/recipe/delete/:mastuuid/:recipeUuid',
	GET_RECIPE_CATEGORY: 'lookup/getLookup?mastLookupType=RCY',
	GET_RECIPE_TYPE: 'lookup/getLookup?mastLookupType=RTE',
	GET_RECIPE_SUBTYPE: 'lookup/getLookup?mastLookupType=RST',
	// CallLogs
	GET_CALL_LOGS_LIST: 'files/node/tent/calls/log',
	//payment
	CHECKOUT_ORDER_SUMMARY: `files/payment/order/createOrders`,
	DELETE_ORDERID: `files/payment/order/updateOrderStatus`,
	UPDATE_PROCEED_PAYMENT: `files/payment/payment/pay`,
	UPDATE_PAYMENT_RESPONSE: `files/payment/payment/paymentResponse`,
	GET_SUCCESS_PAYMENT: `files/ms-b2b/tentOrders/paymentDetail/tentUserId/:uuid/tentOrderHeaderId/:orderUuid`,
	// GET_FAILURE_PAYMENT: `files/ms-b2b/tentOrders/paymentDetail/tentUserId/:uuid/tentOrderHeaderId/:orderUuid/paymentStatus/failure`,

	// DashBoard
	GET_DASHBOARD_LIST: `files/reports/ownerDashboard/:mastuuid/:selectedDateRange/:mastDataId`,
	GET_INDIVIDUAL_DASHBOARD_LIST: `files/reports/professionalsDashboard/:mastuuid/:calenderDate/:selectedDateRange/:upcommingAppointments`,
	GET_USER_ROLES: `files/communication/users/tenantMaster/getRoles/:mastuuid/false`,
	DASHBOARD_PAYMENT_PENDING: `files/reports/getPaymentPending/:mastUuid/:period`,

	// Reports
	GET_REPORT_FILTER_OPTIONS: `files/reports/getReportFilters/:mastuuid`,
	GET_REPORTS_DATA: `files/reports/for/:reportType`,
	SEND_MAIL_EXCEL_REPORTS: 'files/ms-communication/Invoice/excellConversion',

	//Setting Global search
	SETTINGS_GLOBAL_SEARCH: 'files/settings/searchTab/getModule/:uuid',
	//Setting: Practice details
	GET_PRACTICE_DETAILS: 'api/tenantMaster/getTenantMaster/:mastuuid',
	PRACTICE_DETAILS_POST: `/api/tenantMaster/saveTenantMaster`,
	PRACTICE_DETAILS_LIST: `/api/tenant/getPracticeList/:identityUuid`,
	PRACTICE_DETAILS_PUT: `/api/tenantMaster/updateTenantMaster`,
	PRACTICE_DETAILS_DELETE: `/api/tenantMaster/deleteExpiredTenantMaster/:editUuid`,

	//Setting: User Management
	GET_MEDEACCESS_ROLE: 'api/tenantMaster/getMedeAccessRole/:mastuuid',
	GET_ACCESSROLE_TABLE: 'api/tenantMaster/getTentUserByRole/:mastuuid/:role',
	ADD_NEW_USER: '/api/tenant/saveTenant/:mastuuid/:identityUuid',
	GET_INDIVIDUAL_USER: 'api/tenant/getTenant/:mastuuid',
	GET_SPECIALITY_TENTID: 'files/ms-communication/tentSpeciality/index/tentId/:mastuuid',
	USER_DETAILS_PUT: 'api/tenant/updateTenant',

	//Settings: Whatsapp Template
	ADD_LIBRARY: `files/communication/metatemplate/add`,
	UPDATE_LIBRARY: 'files/communication/metatemplate/edit',
	GET_LIBRARY_LIST: `files/communication/metatemplate//library`,
	GET_ACTIVE_LIST: `files/communication/metatemplate/active`,
	GET_DELETED_TEMPLATE_LIST: `files/communication/metatemplate/deletelist`,
	ACTIVE_DELETE: `files/communication/metatemplate/delete`,
	TEMPLATE_OPTION_LOOKUP: 'files/communication/lookuptype?mastLookupType=MTP',
	TEMPLATE_LANGUAGE: 'files/communication/lookuptype?mastLookupType=MWL',
	// Terms and conditions page
	GET_TERMS_AND_CONDITION: '/files/ms-cms/termsAndConditions/index',
	//onlineConsult
	UPDATE_CONSULT_MEETING: `files/consult/firebase/consultMeeting/update/:meetingUuid`,
	GENERATE_TOKEN_ONCUSTOMER: `files/consult/Jitsi/customerJWT/:custUuid`,
	GET_TENANT_DETAILS: `files/consult/jlink/tenant/read/:tentUserUuid`,
	CONSULT_CHATMESSAGE_SAVE: `files/consult/firebase/consultChatMessage/save`,
	CONSULT_DETAILS: `files/consult/jlink/customer/read/:custUuid`,
	LIST_OF_TENANT_APPOINTMENT: `files/consult/jlink/appointment/tenantApp`,
	GET_MESSAGE_TWOSIDE: `files/consult/firebase/consultChatMessage/getMsg2side`,
	ONLINECONSULT_MEETING_SAVE: `files/consult/onlineConsult/Meeting/save`,
	GET_CHAT_IMAGE_DETAILS: `files/consult/onlineConsult/Message/readTentImg/:appointmentUuid`,
	ONLINECONSULT_READTENTMSG: `files/consult/onlineConsult/Message/readTentMsg/:appointmentUuid`,
	ONLINECONSULT_READ_TENANT_DOCUMENT: `files/consult/onlineConsult/Message/readTentDoc/:appointmentUuid`,
	ONLINECONSULT_READ_TENANT_VIDEO: `files/consult/onlineConsult/Message/readTentVideo/:appointmentUuid`,
	ONLINECONSULT_MESSAGE_SAVE_TEXT: `files/consult/onlineConsult/Message/saveText`,
	ONLINECONSULT_MESSAGE_SAVE_EMOJI: `files/consult/onlineConsult/Message/saveEmoji`,
	ONLINECONSULT_MESSAGE_SAVE_FILE: `files/consult/onlineConsult/Message/saveFile`,
	ONLINECONSULT_MESSAGE_SAVEDECLINECALL: `files/consult/onlineConsult/Message/saveDeclineCall`,
	ONLINECONSULT_UPLOAD_TENANTSIDE: 'files/file/application/uploadMultiple/:tentMastUuid/:tentUserUuid/:consultUuid',
	ONLINECONSULT_MEETING_CALL_LEAVE: `files/consult/onlineConsult/Meeting/callleave`,
	ONLINECONSULT_MEETING_TENANT_CALLACCEPT: `files/consult/onlineConsult/Meeting/tenantcallaccept`,
	ONLINECONSULT_DELETE_SPECIFICMEETING: `files/consult/onlineConsult/Meeting/deleteSpecificMeeting`,
	ONLINECONSULT_MEETING_CALLDECLINE: `files/consult/onlineConsult/Meeting/calldecline`,
	ONLINECONSULT_MEETING_DISCONNECT: `files/consult/onlineConsult/Meeting/calldisconnect`,
	ONLINECONSULT_READ_TENANT_MEDIADETAILS: `files/consult/onlineConsult/Message/readTentChat/:appointmentUuid`,
	ONLINECONSULT_READ_TENTANT_LINK: `files/consult/onlineConsult/Message/readTentLink/:appointmentUuid`,
	CONSULT_CALLNOTIFICATION_FINDCALL: `files/consult/firebase/callNotification/read/:callNotificationUuid`,
	CONSULT_UPDATE_CALLDECLINE_NOTIFICATION: `files/consult/firebase/callNotification/updateDecline/:callNotificationUuid/:tentUserUuid`,
	CONSULT_CALLNOTIFICATION_DELETE: `files/consult/firebase/callNotification/delete/:callNotificationUuid`,
	CONSULT_CALLNOTIFICATION_SAVE: `files/consult/firebase/consultation/save/:custUuid/:tentUserUuid/:callNotificationUuid`,
	CONSULT_CALLNOTIFICATION_UPDATE: `files/consult/firebase/callNotification/update/:callNotificationUuid`,
	ONLINECONSULT_CLEARCHAT_HISTORY_TENANT: `files/consult/onlineConsult/Message/cleartenant/:appointmentUuid`,
	ONLINE_CONSULT_SEEN_STATUS: `files/consult/onlineConsult/Message/updateSeenStatus`,

	// 	NON CARE CALENDER APPOINTMENT POP UP SPECIALITY
	APPOINTMENT_SPECIALITY: `welcomeapi/tentSpeciality/timingsSpeciality`,
	CALENDER_VIEW_MODE_CHANGE: `api/tenant/updateTenant`,
	CALENDER_VIEW_MODE: `api/tenant/getTenant/:uuid`,

	// BackOffice
	// Inventory
	GET_NEWITEMSLIST: 'files/products/itemsList',
	GET_CATEGORYITEMSLIST: 'files/products/categoryList/:mastuuid',
	GET_MANUFACTURERLIST: `files/products/manufacturer/:mastuuid`,
	GET_STOCKINGLIST: `files/products/stockingUnitList/:mastuuid`,
	GET_INVENTORYDATALIST: `files/products/findAll/inventory-items/for/tentMaster/:mastuuid`,
	POST_NEWITEMLIST: `files/products/save/inventory-item/for/tentMaster/:mastuuid`,
	DELETE_INVENTORYLIST: `files/products/delete/inventory-item/:itemUuid`,
	UPDATE_INVENTORYLIST: `files/products/update/inventory-item/:itemUuid`,
	POST_NEWDRUG_LIST: `files/settings/save/drug-catalog/for/tentMaster/:mastuuid`,
	GET_DRUG_DETAILS: 'files/products/find/tenantDrugTypes/:mastuuid',
	// Add Stock

	GET_DRUG_ITEM: 'files/products/findAll/inventory-items/for/tentMaster/:mastTentUuid',
	GET_SUPPLIERS_NAME: 'files/products/suppliersList/:mastTentUuid',
	SEND_ADD_STOCK: 'files/products/add/stocks/to/inventory',
	GET_LOCATION_LIST: `files/products/stockLocationList/:mastTentUuid`,
	// Consume Stock
	SEND_CONSUME_STOCK: 'files/products/consumeStock',
	// Activities
	// GET_ACTIVITIES: `files/search/audit/activity`,
	GET_ACTIVITIES: 'files/node/activity/tnt/list',
	GET_USER_BY_ROLES: `api/tenantMaster/getTentUserByRole/:mastUuid/all`,

	// Expenses
	GET_EXPENSES_LIST: `files/settings/expenses/:mastuuid`,
	POST_ALL_EXPENSES_LIST: `files/settings/expenses/findAll`,
	DELETE_EXPENSES_LIST: `files/settings/expenses/:itemUuid`,
	POST_EXPENSES_LIST: `files/settings/expenses/:mastuuid`,
	UPDATE_EXPENSES_LIST: `files/settings/expenses/:itemUuid`,
	GET_PAYMENT_MODE: `files/settings/paymentMode/tentMaster/:mastuuid/tentUser/:uuid`,
	GET_ALL_EXPENSES_MODE: `files/settings/expenses/getExpenses/:mastuuid`,
	POST_EXPENSES_MODE: `files/settings/expenses/addNewExpense`,
	GET_VENDOR_NAME: `files/settings/vendor/get/:venderName`,
	POST_VENDOR_NAME: `files/settings/vendor/add`,
	// XERO_BACKUP: 'https://uat.rigelsoft.com/services/sync/xero_backup',
	// XERO_BACKUP: 'http://localhost:8461/services/sync/xero_backup',
	XERO_BACKUP: 'http://flash.lyfngo.com/services/sync/xero_backup',

	// Global Search
	GLOBAL_SEARCH: `api/customer/getCustomerList/:mastUuid`,
	ADVANCED_GLOBAL_SEARCH_OPTIONS: `lookup/getLookup`,
	ADVANCED_GLOBAL_SEARCH: `files/reports/advancedSearchCustomer`,

	//Download Invoice
	DOWNLOAD_INVOICE: `welcomeapi/invoice/subscriptionInvoiceDownloader`,
	DOWNLOAD_tentOrderHeaderUuid: `welcomeapi/invoice/invoiceDownloader`,
	// Client
	USER_LIST: `welcomeapi/tentUserDetails/index`,

	// Client
	CLIENT_REPORT_DETAILS: `files/reports/getClientDetails/:mastuuid/:custuuid`,
	CLIENT_LIST: `files/reports/clientList/:mastuuid`,
	REVIEW_LIST: `files/ms-b2b/feedbackRating/review/tentId/:tentId/custId/:custId`,
	GET_CLIENT_FILES: `files/file/application/find/customer/:custUuid/category/:categoryUuid/tent/:mastTentUuid`,
	CLIENT_FILE_DELETE: `files/file/application/delete/:uuid`,
	CLIENT_FILE_DOWNLOAD: 'files/file/download/',
	SAVEONLINEPAYMENT: `files/payment/paymentLink/createPaymentLink`,
	EDIT_CLIENT_FILES: `files/file/application/update/notes`,
	// Files
	SaveFiles: `files/charting/api/clientfeed/:appoinmentUuid`,
	getFilesInfo: `files/charting/api/clientfeed/:appoinmentUuid`,
	downloadFile: `files/file/download/:fileUuid`,
	deleteFile: `files/charting/api/files/:fileUuid`,
	// Appointments
	getAppointments: `files/ms-calendar-appointment/appointment/appointmentDetails`,
	//SecretKey
	SECRET_KEY: 'DGq1tmmtiXXlCtBAFpFuGZbdWolHcna7kbRJTbTIw4Y=',
	//Help center
	GET_HELP_CENTER: `files/ms-cms/mastFaqHelp/index/isAppB2b/true`,
	// Consent Form
	POST_CONSENT_FORM: `files/ms-b2b/tentConsentForm/add`,
	GET_CONSENT_FORM: `files/ms-b2b/tentConsentForm/GetConsentForm`,
	UPDATE_CONSENT_FORM: `files/ms-b2b/tentConsentForm/update`,
	DELETE_CONSENT_FORM: `files/ms-b2b/tentConsentForm/delete`,
	// ImportExport
	IMPORT_EXPORT: `files/settings/importexport/importMatch/:tentId/tentUser/:tentUuid`,
	IMPORT_EXPORT_FOR_INVENTORY: `files/communication/inventory/import/mathcfeild/:tentId/:tentUuid/:fileUuid`,
	GET_IMPORT_EXPORT: `files/settings/importexport/getImportHistory/:tentId`,
	GET_FIELD_DATA: `files/settings/importexport/getfields/:tentId`,
	GET_MATCH_FIELD: `files/settings/importexport/matchField`,
	GET_MATCH_FIELD_FOR_INVENTORY: `files/communication/inventory/import/mathcfeild`,
	DELETE_IMPORT_EXPORT: `files/settings/importexport/deleteImportHistory/:importExportUuid`,
	// Currency
	GET_CURRENCY_DETAILS: `api/lookup/getCountryCurrencyInfo`,
	SAVE_CURRENCY_DETAILS: `api/tenantMaster/saveCurrency/:mastuuid`,

	// Setting Diet Plan
	GET_DIET_PLAN_DETAILS: `files/settings/dietPlan/:mastuuid`,
	GET_SINGLE_PLAN_NAME_DETAILS: `files/settings/dietPlan/getDietPlanName/:planUuid`,
	GET_ENTIRE_PLAN_NAME_DETAILS: `files/settings/dietPlan/getDietPlan/:mastuuid`,
	GET_CLIENT_DIET_PLAN_DETAILS: `files/settings/dietPlan/getDietPlanAndInstructionByTenant/:mastuuid`,
	DELETE_PLAN_NAME_DETAILS_FOR_SETTINGS: `/files/settings/dietPlan/deletePlanInMaster/:dietPlanUuid`,

	UPDATE_DIET_PLAN_DETAILS: `files/settings/dietPlan/:dietPlanUuid`,
	POST_DIET_PLAN_DETAILS: `files/settings/dietPlan/:mastuuid`,
	POST_PLAN_NAME_DETAILS_FOR_SETTINGS: `files/settings/dietPlan/createPlanName/:mastuuid`,
	DELETE_DIET_PLAN_DETAILS: `files/settings/dietPlan/:dietPlanUuid`,
	DELETE_PLAN_NAME_DETAILS: `files/settings/dietPlan/deletePlan/:dietPlanUuid`,
	DROPDOWN_DIET_PLAN_DETAILS: `files/settings/dietPlan/clientDietPlan/getClientDietPlanNamesForDropDown/:mastuuid/:custuuid`,
	DIET_PLAN_UNIT_LOOKUP: 'files/settings/dietPlan/unit/lookup',
	IMAGE_SETTING_DIET_PLAN: `files/settings/dietPlan/getSpecificDietPlanByTent/dietPlanId/:dietPlanUuid`,
	GET_PRINT_DIETPLAN: `files/settings/dietPlan/printClientDietPlan/forPlanDetails/:dietPlanUuid`,
	// Client Diet Plan
	POSTGET_CLIENT_DIETPLAN_DETAILS: 'files/settings/dietPlan/clientDietplan/filterClientDietPlan',
	POST_DIETPLAN_CLIENT_DETAILS: `files/settings/dietPlan/clientDietplan/:mastuuid/:custuuid`,
	UPDATE_CLIENT_DIETPLAN_DETAILS: `files/settings/dietPlan/clientDietplan/:clientdietPlanUuid`,
	DELETE_CLIENT_DIETPLAN_DETAILS: `files/settings/dietPlan/clientDietplan/:clientdietPlanUuid`,
	IMAGE_CLIENT_DIET_PLAN: `files/settings/dietPlan/clientDietplan/filterSpecificClientDietPlan/clientDietPlanId/:clientDietPlanUuid`,
	GET_PLAN_NAME_DETAILS: `files/settings/dietPlan/retriveClientDietPlan/forPlanDetails/:dietPlanUuid`,
	ACTIVATE_DIET_PLAN: `files/settings/dietPlan/clientDietplan/activateOrDeactivate`,
	GET_CLIENT_ASSIGNED_FOOD: 'files/settings/dietPlan/getClientFood/:planUuid/:foodUuid',
	UPDATE_CLIENT_FOOD: '/files/settings/dietPlan/updateClientFood/:planUuid/:foodUuid',
	ADD_CLIENT_FOOD: '/files/settings/dietPlan/saveClientFood/:tentUuid/:custUuid/:planUuid',
	GET_FOOD_DETAILS: '/files/communication/getDietFoodBySonnet',
	//Settings Workout plan
	POST_WORKOUT_PLAN: `files/settings/workout/:mastTentUuid`,
	GET_WORKOUT_PLAN: `files/settings/workout/findAll/:mastTentUuid`,
	DELETE_WORKOUT_PLAN: `files/settings/workout/:workoutPlanUuid`,
	UPDATE_WORKOUT_PLAN: `files/settings/workout/:workoutPlanUuid`,
	POST_CLIENT_WORKOUT_PLAN: `files/settings/workout/clientExercise/:mastTentUuid/:custUuid`,
	GET_CLIENT_WORKOUT_PLAN: `files/settings/workout/clientExercise/findAll/:mastTentUuid/:custUuid`,
	DELETE_CLIENT_WORKOUT_PLAN: `files/settings/workout/clientExercise/deleteSpecificClientExercise`,
	UPDATE_CLIENT_WORKOUT_PLAN: `files/settings/workout/clientExercise/updateSpecificClientExercise/:mastTentUuid/:custUuid`,
	ACTIVATE_WORKPLAN_PLAN: `files/settings/workout/clientExercise/activateOrDeactivate`,
	GET_EDIT_MEMBERSHIP_DETAILS: `files/settings/membership/b2bClient/get/:tentMembershipUuid/:clientMembershipUuid`,
	POSTGET_CLIENT_WORKOUTPLAN_DETAILS: `/files/settings/workout/clientExercise/filterClientWorkoutPlan`,
	UPDATE_CLIENT_MEMBERSHIP: `files/settings/membership/b2bClient/update/:clientMembershipUuid`,
	WORKOUT_PLAN_PRINT: 'files/settings/workout/printWorkoutPlan/forPlanDetails/:workoutUuid',
	// Client Membership
	GET_MEMBERSHIP_DETAILS_OF_CLIENT: `files/settings/membership/clientMembership/get`,
	GET_MEMBERSHIP_DROPDOWNLIST: `files/settings/membership/b2bClient/getDropDown/:mastTentUuid`,
	GET_EDIT_MEMBERSHIP_DETAILS: `files/settings/membership/b2bClient/get/:tentMembershipUuid/:clientMembershipUuid`,
	POST_CLIENT_ADD_MEMBERSHIP: `files/settings/membership/b2bClient/save`,
	UPDATE_CLIENT_MEMBERSHIP: `files/settings/membership/b2bClient/update/:clientMembershipUuid`,

	// Client Membership Billing

	GET_DETAILS_FOR_BILLING: `files/settings/membership/b2bClient/generateBill/:clientMembershipUuid`,
	GET_DETAILS_FOR_RENEW_BILLING: `files/settings/membership/b2bClient/renewed/:clientMembershipUuid`,
	PUT_MEMBERSHIP_TOGGLE: `files/settings/membership/b2bClient/clientMembershipStatus/:clientMembershipUuid`,
	POST_MEMBERSHIP_BILLING: 'files/charting/BillingOrder/saveMembershipBilling/:mastTentUuid/:custUuid/:tentUserUuid',
	GETTING_BILLING_MEMBERSHIP_DROPDOWN_BY_GET: `files/settings/membership/b2bClient/getMembershipDropDown/for/billing`,
	GET_DETAILS_FOR_BILLING_DETAILS: `files/charting/BillingOrder/showBillingHistory/Membership/:mastTentUuid/:custUuid`,
	GET_MEMBERSHIP_BILLING_DETAILS: `files/charting/BillingOrder/showBillingHistory/Membership/:mastTentUuid/:custUuid`,

	// Settings Membership
	GET_MEMBERSHIP_LIST: `files/settings/membership/get/tentMaster/:mastTentUuid`,
	POST_ADD_MEMBERSHIP: `files/settings/membership/save/tentMaster/:mastTentUuid`,
	DELETE_MEMBERSHIP: `files/settings/membership/deleteSpecificMembership/:tentMembershipUuid`,
	PUT_UPDATE_MEMBERSHIP: `files/settings/membership/updateMembership/:tentMembershipUuid`,

	//  Settings Discount Management
	POST_DISCOUNT_TYPE: 'files/communication/lookuptype',
	// Wallet
	//communication/wallet/history/mup1pnar?start_date=2023-10-01&end_date=2023-10-04&search=All&limit=1&offset=10
	WALLET_HISTORY_GET: 'files/communication/wallet/history/:mastTentUuid',

	// settings Commission Management
	POST_COMMISSION_TYPE: `files/communication/commission/management/add`,
	PUT_COMMISSION_TYPE: `files/communication/commission/management/update/:editUuid`,
	DELETE_COMMISSION_TYPE: `files/communication/commission/management/delete/:editUuid`,

	// Dashboard Membership
	GET_DASHBOARD_MEMBERSIHP_LIST: `files/communication/dashboardMembership/:mastTentUuid`,
	GET_DASHBOARD_DIETPLAN_LIST: `files/settings/dietPlan/dashboardDietPlan/:mastTentUuid`,
	// Products
	GET_CATEGORIES: 'files/products/get/categoryListByTentUuid/:mastuuid',
	GET_PRODUCT_LIST: 'files/products/get/productListByTentUuid/:mastuuid',
	ADD_PRODUCT: 'files/products/save/product/for/tentMaster/:mastuuid',
	UPDATE_PRODUCT: 'files/products/update/tentProduct/:itemUuid',
	DELETE_PRODUCT: 'files/products/delete/productsByUuid/:itemUuid',

	// My Profile:
	COUNTRY_LIST: `files/ms-cms/mastCountries/countriesList`,
	COUNTRY_FETCH: `files/communication/subscriptioncountries/fetch`,

	//Settings Integration
	DISCONNECT_INTEGRATION: `/files/settings/tentConfig/updateTenantIntegration`,
	VERIFY_INTEGRATION_SMTP: `files/communication/smtp/testConnection`,
	VERIFY_INTEGRATION_WHATSAPP: `files/communication/whatsapp/valdation`,
	VERIFY_INTEGRATION_HITPAY: `files/communication/hitpay/valdation`,
	// PAYTM RZRPY STRIPE
	VERIFY_INTEGRATION_PYRYSP: `files/payment/validate/validateAPIKeys`,
	SAVE_INTEGRATION_INFO: `files/settings/tentConfig/v1/saveIntegrationInfo`,
	LIST_INTEGRATION: `files/settings/tentConfig/getTenantIntegration/:mastuuid`,
	GET_RAZORPAY_LIST: `files/settings/tentConfig/getIntegrationInfo/:mastuuid/:category`,
	GET_GOOGLE_SHEET_LIST: 'files/communication/google/sheets/getlist/:mastuuid',
	GET_GOOGLESHEET_LIST: 'files/communication/googlesheet/getworkorsheet/list/:sheetId/:mastuuid',
	VERIFY_GOOGLE_SHEET: 'files/communication/google/sheets/save/sheets/details',
	VERIFY_JOTFORM: 'files/communication/jotform',
	GET_JOTFORM_LIST: 'files/communication/jotform/list/:mastuuid',
	PREPOPULATE_JOTFORM_LIST: 'files/communication/jotform/form/get/:mastuuid',
	POST_JOTFORM_DATA: 'files/communication/jotform/form/insert/:mastuuid',
	GET_FACEBOOK_SSO: `files/communication/fboauth/login/:mastuuid`,
	FACEBOOK_SSO_INTEGRATION: 'files/communication/ssotoken/:mastuuid',
	GOOGLE_MEET_URL: 'files/communication/google/oauth/link',
	// Pharmacist alert Notification in Header App bar
	PHARMACIST_NOTIFICATION_GET: 'files/charting/api/ePrescribe/getNotification/for/prescription/:mastTentUuid/:tentUserUuid',
	// Docotr alert Notification in Header App bar
	DOCTOR_NOTIFICATION_GET: 'files/charting/api/appointment/getUpcomingAppointment/for/Notification/:tentUserUuid',
	//Pharmacist prescription  Page Data's
	PHARMA_PRESCRIPTION_DATA: 'files/charting/api/ePrescribe/getPharmatictNotification/forPrescription/:mastTentUuid/:tentUserUuid',
	PHARMA_PRESCRIPTION_PUT: 'files/charting/api/ePrescribe/UpdateDispatchNotification/:appointmentUuid',
	// hospital alert Notification in Header App bar

	// Firebase Get API
	PAYMENT_CONFIRMATION_NOTIFICATION: 'files/notifications/notification/getPushNotification',
	// Notification SEEN
	NOTIFICATION_SEEN: `files/notifications/notification/updateNotificationStatus`,

	//ONLINE ADD BILLING PAYMENT
	ONLINE_ADD_BILLING: 'files/payment/paymentLink/payLink',
	UPI_BILLING: 'files/communication/whatsappPaylink',

	// Events Management
	// Events
	GET_ALL_EVENTS: 'files/events/eventDetails/V1/byTenant/:mastuuid',
	CHANGE_EVENT_STATUS: 'files/events/eventDetails/eventStatus/:eventUuid',
	DELETE_EVENT: 'files/events/eventDetails/cancelEvent/:eventUuid',
	GET_TICKETS_COUNT: 'files/events/singleEvent/getOverview/:mastuuid/:eventUuid',
	GET_TOTAL_SALES: 'files/events/singleEvent/getAttendies/:mastuuid/:eventUuid',

	// EventDetails
	CHECK_EVENTS_DOMAIN_AVAILABLE: 'files/events/eventSetup/domainAvailable/:mastuuid',
	GET_EVENTS_SETUP_DETAILS: 'files/events/eventSetup/geteventsetup',
	POST_EVENTS_SETUP_DETAILS: 'files/events/eventSetup/saveEvent',
	GET_EVENT_PREVIEW_DETAILS: 'files/events/eventDetails/V1/get',
	GET_EVENT_CATEGORY_LOOKUP: 'files/communication/lookuptype',

	// AttendeedForm
	GET_ATTENDEES_FORM_DETAILS: 'files/events/form/getForm',
	POST_ATTENDEES_FORM_DETAILS: 'files/events/form/saveForm/:mastuuid/:eventUuid',
	UPDATE_ATTENDEES_FORM_DETAILS: 'files/events/form/updateForm/:mastuuid/:eventUuid',

	// SlotDetails
	GET_SLOT_DETAILS: 'files/events/slotDetails/getSlot',
	POST_SLOT_DETAILS: 'files/events/slotDetails/createSlot',
	//events
	POST_ADD_EVENT: `files/settings/Events/save/events/for/tentMaster/:mastuuid`,
	UPDATE_ADD_EVENT: `files/settings/Events/update/events/:eventUuid`,
	GET_EVENT_ALL: `files/settings/Events/get/events/for/tentMaster/:mastuuid`,
	DELETE_EVENTS: `files/settings/Events/delete/events/:eventUuid`,
	GET_EVENT_EDIT: `files/settings/Events/findEventByUuid/:eventUuid`,
	//websocket
	GET_CHAT_HISTORY: `files/communication/consult/getchats/:cHead?skip=0&limit=300`,
	GET_MEDIA_DETAILS: `files/communication/consult/getmedia/:chead`,
	GET_CHAT_LIST: `files/communication/consult/getcutomer/:mastuuid/:uuid/:status`,
	GET_TEXT_SUGGEST: 'files/models/consult/predict/reply',
	UPDATE_PICKUP_CALL: `files/communication/consult/updatepickup/:cHead/:tentId`,
	GET_ROLE_LIST: `files/communication/consult/isconsult/:mastuuid`,
	GET_MESSAGE_SEARCH: `files/communication/consult/message/search/:chead`,
	CLOSE_CHAT_MESSAGE: `files/communication/consult/message/cleat_chat/:chead`,
	CLOSE_CHAT_AREA: `files/communication/consult/closechat`,

	// Leads
	GET_LEADS: 'files/communication/prospects/fetch/:mastuuid',
	LEADS_ID_DETAILS: 'files/charting/Invoice/getLeadId/:mastuuid',
	UPDATE_REMARKS: 'files/communication/prospects/remarks/:mastuuid',
	DELETE_LEADS_API: 'files/communication/prospects/delete/:mastuuid',
	POST_LEADS: 'files/communication/prospects/add/:mastuuid',
	UPDATE_LEADS: 'files/communication/prospects/update/:mastuuid',
	// Header push notification
	GET_NOTIFICATION_LIST: 'files/communication/dashboard/notify/fetch',
	READ_NOTIFICATION: 'files/communication/dashboard/notify/manage/read',
	CLEAR_ONE_NOTIFICATION: 'files/communication/dashboard/notify/manage/clearone',
	CLEAR_ALL_NOTIFICATION: 'files/communication/dashboard/notify/manage/clearall',
	// Client communication:
	GET_CONSULT_CHAT_HISTORY: 'files/communication/whatsapp/history/cust/:custId',
	GET_CONSULT_CHAT_HISTORY_LEADS: 'files/communication/whatsapp/history/leads/:custId',

	// Google import Contacts
	GET_MULTI_CONTACTS: 'files/communication/multiplecontacts',
	POST_MULTI_CONTACTS: 'files/communication/multiplecontacts/add',
	GET_SYNC_CONTACTS: 'files/communication/synccontacts',
	//health board
	GET_ALL_BOARD: `files/communication/spike/health/board/main/:custUuid/:uuid`,
	GET_PROVIDER_BOARD: `files/communication/spike/user_data/:custUuid`,
	GET_CHART_DETAILS: `files/communication/spike/health/board/single/:custUuid/:type`,
	GET_SYNC: `files/communication/spike/sync/:custUuid`,
	FETCH_HEALTH_REPORTS_LIST: 'files/communication/fetch/reports/:mastUuid/:custUuid',
	FETCH_INIVIDUAL_REPORT_DATA: '/files/communication/fetch/docs/:mastUuid/:custUuid/:reportName/:fileName',
	//attendance
	GET_CLIENT_TABLE_DETAILS: `files/communication/attendance/management/cust/:mastUuid`,
	GET_STAFF_TABLE_DETAILS: `files/communication/attendance/management/tent/:mastuuid`,
	POST_CHECKOUT_CLIENT: 'files/communication/attendance/management/cust/checkout',
	POST_CHECKIN_CLIENT: 'files/communication/attendance/management/cust/checkin',
	GET_CUST_MEMBERSHIP: 'files/settings/membership/clientMembership/get',
	//AI-Dashboard
	GET_S3_EMBBED_URL: `ai/integration/get/quicksightDashboard`,
	GET_QDASHBOARD_URL: 'ai/integration/get/qDashboard/:mastuuid',
	//Jitsi APIs
	JITSI_CALL_ACCEPT: `files/node/tent/calls/accept`,
	REJOIN_STATUS_DATA: `files/node/tent/calls/rejoin`,
	GET_QDASHBOARD_URL: 'ai/integration/get/qDashboard',
	GET_AI_CHAT_HISTORY: `files/communication/aichat/chathistory/get`,
	GET_CHATBOT_SESSIONS: `files/communication/aichat/chathistory/get/sessionid`,
}

export const ACTION_TYPES = {
	AUTH_REGISTER: 'AUTH_REGISTER',
	AUTH_LOGIN: 'AUTH_LOGIN',
	SET_PIN_STATUS: 'SET_PIN_STATUS',
	UPDATE_MAIL: 'UPDATE_MAIL',
	MOBILE_NUMBER_LENGTH: 'MOBILE_NUMBER_LENGTH',
	MOBILE_NUMBER_MIN_LENGTH: 'MOBILE_NUMBER_MIN_LENGTH',
	AUTH_RESETPASSWORD: 'AUTH_RESETPASSWORD',
	AUTH_GROUPSELECTION: 'AUTH_GROUPSELECTION',
	AUTH_OTP: 'AUTH_OTP',
	UPDATE_DEVICE_ID: 'UPDATE_DEVICE_ID',
	UPDATE_ISWELCOME: 'UPDATE_ISWELCOME',
	UPDATE_NEW_USER: 'UPDATE_NEW_USER',
	UPDATE_PRACTICEDETAILS: 'UPDATE_PRACTICEDETAILS',
	GET_GEO_LOCATIONS_STARTED: 'GET_GEO_LOCATIONS_STARTED',
	GET_GEO_LOCATIONS_SUCCESS: 'GET_GEO_LOCATIONS_SUCCESS',
	GET_GEO_LOCATIONS_FAILED: 'GET_GEO_LOCATIONS_FAILED',
	DYNAMIC_MENU_FETCHING: 'DYNAMIC_MENU_FETCHING',
	PRACTICE_LIST_FETCHING: 'PRACTICE_LIST_FETCHING',
	IS_FETCHING_PRACTICE_LIST: 'IS_FETCHING_PRACTICE_LIST',
	DYNAMIC_MENU_SUCCESS: 'DYNAMIC_MENU_SUCCESS',
	CURRENT_PRACTICE_DATA: 'CURRENT_PRACTICE_DATA',
	DYNAMIC_MENU_UPDATE_TRIAL: 'DYNAMIC_MENU_UPDATE_TRIAL',
	UPDATE_DYNAMIC_MENU: 'UPDATE_DYNAMIC_MENU',
	AUTH_CALENDAR: 'AUTH_CALENDAR',
	CURRENT_USER: 'CURRENT_USER',
	CURRENT_TNT: 'CURRENT_TNT',
	TENANT_TYPE: 'TENANT_TYPE',
	TENANT_NAME: 'TENANT_NAME',
	GROUPUSER_NAME: 'GROUPUSER_NAME',
	CUSTOMER_UUID: 'CUSTOMER_UUID',
	CUSTOMER_LIST_DATA: 'CUSTOMER_LIST_DATA',
	SPECIALIST_LIST_DATA: 'SPECIALIST_LIST_DATA',
	CUSTOMER_DATA: 'CUSTOMER_DATA',
	PAST_APPOINTMENT_DATA: 'PAST_APPOINTMENT_DATA',
	UPDATE_MASTER_UUID: 'UPDATE_MASTER_UUID',
	UPDATE_TENTUSER_UUID: 'UPDATE_TENTUSER_UUID',
	UPDATE_OWNER_STATUS: 'UPDATE_OWNER_STATUS',
	POST_DATA: 'POST_DATA',
	DOCTOR_ROLE_UUID: 'DOCTOR_ROLE_UUID',
	ISEDITRECORD: 'ISEDITRECORD',
	USER_MENU: 'USER_MENU',
	UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',

	// SnackBar
	SHOW_SNACKBAR: 'SHOW_SNACKBAR',
	HIDE_SNACKBAR: 'HIDE_SNACKBAR',
	UPDATE_CURRENT_INDEX: 'UPDATE_CURRENT_INDEX',
	CHANNEL_PATIENT_CLICK: 'CHANNEL_PATIENT_CLICK',
	CHANNEL_LEADS_CLICK: 'CHANNEL_LEADS_CLICK',
	AUTH_FORGOTPASSWORD: 'AUTH_FORGOTPASSWORD',
	AUTH_LOGOUT: 'AUTH_LOGOUT',
	AUTH_ESTSELECTION: 'AUTH_ESTSELECTION',
	ERR_MSG: 'ERR_MSG',
	ERROR_MESSAGES_START: 'ERROR_MESSAGES_START',
	ERROR_MESSAGES_FAILURE: 'ERROR_MESSAGES_FAILURE',
	ERROR_MESSAGES_SUCCESS: 'ERROR_MESSAGES_SUCCESS',
	// Loader
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
	TENENT_TYPE_NAME: 'TENENT_TYPE_NAME',
	TENT_USER_ID: 'TENT_USER_ID',
	INDIVIDUAL_TENT_USER: 'INDIVIDUAL_TENT_USER',
	ISCHARTINGDATAPRESENT: 'ISCHARTINGDATAPRESENT',
	SET_VERIFY_MODAL: 'SET_VERIFY_MODAL',
	CLOSE_VERIFY_MODAL: 'CLOSE_VERIFY_MODAL',
	SET_OPEN_OTP_MODAL: 'SET_OPEN_OTP_MODAL',
	CLOSE_OTP_MODAL: 'CLOSE_OTP_MODAL',
	// Prompt
	SHOW_PROMPT: 'SHOW_PROMPT',
	CLOSE_PROMPT: 'CLOSE_PROMPT',
	//calendar - Add Appointment
	APPOINTMENT_ID: 'APPOINTMENT_ID',
	CURRENT_APPOINTMENT_DATA: 'CURRENT_APPOINTMENT_DATA',
	TENT_ROLE_UUID: 'TENT_ROLE_UUID',
	VIEW_CATEGORY_LIST_DATA: 'VIEW_CATEGORY_LIST_DATA',
	ADD_APPOINTMENT_MODAL: 'ADD_APPOINTMENT_MODAL',
	APPOINTMENT_REFRESH: 'APPOINTMENT_REFRESH',
	SET_COUNTRY_CODES: 'SET_COUNTRY_CODES',
	EDIT_APPOINTMENT: 'EDIT_APPOINTMENT',
	APPOINTMENT_FOR_SELECTED_CLIENT: 'APPOINTMENT_FOR_SELECTED_CLIENT',
	SHOW_CANCELLED_APPOINTMENTS: 'SHOW_CANCELLED_APPOINTMENTS',
	SHOW_COMPLETED_APPOINTMENTS: 'SHOW_COMPLETED_APPOINTMENTS',
	WHERE_APPOINTMENT_OPENED_FROM: 'WHERE_APPOINTMENT_OPENED_FROM',
	APPOINTMENT_PREPOPULATION_DATA: 'APPOINTMENT_PREPOPULATION_DATA',
	APPOINTMENT_DATE: 'APPOINTMENT_DATE',

	//Calendar - Add Reminder
	ADD_REMINDER_MODAL: 'ADD_REMINDER_MODAL',
	EDIT_REMINDER_DATA: 'EDIT_REMINDER_DATA',
	ADD_BLOCK_CALENDAR_MODAL: 'ADD_BLOCK_CALENDAR_MODAL',
	EDIT_BLOCKCALENDAR_DATA: 'EDIT_BLOCKCALENDAR_DATA',
	CANCEL_APPOINTMENT_MODAL: 'CANCEL_APPOINTMENT_MODAL',
	CANCEL_APPOINTMENT_DATA: 'CANCEL_APPOINTMENT_DATA',
	CANCEL_APPOINTMENT_REFRESH: 'CANCEL_APPOINTMENT_REFRESH',
	REMINDER_REFRESH: 'REMINDER_REFRESH',
	BLOCK_CALENDAR_REFRESH: 'BLOCK_CALENDAR_REFRESH',

	// INTERNAL SOCKET CONNECTION
	SSE_LISTENER: 'SSE_LISTENER',
	SOCKET_ALERT_CONNECTION: 'SOCKET_ALERT_CONNECTION',
	SOCKET_CONNECTED_CHEAD: 'SOCKET_CONNECTED_CHEAD',
	CHAT_ALL_ACTIVE: 'CHAT_ALL_ACTIVE',
	CHAT_ATTENDING_ACTIVE: 'CHAT_ATTENDING_ACTIVE',
	CHAT_IDLE_ACTIVE: 'CHAT_IDLE_ACTIVE',
	//suubscriptioon
	SUBSCRIPTION_TENTUSER_UUID: 'SUBSCRIPTION_TENTUSER_UUID',
	// Profile Setup
	UPDATE_PERSONALDETAILS_EMAIL_LIST: 'UPDATE_PERSONALDETAILS_EMAIL_LIST',
	UPDATE_PERSONALDETAILS_EMAIL_FORMDATA: 'UPDATE_PERSONALDETAILS_EMAIL_FORMDATA',
	UPDATE_PERSONALDETAILS_PHONE_LIST: 'UPDATE_PERSONALDETAILS_PHONE_LIST',
	UPDATE_PERSONALDETAILS_PHONE_FORMDATA: 'UPDATE_PERSONALDETAILS_PHONE_FORMDATA',
	ESTABLISHMENT_DETAILS: 'ESTABLISHMENT_DETAILS',
	UPDATE_REGISTRATION_DETAILS: 'ADD_REGISTRATION_DETAILS',
	UPDATE_REGISTRATION_FORM_DATA: 'UPDATE_REGISTRATION_FORM_DATA',
	UPDATE_PERSONAL_PROOF: 'UPDATE_PERSONAL_PROOF',
	UPDATE_ESTABLISHMENT_LIST_DATA: 'UPDATE_ESTABLISHMENT_LIST_DATA',
	UPDATE_ESTABLISHMENT_FORM_DATA: 'UPDATE_ESTABLISHMENT_FORM_DATA',
	UPDATE_ESTABLISHMENT_PROOF: 'UPDATE_ESTABLISHMENT_PROOF',
	UPDATE_EDUCATION_DETAILS: 'UPDATE_EDUCATION_DETAILS',
	UPDATE_EDUCATION_FORMDATA: 'UPDATE_EDUCATION_FORMDATA',
	UPDATE_AWARD_DETAILS: 'UPDATE_AWARD_DETAILS',
	UPDATE_AWARD_FORMDATA: 'UPDATE_AWARD_FORMDATA',
	UPDATE_MEMBER_DETAILS: 'UPDATE_MEMBER_DETAILS',
	UPDATE_MEMBER_FORMDATA: 'UPDATE_MEMBER_FORMDATA',
	UPDATE_TIMING_DETAILS: 'UPDATE_TIMING_DETAILS',
	UPDATE_TIMING_FORMDATA: 'UPDATE_TIMING_FORMDATA',
	UPDATE_SERVICEEXP_LIST_DATA: 'UPDATE_SERVICEEXP_LIST_DATA',
	UPDATE_SERVICEEXP_FORM_DATA: 'UPDATE_SERVICEEXP_FORM_DATA',
	SELECTED_DAY: 'SELECTED_DAY',
	SELECTED_DATE_CHANGE: 'SELECTED_DATE_CHANGE',
	SELECTED_DAY_EVENTS: 'SELECTED_DAY_EVENTS',
	SET_PROFILE_STATUS: 'SET_PROFILE_STATUS',
	REMINDER_LIST: 'REMINDER_LIST',
	REMINDER_LIST_DETAILS: 'REMINDER_LIST_DETAILS',
	ISEDITPATIENT: 'ISEDITPATIENT',
	ROLE_UUID: 'ROLE_UUID',
	//charting Add record
	DOCTOR_UUID: 'DOCTOR_UUID',
	DOCTOR_DETAILS: 'DOCTOR_DETAILS',
	VITAL_SIGNS_DATA: 'VITAL_SIGNS_DATA',
	UPCOMING_DATA: 'UPCOMING_DATA',
	UPCOMING_DATAA: 'UPCOMING_DATAA',
	PATIENT_VISIT_COUNT: 'PATIENT_VISIT_COUNT',
	PATIENT_VISIT_COUNT_LIST: 'PATIENT_VISIT_COUNT_LIST',
	CHARTINGTENTID: 'CHARTINGTENTID',
	CATEGORY_UUID: 'CATEGORY_UUID',
	ISCURRENTAPPUUID: 'ISCURRENTAPPUUID',
	PROFILE_VIEW: 'PROFILE_VIEW',
	PROFILE_VIEW_DATA: 'PROFILE_VIEW_DATA',
	SELECTED_DATE: 'SELECTED_DATE',
	PENDING_PAYMENT_MODAL: 'PENDING_PAYMENT_MODAL',
	REFUND_PAYMENT_MODAL: 'REFUND_PAYMENT_MODAL',
	BILLING_TENT_USER_UUID: 'BILLING_TENT_USER_UUID',
	SHOW_BILLING_INVOICEDETAILS: 'SHOW_BILLING_INVOICEDETAILS',
	SHOW_BILLING_PAYMENT_DETAILS: 'SHOW_BILLING_PAYMENT_DETAILS',
	SHOW_BILLING_REFUND_DETAILS: 'SHOW_BILLING_REFUND_DETAILS',
	SHOW_BILLING_APPOINTMENT_DETAILS: 'SHOW_BILLING_APPOINTMENT_DETAILS',
	SHOW_PENDING_DETAILS: 'SHOW_PENDING_DETAILS',
	SHOW_REFUND_DETAILS: 'SHOW_REFUND_DETAILS',
	CHARTING_RECORDS: 'CHARTING_RECORDS',
	TODAYS_APPOINTMENTS: 'TODAYS_APPOINTMENTS',
	TODAYS_MET_APPOINTMENTS: 'TODAYS_MET_APPOINTMENTS',
	// billingHistoryApiTrigger
	ADD_DRUG_INSTRUCTION: 'ADD_DRUG_INSTRUCTION',
	OPEN_COMMUNICATION_FILTER: 'OPEN_COMMUNICATION_FILTER',
	SELECTED_INTEGRATION: 'SELECTED_INTEGRATION',
	UPDATE_INTEGRATION: 'UPDATE_INTEGRATION',
	//Communication lets start button
	LETS_START_BTN: 'LETS_START_BTN',
	//Settings
	ADD_PRACTICE_DETAILS: 'ADD_PRACTICE_DETAILS',
	ADD_PROCEDURE_CATALOG: 'ADD_PROCEDURE_CATALOG',
	ADD_COMMISSION_CATALOG: 'ADD_COMMISSION_CATALOG',
	GET_IMPORT_DROPDOWN: 'GET_IMPORT_DROPDOWN',
	ADD_DRUG_CATALOG: 'ADD_DRUG_CATALOG',
	ADD_DIET_INSTRUCTION_CATALOG: 'ADD_DIET_INSTRUCTION_CATALOG',
	UPDATE_VERIFIEDMAIL: 'UPDATE_VERIFIEDMAIL',
	UPDATE_VERIFIEDMOBILENUMBER: 'UPDATE_VERIFIEDMOBILENUMBER',
	UPDATE_IMAGEID: 'UPDATE_IMAGEID',
	UPDATE_EMAIL: 'UPDATE_EMAIL',
	UPDATE_MOBILE_NUMBER: 'UPDATE_MOBILE_NUMBER',
	UPDATE_BEARER: 'UPDATE_BEARER',
	UPDATE_PROFILEPIC: 'UPDATE_PROFILEPIC',
	BILLING_ORDER_UUID: 'BILLING_ORDER_UUID',
	HANDLE_PRACTICE_MODAL: 'HANDLE_PRACTICE_MODAL',
	GROUP_DATA: 'GROUP_DATA',
	PRACTICE_DETAILS_COUNT: 'PRACTICE_DETAILS_COUNT',
	STAFF_MANAGEMENT_DETAILS_COUNT: 'STAFF_MANAGEMENT_DETAILS_COUNT',
	// Reports
	UPDATE_REPORT: 'UPDATE_REPORT',
	UPDATE_CANCELLED: 'UPDATE_CANCELLED',
	FOLLOW_DURATION: 'FOLLOW_DURATION',
	FOLLOW_OPTION: 'FOLLOW_OPTION',
	FOLLOWDURATION_TYPE: 'FOLLOWDURATION_TYPE',

	// LOOKUPS
	COUNTRY_LOOKUP_DATA: 'COUNTRY_LOOKUP_DATA',
	// CALENDAR DATA
	SET_DOCTOR_ID: 'SET_DOCTOR_ID',
	SET_APPCAT_ID: 'SET_APPCAT_ID',
	SET_SPECIALIST_ID: 'SET_SPECIALIST_ID',
	SET_PATIENT_SPECIALIST_ID: 'SET_PATIENT_SPECIALIST_ID',
	//notification
	SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
	HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',

	//Call Notification
	SHOW_CALL_NOTIFICATION: 'SHOW_CALL_NOTIFICATION',
	HIDE_CALL_NOTIFICATION: 'HIDE_CALL_NOTIFICATION',
	SHOW_JITSI_MEETING: 'SHOW_JITSI_MEETING',
	SHOW_CALLING_POPUP: 'SHOW_CALLING_POPUP',

	// WALKTHROUGH SCREENS
	IS_ACCOUNT_COMPLETED: 'IS_ACCOUNT_COMPLETED',
	IS_USERMANAGEMNET_COMPLETED: 'IS_USERMANAGEMNET_COMPLETED',
	IS_SETTING_CALENDAR_COMPLETED: 'IS_SETTING_CALENDAR_COMPLETED',
	UPDATE_USER_MANAGEMENT_STEPS: 'UPDATE_USER_MANAGEMENT_STEPS',
	UPDATE_ACCOUNT_STEPS: 'UPDATE_ACCOUNT_STEPS',
	UPDATE_SETTING_CALENDAR_STEPS: 'UPDATE_SETTING_CALENDAR_STEPS',
	IS_WALKTHROUGH_SKIPPED: 'IS_WALKTHROUGH_SKIPPED',
	IS_PRICE_COMPLETED: 'IS_PRICE_COMPLETED',
	IS_DRUG_COMPLETED: 'IS_DRUG_COMPLETED',
	IS_CLINICAL_COMPLETED: 'IS_CLINICAL_COMPLETED',
	IS_BILLING_COMPLETED: 'IS_BILLING_COMPLETED',
	IS_CALENDAR_COMPLETED: 'IS_CALENDAR_COMPLETED',
	IS_PATIENT_CARD_COMPLETED: 'IS_PATIENT_CARD_COMPLETED',
	IS_PATIENT_MAIN_COMPLETED: 'IS_PATIENT_MAIN_COMPLETED',
	// Client
	CUSTOMER_LIST: 'CUSTOMER_LIST',
	GLOBAL_GRID_VIEW: 'GLOBAL_GRID_VIEW',
	GLOBAL_GRID_VIEWW: 'GLOBAL_GRID_VIEWW',
	CUSTOMER_LIST_EMPTY: 'CUSTOMER_LIST_EMPTY',
	OPEN_ADD_CLIENT_MODAL: 'OPEN_ADD_CLIENT_MODAL',
	CLOSE_ADD_CLIENT_MODAL: 'CLOSE_ADD_CLIENT_MODAL',
	OPEN_ADD_PATIENT_MODAL: 'OPEN_ADD_PATIENT_MODAL',
	CLOSE_ADD_PATIENT_MODAL: 'CLOSE_ADD_PATIENT_MODAL',
	CLIENT_DATA: 'CLIENT_DATA',
	SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
	SET_APPOINTMENT: 'SET_APPOINTMENT',
	SET_TEMPLATE: 'SET_TEMPLATE',
	GLOBAL_ADVANCED_FILTER: 'GLOBAL_ADVANCED_FILTER',
	CURRENT_EDIT_FOOD: 'CURRENT_EDIT_FOOD',
	CURRENT_CLIENT_DIET_PLAN: 'CURRENT_CLIENT_DIET_PLAN',
	// Global Search
	ADVANCED_SEARCH_OPTIONS: 'ADVANCED_SEARCH_OPTIONS',
	ADVANCED_SEARCH_VALUE: 'ADVANCED_SEARCH_VALUE',
	SEARCH_OPTIONS: 'SEARCH_OPTIONS',
	SEARCH_INPUT: 'SEARCH_INPUT',
	SEARCH_VALUE: 'SEARCH_VALUE',
	SET_UPCOMING_APPOINTMENT: 'SET_UPCOMING_APPOINTMENT',
	SET_PAST_APPOINTMENT: 'SET_PAST_APPOINTMENT',
	// Subscription Plan
	SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
	SET_BUY_NOW: 'SET_BUY_NOW',
	// Import
	IMPORT_DATA: 'IMPORT_DATA',
	CONSENT_FORM_DATA: 'CONSENT_FORM_DATA',
	//API trigger Methods
	BILLING_HISTORY_API_TRIGGER: 'BILLING_HISTORY_API_TRIGGER',
	CUSTOMER_LIST_API_TRIGGER: 'CUSTOMER_LIST_API_TRIGGER',
	WORKOUT_PLAN_TRIGGER: 'WORKOUT_PLAN_TRIGGER',
	INVOICE: 'INVOICE_DETAILS',
	INVOICE_REFUND: 'INVOICE_REFUND',
	GETCUST_MASTER: 'GETCUST_MASTER',
	SET_PRACTICE_LIST_NAME: 'SET_PRACTICE_LIST_NAME',
	SET_CURRENT_CLIENT: 'SET_CURRENT_CLIENT',
	// Leads
	LEADS_DATA: 'LEADS_DATA',
	LEADS_LIST_DATA: 'LEADS_LIST_DATA',
	LEADS_LIST_EMPTY: 'LEADS_LIST_EMPTY',
	LEADS_EDIT_LIST_DATA: 'LEADS_EDIT_LIST_DATA',
	LEADS_GRID_VIEW: 'LEADS_GRID_VIEW',
	LEADS_ADVANCED_FILTER: 'LEADS_ADVANCED_FILTER',
	//Communication Whatsapp center
	TEMPLATE_BASED_DATA: 'TEMPLATE_BASED_DATA',
	CHATLIST_ALL_ACTIVE: 'CHATLIST_ALL_ACTIVE',
	CHATLIST_ATTENDING_ACTIVE: 'CHATLIST_ATTENDING_ACTIVE',
	CHATLIST_WAITING_ACTIVE: 'CHATLIST_WAITING_ACTIVE',
	CHATLIST_IDLE_ACTIVE: 'CHATLIST_IDLE_ACTIVE',
	CHATLIST_CLOSED_ACTIVE: 'CHATLIST_CLOSED_ACTIVE',
	CHATLIST_EXPIRED_ACTIVE: 'CHATLIST_EXPIRED_ACTIVE',
	UPDATE_CURRENT_MODULE: 'UPDATE_CURRENT_MODULE',
	// JITSI
	JITSI_CALL_REQUEST: 'JITSI_CALL_REQUEST',
	JITSI_CALLER_ACTIVE: 'JITSI_CALLER_ACTIVE',
	// meeting link from appt
	UPDATE_USERDATA: 'UPDATE_USERDATA',
	// Events
	CURRENT_EVENT_LIST: 'SET_CURRENT_LIST',
	SAVED_EVENT: 'SAVED_EVENT',
	IS_EDIT_EVENT: 'IS_EDIT_EVENT',
	FEEDBACK_FILTER: 'FEEDBACK_FILTER',
}

export const SYMBOLS = {
	PERCENTAGE: '%',
	RUPEE: '',
	DOLLAR: '$',
}

export const ROLE_MODULES = {
	// DASHBOARD: 'Dashboard',
	DASHBOARD: 'dashboard',
	DASHBOARDAI: 'dashboardai',
	// CALENDAR: 'Calendar',
	CALENDAR: 'calendar',
	// QUICKSALE: 'Quick sale',
	QUICKSALE: 'quicksale',
	// PATIENTS: 'Patients',
	PATIENTS: 'clients',
	// COMMUNICATION: 'Communication',
	COMMUNICATION: 'communication',
	// REPORT: 'Reports',
	REPORT: 'report',
	// SETTINGS: 'Settings',
	SETTINGS: 'setting',
	// CLIENTS: 'Clients',
	CLIENTS: 'clients',
	// CHAT_CONSULT: 'Consult',
	CHAT_CONSULT: 'chats',
	// LEADS: 'Leads',
	LEADS: 'leads',
	// PROFILE: 'Profile',
	PROFILE: 'profile',
	// FEEDBACK: 'Feedback',
	FEEDBACK: 'feedback',
	// PRODUCTS: 'Products',
	PRODUCTS: 'products',
	// PHARMACIST: 'Pharma',
	PHARMACIST: 'pharma',
	// INVENTORY: 'Inventory',
	INVENTORY: 'inventory',
	// EXPENSES: 'Expenses',
	EXPENSES: 'expenses',
	// BACK_OFFICE: 'Back Office',
	BACK_OFFICE: 'backoffice',
	// ACCOUNT_SETTING: 'Account settings',
	ACCOUNT_SETTING: 'accountsetting',
	// MY_PROFILE: 'My profile',
	MY_PROFILE: 'accountdetails',
	// SUBSCRIPTION: 'Subscription',
	SUBSCRIPTION: 'accountsubscription',

	// ############
	PATIENTEMR: 'EMR',
	CONSULT: 'consult',
	HEALTH_FEED: 'Health Feed',
	E_SIGNATURE: 'eSignature',
	ACTIVITIES: 'activities',
	ACCOUNT_SETTINGS: 'Account settings',
	ADD_NEW_PRACTICE: 'Add new practice',
	HELP_CENTER: 'helpcenter',
	EVENTS: 'events',
	BREATHING_RATE: 'breathing rate',
	// mastMenuName: 'My profile',
	ATTENDANCE: 'attendance',
}

export const FEATURES_LIST = {
	DASHBOARD: 'Dashboard',
	DASHBOARDAI: 'Dashboardai',
	QUICKSALE: 'Quick sale',
	APPOINTMENT: 'Appointment',
	BLOCK_CALENDAR: 'Block calendar',
	CATEGORY: 'Category',
	REMINDER: 'Reminder',
	VITAL_SIGNS: 'Vital signs',
	CLINICAL_NOTES: 'Clinical notes',
	PRESCRIPTION: 'Prescription',
	LAB_ORDERS: 'Lab orders',
	AYURVEDIC_PRESCRIPTION: 'Ayurvedic Prescription',
	COMPLETED_PROCEDURE: 'Completed procedure',
	BILLING: 'Billing',
	FILES: 'Files',
	MEDICAL_HISTORY: 'Medical history',
	PATIENTS: 'Patients',
	SCHEDULE_COMMUNICATION: 'Schedule communication',
	TREATMENT_PLAN: 'Treatment plan',
	ARTICLE: 'Article',
	COMMUNICATION_PLAN: 'Communication plan',
	SMS_CENTER: 'SMS Center',
	REPORTS: 'Reports',
	EVENTS: 'Events',
	REPORTS_IN_MAIL: 'Reports in mail',
	REPORTS_INVOICED_DETAIL: 'Invoiced details',
	REPORT_NEW_CLIENTS: 'New patients',
	REPORT_APPOINTMENTS: 'reportAppointment',
	REPORT_DISTRIBUTOR: 'reportDistributor',
	REPORT_MEMBERSHIP: 'reportMembership',
	AUTO_ID_GENERATION: 'Auto ID generation',
	CHARTING_TEMPLATE: 'Charting Template',
	WORK_OUT_PLAN: 'Workout plan',
	WHATSAPP_TEMPLATE_MENU: 'Whatsapp template',
	CONSENT_FORM: 'Consent form',
	DATA_SECURITY: 'Data security',
	DRUG_CATELOG: 'Drug catalog',
	PATIENT_GROUP: 'Patient group',
	PAYMENT_MODES: 'Payment modes',
	PRACTICE_DETAILS: 'Practice details',
	PRICING_CATELOG: 'Pricing catalog',
	DISCOUNT_MANAGEMENT: 'Discount management',
	COMMISSION_MANAGEMENT: 'Commission Management',
	SETTINGS_CALENDAR: 'Settings calendar',
	SETTINGS_COMMUNICATION: 'Settings communication',
	SETTINGS_NOTIFICATION: 'Settings notification',
	SETTINGS_INTEGRATION: 'Settings integration',
	TAX_CATELOG: 'Tax catalog',
	STAFF_MANAGEMNET: 'Staff management',
	PUBLIC_HOLIDAY: 'Public holiday',
	RECIPE: 'Recipe',
	CALL_LOGS: 'Call Logs',
	PROFILE: 'Profile',
	FEEDBACK: 'Feedback',
	CONSULT: 'Consult',
	HEALTH_FEED: 'Health feed',
	CLINICAL_CATELOG: 'Clinical notes',
	DIET_LIFESTYLE: 'Diet Lifestyle',
	DRUG_INSTRUCTION: 'Drug instruction',
	EXPENSES: 'Expenses',
	CONSUME_STOCK: 'Consume stock',
	NEW_ITEM: 'New item',
	ADD_STOCK: 'Add stock',
	ACTIVITIES: 'Activities',
	ADD_NEW_PRODUCTS: 'Add new product',
	MY_PROFILE: 'My profile',
	ACCOUNT_SETTING: 'Account setting',
	ACCOUNT_CHANGE_PASSWORD: 'Change password',
	ACCOUNT_E_SIGNATURE: 'eSignature',
	ACCOUNT_CHANGE_LOGIN_DETAILS: 'Change login details',
	ACCOUNT_DELETE_ACCOUNT: 'Delete account',
	ACCOUNT_ACTIVE_DEVICES: 'Active devices',
	ACCOUNT_LOGOUT: 'Logout',
	ACCOUNT_PIN: 'PIN',
	PRINT_INVENTORY: 'Print inventory',
	MAIL_INVENTORY: 'Inventory in mail',
	IMPORT_EXPORT: 'Import Export ',
	CURRENCY: 'Currency',
	DIET_PLAN: 'Dietplan',
	MEMBERSHIP: 'Membership',
	CUSTOMIZE_EMAIL: 'Customize',
	// Clients
	CLIENT_DETAILS: 'Client details',
	APPOINTMENTS: 'Appointment',
	CLIENT_FEED: 'Client feed',
	FILES_FORMS: 'Files',
	PRODUCTS: 'Products',
	REVIEW: 'Review',
	CHATBOT: 'ChatBot History',
	// Patients
	PATIENT_DETAILS: 'Patient details',
	PATIENT_FEED: 'Patient feed',
	WALLET: 'Wallet',
	DELETE_PROFILE: 'Delete profile',
	ATTENDANCE: 'Attendance',
}

export const PERMISSION_TYPE = {
	CREATE: 'isCreate',
	VIEW: 'isRead',
	EDIT: 'isUpdate',
	DELETE: 'isDelete',
}
