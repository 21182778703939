import { ACTION_TYPES } from '../../../constants'

export const updateCurrentIndex = (payload) => ({
	type: ACTION_TYPES.UPDATE_CURRENT_INDEX,
	payload,
})
export const clickPatientDetails = (payload) => ({
	type: ACTION_TYPES.CHANNEL_PATIENT_CLICK,
	payload,
})
export const clickLeadsDetails = (payload) => ({
	type: ACTION_TYPES.CHANNEL_LEADS_CLICK,
	payload,
})
