import { ACTION_TYPES } from '../../../constants'

export const setOpenOtpModal = (payload) => ({
	type: ACTION_TYPES.SET_OPEN_OTP_MODAL,
	payload,
})

export const closeOtpModal = (payload) => ({
	type: ACTION_TYPES.CLOSE_OTP_MODAL,
	payload,
})
