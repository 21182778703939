import React from 'react'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Dropzone from '../DropzoneUpload/Dropzone'
import themesConfig from 'app/fuse-configs/themesConfig'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	rootMain: {
		display: 'flex',
		gap: 24,
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	header: {
		paddingBlock: 8,
		justifyContent: 'end',
		'& .MuiButtonBase-root': {
			padding: 0,
		},
	},
	Upload: {
		width: 120,
		height: 130,
		display: 'flex',
	},
	mainContain: {
		display: 'flex',
		flexDirection: 'column',
		background: '#fff',
		gap: (props) => !props?.events && 12,
		'&:hover': {
			background: 'transparent',
		},
	},
	buttonMain: {
		display: 'flex',
		width: '100%',
		justifyContent: 'end',
		gap: 8,
	},
	cancelButton: {
		background: '#EFEFEF',
		color: 'black',
		paddingBlock: 4,
		paddingInline: 20,
	},
	deleteIcon: {
		fill: '#C40707',
	},
	fileImg: {
		minwidth: '100%',
		height: '50px',
	},
	buttonText: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		borderRadius: 21,
		textTransform: 'none',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		boxShadow: 'none',
		gap: 4,
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
	disable: {
		backgroundColor: '#bcbcbc52',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		borderRadius: 21,
	},
	helperText: {
		marginTop: 2,
		fontSize: 14,
		color: 'gray',
	},
}))

const BannerImg = (props) => {
	const {
		deleteAllFiles,
		fileDetail = [],
		establishmentFileResponse,
		setEstablishmentFileResponse = [],
		displayEstablishmentImage,
		setTriggerGetApi,
		formik,
		isMulti,
		events,
	} = props
	const classes = useStyles({ events })

	return (
		<>
			{(!_.isEmpty(fileDetail) || !_.isEmpty(establishmentFileResponse)) && !events && (
				<Grid container lg={12} justifyContent='end' className={classes.header}>
					<Grid item justifyContent='end'>
						<Tooltip title='delete' arrow placement='top'>
							<IconButton
								onClick={() => {
									deleteAllFiles()
								}}
							>
								<DeleteIcon className={classes.deleteIcon} />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			)}
			{/* {!_.isEqual(fileTypeDetail?.type, 'appointmentUpload') ? ( */}
			<div className={classes.mainContain}>
				<Dropzone
					imgResponse={establishmentFileResponse}
					formik={formik}
					// setGroups(_.uniqBy(groupData, 'uuid'))
					setTriggerGetApi={setTriggerGetApi}
					setImage={setEstablishmentFileResponse}
					displayUploadImage={displayEstablishmentImage}
					isMulti={typeof isMulti !== 'undefined' ? isMulti : true}
					events={events}
					deleteAllFiles={deleteAllFiles}
					// fileTypeDetail={fileTypeDetail}
				/>

				{/* HELPER TEXT FOR MAXIMUM FILES */}
				{typeof isMulti !== 'undefined'
					? isMulti
					: true && (
							<p className={classes.helperText}>Attached {formik?.values?.isBanner === 'yes' ? establishmentFileResponse?.length : 0}/6 files.</p>
					  )}
			</div>
			{/* ) : (
				<div className={classes.mainContain}>
					{!_.isEqual(fileDetail, []) ? (
						<div>
							<ShowViewFilesWithPreview
								filesData={fileDetail}
								// fileTypeDetail={fileTypeDetail}
								setTriggerGetApi={setTriggerGetApi}
								enableDelete={true}
							/>
						</div>
					) : (
						<img src='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/Noattachments_found.svg' alt='No files' className={classes.noFiles} />
					)}
				</div>
			)} */}
		</>
	)
}

export default BannerImg
