import React from 'react'
import Box from '@mui/material/Box'
import useStyles from './style'
import Patients from './Patients/index'
import Groups from './Groups/index'
import Staffs from './Staff/index'
import { Grid } from '@mui/material'
import EmailTemplate from '../EmailTemplate'

export default function BasicTabs({
	patientList,
	groupList,
	staffList,
	templateList,
	loading,
	templateLoading,
	state,
	dispatch,
	formik,
	templateType,
	checkAll,
	setCheckAll,
	filterTxt,
	setFilterTxt,
	list,
	setList,
}) {
	const classes = useStyles()

	return (
		<div className={classes.tabsMain}>
			<Box sx={{ width: '100%' }}>
				<Grid container spacing={2}>
					<Grid item lg={6} md={6} sm={12}>
						{templateType === 'Patients' ? (
							<Patients
								patientList={patientList}
								state={state}
								checkAll={checkAll}
								setCheckAll={setCheckAll}
								filterTxt={filterTxt}
								setFilterTxt={setFilterTxt}
								dispatch={dispatch}
								loading={loading}
								templateType={templateType}
								list={list}
								setList={setList}
							/>
						) : templateType === 'Clients' ? (
							<Patients
								patientList={patientList}
								state={state}
								checkAll={checkAll}
								setCheckAll={setCheckAll}
								filterTxt={filterTxt}
								setFilterTxt={setFilterTxt}
								dispatch={dispatch}
								loading={loading}
								templateType={templateType}
								list={list}
								setList={setList}
							/>
						) : templateType === 'Groups' ? (
							<Groups groupList={groupList} state={state} dispatch={dispatch} loading={loading} />
						) : (
							<Staffs staffList={staffList} state={state} dispatch={dispatch} loading={loading} />
						)}
					</Grid>
					<Grid item lg={6} md={6} sm={12}>
						<EmailTemplate templateList={templateList} loading={templateLoading} formik={formik} />
					</Grid>
				</Grid>
			</Box>
		</div>
	)
}
