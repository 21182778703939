/* eslint-disable prettier/prettier */
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import CardContent from '@mui/material/CardContent'
import Form from './loginForm'
import LoginSwip from '../SwiperLogin'
import LyfngoFlashLogo from '../../../images/logos/LyfngoFlash.png'
import _ from 'lodash'
import { Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import GoogleIcon from 'images/logos/Google_logo.svg'
import themesConfig from 'app/fuse-configs/themesConfig'
import EmailIcon from '@mui/icons-material/Email'
const Root = styled('div')(({ theme }) => ({
	background: '#FFF',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100vh',
	'& .Login-leftSection': {
		// maxWidth: 412,
	},
	'& .split-container': {
		display: 'flex',
		width: '100%',
	},
	'& .googleBtn': {
		display: 'flex',
		alignItems: 'center',
		marginBlockStart: 8,
		paddingBlock: 7,
		paddingInline: 8,
		width: '100%',
		background: '#fff',
		borderRadius: 24,
		border: '1px solid #C3C3C3',
		maxHeight: 34,
		fontWeight: 600,
		gap: 4,
		color: '#000',
		'&:hover': {
			background: '#fff',
			color: '#887E89',
		},
		'&.MuiButton-root.Mui-disabled': {
			background: '#fff',
			color: 'rgba(0, 0, 0, 0.26)',
		},
	},

	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .cardLogoContent': {
		width: '100%',
	},
}))
const Swiper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	height: '100vh',
	justifyContent: 'center',
	background: '#F7FAFF',
	width: '100%',
	[theme.breakpoints.down('xs')]: {
		display: 'none',
	},
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}))
const Footer = styled('div')(({ theme }) => ({
	position: 'relative',
	top: 48,
	'& .MuiTypography-body1': {
		color: '#000',
		fontWeight: 600,
	},
}))
export function getExplore() {
	return `${
		(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com')) ||
		(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.rigelsoft.online')) ||
		(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com'))
	}`
}
function Login({ history }) {
	const location = window?.location?.pathname
	const onClick = () => {
		return `${
			(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com/start-free-trial')) ||
			(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.rigelsoft.online/start-free-trial')) ||
			(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com/start-free-trial'))
		}`
	}

	return (
		<>
			<Root>
				<div className='split-container'>
					<Swiper>
						<LoginSwip location={location} styleStatus={false} />
						<Footer className='footer-Container'>
							<Typography variant='body1'>© {new Date().getFullYear()} LYFnGO, All Rights Reserved.</Typography>
						</Footer>
					</Swiper>

					<Card className='Login-leftSection flex flex-col w-full  items-center justify-center shadow-0 ' square>
						<CardContent className='cardContent flex flex-col items-center justify-center w-full p-0 max-w-320'>
							<div className='cardLogoContent' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-center mb-20' style={{ cursor: 'pointer' }} onClick={getExplore}>
									<img className='logo-icon' src={LyfngoFlashLogo} alt='logo' width='120' height='72' />
								</div>
							</div>
							<div className='mb-20 w-full'>
								<Typography variant='body1' style={{ color: '#000', fontSize: 16, fontWeight: 500 }}>
									Log in
								</Typography>
								<Typography variant='body1' style={{ color: '#000', fontSize: 14 }}>
									to access your <span style={{ color: '#0062DD', fontWeight: 600 }}>LYFnGO</span> account
								</Typography>
							</div>
							<Form />
						</CardContent>
					</Card>
				</div>
				{/* <Footer className='footer-Container'>
					<Typography variant='body1'>© {new Date().getFullYear()} LYFnGO, All Rights Reserved.</Typography>
				</Footer> */}
			</Root>
		</>
	)
}

export default Login
