import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	vitalSigns: secureLocalStorage.getItem('vitalSigns') || {},
}

const chartingReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.VITAL_SIGNS_DATA: {
			return {
				...state,
				vitalSigns: action.payload,
			}
		}
		default:
			return state
	}
}
export default chartingReducer
