import { authRoles } from 'app/auth'
import LoginOld from '../LoginOld/index'

const LoginOldConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/',
			exact: true,
			component: LoginOld,
		},
	],
}

export default LoginOldConfig
