import { React, useState, useEffect } from 'react'
import { showMessage } from 'app/store/fuse/messageSlice'
import { Typography, Grid, TextField, Autocomplete, FormHelperText } from '@mui/material'
import useStyles from './style'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { showLoader, hideLoader } from 'services/loader/action'
import { useDispatch, useSelector } from 'react-redux'
import _ from '@lodash'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import PlanArticle from '../../../../../../services/Communication/Article/api'
import Weeks from '../../../../../../model/Communication/Weeks/data'
import Days from '../../../../../../model/Communication/Days/data'
import OperationHours from '../../../../../../model/Communication/OperationHours/data'
import moment from 'moment'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import { getResponseMessage } from 'utils'

const convertHtmlToEditorState = (value) => {
	return _.isEmpty(value)
		? EditorState.createEmpty()
		: EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(JSON.parse(value)))))
}

export default function AddArticle({ open6, handleClose6, articleData, onArticleAddSuccess }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [editorState, setEditorState] = useState(convertHtmlToEditorState(articleData?.[0]?.articleContent))
	const erroJson = useSelector((states) => states?.errorMessages)
	const timezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezoneOffset || '')

	const onSubmit = (e) => {
		const formData2 = new FormData()
		formData2.append('uuid', articleData[0]?.articleUuid)
		formData2.append('articleName', e?.educatePatient)
		formData2.append('weekNo', e?.selectWeek?.WeekNo)
		formData2.append('dayNo', e?.selectDay?.dayNo)
		formData2.append('articleTime', moment(e?.operationTime, 'h:mm:ss A').format(`HH:mm:ss${timezone}`))
		formData2.append('articleContent', JSON.stringify(e?.editorState))

		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				handleClose6()
				onArticleAddSuccess()
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, erroJson, 'Article details upated successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res.data.status === 'failure') {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, erroJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, erroJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader('Updating data please wait...'))
		PlanArticle.articleUpdate(formData2).then(onSuccess, onFailure)
	}
	//Formik Validation
	const formik = useFormik({
		initialValues: {
			selectWeek: [],
			selectDay: [],
			operationTime: '',
			educatePatient: '',
			editorState: '',
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			selectWeek: yup.object().nullable().required('Please choose a week'),
			selectDay: yup.object().nullable().required('Please choose a day'),
			operationTime: yup.string().nullable().required('Please choose a scheduled time'),
			educatePatient: yup.string().required('Please enter the article title').max(100, 'Article topic is maximum 100 character only'),
			editorState: yup.string().required('Article content should not be empty'),
		}),
		onSubmit: onSubmit,
	})
	//Start : Artcile get
	useEffect(() => {
		if (!_.isEmpty(articleData)) {
			formik.setFieldValue('educatePatient', articleData[0]?.articleName || '')
			let foundWeek = _.find(Weeks, (obj) => obj.WeekNo === articleData[0].weekNo)
			let foundDay = _.find(Days, (obj) => obj.dayNo === articleData[0].dayNo)
			// let time = moment(articleData?.[0]?.articleTime, 'HH').format('h:mm A')
			let time = moment(articleData?.[0]?.articleTime, 'HH:mm:ss A').format('h:mm A')
			let foundTime = _.find(OperationHours, (o) => time === o)
			formik.setFieldValue('selectWeek', foundWeek)
			formik.setFieldValue('selectDay', foundDay)
			formik.setFieldValue('operationTime', foundTime)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [articleData])
	const handleEditorChange = (state) => {
		setEditorState(state)
		formik?.setFieldValue('editorState', draftToHtml(convertToRaw(state.getCurrentContent())))
	}

	//End : editor section
	const onCloseModal = (e, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			handleClose6()
		}
	}
	return (
		<CustomDialog headerText='Edit article' onClose={onCloseModal} open={open6} onSubmit={() => formik?.handleSubmit()}>
			<Grid container spacing={1} className={classes.newAddDetails}>
				<Grid item lg={12} md={12} sm={12}>
					<Typography className={classes.txt}>
						Add educational content about DOs and DON’Ts, dietary recommendations, exercises, things to expect or anything else to your communication
						plan.
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Typography variant='h6' className={classes.dayText}>
						Select day and time to schedule your article
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container className={classes.selectDayWeek} justifyContent='space-between'>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='selectweek'
								name='selectweek'
								size='small'
								fullWidth
								clearIcon=''
								options={Weeks}
								getOptionLabel={(option) => option?.week || ''}
								value={formik?.values?.selectWeek}
								onChange={(e, value) => {
									formik?.setFieldValue('selectWeek', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select week *'
										color='secondary'
										error={formik.touched.selectWeek && formik.errors.selectWeek}
										helperText={formik.touched.selectWeek && formik.errors.selectWeek && formik.errors.selectWeek}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='selectday'
								name='selectday'
								size='small'
								fullWidth
								clearIcon=''
								options={Days}
								getOptionLabel={(option) => option?.day || ''}
								value={formik?.values?.selectDay}
								onChange={(e, value) => {
									formik?.setFieldValue('selectDay', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select day *'
										color='secondary'
										error={formik.touched.selectDay && formik.errors.selectDay}
										helperText={formik.touched.selectDay && formik.errors.selectDay && formik.errors.selectDay}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='operationtime'
								name='operationtime'
								size='small'
								fullWidth
								clearIcon=''
								options={OperationHours}
								getOptionLabel={(option) => option || ''}
								value={formik?.values?.operationTime}
								onChange={(e, value) => {
									formik?.setFieldValue('operationTime', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Time *'
										color='secondary'
										error={formik.touched.operationTime && formik.errors.operationTime}
										helperText={formik.touched.operationTime && formik.errors.operationTime && formik.errors.operationTime}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={5} md={5} sm={12}>
							<TextField
								id='educatePatient'
								name='educatePatient'
								variant='outlined'
								placeholder='What do you want to educate your patients about? *'
								multiline
								fullWidth
								focused={false}
								size='small'
								color='secondary'
								value={formik?.values?.educatePatient}
								onChange={(e, value) => {
									formik?.setFieldValue('educatePatient', e.target.value)
								}}
								error={formik.touched.educatePatient && formik.errors.educatePatient}
								helperText={formik.touched.educatePatient && formik.errors.educatePatient && formik.errors.educatePatient}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Editor
						editorState={editorState}
						onEditorStateChange={handleEditorChange}
						wrapperClassName={classes.wrapperClass}
						editorClassName={classes.editorClass}
						toolbarClassName={classes.toolbarClass}
					/>
					<FormHelperText error>{formik?.errors?.editorState && formik?.touched?.editorState && formik?.errors?.editorState}</FormHelperText>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}
