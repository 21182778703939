import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getEstablishmentCity: (params) => {
		return axios.get(API_ENDPOINTS.ESTABLISHMENT_CITY, {
			headers: { isAuthRequired: true, 'content-Type': 'application/json' },
			params: {
				mastCountryCode: 'IN',
				limit: params?.limit || 20,
				offset: params?.offset || 1,
				search: params?.search || '',
			},
		})
	},
	getEstablishmentDetails: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_ESTABLISHMENT, {
			headers: { isAuthRequired: true, 'content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getSpeciality: (tentTypeId) => {
		return axios.get(API_ENDPOINTS.GET_SPECIALITY, {
			headers: { isAuthRequired: true, 'content-Type': 'application/json', path: { tentTypeId } },
		})
	},
	getFacility: () => {
		return axios.get(API_ENDPOINTS.GET_FACILITIES, {
			headers: { isAuthRequired: true, 'content-Type': 'application/json' },
		})
	},
	postFacility: (uuid) => {
		return axios.get(API_ENDPOINTS.POST_FACILITIES, {
			headers: { isAuthRequired: true, 'content-Type': 'application/json', path: { uuid } },
		})
	},
	updateEstablishment: async (uuid, body) => {
		return await axios.patch(API_ENDPOINTS.UPDATE_ESTABLISHMENT, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'content-Type': 'application/json', path: { uuid } },
		})
	},
}
