import { Card, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CardContent from '@mui/material/CardContent'
import Registerform from './registerform'
import { useState } from 'react'
import themesConfig from 'app/fuse-configs/themesConfig'
import LyfngoFlashLogo from '../../../images/logos/LyfngoFlash.png'
import Steps from './StepperComponent'
import _ from 'lodash'
import BusinessSetup from './BusinessSetup'
import ModuleSelection from './ModuleSelection'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
const Root = styled('div')(({ theme }) => ({
	background: '#FFF',
	display: 'flex',
	flexDirection: 'column',
	gap: 36,
	alignItems: 'center',
	justifyContent: 'center',

	'& .regContainer': {
		background: '#FFF',
		display: 'flex',
		width: '100%',
		height: '100vh',
		// gap: 36,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			height: 'auto',
		},
	},
	'& .Register-leftSection': {
		// // maxWidth: 524,
		// // padding: 24,
		// [theme.breakpoints.down('sm')]: {
		// 	width: '100%',
		// 	background: '#fff',
		// },
		// width: '100%',
		background: themesConfig.lyfngo.palette.primary.light,
		borderTopLeftRadius: 12,
		borderBottomLeftRadius: 12,
	},
	'& .Register-rightSection': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 24,
		width: '100%',
		// [theme.breakpoints.down('sm')]: {
		// 	padding: 0,
		// 	width: '100%',
		// },
		// [theme.breakpoints.up('sm')]: {
		// 	width: '50%',
		// },
		// // display: 'flex',
		// // alignItems: 'center',
		flexDirection: 'column',

		// justifyContent: 'space-evenly',
		'& .MuiTypography-h6': {
			paddingBlock: 12,
			fontSize: 16,
			fontWeight: 600,
			color: themesConfig.lyfngo.palette.primary.main,
		},
	},
	'& .bottomContainer': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			paddingBlock: 12,
		},
		'& .MuiTypography-h6': {
			fontSize: 16,
			fontWeight: 600,
		},
		'& .MuiTypography-subtitle1': {
			fontSize: 14,
			fontWeight: 500,
			color: themesConfig.lyfngo.palette.Gray.main,
		},
	},
	'& .cardContent': {
		[theme.breakpoints.down('md')]: {
			'&.MuiCardContent-root': {
				padding: 6,
			},
			height: 'auto',
		},
		height: '100vh',
	},
	'& .right-content': {
		fontWeight: 'bold',
	},
	'& .MuiPaper-root': {
		paddingBlockEnd: 14,
	},
	'& .stepperContainer': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
}))

export function getExplore() {
	return `${
		(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com')) ||
		(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.rigelsoft.online')) ||
		(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com'))
	}`
}
function Register({ match }) {
	const theme = useTheme()
	const [pageQuery, setPageQuery] = useUrlSearchParams({ tab: 'setupprofile' }, { tab: String })
	const currentTab = pageQuery?.tab
	const isMobileView = useMediaQuery(theme.breakpoints.down('md'))
	const [activeStep, setActiveStep] = useState(currentTab)

	return (
		<>
			<Root>
				<div className='regContainer'>
					<Card className='Register-leftSection w-full items-center  shadow-0' square>
						<CardContent className='cardContent flex  flex-col justify-center'>
							<div className='stepperContainer'>
								<Steps activeStep={activeStep} isMobileView={isMobileView} LyfngoFlashLogo={LyfngoFlashLogo} getExplore={getExplore} />
							</div>

							<div className='bottomContainer'>
								{!_.isEqual(isMobileView, true) && (
									<Typography variant='h6'>
										Explore <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontWeight: 600 }}>LYFnGO!</span>
									</Typography>
								)}
								<Typography variant='subtitle1'>
									Less than <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontWeight: 600 }}>40secs</span> to Setup flash
								</Typography>
							</div>
						</CardContent>
					</Card>
					<div className='Register-rightSection'>
						{_.isEqual(currentTab, 'setupprofile') ? (
							<Registerform setActiveStep={setActiveStep} setPageQuery={setPageQuery} pageQuery={pageQuery} />
						) : _.isEqual(currentTab, 'setupbusiness') ? (
							<BusinessSetup setActiveStep={setActiveStep} setPageQuery={setPageQuery} pageQuery={pageQuery} />
						) : _.isEqual(currentTab, 'moduleselection') ? (
							<ModuleSelection setActiveStep={setActiveStep} setPageQuery={setPageQuery} pageQuery={pageQuery} />
						) : null}
					</div>
				</div>
			</Root>
		</>
	)
}

export default Register
