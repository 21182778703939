import { React, useEffect, useState, useCallback, useRef } from 'react'
import {
	Typography,
	Grid,
	TextField,
	Autocomplete,
	Card,
	CardContent,
	Avatar,
	CardMedia,
	IconButton,
	CardActions,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@mui/material'
import useStyles from './style'
import Tabs from './Tabs/index'
import _ from 'lodash'
import patientsApi from '../../../../../../services/patient/patientDetails/api'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import { useSelector } from 'react-redux'
import { decryption } from 'utils'
import CardCover from '@mui/joy/CardCover'
// import moment from 'moment'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { green } from '@mui/material/colors'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import ViewModal from 'app/sharedComponents/ViewModal'

export default function SendSms({
	isCare,
	open,
	onModalClose,
	isView,
	handleClose,
	formik,
	mastuuid,
	handleChange,
	state,
	dispatch,
	templateType,
	onTemplateChange,
	checkAll,
	setCheckAll,
	patientsData,
	setPatientsData,
	patientSearchValue,
	setPatientSearchValue,
	patientSearchParams,
	setPatientSearchParams,
	message,
	setMessage,
	replacedContent,
	templateOption,
	metaTemplateVal,
	setMetaTemplateVal,
	handleFileChange,
	handleAvatarClick,
	fileInputRef,
	mediaUuid,
	setMediaUuid,
	removeMediaUuid,
	headerMessage,
	replacedHeaderContent,
	setHeaderMessage,
}) {
	const classes = useStyles()
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const [loading, setLoading] = useState(false)
	const [patientList, setPatientList] = useState([])
	const [groupList, setGroupList] = useState([])
	const [staffList, setStaffList] = useState([])
	const [pageNo, setPageNo] = useState(0)
	const [hasMore, setHasMore] = useState(true)
	const options = [groupName === 'health care' ? 'Patients' : 'Clients', 'Groups', 'Staff']
	const cancelExecutor = useRef()
	const [openView, setOpenView] = useState(false)
	const [type, setType] = useState('')
	const [imageDetails, setImageDetails] = useState(false)
	const BearerToken = useSelector((states) => states?.Authentication?.BearerToken)

	const handleClickOpen = (data, mediaType) => {
		setImageDetails(data)
		setOpenView(true)
		setType(mediaType)
	}

	const onSearch = _.debounce(
		(value) => {
			setPatientSearchParams({
				...patientSearchParams,
				name: value,
			})
		},
		[300]
	)

	const getPatientList = () => {
		let isSearchingPatient = patientSearchParams?.name?.length >= 2
		let queryParams = {
			...patientSearchParams,
			size: 20,
			page: isSearchingPatient ? 0 : pageNo,
		}
		setLoading(true)
		isSearchingPatient && setPatientList([])
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLoading(false)
				isSearchingPatient
					? setPatientList(_.uniqBy(res?.data?.data?.DataList || [], (obj) => obj.custUuid))
					: setPatientList((prevItems) => _.uniqBy([...prevItems, ...res?.data?.data?.DataList], (obj) => obj.custUuid))
				// setPatientList(res?.data?.data?.DataList || [])
				setPatientsData(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			setLoading(false)
			setPatientList([])
			setPatientsData(null)
		}
		patientsApi.getPatientsList(mastuuid, queryParams, cancelExecutor).then(onSuccess, onFailure)
	}

	const getGroupList = useCallback((mastuuid) => {
		const onSuccess = (res) => {
			setLoading(false)
			setGroupList(res?.data?.data?.TentGroupData || [])
		}
		const onFailure = (err) => {
			setLoading(false)
			setGroupList([])
		}
		setLoading(true)
		patientsApi.getGroupDetails(mastuuid, { isMenu: true }).then(onSuccess, onFailure)
	}, [])

	const getStaffList = useCallback((mastuuid) => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setLoading(false)
			let filteredList = _.filter(successData?.data?.tentUserRoleBasedDtoList, (item) => item.mastRoleName !== 'Owner')
			setStaffList(filteredList || [])
		}
		const onFailure = (err) => {
			setLoading(false)
			setStaffList([])
		}
		setLoading(true)
		patientsApi.getTentUserByRole(mastuuid, 'all').then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		if (open && isView === false) {
			if (templateType === 'Patients' || templateType === 'Clients') {
				getPatientList(mastuuid)
			} else if (templateType === 'Groups') {
				getGroupList(mastuuid)
			} else if (templateType === 'Staff') {
				getStaffList(mastuuid)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, open, isView, templateType, patientSearchParams, pageNo])

	useEffect(() => {
		let patientList = _.map(state?.patientList, (item) => (item?.custName ? item?.custName : item?.custCustomId))
		let groupList = _.map(state?.groupList, (item) => item?.tentGroupName)
		let staffList = _.map(
			state?.staffList,
			(item) => `${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || ''}`
		)
		let curentList =
			templateType === 'Groups'
				? [...groupList]
				: templateType === 'Staff'
				? [...staffList]
				: (_.isEqual(templateType, 'Patients') || _.isEqual(templateType, 'Clients')) && _.isEqual(checkAll, true)
				? [[`Selected all ${patientsData?.totalCount || ''} ${isCare ? 'patient(s)' : 'client(s)'}`]]
				: _.isEqual(templateType, 'Patients') || _.isEqual(templateType, 'Clients')
				? [...patientList]
				: []
		formik?.setFieldValue('recipients', curentList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, templateType, checkAll])

	const disabledSendButton = _.isEmpty(formik?.values?.recipients) || !((formik?.values?.addMeassage).trim().length >= 3)

	const numberFormat = useCallback(() => {
		let test1 = formik?.values?.addMeassage?.match(/{+([^}]+)}+/g)
		let res = _.map(test1, (data, i) => {
			return { [i + 1]: '' }
		})
		setMessage(res)
	}, [formik?.values?.addMeassage, setMessage])

	const numberFormatHeader = useCallback(() => {
		let test1 = formik?.values?.headerText?.match(/{+([^}]+)}+/g)
		let res = _.map(test1, (data, i) => {
			return { [i + 1]: '' }
		})
		setHeaderMessage(res)
	}, [formik?.values?.headerText, setHeaderMessage])

	useEffect(() => {
		if (formik?.values?.headerText) {
			numberFormatHeader()
		}
	}, [formik?.values?.headerText, numberFormatHeader])

	useEffect(() => {
		if (formik?.values?.addMeassage) {
			numberFormat()
		}
	}, [formik?.values?.addMeassage, numberFormat])

	// Function to update the data
	const updateTemplateData = (index, key, value) => {
		setMessage((prevData) => {
			const newData = [...prevData]
			newData[index][key] = value
			return newData
		})
	}

	const onCloseModal = () => {
		setPatientList([])
		setPatientsData(null)
		dispatch({ type: 'resetState' })
		onModalClose()
		setPageNo(0)
		setHasMore(true)
	}

	const clearRecipients = () => {
		dispatch({ type: 'resetState' })
		setCheckAll(false)
	}

	const messageFormatValues = (header, body, footer) => {
		return `
    ${header ? header : ''}

    ${body}

    ${footer}
  `
	}

	const updateTextValue = (header, body, footer) => {
		const result = messageFormatValues(header, body, footer)
		formik?.setFieldValue('messageTemplate', result)
	}

	return (
		<div>
			<CustomDialog
				onClose={onCloseModal}
				open={open && isView === false}
				disabled={disabledSendButton}
				headerText={'Send WhatsApp message'}
				onSubmit={() => formik?.handleSubmit()}
				formikValues={formik}
				maxWidth='md'
				SubmitBtnText='Send'
				hideSaveAndAddBtn
			>
				<div className={classes.smsPlanMain}>
					<Grid container xs={12}>
						{/* SELECT TYPE SECTION */}
						<Grid item xs={12} className='mt-4'>
							<Typography variant='body1'>Type</Typography>
							<FormControl>
								<RadioGroup
									row
									aria-labelledby='demo-row-radio-buttons-group-label'
									name='row-radio-buttons-group'
									value={templateType}
									onChange={(e) => onTemplateChange(e)}
								>
									{_.map(options, (data, i) => (
										<FormControlLabel
											value={data}
											style={{ paddingInlineStart: 12 }}
											control={
												<Radio
													sx={{
														color: '#0062DD',
														'&.Mui-checked': {
															color: '#0062DD',
														},
													}}
												/>
											}
											label={data}
										/>
									))}
								</RadioGroup>
							</FormControl>
						</Grid>

						{/* LEFT side */}
						<Grid item xs={5.7} style={{ marginRight: 20 }}>
							{/* SEARCHING AND SELECTING PATIENTS AND GROUPS */}
							<div className='mt-4'>
								<Tabs
									state={state}
									dispatch={dispatch}
									templateType={templateType}
									handleChange={handleChange}
									patientList={patientList}
									groupList={groupList}
									staffList={staffList}
									loading={loading}
									checkAll={checkAll}
									setCheckAll={setCheckAll}
									patientSearchValue={patientSearchValue}
									setPatientSearchValue={setPatientSearchValue}
									onSearch={onSearch}
									pageNo={pageNo}
									setPageNo={setPageNo}
									hasMore={hasMore}
									setHasMore={setHasMore}
									patientsData={patientsData}
								/>
							</div>
							{/* SELECT TEMPLATE */}
							<div className='mt-12'>
								<Autocomplete
									id='selectgrouptype'
									name='selectgrouptype'
									disablePortal
									color='secondary'
									size='small'
									disableClearable={true}
									options={templateOption}
									value={metaTemplateVal}
									filterSelectedOptions
									getOptionLabel={(option) => option?.template_name}
									onChange={(e, newVal) => {
										setMetaTemplateVal(newVal)
										setMessage([])
										setHeaderMessage([])
										setMediaUuid('')
										formik?.setFieldValue('addMeassage', newVal?.body?.text || '')
										formik?.setFieldValue('header', newVal?.header?.format || '')
										formik?.setFieldValue('footer', newVal?.footer?.text || '')
										formik?.setFieldValue('headerText', newVal?.header?.text || '')
										updateTextValue(newVal?.header?.text, newVal?.body?.text, newVal?.footer?.text)
									}}
									renderInput={(params) => <TextField size='small' color='secondary' {...params} label='Template' />}
								/>
							</div>
							{/* TYPING MESSAGE AREA FIELD */}
							<div className='mt-12'>
								<TextField
									id='messageTemplate'
									name='messageTemplate'
									multiline
									fullWidth
									variant='outlined'
									rows={10}
									color='secondary'
									value={formik?.values?.messageTemplate}
									// placeholder={messageFormatValues(formik?.values?.headerText, formik?.values?.messageTemplate, formik?.values?.footer)}
									inputProps={{ maxLength: 250 }}
									disabled
									error={formik.errors.messageTemplate && formik.touched.messageTemplate}
									helperText={formik.errors.messageTemplate && formik.touched.messageTemplate && formik.errors.messageTemplate}
									label='Message'
								/>
							</div>
							{!_.isEmpty(message) && (
								<div className='mt-12'>
									<Typography variant='body1'>Body*</Typography>
									{message.map((messageType, index) => (
										<div className='mt-12' key={index}>
											<Typography variant='body1' style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
												&#123;&#123;{index + 1}&#125;&#125; {''}
												<span style={{ width: '100%' }}>
													<TextField
														id={index}
														sx={{ width: '100%' }}
														name='dynamicTemplate'
														fullWidth
														variant='outlined'
														color='secondary'
														size='small'
														multiline
														onChange={(e) => updateTemplateData(index, index + 1, e.target.value)}
													/>
												</span>
											</Typography>
										</div>
									))}
								</div>
							)}
						</Grid>

						{/* Right side */}
						<Grid item xs={6}>
							{/* ADDED RECEIPIENTS LIST FIELD */}
							<>
								<Typography className={classes.recp}>Recipients</Typography>
								<div className={classes.recipientsArea}>
									<Typography>{formik?.values?.recipients.toString().replaceAll(',', ',  ')}</Typography>
								</div>
								<Typography onClick={clearRecipients} className={classes.clearAllRec}>
									Clear all recipients
								</Typography>
							</>
							<div className='mt-12'>
								{!_.isEmpty(formik?.values?.header) &&
									(_.isEqual(formik?.values?.header, 'TEXT') ? (
										<>
											<Typography variant='body1'>Header*</Typography>
											<div className='mt-12'>
												{headerMessage.map((messageType, index) => (
													<Typography variant='body1' style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
														&#123;&#123;{'1'}&#125;&#125; {''}
														<span style={{ width: '100%' }}>
															<TextField
																id={index}
																sx={{ width: '100%' }}
																name='dynamicTemplate'
																fullWidth
																variant='outlined'
																color='secondary'
																size='small'
																multiline
																onChange={(e) => {
																	setHeaderMessage((prevData) => {
																		const newData = [...prevData]
																		newData[index][index + 1] = e.target.value
																		return newData
																	})
																}}
															/>
														</span>
													</Typography>
												))}
											</div>
										</>
									) : _.isEqual(formik?.values?.header, 'IMAGE') ? (
										<>
											<Typography variant='body1'>Header*</Typography>
											<div className={classes.media}>
												{!_.isEmpty(mediaUuid) ? (
													<div
														style={{
															minWidth: 120,
															height: 120,
															position: 'relative',
															overflow: 'hidden',
															backgroundImage: `url(${mediaUuid?.filePath})`,
															backgroundSize: 'cover',
															backgroundPosition: 'center',
														}}
														onClick={() => handleClickOpen(mediaUuid, 'image')}
													>
														<IconButton
															onClick={() => removeMediaUuid()}
															aria-label='Remove Image'
															size='small'
															sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
														>
															<HighlightOffIcon />
														</IconButton>
													</div>
												) : (
													<>
														<input
															type='file'
															accept='image/png,image/jpeg,image/jpg'
															style={{ display: 'none' }}
															ref={fileInputRef}
															onChange={handleFileChange}
														/>
														<Avatar sx={{ bgcolor: green[500], cursor: 'pointer' }} onClick={handleAvatarClick} variant='rounded'>
															<AddAPhotoIcon />
														</Avatar>
														<Typography variant='body1'>
															File types: PNG, JPG, JPEG <br /> Maximum file size: 2 MB
														</Typography>
													</>
												)}
											</div>
										</>
									) : _.isEqual(formik?.values?.header, 'VIDEO') ? (
										<>
											<Typography variant='body1'>Header*</Typography>
											<div className={classes.media}>
												{!_.isEmpty(mediaUuid) ? (
													<List sx={{ bgcolor: 'background.paper', position: 'relative', cursor: 'pointer' }}>
														<ListItem onClick={() => handleClickOpen(mediaUuid, 'video')}>
															<ListItemAvatar>
																<Avatar>
																	<VideoLibraryIcon />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary={mediaUuid?.documentName} />
														</ListItem>
														<IconButton
															onClick={removeMediaUuid}
															aria-label='Remove Image'
															size='small'
															sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
														>
															<HighlightOffIcon />
														</IconButton>
													</List>
												) : (
													<>
														<input type='file' accept='video/mp4' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
														<Avatar sx={{ bgcolor: green[500], cursor: 'pointer' }} onClick={handleAvatarClick} variant='rounded'>
															<OndemandVideoIcon />
														</Avatar>
														<Typography variant='body1'>
															File types: mp4 <br /> Maximum file size: 2 MB
														</Typography>
													</>
												)}
											</div>
										</>
									) : _.isEqual(formik?.values?.header, 'DOCUMENT') ? (
										<>
											<Typography variant='body1'>Header*</Typography>
											<div className={classes.media}>
												{!_.isEmpty(mediaUuid) ? (
													<List sx={{ bgcolor: 'background.paper', position: 'relative', cursor: 'pointer' }}>
														<ListItem onClick={() => handleClickOpen(mediaUuid, 'document')}>
															<ListItemAvatar>
																<Avatar>
																	<PictureAsPdfIcon />
																</Avatar>
															</ListItemAvatar>
															<ListItemText primary={mediaUuid?.documentName} />
														</ListItem>
														<IconButton
															onClick={removeMediaUuid}
															aria-label='Remove Image'
															size='small'
															sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
														>
															<HighlightOffIcon />
														</IconButton>
													</List>
												) : (
													<>
														<input
															name='docFile'
															id='selectFile'
															type='file'
															style={{ display: 'none' }}
															accept='application/pdf'
															ref={fileInputRef}
															onChange={handleFileChange}
														/>
														<Avatar sx={{ bgcolor: green[500], cursor: 'pointer' }} onClick={handleAvatarClick} variant='rounded'>
															<PictureAsPdfIcon />
														</Avatar>
														<Typography variant='body1'>
															File types: pdf <br /> Maximum file size: 2 MB
														</Typography>
													</>
												)}
											</div>
										</>
									) : null)}
							</div>
							{/* WHATSAPP MESSAGE PREVIEW AREA */}
							<div
								style={{
									marginTop: 12,
									backgroundImage: `url({${process.env.REACT_APP_IMAGEKIT_URL}}/B2B_Flash/Consult/WhatsApp_Background.png})`,
									backgroundSize: 'cover',
									backgroundPosition: 'intial',
									overflow: 'hidden',
									borderRadius: 4,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									// height: 'calc(100vh - 289px)',
								}}
							>
								<div style={{ padding: 14, background: '#2DBD96' }}>
									<Typography style={{ color: '#fff' }}>Preview</Typography>
								</div>
								<div style={{ margin: '12px 12px 18px 90px', padding: 14, borderRadius: '12px 12px 0px 12px', background: '#fff', minHeight: 300 }}>
									<Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
										{replacedHeaderContent ? replacedHeaderContent : ''}{' '}
										{replacedHeaderContent && (
											<>
												<br /> <br />
											</>
										)}{' '}
										{replacedContent} <br /> <br /> {formik?.values?.footer}
									</Typography>
								</div>
							</div>
							{!_.isEmpty(formik?.values?.footer) && (
								<div className='mt-12'>
									<Typography variant='body1'>Footer</Typography>
									<TextField
										id='footer'
										name='footer'
										multiline
										fullWidth
										variant='outlined'
										rows={3}
										color='secondary'
										value={formik?.values?.footer}
										inputProps={{ readOnly: true }}
										error={formik.errors.footer && formik.touched.footer}
										helperText={formik.errors.footer && formik.touched.footer && formik.errors.footer}
									/>
								</div>
							)}
						</Grid>
					</Grid>
				</div>
			</CustomDialog>
			<ViewModal fileSpecificData={imageDetails} type={type} viewOpen={openView} setViewOpen={setOpenView} token={BearerToken} />
		</div>
	)
}
