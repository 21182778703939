import { ACTION_TYPES } from '../../../constants'

const snackbarReducer = (
	state = {
		showSnackbar: false,
		snackbarText: '',
		severity: 'info',
		onClose: false,
	},
	action
) => {
	switch (action.type) {
		case ACTION_TYPES.SHOW_SNACKBAR: {
			return {
				...state,
				showSnackbar: true,
				snackbarText: action.payload.msg,
				severity: action.payload.severity,
				onClose: action.payload.onClose,
			}
		}
		case ACTION_TYPES.HIDE_SNACKBAR: {
			return {
				...state,
				showSnackbar: false,
			}
		}
		default:
			return state
	}
}

export default snackbarReducer
