import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const currentEventDetails = secureLocalStorage.getItem('currentEvent')

const initialState = {
	currentEvent: currentEventDetails || {},
	savedEvent: '',
	isEditEvent: false,
}

const eventAction = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.CURRENT_EVENT_LIST: {
			return {
				...state,
				currentEvent: action.payload,
			}
		}
		case ACTION_TYPES.SAVED_EVENT: {
			return {
				...state,
				savedEvent: action.payload,
			}
		}
		case ACTION_TYPES.IS_EDIT_EVENT: {
			return {
				...state,
				isEditEvent: action.payload,
			}
		}
		default:
			return state
	}
}

export default eventAction
