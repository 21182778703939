import { useState, useEffect, useRef } from 'react'
import {
	Grid,
	Stack,
	Avatar,
	Typography,
	Card,
	TextField,
	InputAdornment,
	CardContent,
	Dialog,
	Button,
	IconButton,
	DialogContent,
	Skeleton,
	DialogTitle,
	Menu,
	MenuItem,
	CircularProgress,
} from '@mui/material'
import { useStyles } from './style'
import secureLocalStorage from 'react-secure-storage'
import whatsAppLogo from '../../../../../images/logos/whatsappConsult.png'
import LyfngoLogo from '../../../../../images/logos/lyfngoLogo.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import startChat from 'images/consult/startchat.png'

import {
	SentimentSatisfiedAlt,
	AttachFile,
	Send,
	DownloadForOfflineOutlined,
	CallOutlined,
	CallEnd,
	PhoneMissed,
	MissedVideoCall,
	InsertDriveFileRounded,
	VideocamOutlined,
	CheckCircle,
	Close,
	MoreVertOutlined,
} from '@mui/icons-material'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import consultApi from '../../../../../services/consult/api'
// import 'emoji-mart/css/emoji-mart.css'
// import { Picker } from 'emoji-mart'
import { AttachmentModal } from './AttachmentModal'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { v4 as uuidv4 } from 'uuid'
import { getResponseMessage } from 'utils'

const ChatArea = (props) => {
	const {
		data = {},
		handleChange,
		chatMessage,
		setChatMessage,
		activeCustDetails = {},
		loading = false,
		clearChatAreaMessage = () => {},
		isCircularLoading = false,
		setIsCircularLoading = () => {},
		tenantDetail = {},
		// indexVal,
		// setIndexVal,
		setHeaderText,
		isMobileDevice,
		setCurrentChatPatient,
		setCurrentPatientMediaOpen,
		handleTenantMediaChatDetails,
	} = props
	let BearerToken = secureLocalStorage.getItem('AidiBearer')
	const dispatch = useDispatch()

	const classes = useStyles()
	let [chatData, setChatData] = useState(data)
	let [showPicker, setShowPicker] = useState(false)
	const [txtLoading, setTxtLoading] = useState(false)

	let avatarText = `U`
	const [fileDetails, setFileDetails] = useState({
		showFileModal: false,
		docFile: [],
		galleryFile: [],
		photoFile: [],
		showCamera: false,
	})
	const anchorRef = useRef(null)
	const photoRef = useRef(null)
	const stripRef = useRef(null)
	const videoRef = useRef(null)
	const [reload, setReload] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const errorJson = useSelector((states) => states?.errorMessages)

	let tentUserUuid = secureLocalStorage.getItem('uuid')
	let tentMastUuid = secureLocalStorage.getItem('mastuuid')

	let appointmentUuid = activeCustDetails && activeCustDetails?.appointmentUuid

	let chatCallDisabled = activeCustDetails?.lastMessage?.isWhatspp || activeCustDetails.appointmentUuid === '' || loading

	useEffect(() => {
		if (data && document.getElementById('msgChatBot')) {
			const element = document.getElementById('msgChatBot')
			element.scrollTop = element.scrollHeight
			setChatData(data)
		}
	}, [data])

	useEffect(() => {
		if (chatData && document.getElementById('msgChatBot')) {
			const element = document.getElementById('msgChatBot')
			element.scrollTop = element.scrollHeight
			setChatData(chatData)
		}
	}, [chatData])

	const handleSendTextMessage = () => {
		let temp = {
			chatId: '',
			isShowTenant: true,
			isMessageSendByCustomer: false,
			messageType: 'text',
			text: { chattingMessage: chatMessage, messageSendedProfilePic: tenantDetail?.profilePic },
		}
		chatData.push(temp)
		setChatData(chatData)
		const body = {
			appointmentUuid: appointmentUuid,
			chattingMessage: chatMessage,
			isMessageSendByCustomer: false,
			isShowCustomer: true,
			isShowTenant: true,
			isSystemMessage: false,
		}

		setTxtLoading(true)
		if (chatMessage !== '') {
			setTxtLoading(true)
			consultApi
				.consultMessageSaveText(body)
				.then((resp) => {
					setTxtLoading(false)
				})
				.catch((err) => {
					setTxtLoading(false)
					dispatch(
						showMessage({
							message: err?.response?.data?.message,
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'error',
						})
					)
				})
		}
		setChatMessage('')
	}
	// const handleSendEmojiMessage = (emojiUnifiedCode) => {
	// 	setShowPicker(!showPicker)
	// 	let temp = {
	// 		chatId: '',
	// 		isShowTenant: true,
	// 		isemojiSendByCustomer: false,
	// 		messageType: 'emoji',
	// 		emoji: { emojis: emojiUnifiedCode, isEmojiSendedProfilePic: tenantDetail?.profilePic },
	// 	}
	// 	chatData.push(temp)
	// 	setChatData(chatData)
	// 	const body = {
	// 		appointmentUuid: appointmentUuid,
	// 		emojiMessage: `${emojiUnifiedCode}`,
	// 		isemojiSendByCustomer: false,
	// 		isShowCustomer: true,
	// 		isShowTenant: true,
	// 		isSystemMessage: false,
	// 	}
	// 	setTxtLoading(true)
	// 	if (emojiUnifiedCode) {
	// 		setTxtLoading(true)
	// 		consultApi
	// 			.consultMessageSaveEmoji(body)
	// 			.then((resp) => {
	// 				setTxtLoading(false)
	// 			})
	// 			.catch((err) => {
	// 				setTxtLoading(false)
	// 				dispatch(
	// 					showMessage({
	// 						message: err?.response?.data?.error,
	// 						autoHideDuration: 3000,
	// 						anchorOrigin: {
	// 							vertical: 'top',
	// 							horizontal: 'right',
	// 						},
	// 						variant: 'error',
	// 					})
	// 				)
	// 			})
	// 	}
	// }

	const handleSendFileMessage = (uuid, data) => {
		setChatData((y) => {
			let temp = _.cloneDeep(y)
			_.map(data, (x, i) => {
				let fileType = ''
				if (x.documentExtension === 'jpg' || x.documentExtension === 'jpeg' || x.documentExtension === 'png') {
					fileType = 'IMAGE'
				} else if (x.documentExtension === 'pdf') {
					fileType = 'DOCUMENT'
				} else if (x.documentExtension === 'mp4') {
					fileType = 'VIDEO'
				}
				temp.push({
					isShowTenant: true,
					messageType: 'file',
					file: {
						chatId: '',
						documentUuid: uuid[i],
						documentName: x.documentName,
						isFileUploadCustomer: false,
						fileSize: x.fileSize,
						viewFileUrl: x.filePath,
						fileType: fileType,
						fileUploadedProfilePic: tenantDetail?.profilePic,
					},
				})
			})
			return temp
		})
		const body = {
			appointmentUuid: appointmentUuid,
			docDriveUuid: uuid,
			isFileUploadCustomer: false,
		}
		setTxtLoading(true)
		consultApi
			.consultMessageSaveFile(body)
			.then((resp) => {
				setTxtLoading(false)
			})
			.catch((err) => {
				setTxtLoading(false)
				dispatch(
					showMessage({
						message: err?.response?.data?.error,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			})
	}

	const handleUploadingFileToTenant = (files) => {
		setFileDetails({ ...fileDetails, showFileModal: false, showCamera: false })
		// stopWebCam()
		let consultUuid = typeof window !== 'undefined' && secureLocalStorage.getItem('consultUuid')
		let formData = new FormData()
		_.map(files, (file) => {
			formData.append('FILE', file)
		})
		setTxtLoading(true)
		let uuidArr = []
		consultApi
			.uploadingFileToTenant(formData, tentMastUuid, tentUserUuid, consultUuid)
			.then((resp) => {
				setTxtLoading(false)
				if (resp?.data?.status?.toLowerCase() === 'success') {
					_.map(resp?.data?.data?.fileMetaDataList, (x) => uuidArr.push(x.uuid))
					handleSendFileMessage(uuidArr, resp?.data?.data?.fileMetaDataList)
				}
			})
			.catch((err) => {
				setTxtLoading(false)
				dispatch(
					showMessage({
						message: err?.response?.data?.message,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			})
	}

	const customerInitiateMeeting = (conferenceType) => {
		setIsCircularLoading(true)
		const body = {
			appointmentUuid: appointmentUuid,
			conferenceType: conferenceType,
			callInitiateCustomer: false,
		}
		consultApi
			.consultMeetingInitiate(body)
			.then((resp) => {
				setIsCircularLoading(false)
			})
			.catch((err) => {
				dispatch(
					showMessage({
						message: getResponseMessage(err?.response?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			})
	}

	const handleAttachmentClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setFileDetails({ ...fileDetails, showFileModal: false })
	}

	// const handleDelete = (idx) => {
	// 	const list = cloneDeep(fileDetails.docFile)
	// 	list.splice(idx, 1)
	// 	setFileDetails({ ...fileDetails, docFile: list })
	// }

	const takePhoto = () => {
		let photo = photoRef.current
		let strip = stripRef.current
		const data = photo.toDataURL('image/jpeg')
		const link = document.createElement('a')
		link.href = data
		link.id = 'anchorId'
		link.setAttribute('download', 'myWebcam')
		link.innerHTML = `<img src='${data}' alt='thumbnail' id="imageId"/>`
		strip.insertBefore(link, strip.firstChild)
		setReload(!reload)
	}

	useEffect(() => {
		!chatCallDisabled && getVideo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoRef, fileDetails.showCamera, chatCallDisabled])

	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 400 } })
			.then((stream) => {
				let video = videoRef.current
				if (fileDetails.showCamera && stream !== null) {
					video.srcObject = stream
					video.play()
				} else {
					let streamData = video.srcObject
					const tracks = streamData.getTracks()
					tracks.forEach((track) => track.stop())
					streamData.video.srcObject = null
					streamData.getTracks().forEach(function (track) {
						track.stop()
					})
				}
			})
			.catch((err) => {
				console.error('error:', err)
			})
	}

	const paintToCanvas = () => {
		let video = videoRef.current
		let photo = photoRef.current
		let ctx = photo.getContext('2d')

		const width = 400
		const height = 280
		photo.width = width
		photo.height = height

		return setInterval(() => {
			ctx.drawImage(video, 0, 0, width, height)
		}, 200)
	}

	const cancelPhoto = (data) => {
		// stripRef.current = <div></div>;
		// photoRef.current = <div></div>;
		// videoRef.current = null;
		setReload(!reload)
		const link = document.getElementById('anchorId')
		const image = document.getElementById('imageId')
		link !== null && link.remove()
		image !== null && image.remove()
		// data === 'back' && getVideo()
	}

	// identify link from text message

	const urlify = (text) => {
		const urlRegex = /(\b(https?|ftp|file|www):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
		const finalGreeting = text.replace(urlRegex, (url) => {
			return '<a class=' + classes.textLinkView + ' target="_blank" href="' + url + '">' + url + '</a>'
		})
		return <span style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: finalGreeting }} />
	}

	const handleOnKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSendTextMessage(chatMessage)
		}
	}

	const checkItem = () => {
		const data = document.getElementById('imageId')
		return data === null
	}

	function blobCreationFromURL(str) {
		let pos = str.indexOf(';base64,')
		let type = str.substring(5, pos)
		let b64 = str.substr(pos + 8)
		let imageContent = atob(b64)
		let buffer = new ArrayBuffer(imageContent.length)
		let view = new Uint8Array(buffer)
		for (var n = 0; n < imageContent.length; n++) {
			view[n] = imageContent.charCodeAt(n)
		}
		let blob = new Blob([buffer], { type: type })
		return blob
	}

	const sendCapturedPhoto = async () => {
		const imageId = document.getElementById('imageId')
		const srcFile = imageId.getAttribute('src')
		const list = []
		const file = blobCreationFromURL(srcFile)

		let fileName = new Date().getTime() + `.${file.type.split('/')[1]}`
		let filedata = new File([file], uuidv4() + '___' + fileName, {
			type: file.type,
			lastModified: Date.now(),
		})

		list.push(filedata)
		handleUploadingFileToTenant(list)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			{/* <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackObj?.showSnack}
                onClose={() => {setSnackObj({...snackObj, showSnack: !snackObj.showSnack})}}
                message={snackObj?.snackMessage}
            /> */}
			<Card style={{ height: '100%', borderRadius: isMobileDevice ? '0px' : '10px' }}>
				{!_.isEmpty(activeCustDetails) ? (
					<CardContent style={{ padding: '0px' }} className={classes.chatArea_CardContent}>
						{/* Header */}
						<Grid className={clsx('', isMobileDevice ? classes.mobile_topHeader : classes.topHeader)} xs={12}>
							<Stack direction='row' spacing={isMobileDevice ? 1 : 2}>
								{loading ? (
									<>
										<Skeleton variant='circular' height={50} style={{ alignSelf: 'center' }}>
											<Avatar style={{ width: '50px' }} />
										</Skeleton>
										<Skeleton variant='rectangular' width={200} height={30} style={{ background: '#F5F4F6', borderRadius: '8px 8px 8px 0px' }} />
									</>
								) : (
									<>
										{activeCustDetails.appointmentUuid !== '' ? (
											<>
												<div
													className={classes.mobile_back}
													onClick={() => {
														setCurrentChatPatient(null)
													}}
												>
													<ArrowBackIcon style={{ color: '#839192' }} />
												</div>
												{/* <StyledBadge
                                                    color={activeCustDetails?.customerResponseVO?.custStatus === true ? "success" : "warning"}
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    variant="dot"
                                                > */}
												<div className={classes.patientProfileImg}>
													<Avatar alt={avatarText} src={activeCustDetails?.customerResponseVO?.profilePic} />
												</div>
												{/* </StyledBadge> */}
												<Typography className={classes.chatArea_name}>
													{activeCustDetails?.customerResponseVO?.custName || activeCustDetails?.customerResponseVO?.custUuid}
												</Typography>
												{activeCustDetails?.lastMessage?.isWhatspp === true ? (
													<img style={{ width: 20, alignSelf: 'center', textAlign: 'center' }} src={whatsAppLogo} alt='w-logo' />
												) : (
													<CheckCircle style={{ fontSize: '12px', marginBottom: '16px', color: '#0062DD' }} />
												)}
											</>
										) : (
											<>
												{/* <Avatar alt={avatarText} src="assets/images/logos/aidivaaIcon.svg" alt="logo"/> */}
												<div
													className={classes.mobile_back}
													onClick={() => {
														setCurrentChatPatient(null)
													}}
												>
													<ArrowBackIcon style={{ color: '#839192' }} />
												</div>
												<div className={classes.aidivaBg2}>
													<img className='w-50' style={{ margin: '8px', width: '50%' }} src={LyfngoLogo} alt='logo' />
												</div>
												<Typography className={classes.chatArea_name}>
													LYFnGO
													<CheckCircle style={{ fontSize: '12px', marginBottom: '16px', color: '#0062DD' }} />
												</Typography>
											</>
										)}
									</>
								)}
							</Stack>
							<Stack direction='row' spacing={isMobileDevice ? 2 : 3}>
								{isMobileDevice && (
									<>
										<div
											className={classes.mobileCallIcon}
											onClick={activeCustDetails.appointmentUuid !== '' && !chatCallDisabled ? () => customerInitiateMeeting('audio') : () => {}}
										>
											<CallOutlined style={{ fontSize: 28, color: chatCallDisabled ? '#AFBBC6' : '#2EB2FF' }} />
										</div>
										<div
											className={classes.mobileCallIcon}
											onClick={activeCustDetails.appointmentUuid !== '' && !chatCallDisabled ? () => customerInitiateMeeting('video') : () => {}}
										>
											<VideocamOutlined style={{ fontSize: 28, marginLeft: 6, color: chatCallDisabled ? '#AFBBC6' : '#2EB2FF' }} />
										</div>
									</>
								)}
								{!isMobileDevice && (
									<Button
										variant='contained'
										className={clsx(classes.buttonBgCss, { [classes.disabledCss]: activeCustDetails.appointmentUuid === '' || loading })}
										onClick={activeCustDetails.appointmentUuid === '' ? () => {} : () => customerInitiateMeeting('audio')}
										disabled={chatCallDisabled}
									>
										<CallOutlined className={classes.font20} />
										<span className={classes.btnTxtCss}> Voice chat </span>
									</Button>
								)}
								{!isMobileDevice && (
									<Button
										variant='contained'
										className={classes.buttonBgCss}
										onClick={activeCustDetails.appointmentUuid === '' ? () => {} : () => customerInitiateMeeting('video')}
										disabled={chatCallDisabled}
									>
										<VideocamOutlined className={classes.font20} />
										<span className={classes.btnTxtCss}> Video call </span>
									</Button>
								)}
								<>
									<IconButton className={clsx(classes.IconBtnSq)} aria-controls='fade-menu' aria-haspopup='true' onClick={handleClick}>
										<MoreVertOutlined />
									</IconButton>
									<Menu
										id='fade-menu'
										getContentAnchorEl={null}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										transformOrigin={{
											vertical: 'left',
											horizontal: 'center',
										}}
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleClose}
									>
										<MenuItem
											className={classes.menuListAction}
											onClick={() => {
												handleClose()
												clearChatAreaMessage(appointmentUuid)
											}}
										>
											Delete Conversation
										</MenuItem>

										{isMobileDevice && (
											<MenuItem
												className={classes.menuListAction}
												onClick={() => {
													setCurrentPatientMediaOpen(true)
													handleClose()
													handleTenantMediaChatDetails(activeCustDetails)
													setHeaderText('Chat Details')
												}}
											>
												Chat Details
											</MenuItem>
										)}
										{!isMobileDevice && (
											<MenuItem
												className={classes.menuListAction}
												onClick={() => {
													setCurrentPatientMediaOpen(true)
													handleClose()
													handleTenantMediaChatDetails(activeCustDetails)
													setHeaderText('Chat Details')
												}}
											>
												Chat Details
											</MenuItem>
										)}
										{/* <MenuItem className={classes.menuListAction}>LYFnGO help</MenuItem> */}
									</Menu>
								</>
							</Stack>
						</Grid>

						{/* Message area */}
						<Grid className={clsx(classes.msg_area)} id='msgChatBot'>
							{loading ? (
								<>
									<Grid container direction='column'>
										{_.times(5, (x) => {
											if (x % 2 === 0) {
												return (
													<div style={{ display: 'flex' }}>
														<Skeleton variant='circular' height={50} className={classes.chatAreaSkeletonCircle}>
															<Avatar />
														</Skeleton>
														<Skeleton variant='rectangular' width={320} height={50} className={classes.chatAreaSkeletonRectangle} />
													</div>
												)
											} else {
												return (
													<div style={{ display: 'flex', justifyContent: 'end' }}>
														<Skeleton variant='rectangular' width={320} height={50} className={classes.chatAreaSkeletonRectangle} />
														<Skeleton variant='circular' height={50} className={classes.chatAreaSkeletonCircle}>
															<Avatar />
														</Skeleton>
													</div>
												)
											}
										})}
									</Grid>
								</>
							) : (
								<>
									{isCircularLoading ? (
										<CircularProgress style={{ bottom: '50%', left: '50%', position: 'absolute', color: 'red', zIndex: 999 }} />
									) : (
										chatData &&
										_.map(_.cloneDeep(chatData), (o, i) => {
											let callDuration = {}
											if (o?.messageType === 'endcall') {
												let startTime = moment(o?.endedCallDTO?.callStartTime).format('DD/MM/YYYY HH:mm:ss')
												let endTime = moment(o?.endedCallDTO?.callEndTime).format('DD/MM/YYYY HH:mm:ss')
												callDuration = moment.duration(moment(endTime, 'DD/MM/YYYY HH:mm:ss').diff(moment(startTime, 'DD/MM/YYYY HH:mm:ss')))
											}
											if (o?.isShowTenant) {
												return (
													<>
														{o.messageType === 'text' ? (
															!o?.text?.isSystemMsg ? (
																<Grid
																	direction='row'
																	className={clsx(classes.msgWrap, { [classes.rightMsg_wrap]: !o?.text?.isMessageSendByCustomer })}
																>
																	<Avatar
																		style={{ fontSize: '14px', alignSelf: 'end', marginBottom: '32px' }}
																		alt={avatarText}
																		src={o?.text?.messageSendedProfilePic}
																	></Avatar>
																	<Grid direction='column' spacing={1} style={{ margin: '4px 8px' }}>
																		<Typography
																			key={i}
																			className={clsx('', {
																				[classes.rightMessageContent]: !o?.text?.isMessageSendByCustomer,
																				[classes.leftMessageContent]: o?.text?.isMessageSendByCustomer,
																			})}
																		>
																			{urlify(o?.text?.chattingMessage)}
																		</Typography>
																		<Typography
																			className={clsx(classes.timeDisplayFont, { [classes.floatRight]: !o?.text?.isMessageSendByCustomer })}
																		>
																			{o.currentDateTime && moment(o.currentDateTime).format('hh:mm A')}
																		</Typography>
																	</Grid>
																</Grid>
															) : (
																<Grid container direction='row' style={{ margin: '10px 0px 0px 0px', justifyContent: 'center' }}>
																	<div className={classes.sysMsg}>
																		<Typography className={classes.missedCallTxt}>{o?.text?.chattingMessage}</Typography>
																	</div>
																</Grid>
															)
														) : o.messageType === 'emoji' ? (
															<Grid direction='row' className={clsx(classes.msgWrap, { [classes.rightMsg_wrap]: !o?.emoji?.isEmojiSendByCustomer })}>
																<Avatar
																	style={{ fontSize: '14px', alignSelf: 'end', marginBottom: '32px' }}
																	alt={avatarText}
																	src={o?.emoji?.isEmojiSendedProfilePic}
																></Avatar>
																<Grid direction='column' spacing={1} style={{ margin: '4px 8px' }}>
																	<Typography
																		className={clsx(classes.emojiFont, {
																			[classes.rightMessageContent]: !o?.emoji?.isEmojiSendByCustomer,
																			[classes.leftMessageContent]: o?.emoji?.isEmojiSendByCustomer,
																		})}
																	>
																		{String.fromCodePoint(parseInt(o?.emoji?.emojis, 16))}
																	</Typography>
																	<Typography className={clsx(classes.timeDisplayFont, { [classes.floatRight]: !o?.emoji?.isEmojiSendByCustomer })}>
																		{o.currentDateTime && moment(o.currentDateTime).format('hh:mm A')}
																	</Typography>
																</Grid>
															</Grid>
														) : o.messageType === 'file' ? (
															<Grid
																direction='row'
																className={clsx(classes.msgWrap, { [classes.rightMsg_wrap]: !o?.file?.isFileUploadedByCustomer })}
															>
																<Avatar
																	style={{ fontSize: '14px', alignSelf: 'end', marginBottom: '35px' }}
																	src={o?.file?.fileUploadedProfilePic}
																></Avatar>
																<Grid direction='column' spacing={1} style={{ margin: '4px 8px' }}>
																	{o?.file?.fileType === 'IMAGE' ? (
																		<Typography
																			className={clsx('', {
																				[classes.rightMessageImageContent]: !o?.file?.isFileUploadedByCustomer,
																				[classes.leftMessageImageContent]: o?.file?.isFileUploadedByCustomer,
																			})}
																		>
																			{!o?.file?.isFileUploadedByCustomer ? (
																				<img style={{ borderRadius: 6, height: '100%' }} src={o?.file?.viewFileUrl} width={90} alt='file' />
																			) : (
																				<a href={consultApi.getFileDownloadUrl(o?.file?.documentUuid, BearerToken)} download>
																					<img style={{ borderRadius: 6, height: '100%' }} src={o?.file?.viewFileUrl} width={90} alt='file' />
																				</a>
																			)}
																		</Typography>
																	) : o?.file?.fileType === 'DOCUMENT' || o?.file?.documentExtension === 'pdf' ? (
																		<Typography
																			className={clsx('', {
																				[classes.rightMessageDocumentContent]: !o?.file?.isFileUploadedByCustomer,
																				[classes.leftMessageDocumentContent]: o?.file?.isFileUploadedByCustomer,
																			})}
																		>
																			<InsertDriveFileRounded style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }} />
																			{o?.file?.isFileUploadedByCustomer ? (
																				<a
																					href={consultApi.getFileDownloadUrl(o?.file?.documentUuid, BearerToken)}
																					className={classes.videoLinkDoc}
																					target='_blank'
																					download
																					rel='noreferrer'
																					style={{ display: 'flex' }}
																				>
																					<Typography
																						style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }}
																						className={classes.documentText}
																					>
																						{o?.file?.documentName}
																					</Typography>
																					<DownloadForOfflineOutlined style={{ color: '#7047EA', cursor: 'pointer', marginLeft: '30px' }} />
																				</a>
																			) : (
																				<Typography
																					style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }}
																					className={classes.documentText}
																				>
																					{o?.file?.documentName}
																				</Typography>
																			)}
																		</Typography>
																	) : o?.file?.fileType === 'VIDEO' ? (
																		<Typography
																			className={clsx('', {
																				[classes.rightMessageDocumentContent]: !o?.file?.isFileUploadedByCustomer,
																				[classes.leftMessageDocumentContent]: o?.file?.isFileUploadedByCustomer,
																			})}
																		>
																			{/* <video href={consultApi.getFileDownloadUrl(o?.file?.documentUuid, BearerToken)} src={o?.file?.viewFileUrl} type="video/mp4" /> */}
																			<InsertDriveFileRounded style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }} />
																			{o?.file?.isFileUploadedByCustomer ? (
																				<a
																					href={consultApi.getFileDownloadUrl(o?.file?.documentUuid, BearerToken)}
																					download
																					className={classes.videoLinkDoc}
																					style={{ display: 'flex' }}
																				>
																					<Typography
																						style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }}
																						className={classes.documentText}
																					>
																						{o?.file?.documentName}
																					</Typography>
																					<DownloadForOfflineOutlined style={{ color: '#7047EA', cursor: 'pointer', marginLeft: '30px' }} />
																				</a>
																			) : (
																				<Typography
																					style={{ color: o?.file?.isFileUploadedByCustomer ? 'gray' : '#FFFF' }}
																					className={classes.documentText}
																				>
																					{o?.file?.documentName}
																				</Typography>
																			)}
																		</Typography>
																	) : (
																		<></>
																	)}
																	<Typography
																		className={clsx(classes.timeStorageDisplayFont, { [classes.reverseDisplay]: !o?.file?.isFileUploadedByCustomer })}
																	>
																		<span className={classes.timeDisplayFont}>
																			{o.currentDateTime && moment(o.currentDateTime).format('hh:mm A')}
																		</span>
																		<span className={classes.timeDisplayFont}>{o?.file?.storage}</span>
																	</Typography>
																</Grid>
															</Grid>
														) : o.messageType === 'declinecall' ? (
															<Grid container direction='row' style={{ marginBottom: '5px' }}>
																<div className={classes.callMessageType}>
																	{!o?.missedCall?.isCallDeclinedByCustomer ? (
																		<>
																			<CallEnd style={{ color: '#e80404', fontSize: '16px', margin: '1px 10px 0px 0px' }} />
																			<Typography className={classes.missedCallTxt}>{`Call Declined at ${moment(o.currentDateTime).format(
																				'hh:mm A'
																			)}`}</Typography>
																		</>
																	) : (
																		<>
																			{o?.missedCall?.callType === 'audio' ? (
																				<>
																					<PhoneMissed
																						style={{
																							color: !o?.missedCall?.isCallDeclinedByCustomer ? 'green' : '#e80404',
																							fontSize: '16px',
																							margin: '1px 10px 0px 0px',
																						}}
																					/>
																					<Typography className={classes.missedCallTxt}>{`Missed voice call at ${moment(o.currentDateTime).format(
																						'hh:mm A'
																					)}`}</Typography>
																				</>
																			) : (
																				<>
																					<MissedVideoCall
																						style={{
																							color: !o?.missedCall?.isCallDeclinedByCustomer ? 'green' : '#e80404',
																							fontSize: '22px',
																							margin: '-1px 10px 0px 0px',
																						}}
																					/>
																					<Typography className={classes.missedCallTxt}>{`Missed video call at ${moment(o.currentDateTime).format(
																						'hh:mm A'
																					)}`}</Typography>
																				</>
																			)}
																		</>
																	)}
																</div>
															</Grid>
														) : o.messageType === 'endcall' ? (
															<Grid container direction='row' style={{ marginBottom: '5px' }}>
																<div className={classes.callMessageType}>
																	<>
																		<PhoneCallbackIcon style={{ color: '#332FB3', fontSize: '16px', margin: '2px 2px 0px 0px' }} />
																		<Typography style={{ margin: '2px 10px 0px 10px', fontSize: '12px' }} className={classes.missedCallTxt}>{`${
																			o?.endedCallDTO?.isCallInitiatorByCustomer ? `Incoming call ` : `Outgoing call`
																		} ${callDuration.minutes()} min ${callDuration.seconds()} sec ${moment(o.currentDateTime).format(
																			'[at] hh:mm A'
																		)}`}</Typography>
																	</>
																</div>
															</Grid>
														) : (
															<></>
														)}
													</>
												)
											}
										})
									)}
									{_.isEmpty(chatData) && (
										<Typography style={{ position: 'relative', top: '50%', textAlign: 'center', fontSize: 24, color: '#0062DD' }}>
											Send <b>"Hi"</b> to start the Conversation
										</Typography>
									)}
								</>
							)}
						</Grid>

						{/* Footer */}
						<Grid className={classes.footer}>
							<TextField
								id='type-message'
								variant='outlined'
								placeholder='Enter Your message here....'
								onChange={(e) => {
									handleChange(e)
								}}
								onKeyPress={(e) => {
									chatMessage === '' && e.charCode === 32 ? e.preventDefault() : setChatMessage(e.target.value)
								}}
								className={classes.searchBox}
								disabled={txtLoading || isCircularLoading}
								value={chatMessage}
								autoFocus
								autoComplete='off'
								type='text'
								// onBlur={onInputBlur}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end' className={classes.inputLayer}>
											<IconButton
												disabled={activeCustDetails.appointmentUuid === ''}
												onClick={activeCustDetails.appointmentUuid === '' ? () => {} : () => setShowPicker(!showPicker)}
											>
												<SentimentSatisfiedAlt className={clsx(classes.sentimentIcon, showPicker && classes.activeIcon)} />
											</IconButton>
											{/* {showPicker && (
												<Picker
													onSelect={(emoji) => {
														handleSendEmojiMessage(emoji.unified)
													}}
													style={{ width: '100%', whiteSpace: 'pre-wrap' }}
												/>
											)} */}
											<IconButton
												disabled={activeCustDetails.appointmentUuid === ''}
												style={{ marginRight: '15px' }}
												onClick={
													activeCustDetails.appointmentUuid === ''
														? () => {}
														: () => {
																setFileDetails({ ...fileDetails, showFileModal: true })
														  }
												}
											>
												<AttachFile className={classes.attachFileIcon} ref={anchorRef} />
											</IconButton>
											<IconButton
												className={clsx(
													classes.sendIconLayer,
													(chatMessage === '' || activeCustDetails.appointmentUuid === '') && classes.disabledSendIcon
												)}
												onClick={chatMessage === '' || activeCustDetails.appointmentUuid === '' ? () => {} : () => handleSendTextMessage(chatMessage)}
											>
												<Send className={classes.navigationOutlinedIcon} />
											</IconButton>
										</InputAdornment>
									),
								}}
								onKeyDown={handleOnKeyDown}
							/>
						</Grid>
					</CardContent>
				) : (
					<>
						<Grid xs={12} md={12} lg={12} className={classes.noContentWrap}>
							<img className={classes.screenDash} src={startChat} alt='chat' />
							<div className={classes.noContentMsg}>
								{' '}
								You will see a new messages once the customer starts a conversation. <br /> Click on any chat to view previous messages or continue a
								conversation.
							</div>
						</Grid>
					</>
				)}
			</Card>
			<AttachmentModal
				setFileDetails={setFileDetails}
				fileDetails={fileDetails}
				anchorRef={anchorRef}
				handleAttachmentClose={handleAttachmentClose}
				handleUploadingFileToTenant={handleUploadingFileToTenant}
			/>
			{fileDetails.showCamera && (
				<Dialog
					fullWidth
					maxWidth='sm'
					open={fileDetails.showCamera}
					aria-labelledby='Loader Popup'
					className={classes.dialogLayer}
					onClose={() => {
						setFileDetails({ ...fileDetails, showCamera: false })
						cancelPhoto()
					}}
				>
					<DialogTitle sx={{ m: 0, p: 2 }} className={classes.photoLayerHeader}>
						Take Photo
						<IconButton
							aria-label='close'
							onClick={() => {
								setFileDetails({ ...fileDetails, showCamera: false })
								cancelPhoto()
							}}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Grid direction='column' justifyContent='center' container alignItems='center'>
							<Grid item style={{ paddingTop: '10px' }} lg={12}>
								{checkItem() && <video onCanPlay={() => paintToCanvas()} ref={videoRef} />}
								<canvas ref={photoRef} style={{ display: 'none' }} />
								<div ref={stripRef} />
							</Grid>
							<Grid item lg={12} style={{ marginTop: '10px' }}>
								{checkItem() ? (
									<Button
										onClick={() => {
											takePhoto()
											videoRef.current = null
										}}
										variant='contained'
										className={classes.btnStyle}
									>
										Capture
									</Button>
								) : (
									<Grid container direction='row' alignItems='center'>
										<Button
											variant='outlined'
											style={{ color: 'red' }}
											onClick={() => {
												cancelPhoto('back')
											}}
											className={classes.cancelBtn}
										>
											Cancel
										</Button>
										<Button
											onClick={() => {
												sendCapturedPhoto()
											}}
											variant='contained'
											className={classes.btnStyle}
										>
											Send
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			)}
		</>
	)
}
export default ChatArea
