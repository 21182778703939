import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { Typography, Autocomplete, TextField, Grid, MenuItem, FormControl, Select, InputLabel, OutlinedInput } from '@mui/material'
import LookupApi from '../../../../../../src/services/lookup/api'
import ProductsAPI from 'services/BackOffice/Products/api'
import ReportOptions from '../../../../../services/Reports/FilterOptions/api'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from '../../../../../services/loader/action'
import _ from 'lodash'
import { decryption, failureLogin, getResponseMessage } from 'utils'
import MembershipApi from 'services/Settings/Membership/api'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#f6f7f9',
		[theme.breakpoints.up('xs')]: {
			padding: 14,
		},
		[theme.breakpoints.up('md')]: {
			padding: 20,
		},
		'& .MuiTypography-subtitle1': {
			color: 'rgba(99, 99, 99, 0.9)',
		},
	},
	container: {
		width: '100%',
		paddingBlock: 18,

		'& .MuiAutocomplete-popper': {
			zIndex: 40,
		},

		'& .MuiFormControl-root': {
			background: '#ffffff',
			'& .Mui-focused': {
				color: 'inherit',
			},
		},
	},
}))

function Filters(props) {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {
		report,
		category,
		pageQuery,
		mastuuid,
		resetTriggred,
		onQueryChange,
		getAppliedFilters,
		uuid,
		isDoctor,
		isCare,
		setdoctorsList,
		practicelist,
		selectedProducts,
		setSelectedProducts,
		selectedPractice,
		setSelectedPractice,
		allAccessTypeUser,
		userRole,
		selectableOptions,
		updateOptions,
		selectedFilters,
		updateSelectedFilters,
	} = props

	const erroJson = useSelector((states) => states?.errorMessages)
	const [doctor, setDoctor] = useState([])

	const [distributor, setDistributor] = useState([])
	const [bloodGroup, setBloodGroup] = useState([])
	const [referredBy, setReferredBy] = useState([])
	const [patientGroup, setPatientGroup] = useState([])
	const [procedureCategories, setProcedureCategories] = useState([])
	const [paymentVendor, setPaymentVendor] = useState([])
	const [taxs, setTaxs] = useState([])
	const [commissionTier, setcommissionTier] = useState([])
	const [paymentsOnType, setPaymentsOnType] = useState('Billed date')
	const [StatusValue, setStatusValue] = useState(null)
	const [QuickSaleValue, setQuickSaleValue] = useState([])

	useEffect(() => {
		getAppliedFilters({
			doctor: doctor,
			bloodGroup: bloodGroup,
			referredBy: referredBy,
			patientGroup: patientGroup,
			procedureCategories: procedureCategories,
			paymentVendor: paymentVendor,
			taxs: taxs,
			show: 'all',
			discount: pageQuery?.discount,
			cancelled: pageQuery?.cancelled,
			distributor: distributor,
			commissionTier: commissionTier,
			selectedPractice,
			selectedProducts: selectedProducts,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		bloodGroup,
		doctor,
		pageQuery?.discount,
		pageQuery?.cancelled,
		patientGroup,
		paymentVendor,
		procedureCategories,
		referredBy,
		taxs,
		distributor,
		commissionTier,
		selectedPractice,
		selectedProducts,
	])

	useEffect(() => {
		if (resetTriggred) {
			allAccessTypeUser && setDoctor([])
			setBloodGroup([])
			setReferredBy([])
			setPatientGroup([])
			setProcedureCategories([])
			setPaymentVendor([])
			setTaxs([])
			setcommissionTier([])
			!(userRole === 'Distributor' || userRole === 'Agent') && setDistributor([])
			updateSelectedFilters({
				type: 'reset',
			})
		}
	}, [allAccessTypeUser, resetTriggred, updateSelectedFilters, userRole])

	// Loading Bloodgroup and Referred options
	useEffect(() => {
		LookupApi.getLookup('getLookup', { mastLookupType: 'BGP' }).then(
			(res) =>
				updateOptions({
					type: 'setBloodGroupOptions',
					payload: res?.data?.data,
				}),
			() =>
				updateOptions({
					type: 'setBloodGroupOptions',
					payload: [],
				})
		)
		LookupApi.getLookup('getLookup', { mastLookupType: 'RFL' }).then(
			(res) =>
				updateOptions({
					type: 'setReferredByOptions',
					payload: res?.data?.data,
				}),
			() =>
				updateOptions({
					type: 'setReferredByOptions',
					payload: [],
				})
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const filterDoctorOptions = (specilaistList) => {
		if (allAccessTypeUser) {
			updateOptions({
				type: 'setDoctorOptions',
				payload: specilaistList,
			})
			setdoctorsList(specilaistList)
		} else {
			let foundUser = specilaistList.find((o) => o.tentUserUuid === uuid)
			if (!_.isEmpty(foundUser)) {
				updateOptions({
					type: 'setDoctorOptions',
					payload: [foundUser],
				})
				setdoctorsList([foundUser])
				// pre-populating in the Select Specilaist field
				let tempArr = [foundUser].map((item) => item.tentUserUuid)
				onQueryChange({ ...pageQuery, doctor: tempArr.toString() })
				setDoctor([foundUser])
			}
		}
	}

	const filterDistributorOptions = (distributorList) => {
		if (userRole === 'Distributor' || userRole === 'Agent') {
			let foundDistributor = distributorList.find((o) => o.tentUserUuid === uuid)
			if (!_.isEmpty(foundDistributor)) {
				updateOptions({
					type: 'setDistributorOptions',
					payload: [foundDistributor],
				})
				// pre-populating in the Select Distributor field
				let tempArr = [foundDistributor].map((item) => item.tentUserUuid)
				onQueryChange({ ...pageQuery, distributor: tempArr.toString() })
				setDistributor([foundDistributor])
			}
		} else {
			updateOptions({
				type: 'setDistributorOptions',
				payload: distributorList,
			})
		}
	}

	// Loading filter options:
	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success') {
				dispatch(hideLoader())
				filterDoctorOptions(successData?.data?.users?.tentUserRoleBasedDtoList)
				filterDistributorOptions(successData?.data?.users?.distributorList)
				updateOptions({
					type: 'setTaxOptions',
					payload: successData?.data?.taxs,
				})
				updateOptions({
					type: 'setPaymentvendorOptions',
					payload: successData?.data?.paymentModes,
				})
				updateOptions({
					type: 'setCommissionTypeOptions',
					payload: successData?.data?.commission,
				})
				updateOptions({
					type: 'setPatientGroupOptions',
					payload: successData?.data?.patientGroup,
				})
				updateOptions({
					type: 'setProcedureCategoriesOptions',
					payload: successData?.data?.Procedures,
				})
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, erroJson), //text or html
						autoHideDuration: 3000, //ms
						anchorOrigin: {
							vertical: 'top', //top bottom
							horizontal: 'right', //left center right
						},
						variant: 'error', //success error info warning null
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, erroJson), //text or html
					autoHideDuration: 3000, //ms
					anchorOrigin: {
						vertical: 'top', //top bottom
						horizontal: 'right', //left center right
					},
					variant: 'error', //success error info warning null
				})
			)
		}
		ReportOptions.getFilterOptions(mastuuid).then(onSuccess, onFailure)

		// Products List
		ProductsAPI.getProductsList(mastuuid).then(
			(res) => {
				updateOptions({
					type: 'setProductOptions',
					payload: res?.data?.data,
				})
			},
			() => {
				updateOptions({
					type: 'setProductOptions',
					payload: [],
				})
			}
		)

		// Membership List
		MembershipApi.getMembershipListForSettings(mastuuid).then(
			(res) =>
				updateOptions({
					type: 'setMembershipOptions',
					payload: res?.data?.data?.getTentMembership,
				}),
			() =>
				updateOptions({
					type: 'setMembershipOptions',
					payload: [],
				})
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, erroJson, mastuuid])

	useEffect(() => {
		if (category === 'quickSale') {
			onQueryChange({ ...pageQuery, procedureCategories: [].toString() })
			setProcedureCategories([])
		} else if (category === 'procedure' || category === 'pharmaSales') {
			onQueryChange({ ...pageQuery, quickSales: [].toString() })
			setQuickSaleValue([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category])

	useEffect(() => {
		if (_.isEmpty(StatusValue)) {
			let paymentOption = selectableOptions?.paymentStatusOptions?.find((item) => item?.uuid === pageQuery?.status)
			setStatusValue(paymentOption)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classes.root}>
			<Typography variant='subtitle1'>Filters</Typography>
			<div className={classes.container}>
				<Grid container spacing={2}>
					{/* Doctor */}
					{(report === 'Appointments' || report === 'InvoicedDetail') && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								id='selectDoctors'
								name='selectDoctors'
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.doctorOptions) || !allAccessTypeUser}
								options={selectableOptions?.doctorOptions}
								getOptionLabel={(option) =>
									`${option?.tentUserSalutation ? option?.tentUserSalutation + '.' : ''} ${option?.tentUserFirstName}` || ''
								}
								value={doctor}
								filterSelectedOptions
								disableCloseOnSelect={true}
								disableClearable={isDoctor}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.tentUserUuid)
									onQueryChange({ ...pageQuery, doctor: tempArr.toString() })
									setDoctor(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={'Select specialist'} />}
							/>
						</Grid>
					)}

					{/* Referrer */}
					{(report === 'NewPatients' || report === 'InvoicedDetail') && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.referredByOptions)}
								id='referredBy'
								name='referredBy'
								options={selectableOptions?.referredByOptions}
								getOptionLabel={(option) => option?.mastLookupValue || ''}
								value={referredBy}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.mastLookupValue)
									onQueryChange({ ...pageQuery, referredBy: tempArr.toString() })
									setReferredBy(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Select referrer' />}
							/>
						</Grid>
					)}

					{/* Discount */}
					{report === 'InvoicedDetail' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl sx={{ width: '100%' }}>
								<InputLabel id='discounts'>Select Discount</InputLabel>
								<Select
									id='selectdiscount'
									name='selectdiscount'
									value={pageQuery?.discount}
									labelId='discounts'
									size='small'
									color='secondary'
									onChange={(event) => {
										onQueryChange({ ...pageQuery, discount: event.target.value })
									}}
									input={<OutlinedInput label='Select discount' />}
								>
									<MenuItem value={'all'}>All</MenuItem>
									<MenuItem value={'isZero'}>Is Zero</MenuItem>
									<MenuItem value={'moreThanZero'}>More than Zero</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}

					{/* Patient Groups */}
					{(report === 'NewPatients' || report === 'InvoicedDetail' || _.isEqual(report, 'Distributor')) && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.patientGroupOptions)}
								id='selectPatientGroups'
								name='selectPatientGroups'
								options={selectableOptions?.patientGroupOptions}
								getOptionLabel={(option) => option?.tentGroupName || ''}
								value={patientGroup}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.uuid)
									onQueryChange({ ...pageQuery, patientGroup: tempArr.toString() })
									setPatientGroup(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={isCare ? 'Patient groups' : 'Client groups'} />}
							/>
						</Grid>
					)}

					{/* Membership */}
					{report === 'Membership' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.membershipOptions)}
								id='selectMembership'
								name='selectMembership'
								options={selectableOptions?.membershipOptions}
								getOptionLabel={(option) => option?.membershipName || ''}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								value={selectedFilters.membership}
								onChange={(e, value) => {
									updateSelectedFilters({
										type: 'setMembership',
										payload: value,
									})
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Select membership' />}
							/>
						</Grid>
					)}

					{/* Procedure Categories */}
					{report === 'InvoicedIncome' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.procedureCategoriesOptions)}
								id='selectProcedureCategories'
								name='selectProcedureCategories'
								options={selectableOptions?.procedureCategoriesOptions}
								getOptionLabel={(option) => option?.tentProcedureCatalogName || ''}
								value={procedureCategories}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.uuid)
									onQueryChange({ ...pageQuery, procedureCategories: tempArr.toString() })
									setProcedureCategories(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={isCare ? 'Procedure categories' : 'Procedure'} />}
							/>
						</Grid>
					)}

					{/* Blood Group */}
					{report === 'NewPatients' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								disabled={_.isEmpty(selectableOptions?.bloodGroupOptions)}
								id='bloodGroup'
								name='bloodGroup'
								disablePortal={true}
								options={selectableOptions?.bloodGroupOptions}
								getOptionLabel={(option) => option?.mastLookupValue || ''}
								value={bloodGroup}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									onQueryChange({ ...pageQuery, bloodGroup: value?.mastLookupValue })
									setBloodGroup(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Blood group' />}
							/>
						</Grid>
					)}

					{/* Payment Vendor */}
					{(report === 'InvoicedDetail' || report === 'Membership') && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.paymentVendorOptions)}
								id='selectPaymentVendor'
								name='selectPaymentVendor'
								options={selectableOptions?.paymentVendorOptions}
								getOptionLabel={(option) => option?.modeName || ''}
								value={paymentVendor}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.uuid)
									onQueryChange({ ...pageQuery, paymentVendor: tempArr.toString() })
									setPaymentVendor(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Select payment vendors' />}
							/>
						</Grid>
					)}

					{/* Taxes */}
					{report === 'InvoicedDetail' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.taxOptions)}
								id='selectTaxes'
								name='selectTaxes'
								options={selectableOptions?.taxOptions}
								getOptionLabel={(option) => option?.taxName || ''}
								value={taxs}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.taxUuid)
									onQueryChange({ ...pageQuery, taxes: tempArr.toString() })
									setTaxs(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Taxes' />}
							/>
						</Grid>
					)}

					{/* Cancelled Payments */}
					{report === 'Appointments' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<FormControl sx={{ width: '100%' }}>
								<InputLabel id='cancelledPayments'>Cancelled</InputLabel>
								<Select
									id='cancelledPayments'
									name='cancelledPayments'
									labelId='cancelledPayments'
									value={pageQuery.cancelled}
									size='small'
									color='secondary'
									onChange={(event) => {
										onQueryChange({ ...pageQuery, cancelled: event.target.value })
									}}
									input={<OutlinedInput label='Cancelled' />}
								>
									<MenuItem value={false}>No</MenuItem>
									<MenuItem value={true}>Yes</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}

					{/* Procedure Categories */}
					{report === 'InvoicedDetail' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.procedureCategoriesOptions) || category === 'quickSale'}
								id='selectProcedureCategories'
								name='selectProcedureCategories'
								options={selectableOptions?.procedureCategoriesOptions}
								getOptionLabel={(option) => option?.tentProcedureCatalogName || ''}
								value={procedureCategories}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.uuid)
									onQueryChange({ ...pageQuery, procedureCategories: tempArr.toString() })
									setProcedureCategories(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={isCare ? 'Procedure categories' : 'Procedure'} />}
							/>
						</Grid>
					)}

					{/* Quick sales' */}
					{report === 'InvoicedDetail' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='quickSales'
								name='quickSales'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.quickSaleOptions) || category === 'procedure' || category === 'pharmaSales'}
								options={selectableOptions?.quickSaleOptions}
								value={QuickSaleValue}
								getOptionLabel={(option) => option?.tentSaleCatalogName || ''}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.uuid)
									onQueryChange({ ...pageQuery, quickSales: tempArr.toString() })
									setQuickSaleValue(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Quick sales' />}
							/>
						</Grid>
					)}

					{/* Status */}
					{(report === 'InvoicedDetail' || report === 'Membership' || _.isEqual(report, 'Distributor')) && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='status'
								name='status'
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.paymentStatusOptions)}
								options={selectableOptions?.paymentStatusOptions}
								getOptionLabel={(option) => option?.tentStatusCatalogName || ''}
								value={StatusValue}
								disableCloseOnSelect={true}
								disableClearable
								onChange={(e, value) => {
									onQueryChange({ ...pageQuery, status: value?.uuid })
									setStatusValue(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Payment status' />}
							/>
						</Grid>
					)}

					{/* Membership */}
					{report === 'Membership' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.membershipStatusOptions)}
								options={selectableOptions?.membershipStatusOptions}
								getOptionLabel={(option) => option?.label || ''}
								value={selectedFilters.membershipStatus}
								filterSelectedOptions={true}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									updateSelectedFilters({
										type: 'setMembershipStatus',
										payload: value,
									})
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Membership status' />}
							/>
						</Grid>
					)}

					{/* Payments on */}
					{report === 'InvoicedDetail' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='status'
								name='status'
								disablePortal={true}
								options={selectableOptions?.paymentsOnOptions}
								getOptionLabel={(option) => option || ''}
								value={paymentsOnType}
								disableClearable
								onChange={(e, value) => {
									onQueryChange({ ...pageQuery, paymentsOnType: value })
									setPaymentsOnType(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Payments on' />}
							/>
						</Grid>
					)}

					{/* select Distributor  */}
					{report === 'Distributor' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								multiple
								id='selectDistributor'
								name='selectDoctors'
								disablePortal={true}
								disabled={_.isEmpty(selectableOptions?.distributorOptions) || userRole === 'Distributor' || userRole === 'Agent'}
								options={selectableOptions?.distributorOptions}
								getOptionLabel={(option) =>
									`${option?.tentUserSalutation ? option?.tentUserSalutation + '.' : ''} ${option?.tentUserFirstName}` || ''
								}
								value={distributor}
								filterSelectedOptions
								disableCloseOnSelect={true}
								disableClearable={isDoctor}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.tentUserUuid)
									onQueryChange({ ...pageQuery, distributor: tempArr.toString() })
									setDistributor(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={'Select distributor / agent'} />}
							/>
						</Grid>
					)}

					{/* Commission value  */}
					{report === 'Distributor' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='tierLevel'
								name='tierLevel'
								multiple
								disablePortal={true}
								filterSelectedOptions
								disabled={_.isEmpty(selectableOptions?.commissionTypeOptions)}
								options={selectableOptions?.commissionTypeOptions}
								getOptionLabel={(option) => option?.commissionName}
								value={commissionTier}
								disableCloseOnSelect={true}
								onChange={(e, value) => {
									let tempArr = value.map((item) => item.commissionUuid)
									onQueryChange({ ...pageQuery, tierLevel: tempArr.toString() })
									setcommissionTier(value)
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label={'Commission value'} />}
							/>
						</Grid>
					)}

					{/* Practice  */}
					{report === 'Distributor' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='practice'
								name='practice'
								multiple
								disablePortal={true}
								filterSelectedOptions
								disabled={_.isEmpty(practicelist)}
								options={practicelist}
								getOptionLabel={(option) => option?.practiceName}
								value={selectedPractice}
								disableCloseOnSelect={true}
								onChange={(e, value) => setSelectedPractice(value)}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Practice' />}
							/>
						</Grid>
					)}

					{/*Products  */}
					{report === 'Distributor' && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								id='products'
								name='products'
								multiple
								disablePortal={true}
								filterSelectedOptions
								disabled={_.isEmpty(selectableOptions?.productOptions)}
								options={selectableOptions?.productOptions}
								getOptionLabel={(option) => option?.name}
								value={selectedProducts}
								disableCloseOnSelect={true}
								onChange={(e, value) => setSelectedProducts(value)}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Products' />}
							/>
						</Grid>
					)}

					{/* Cancelled Payments */}
					{(report === 'Membership' || report === 'Distributor' || report === 'InvoicedDetail') && (
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<Autocomplete
								size='small'
								disablePortal={true}
								disableClearable
								disabled={_.isEmpty(selectableOptions?.paymentCancelledOptions)}
								options={selectableOptions?.paymentCancelledOptions}
								getOptionLabel={(option) => option?.label}
								value={selectedFilters.paymentCancelled}
								onChange={(e, value) => {
									updateSelectedFilters({
										type: 'setCancelledPayments',
										payload: value,
									})
								}}
								renderInput={(params) => <TextField {...params} fullWidth color='secondary' label='Cancelled payments' />}
							/>
						</Grid>
					)}
				</Grid>
			</div>
		</div>
	)
}
export default Filters
