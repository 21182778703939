import React, { useCallback, useEffect, useState } from 'react'
import Header from './Header'
import { Avatar, Button, Grid, IconButton, Rating, Skeleton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import themesConfig from 'app/fuse-configs/themesConfig'
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useDispatch, useSelector } from 'react-redux'
import feedBackApi from '../../../../services/Feedback/api'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getImgUrl, getResponseMessage } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import { setFeedbackFilter } from 'services/Feedback/actions'
import FilterDrawer from 'app/sharedComponents/FilterDrawer'
import Filter from './filter'
import { closePrompt, showPrompt } from 'services/Prompt/actions'
import InfiniteScroll from 'react-infinite-scroll-component'

const useStyles = makeStyles((theme) => ({
	activeTotal: {
		'& .MuiButton-root': {
			color: '#0062DD',
			width: '150px',
			border: '1px solid #0062DD',
			borderRadius: '6px',
			background: '#EFF6FF',
		},
	},
	activeCheckIn: {
		'& .MuiButton-root': {
			color: '#69B701',
			width: '150px',
			border: '1px solid #69B701',
			borderRadius: '6px',
			background: '#F2FFE0',
		},
	},
	buttonTotal: {
		'& .MuiButton-root': {
			color: '#0062DD',
			width: '150px',
			border: '1px solid #B1AAAA',
			borderRadius: '6px',
			'&:hover': {
				background: '#EFF6FF',
			},
		},
	},
	buttonCheckIn: {
		'& .MuiButton-root': {
			color: '#69B701',
			width: '150px',
			border: '1px solid #B1AAAA',
			borderRadius: '6px',
		},
		'&:hover': {
			background: '#F2FFE0',
		},
	},
	cardMain: {
		border: '1px solid #E5E5E5',
		padding: 12,
		borderRadius: '10px',
		marginBottom: 10,
		marginInline: 10,
		boxShadow: '0px 0px 3px 3px #EDEDED',
	},
	cardMainContainer: {
		// paddingBlock: 60,
	},
	AvatarText: {
		background: '#2EB2FF',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
		width: '40px',
		height: '40px',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: 18,
			fontWeight: 600,
			color: '#fff',
		},
	},
	AvatarText1: {
		background: themesConfig.greyDark.palette.primary.main,
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
		width: '35px',
		height: '35px',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: 15,
			fontWeight: 600,
			color: '#fff',
		},
	},
	wrapper: {
		display: 'flex',
		gap: '12px',
	},
	wrapper1: {
		display: 'flex',
		gap: '12px',
		alignItems: 'center',
	},
	recommand: {
		display: 'flex',
		gap: 4,
		alignItems: 'center',
		marginTop: '2px',
		'& .MuiTypography-root': {
			fontSize: 12,
			fontWeight: 600,
		},
		'& .MuiRating-icon': {
			fontSize: 14,
		},
	},
	recommand1: {
		display: 'flex',
		gap: 4,
		alignItems: 'center',
		justifyContent: 'space-between',
		'& .MuiTypography-root': {
			fontSize: 12,
			fontWeight: 500,
		},
		'& .MuiRating-icon': {
			fontSize: 14,
		},
		'& .MuiButton-root': {
			background: '#6EBB09',
			color: '#fff',
			borderRadius: '16px',
		},
	},
	nameHead: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		'& .MuiTypography-root': {
			fontSize: 12,
			fontWeight: 600,
		},
	},
	textandId: {
		display: 'flex',
		gap: 2,
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 500,
		},
	},
	actionButton: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
	},
	noData: {
		height: '68vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconButton: {
		border: '1px solid #DF0707',
		padding: 0,
		'& .MuiSvgIcon-root': {
			fontSize: 25,
			color: '#DF0707',
			padding: 4,
			'&:hover': {
				color: '#fff',
			},
		},
		'&:hover': {
			background: '#DF0707',
		},
	},
	fixedSubHeaderContainer: {
		position: 'sticky',
		zIndex: 999,
		background: '#fff',
		padding: 12,
	},
}))
const Feedback = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { mastuuid, BearerToken } = useSelector((state) => state.Authentication)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const [feedbackType, setfeedbackType] = useState('all')
	const [feedbackData, setFeedbackData] = useState([])
	const [feedbackCounts, setFeedbacCounts] = useState({})
	const [pageNo, setPageNo] = useState(0)
	const [hasMore, setHasMore] = useState(false)
	const [loading, setLoading] = useState(false)
	const [drawerCLick, setDrawerClick] = useState(false)
	const feedbackFilter = useSelector((state) => state?.Feedback?.FeedbackFilter)
	const [ratingData, setRatingData] = useState([
		{ id: 1, value: 1, lable: '1-star', isCheck: false },
		{ id: 2, value: 2, lable: '2-star', isCheck: false },
		{ id: 3, value: 3, lable: '3-star', isCheck: false },
		{ id: 4, value: 4, lable: '4-star', isCheck: false },
		{ id: 5, value: 5, lable: '5-star', isCheck: false },
	])
	const [recommentData, setRecommandData] = useState('')
	const clearClose = () => {
		setRecommandData('')
		setRatingData([
			{ id: 1, value: 1, lable: '1-star', isCheck: false },
			{ id: 2, value: 2, lable: '2-star', isCheck: false },
			{ id: 3, value: 3, lable: '3-star', isCheck: false },
			{ id: 4, value: 4, lable: '4-star', isCheck: false },
			{ id: 5, value: 5, lable: '5-star', isCheck: false },
		])
		setDrawerClick(false)
		dispatch(setFeedbackFilter(!feedbackFilter))
		setPageNo(0)
		window.scrollTo(0, 0)
	}
	const handleChange = (event) => {
		setRecommandData(event.target.value)
		setPageNo(0)
		window.scrollTo(0, 0)
	}
	const toggleSessionTypeClick = (item) => {
		let ratingType = []
		_.map(ratingData, (type, idx) => {
			if (type?.value === item?.value) {
				ratingType.push({
					...type,
					isCheck: !type?.isCheck,
				})
			} else {
				ratingType.push(type)
			}
		})
		setRatingData(ratingType)
		setPageNo(0)
		window.scrollTo(0, 0)
	}
	const filteredData = ratingData.filter((item) => item.isCheck === true)
	const finalValue = filteredData.map((item, idx) => {
		return item?.value
	})
	//drawer
	const setFeedbackFilters = () => {
		setDrawerClick(true)
		dispatch(setFeedbackFilter(!feedbackFilter))
	}
	function customDate(data) {
		const dateString = data
		const parsedDate = moment(dateString)
		const formattedDate = parsedDate.format('Do MMMM YYYY,')
		return formattedDate
	}
	function customTime(data) {
		const dateString = data
		const parsedDate = moment(dateString)
		const formattedDate = parsedDate.format('hh:mmA')
		return formattedDate
	}

	const fetchMoreData = () => {
		getfeedback(true)
	}

	const getfeedback = useCallback(
		(data) => {
			setLoading(true)
			const onSuccess = (res) => {
				setLoading(false)
				setFeedbacCounts(res?.data?.data)
				data === true ? setFeedbackData((prevData) => [...prevData, ...res?.data?.data?.data]) : setFeedbackData(res?.data?.data?.data)
				res?.data?.data?.pagination?.current_page === res?.data?.data?.pagination?.total_pages ? setHasMore(false) : setHasMore(true)
				data === 'trigger' ? setPageNo(0) : setPageNo((prev) => prev + 1)
				data === 'trigger' && window.scrollTo(0, 0)
			}
			const onFailure = (res) => {
				setLoading(false)
				setFeedbackData([])
			}

			feedBackApi
				.getfeedbackDetails(mastuuid, {
					isrecommend: recommentData === 'yes' ? true : recommentData === 'no' ? false : '',
					ratings: finalValue ? finalValue.join(',') : '',
					list: feedbackType,
					limit: 5,
					offset: data === 'trigger' ? 0 : pageNo,
				})
				.then(onSuccess, onFailure)
		},
		[feedbackType, finalValue, mastuuid, pageNo, recommentData]
	)
	const deleteUser = (item) => {
		dispatch(
			showPrompt(
				`Are you sure want to remove this feedback from microsite ?`,
				'Yes',
				'No',
				() => deleteFeedback(item),
				() => dispatch(closePrompt())
			)
		)
	}
	//delete
	const deleteFeedback = (data) => {
		dispatch(closePrompt())
		dispatch(showLoader('Loading please wait ...'))
		let body = {
			tent_uuid: mastuuid,
			feedback_uuid: data,
		}
		const onSuccess = (res) => {
			getfeedback('trigger')
			dispatch(hideLoader(''))
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		const onFailure = (err) => {
			dispatch(hideLoader(''))
			dispatch(
				showMessage({
					message: getResponseMessage(err, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		feedBackApi.deleteFeedbackDetails(body).then(onSuccess, onFailure)
	}
	//post
	const postFeedback = (data) => {
		dispatch(showLoader('Loading please wait ...'))
		let body = {
			tent_uuid: mastuuid,
			feedback_uuid: data,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader(''))

			getfeedback('trigger')
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		const onFailure = (err) => {
			dispatch(hideLoader(''))
			dispatch(
				showMessage({
					message: getResponseMessage(err, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		feedBackApi.postFeedbackDetails(body).then(onSuccess, onFailure)
	}
	useEffect(() => {
		if (feedbackType) {
			getfeedback()
		}
		// eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
	}, [mastuuid, recommentData, feedbackType, ratingData])

	return (
		<div style={{ height: '100%', background: '#fff' }}>
			<Header setFeedbackFilters={setFeedbackFilters} feedbackFilter={feedbackFilter} />
			<Grid container xs={12} padding={2} className={classes?.fixedSubHeaderContainer}>
				<Grid item className={feedbackType === 'all' ? classes.activeTotal : classes.buttonTotal}>
					<Button
						variant='outlined'
						onClick={() => {
							setfeedbackType('all')
							setPageNo(0)
							window.scrollTo(0, 0)
						}}
					>
						All {feedbackCounts?.total_reviews || 0}
					</Button>
				</Grid>
				<Grid item marginLeft={2} className={feedbackType === 'microsite' ? classes.activeCheckIn : classes.buttonCheckIn}>
					<Button
						variant='outlined'
						onClick={() => {
							setfeedbackType('microsite')
							setPageNo(0)
							window.scrollTo(0, 0)
						}}
					>
						In Microsite {feedbackCounts?.total_approved || 0}
					</Button>
				</Grid>
				<Grid item marginLeft={2} className={feedbackType === 'bot' ? classes.activeCheckIn : classes.buttonCheckIn}>
					<Button
						variant='outlined'
						onClick={() => {
							setfeedbackType('bot')
							setPageNo(0)
							window.scrollTo(0, 0)
						}}
					>
						Bot {feedbackCounts?.total_bot_review || 0}
					</Button>
				</Grid>
			</Grid>

			<InfiniteScroll
				dataLength={feedbackData?.length || 0}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={
					<>
						{loading ? (
							<>
								{' '}
								{_.map(Array(6), (itm, idx) => {
									return (
										<div className={classes.cardMain}>
											<div className={classes.wrapper}>
												<div>
													<Skeleton variant='circular' width={40} height={40} />
												</div>
												<div style={{ width: '100%' }}>
													<div className={classes.nameHead}>
														<div className={classes.textandId}>
															<Skeleton variant='rectangular' width={250} height={20} style={{ marginBottom: 6 }} />
														</div>
														<Skeleton variant='rectangular' width={'10%'} height={20} />
													</div>
													<div className={classes.recommand}>
														<Skeleton variant='rectangular' width={'10%'} height={20} style={{ marginBottom: 6 }} />
														<Skeleton variant='rectangular' width={'10%'} height={20} style={{ marginBottom: 6 }} />{' '}
														<Skeleton variant='rectangular' width={'10%'} height={20} style={{ marginBottom: 6 }} />{' '}
													</div>

													<Skeleton variant='rectangular' width={'100%'} height={20} style={{ marginBottom: 6 }} />
													<Skeleton variant='rectangular' width={'60%'} height={20} />
												</div>
											</div>
										</div>
									)
								})}
							</>
						) : (
							<></>
						)}
					</>
				}
			>
				{!_.isEmpty(feedbackData) ? (
					<>
						<div className={classes?.cardMainContainer}>
							{_.map(feedbackData, (item, idx) => {
								return (
									<div className={classes.cardMain}>
										<div className={classes.wrapper}>
											<div>
												{item?.cust_logo_path ? (
													<Avatar src={item?.cust_logo_path} alt='user' />
												) : (
													<div className={classes.AvatarText}>
														<Typography variant='body1'>{item?.cust_name?.charAt(0)}</Typography>
													</div>
												)}
											</div>
											<div style={{ width: '100%' }}>
												<div className={classes.nameHead}>
													<div className={classes.textandId}>
														<Typography>
															{item?.cust_name}
															<span style={{ color: '#727272', fontSize: '14px' }}> ({item?.cust_custom_id})</span>
														</Typography>
													</div>
													<Typography variant='body1'>
														{customDate(item?.created_on)} <span style={{ color: '#727272' }}>{customTime(item?.created_on)}</span>
													</Typography>
												</div>
												<div className={classes.recommand}>
													<Rating name='read-only' value={item?.feedback_star} readOnly />
													{item?.is_recommend && <ThumbUpIcon style={{ fill: '#2EB2FF', width: '13px', height: '13px' }} />}
													{item?.is_recommend && <Typography variant='body1'>Recommended</Typography>}
												</div>

												<Typography
													marginTop={0.5}
													fontSize={12}
													fontWeight={500}
													variant='body1'
													// component='span'
													style={{ whiteSpace: 'pre-wrap' }}
													dangerouslySetInnerHTML={{ __html: item?.feedback_description }}
												/>
											</div>
										</div>

										{!_.isEqual(feedbackType, 'bot') && (
											<div className={classes.wrapper1}>
												<div>
													{item?.tent_logo_uuid !== null ? (
														<Avatar src={getImgUrl(item?.tent_logo_uuid, BearerToken)} alt='tent' style={{ width: 40, height: 40 }} />
													) : (
														<div className={classes.AvatarText1}>
															<Typography variant='body1'>{item?.tent_user_first_name?.charAt(0)}</Typography>
														</div>
													)}
												</div>
												<div style={{ width: '100%' }}>
													<div className={classes.recommand1}>
														<Typography variant='body1'>
															{item?.tent_user_salutation}
															{item?.tent_user_salutation ? '.' : null} {item?.mast_role_name ? ' ' : null}
															{item?.tent_user_first_name}
															<span style={{ color: '#727272' }}> ({item?.mast_role_name})</span>
														</Typography>
														{item?.modified_on ? (
															<div className={classes.actionButton}>
																<Typography variant='body1'>
																	{customDate(item?.modified_on)} <span style={{ color: '#727272' }}>{customTime(item?.modified_on)}</span>
																</Typography>
																<div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
																	<CheckCircleIcon style={{ fill: '#8CC63F', fontSize: 18 }} />
																	<Typography variant='body1'> In Microsite</Typography>
																</div>
																<IconButton
																	className={classes.iconButton}
																	onClick={() => {
																		deleteUser(item?.tent_feedback_uuid)
																	}}
																>
																	<DeleteOutlineIcon />
																</IconButton>
															</div>
														) : (
															<Button
																variant='contained'
																onClick={() => {
																	postFeedback(item?.tent_feedback_uuid)
																}}
															>
																Send to Microsite
															</Button>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
								)
							})}
						</div>
					</>
				) : (
					<div className={classes.noData}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/No_Feedback_Available.svg`}
							alt='no-data'
							style={{ width: 250, height: 250 }}
						/>
					</div>
				)}
			</InfiniteScroll>

			<div style={{ width: feedbackFilter ? 110 : 0 }}>
				<FilterDrawer isOpen={feedbackFilter} toggleDrawer={() => {}} variant='persistent'>
					<Filter
						ratingData={ratingData}
						recommentData={recommentData}
						handleChange={handleChange}
						clearClose={clearClose}
						toggleSessionTypeClick={toggleSessionTypeClick}
					/>
				</FilterDrawer>
			</div>
		</div>
	)
}

export default Feedback
