import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	globalSearch: (mastUuid, params) => {
		return axios.get(API_ENDPOINTS.GLOBAL_SEARCH, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid } },
			params: { ...params },
		})
	},
	advanceSearchOptions: (params) => {
		return axios.get(API_ENDPOINTS.ADVANCED_GLOBAL_SEARCH_OPTIONS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	advancedGlobalSearch: (params) => {
		return axios.get(API_ENDPOINTS.ADVANCED_GLOBAL_SEARCH, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
