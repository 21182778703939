import axios from 'axios'

import { API_ENDPOINTS } from '../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDynamicMenu: (tentMasterUuid, userUuid, params) => {
		return axios.get(API_ENDPOINTS.DYNAMIC_MENU, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentMasterUuid, userUuid } },
			params: { ...params },
		})
	},
}
