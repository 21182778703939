import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	groupUserName: secureLocalStorage.getItem('groupUserName') || '',
	tenentTypeName: secureLocalStorage.getItem('practicelist') || '',
	tentUserId: secureLocalStorage.getItem('profileCurrentTentUserId') || '',
	profileTentuserDetails: secureLocalStorage.getItem('profileCurrentTentUser') || {},
}

const groupUserNameAction = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GROUPUSER_NAME: {
			return {
				...state,
				groupUserName: action.payload.groupUserName,
			}
		}
		case ACTION_TYPES.TENENT_TYPE_NAME: {
			return {
				...state,
				tenentTypeName: action.payload.tenentTypeName,
			}
		}

		case ACTION_TYPES.TENT_USER_ID: {
			return {
				...state,
				tentUserId: action.payload,
			}
		}
		case ACTION_TYPES.INDIVIDUAL_TENT_USER: {
			return {
				...state,
				profileTentuserDetails: action.payload,
			}
		}
		default:
			return state
	}
}

export default groupUserNameAction
