import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getQualificationName: () => {
		return axios.get(API_ENDPOINTS.QUALIFICATION, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	getSpecialisationName: (dataType, params) => {
		return axios.get(API_ENDPOINTS.SPECIALIZATION, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
		})
	},
}
