import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
		padding: 14,
		borderRadius: 10,
	},

	titleContainer: {
		display: 'flex',
		marginBlockEnd: 14,
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},

	title: {
		display: 'flex',
		alignItems: 'baseline',

		'& .MuiTypography-body1 ': {
			color: '#887CE3',
			fontSize: '1.5rem',
			marginLeft: 14,
			fontWeight: 500,
			textDecoration: 'underline',
			cursor: 'pointer',
		},
	},

	buttonContainer: {
		alignSelf: 'flex-end',
		'& .MuiOutlinedInput-root': {
			borderRadius: 10,
		},
		'& .MuiOutlinedInput-input': {
			paddingBlock: 10,
			fontWeight: 500,
		},
	},

	listContainer: {
		// border: '1px solid hotpink',
		maxHeight: 380,
		overflowY: 'scroll',
		[theme.breakpoints.up('md')]: {
			minHeight: 340,
			maxHeight: 340,
		},
	},
	listContainernoData: {
		display: 'flex',
		justifyContent: 'center',
	},
	list: {
		border: '1px solid #CCD1D1 ',
		borderRadius: 8,
		padding: 8,
		marginBlockEnd: 8,
	},
	cardImage: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBlockStart: 2,
		'& .MuiAvatar-square': {
			borderRadius: 4,
		},
	},

	cardDetail: {
		paddingInlineEnd: 6,
		'& .MuiTypography-body1': {
			color: '#718499',
			[theme.breakpoints.up('xs')]: {
				paddingBlockStart: 12,
			},
			[theme.breakpoints.up('md')]: {
				paddingBlockStart: 0,
			},
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
		},
	},

	cardDetailAmount: {
		paddingInlineStart: 10,
		'& .MuiTypography-body1': {
			color: '#718499',
			[theme.breakpoints.up('xs')]: {
				paddingBlockStart: 12,
			},
			[theme.breakpoints.up('md')]: {
				paddingBlockStart: 0,
			},
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
		},
	},

	cardDetailName: {
		'& .MuiTypography-body1': {
			color: '#718499',
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
			textOverflow: 'ellipsis',
		},
	},

	cardNavigate: {
		marginBlockStart: 8,
		[theme.breakpoints.up('xs')]: {
			paddingBlockStart: 12,
		},
		[theme.breakpoints.up('md')]: {
			paddingBlockStart: 0,
		},
		'& .MuiSvgIcon-root': {
			fontSize: 26,
		},
	},

	noAppointments: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiTypography-h6': {
			color: '#718499',
		},
	},
}))
export default useStyles
