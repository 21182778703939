import { React, useState, useEffect } from 'react'
import ChangeMailApi from '../../../services/changeMailId/api'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Typography, useMediaQuery, Button, IconButton } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import ButtonComp from '../../sharedComponents/Button'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { Link } from 'react-router-dom'
import ResetPasswordApi from '../../../services/ResetPassword/api'
import 'react-confirm-alert/src/react-confirm-alert.css'
import resendEmailApi from '../../../services/generateEmailToken/api'
import { encryption, decryption, getResponseMessage, failureLogin } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import OtpInput from 'react-otp-input'
import successEmailApi from '../../../services/successEmail/api'
import { closeVerifyModal } from '../../../services/VerficationModal/actions'
import { updateBearerToken, updateDeviceId, UpdateMail, updateEmail } from 'app/session/actions'
import moment from 'moment'
import loginApi from '../../../services/login/api'
import { detect } from 'detect-browser'
import CloseIcon from '@mui/icons-material/Close'
import secureLocalStorage from 'react-secure-storage'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	modalbox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		background: '#fff',
		border: '1px solid #fff',
		borderRadius: '12px',
		boxShadow: 24,
		paddingBlock: '40px',
		paddingInline: '40px',
		'& .MuiTypography-h6': {
			fontSize: '16px',
		},
		'& .MuiTypography-h5': {
			fontSize: '14px',
		},
		'& .MuiTypography-h4': {
			fontSize: '14px',
		},
		[theme.breakpoints.down('xs')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
		[theme.breakpoints.down('md')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
		[theme.breakpoints.down('sm')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
	},
	emailtext: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
	},
	textField: {
		width: '100%',
		margin: '10px 0px',
	},
	heading: {
		color: '#E22C24',
		fontWeight: 'bold',
		fontFamily: 'Poppins',
	},
	subheading: {
		color: '#707680',
	},
	autocomplete: {
		marginBottom: '18px',
		height: '5rem',
	},
	btn: {
		backgroundColor: '#414A58',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#252F3E',
		},
	},
	Link: {
		color: '#2658DC',
	},

	enabled: {
		textDecoration: 'underline',
		color: themesConfig.greyDark.palette.primary.main,
		cursor: 'pointer',
	},
	disabled: {
		color: '#e0e0e0',
	},
	otpSection: {
		display: 'flex',
		justifyContent: 'center',
	},
}))
export default function EmailVerificationModal({ history }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const browser = detect()
	const state = useSelector((states) => states.Authentication)
	const errorJson = useSelector((states) => states.errorMessages || {})
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
	const { userType, groupId, deviceId, identityUuid } = state
	const verification = useSelector((states) => states?.verificationMail)
	const { open, data, mode } = verification
	const pathName = history?.location?.pathname
	const [shoudFocus, setShouldFocus] = useState(false)
	const [counter, setCounter] = useState(0)
	const [checkColor, setCheckColor] = useState('')
	const [otpNumber, setOtpNumber] = useState('')
	const [deviceData, setDeviceData] = useState({})

	useEffect(() => {
		const onSuccess = (res) => {
			setDeviceData(res?.data)
		}
		const onFailure = (err) => {
			console.log(err)
			setDeviceData({})
		}
		loginApi.getDeviceData().then(onSuccess, onFailure)
	}, [])

	const resendEmail = () => {
		setOtpNumber('')
		setShouldFocus(true)
		setCounter(counter + 1)
		secureLocalStorage.setItem('resendOtpBtn', counter)
		if (counter === 2) {
			setCheckColor(!checkColor)
		} else {
			setCheckColor(checkColor)
		}
		dispatch(showLoader('Loading please wait...'))
		const body = {
			isGenerateEmail: true,
			mastTentGroupUuid: groupId,
			userName: data,
			userType: userType,
			uuid: identityUuid,
			isWelcomeMail: false,
			isForgotPassword: false,
		}
		const changeEmailBody = {
			emailType: 'new',
			isForgotPassword: false,
			isGenerateEmail: false,
			isWelcomeMail: true,
			mastTentGroupUuid: groupId,
			userName: data,
			userType: userType,
			uuid: identityUuid,
		}
		const forgotPasswordBody = {
			userName: data,
			userType: 'TNT',
			isForgotPassword: true,
		}
		let decryptBody = encryption(body)
		let decryptChangeEmail = encryption(changeEmailBody)
		let decryptForgetPassword = encryption(forgotPasswordBody)
		dispatch(hideLoader())
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson, 'OTP sent successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		mode === 'forgotPassword'
			? ResetPasswordApi.setResetPassword(decryptForgetPassword?.plainText, decryptForgetPassword?.publicKey).then(onSuccess, onFailure)
			: mode === 'changeEmail'
			? resendEmailApi.setEmail(decryptChangeEmail?.plainText, decryptChangeEmail?.publicKey).then(onSuccess, onFailure)
			: resendEmailApi.setEmail(decryptBody?.plainText, decryptBody?.publicKey).then(onSuccess, onFailure)
	}
	const updateDeviceDetails = () => {
		dispatch(showLoader('Loading please wait...'))
		const os = browser.os
		const osName = os.split(' ')
		const deviceName = ''
		const body = {
			browserName: browser.name,
			deviceId: '',
			deviceName,
			deviceToken: '',
			ipAddress: deviceData?.ipAddress || '',
			locCity: deviceData?.cityName || '',
			locCountry: deviceData?.countryName || '',
			locRegion: deviceData?.regionName || '',
			loginTime: moment().format(),
			logoutTime: '',
			model: '',
			osName: os,
			osVersion: osName[1],
			qrStatus: false,
			sessionDetails: '',
			status: true,
			version: '',
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				secureLocalStorage.setItem('AidiDeviceId', successData.data.deviceUuid)
				secureLocalStorage.setItem('AidiBearer', successData?.data?.Bearer || '')
				dispatch(updateDeviceId(successData.data.deviceUuid))
				dispatch(updateBearerToken(successData?.data?.Bearer || ''))
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson, 'Email verified successfully'), //text or html
						autoHideDuration: 3000, //ms
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success', //success error info warning null
					})
				)
				history.push('/establishmentselection')
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson), //text or html
						autoHideDuration: 3000, //ms
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error', //success error info warning null
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				dispatch(
					showMessage({
						message: getResponseMessage(failureData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			)
		}
		loginApi.saveActiveDevices(userType, identityUuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const ChangeMail = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			uuid: identityUuid,
			userType: userType,
			email: data,
			deviceUuid: deviceId,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res.data.status === 'success') {
				secureLocalStorage.setItem('aidivaTenantUserEmail', data)
				dispatch(
					UpdateMail({
						emailId: data,
					})
				)
				secureLocalStorage.setItem('userEmail', data)
				dispatch(updateEmail(data))
				secureLocalStorage.setItem('AidiBearer', res?.data?.token || '')
				dispatch(updateBearerToken(res?.data?.token || ''))
				updateDeviceDetails()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err.response.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ChangeMailApi.setChangeMail(body).then(onSuccess, onFailure)
	}
	// useEffect(() => {
	// 	if (open && (isTokenValid === 'false' || false) && isTokenValid !== null) {
	// 		const data = {
	// 			isGenerateEmail: true,
	// 			mastTentGroupUuid: groupId,
	// 			userName: userEmail,
	// 			userType: userType,
	// 		}
	// 		const onSuccess = (res) => {
	// 			dispatch(hideLoader())
	// 			if (res.data.status === 'success') {
	// 				dispatch(
	// 					showMessage({
	// 						message: getResponseMessage(res?.data, errorJson, 'Instruction sent successfully'),
	// 						autoHideDuration: 3000,
	// 						anchorOrigin: {
	// 							vertical: 'top',
	// 							horizontal: 'right',
	// 						},
	// 						variant: 'success',
	// 					})
	// 				)
	// 			} else if ((res.data.status === 'failure')) {
	// 				dispatch(hideLoader())
	// 				dispatch(
	// 					showMessage({
	// 						message: getResponseMessage(res?.data, errorJson),
	// 						autoHideDuration: 3000,
	// 						anchorOrigin: {
	// 							vertical: 'top',
	// 							horizontal: 'right',
	// 						},
	// 						variant: 'error',
	// 					})
	// 				)
	// 			}
	// 		}
	// 		const onFailure = (err) => {
	// 			dispatch(hideLoader())
	// 			dispatch(
	// 				showMessage({
	// 					message: getResponseMessage(err?.response?.data, errorJson),
	// 					autoHideDuration: 3000,
	// 					anchorOrigin: {
	// 						vertical: 'top',
	// 						horizontal: 'right',
	// 					},
	// 					variant: 'error',
	// 				})
	// 			)
	// 		}
	// 		resendEmailApi.setEmail(data).then(onSuccess, onFailure)
	// 	}
	// }, [isTokenValid])
	const handleChange = (value) => setOtpNumber(value)
	const verifyEmailToken = () => {
		dispatch(showLoader('Loading please wait...'))
		let body = {}
		if (_.isEqual(mode, 'forgotPassword')) {
			Object.assign(body, {
				email: data,
				subject: mode,
				userType: 'TNT',
				verificationToken: otpNumber,
				isExpired: counter === 3 ? true : false,
			})
		} else if (_.isEqual(mode, 'changeEmail')) {
			Object.assign(body, {
				email: data,
				subject: 'userVerification',
				uuid: identityUuid,
				userType: userType || 'TNT',
				verificationToken: otpNumber,
				isExpired: counter === 3 ? true : false,
			})
		} else {
			Object.assign(body, {
				email: data,
				subject: mode,
				uuid: identityUuid,
				userType: userType || 'TNT',
				verificationToken: otpNumber,
				isExpired: counter === 3 ? true : false,
			})
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData?.message, errorJson, 'OTP verified successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				if (_.isEqual(mode, 'forgotPassword')) {
					history.push('/resetpassword')
				} else if (_.isEqual(mode, 'changeEmail')) {
					ChangeMail()
				} else {
					history.push('/establishmentselection')
				}
				dispatch(closeVerifyModal())
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
			if (counter === 3) {
				dispatch(closeVerifyModal())
				history.push('/')
				setCounter(0)
			}
			setOtpNumber('')
		}
		successEmailApi.setSuccessMail(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const onClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			dispatch(closeVerifyModal())
			setCounter(0)
			setOtpNumber('')
		}
	}

	useEffect(() => {
		if (open && pathName === '/groupselection') {
			setShouldFocus(true)
		} else if (open && pathName === '/changeemail') {
			setShouldFocus(true)
		} else if (open && pathName === '/forgotpassword') {
			setShouldFocus(true)
		} else if (open && pathName === '/') {
			setShouldFocus(true)
		} else if (open && pathName === '/otp') {
			setShouldFocus(true)
		}
	}, [open, pathName])

	return (
		<>
			<Modal keepMounted open={open} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<IconButton
						aria-label='close'
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
					<img width='350' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Mar-Business_18.png`} alt='email verification pic' />
					<form
						onSubmit={(e) => {
							e.preventDefault()
							verifyEmailToken(e)
						}}
					>
						<div className={classes.emailtext}>
							<Typography id='keep-mounted-modal-title' fontSize={isSmall ? '11px' : '14	px'} variant='body' align='center'>
								OTP has been sent to
							</Typography>
							<Typography className={classes.content3} id='keep-mounted-modal-title' variant='h4' align='center'>
								{data ? <b>{data}</b> : 'your E-mail ID'}
							</Typography>
							<Typography id='keep-mounted-modal-title' fontSize={isSmall ? '11px' : '14px'} variant='body1' align='center'>
								Please check your registered mail
							</Typography>

							{(_.isEqual(mode, 'userVerification') || _.isEqual(mode, 'changeEmail')) && (
								<Typography align='center'>
									<Link
										to='/changeemail'
										style={{ color: themesConfig.greyDark.palette.primary.main }}
										onClick={() => {
											dispatch(closeVerifyModal())
											setCounter(0)
											setOtpNumber('')
										}}
									>
										Change email address
									</Link>
								</Typography>
							)}

							{open && shoudFocus && (
								<div className={classes.otpSection}>
									<OtpInput
										type='password'
										value={otpNumber}
										onChange={handleChange}
										numInputs={4}
										isInputNum={true}
										separator={<span>&nbsp;&nbsp;&nbsp;</span>}
										focusStyle={{
											border: '3px solid #000000',
											outline: 'none',
										}}
										shouldAutoFocus={shoudFocus}
										inputStyle={{
											border: '1px solid #6b7280',
											width: '40px',
											height: '40px',
											fontSize: '12px',
											color: '#000',
										}}
									/>
								</div>
							)}
							<Typography align='center'>
								Don't receive the email?{' '}
								<Button disabled={counter === 3 ? true : false}>
									<span className={counter === 3 ? classes.disabled : classes.enabled} onClick={resendEmail}>
										Resend email
									</span>
								</Button>
							</Typography>
							<ButtonComp type='submit' disabled={otpNumber.length === 4 ? false : true}>
								Verify
							</ButtonComp>
						</div>
					</form>
				</Box>
			</Modal>
		</>
	)
}
