import { Card, Typography, TextField, InputAdornment, Stack, Avatar, Grid, Box, CardContent, Skeleton, Icon } from '@mui/material'
import { useStyles } from './style'
import {
	Search,
	Image,
	InsertDriveFileRounded,
	PhoneMissed,
	MissedVideoCall,
	Call,
	VideoCall,
	CallEnd,
	PhoneCallback,
	CheckCircle,
} from '@mui/icons-material'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import LyfngoLogo from '../../../../../images/logos/lyfngoLogo.png'
import whatsAppLogo from '../../../../../images/logos/whatsappConsult.png'
import secureLocalStorage from 'react-secure-storage'
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded'

const ActiveChats = (props) => {
	const classes = useStyles()
	const {
		data = {},
		setChatMessage,
		totalActiveAppointment = '',
		handleActiveChatDetails = () => {},
		// seenStatus = () => {},
		setActiveCustDetails = () => {},
		activeCustDetails = {},
		loading = false,
		fetchAppointmentList = () => {},
		setpageNum = () => {},
		pageNum = 1,
		// indexVal,
		setIndexVal,
		searchVal,
		setSearchVal,
		tenantSearcher,
		chatData,
		setStatusChatId,

		isMobileDevice,
		isTabletDevice,
		isLargeDevice,
		setCurrentChatPatient,
		handleTenantMediaDetails,
	} = props

	// const data = _.range(20)

	return (
		<>
			{/* <Card className={clsx('pb-8', classes.heightStyle)}> */}
			<Card className={clsx('pb-8', isMobileDevice ? classes.mobile_heightStyle : classes.heightStyle)}>
				<CardContent style={{ padding: '14px 14px 4px 14px' }}>
					<Typography className={classes.HeadingTxt}>Active chats</Typography>
					<TextField
						placeholder='Search People'
						className={classes.searchBox}
						style={{ height: '40px' }}
						value={searchVal}
						onChange={(e, value) => {
							tenantSearcher(e.target.value)
							setSearchVal(e.target.value)
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Search />
								</InputAdornment>
							),
						}}
					/>
				</CardContent>

				<Grid className={clsx(classes.active_scrollWrap)}>
					{loading ? (
						<div style={{ height: isMobileDevice ? '70vh' : '100%' }}>
							<Skeleton variant='rectangular' className={classes.activeChatSkeleton} />
							<Skeleton variant='rectangular' className={classes.activeChatSkeleton} />
							<Skeleton variant='rectangular' className={classes.activeChatSkeleton} />
							<Skeleton variant='rectangular' className={classes.activeChatSkeleton} />
							<Skeleton variant='rectangular' className={classes.activeChatSkeleton} />
						</div>
					) : (
						<div id='scrollableDiv' style={{ height: isMobileDevice ? '70vh' : '443px', overflowY: 'auto' }}>
							<InfiniteScroll
								dataLength={data?.length}
								next={data?.length === totalActiveAppointment.current ? () => {} : () => fetchAppointmentList(false, pageNum + 1)}
								hasMore={data?.length === totalActiveAppointment.current ? false : true}
								// loader={<h4>Loading...</h4>}
								loader={
									<>
										<Skeleton variant='rectangular' className={classes.chatLoadingSkeleton} />
										<Skeleton variant='rectangular' className={classes.chatLoadingSkeleton} />
										<Skeleton variant='rectangular' className={classes.chatLoadingSkeleton} />
									</>
								}
								scrollableTarget='scrollableDiv'
							>
								{data &&
									_.map(data, (o, i) => {
										const { customerResponseVO, lastMessage = {} } = o
										let tempStatus = customerResponseVO?.custStatus === true ? 'success' : 'warning'
										// indexVal === 0 && setIndexVal(0)
										// indexVal === i && secureLocalStorage.setItem('activeChat', o)
										// let highlightStatus = indexVal === i || indexVal === customerResponseVO?.custName
										return (
											<Stack
												// activeCustDetails?.custUuid === o?.custUuid || o?.custUuid === ''
												className={clsx('mx-8 my-8 p-10', classes.activeChat_card, {
													// [classes.activeChatItem]: indexVal === 0 ? indexVal === i : indexVal === customerResponseVO?.custName,
													[classes.activeChatItem]: activeCustDetails?.custUuid === o?.custUuid || o?.custUuid === '',
												})}
												direction='row'
												spacing={2}
												onClick={() => {
													setActiveCustDetails(o)
													handleActiveChatDetails(o)
													handleTenantMediaDetails(o)
													setChatMessage('')
													// setIndexVal(o?.customerResponseVO?.custName)
													// secureLocalStorage.setItem('activeChat', o)
													setCurrentChatPatient(o?.customerResponseVO?.custName)
												}}
												// style={{ border: '1px solid orange' }}
											>
												<Grid xs={2}>
													{o.appointmentUuid !== '' ? (
														// <StyledBadge
														//     color={tempStatus}
														//     overlap="circular"
														//     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
														//     variant="dot"
														// >
														<Avatar alt={''} src={customerResponseVO?.profilePic}></Avatar>
													) : (
														// </StyledBadge>
														<div className={classes.aidivaBg1}>
															<img className={classes.aidivaImg} src={LyfngoLogo} alt='logo' />
														</div>
													)}
												</Grid>
												<Grid xs={6}>
													<Box>
														<div>
															{o.appointmentUuid !== '' ? (
																<div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
																	<Typography className='text-sm text-black opacity-80'>
																		{customerResponseVO?.custName === null ? 'Patient' : customerResponseVO?.custName}
																	</Typography>
																	{lastMessage?.isWhatspp === true ? (
																		// <img src={whatsAppLogo} alt='w-logo' width='18' height='18' />
																		<></>
																	) : (
																		<CheckCircle style={{ fontSize: '14px', marginTop: -6, color: '#0062DD' }} />
																	)}
																</div>
															) : (
																<div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
																	<Typography className='text-sm text-black opacity-80' style={{ fontWeight: 500 }}>
																		LYFnGO
																	</Typography>
																	<CheckCircle style={{ fontSize: '14px', marginTop: -6, color: '#0062DD' }} />
																</div>
															)}
														</div>
														<Typography className={clsx('text-xs opacity-40 font-medium', classes.activechat_msgContent)}>
															{lastMessage?.isShowTenant && (
																<>
																	{lastMessage?.messageType === 'text' ? (
																		<>
																			{lastMessage?.text?.isMessageSendByCustomer ? null : 'Me: '}
																			{<span className={lastMessage?.text?.isSystemMsg ? 'italic' : ''}>{lastMessage?.text?.chattingMessage}</span>}
																		</>
																	) : lastMessage?.messageType === 'emoji' ? (
																		<>
																			{lastMessage?.emoji?.isEmojiSendByCustomer ? null : 'Me: '}
																			{String.fromCodePoint(parseInt(lastMessage?.emoji?.emojis, 16))}
																		</>
																	) : lastMessage?.messageType === 'file' ? (
																		<>
																			{lastMessage?.file?.isFileUploadedByCustomer ? null : 'Me: '}
																			{lastMessage?.file?.fileType === 'IMAGE' ? (
																				<Image className={classes.activeFileIcon} />
																			) : lastMessage?.file?.fileType === 'DOCUMENT' || 'VIDEO' ? (
																				<InsertDriveFileRounded className={classes.activeFileIcon} />
																			) : (
																				''
																			)}
																			{_.capitalize(lastMessage?.file?.fileType)}
																		</>
																	) : lastMessage.messageType === 'startcall' ? (
																		<>
																			{lastMessage?.initiateCallDTO?.callType === 'audio' ? (
																				<Call style={{ color: '#332FB3', fontSize: '14px' }} />
																			) : (
																				<VideoCall style={{ color: '#332FB3', fontSize: '14px' }} />
																			)}
																			{`Call started`}
																		</>
																	) : lastMessage.messageType === 'endcall' ? (
																		<>
																			<PhoneCallback style={{ color: '#332FB3', fontSize: '14px' }} />
																			<span style={{ margin: '2px 0px 0px 5px', fontSize: '12px' }}>
																				{lastMessage?.endedCallDTO?.isCallInitiatorByCustomer ? `Incoming call` : `Outgoing call`}
																			</span>
																			{/* <Typography className={classes.missedCallTxt}>{`${moment(o.currentDateTime).format('DD-MM-YYYY  HH:mm')}`}</Typography> */}
																		</>
																	) : // <>
																	//     {lastMessage?.endedCallDTO?.callType === "audio" ? <CallEnd style={{ color: "#9E0303" }} /> : <VideoCall style={{ color: "#9E0303" }} />}
																	//     {`Call ended`}
																	// </>

																	lastMessage.messageType === 'declinecall' ? (
																		<>
																			{!lastMessage?.missedCall?.isCallDeclinedByCustomer ? (
																				<>
																					<CallEnd style={{ color: '#e80404', fontSize: '14px' }} /> Call Declined
																				</>
																			) : (
																				<>
																					{lastMessage?.missedCall?.callType === 'audio' ? (
																						<>
																							<PhoneMissed
																								style={{
																									color: !lastMessage?.missedCall?.isCallDeclinedByCustomer ? 'green' : '#e80404',
																									fontSize: '14px',
																								}}
																							/>
																							<span style={{ margin: '2px 0px 0px 5px', fontSize: '14px' }}>{`Missed voice call at ${moment(
																								lastMessage?.currentDateTime
																							).format('hh:mm A')}`}</span>
																						</>
																					) : (
																						<>
																							<MissedVideoCall
																								style={{
																									color: !lastMessage?.missedCall?.isCallDeclinedByCustomer ? 'green' : '#e80404',
																									fontSize: '14px',
																								}}
																							/>
																							<span style={{ margin: '2px 0px 0px 5px', fontSize: '14px' }}>{`Missed video call at ${moment(
																								lastMessage?.currentDateTime
																							).format('hh:mm A')}`}</span>
																						</>
																					)}
																				</>
																			)}
																		</>
																	) : (
																		<></>
																	)}
																</>
															)}
														</Typography>
													</Box>
												</Grid>
												<Grid xs={4} className={clsx('text-right', classes.lastMsgTime)} style={{ marginLeft: '8px' }}>
													<Box>
														<Typography className={'text-xs opacity-60'} style={{ fontSize: '11px' }} noWrap>
															{lastMessage?.currentDateTime && lastMessage?.isShowTenant ? (
																moment(lastMessage?.currentDateTime).format('hh:mm A')
															) : (
																<div style={{ visibility: 'hidden' }}>hi</div>
															)}
														</Typography>
														<div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'end', gap: 8 }}>
															{lastMessage?.isWhatspp === true && (
																<img src={whatsAppLogo} alt='w-logo' width='18' height='18' style={{ position: 'relative', top: 3 }} />
															)}
															{!o?.lastMessage?.isSeen > 0 && (
																<Icon color='error'>
																	<FiberNewRoundedIcon />
																</Icon>
															)}
														</div>

														{/* temp unread count integrated as balanceMessage */}
														{/* {highlightStatus ? (
															<div style={{ height: '20px' }}></div>
														) : o?.lastMessage?.unReadCount > 0 ? (
															<Badge badgeContent={o?.lastMessage?.unReadCount} className={classes.activechat_unreadCount} />
														) : (
															<div style={{ height: '20px' }}></div>
														)} */}
													</Box>
												</Grid>
											</Stack>
										)
									})}
							</InfiniteScroll>
						</div>
					)}
				</Grid>
			</Card>
		</>
	)
}
export default ActiveChats
