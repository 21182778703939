import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getSpeciality: (params) => {
		return axios.get(`${API_ENDPOINTS.NON_CARE_SPECIALITY}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	saveTimingList: (data) => {
		return axios.post(`${API_ENDPOINTS.TUBETIMINGPAYMENT}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	getTimingData: (tentId) => {
		return axios.get(`${API_ENDPOINTS.GET_TIMING_DATA}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentId } },
		})
	},
	updateTimingData: (data) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_TIMING_DATA}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	userUpdateTimingData: (data) => {
		return axios.put(`${API_ENDPOINTS.USER_UPDATE_TIMING_DATA}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
}
