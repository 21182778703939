import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const setDataToLocalStorage = (geoInfo, countryCode) => {
	secureLocalStorage.setItem('geoInfo', geoInfo || {})
	// secureLocalStorage.setItem('countryCode', countryCode)
}
const initialState = {
	loading: false,
	geoInfo: secureLocalStorage.getItem('geoInfo') || {},
	countryCode: secureLocalStorage.getItem('countryCode') || '',
	error: false,
}

const geoInfoReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_GEO_LOCATIONS_STARTED: {
			return {
				...state,
				loading: true,
				geoInfo: {},
				countryCode: '',
				error: false,
			}
		}
		case ACTION_TYPES.GET_GEO_LOCATIONS_SUCCESS: {
			setDataToLocalStorage(JSON.stringify(action.payload), action.payload.country_calling_code)
			// secureLocalStorage.setItem('countryCode', action.payload.country_calling_code)
			return {
				...state,
				loading: false,
				geoInfo: action.payload,
				countryCode: action.payload.country_calling_code,
				error: false,
			}
		}
		case ACTION_TYPES.GET_GEO_LOCATIONS_FAILED: {
			return {
				...state,
				loading: false,
				geoInfo: {},
				error: true,
			}
		}
		default:
			return state
	}
}

export default geoInfoReducer
