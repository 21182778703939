const navigationConfig = [
	{
		id: 'DashBoard',
		title: 'DashBoard',
		translate: 'DashBoard',
		type: 'item',
		icon: 'dashboard',
		url: '/apps/dashBoard',
	},
	{
		id: 'LeadsManagement',
		title: 'LeadsManagement',
		translate: 'LeadsManagement',
		type: 'collapse',
		icon: 'leaderboard',
		children: [
			{
				id: 'Leads',
				title: 'Leads',
				type: 'item',
				url: '/apps/LeadsManagement/Leads',
			},
			{
				id: 'Task',
				title: 'Task',
				type: 'item',
				url: '/apps/LeadsManagement/Task',
			},
			{
				id: 'Member',
				title: 'Member',
				type: 'item',
				url: '/apps/LeadsManagement/Member',
			},
		],
	},
	{
		id: 'example-component',
		title: 'Example',
		translate: 'Patients',
		type: 'item',
		icon: 'bike_scooter',
		url: '/DashBoard',
	},
	{
		id: 'Calendar',
		title: 'Example',
		translate: 'Calendar',
		type: 'item',
		icon: 'today',
		url: '/apps/calendar',
	},
	{
		id: 'Communication',
		title: 'Example',
		translate: 'Communication',
		type: 'item',
		icon: 'shopping_cart',
		url: '/apps/Communication',
	},
	{
		id: 'Report',
		title: 'Example',
		translate: 'Report',
		type: 'item',
		icon: 'school',
		url: '/apps/Report',
	},
	{
		id: 'Settings',
		title: 'Example',
		translate: 'Settings',
		type: 'item',
		icon: 'app_settings_alt',
		url: '/apps/Settings',
	},
	{
		id: 'Integration',
		title: 'Example',
		translate: 'Integration',
		type: 'collapse',
		icon: 'check_box',
		children: [
			{
				id: 'Drive',
				title: 'Drive',
				type: 'item',
				url: '/apps/integration/drive',
			},
			{
				id: 'Online-Follow-up',
				title: 'Online-Follow-up',
				type: 'item',
				url: '/apps/integration/onlineFollowUp',
			},
			{
				id: 'Aidivaa-Prime',
				title: 'Aidivaa-Prime',
				type: 'item',
				url: '/apps/integration/aidivaaPrime',
			},
		],
	},
	{
		id: 'Back-Office',
		title: 'Example',
		translate: 'Back-Office',
		type: 'collapse',
		icon: 'account_box',
		children: [
			{
				id: 'Inventory',
				title: 'Inventory',
				type: 'item',
				url: '/apps/backOffice/inventory',
			},
			{
				id: 'Expenses',
				title: 'Expenses',
				type: 'item',
				url: '/apps/backOffice/expenses',
			},
			{
				id: 'Activities',
				title: 'Activities',
				type: 'item',
				url: '/apps/backOffice/activities',
			},
		],
	},
	{
		id: 'Profile',
		title: 'Profile',
		translate: 'Profile',
		type: 'item',
		icon: 'assessment',
		url: '/apps/profile',
	},
	{
		id: 'Prime',
		title: 'Prime',
		translate: 'Prime',
		type: 'item',
		icon: 'note',
		url: '/apps/Prime',
	},
	{
		id: 'Prime',
		title: 'Prime',
		translate: 'Prime',
		type: 'item',
		icon: 'note',
		url: '/apps/Prime',
	},
]

export default navigationConfig
