import { ACTION_TYPES } from '../../../../constants'

export const groupUserNameAction = (payload) => {
	return {
		type: ACTION_TYPES.GROUPUSER_NAME,
		payload: { ...payload },
	}
}
export const tenentNameAction = (payload) => {
	return {
		type: ACTION_TYPES.TENENT_TYPE_NAME,
		payload: { ...payload },
	}
}

export const individualTentUserId = (payload) => {
	return {
		type: ACTION_TYPES.TENT_USER_ID,
		payload: payload,
	}
}

export const individualTentUser = (payload) => {
	return {
		type: ACTION_TYPES.INDIVIDUAL_TENT_USER,
		payload: payload,
	}
}
