import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getPracticeDetails: (uuid) => {
		return axios.get(API_ENDPOINTS.GET_PRACTICE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	// updatePracticeDetails: (publicKey, ivKey) => {
	// 	const result = { data: publicKey }
	// 	return axios.patch(
	// 		`${API_ENDPOINTS.UPDATE_PRACTICE}`,
	// 		{ ...result },
	// 		{
	// 			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}` },
	// 		}
	// 	)
	// },
	updatePracticeDetails: (body) => {
		return axios.patch(`${API_ENDPOINTS.UPDATE_PRACTICE}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	// getUserNameValidate: (body, tentUserId) => {
	// 	return (
	// 		axios.post(`${API_ENDPOINTS.USER_NAME_AVAILABLE_CHECK}`),
	// 		body,
	// 		{
	// 			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { tentUserId } },
	// 		}
	// 	)
	// },
	getUserNameValidate: (body, tentUserId) => {
		return axios.post(`${API_ENDPOINTS.USER_NAME_AVAILABLE_CHECK}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { tentUserId } },
		})
	},
}
