import { React, useEffect, useState, useCallback } from 'react'
import DialogContent from '@mui/material/DialogContent'
import { Typography, Grid, TextField, Autocomplete } from '@mui/material'
import useStyles from './style'
import Tabs from './Tabs/index'
import _ from 'lodash'
import patientsApi from '../../../../../../services/patient/patientDetails/api'
import templateApi from '../../../../../../services/Communication/api'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import { useSelector } from 'react-redux'
import { decryption } from 'utils'

export default function SendSms({
	open,
	handleClose,
	formik,
	mastuuid,
	handleChange,
	state,
	dispatch,
	templateType,
	onTemplateChange,
	handleModalClose,
	count,
	setCount,
	checkAll,
	setCheckAll,
	filterTxt,
	setFilterTxt,
	list,
	setList,
}) {
	const classes = useStyles()
	const [patientList, setPatientList] = useState([])
	const [groupList, setGroupList] = useState([])
	const [staffList, setStaffList] = useState([])
	const [templateList, setTemplateList] = useState([])
	const [loading, setLoading] = useState(false)
	const [templateLoading, settemplateLoading] = useState(false)
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)

	const getPatientList = useCallback((mastuuid) => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setLoading(false)
			setPatientList(successData?.data || [])
		}
		const onFailure = (err) => {
			setLoading(false)
			setPatientList([])
		}
		setLoading(true)
		patientsApi.getCustomerDetails(mastuuid).then(onSuccess, onFailure)
	}, [])
	const getGroupList = useCallback((mastuuid) => {
		const onSuccess = (res) => {
			setLoading(false)
			setGroupList(res?.data?.data || [])
		}
		const onFailure = (err) => {
			setLoading(false)
			setGroupList([])
		}
		setLoading(true)
		patientsApi.getGroupDetails(mastuuid, { isMenu: true }).then(onSuccess, onFailure)
	}, [])

	const getStaffList = useCallback((mastuuid) => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setLoading(false)
			let filteredList = _.filter(successData?.data?.tentUserRoleBasedDtoList, (item) => item.mastRoleName !== 'Owner')
			setStaffList(filteredList || [])
		}
		const onFailure = (err) => {
			setLoading(false)
			setStaffList([])
		}
		setLoading(true)
		patientsApi.getTentUserByRole(mastuuid, 'all').then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		if (open) {
			if (templateType === 'Patients' || templateType === 'Clients') {
				getPatientList(mastuuid)
			} else if (templateType === 'Groups') {
				getGroupList(mastuuid)
			} else if (templateType === 'Staff') {
				getStaffList(mastuuid)
			}
		}
	}, [getGroupList, getPatientList, getStaffList, mastuuid, open, templateType])

	useEffect(() => {
		let tentType = templateType === 'Staff' ? 'TNT' : templateType === 'Groups' ? 'GRP' : 'CUST'

		const onSuccess = (res) => {
			settemplateLoading(false)
			setTemplateList(res?.data?.data || [])
		}
		const onFailure = (err) => {
			settemplateLoading(false)
			setTemplateList([])
		}
		settemplateLoading(true)
		if (tentType !== '') templateApi.getTemplateList(tentType).then(onSuccess, onFailure)
	}, [templateType])

	const options = [groupName === 'health care' ? 'Patients' : 'Clients', 'Groups', 'Staff']
	useEffect(() => {
		let patientList = _.map(state?.patientList, (item) => (item?.custName ? item?.custName : item?.custCustomId))
		let groupList = _.map(state?.groupList, (item) => item?.tentGroupName)
		let staffList = _.map(
			state?.staffList,
			(item) => `${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || ''}`
		)

		let curentList = templateType === 'Groups' ? [...groupList] : templateType === 'Staff' ? [...staffList] : [...patientList]
		formik?.setFieldValue('recipients', curentList)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, templateType])

	const disableSendButton = _.isEmpty(formik?.values?.recipients) || !((formik?.values?.addMeassage).trim().length >= 3)

	return (
		<div>
			<CustomDialog
				onClose={handleClose}
				open={open}
				disabled={disableSendButton}
				headerText={'Send SMS message'}
				onSubmit={() => formik?.handleSubmit()}
				maxWidth='md'
				SubmitBtnText='Send'
				hideSaveAndAddBtn
			>
				<div className={classes.smsPlanMain}>
					<DialogContent>
						<Grid container spacing={2} className={classes.smsModalMain}>
							<Grid item lg={6} md={6} sm={12}>
								<Autocomplete
									id='selectgrouptype'
									name='selectgrouptype'
									disablePortal
									color='secondary'
									size='small'
									options={options}
									disableClearable
									value={templateType}
									onChange={(e, newVal) => {
										onTemplateChange(newVal)
										// getTemplates(newVal)
										setList([])
										dispatch({
											type: 'setPatientList',
											data: [],
										})
									}}
									renderInput={(params) => <TextField size='small' color='secondary' {...params} label='Type' />}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12}>
								<Tabs
									state={state}
									dispatch={dispatch}
									templateType={templateType}
									handleChange={handleChange}
									patientList={patientList}
									groupList={groupList}
									staffList={staffList}
									loading={loading}
									templateList={templateList}
									templateLoading={templateLoading}
									checkAll={checkAll}
									setCheckAll={setCheckAll}
									filterTxt={filterTxt}
									setFilterTxt={setFilterTxt}
									formik={formik}
									list={list}
									setList={setList}
								/>
							</Grid>
							<Grid item lg={6} md={6} sm={12} className={classes.cancelButton.textpart}>
								<TextField
									id='addrecipients'
									name='addrecipients'
									label='Add recipients'
									value={formik?.values?.recipients.toString().replaceAll(',', ',  ')}
									multiline
									fullWidth
									disabled={true}
									InputLabelProps={{
										shrink: true,
									}}
									variant='outlined'
									rows={5}
									color='secondary'
									className={classes.addRecipient}
									error={formik.touched.recipients && formik.errors.recipients}
									helperText={formik.touched.recipients && formik.errors.recipients && formik.errors.recipients}
								></TextField>
							</Grid>
							<Grid item lg={6} md={6} sm={12}>
								<TextField
									id='addMeassage'
									name='addMeassage'
									label='Add message'
									multiline
									fullWidth
									variant='outlined'
									rows={5}
									color='secondary'
									value={formik?.values?.addMeassage}
									inputProps={{ maxLength: 50 }}
									onChange={(e, value) => {
										formik?.setFieldValue('addMeassage', e.target.value)
										setCount(e.target.value.length)
									}}
									error={formik.errors.addMeassage && formik.touched.addMeassage}
									helperText={formik.errors.addMeassage && formik.touched.addMeassage && formik.errors.addMeassage}
								/>
								<Typography className={classes.messageCount}>
									{count}
									<span>(50 characters)</span>
								</Typography>
							</Grid>
						</Grid>
					</DialogContent>
				</div>
			</CustomDialog>
		</div>
	)
}
