import Tooltip from '@mui/material/Tooltip'
import { useLayoutEffect, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	iconBtn: {
		'&:hover .MuiSvgIcon-root': {
			// color: themesConfig.lyfngo.palette.primary.main,
		},
		'&:hover': {
			// background: themesConfig.lyfngo.palette.light.main,
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 'lighter',
			// '&:hover': {
			// 	color: themesConfig.lyfngo.palette.primary.main,
			// },
		},
	},
}))

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const FullscreenToggle = () => {
	const classes = useStyles()
	const [isFullScreen, setIsFullScreen] = useState(false)

	useEnhancedEffect(() => {
		document.onfullscreenchange = () => setIsFullScreen(document[getBrowserFullscreenElementProp()] != null)

		return () => {
			document.onfullscreenchange = undefined
		}
	})

	function getBrowserFullscreenElementProp() {
		if (typeof document.fullscreenElement !== 'undefined') {
			return 'fullscreenElement'
		}
		if (typeof document.mozFullScreenElement !== 'undefined') {
			return 'mozFullScreenElement'
		}
		if (typeof document.msFullscreenElement !== 'undefined') {
			return 'msFullscreenElement'
		}
		if (typeof document.webkitFullscreenElement !== 'undefined') {
			return 'webkitFullscreenElement'
		}
		throw new Error('fullscreenElement is not supported by this browser')
	}

	/* View in fullscreen */
	function openFullscreen() {
		const elem = document.documentElement

		if (elem.requestFullscreen) {
			elem.requestFullscreen()
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */
			elem.mozRequestFullScreen()
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen()
		} else if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem.msRequestFullscreen()
		}
	}

	/* Close fullscreen */
	function closeFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen()
		} else if (document.mozCancelFullScreen) {
			/* Firefox */
			document.mozCancelFullScreen()
		} else if (document.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			document.webkitExitFullscreen()
		} else if (document.msExitFullscreen) {
			/* IE/Edge */
			document.msExitFullscreen()
		}
	}

	function toggleFullScreen() {
		if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
			closeFullscreen()
		} else {
			openFullscreen()
		}
	}

	//

	return (
		<Tooltip title={isFullScreen ? 'Minimize' : 'Maximize'} followCursor>
			<IconButton onClick={toggleFullScreen} className={classes.iconBtn} size='medium'>
				{isFullScreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
			</IconButton>
		</Tooltip>
	)
}

export default FullscreenToggle
