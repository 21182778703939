import React from 'react'
import { Typography, Grid, Tab, useMediaQuery } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import useStyles from './style'
import HomePage from './HomePage'
import TreatmentPage from './TreatmentPage'
import _ from 'lodash'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { clickLetsStartBtn } from '../../../../services/Communication/action'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, PERMISSION_TYPE, FEATURES_LIST } from '../../../../constants'
import MessageCenter from './MessageCenter'
import WhatsAppCenter from './WhatsAppCenter'
import CustomStartBtn from 'app/sharedComponents/StartIconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useTheme } from '@mui/styles'
import CreateNewPlan from './Modal/CreateNewPlan'
import { getResponseMessage } from 'utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import UpdatePlan from 'services/Communication/Plan/api'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'

function CommunicationPage({ history, match }) {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, isNewUser } = state
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const isCare = _.isEqual(groupName, 'health care')
	const [templateType, setTemplateType] = useState(isCare ? 'Patients' : 'Clients')
	// const defaultSelected = isCare ? 'whatsAppCenter' : 'smsCenter'
	const [tabType, setTabType] = React.useState('whatsAppCenter')
	const [isView, setIsView] = useState(false)
	const dispatch = useDispatch()
	const [openSms, setOpenSms] = useState(false)
	const letsStart = useSelector((state) => state?.LetsStartBtn?.letsStart)
	const theme = useTheme()
	const [listOne, setListOne] = useState([])
	const isTab = useMediaQuery(theme.breakpoints.down('lg'))
	const [open, setOpen] = useState(false)
	const communicationPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.COMMUNICATION_PLAN)
	const communicationMessagePerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.SMS_CENTER)
	const walletModulePermission = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.WALLET)

	const [viewWhatsappMsg, setViewWhatsappMsgText] = useState({})
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})

	// Practice Selection Code :: ENDS

	const toggleSmsModal = () => {
		setTemplateType(isCare ? 'Patients' : 'Clients')
		setOpenSms(!openSms)
		openSms === false && setViewWhatsappMsgText({})
		openSms === false && setIsView(false)
	}

	const handleRedirect = () => {
		history.replace(`/communication/whatsapp`)
	}

	const goStart = () => {
		dispatch(clickLetsStartBtn(false))
	}
	//End : GET plan details  //
	// Start : Create plan //
	const createPlan = (e) => {
		const formData1 = new FormData()
		formData1.append('tentId', mastuuid)
		formData1.append('title', e?.planName)
		dispatch(showLoader('Creating a plan please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())

			if (res?.data?.status === 'success') {
				setOpen(false)
				setListOne((prevState) => [
					{ mastTentUuid: mastuuid, planMappingPatientCount: 'never used', planUuid: res?.data?.data?.planUuid, title: e?.planName },
					...prevState,
				])
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Plan created Successfully!'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePlan.createPlanName(formData1).then(onSuccess, onFailure)
	}
	// End : Create plan //
	const createNewPlan = () => {
		setOpen(true)
	}
	return (
		<Grid container className={classes.communicationMain}>
			<TabContext value={tabType}>
				<Grid container xs={12} className={classes.sectionHead}>
					<Grid item xs={3} className={classes.titleText}>
						{/* <Typography variant='h6' className={classes.commText}>
							Communication
						</Typography> */}
						<ModuleTitle>{currentModule?.mastMenuName || ''}</ModuleTitle>
					</Grid>

					<Grid item xs={5} className={classes.TabHeadContainer}>
						{/* <TabList onChange={(event, newValue) => setTabType(newValue)} aria-label='communicationModuleTab'>
							<Tab label='WhatsApp Center' value='whatsAppCenter' />
							<Tab label='SMS Center' value='smsCenter' />
							{isCare && <Tab label='Treatment Communication' value='treatmentCommunication' />}
						</TabList> */}
					</Grid>

					<Grid item xs={4} className={classes.buttonside}>
						{_.get(walletModulePermission, PERMISSION_TYPE.VIEW, false) && (
							<CustomStartBtn className={classes.walletBtn} onClick={() => history.push('/wallet')}>
								<AccountBalanceWalletOutlinedIcon /> <span>{'Wallet'}</span> <ArrowForwardIcon />
							</CustomStartBtn>
						)}

						{tabType === 'treatmentCommunication' && (
							<>
								{_.get(communicationPerms, PERMISSION_TYPE.CREATE, false) && (
									<CustomStartBtn className={classes.createPlanBtn} onClick={createNewPlan} startIcon={!isTab && <AddCircleIcon />}>
										{'Create new'}
									</CustomStartBtn>
								)}
							</>
						)}

						{tabType !== 'treatmentCommunication' && (
							<>
								{_.get(communicationMessagePerms, PERMISSION_TYPE.CREATE, false) && (
									<CustomStartBtn
										className={classes.addNewSms}
										onClick={() => {
											tabType === 'whatsAppCenter' ? handleRedirect() : toggleSmsModal()
										}}
									>
										{tabType === 'whatsAppCenter' ? 'Send Message' : 'Send new SMS'}
									</CustomStartBtn>
								)}
							</>
						)}
					</Grid>
				</Grid>

				<Grid container xs={12} className={classes.mainContainer}>
					<Grid item xs={12} className={classes.contentGridItem}>
						<TabPanel value='treatmentCommunication'>
							{letsStart === true && isNewUser === true ? (
								<Grid container justifyContent='center' className={classes.communicationMainTwo}>
									<Grid item lg={10} md={10} sm={12}>
										<Grid container>
											<Grid item>
												<HomePage goStart={goStart} />
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							) : (
								<Grid container justifyContent='center'>
									<TreatmentPage history={history} match={match} open={open} setOpen={setOpen} listOne={listOne} setListOne={setListOne} />
								</Grid>
							)}
						</TabPanel>

						<TabPanel value='smsCenter'>
							<MessageCenter openModal={openSms} handleClose={toggleSmsModal} setTemplateType={setTemplateType} templateType={templateType} />
						</TabPanel>

						<TabPanel value='whatsAppCenter'>
							<WhatsAppCenter
								openModal={tabType === 'whatsAppCenter' ? openSms : false}
								handleClose={
									tabType === 'whatsAppCenter'
										? () => {
												toggleSmsModal()
										  }
										: () => {}
								}
								setTemplateType={setTemplateType}
								templateType={templateType}
								viewWhatsappMsg={viewWhatsappMsg}
								setViewWhatsappMsgText={setViewWhatsappMsgText}
								setOpenSms={setOpenSms}
								isView={isView}
								setIsView={setIsView}
								history={history}
								match={match}
							/>
						</TabPanel>
					</Grid>
					<CreateNewPlan open={open} setOpen={setOpen} createPlan={createPlan} />
				</Grid>
			</TabContext>
		</Grid>
	)
}
export default CommunicationPage
