import { Step, StepLabel, Stepper, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import themesConfig from 'app/fuse-configs/themesConfig'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
	stepComponent: {
		paddingBlock: 32,
		[theme.breakpoints.down('md')]: {
			paddingBlock: 0,
			'& .MuiStepConnector-line': {
				minHeight: 0,
				position: 'relative',
				borderTopStyle: 'dashed',
			},
		},
		[theme.breakpoints.up('md')]: {
			'& .MuiStepConnector-line': {
				minHeight: 60,
				position: 'relative',
				left: 10,
			},
		},

		'& .MuiStep-root': {
			'& .MuiTypography-h6': {
				fontSize: 16,
				fontWeight: 600,
			},
			'& .MuiTypography-subtitle1': {
				fontSize: 14,
				fontWeight: 500,
				color: themesConfig.lyfngo.palette.Gray.main,
			},
		},
	},
}))
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#C6DCF8',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor: '#0062DD',
	}),
	...(ownerState.completed && {
		backgroundColor: '#00DA3D',
	}),
}))
function ColorlibStepIcon(props) {
	const { active, completed, className } = props

	const icons = {
		1: <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Freetrial/Profile.svg`} alt='Module' />,
		2: <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Freetrial/Business.svg`} alt='Module' />,
		3: <img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Freetrial/Module.svg`} alt='Module' />,
	}

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	)
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
}
const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#01B84A',
			borderLeftWidth: 3,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#01B84A',
			borderLeftWidth: 3,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#C6DCF8',
		borderLeftWidth: 3,
		borderLeftStyle: 'dotted',
	},
}))

function StepperComponent(props) {
	const { activeStep, isMobileView, getExplore, LyfngoFlashLogo } = props
	const classes = useStyles()

	return (
		<div>
			<div className='flex items-center pb-8' onClick={getExplore}>
				<img className='logo-icon' src={LyfngoFlashLogo} alt='logo' width='120' height='72' />
			</div>
			<Stepper
				orientation={isMobileView ? 'horizontal' : 'vertical'}
				activeStep={_.isEqual(activeStep, 'setupprofile') ? 0 : _.isEqual(activeStep, 'setupbusiness') ? 1 : 2}
				connector={<QontoConnector />}
				className={classes.stepComponent}
			>
				<Step>
					<StepLabel StepIconComponent={ColorlibStepIcon}>
						{!_.isEqual(isMobileView, true) && (
							<>
								<Typography variant='h6'> Setup your profile</Typography>
								<Typography variant='subtitle1'>
									Within <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontWeight: 600 }}>20sec</span> to complete
								</Typography>
							</>
						)}
					</StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={ColorlibStepIcon}>
						{!_.isEqual(isMobileView, true) && (
							<>
								<Typography variant='h6'>Setup your business</Typography>
								<Typography variant='subtitle1'>
									Within <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontWeight: 600 }}>10sec</span> to complete
								</Typography>
							</>
						)}
					</StepLabel>
				</Step>
				<Step>
					<StepLabel StepIconComponent={ColorlibStepIcon}>
						{!_.isEqual(isMobileView, true) && (
							<>
								<Typography variant='h6'>Module Selection for trial</Typography>
								<Typography variant='subtitle1'>
									Within <span style={{ color: themesConfig.lyfngo.palette.primary.main, fontWeight: 600 }}>10sec</span> to complete
								</Typography>
							</>
						)}
					</StepLabel>
				</Step>
			</Stepper>
		</div>
	)
}

export default StepperComponent
