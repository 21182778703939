import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	fitmaincard: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		gap: '10px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	fitcard1: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/fitness.png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	fitcard2: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(7).png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	fitcard3: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(8).png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	fitcard4: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(9).png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	fitcard5: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(5).png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	content: {
		transform: 'translate(4px, 99px)',
		color: '#fff',
	},
}))
function Fitness() {
	const classes = useStyles()
	return (
		<Grid container className={classes.fitmaincard}>
			<Grid item className={classes.fitcard1}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Fitness Studio
				</Typography>
			</Grid>
			<Grid item className={classes.fitcard2}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Gym
				</Typography>
			</Grid>
			<Grid item className={classes.fitcard3}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Home Service
				</Typography>
			</Grid>
			<Grid item className={classes.fitcard4}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Trainer
				</Typography>
			</Grid>
			<Grid item className={classes.fitcard5}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Equipments
				</Typography>
			</Grid>
		</Grid>
	)
}
export default Fitness
