import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { setDefaultSettings } from 'app/store/fuse/settingsSlice'
import _ from '@lodash'
import { navbarToggleMobile, navbarToggle } from '../../store/fuse/navbarSlice'

function NavbarToggleButton(props) {
	const dispatch = useDispatch()
	const theme = useTheme()
	const mdDown = useMediaQuery(theme.breakpoints.down('lg'))
	const settings = useSelector(({ fuse }) => fuse.settings.current)
	const { config } = settings.layout

	return (
		<IconButton
			color='inherit'
			style={{
				' -webkit-touch-callout': 'none',
				'-webkit-user-select': 'none',
				'-khtml-user-select': 'none',
				'-moz-user-select': 'none',
				'-ms-user-select': 'none',
				'user-select': 'none',
			}}
			size='small'
			onClick={(ev) => {
				if (mdDown) {
					dispatch(navbarToggleMobile())
				} else if (config.navbar.style === 'style-2') {
					dispatch(setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)))
				} else {
					dispatch(navbarToggle())
				}
			}}
		>
			{props.children}
		</IconButton>
	)
}

NavbarToggleButton.defaultProps = {
	children: (
		<Icon fontSize='inherit' className='text-20' style={{ color: '#757575' }}>
			menu_open
		</Icon>
	),
}

export default NavbarToggleButton
