import { authRoles } from 'app/auth'

const StepperConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true,
				},
				toolbar: {
					display: true,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: true,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [],
}
export default StepperConfig
