import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	remember: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			color: themesConfig.greyDark.palette.primary.main,
		},
	},
	visibility: {
		cursor: 'pointer',
	},
	captcha: {
		display: 'flex',
		gap: 6,
	},
	reloadCaptcha: {
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
	captchaContainer: {
		'& .MuiTextField-root': {
			marginLeft: 8,
		},
		'& .MuiOutlinedInput-input': {
			textAlign: 'center',
		},
	},
}))
export default useStyles
