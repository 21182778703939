import { ACTION_TYPES } from '../../../constants'

export const setJitsiConnect = (payload, editData) => ({
	type: ACTION_TYPES.JITSI_CALL_REQUEST,
	payload,
})

export const setCallerActive = (payload) => ({
	type: ACTION_TYPES.JITSI_CALLER_ACTIVE,
	payload,
})
