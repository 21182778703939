import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	createPlanName: (data, tentUuid) => {
		return axios.post(API_ENDPOINTS.CREATEPLAN_POST, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'multipart/form-data', 'X-SECURITY': csrf(), path: { tentUuid } },
		})
	},
	getPlanDetails: (mastuuid) => {
		return axios.get(API_ENDPOINTS.PLANLIST_GET, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getPlanListDetails: (params) => {
		return axios.get(API_ENDPOINTS.PLANDETAILS_GET, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	planNameUpdate: (data) => {
		return axios.post(`${API_ENDPOINTS.PLANNAME_UPDATE}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'multipart/form-data',
				'X-SECURITY': csrf(),
			},
		})
	},
	planNameDuplication: (data, params) => {
		return axios.post(
			`${API_ENDPOINTS.PLANNAME_DUPLICATE}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
				},
				params: { ...params },
			}
		)
	},
	planDelete: (data) => {
		return axios.delete(`${API_ENDPOINTS.PLANNAME_DELETE}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			data: { ...data },
		})
	},
	planStop: (data) => {
		return axios.delete(`${API_ENDPOINTS.PLAN_STOP}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			data: { ...data },
		})
	},
}
