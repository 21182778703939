import React from 'react'
import { Typography, TextField, FormControl, FormGroup, Checkbox, Tooltip, FormControlLabel, IconButton, Button, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import TimePicker from '@mui/lab/TimePicker'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	buttonList: {
		display: 'flex',
		justifyContent: 'center',
		paddingBlock: 12,
	},
	mapPartTwo: {
		paddingBlockStart: 12,
		'& .MuiFormGroup-root': {
			flexDirection: 'row',
		},
	},
	paymentOptions: {
		fontWeight: 600,
		color: '#000',
	},
	operationHead: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	addOperationSlot: {
		color: '#0088ff',
		cursor: 'pointer',
		padding: 0,
	},
	slotSection: {
		display: 'flex',
		flexDirection: 'column',
		marginBlockStart: -16,
	},
	slotSectionOne: {
		display: 'flex',
		gap: 8,
		whiteSpace: 'nowrap',
		flexDirection: 'column',
		marginTop: '10px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 4,
		},
		'& .MuiOutlinedInput-root': {},
		'& .MuiOutlinedInput-input': {
			padding: '8.5px 4px',
		},
	},
	slotSectionTwo: {
		display: 'flex',
		gap: 40,
		flexDirection: 'column',
		marginTop: 15,
	},
	mainSlot1: {
		display: 'flex',
		gap: '6px !important',
		'& .muiltr-3sosnl-MuiInputBase-input-MuiOutlinedInput-input': {
			padding: '9.5px 4px',
			textAlign: 'center',
		},
		'& .MuiOutlinedInput-root ': {},
		[theme.breakpoints.down('sm')]: {
			gap: 6,
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',
		},
	},
	cancelSlot: {
		cursor: 'pointer',
	},
	formSlotSection: {
		display: 'flex',
		gap: 12,
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			gap: 0,
			display: 'block',
		},
	},
	dayBtnSelected: {
		background: '#2a2a2a',
		borderRadius: '50%',
		color: '#fff',
		border: '0.5px solid',
		minWidth: 36,
		minHeight: 36,
		'&:hover': {
			background: '#2a2a2a',
			color: '#fff',
		},
		[theme.breakpoints.down('sm')]: {},
	},
	dayBtn: {
		background: '#fff',
		borderRadius: '50%',
		color: '#000',
		border: '0.5px solid',
		minWidth: 36,
		minHeight: 36,
		'&:hover': {
			background: '#fff',
			color: '#000',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 36,
			height: 33,
		},
	},
	star: {
		color: 'red',
	},
	slotButtons: {
		display: 'flex',
		gap: 3,
		[theme.breakpoints.down('sm')]: {
			gap: 10,
		},
		[theme.breakpoints.up('sm')]: {},
	},
	addServiceAdd: {
		display: 'flex',
		flexDirection: 'column',
		gap: 40,
		'& .MuiChip-root': {
			background: 'antiquewhite',
		},
	},
	addServiceOne: {
		width: '100%',
		position: 'relative',
	},
	searchCategoriey: {
		[theme.breakpoints.down('sm')]: {
			width: 200,
		},
	},
	cancelExperience: {
		cursor: 'pointer',
		'& .MuiSvgIcon-root': {
			fill: 'red',
		},
	},
	timePicker: {
		color: 'red',
	},
	timeSlot: {
		border: '2px solid red',
	},
}))
function SlotBook({
	slotbooking,
	stateselect,
	handleChange,
	closeTimeChange,
	operationTimeChange,
	weekDays,
	selectedDays,
	handleItemClick,
	removeRow,
	slotFormik,
	setHideForm,
	formRef,
	hideForm,
	onClickHideForm,
	props,
}) {
	const classes = useStyles()
	const { Option1, Option2, Option3, Option4, Option5, Option6, Option7 } = stateselect

	return (
		<div className={classes.mapPartRight}>
			{/* Start: Operational Hours section */}
			<div className={classes.operationHead}>
				<Typography pb={0} className={classes.paymentOptions}>
					Operational Hours:
				</Typography>
				<Button
					className={classes.addOperationSlot}
					disabled={slotbooking.length > 3 ? true : false}
					onClick={(e) => {
						if (hideForm) {
							setHideForm(false)
						} else {
							formRef.current = 'addData'
							slotFormik.handleSubmit(e)
						}
					}}
				>
					+ Add another slot
				</Button>
			</div>
			<div className={classes.slotSection}>
				{!hideForm && (
					<div className={classes.slotSectionTwo}>
						<form className={classes.formSlotSection}>
							<div className={classes.mainSlot1}>
								<div className={classes.slotSectionOne}>
									{/* <span className={classes.star}>*</span>
<span className={classes.star}>*</span>
<span className={classes.star}>*</span>
<span className={classes.star}>*</span> */}
									<Typography>Operation at</Typography>
									<TimePicker
										label='Time'
										value={slotFormik?.values?.operationAt}
										className={classes.timePicker}
										onChange={operationTimeChange}
										renderInput={(params) => <TextField size='small' color='secondary' sx={{ width: 120 }} {...params} />}
									/>
									{slotFormik.touched.operationAt && slotFormik.errors.operationAt && (
										<FormHelperText error>{slotFormik.errors.operationAt}</FormHelperText>
									)}
								</div>
								<Typography pt={4} style={{ display: 'flex', alignItems: 'center' }}>
									to
								</Typography>
								<div className={classes.slotSectionOne}>
									<Typography>Closes at</Typography>
									<TimePicker
										label='Time'
										value={slotFormik?.values?.closeAt}
										onChange={closeTimeChange}
										className={classes.timeSlot}
										renderInput={(params) => <TextField size='small' color='secondary' sx={{ width: 120 }} {...params} />}
									/>
									{slotFormik.touched.closeAt && slotFormik.errors.closeAt && <FormHelperText error>{slotFormik.errors.closeAt}</FormHelperText>}
								</div>
								<Typography pt={4} style={{ display: 'flex', alignItems: 'center' }}>
									on
								</Typography>
								<div className={classes.slotSectionOne}>
									<Typography>When</Typography>
									<div className={classes.slotButtons}>
										{weekDays.map(({ id, name, text, isSelected }) => {
											const disabled = _.findIndex(selectedDays, (day) => day.name === name) > -1
											return (
												<Tooltip title={name} placement='top'>
													<Button
														key={id}
														name='selectDay'
														disabled={disabled}
														className={!disabled && isSelected ? classes.dayBtnSelected : classes.dayBtn}
														onClick={(event) => handleItemClick(name)}
													>
														{text}
													</Button>
												</Tooltip>
											)
										})}
									</div>
									{slotFormik.touched.days && slotFormik.errors.days && <FormHelperText error>{slotFormik.errors.days}</FormHelperText>}
								</div>
							</div>
							{!_.isEmpty(slotbooking) && (
								<div className={classes.cancelExperience}>
									<IconButton onClick={onClickHideForm}>
										<HighlightOffIcon />
									</IconButton>
								</div>
							)}
						</form>
					</div>
				)}
				{slotbooking.map((item, index) => {
					return (
						<div className={classes.slotSectionTwo}>
							<form className={classes.formSlotSection}>
								<div className={classes.mainSlot1}>
									<div className={classes.slotSectionOne}>
										<TextField
											variant='outlined'
											sx={{ width: 120 }}
											InputLabelProps={{ shrink: false }}
											value={moment(item.operationAt, 'HH:mm').format('hh:mm A')}
										/>
									</div>
									<Typography pt={2}>to</Typography>
									<div className={classes.slotSectionOne}>
										<TextField
											variant='outlined'
											sx={{ width: 120 }}
											InputLabelProps={{ shrink: false }}
											value={moment(item.closeAt, 'HH:mm').format('hh:mm A')}
										/>
									</div>
									<Typography pt={2}>on</Typography>
									<div className={classes.slotSectionOne}>
										<div className={classes.slotButtons}>
											{item?.weekDays.map((item, index) => {
												return (
													<Tooltip title={item.name} placement='top' key={index}>
														<Button
															name='selectDay'
															disabled={!item.isSelected ? true : false}
															className={item.isSelected ? classes.dayBtnSelected : classes.dayBtn}
														>
															{item.text}
														</Button>
													</Tooltip>
												)
											})}
										</div>
									</div>{' '}
								</div>
								<div className={classes.cancelExperience} onClick={() => removeRow(index, item)}>
									<IconButton>
										<HighlightOffIcon />
									</IconButton>
								</div>
							</form>
						</div>
					)
				})}
			</div>
			{/* End: Operational Hours section */}
			{/* Start: Payment option section */}
			<div className={classes.mapPartTwo}>
				<Typography pb={0} className={classes.paymentOptions}>
					Payment Options :
				</Typography>
				<FormControl>
					<FormGroup>
						<FormControlLabel
							control={<Checkbox color='secondary' checked={Option1} onChange={(event) => handleChange(event, 'Option1')} />}
							label='UPI'
						/>
						<FormControlLabel
							control={<Checkbox color='secondary' checked={Option2} onChange={(event) => handleChange(event, 'Option2')} />}
							label='Credit/Debit Card'
						/>
						<FormControlLabel
							control={<Checkbox color='secondary' checked={Option3} onChange={(event) => handleChange(event, 'Option3')} />}
							label='Online Payment'
						/>
						{/* <FormControlLabel
							control={<Checkbox color='secondary' checked={Option4} onChange={(event) => handleChange(event, 'Option4')} />}
							label='Lyfngo Wallet'
						/> */}
						<FormControlLabel
							control={<Checkbox color='secondary' checked={Option5} onChange={(event) => handleChange(event, 'Option5')} />}
							label='Cash'
						/>
						<FormControlLabel
							control={<Checkbox color='secondary' checked={Option6} onChange={(event) => handleChange(event, 'Option6')} />}
							label='Cheque'
						/>
						{/* <FormControlLabel
							control={<Checkbox color='secondary' checked={Option7} onChange={(event) => handleChange(event, 'Option7')} />}
							label='Online'
						/> */}
					</FormGroup>
				</FormControl>

				{slotFormik?.errors?.paymentOptions && slotFormik?.touched?.paymentOptions && (
					<FormHelperText error>{slotFormik?.errors?.paymentOptions}</FormHelperText>
				)}
			</div>
			{/* End: Payment option section */}
		</div>
	)
}
export default SlotBook
