import OnlineFollowUp from './OnlineFollowUp'

const OnlineFollowUpConfig = {
	routes: [
		{
			path: '/onlineFollowUp',
			component: OnlineFollowUp,
		},
	],
}

export default OnlineFollowUpConfig
