import CustomDialog from 'app/sharedComponents/CustomDialog'
import React from 'react'
import { Box, Typography } from '@mui/material'
import DatePicker from './DatePicker'
import { useState } from 'react'
import moment from 'moment'

const ZohoDialog = ({ open, handleClose, mastuuid, dateValue, type }) => {
	const [date, setDate] = useState([new Date(moment().format()), new Date(moment().format())])
	const [value, setValue] = useState([new Date(moment().format()), new Date(moment().format())])
	const onClickBackup = () => {
		// window.open(``, 'Xero_backup')
		window.open(
			`${process.env.REACT_APP_FILE_UPLOAD}/sync/zoho/init_zoho?start_date=${moment(date[0]).format('DD-MM-YYYY')}&end_date=${moment(date[1]).format(
				'DD-MM-YYYY'
			)}&mast_tent_uuid=${mastuuid}&type=${type || 'ACCPAY'}`,
			'',
			'width=420, height=500'
		)
		// window.open(
		// 	`${process.env.REACT_APP_FILE_UPLOAD}/sync/xero_backup?start_date=${moment(date[0]).format('DD-MM-YYYY')}&end_date=${moment(date[1]).format(
		// 		'DD-MM-YYYY'
		// 	)}&mast_tent_uuid=${mastuuid}&type=${type || 'ACCPAY'}`,
		// 	'',
		// 	'width=420, height=500'
		// )
	}
	return (
		<CustomDialog
			headerText={'Sync with Zoho'}
			open={open}
			onClose={handleClose}
			onSubmit={() => onClickBackup()}
			maxWidth='sm'
			fullWidth={true}
			SubmitBtnText='Backup'
		>
			<Box style={{ marginBlock: 16 }}>
				<Typography variant={'body1'} style={{ marginBlock: 16 }}>
					Export your updated invoices list to Zoho account by selecting the period. Upon adding to your Zoho account you could find the list in order
					to track your invoices.
				</Typography>
				<DatePicker
					name={'dataPicker'}
					id={'dataPicker'}
					getDateRange={(dateFromPicker) => {
						setDate(dateFromPicker)
					}}
					getDateRangeForPrint={() => {}}
					setValue={setValue}
					value={value}
				/>
			</Box>
		</CustomDialog>
	)
}

export default ZohoDialog
