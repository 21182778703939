import React, { useReducer, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useMediaQuery, IconButton, Typography, Tooltip, Skeleton, Avatar } from '@mui/material'
import { useTheme } from '@emotion/react'
import useStyles from './style'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import MessageModal from './MessageModal'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import smsApi from '../../../../../services/Communication/api'
import { initialState, reducer } from './reducer'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage, customDateFormat } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import moment from 'moment'
function PracticeListTable({ setTemplateType, templateType, openModal, handleClose }) {
	const classes = useStyles()
	const theme = useTheme()
	const [pageSize, setPageSize] = React.useState(10)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const userData = useSelector((state) => state?.Authentication)
	const { mastuuid } = userData
	const [state, dispatch] = useReducer(reducer, initialState)
	const [tableList, setTableList] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const reduxDispatch = useDispatch()
	const errorJson = useSelector((state) => state?.errorMessages)
	const [count, setCount] = useState(0)
	const [checkAll, setCheckAll] = React.useState(false)
	const [filterTxt, setFilterTxt] = React.useState('')
	const [list, setList] = useState([])
	const onTemplateChange = (newVal) => {
		setTemplateType(newVal)
	}
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	const columns = [
		{
			field: 'recipients',
			headerName: 'Recipients',
			minWidth: isMobile ? 100 : 280,
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<>
					<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 4 }}>
						{params.row?.tentUserSalutation}
						{params.row?.tentUserSalutation ? <span>.</span> : null}
					</span>
					<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{params.row?.recipients}</span>
				</>
			),
		},
		{
			field: 'message',
			headerName: 'Messages',
			minWidth: isMobile ? 100 : 320,
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<Tooltip title={params.value} arrow>
					<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{params.value}</span>
				</Tooltip>
			),
		},
		{
			field: 'deliveryTime',
			headerName: 'Delivery date & time',
			sortable: false,
			filterable: false,
			minWidth: isMobile ? 100 : 320,
		},
		{
			field: 'smsdelivery',
			headerName: 'SMS status',
			sortable: false,
			filterable: false,
			minWidth: isMobile ? 100 : 320,
		},
	]

	const getSmsList = React.useCallback((mastuuid) => {
		const onSuccess = (res) => {
			setTableList(res?.data?.data || [])
			setLoading(false)
		}
		const onFailure = (err) => {
			setTableList([])
			setLoading(false)
		}
		setLoading(true)
		smsApi.getSmsList(mastuuid).then(onSuccess, onFailure)
	}, [])

	React.useEffect(() => {
		mastuuid && getSmsList(mastuuid)
	}, [getSmsList, mastuuid])

	const loadingRow = _.map(_.range(5), (item, idx) => ({
		id: idx + 1,
		recipients: '',
		message: '',
		deliveryTime: '',
		smsdelivery: '',
	}))

	const loadingColumn = [
		{
			field: 'recipients',
			headerName: 'Recipients',
			minWidth: isMobile ? 100 : 280,
			sortable: false,
			filterable: false,
			renderCell: (params) => <Skeleton width='90%' height='40px' />,
		},
		{
			field: 'message',
			headerName: 'Messages',
			minWidth: isMobile ? 100 : 320,
			sortable: false,
			filterable: false,
			renderCell: (params) => <Skeleton width='90%' height='40px' />,
		},
		{
			field: 'deliveryTime',
			headerName: 'Delivery date & time',
			sortable: false,
			filterable: false,
			minWidth: isMobile ? 100 : 320,
			renderCell: (params) => <Skeleton width='90%' height='40px' />,
		},
		{
			field: 'smsdelivery',
			headerName: 'SMS status',
			sortable: false,
			filterable: false,
			renderCell: (params) => <Skeleton width='90%' height='40px' />,
		},
	]
	const rows = _.map(tableList, (item, idx) => {
		return {
			id: idx + 1,
			tentUserSalutation: item?.tentUserSalutation || '',
			recipients: item?.custName || item?.tentUserFirstName || '',
			message: item?.smsLogContent?.sendContent || '',
			// deliveryTime: item?.createdOn ? customDateFormat(item?.createdOn, dateFormatForViewer) : '-',

			deliveryTime: `${item?.createdOn ? customDateFormat(item?.createdOn, dateFormatForViewer) : '-'}  & ${
				moment(item?.createdOn).format('LT') || '-'
			}`,

			smsdelivery: item?.smsLogResponse?.status || false ? 'Success' : 'Failed',
		}
	})
	const onSubmit = (e) => {
		let id =
			templateType === 'Groups'
				? { groupUuid: _.map(state?.groupList, (grp) => grp?.uuid).filter(Boolean) }
				: templateType === 'Staff'
				? { tentUserUuid: _.map(state?.staffList, (staff) => staff?.tentUserUuid).filter(Boolean) }
				: { custUuid: _.map(state?.patientList, (patient) => patient?.custUuid).filter(Boolean) }

		const body = {
			mastCommName: e?.template,
			mastCommMode: 'sms',
			...id,
			tentId: mastuuid,
			message: e?.addMeassage,
			senderType: 'tent',
		}
		const onSuccess = (res) => {
			reduxDispatch(hideLoader())
			if (res?.data?.status === 'success') {
				handleModalClose()
				getSmsList(mastuuid)
				reduxDispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Message created successfullt'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else {
				reduxDispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			reduxDispatch(hideLoader())
			reduxDispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		reduxDispatch(showLoader('Loading please wait...'))
		smsApi.sendSms(body).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			addMeassage: '',
			recipients: [],
			template: '',
		},
		validationSchema: yup.object({
			addMeassage: yup
				.string()
				.nullable()
				.required('Please enter the message')
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the message')
				.max(50, 'message is maximum 50 character'),
			recipients: yup.array().min(1).required('Please add atleat one Recipent'),
			template: yup.string(),
		}),
		onSubmit: onSubmit,
	})
	const handleModalClose = () => {
		formik?.resetForm()
		handleClose()
		dispatch({ type: 'resetState' })
		setCount(0)
		setCheckAll('')
		setFilterTxt('')
	}
	return (
		<div className={classes.profileTable} style={{ height: '65vh' }}>
			<div className={classes.wrapper}>
				<Typography>{`Today's promotional messages`}</Typography>
				<Tooltip
					classes={{
						tooltip: classes.customTooltip,
						arrow: classes.arrow,
					}}
					title='This only covers messages sent from SMS centre.'
					arrow
				>
					<IconButton>
						<HelpOutlineOutlinedIcon />
					</IconButton>
				</Tooltip>
			</div>

			{loading ? (
				<div style={{ height: 500 }}>
					<DataGrid rows={loadingRow} columns={loadingColumn} />
				</div>
			) : !_.isEmpty(tableList) ? (
				<DataGrid
					rows={rows}
					columns={columns}
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[10, 25, 50]}
					pagination
					disableSelectionOnClick
					disableColumnMenu={true}
				/>
			) : (
				<div className={classes.noData}>
					<Avatar src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/patientbg.png`} variant='square' sx={{ width: 310, height: 258 }} />
					<Typography variant='subtitle1'>You haven't sent any SMS </Typography>
				</div>
			)}

			<MessageModal
				open={openModal}
				handleClose={handleModalClose}
				formik={formik}
				mastuuid={mastuuid}
				templateType={templateType}
				onTemplateChange={onTemplateChange}
				state={state}
				dispatch={dispatch}
				count={count}
				setCount={setCount}
				checkAll={checkAll}
				setCheckAll={setCheckAll}
				filterTxt={filterTxt}
				setFilterTxt={setFilterTxt}
				list={list}
				setList={setList}
			/>
		</div>
	)
}

export default PracticeListTable
