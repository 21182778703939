import { ACTION_TYPES } from '../../../constants'

const initialState = {
	chatlistAllActive: null,
	chatlistAttendingActive: null,
	chatlistWaitingActive: null,
	chatlistIdleActive: null,
	chatlistClosedActive: null,
	chatlistExpiredActive: null,
}

const chatConsultReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.CHATLIST_ALL_ACTIVE: {
			return {
				...state,
				chatlistAllActive: action.payload,
			}
		}
		case ACTION_TYPES.CHATLIST_ATTENDING_ACTIVE: {
			return {
				...state,
				chatlistAttendingActive: action.payload,
			}
		}
		case ACTION_TYPES.CHATLIST_WAITING_ACTIVE: {
			return {
				...state,
				chatlistWaitingActive: action.payload,
			}
		}
		case ACTION_TYPES.CHATLIST_IDLE_ACTIVE: {
			return {
				...state,
				chatlistIdleActive: action.payload,
			}
		}
		case ACTION_TYPES.CHATLIST_CLOSED_ACTIVE: {
			return {
				...state,
				chatlistClosedActive: action.payload,
			}
		}
		case ACTION_TYPES.CHATLIST_EXPIRED_ACTIVE: {
			return {
				...state,
				chatlistExpiredActive: action.payload,
			}
		}
		default:
			return state
	}
}
export default chatConsultReducer
