import React from 'react'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Button, DialogContent } from '@mui/material'
import Webcam from 'react-webcam'
import CustomBtn from '../Button'
import CancelBtn from '../CancelButton'

const UseStyle = makeStyles((theme) => ({
	root: {
		'& .MuiDialogContent-root': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 12,
			'& .MuiButton-root': {
				background: '#ff5e6a',
				maxWidth: 132,
				minWidth: 112,
				lineHeight: '24px',
				fontSize: 14,
				letterSpacing: 2,
			},
		},
	},
}))

const CaptureDialog = ({
	open,
	handleClose,
	capture,
	img,
	setImg,
	uploadBtnText,
	videoConstraints,
	webcamRef,
	captureImg,
	handleUploadingFileToTenant,
	videocapturing,
	setVideoCapturing,
	recordedChunks,
	setRecordedChunks,
}) => {
	const theme = useTheme()
	const classes = UseStyle()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
	return (
		<>
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title' className={classes.root}>
				<DialogTitle id='responsive-dialog-title'>Take Photo</DialogTitle>
				<DialogContent>
					{img === null ? (
						<>
							<Webcam
								audio={false}
								mirrored={true}
								height={400}
								width={400}
								ref={webcamRef}
								screenshotFormat='image/jpeg'
								videoConstraints={videoConstraints}
							/>
							<Button onClick={() => capture()} autoFocus>
								Capture
							</Button>
						</>
					) : (
						<>
							<img src={img} alt='screenshot' />
							<Button onClick={() => setImg(null)} autoFocus>
								Retake
							</Button>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<CancelBtn autoFocus onClick={handleClose}>
						Close
					</CancelBtn>
					<CustomBtn
						autoFocus
						disabled={captureImg === null}
						onClick={() => {
							handleClose()
							captureImg && handleUploadingFileToTenant(captureImg)
						}}
					>
						{uploadBtnText ? uploadBtnText : 'Send'}
					</CustomBtn>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default CaptureDialog
