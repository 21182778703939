import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	patientsMain: {
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: '#000',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#000',
			border: '1px solid',
		},
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
	},
	listSection: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 0,
		paddingInline: 8,
		overflow: 'hidden',
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
			height: 246,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
			paddingBlock: 12,
		},
		[theme.breakpoints.up('md')]: {
			marginBlock: 8,
			paddingBlock: 12,
			height: 320,
		},
	},
	searchAuto: {
		[theme.breakpoints.up('xs')]: {
			marginBlock: 0,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 0,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 340,
		},
	},
	listItem: {
		[theme.breakpoints.up('xs')]: {
			height: 168,
			overflow: 'auto',
		},
		[theme.breakpoints.up('md')]: {
			height: 208,
			overflow: 'auto',
		},
	},
	checkBox: {
		'& .MuiCheckbox-colorSecondary': {
			color: '#0062DD',
		},
		'& .Mui-disabled': {
			color: '#C4C4C4',
		},
	},
	mainContainer: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		padding: 10,
		paddingBottom: 0,
	},
	listComp: {
		textAlign: 'center',
		color: 'gray',
		fontSize: 15,
		paddingBlockEnd: 12,
	},
	noData: {
		textAlign: 'center',
		color: 'gray',
		fontSize: 15,
		marginBlock: 16,
	},
}))
export default useStyles
