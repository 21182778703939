import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	groupssMain: {},
	listSection: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 0,
		marginBlock: theme.spacing(1),
		height: 276,
		overflow: 'auto',
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
	listSectionOne: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 8,
		paddingInline: 8,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
	},
	checkBox: {},
	groupText: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingInline: 12,
	},
	filterText: {
		background: '#EFEFEF',
		padding: 6,
	},
}))
export default useStyles
