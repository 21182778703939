import React, { useCallback, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Slide, DialogActions, Divider, Typography, IconButton, useMediaQuery } from '@mui/material'
import CustomBtn from '../Button'
import CancelBtn from '../CancelButton'
import SaveAndNewButton from '../SaveAndNewButton'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
import CloseIcon from '@mui/icons-material/Close'
import CustomDialogAction from '../CustomDialogAction'
import { showPrompt, closePrompt } from 'services/Prompt/actions'
import { useDispatch } from 'react-redux'
import Prompt from '../Prompt'
import Draggable from 'react-draggable'

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Draggable bounds={{ left: -400, top: -200, right: 400, bottom: 200 }} handle='.onlyDraggableArea'>
			<Slide direction='up' ref={ref} {...props} />
		</Draggable>
	)
})

const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiDialogActions-root': {
			paddingInline: 24,
		},
	},
	titleRoot: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		padding: 0,
		display: 'flex',
		'& .MuiTypography-h5': {
			paddingInline: 24,
			paddingBlock: 18,
			width: 'calc(100% - 70px)',
			fontSize: 16,
			fontWeight: 500,
			cursor: 'move',
		},
	},
	title: {
		fontSize: theme.spacing(1.9),
		fontWeight: '500',
	},
	closeIconContainer: {
		width: 70,
		paddingInlineEnd: 24,
		paddingInlineStart: 10,
		paddingBlock: 8,
	},
	root: {
		backgroundColor: 'transparent',
	},
}))

const CustomDialog = (props) => {
	const {
		open,
		onClose,
		onSubmit,
		onSubmitSave,
		SubmitBtnText,
		saveAndAddBtnText,
		CancelBtnText = 'Cancel',
		headerText,
		children,
		maxWidth = 'lg',
		fullWidth = true,
		className,
		hideCloseBtn,
		hideSaveBtn,
		ShowSaveAndAddBtn,
		hideDivider,
		hideContent,
		totalExpenses,
		titleStyle,
		tableData,
		editMode,
		isNewUser,
		disabled,
		dialogActions = true,
		customStyles = {},
		formikValues,
	} = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const KEY_NAME_ESC = 'Escape'
	const KEY_EVENT_TYPE = 'keyup'
	const isEscClose = open
	const isValidateEsc = formikValues?.dirty ? formikValues?.dirty : false

	const handleEscKey = useCallback(
		(event) => {
			if (event.key === KEY_NAME_ESC && isValidateEsc === false) {
				onClose()
				dispatch(closePrompt())
			} else if (event.key === KEY_NAME_ESC && isValidateEsc === true) {
				dispatch(
					showPrompt(
						'Are you sure want to close?',
						'Yes',
						'No',
						() => {
							onClose()
							dispatch(closePrompt())
						},
						() => dispatch(closePrompt())
					)
				)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onClose]
	)

	useEffect(() => {
		isEscClose && document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

		return () => {
			isEscClose && document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleEscKey])

	return (
		<Dialog
			open={open}
			className={className ? `${className} ${classes.dialog}` : classes.dialog}
			TransitionComponent={Transition}
			fullWidth={fullWidth}
			maxWidth={props.maxWidth || maxWidth}
			// keepMounted
			onClose={onClose}
			isNewUser={isNewUser}
			disableEscapeKeyDown
			aria-labelledby='draggable-dialog-title'
			aria-describedby='Common Dialog Box'
			{...props}
		>
			<DialogTitle className={classes.titleRoot}>
				<Typography variant='h5' className='onlyDraggableArea'>
					{headerText}
				</Typography>
				{onClose && (
					<div className={classes.closeIconContainer}>
						<IconButton
							aria-label='close'
							onClick={onClose}
							sx={{
								color: '#fff',
							}}
						>
							<CloseIcon />
						</IconButton>
					</div>
				)}
			</DialogTitle>

			<DialogContent style={customStyles?.dialogContent || {}}>{children}</DialogContent>

			{hideDivider ? <></> : <Divider />}

			{dialogActions && (
				<DialogActions>
					{hideContent ? (
						<CustomDialogAction
							onClose={onClose}
							onSubmit={onSubmit}
							SubmitBtnText={SubmitBtnText}
							saveAndAddBtnText={saveAndAddBtnText}
							totalExpenses={totalExpenses}
							tableData={tableData}
							editMode={editMode}
							disabled={disabled}
						/>
					) : (
						<></>
					)}

					{!hideContent ? hideCloseBtn ? <></> : <CancelBtn onClick={onClose}>{CancelBtnText}</CancelBtn> : <></>}

					{!hideContent ? (
						ShowSaveAndAddBtn ? (
							<SaveAndNewButton
								disabled={disabled}
								onClick={() => {
									onSubmitSave()
								}}
							>
								{saveAndAddBtnText ? saveAndAddBtnText : 'Save Draft'}
							</SaveAndNewButton>
						) : (
							<></>
						)
					) : (
						<></>
					)}

					{!hideContent ? (
						hideSaveBtn ? (
							<></>
						) : (
							<CustomBtn
								disabled={disabled}
								onClick={() => {
									onSubmit()
								}}
							>
								{SubmitBtnText ? SubmitBtnText : 'Save'}
							</CustomBtn>
						)
					) : (
						<></>
					)}
				</DialogActions>
			)}

			<Prompt />
		</Dialog>
	)
}

export default CustomDialog
