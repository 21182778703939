import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react'
import {
	Typography,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	Autocomplete,
	Radio,
	Grid,
	IconButton,
	createFilterOptions,
	InputAdornment,
	CircularProgress,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DropZone from './DropzoneUpload/DropZone'
import useStyles from './style'
import moment from 'moment'
import { hideLoader, showLoader } from '../../../services/loader/action'
import { useFormik } from 'formik'
import { decryption, encryption, failureLogin, getResponseMessage } from '../../../utils'
import _ from 'lodash'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import patientDetails from '../../../services/patient/patientDetails/api'
import LookupApi from '../../../services/lookup/api'
import sendUploadFileApi from '../../../services/fileupload/PostApi'
import fileUploadDetailsApi from '../../../services/fileupload/GetApi'
import { showMessage } from 'app/store/fuse/messageSlice'
import Lookup from './Lookup/index'
import MedicalhistoryComp from './MedicalHistoryIPG/medicalHistoryData'
import GroupComp from './groupIPG/index'
import AddressFile from './Address/index'
import countryLookup from '../../../services/register/api'
import CustomTextFieldWithCountryCode from '../CountryCodeAdornment'
import CustomDialog from '../CustomDialog'
import CancelBtn from '../CancelButton'
import CustomDatePicker from '../CustomDatePicker'
import { profileViewData, groupSettingsData, customerListApiTriggerFn } from '../../../services/patient/roleBasedUuid/action'
import history from '@history'
import secureLocalStorage from 'react-secure-storage'
import ClientInfo from './ClientInfo'
import { setCustomerData } from 'services/Client/actions'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import { updateCurrentIndex } from 'services/sidemenu/action'
import TagComponent from './TagIPG'

const AddPatient = (props) => {
	const classes = useStyles()
	const { opens, open, handleClose, handleClose1, match, isEditMode } = props
	const auth = useSelector((state) => state?.Authentication || {})
	const geoInfo = useSelector((state) => state?.GeoInfo?.geoInfo)
	const { uuid, mastuuid } = auth
	const groupSelection = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)
	const countryCode = useSelector((state) => state?.dynamicMenu?.userData?.countryCode)
	const countryName = useSelector((state) => state?.dynamicMenu?.userData?.countryName)

	const filter = createFilterOptions()
	const [isNeww, setIsNeww] = useState(false)
	const [isNewInsurance, setIsNewInsurance] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loadingInsurance, setLoadingInsurance] = useState(false)
	const cancelExecutor = useRef()
	const [bloodGroupOptions, setBloodGroupOptions] = useState([])
	const [languageOptions, setLanguageOptions] = useState([])
	const [referredByOptions, setReferredByOptions] = useState([])
	const [insuranceOptions, setInsuranceOptions] = useState([])
	const [insuranceName, setInsuranceName] = useState(null)
	const [insuranceNumber, setInsuranceNumber] = useState(null)
	const dispatch = useDispatch()
	const [occupationOptions, setOccupationOptions] = useState([])
	const [patientId, setPatientId] = useState('')
	const [groupOptions, setGroupOptions] = useState([])
	const [tagOptions, setTagOptions] = useState([])
	const [tags, setTags] = useState([])
	const [custSendFollowupNotification, setCustSendFollowupNotification] = useState(false)
	const [custBdayNotification, setCustBdayNotification] = useState(false)
	const [custEmailNotification, setEmailNotification] = useState(true)
	const [custSmsNotification, setSmsNotification] = useState(true)
	const [fileCategoryList, setFileCategoryList] = useState([])
	// const [identityFileResponse, setIdentityFileResponse] = useState([])
	const [medicalHistory, setMedicalHistory] = useState([])
	const [medicalData, setMedicalData] = useState([])
	const [groups, setGroups] = useState([])
	const [occupation, setOccupation] = useState(null)
	const [bloodgroup, setBloodgroup] = useState(null)
	const [referredBy, setReferredBy] = useState(null)
	const [ageChanged, setAgeChanged] = useState(false)
	// const custUuid = useSelector((state) => state?.RoleIdReducer.cust_Uuid)
	const [custUuid, setCustUuid] = useState('')
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const [patientInfo, setPatientInfo] = useState([])
	const isEditPatientStatus = false
	const [countryCodeList, setCountryCodeList] = useState([])
	const [identityTypes, setIdentityTypes] = useState([])
	const [isNewIdentityType, setIsNewIdentityType] = useState(false)
	const [nationalityIDType, setNationalityIDType] = useState(null)
	const [countryCodeLength, setCountryCodeLength] = useState(10)
	const [countryCodeMinLength, setCountryCodeMinLength] = useState(1)
	const [secondaryCountryCodeLenght, setSecondaryCountryCodeLenght] = useState(10)
	const [secondaryCountryCodeMinLenght, setSecondaryCountryCodeMinLenght] = useState(1)
	const [countryNameOption, setCountryNameOption] = useState('')
	const [statesOption, setStatesOption] = useState([])
	const [captureImg, setCaptureImg] = useState(null)
	const [img, setImg] = useState(null)
	const [camOpen, setCamOpen] = useState(false)
	const isNewUser = useSelector((state) => state?.Authentication?.isNewUser)
	const [memberShipList, setMemberShipList] = useState([])
	const [genderChanged, setGenderChanged] = useState(false)
	const [chooseRelationOption, setChooseRelationOption] = useState([])

	const [relationTypeOption, setRelationTypeOption] = useState([])
	// const isPatientCompleted = useSelector((state) => state?.walkthrough?.isPatientCompleted)
	const slotId = _.get(match, 'params.id')
	const [uploadUuid, setUploadUuid] = useState(null)
	const [languageParams, onLanguageSearch] = useState({
		limit: 50,
		offset: 1,
		search: 'English',
	})
	const handleAddPatient = (e) => {
		submitAddPatient(e)
	}
	const formik = useFormik({
		initialValues: {
			Patientname: '',
			PatientID: patientId ? patientId : '',
			gender: '',
			dob: null,
			doj: !_.isEqual(isEditMode, true) ? moment(new Date()).format('YYYY-MM-DD') : null,
			age: null,
			Group: '',
			mobileNumber: '',
			EmailID: '',
			insuranceNumber: null,
			country: null,
			state: null,
			Address: '',
			insuranceName: null,
			//
			pincode: !_.isEqual(isEditMode, true) ? (!_.isEmpty(geoInfo) ? geoInfo?.postal : '') : '',
			otherMobileNumber: '',
			countryCode: '',
			clientInfo: '',
			language: { mastLookupKey: 'eng', mastLookupValue: 'English', mastLookupType: 'LAN' } || {},
			secondaryCountryCode: '',
			nationalityIDType: null,
			nationalityIDValue: '',
			membershipName: null,
			membershipValidity: null,
			relationType: null,
			relationName: null,
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			Patientname: yup.string().strict(true).max(101, 'patient name field limit exist').required('Please enter the  patient name'),
			PatientId: yup.string().strict(true).max(100, 'Maximum 100 characters allowed'),
			EmailID: yup.string().nullable().email('You must enter a valid email'),
			country: yup.object().nullable(),
			insuranceNumber: !_.isEmpty(insuranceName)
				? yup
						.string()
						.nullable()
						.min(5, 'Minimum 5 digits allowed for insurance number ')
						.max(30, 'Maximum 30 digits allowed for insurance number ')
						.required('Please enter the insurance number ')
				: yup.string().nullable().min(5, 'Minimum 5 digits allowed for insurance number ').max(30, 'Maximum 30 digits allowed for insurance number '),
			dob: yup.date().nullable(),
			doj: yup?.date()?.nullable(),
			insuranceName: yup.lazy((value) => {
				switch (typeof value) {
					case 'object':
						return yup.object().nullable()

					case 'string':
						return _.isEqual(insuranceNumber, null)
							? yup
									.string()
									.nullable()
									.min(2, 'Minimum 2 digits allowed for insurance number ')
									.max(50, 'Maximum 50 digits allowed for insurance number ')
									.required('Please enter the insurance name')
							: yup.string().nullable()
					default:
						return yup.mixed()
				}
			}),
			gender: yup.string().nullable(),
			age: yup.number().nullable(),
			mobileNumber: yup
				.string()
				.nullable()
				.required('Please enter the mobile number')
				.matches(/^([1-9][0-9]*)?$/, 'Enter valid number')
				.min(countryCodeMinLength, `Mobile number should be minimum ${countryCodeMinLength} digits`)
				.max(countryCodeLength, `Mobile number should be ${countryCodeLength} digit`),
			Group: yup.object(),

			nationalityIDValue: yup.string().when('nationalityIDType', {
				is: (nationalityIDType) => {
					return !_.isEmpty(nationalityIDType)
				},
				then: yup.string(),
			}),
			// nationalityIDType: yup.string().when('nationalityIDValue', {
			// 	is: (nationalityIDValue) => {
			// 		return nationalityIDValue.length > 0
			// 	},
			// 	then: yup.object().required('Please select the nationality ID'),
			// }),

			Address: yup.string().nullable().max(500, 'Max 500 characters only'),
			language: yup.object().nullable(),
			pincode: yup
				.string()
				.nullable()
				.matches(/^[0-9\s]+$/, 'Only numbers are allowed'),
			otherMobileNumber: yup
				.string()
				.nullable()
				.matches(/^([1-9][0-9]*)?$/, 'Enter valid number')
				.min(secondaryCountryCodeMinLenght, `Mobile number should be minimum  ${secondaryCountryCodeMinLenght} digits`)
				.max(secondaryCountryCodeLenght, `Mobile number should be ${secondaryCountryCodeLenght} digits`),
		}),
		onSubmit: handleAddPatient,
	})
	const submitTheRelationShip = (e) => {
		submitRelationship(e)
	}
	const formikSchema = yup.object().shape({
		edudation: yup
			.array()
			.min(1)
			.of(
				yup.object().shape({
					relationshipCode: yup.object().nullable(),
					relationshipName: yup.string().nullable(),
				})
			)
			.required('Required'),
	})

	const formik2 = useFormik({
		initialValues: {
			relationshipDetails: !_.isEmpty(patientInfo?.customerRelationshipMappingList)
				? patientInfo?.customerRelationshipMappingList
				: [
						{
							relationshipCode: null,
							relationshipName: '',
						},
				  ],
		},

		validationSchema: formikSchema,
		onSubmit: submitTheRelationShip,
	})

	useEffect(() => {
		if (open || opens) {
			// Blood group lookup
			LookupApi.getLookup('getLookup', { mastLookupType: 'BGP' }).then(
				(res) => {
					setBloodGroupOptions(res?.data?.data)
				},
				() => {
					setBloodGroupOptions([])
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, opens])

	useEffect(() => {
		if (open || opens) {
			patientDetails.getOccupationMasterData(mastuuid).then(
				(res) => {
					const successData = decryption(res)
					setOccupationOptions(successData?.data)
				},
				() => {}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, opens])

	useEffect(() => {
		if (isNeww) {
			let body = {
				lookup_value: referredBy?.inputValue ? referredBy?.inputValue : referredBy?.mastLookupValue,
				tentUuid: mastuuid,
			}
			referredBy && setLoading(true)
			const onSuccess = (res) => {
				setLoading(false)
				if (res?.data?.status === true)
					if (!_.isEmpty(referredByOptions)) {
						setLoading(false)
					} else {
						setLoading(false)
					}
				else if (res?.data?.status === false)
					if (res?.data?.message === 'err_Lookup Value Already exist') {
						dispatch(
							showMessage({
								message: 'Duplicate Referred by name ',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					} else if (res?.data?.message === 'err_Max_Min_character allowed') {
						dispatch(
							showMessage({
								message: 'Referred by Name should contain min 2 to max 50 characters',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					}
				dispatch(
					showMessage({
						message: 'Referred by type added successfully',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				// getReferedByField()
			}
			const onFailure = (err) => {
				setLoading(false)
				setReferredBy(null)
				dispatch(
					showMessage({
						message: err?.response?.data?.message,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			referredBy && countryLookup.setInsuranceName(body, { mastLookupType: 'RFD', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNeww, mastuuid, referredBy])

	// INSURANCE DROPDOWN POST API
	useEffect(() => {
		if (isNewInsurance) {
			let body = {
				lookup_value: insuranceName?.inputValue ? insuranceName?.inputValue : insuranceName?.mastLookupValue,
			}
			insuranceName && setLoadingInsurance(true)
			const onSuccess = (res) => {
				setLoadingInsurance(false)
				if (res?.data?.status === true)
					if (!_.isEmpty(insuranceOptions)) {
						setLoadingInsurance(false)
					} else {
						setLoadingInsurance(false)
					}
				else if (res?.data?.status === false)
					if (res?.data?.message === 'err_Lookup Value Already exist') {
						dispatch(
							showMessage({
								message: 'Duplicate Insurance name ',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					} else if (res?.data?.message === 'err_Max_Min_character allowed') {
						dispatch(
							showMessage({
								message: 'Insurance Name should contain min 2 to max 50 characters',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					}
			}
			const onFailure = (err) => {
				setLoadingInsurance(false)
				setInsuranceName(null)
			}
			insuranceName && countryLookup.setInsuranceName(body, { mastLookupType: 'MYS', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNewInsurance, mastuuid, insuranceName, insuranceOptions, dispatch])

	// IDENTITY DOCUMENT TYPE DROPDOWN
	useEffect(() => {
		if (isNewIdentityType) {
			let body = {
				lookup_value: nationalityIDType,
			}
			const onSuccess = (res) => {
				if (res?.data?.status === true) {
					dispatch(
						showMessage({
							message: 'Document type added successfully ',
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
					getDoucmentTypeDataField()
				} else if (res?.data?.status === false)
					if (res?.data?.message === 'err_Lookup Value Already exist') {
						dispatch(
							showMessage({
								message: 'Duplicate Document type name ',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					} else if (res?.data?.message === 'err_Max_Min_character allowed') {
						dispatch(
							showMessage({
								message: 'Doucument type should contain min 2 to max 50 characters',
								autoHideDuration: 3000,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right',
								},
								variant: 'error',
							})
						)
					}
			}
			const onFailure = (err) => {
				setNationalityIDType(null)
			}
			nationalityIDType && countryLookup.setInsuranceName(body, { mastLookupType: 'GID', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNewIdentityType])

	useEffect(() => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success') {
				setMedicalHistory(successData?.data)
			} else {
				setMedicalHistory([])
			}
		}
		const onFailure = (err) => {}
		if ((mastuuid && open) || (mastuuid && opens)) {
			patientDetails.getMedicalHistory(mastuuid).then(onSuccess, onFailure)
		}
	}, [mastuuid, open, opens])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'STATUS_SUCCESS') {
				setTagOptions(res?.data?.data)
			} else {
				setTagOptions([])
			}
		}
		const onFailure = (err) => {}
		if ((mastuuid && open) || (mastuuid && opens)) {
			patientDetails.getTagsData(mastuuid).then(onSuccess, onFailure)
		}
	}, [mastuuid, open, opens])
	const getPatientIdData = useCallback(() => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setPatientId(successData?.data)
		}
		const onFailure = (err) => {}
		if (mastuuid && open) {
			patientDetails.getPatientId(mastuuid).then(onSuccess, onFailure)
		}
	}, [mastuuid, open])

	useEffect(() => {
		getPatientIdData()
	}, [getPatientIdData])

	const languageSearch = _.debounce((e, value) => {
		onLanguageSearch({
			...languageParams,
			search: value,
		})
	})
	const [countryParams, setCountryParams] = useState({
		limit: 50,
		offset: 1,
		mastLookupType: 'CNT',
		search: '',
	})
	const onCountrySearch = _.debounce(
		(e, value) => {
			setCountryParams({
				...countryParams,
				search: value,
			})
		},
		[300]
	)
	const [stateParams, setStateParams] = useState({
		limit: 50,
		offset: 1,
		mastCountryCode: '',
		search: '',
	})

	const LanguageSearchFn = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLanguageOptions(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			setLanguageOptions([])
		}
		LookupApi.getLookup('getLookup', { ...languageParams, mastLookupType: 'LAN' }, cancelExecutor).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languageParams, open])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				setCountryCodeList(code)
				if (!isEditMode) {
					let initCode = _.find(code, { country: countryName } || { mastLookupKey: countryCode })
					formik?.setFieldValue('countryCode', initCode)
					formik?.setFieldValue('secondaryCountryCode', initCode)
					setCountryCodeLength(initCode?.mastLookupValue)
					setCountryCodeMinLength(initCode?.minimumLength)
					setSecondaryCountryCodeLenght(initCode?.mastLookupValue)
					setSecondaryCountryCodeMinLenght(initCode?.minimumLength)
					setCountryNameOption(initCode?.country)
					setStateParams((prevState) => ({ ...prevState, mastCountryCode: initCode?.countryCode }))
				} else {
					getPatientDetails(code)
				}
			}
		}
		const onFailure = (err) => {
			setCountryCodeList([])
		}
		if (open || opens) {
			countryLookup.getCountryCode().then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryCode, isEditMode, open, opens, countryName])

	useEffect(() => {
		if (!_.isEmpty(mastuuid)) {
			const onSuccess = (res) => {
				let groupOptions = _.map(res?.data?.data?.TentGroupData, (el) => ({
					uuid: el?.groupUuid,
					tentGroupName: el?.tentGroupName,
					type: el?.type,
				}))
				setGroupOptions(groupOptions)
			}
			if (open || opens) {
				patientDetails.getGroupDetails(mastuuid, { isMenu: false }).then(onSuccess, () => {})
			}
		}
	}, [mastuuid, open, opens])

	const getPatientDetails = useCallback(
		(code) => {
			const onSuccess = (res) => {
				const successData = decryption(res)
				dispatch(hideLoader())
				setPatientInfo(successData?.data)
				secureLocalStorage.setItem('profileViewData', successData?.data)
				dispatch(profileViewData(successData?.data))
				dispatch(setCustomerData(successData?.data))
				formik.setFieldValue('Patientname', successData?.data?.custName)
				formik.setFieldValue('mobileNumber', successData?.data?.custMobileNo)
				formik.setFieldValue('EmailID', successData?.data?.custEmail)
				formik.setFieldValue('pincode', successData?.data?.postalCode || '')
				formik.setFieldValue('Address', successData?.data?.address1)
				let currentIdType = { mastLookupValue: successData?.data?.proofDTO?.mastLookupValue, mastLookupType: 'GID' }
				formik.setFieldValue('nationalityIDType', successData?.data?.proofDTO?.mastLookupValue ? currentIdType : null)
				formik.setFieldValue('nationalityIDValue', successData?.data?.customerIdProof || '')
				formik.setFieldValue('PatientID', successData?.data?.custCustomId)
				formik?.setFieldValue('otherMobileNumber', successData?.data?.custAlternateContactNo)
				formik2?.setFieldValue('relationshipDetails', successData?.data?.customerRelationshipMappingList)
				formik.setFieldValue('age', successData?.data?.custAge)
				formik.setFieldValue('dob', successData?.data?.custDob)
				formik.setFieldValue('doj', successData?.data?.joiningDate)
				formik.setFieldValue('gender', successData?.data?.custGender)
				formik.setFieldValue('insuranceNumber', successData?.data?.insuranceNumber)
				// formik.setFieldValue('insuranceName', successData?.data?.insuranceName)
				let initCode = _.find(code ? code : countryCodeList, { mastLookupKey: successData?.data?.custCountryCode })
				formik?.setFieldValue('countryCode', initCode)
				setCountryCodeLength(initCode?.mastLookupValue)
				setCountryCodeMinLength(initCode?.minimumLength)
				let secInitCode = _.find(code ? code : countryCodeList, { mastLookupKey: successData?.data?.custSecCountryCode || countryCode })
				formik?.setFieldValue('secondaryCountryCode', secInitCode)
				formik?.setFieldValue('country', successData?.data?.country)
				// formik.setFieldValue('country', { country: 'India' })
				formik.setFieldValue('state', successData?.data?.state)
				setStateParams((prevState) => ({ ...prevState, mastCountryCode: successData?.data?.country?.countryCode }))
				let custRelationType = { mastLookupValue: successData?.data?.custRelationType, mastLookupType: 'RLS' }
				let custRelationName = { custName: successData?.data?.custRelationName, custUuid: '' }

				formik?.setFieldValue('relationType', successData?.data?.custRelationType ? custRelationType : null)
				formik?.setFieldValue('relationName', successData?.data?.custRelationName ? custRelationName : null)

				// Client info:
				let foundClientInfo = successData?.data?.custOtherMedicalHistory || ''
				formik?.setFieldValue('clientInfo', foundClientInfo)

				setSecondaryCountryCodeLenght(secInitCode?.mastLookupValue)
				setSecondaryCountryCodeMinLenght(secInitCode?.minimumLength)
				setSmsNotification(successData?.data?.custMobileNotification)
				setEmailNotification(successData?.data?.custEmailNotification)
				setCustSendFollowupNotification(successData?.data?.custSendFollowupNotification)
				setCustBdayNotification(successData?.data?.custBdayNotification)
				setEmailNotification(successData?.data?.custEmailNotification === false ? false : true)
				setUploadUuid(successData?.data?.custLogoUuid || null)
				setReferredBy(successData?.data?.custReferredBy)
				setCustUuid(successData?.data?.custUuid)
				let groupData = _.map(successData?.data?.tentGroupDtoList, (el) => ({
					uuid: el?.uuid,
					tentGroupName: el?.tentGroupName,
					type: el?.type,
				}))
				setGroups(_.uniqBy(groupData, 'uuid'))
				let medData = _.map(successData?.data?.customerMedicalHistoryList, (item) => ({
					...item,
					tentMedicalhistoryName: item?.medicalHistoryName,
				}))
				setMedicalData(_.uniqBy(medData, 'tentMedicalhistoryName') || [])

				let tagData = _.map(successData?.data?.customerTagDtoList, (item) => ({
					...item,
					mastTagName: item?.mastTagName,
				}))
				setTags(_.uniqBy(tagData, 'mastTagName') || [])
			}
			const onFailure = (err) => {
				dispatch(hideLoader())
			}
			if (slotId) {
				dispatch(showLoader('Loading please wait'))
				patientDetails.getSpecificCustomerDetails(slotId).then(onSuccess, onFailure)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[slotId]
	)

	useEffect(() => {
		if (!_.isEmpty(occupationOptions)) {
			let temp = _.find(occupationOptions, { mastLookupValue: patientInfo?.custOccupation })
			setOccupation(temp)
		}
	}, [occupationOptions, isEditMode, patientInfo])

	useEffect(() => {
		if (!_.isEmpty(bloodGroupOptions)) {
			let temp = _.find(bloodGroupOptions, { mastLookupValue: patientInfo?.custBloodGroup })
			setBloodgroup(temp)
		}
	}, [bloodGroupOptions, isEditMode, patientInfo])

	useEffect(() => {
		if (!_.isEmpty(referredByOptions)) {
			let temp = _.find(referredByOptions, { mastLookupValue: patientInfo?.custReferredBy })
			setReferredBy(temp)
		}
	}, [isEditMode, patientInfo, referredByOptions])
	useEffect(() => {
		if (!_.isEmpty(insuranceOptions)) {
			let temp = _.find(insuranceOptions, { mastLookupValue: patientInfo?.insuranceName })
			setInsuranceName(temp)
			formik?.setFieldValue('insuranceName', temp)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditMode, patientInfo, insuranceOptions])
	useEffect(() => {
		if (!_.isEmpty(identityTypes)) {
			let temp = _.find(identityTypes, { mastLookupValue: patientInfo?.nationalityIDType })
			setNationalityIDType(temp)
		}
	}, [isEditMode, patientInfo, identityTypes])

	useEffect(() => {
		if (!_.isEmpty(countryCodeList) && !_.isEmpty(countryNameOption)) {
			let initialLanguageOptions = _.find(countryCodeList, { country: countryNameOption })
			formik?.setFieldValue('country', initialLanguageOptions)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryCodeList, countryNameOption])

	useEffect(() => {
		open && LanguageSearchFn()
	}, [LanguageSearchFn, open])
	const submitAddPatient = (e) => {
		if (e?.mobileNumber === e?.otherMobileNumber) {
			dispatch(
				showMessage({
					message: 'Please enter new alternative mobile number',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		} else {
			dispatch(showLoader('Loading please wait...'))
			let medicalHistorySaveDTO = []
			_.map(medicalData, (item) => {
				if (_.isEmpty(item?.mastMedhistUuid)) {
					medicalHistorySaveDTO.push({
						isNew: true,
						tentMedicalhistoryDesc: item?.inputValue || item?.tentMedicalhistoryName,
						tentMedicalhistoryName: item?.inputValue || item?.tentMedicalhistoryName,
					})
				} else {
					medicalHistorySaveDTO.push({
						isNew: false,
						mastMedhistUuid: item?.mastMedhistUuid,
					})
				}
			})
			const body = {
				leadStatus: 'Customer',
				tentUserUuid: uuid,
				address1: e?.Address || null,
				custAge: e?.age || null,
				custBdayNotification: custBdayNotification || false,
				custBloodGroup: bloodgroup?.mastLookupValue || null,
				custCountryCode: e?.countryCode?.mastLookupKey || null,
				custCustomId: e?.PatientID,
				custCustomIdStatus: isEditPatientStatus || false,
				custDob: e?.dob || null,
				joiningDate: e?.doj || null,
				isAddress: e?.Address || e?.pincode || e?.country ? true : false,
				custEmail: e?.EmailID ? e?.EmailID : null,
				custEmailNotification: custEmailNotification || false,
				custGender: e?.gender || '',
				custLogoUuid: uploadUuid ? uploadUuid : null,
				custMobileNo: e?.mobileNumber,
				custMobileNotification: custSmsNotification || false,
				custName: e?.Patientname,
				custOccupation: occupation?.inputValue || occupation?.mastLookupValue || null,
				custReferredBy: referredBy?.inputValue ? referredBy?.inputValue : referredBy?.mastLookupValue || null,
				custSendFollowupNotification: custSendFollowupNotification || false,
				custStageStatus: 0,
				country: e?.country,
				state: e?.state,
				custSecCountryCode: e?.secondaryCountryCode?.mastLookupKey || null,
				// custOtherMedicalHistory: !groupSelection === 'health care' ? e?.clientInfo : '',
				custOtherMedicalHistory: e?.clientInfo || '',
				langPreference: e?.language?.mastLookupValue || null,
				postalCode: e?.pincode || null,
				userType: 'CUS',
				client: groupSelection === 'health care' ? 'care' : 'noncare',
				custEthnicity: null,
				insuranceNumber: e?.insuranceNumber,
				insuranceName: e?.insuranceName?.inputValue ? e?.insuranceName?.inputValue : e?.insuranceName?.mastLookupValue || '',
				custAlternateContactNo: e?.otherMobileNumber || null,
				proofDTO: {
					mastLookupValue: e?.nationalityIDType || '',
				},
				customerIdProof: e?.nationalityIDType ? e?.nationalityIDValue : null,
				// Membership keys
				membershipName: !_.isEmpty(e?.membershipName) ? e?.membershipName?.membershipName : null,
				tentMembershipUuid: !_.isEmpty(e?.membershipName) ? e?.membershipName?.tentMembershipUuid : null,
				sessionValidity: !_.isEmpty(e?.membershipValidity) ? e?.membershipValidity?.validityPeriod : null,
				totalSessions: !_.isEmpty(e?.membershipValidity) ? e?.membershipValidity?.sessionCount : null,
				price: !_.isEmpty(e?.membershipValidity) ? e?.membershipValidity?.price : null,
				isDays: !_.isEmpty(e?.membershipValidity) ? e?.membershipValidity?.isDays : null,
			}

			const updateBody = {
				tentUserUuid: uuid,
				address1: e?.Address || null,
				custAge: e?.age || null,
				custBdayNotification: custBdayNotification || false,
				custBloodGroup: bloodgroup?.mastLookupValue || null,
				custCountryCode: e?.countryCode?.mastLookupKey || null,
				custCustomId: e?.PatientID,
				custCustomIdStatus: isEditPatientStatus || false,
				custDob: e?.dob || null,
				joiningDate: e?.doj || null,
				isAddress: e?.Address || e?.pincode || e?.country ? true : false,
				custEmail: e?.EmailID ? e?.EmailID : null,
				custEmailNotification: custEmailNotification || false,
				custGender: e?.gender || '',
				custLogoUuid: uploadUuid ? uploadUuid : null,
				custMobileNo: e?.mobileNumber,
				custMobileNotification: custSmsNotification || false,
				custName: e?.Patientname,
				custOccupation: occupation?.inputValue || occupation?.mastLookupValue || null,
				custReferredBy: referredBy?.inputValue ? referredBy?.inputValue : referredBy?.mastLookupValue || null,
				custSendFollowupNotification: custSendFollowupNotification || false,
				custStageStatus: 0,
				genderChanged: _.isEqual(genderChanged, true) ? true : false,
				ageChanged: _.isEqual(ageChanged, true) ? true : false,
				custSecCountryCode: e?.secondaryCountryCode?.mastLookupKey || null,
				// custOtherMedicalHistory: !groupSelection === 'health care' ? e?.clientInfo : '',
				custOtherMedicalHistory: e?.clientInfo || '',
				langPreference: e?.language?.mastLookupValue || null,
				custUuid: custUuid,
				postalCode: e?.pincode || null,
				userType: 'CUS',
				client: groupSelection === 'health care' ? 'care' : 'noncare',
				country: e?.country,
				state: e?.state,
				custEthnicity: null,
				insuranceNumber: e?.insuranceNumber,
				insuranceName: e?.insuranceName?.inputValue ? e?.insuranceName?.inputValue : e?.insuranceName?.mastLookupValue || '',
				medicalHistorySaveDTO: medicalHistorySaveDTO,
				custAlternateContactNo: e?.otherMobileNumber || null,
				proofDTO: {
					mastLookupValue: e?.nationalityIDType || '',
				},
				customerIdProof: e?.nationalityIDType ? e?.nationalityIDValue : null,
				custRelationType: e?.relationType?.mastLookupValue ? e?.relationType?.mastLookupValue : null,
				custRelationName: e?.relationName?.custName ? e?.relationName?.custName : null,
			}
			let decryptKey = isEditMode ? updateBody : body
			let final = encryption(decryptKey)
			const onSuccess = (res) => {
				const successData = decryption(res)
				dispatch(hideLoader())
				if (successData?.status === 'success') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
					if (isEditMode && custUuid) {
						submitMedicalHistory(custUuid)
						submitTags(custUuid)
						secureLocalStorage.setItem('custUuid', `${custUuid}`)
						submitGroupDetails(custUuid)
					} else {
						submitMedicalHistory(successData?.data?.custUuid)
						submitTags(successData?.data?.custUuid)
						submitRelationship(successData?.data?.custUuid)
						submitGroupDetails(successData?.data?.custUuid)
						secureLocalStorage.setItem('custUuid', successData?.data?.custUuid)
					}
					handleClose1 ? handleClose1() : handleClose()
					getPatientIdData()
					formik.resetForm()
					// setIdentityFileResponse('')
					setMedicalData([])
					setOccupation(null)
					setUploadUuid(null)
					setGroups([])
					setTags([])
					setGenderChanged(false)
					setAgeChanged(false)
					getPatientDetails()
					!_.isEmpty(groups) && dispatch(groupSettingsData('Hit API'))
					isEditMode && dispatch(customerListApiTriggerFn('Hit API'))
					setBloodgroup(null)
					formik2.resetForm()

					// successData?.data?.custUuid && groupSelection === 'health care' && history.push(`/patientemr/view/${successData?.data?.custUuid}`)
					// successData?.data?.custUuid && groupSelection !== 'health care' && history.push(`/clientinfo/${successData?.data?.custUuid}`)
					if (successData?.data?.custUuid) {
						// if (groupSelection === 'health care') {
						// 	history.push(`/patientinfo/${successData?.data?.custUuid}`)
						// 	secureLocalStorage.setItem('custUuid', `${successData?.data?.custUuid}`)
						// 	dispatch(customer_Uuid(`${successData?.data?.custUuid}`))
						// } else {
						// 	history.push(`/clientinfo/${successData?.data?.custUuid}`)
						// 	secureLocalStorage.setItem('custUuid', `${successData?.data?.custUuid}`)
						// 	dispatch(customer_Uuid(`${successData?.data?.custUuid}`))
						// }
						history.push(`/clientinfo/${successData?.data?.custUuid}`)
						dispatch(updateCurrentIndex('clients'))
						secureLocalStorage.setItem('custUuid', `${successData?.data?.custUuid}`)
						dispatch(customer_Uuid(`${successData?.data?.custUuid}`))
					}
					setIsNeww(false)
					setIsNewInsurance(false)
					setIsNewIdentityType(false)
				} else if (successData?.status === 'failure') {
					dispatch(hideLoader())
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'error',
						})
					)
				}
			}
			const onFailure = (err) => {
				const failureData = failureLogin(err)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(failureData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}

			isEditMode
				? patientDetails.updatePatientdetails(final?.plainText, final?.publicKey, custUuid).then(onSuccess, onFailure)
				: patientDetails.savePatientDetails(final?.plainText, final?.publicKey, mastuuid).then(onSuccess, onFailure)
		}
	}
	const submitRelationship = (data) => {
		let body = []
		_.map(formik2?.values?.relationshipDetails, (item) => {
			body.push({
				relationshipCode: item?.relationshipCode,
				relationshipName: item?.relationshipName,
				status: true,
			})
		})
		let decryptKey = encryption(body)
		const onSuccess = (res) => {}
		const onFailure = (err) => {}
		!_.isEmpty(formik2?.values?.relationshipDetails?.[0]?.relationshipCode) &&
			patientDetails.saveRelationShipDetails(decryptKey?.plainText, decryptKey?.publicKey, data).then(onSuccess, onFailure)
	}
	const submitMedicalHistory = (data) => {
		let body = []
		_.map(medicalData, (item) => {
			if (_.isEmpty(item?.mastMedhistUuid)) {
				body.push({
					isNew: true,
					tentMedicalhistoryDesc: item?.inputValue || item?.tentMedicalhistoryName,
					tentMedicalhistoryName: item?.inputValue || item?.tentMedicalhistoryName,
				})
			} else {
				body.push({
					isNew: false,
					mastMedhistUuid: item?.mastMedhistUuid,
				})
			}
		})
		let decryptKey = encryption(body)
		const onSuccess = (res) => {}
		!_.isEmpty(medicalData) &&
			patientDetails.saveMedicalHistory(decryptKey?.plainText, decryptKey?.publicKey, mastuuid, data).then(onSuccess, () => {})
	}

	const submitTags = (data) => {
		let body = []
		_.map(tags, (item) => {
			if (_.isEmpty(item?.mastTagUuid)) {
				body.push({
					isNew: true,
					mastTagDesc: item?.inputValue || item?.mastTagName,
					mastTagName: item?.inputValue || item?.mastTagName,
				})
			} else {
				body.push({
					isNew: false,
					mastTagUuid: item?.mastTagUuid,
				})
			}
		})
		const onSuccess = (res) => {
			updateCustDetails()
		}
		patientDetails.saveTagsData(body, mastuuid, data).then(onSuccess, () => {})
	}

	const updateCustDetails = () => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(profileViewData(successData?.data))
		}
		const onFailure = (err) => {
			console.log(err)
		}
		patientDetails.getCustomerMasterDetails(custUuid || slotId).then(onSuccess, onFailure)
	}
	const submitGroupDetails = (data) => {
		let body = []
		let updatedbody = _.filter(groups, (item) => item?.type === 'custom' || item?.new === true)
		isEditMode
			? _.map(updatedbody, (item) => {
					if (item?.new) {
						body.push({
							new: true,
							uuidOrName: item?.uuidOrName,
						})
					} else {
						body.push({
							new: false,
							uuidOrName: item?.uuid,
						})
					}
			  })
			: _.map(groups, (item) => {
					if (item?.new) {
						body.push({
							new: true,
							uuidOrName: item?.uuidOrName,
						})
					} else {
						body.push({
							new: false,
							uuidOrName: item?.uuid,
						})
					}
			  })
		// let final = isEditMode ? updatedbody : body
		// let decryptKey = encryption(final)
		!_.isEmpty(groups) && isEditMode ? patientDetails.saveGroupDetails(updatedbody, data) : patientDetails.saveGroupDetails(body, data)
	}

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				setFileCategoryList(res?.data?.data)
			} else {
				setFileCategoryList([])
			}
		}
		if (open || opens) {
			fileUploadDetailsApi.getFileUploadDetails(uuid).then(onSuccess, () => {})
		}
	}, [open, opens, uuid])

	const displayIdentityImage = (acceptedFiles) => {
		dispatch(showLoader('Uploading image please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				// setIdentityFileResponse(res?.data?.data?.uuid)
				setUploadUuid(res?.data?.data?.uuid)
			} else {
				// setIdentityFileResponse([])
				setUploadUuid()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.message, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'PERSONAL')
		let categoryUuid = identityCategoryId?.uuid
		var formData1 = new FormData()
		formData1.append('FILE', acceptedFiles[0])
		sendUploadFileApi.sendUploadFile(mastuuid, uuid, categoryUuid, formData1).then(onSuccess, onFailure)
	}

	const editModeClose = () => {
		handleClose()
		// setIdentityFileResponse([])
		formik.resetForm()
		setUploadUuid(null)
		setIsNeww(false)
		setIsNewIdentityType(false)
		setIsNewInsurance(false)
		setInsuranceNumber(null)
		setInsuranceName('')
		setNationalityIDType('')
	}
	const addModeClose = () => {
		handleClose1()
		formik.resetForm()
		// setIdentityFileResponse([])
		setUploadUuid(null)
		setIsNeww(false)
		setIsNewIdentityType(false)
		setIsNewInsurance(false)
		setInsuranceNumber(null)
		setInsuranceName('')
		setNationalityIDType('')
	}

	const handleKeyDown = (e) => {
		if (e.key === ' ' && formik?.values?.Patientname.length === 0) {
			e.preventDefault()
		}
	}

	const handleKeyDownAge = (e) => {
		if ((e.which === 96 && formik?.values?.age?.length === 0) || (e.which === 48 && formik?.values?.age?.length === 0)) {
			e.preventDefault()
		}
	}

	useEffect(() => {
		if (!_.isEmpty(referredByOptions)) {
			let temp = _.find(referredByOptions, { mastLookupValue: 'Google' })
			setReferredBy(temp)
		}
	}, [referredByOptions])

	//FILE UPLOAD
	const handleUploadingFileToTenant = (files) => {
		dispatch(showLoader('Uploading image please wait...'))
		let formData = new FormData()
		_.map(files, (file) => {
			formData.append('FILE', file)
		})
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				// setIdentityFileResponse(res?.data?.data?.uuid)
				setUploadUuid(res?.data?.data?.uuid)
				setCamOpen(false)
				setCaptureImg(null)
				setImg(null)
			} else {
				// setIdentityFileResponse([])
				setUploadUuid()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'PERSONAL')
		let categoryUuid = identityCategoryId?.uuid
		var formData1 = new FormData()
		formData1.append('FILE', files[0])
		sendUploadFileApi.sendUploadFile(mastuuid, uuid, categoryUuid, formData1).then(onSuccess, onFailure)
	}
	// GET INSURANCE DROP DOWN
	const getInsuranceDataField = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setInsuranceOptions(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		if (open || opens) {
			countryLookup.getInsuranceField({ mastLookupType: 'MYS', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, opens])

	//GET DOCUMENT TYPE DROPDOWN
	const getDoucmentTypeDataField = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setIdentityTypes(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		if (open || opens) {
			countryLookup.getInsuranceField({ mastLookupType: 'GID', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, opens])

	// GET REFERED BY DROPDOWN
	const getReferedByField = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setReferredByOptions(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		if (open || opens) {
			countryLookup.getInsuranceField({ mastLookupType: 'RFD', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, opens])
	// GET REFERED BY DROPDOWN
	const getCustomersByMobNo = useCallback(() => {
		let body = {
			tentUuid: mastuuid,
			mobNumber: formik?.values?.mobileNumber,
			custUuid: custUuid,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'STATUS_SUCCESS') {
				setRelationTypeOption(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		if (isEditMode) {
			patientDetails.saveCustomersByMobNo(body).then(onSuccess, onFailure)
		}
	}, [custUuid, formik?.values?.mobileNumber, isEditMode, mastuuid])
	// GET REFERED BY DROPDOWN
	const getRelationShipField = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setChooseRelationOption(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		if (isEditMode) {
			countryLookup.getInsuranceField({ mastLookupType: 'RLS', mastTentUuid: mastuuid }).then(onSuccess, onFailure)
		}
	}, [isEditMode, mastuuid])
	useEffect(() => {
		getRelationShipField()
	}, [getRelationShipField])
	useEffect(() => {
		getCustomersByMobNo()
	}, [getCustomersByMobNo])
	useEffect(() => {
		getDoucmentTypeDataField()
	}, [getDoucmentTypeDataField])
	useEffect(() => {
		getReferedByField()
	}, [getReferedByField])
	const deleteInsuranceField = (mastLookupUuid) => {
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res.data.status === true) {
				getInsuranceDataField()
			}
		}
		const onFailure = () => {
			dispatch(hideLoader())
		}
		countryLookup.deleteInsurance({ mastLookupType: 'MYS', mastTentUuid: mastuuid, mastLookUuid: mastLookupUuid }).then(onSuccess, onFailure)
	}
	useEffect(() => {
		getInsuranceDataField()
	}, [getInsuranceDataField])
	return (
		<div className={classes.root}>
			<CustomDialog
				className={classes.mainContain}
				headerText={
					groupSelection === 'health care'
						? isEditMode
							? 'Edit patient details'
							: 'Add patient details'
						: isEditMode
						? 'Edit client details'
						: 'Add client details'
				}
				open={isEditMode ? opens : open}
				onClose={isEditMode || false ? () => editModeClose() : () => addModeClose()}
				onSubmit={() => formik?.handleSubmit()}
				maxWidth={'md'}
				isNewUser={isNewUser}
				formikValues={formik}
				hideSaveAndAddBtn={isEditMode}
			>
				<form onSubmit={() => formik?.handleSubmit()} autoComplete='off'>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} className={classes.modelAlignment}>
						<Grid container xs={12} spacing={3}>
							<Grid item xs={2}>
								<DropZone
									imgResponse={uploadUuid}
									displayUploadImage={displayIdentityImage}
									setUploadUuid={setUploadUuid}
									// setIdentityFileResponse={setIdentityFileResponse}
									captureImg={captureImg}
									setCaptureImg={setCaptureImg}
									img={img}
									setImg={setImg}
									camOpen={camOpen}
									setCamOpen={setCamOpen}
									handleUploadingFileToTenant={handleUploadingFileToTenant}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									id='Patientname'
									name='Patientname'
									size='small'
									label={`${groupSelection === 'health care' ? 'Patient name *' : 'Client name *'}`}
									color='secondary'
									value={formik.values.Patientname}
									onKeyDown={(e) => {
										handleKeyDown(e)
									}}
									onChange={(e) => formik?.setFieldValue('Patientname', e.target.value.replace(/[^A-Za-z0-9.,/\-_ ]/gi, ''))}
									fullWidth
									variant='outlined'
									InputProps={{ className: classes.input }}
									error={formik.touched.Patientname && formik.errors.Patientname}
									helperText={formik.touched.Patientname && formik.errors.Patientname && formik.errors.Patientname}
								/>
								<Grid xs={12} style={{ paddingBlock: 24 }}>
									<Typography variant='body1'>
										{`${groupSelection === 'health care' ? 'Patient ID' : 'Client ID'}`}
										<span style={{ color: '#0062DD', paddingLeft: 12, fontWeight: '600' }}>{formik?.values?.PatientID}</span>
									</Typography>
								</Grid>
								<Grid xs={12}>
									<Grid>
										<CustomTextFieldWithCountryCode
											id='mobileNumber'
											name='mobileNumber'
											size='small'
											type='tel'
											value={formik?.values?.mobileNumber}
											onChange={(e) => formik?.setFieldValue('mobileNumber', e.target.value.replace(/[^0-9]/g, ''))}
											label='Mobile number  *'
											fullWidth
											color='secondary'
											variant='outlined'
											countryCodeValue={formik?.values?.countryCode}
											countryCodeOnChange={(e, newValue) => {
												setStateParams((prevState) => ({ ...prevState, mastCountryCode: newValue?.countryCode }))
												formik?.setFieldValue('countryCode', newValue)
												setCountryCodeLength(newValue?.mastLookupValue)
												setCountryCodeMinLength(newValue?.minimumLength)
												setCountryNameOption(newValue?.country)
												formik.setFieldValue('state', '')
											}}
											countryCodeList={countryCodeList}
											error={formik.touched.mobileNumber && formik.errors.mobileNumber}
											helperText={formik.touched.mobileNumber && formik.errors.mobileNumber && formik.errors.mobileNumber}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={5}>
								<FormControl component='fieldset'>
									<FormLabel component='legend' color='secondary'>
										Gender
									</FormLabel>
									<RadioGroup row aria-label='gender' value={formik.values.gender} name='row-radio-buttons-group'>
										<FormControlLabel
											value='M'
											id='male'
											name='male'
											control={<Radio size='small' classes={{ root: classes.radio, checked: classes.checked }} />}
											label='Male'
											onChange={(e, value) => {
												formik.setFieldValue('gender', e.target.value)
												setGenderChanged(true)
											}}
										/>
										<FormControlLabel
											value='F'
											id='female'
											name='female'
											control={<Radio size='small' classes={{ root: classes.radio, checked: classes.checked }} />}
											label='Female'
											onChange={(e, value) => {
												formik.setFieldValue('gender', e.target.value)
												setGenderChanged(true)
											}}
										/>
										<FormControlLabel
											value='O'
											id='others'
											name='others'
											control={<Radio size='small' classes={{ root: classes.radio, checked: classes.checked }} />}
											label='Others'
											onChange={(e, value) => {
												formik.setFieldValue('gender', e.target.value)
												setGenderChanged(true)
											}}
										/>
									</RadioGroup>
								</FormControl>
								<Grid style={{ display: 'flex', gap: 30, paddingBlockStart: 47 }}>
									<Grid item xs={6}>
										<TextField
											id={`age`}
											name={`age`}
											label='Age'
											size='small'
											color='secondary'
											onKeyDown={(e) => {
												handleKeyDownAge(e)
											}}
											value={_.isEqual(formik?.values?.age, null) ? '' : formik?.values?.age}
											inputProps={{ maxLength: 5 }}
											onChange={(e) => {
												var t = e.target.value
												e.target.value = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 2) : t
												setAgeChanged(true)
												formik.setFieldValue('dob', null)
												formik?.setFieldValue('age', e.target.value.replace(/[^.0-9]/g, ''))
											}}
											helperText={formik?.touched?.price && formik?.errors?.price && formik?.errors?.price}
											variant='outlined'
											InputLabelProps={{
												shrink: formik?.values?.age || formik?.values?.age === 0 ? true : false,
											}}
											fullWidth
										/>
									</Grid>
									{isEditMode ? (
										<></>
									) : (
										<Grid item xs={6}>
											<CancelBtn disabled={false} onClick={() => formik?.setFieldValue('addMore', !formik?.values?.addMore)}>
												{formik?.values?.addMore ? '-  Show less' : '+  Add more'}
											</CancelBtn>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
						{(formik?.values?.addMore || isEditMode) && (
							<>
								<Grid container spacing={3} className={classes.addMoreContainer}>
									{!isEditMode && (
										<>
											{/* Membership  */}
											<Grid item xs={6}>
												<Autocomplete
													id='membershipName'
													name='membershipName'
													options={memberShipList || []}
													disabled={_.isEmpty(memberShipList)}
													getOptionLabel={(option) => option?.membershipName || ''}
													isOptionEqualToValue={(option, value) => option?.membershipName === value?.membershipName}
													value={formik?.values?.membershipName}
													onChange={(e, value) => {
														formik?.setFieldValue('membershipName', value)
														formik?.setFieldValue('membershipValidity', value?.tentSettingsValidityDTOList?.at(0))
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label='Select membership'
															color='secondary'
															size='small'
															error={formik.touched.membershipName && formik.errors.membershipName}
															helperText={formik.touched.membershipName && formik.errors.membershipName && formik.errors.membershipName}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={6}>
												<Autocomplete
													id='validity'
													name='validity'
													options={formik?.values?.membershipName?.tentSettingsValidityDTOList || []}
													disabled={_.isEmpty(formik?.values?.membershipName?.tentSettingsValidityDTOList)}
													disableClearable='true'
													getOptionLabel={(option) => {
														const validityPeriod = option.validityPeriod || ''
														return option.isDays ? `${validityPeriod} days` : `${validityPeriod} months`
													}}
													isOptionEqualToValue={(option, value) => option?.price === value?.price}
													value={formik?.values?.membershipValidity}
													onChange={(e, value) => {
														formik?.setFieldValue('membershipValidity', value)
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label={_.isEmpty(formik?.values?.membershipName) ? 'Select validity' : 'Select validity *'}
															color='secondary'
															size='small'
															error={formik.touched.membershipValidity && formik.errors.membershipValidity}
															helperText={formik.touched.membershipValidity && formik.errors.membershipValidity && formik.errors.membershipValidity}
														/>
													)}
												/>
											</Grid>
										</>
									)}

									{/* otherMobileNumber */}
									<Grid item xs={6}>
										<CustomTextFieldWithCountryCode
											id='otherMobileNumber'
											name='otherMobileNumber'
											size='small'
											type='tel'
											value={formik?.values?.otherMobileNumber}
											onChange={(e) => formik?.setFieldValue('otherMobileNumber', e.target.value.replace(/[^0-9]/g, ''))}
											label='Alternate Mobile number'
											fullWidth
											color='secondary'
											variant='outlined'
											// disableCountryCode={true}
											countryCodeValue={formik?.values?.secondaryCountryCode}
											countryCodeOnChange={(e, newValue) => {
												formik?.setFieldValue('secondaryCountryCode', newValue)
												setSecondaryCountryCodeLenght(newValue?.mastLookupValue)
												setSecondaryCountryCodeMinLenght(newValue?.minimumLength)
											}}
											countryCodeList={countryCodeList}
											error={formik.touched.otherMobileNumber && formik.errors.otherMobileNumber}
											helperText={formik.touched.otherMobileNumber && formik.errors.otherMobileNumber && formik.errors.otherMobileNumber}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											id='EmailID'
											size='small'
											color='secondary'
											label='Email'
											value={formik?.values?.EmailID}
											onChange={(e) => formik?.setFieldValue('EmailID', e.target.value)}
											fullWidth
											variant='outlined'
											InputProps={{ className: classes.input }}
											error={formik.touched.EmailID && formik.errors.EmailID}
											helperText={formik.touched.EmailID && formik.errors.EmailID && formik.errors.EmailID}
										/>
									</Grid>
									<Grid item xs={6}>
										<CustomDatePicker
											label={dateFormat.toUpperCase()}
											value={formik?.values?.dob}
											color='secondary'
											inputFormat={dateFormat}
											format={dateFormat}
											disableFuture={true}
											onAccept={(newValue) => {
												formik.setFieldValue('dob', moment(newValue).format('YYYY-MM-DD'))
												setAgeChanged(true)
												formik.setFieldValue('age', moment()?.diff(moment(newValue, 'YYYYMMDD'), 'years') || '0')
											}}
											minDate={new Date(moment().subtract(100, 'Y').format('YYYY-MM-DD'))}
											maxDate={new Date()}
											showTodayButton={false}
											error={formik.touched.dob && formik.errors.dob}
											helperText={formik.touched.dob && formik.errors.dob && formik.errors.dob}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											id='BloodGroup'
											name='BloodGroup'
											options={bloodGroupOptions}
											fullWidth
											clearIcon=''
											disableClearable
											getOptionLabel={(option) => option?.mastLookupValue}
											isOptionEqualToValue={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
											value={bloodgroup || null}
											onChange={(e, value) => {
												setBloodgroup(value)
											}}
											renderInput={(params) => <TextField {...params} size='small' color='secondary' label='Blood group ' />}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											id='nationalityIDType'
											name='nationalityIDType'
											size='small'
											fullWidth
											options={identityTypes || []}
											// getOptionLabel={(option) => option?.mastLookupValue || ''}
											value={formik?.values?.nationalityIDType}
											filterSelectedOptions={true}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
													return option
												}
												if (option?.inputValue) {
													return option?.inputValue
												}
												return option?.mastLookupValue
											}}
											onChange={(e, value) => {
												setNationalityIDType(value?.inputValue ? value?.inputValue : value?.mastLookupValue)
												formik?.setFieldValue('nationalityIDType', value?.inputValue ? value?.inputValue : value?.mastLookupValue)
												if (value?.inputValue) {
													setIsNewIdentityType(true)
												} else {
													setIsNewIdentityType(false)
												}
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const { inputValue } = params
												const isExisting = options.includes((option) => inputValue === option?.mastlookupValue)
												if (inputValue.trim() !== '' && !isExisting) {
													let format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.0-9<>/?]*$/
													if (!inputValue.match(format)) {
														filtered.push({
															inputValue,
															mastLookupValue: `Add '${inputValue}'`,
														})
													}
												}
												return filtered
											}}
											renderOption={(props, option) => <li {...props}>{option?.mastLookupValue}</li>}
											renderInput={(params) => {
												if (!isNewIdentityType) {
													params.InputProps.endAdornment = (
														<InputAdornment position='end' size='small'>
															{/* <KeyboardArrowDownIcon /> */}
														</InputAdornment>
													)
												} else {
													params.InputProps.endAdornment = (
														<InputAdornment position='end' size='small'>
															<Typography
																variant='body'
																style={{ color: '#FFF', background: '#28A745', width: 'fit-content', padding: '2px 8px', borderRadius: 4 }}
															>
																New
															</Typography>
														</InputAdornment>
													)
												}

												return <TextField {...params} fullWidth color='secondary' label='Document type' />
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											name='last4Digits'
											id='last4Digits'
											size='small'
											color='secondary'
											label='ID number'
											fullWidth
											inputProps={{ maxLength: 50 }}
											value={formik?.values?.nationalityIDValue}
											onChange={(e, value) =>
												formik?.setFieldValue('nationalityIDValue', e.target.value.replace(/[^A-Za-z0-9 ]/gi, '').toUpperCase())
											}
											error={formik.touched.nationalityIDValue && formik.errors.nationalityIDValue}
											helperText={formik.touched.nationalityIDValue && formik.errors.nationalityIDValue && formik.errors.nationalityIDValue}
										/>
									</Grid>
									<Grid item xs={6} sm={6} style={{ marginBlockStart: 10 }}>
										<Autocomplete
											id='country'
											name='country'
											options={countryCodeList}
											disableClearable
											autoHighlight
											openOnFocus
											getOptionLabel={(option) => option?.country || ''}
											value={formik?.values?.country}
											onChange={(e, value) => {
												formik?.setFieldValue('country', value)
												setCountryParams((prevState) => ({ ...prevState, search: value?.country }))
												setStateParams((prevState) => ({ ...prevState, mastCountryCode: value?.countryCode }))
												formik.setFieldValue('state', '')
												// formik?.setFieldValue('pincode', '')
											}}
											onInputChange={onCountrySearch}
											renderInput={(params) => (
												<TextField
													{...params}
													color='secondary'
													size='small'
													className={classes.textField}
													label='Country'
													error={formik.touched.country && formik.errors.country}
													helperText={formik.touched.country && formik.errors.country}
													inputProps={{
														...params.inputProps,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											variant='outlined'
											type='text'
											id='Postal Code'
											size='small'
											name='pincode'
											color='secondary'
											className='mt-3 mb-2'
											inputProps={{ maxlength: 6 }}
											value={formik?.values?.pincode}
											onChange={(e) => formik?.setFieldValue('pincode', e.target.value.replace(/[^0-9]/, ''))}
											fullWidth
											label='Postcode'
											InputProps={{ className: classes.input }}
											error={formik?.touched?.pincode && formik?.errors?.pincode}
											helperText={formik?.touched?.pincode && formik?.errors?.pincode && formik?.errors?.pincode}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											label='Address '
											size='small'
											type='text'
											id='Address'
											name='Address'
											color='secondary'
											value={formik?.values?.Address}
											onChange={formik.handleChange}
											error={formik.touched.Address && formik.errors.Address}
											helperText={formik.touched.Address && formik.errors.Address && formik.errors.Address}
											inputProps={{ className: classes.input }}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Autocomplete
											name='state'
											id='state'
											options={statesOption}
											disableClearable='true'
											openOnFocus
											noOptionsText={_.isEmpty(formik.values.country) ? 'Please select country' : 'No options'}
											getOptionLabel={(option) => option?.mastState || ''}
											value={formik?.values?.state}
											onChange={(e, value) => {
												formik?.setFieldValue('state', value || '')
												// formik?.setFieldValue('pincode', '')
											}}
											getOptionSelected={(option, value) => {
												return option?.mastState === value?.mastState
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													color='secondary'
													size='small'
													className={classes.textField}
													label='State'
													error={formik.touched.state && formik.errors.state}
													helperText={formik.touched.state && formik.errors.state && formik.errors.state}
													inputProps={{
														...params.inputProps,
													}}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6} style={{ marginBlockStart: 10 }}>
										<AddressFile
											occupationOptions={_.sortBy(occupationOptions, 'mastLookupValue')}
											occupation={occupation}
											setOccupation={setOccupation}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											id='Language'
											value={formik?.values?.language}
											options={languageOptions}
											clearIcon=''
											disableClearable
											disabled
											getOptionLabel={(option) => option?.mastLookupValue}
											getOptionSelected={(option) => option?.mastLookupValue === option?.mastlookupValue}
											fullWidth
											color='secondary'
											onChange={(e, value) => {
												formik?.setFieldValue('language', value)
												onLanguageSearch((prevState) => ({ ...prevState, search: value?.mastLookupValue }))
											}}
											onInputChange={languageSearch}
											renderInput={(params) => (
												<TextField {...params} size='small' className='mt-3 mb-2' color='secondary' label='Language preference ' />
											)}
										/>
									</Grid>
									<Grid item xs={6} className={classes.otherNumber}>
										<MedicalhistoryComp medicalData={medicalData} setMedicalData={setMedicalData} medicalHistory={medicalHistory} />
									</Grid>

									<Grid item xs={6} sm={6} style={{ marginBlockStart: 3 }}>
										<GroupComp groupOptions={groupOptions} groups={groups} setGroups={setGroups} isEditMode={isEditMode} />
									</Grid>

									<Grid item xs={6} style={{ marginBlockStart: 6 }}>
										<TextField
											id='insuranceNumber'
											name='insuranceNumber'
											size='small'
											color='secondary'
											label='Insurance number'
											value={formik?.values?.insuranceNumber}
											onChange={(e) => {
												formik?.setFieldValue('insuranceNumber', e.target.value.replace(/[^A-Z0-9/ ]/gi, ''))

												setInsuranceNumber(e.target.value.replace(/[^A-Z0-9/ ]/gi, ''))
											}}
											fullWidth
											variant='outlined'
											inputProps={{
												style: { textTransform: 'uppercase' },
											}}
											InputLabelProps={{
												shrink: formik?.values?.insuranceNumber || formik?.values?.insuranceNumber === 0 ? true : false,
											}}
											error={formik.touched.insuranceNumber && formik.errors.insuranceNumber}
											helperText={formik.touched.insuranceNumber && formik.errors.insuranceNumber && formik.errors.insuranceNumber}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											id='insuranceName'
											options={insuranceOptions}
											value={formik?.values?.insuranceName}
											color='secondary'
											disableClearable={false}
											openOnFocus
											isOptionEqualToValue={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
													return option
												}
												if (option.inputValue) {
													return option.inputValue
												}
												return option.mastLookupValue
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const { inputValue } = params
												const isExisting = options.includes((option) => inputValue === option.mastLookupValue)
												if (inputValue.trim() !== '' && !isExisting) {
													let format = /^[><@#$]*$/
													if (!inputValue.match(format)) {
														filtered.push({
															inputValue,
															mastLookupValue: `Add "${inputValue}"`,
														})
													}
												}
												return filtered
											}}
											onChange={(e, value) => {
												setInsuranceName(value || value?.mastLookupValue)
												formik?.setFieldValue('insuranceName', value || value?.mastLookupValue)
												if (value?.inputValue) {
													setIsNewInsurance(true)
												} else {
													setIsNewInsurance(false)
												}
											}}
											renderOption={(props, option) => (
												<li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
													{option.mastLookupValue}
													{option?.mastTentUuid && (
														<IconButton
															size='small'
															color='secondary'
															onClick={(e) => {
																e.stopPropagation()
																deleteInsuranceField(option.mastLookupUuid)
															}}
														>
															<DeleteIcon />
														</IconButton>
													)}
												</li>
											)}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														label='Insurance name'
														className='mt-2 mb-1'
														size='small'
														color='secondary'
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{loadingInsurance ? (
																		<CircularProgress color='inherit' className={classes.circular_progress} size={20} />
																	) : isNewInsurance ? (
																		<Typography
																			variant='body'
																			style={{ color: '#FFF', background: '#28A745', width: 'fit-content', padding: '2px 8px', borderRadius: 4 }}
																		>
																			New
																		</Typography>
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															),
														}}
														error={formik.touched.insuranceName && formik.errors.insuranceName}
														helperText={formik.touched.insuranceName && formik.errors.insuranceName && formik.errors.insuranceName}
													/>
												)
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											id='referredBy'
											options={referredByOptions}
											value={referredBy}
											color='secondary'
											disableClearable={false}
											openOnFocus
											isOptionEqualToValue={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
													return option
												}
												if (option.inputValue) {
													return option.inputValue
												}
												return option.mastLookupValue
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const { inputValue } = params
												const isExisting = options.includes((option) => inputValue === option.mastLookupValue)
												if (inputValue.trim() !== '' && !isExisting) {
													let format = /^[><@#$]*$/
													if (!inputValue.match(format)) {
														filtered.push({
															inputValue,
															mastLookupValue: `Add "${inputValue}"`,
														})
													}
												}
												return filtered
											}}
											renderOption={(props, option) => <li {...props}>{option.mastLookupValue}</li>}
											onChange={(e, value) => {
												setReferredBy(value || value?.mastLookupValue)
												if (value?.inputValue) {
													setIsNeww(true)
												} else {
													setIsNeww(false)
												}
											}}
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														label='Referred by'
														className='mt-2 mb-1'
														size='small'
														color='secondary'
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{loading ? (
																		<CircularProgress color='inherit' className={classes.circular_progress} size={20} />
																	) : isNeww ? (
																		<Typography
																			variant='body'
																			style={{ color: '#FFF', background: '#28A745', width: 'fit-content', padding: '2px 8px', borderRadius: 4 }}
																		>
																			New
																		</Typography>
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															),
														}}
													/>
												)
											}}
										/>
										{/* <Autocomplete
											id='referredBy'
											options={referredByOptions}
											value={referredBy || null}
											color='secondary'
											// clearIcon=''
											disableClearable={false}
											openOnFocus
											isOptionEqualToValue={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
											getOptionLabel={(option) => {
												if (typeof option === 'string') {
													return option
												}
												if (option.inputValue) {
													return option.inputValue
												}
												return option.mastLookupValue
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const { inputValue } = params
												const isExisting = options.includes((option) => inputValue === option.mastLookupValue)
												if (inputValue.trim() !== '' && !isExisting) {
													let format = /^[><@#$]*$/
													if (!inputValue.match(format)) {
														filtered.push({
															inputValue,
															mastLookupValue: `Add "${inputValue}"`,
														})
													}
												}
												return filtered
											}}
											onChange={(e, value) => {
												setReferredBy(value || value?.mastLookupValue)
												if (value?.inputValue) {
													setIsNeww(true)
												} else {
													setIsNeww(false)
												}
											}}
											renderOption={(props, option) => <li {...props}>{option.mastLookupValue}</li>}
											renderInput={(params) => {
												if (loading) {
													params.InputProps.endAdornment = (
														<InputAdornment position='end' size='small'>
															<CircularProgress style={{ color: 'red' }} size={16} />
														</InputAdornment>
													)
												} else if (!isNeww) {
													params.InputProps.endAdornment = (
														<InputAdornment position='end' size='small'>
															<SearchIcon />
														</InputAdornment>
													)
												} else {
													params.InputProps.endAdornment = (
														<InputAdornment position='end' size='small'>
															<Typography
																variant='body'
																style={{ color: '#FFF', background: '#28A745', width: 'fit-content', padding: '2px 8px', borderRadius: 4 }}
															>
																New
															</Typography>
														</InputAdornment>
													)
												}
												return <TextField {...params} label='Referred by ' className='mt-2 mb-1' size='small' color='secondary' />
											}}
										/> */}
									</Grid>
									<Grid item xs={6}>
										<CustomDatePicker
											label='Date of join'
											value={formik?.values?.doj}
											color='secondary'
											inputFormat={dateFormat}
											format={dateFormat}
											disablePast={false}
											disableFuture={false}
											onAccept={(newValue) => {
												formik.setFieldValue('doj', moment(newValue).format('YYYY-MM-DD'))
											}}
											showTodayButton={false}
											error={formik.touched.doj && formik.errors.doj}
											helperText={formik.touched.doj && formik.errors.doj && formik.errors.doj}
										/>
									</Grid>
									<Grid item xs={6}>
										<TagComponent tagOptions={tagOptions} tags={tags} setTags={setTags} isEditMode={isEditMode} />
									</Grid>

									{isEditMode && (
										<Grid item xs={6}>
											<Autocomplete
												id='relationType'
												name='relationType'
												options={chooseRelationOption}
												fullWidth
												clearIcon=''
												disableClearable
												getOptionLabel={(option) => option?.mastLookupValue}
												isOptionEqualToValue={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
												value={formik?.values?.relationType}
												onChange={(e, value) => {
													formik?.setFieldValue('relationType', value)
												}}
												renderInput={(params) => <TextField {...params} size='small' color='secondary' label='Relation type' />}
											/>
										</Grid>
									)}
									{isEditMode && (
										<Grid item xs={6}>
											<Autocomplete
												id='relationName'
												name='relationName'
												options={relationTypeOption}
												fullWidth
												clearIcon=''
												disableClearable
												getOptionLabel={(option) => option?.custName}
												isOptionEqualToValue={(option, value) => option?.custName === value?.custName}
												value={formik?.values?.relationName}
												onChange={(e, value) => {
													formik?.setFieldValue('relationName', value)
												}}
												renderInput={(params) => <TextField {...params} size='small' color='secondary' label='Relation name' />}
											/>
										</Grid>
									)}
									{groupSelection !== 'health care' && (
										<Grid item xs={6} className={classes.otherNumber} style={{ marginBlockStart: 6 }}>
											<ClientInfo formik={formik} />
										</Grid>
									)}
								</Grid>
							</>
						)}
					</Grid>
				</form>
			</CustomDialog>
			<Lookup
				open={open}
				opens={opens}
				mastuuid={mastuuid}
				setStatesOption={setStatesOption}
				countryParams={countryParams}
				stateParams={stateParams}
				setMemberShipList={setMemberShipList}
			/>
		</div>
	)
}
export default AddPatient
