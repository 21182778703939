import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	timingHeader: {
		gap: 20,
		marginBottom: theme.spacing(1.5),
		[theme.breakpoints.down('sm')]: {
			gap: 8,
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {
			gap: 8,
			display: 'block',
		},
		'& .MuiTextField-root': {
			[theme.breakpoints.down('sm')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.down('md')]: {
				paddingBlock: 8,
			},
		},
	},
	timingPaymentsViewEdit: {
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
		'& .MuiTextField-root': {
			[theme.breakpoints.down('sm')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.down('md')]: {
				paddingBlock: 8,
			},
		},
	},
	noSlotsTxt: {
		padding: theme.spacing(1),
	},
}))
export default useStyles
