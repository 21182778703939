import { Card, CardContent, Button, Typography, IconButton } from '@mui/material'
import ButtonComp from '../../sharedComponents/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/material/styles'
import { useEffect, useState, useCallback } from 'react'
import { showMessage } from 'app/store/fuse/messageSlice'
import OtpInput from 'react-otp-input'
import history from '@history'
import { useDispatch, useSelector } from 'react-redux'
import LoginApi from '../../../services/login/api'
import OtpApi from '../../../services/otp/api'
import { Otp, updateDeviceId, updatePracticeDetails } from 'app/session/actions'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'
import moment from 'moment'
import { detect } from 'detect-browser'
import { encryption, decryption, failureLogin, getResponseMessage } from 'utils'
import { updateIswelcome } from 'app/session/actions'
import themesConfig from 'app/fuse-configs/themesConfig'
import EmailVerificationModal from '../EmailVerificationModal/EmailVerificationModal'
import secureLocalStorage from 'react-secure-storage'
import { setVerifyModal } from 'services/VerficationModal/actions'
import DynamicMenuApi from '../../../services/DynamicMenu/api'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import { getMenuFeatures } from 'utils'
import OTPImg from 'images/login/OTPVerify.png'

const Root = styled('div')(({ theme }) => ({
	background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,

	'& .Otp-leftSection': {
		[theme.breakpoints.down('sm')]: {
			borderRadius: '2rem',
		},
		[theme.breakpoints.down('md')]: {
			borderRadius: '2rem',
		},
	},
	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	'& .cardContent': {
		margin: '0px auto',
		paddingInline: '3rem',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	'& .numbercontent': {
		justifyContent: 'center',
		alignItems: 'center',
	},
	'& .text': {
		margin: '16px 0',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid #6b7280',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .resendButton': {
		cursor: 'pointer',
		color: themesConfig.greyDark.palette.primary.link,
	},
	'& .backButton': {
		background: themesConfig.greyDark.palette.primary.main,
		marginInline: '20px',
		color: '#fff',
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
function OtpModule() {
	const [otpNumber, setOtpNumber] = useState('')
	const [otpError, setOtpError] = useState('')
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const errorJson = useSelector((state) => state.errorMessages || {})
	const geoInfo = useSelector((state) => state?.GeoInfo?.geoInfo)
	const {
		isEmail,
		isPractice,
		isWelcome,
		uuid,
		userType,
		mobileNo,
		userRole,
		isOtp,
		AidiPracticeDetails,
		groupId,
		countryCode,
		userEmail,
		identityUuid,
		newOwner,
	} = state

	const handleChange = (value) => setOtpNumber(value)
	const browser = detect()
	const [deviceData, setDeviceData] = useState({})

	useEffect(() => {
		const onSuccess = (res) => {
			setDeviceData(res?.data)
		}
		const onFailure = (err) => {
			console.log(err)
			setDeviceData({})
		}
		LoginApi.getDeviceData().then(onSuccess, onFailure)
	}, [])

	const roleNavigated = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		let uuid = secureLocalStorage.getItem('uuid')
		let mastUuid = secureLocalStorage.getItem('mastuuid')
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(dynamicMenuFetching())
		DynamicMenuApi.getDynamicMenu(uuid, mastUuid).then(onSuccess, onFailure)
	}, [dispatch])

	useEffect(() => {
		if (uuid === '' || uuid === undefined) {
			history.push('/')
		}
	}, [uuid])

	// const [generate, setGenerate] = useState(false)

	// eslint-disable-next-line no-undef
	const updateDeviceDetails = (userType, uuid) => {
		const os = browser.os
		const osName = os.split(' ')
		dispatch(showLoader('Loading please wait...'))
		const body = {
			browserName: browser.name,
			deviceId: '',
			deviceName: '',
			deviceToken: '',
			ipAddress: deviceData?.ipAddress || '',
			locCity: deviceData?.cityName || '',
			locCountry: deviceData?.countryName || '',
			locRegion: deviceData?.regionName || '',
			loginTime: moment().format(),
			logoutTime: '',
			model: '',
			osName: os,
			osVersion: osName[1],
			qrStatus: false,
			sessionDetails: '',
			status: true,
			version: '',
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				if (_.isEqual(newOwner, true)) {
					// setGenerate(true)
					dispatch(hideLoader())
					secureLocalStorage.setItem('AidiDeviceId', successData?.data?.deviceUuid)
					dispatch(updateDeviceId(successData?.data?.deviceUuid))
					let practice_details = AidiPracticeDetails
					if (practice_details === undefined || practice_details === null) {
						getPracticeDetails(uuid)
					}
					if (uuid === '' || uuid === undefined) {
						history.push('/')
					} else if (!isPractice) {
						history.push('/establishmentselection')
					} else if (!isWelcome) {
						if (userRole === 'Owner') {
							history.push('/moduleselection')
						} else {
							secureLocalStorage.setItem('isWelcome', true)
							dispatch(updateIswelcome(true))
							roleNavigated()
						}
					} else {
						roleNavigated()
					}
				} else {
					// setGenerate(true)
					dispatch(hideLoader())
					secureLocalStorage.setItem('AidiDeviceId', successData?.data?.deviceUuid)
					dispatch(updateDeviceId(successData?.data?.deviceUuid))
					let practice_details = AidiPracticeDetails
					if (practice_details === undefined || practice_details === null) {
						getPracticeDetails(uuid)
					}
					if (uuid === '' || uuid === undefined) {
						history.push('/')
					} else if (groupId === 'null' || groupId === '' || groupId === null) {
						history.push('/groupselection')
					} else if (isEmail === 'false' || isEmail === false) {
						dispatch(setVerifyModal({ open: true, data: userEmail, mode: 'userVerification' }))
					} else if (!isPractice) {
						history.push('/establishmentselection')
					} else if (!isWelcome) {
						if (userRole === 'Owner') {
							history.push('/moduleselection')
						} else {
							secureLocalStorage.setItem('isWelcome', true)
							dispatch(updateIswelcome(true))
							roleNavigated()
						}
					} else {
						roleNavigated()
					}
				}
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		LoginApi.saveActiveDevices(userType, identityUuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const submitOtpConfirmation = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			otpNumber: otpNumber,
			sentEmail: false,
			userType: userType,
			uuid: identityUuid,
			isExpired: counter === 3 ? true : false,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				updateDeviceDetails(userType, uuid)
				secureLocalStorage.setItem('AidiBearer', successData?.data?.Bearer)
				secureLocalStorage.setItem('aidivaTenantUserEmail', successData.emailId)
				secureLocalStorage.setItem('isMobile', true)
				if (!isOtp) {
					secureLocalStorage.setItem('isEmail', false)
				}
				let dispData = !isOtp
					? {
							emailId: successData.emailId,
							bearer: successData?.data?.Bearer || null,
							isEmail: 'false',
					  }
					: {
							emailId: successData.emailId,
							bearer: successData?.data?.Bearer || null,
							isMobile: true,
					  }
				dispatch(Otp(dispData))
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.setOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const validate = () => {
		if (otpNumber === '') {
			setOtpError('Please enter OTP')
		} else if (otpNumber.length !== 4) {
			setOtpError('Enter a valid OTP')
		} else if (otpNumber.length === 4) {
			setOtpError('')
			submitOtpConfirmation()
		}
	}

	const getPracticeDetails = (uuid) => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				let data = !_.isEmpty(successData.data.data) ? successData.data.data[0] : []
				secureLocalStorage.setItem('mastuuid', data.master_tenant_uuid)
				secureLocalStorage.setItem('tentantuuid', data.mast_tent_type_uuid)
				if (data.length > 0) {
					dispatch(updatePracticeDetails(data))
				}
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.getActiveList(identityUuid).then(onSuccess, onFailure)
	}
	const [counter, setCounter] = useState(0)
	const [disable, setDisable] = useState(false)
	const [checkColor, setCheckColor] = useState('')
	const colorStyle = { color: themesConfig.greyDark.palette.primary.main }
	const disabled = { color: '#e0e0e0' }
	const resendOtp = () => {
		setCounter(counter + 1)
		secureLocalStorage.setItem('resendOtpBtn', counter)
		if (counter === 2) {
			setDisable(true)
			setCheckColor(!checkColor)
		} else {
			setDisable(false)
		}
		dispatch(showLoader('Loading please wait...'))
		const body = {
			countryCode: countryCode,
			mobileNo: mobileNo,
			userType: userType,
			uuid: identityUuid,
			email: userEmail,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			setOtpNumber('')
			dispatch(hideLoader())
			if (successData.status === 'success') {
				let successMessage = successData.message
				if (successMessage === 'suc_sendOtp') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson, 'OTP sent successully'),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
				}
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.resendOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const handleClick = () => {
		history.push('/')
	}
	const onSubmit = (e) => {
		e.preventDefault()
		validate()
	}

	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				{/* eslint-disable-next-line prettier/prettier */}
				<div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='flex w-full max-w-400 md:max-w-3xl rounded-20  overflow-hidden justify-center '
				>
					<Card className='Otp-leftSection flex flex-col w-full max-w-360 items-start justify-evenly shadow-0 p-16' square>
						<IconButton className='backButton' onClick={handleClick}>
							<ArrowBackIcon />
						</IconButton>
						<CardContent className='cardContent flex flex-col items-start justify-center w-full max-w-320'>
							<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-start mb-8'>
									<div>
										<Typography className='text-20 font-semibold logo-text' color='#6b7280'>
											OTP Verification
										</Typography>
									</div>
								</div>
							</div>
							<Typography className='numbercontent' align='center' justifyContent='center' variant='caption' color='#6b7280'>
								Enter OTP received on your registered mobile number{' '}
								<b>
									{countryCode}-{mobileNo}
								</b>
							</Typography>
							<form onSubmit={(e) => onSubmit(e)}>
								<div className='flex'>
									<OtpInput
										name='otp'
										type='password'
										value={otpNumber}
										onChange={handleChange}
										className='otp-input flex justify-center'
										numInputs={4}
										isInputNum={true}
										separator={<span>&nbsp;&nbsp;&nbsp;</span>}
										shouldAutoFocus={true}
										focusStyle={{
											border: '3px solid #000',
											outline: 'none',
										}}
									/>
								</div>
								{otpError ? (
									<Typography align='center' color='error'>
										{otpError}
									</Typography>
								) : null}
								<Typography variant='body1' align='left' className='mt-8 flex items-center whitespace-nowrap'>
									Didn't receive OTP?
									<Button className='resendButton' style={checkColor ? disabled : colorStyle} onClick={resendOtp} disabled={disable ? true : false}>
										Resend OTP
									</Button>
								</Typography>
								<ButtonComp
									type='submit'
									variant='contained'
									customStyle='btn w-full mx-auto mt-16'
									aria-label='Otp'
									value='submit'
									onClick={onSubmit}
								>
									Verify & proceed
								</ButtonComp>
							</form>
						</CardContent>
					</Card>

					<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
						<div className='max-w-320'>
							<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
								<img src={OTPImg} alt='logo' />
							</div>
						</div>
					</div>
				</div>
			</Root>
			<EmailVerificationModal history={history} />
		</>
	)
}

export default OtpModule
