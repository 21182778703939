import axios from 'axios'
import { API_ENDPOINTS } from '../../../../constants/index'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFileDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.FILE_GET, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid } },
		})
	},

	deleteAllfiles: (data) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_ALL_FILES}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			data: { ...data },
		})
	},
	postFileDetail: (data, appointmentUuid) => {
		return axios.post(`${API_ENDPOINTS.FILE_GET}`, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf(), path: { appointmentUuid } },
		})
	},
}
