import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	tentUuid: secureLocalStorage.getItem('tentUuid'),
	roleUuid: [],
	doctorUuid: '',
	doctorDetails: '',
	doctorRoleUuid: secureLocalStorage.getItem('doctorRoleUuid'),
	isEditRecord: false,
	isChartingDataPresent: false,
	isCurrentAppointmentUuid: false,
	catergoryUuid: '',
	cust_Uuid: secureLocalStorage.getItem('custUuid'),
	billing_order_uuid: secureLocalStorage.getItem('billingOrderUuid') || '',
	profile_view_data: secureLocalStorage.getItem('profileViewData'),
	currentPatient: null,
	pendingPaymentModal: false,
	refundPaymentModal: false,
	billingTentUserUuid: '',
	billingInvoiceDetails: [],
	billingPaymentDetails: [],
	billingRefundDetails: [],
	billingAppointmentDetails: [],
	chartingrecords: '',
	showPendingModalData: '',
	showRefundModalData: '',
	todaysAppointment: [],
	todayMetAppointmentDataList: [],
	groupDataSettings: '',
	billingHistoryApiTrigger: '',
	customerListApiTrigger: '',
	workoutPlanTrigger: '',
	invoiceDetails: {},
	invoiceDetailsRefund: {},
	getCustomerMaster: {},
	currentSelectedClient: null,
}

const RoleIdReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.TENT_ROLE_UUID: {
			return {
				...state,
				tentUuid: action.payload,
			}
		}
		case ACTION_TYPES.ROLE_UUID: {
			return {
				...state,
				roleUuid: action.payload,
			}
		}
		case ACTION_TYPES.DOCTOR_UUID: {
			return {
				...state,
				doctorUuid: action.payload,
			}
		}
		case ACTION_TYPES.DOCTOR_DETAILS: {
			return {
				...state,
				doctorDetails: action.payload,
			}
		}
		case ACTION_TYPES.DOCTOR_ROLE_UUID: {
			return {
				...state,
				doctorRoleUuid: action.payload,
			}
		}
		case ACTION_TYPES.ISEDITPATIENT: {
			return {
				...state,
				isEditPatient: action.payload,
			}
		}
		case ACTION_TYPES.ISEDITRECORD: {
			return {
				...state,
				isEditRecord: action.payload,
			}
		}
		case ACTION_TYPES.ISCHARTINGDATAPRESENT: {
			return {
				...state,
				isChartingDataPresent: action.payload,
			}
		}
		case ACTION_TYPES.CHARTINGTENTID: {
			return {
				...state,
				chartingTentId: action.payload,
			}
		}
		case ACTION_TYPES.CATEGORY_UUID: {
			return {
				...state,
				catergoryUuid: action.payload,
			}
		}
		case ACTION_TYPES.ISCURRENTAPPUUID: {
			return {
				...state,
				isCurrentAppointmentUuid: action.payload,
			}
		}
		case ACTION_TYPES.CUSTOMER_UUID: {
			return {
				...state,
				cust_Uuid: action.payload,
			}
		}
		case ACTION_TYPES.PROFILE_VIEW_DATA: {
			return {
				...state,
				profile_view_data: action.payload,
			}
		}
		case ACTION_TYPES.BILLING_ORDER_UUID: {
			return {
				...state,
				billing_order_uuid: action.payload,
			}
		}
		case ACTION_TYPES.PENDING_PAYMENT_MODAL: {
			return {
				...state,
				pendingPaymentModal: action.payload,
			}
		}
		case ACTION_TYPES.REFUND_PAYMENT_MODAL: {
			return {
				...state,
				refundPaymentModal: action.payload,
			}
		}
		case ACTION_TYPES.BILLING_TENT_USER_UUID: {
			return {
				...state,
				billingTentUserUuid: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_BILLING_INVOICEDETAILS: {
			return {
				...state,
				billingInvoiceDetails: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_BILLING_PAYMENT_DETAILS: {
			return {
				...state,
				billingPaymentDetails: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_BILLING_REFUND_DETAILS: {
			return {
				...state,
				billingRefundDetails: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_BILLING_APPOINTMENT_DETAILS: {
			return {
				...state,
				billingAppointmentDetails: action.payload,
			}
		}
		case ACTION_TYPES.CHARTING_RECORDS: {
			return {
				...state,
				chartingrecords: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_PENDING_DETAILS: {
			return {
				...state,
				showPendingModalData: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_REFUND_DETAILS: {
			return {
				...state,
				showRefundModalData: action.payload,
			}
		}
		case ACTION_TYPES.TODAYS_APPOINTMENTS: {
			return {
				...state,
				todaysAppointment: action.payload,
			}
		}
		case ACTION_TYPES.TODAYS_MET_APPOINTMENTS: {
			return {
				...state,
				todayMetAppointmentDataList: action.payload,
			}
		}
		case ACTION_TYPES.GROUP_DATA: {
			return {
				...state,
				groupDataSettings: action.payload,
			}
		}
		case ACTION_TYPES.BILLING_HISTORY_API_TRIGGER: {
			return {
				...state,
				billingHistoryApiTrigger: action.payload,
			}
		}
		case ACTION_TYPES.CUSTOMER_LIST_API_TRIGGER: {
			return {
				...state,
				customerListApiTrigger: action.payload,
			}
		}
		case ACTION_TYPES.WORKOUT_PLAN_TRIGGER: {
			return {
				...state,
				workoutPlanTrigger: action.payload,
			}
		}
		case ACTION_TYPES.INVOICE: {
			return {
				...state,
				invoiceDetails: action.payload,
			}
		}

		case ACTION_TYPES.INVOICE_REFUND: {
			return {
				...state,
				invoiceDetailsRefund: action.payload,
			}
		}
		case ACTION_TYPES.SET_CURRENT_CLIENT: {
			return {
				...state,
				currentSelectedClient: action.payload,
			}
		}

		default:
			return state
	}
}

export default RoleIdReducer
