import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Rating,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
import _ from 'lodash'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerName: {
		'& .MuiTypography-root': {
			fontSize: 13,
			fontWeight: 600,
		},
	},
	headerClear: {
		cursor: 'pointer',
		'& .MuiTypography-root': {
			fontSize: 13,
			fontWeight: 600,
			textDecorationLine: 'underline',
			color: themesConfig.greyDark.palette.primary.main,
		},
	},
	timeLine: {
		borderRadius: 2,
		'& .MuiTimelineDot-root': {
			borderColor: '#8CC63F',
			backgroundColor: '#fff',
			margin: '0px',
		},
		'& 	.MuiTimelineConnector-root': {
			background: '#8CC63F',
		},
		'& .MuiTimelineContent-root': {
			padding: '0px 6px',
			cursor: 'pointer',
		},
		'& .MuiTimelineItem-root': {
			minHeight: 54,
			padding: '0px 0px',
		},
		'& .MuiTimeline-root': {
			padding: '0px 0px',
		},
		'& :hover': {
			background: '#e6e6e610',
		},
		'& .MuiAccordionSummary-root': {
			padding: 0,
		},
		'& .Mui-expanded:last-of-type': {
			'&:hover': {
				background: '#fff',
			},
		},
		'& .MuiCollapse-root': {
			'&:hover': {
				background: '#fff',
			},
		},
	},
	expandedContent: {
		'& .MuiAccordionSummary-content': {
			'& .MuiTypography-body1': {
				color: '#0062dd',
			},
			'& .MuiSvgIcon-root': {
				color: '#0062dd',
			},
		},
		'& .MuiAccordionSummary-expandIconWrapper': {
			color: '#0062dd',
			'& .MuiSvgIcon-root': {
				color: '#0062dd',
			},
		},
	},
	accordion: {
		boxShadow: 'none',
		'& .MuiAccordion-root': {
			boxShadow: 'none',
		},
		'& .MuiAccordionSummary-content': {
			'& .MuiTypography-body1': {
				fontWeight: 500,
				fontSize: 14,
			},
		},
	},
	createdBySec: {
		overflowY: 'auto',
		padding: 0,
		'&:hover': {
			background: '#fff',
		},
	},
	insideHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: '12px',
		'& .MuiRating-root': {
			color: '#faaf00',
		},
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
}))
const Filter = ({ ratingData, recommentData, handleChange, toggleSessionTypeClick, clearClose }) => {
	const classes = useStyles()
	return (
		<>
			<div className={classes.root}>
				<div className={classes.headerName}>
					<Typography variant='body1'>Filters</Typography>
				</div>
				<div
					onClick={() => {
						clearClose()
					}}
					className={classes.headerClear}
				>
					<Typography variant='body1'>Clear all</Typography>
				</div>
			</div>
			<div className={classes.timeLine}>
				<Accordion disableGutters={true} className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
						classes={{ expanded: classes.expandedContent }}
					>
						<Typography>Ratings</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.createdBySec}>
						{_.map(ratingData || [], (item) => {
							return (
								<div className={classes.insideHeader}>
									<FormControlLabel
										label={item?.value || ''}
										control={
											<Checkbox
												checked={item?.isCheck}
												onChange={() => {
													toggleSessionTypeClick(item)
												}}
											/>
										}
									/>
									<Rating name='read-only' value={item?.value} readOnly max={item?.value} />
								</div>
							)
						})}
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={classes.timeLine}>
				<Accordion disableGutters={true} className={classes.accordion}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1a-content'
						id='panel1a-header'
						classes={{ expanded: classes.expandedContent }}
					>
						<Typography>Recommended</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.createdBySec}>
						<div className={classes.insideHeader}>
							<FormControl>
								<RadioGroup
									aria-labelledby='demo-controlled-radio-buttons-group'
									name='controlled-radio-buttons-group'
									value={recommentData}
									onChange={handleChange}
								>
									<FormControlLabel value='yes' control={<Radio />} label='Yes' />
									<FormControlLabel value='no' control={<Radio />} label='No' />
								</RadioGroup>
							</FormControl>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
		</>
	)
}

export default Filter
