import useStyles from './style'
import { React, useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Avatar, Icon, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import GetEstablishment from '../../../../../services/ProfileSetup/EstablishmentDetails/api'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import { getImgUrl } from 'utils'

function EstablishmentView({ moveBackTab, moveNextTab, match, history }) {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [showEstablishment, setShowEstablishment] = useState()
	const uuid = _.get(match, 'params.uuid', '')
	const mastuuid = _.get(match, 'params.mastuuid')
	const token = useSelector((state) => state?.Authentication?.BearerToken || '')
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || '')
	const getEstablishmentDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data.status === 'success') {
				setShowEstablishment(res?.data.data)
			} else {
				setShowEstablishment([])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		GetEstablishment.getEstablishmentDetails(mastuuid).then(onSuccess, onFailure)
	}, [dispatch, mastuuid])
	useEffect(() => {
		getEstablishmentDetails()
	}, [getEstablishmentDetails])

	const onEditClick = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastuuid}?tabId=establishment`)
	}
	const onAddClick = () => {
		history.push(`/profilesetup/?tabId=establishment`)
	}

	return (
		<Grid container className={classes.establishmentviewMain} justifyContent={'space-between'}>
			<Grid item lg={6} sm={12}>
				<Grid container className={classes.datapart} spacing={2}>
					<Grid item lg={3}>
						<Avatar className={classes.avatar} src={getImgUrl(showEstablishment?.tentLogo || '', token)} variant='rounded'></Avatar>
					</Grid>
					<Grid item lg={9} className={classes.establishmentDetails}>
						<Typography className={classes.establishmentName} variant='h6'>
							{showEstablishment?.tentName}
						</Typography>
						<div className={classes.addressDetails}>
							{showEstablishment?.locality && <Typography>{`${showEstablishment?.locality}`}</Typography>}
							{showEstablishment?.address && <Typography>{`, ${showEstablishment?.address}`}</Typography>}
							{showEstablishment?.pincode && <Typography>{`, ${showEstablishment?.pincode}`}</Typography>}
						</div>
						<div className={classes.contactDetails}>
							<Typography>{showEstablishment?.tentContactNo}</Typography>
							<Typography>{showEstablishment?.tentEmail}</Typography>
						</div>
						{progressStatus === 'SUB' ? null : (
							<div className={classes.edit}>
								<Button className={classes.editBtn} onClick={onEditClick}>
									<Icon color='error' className={classes.icon}>
										edit
									</Icon>
									<Typography>&nbsp; Edit</Typography>
								</Button>
								{/* <IconButton size='small' className={classes.editIcon}></IconButton> */}
							</div>
						)}
					</Grid>
				</Grid>
				<div className={classes.addEstablishment}>
					<Button className={classes.editBtn} onClick={onAddClick}>
						<Icon color='error' className={classes.icon}>
							add_circle_outline
						</Icon>
						<Typography className={classes.contactDetails}>&nbsp; Add Establishment</Typography>
					</Button>
					{/* <IconButton>
						<AddCircleOutlineIcon />
					</IconButton>
					<Typography className={classes.contactDetails}></Typography> */}
				</div>
			</Grid>
			<Grid item lg={2.3} sm={12} className={classes.userTextMessage}>
				<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/erroricon.svg`} alt='' width={20} height={20} />
				<Typography>This mobile number and email will be visible to the patients</Typography>
			</Grid>
		</Grid>
	)
}
export default EstablishmentView
