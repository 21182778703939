import React, { useState, useEffect, useCallback, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Button, IconButton, FormHelperText, Autocomplete } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ButtonComp from '../../../sharedComponents/Button'
import LookupApi from '../../../../services/lookup/api'
import serviceLookupApi from '../../../../services/welcomeCare/api'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { showLoader, hideLoader } from 'services/loader/action'
import { getResponseMessage } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import ServiceDetailsApi from '../../../../services/ProfileSetup/serviceAndExperience/api'
import monthApi from '../../../../services/register/api'
import useStyles from './style'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import AdvancedAutocomplete from 'app/sharedComponents/AdvancedAutocomplete'
import countryLookup from 'services/register/api'
import { setServiceListData } from 'services/ProfileSetup/list/actions'
import { FieldArray, ErrorMessage, FormikProvider } from 'formik'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'

function ServiceDetails({ moveNextTab, moveBackTab, match }) {
	const action = _.get(match, 'params.action', '')
	const isEditMode = _.isEqual(action, 'edit')
	const classes = useStyles()
	const cancelExecutor = useRef()
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
	const clickResponseType = useRef('formSubmission')
	const dispatch = useDispatch()
	const state = useSelector((state) => state.GroupUser)
	const userType = useSelector((state) => state?.Authentication?.userType)
	const { groupUserName, tentUserId } = state
	// const serviceListData = useSelector((state) => state?.profileSetup?.serviceExperience?.serviceExpList || [])
	const [serviceDetails, setServiceDetails] = useState([])
	const [emptyCheck, setEmptyCheck] = useState()
	const [countryCodeList, setCountryCodeList] = useState([])
	const [statesOption, setStatesOption] = useState([])
	const [fromYear, setFromYear] = useState([])
	const [selectToYear, setSelectToYear] = useState([])
	const [fromMonth, setFromMonth] = useState([])
	const [monthTo, setToMonth] = useState([])
	const [roleOptions, setroleNameOptions] = useState([])
	const [services, setServices] = useState([])
	const [optionsLoading, setOptionsLoading] = useState(false)
	const errorMessages = useSelector((state) => state?.errorMessages)
	// const state = useSelector((state) => state.Authentication)
	// const { tentUserId } = state

	const [yearFromParams, setYearFromParams] = useState({
		limit: 10,
		offset: 1,
		search: '',
	})
	const [yearToParams, setYearToParams] = useState({
		limit: 10,
		offset: 1,
		search: '',
	})
	const [countryParams, setCountryParams] = useState({
		limit: 50,
		offset: 1,
		mastLookupType: 'CNT',
		search: '',
	})
	const onCountrySearch = _.debounce(
		(e, value) => {
			setCountryParams({
				...countryParams,
				search: value,
			})
		},
		[300]
	)
	const [stateParams, setStateParams] = useState({
		limit: 50,
		offset: 1,
		mastCountryCode: '',
		search: '',
	})
	const onFromYearSearch = _.debounce(
		(e, value) => {
			setYearFromParams({
				...yearFromParams,
				search: value,
			})
		},
		[300]
	)

	const onToYearSearch = _.debounce(
		(e, value) => {
			setYearToParams({
				...yearToParams,
				search: value,
			})
		},
		[300]
	)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const [specialityParams, setSpecialityParams] = useState({
		tentTypeId: secureLocalStorage.getItem('tentTypeId'),
	})
	const getServices = useCallback(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOptionsLoading(false)
				setServices(res?.data?.data)
			}
		}
		const onFailure = (err) => {}
		serviceLookupApi.getPracticeSpeciality('SpecialityType', { ...specialityParams }).then(onSuccess, onFailure)
	}, [specialityParams])

	const RegFromYear = useCallback(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOptionsLoading(false)
				setFromYear(res.data.data)
			}
		}
		const onFailure = (err) => {
			setFromYear([])
		}
		LookupApi.getLookup('getLookup', { ...yearFromParams, mastLookupType: 'YER' }).then(onSuccess, onFailure)
	}, [yearFromParams])
	const RegToYear = useCallback(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOptionsLoading(false)
				setSelectToYear(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			setSelectToYear('')
		}
		LookupApi.getLookup('getLookup', { ...yearToParams, mastLookupType: 'YER' }).then(onSuccess, onFailure)
	}, [yearToParams])
	const RegmonthName = useCallback(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOptionsLoading(false)
				setFromMonth(res.data.data)
				setToMonth(res.data.data)
			} else {
				setFromMonth('')
			}
		}
		const onFailure = (err) => {
			setFromMonth('')
		}
		monthApi.getLookup('MNT').then(onSuccess, onFailure)
	}, [])
	const RegroleName = useCallback(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setOptionsLoading(false)
				setroleNameOptions(res.data.data)
			} else {
				setroleNameOptions([])
			}
		}
		const onFailure = (err) => {
			setroleNameOptions([])
		}
		LookupApi.getLookup('getLookup', { mastLookupType: 'ROL' }).then(onSuccess, onFailure)
	}, [])
	// const RegcityName = useCallback(() => {
	// 	setOptionsLoading(true)
	// 	const onSuccess = (res) => {
	// 		if (res?.data?.status === 'success') {
	// 			setOptionsLoading(false)
	// 			setrolecityOptions(res.data.data)
	// 		} else {
	// 			setrolecityOptions([])
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		setrolecityOptions([])
	// 	}
	// 	LookupApi.getLookup('getLookup', { ...cityParams, mastLookupType: 'CTY' }).then(onSuccess, onFailure)
	// }, [cityParams])

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				setCountryCodeList(code)
			}
		}
		const onFailure = (err) => {
			setCountryCodeList([])
		}
		countryLookup.getCountryCode().then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		const onSuccess = (res) => {
			setStatesOption(res.data.data)
		}
		const onFailure = () => {
			setStatesOption([])
		}
		stateParams?.mastCountryCode && LookupApi.getLookup('citiesStates', { ...stateParams }, cancelExecutor).then(onSuccess, onFailure)
	}, [stateParams])

	useEffect(() => {
		getServices()
		RegFromYear()
		RegToYear()
		RegmonthName()
		// RegcityName()
		RegroleName()
	}, [getServices, RegFromYear, RegToYear, RegmonthName, RegroleName])

	const onSubmit = (e) => {
		saveServiceDetails(e)
	}
	const formik = useFormik({
		initialValues: {
			serviceDetails: !_.isEmpty(serviceDetails)
				? serviceDetails
				: [
						{
							serviceList: [],
							monthFrom: null,
							yearFrom: null,
							monthTo: null,
							yearTo: null,
							role: null,
							country: null,
							state: null,
							establishmentName: '',
						},
				  ],
		},
		validationSchema: yup.object().shape({
			serviceDetails: yup
				.array()
				.min(1)
				.of(
					yup.object().shape({
						serviceList:
							yup.object().nullable().required('Please select one Service') || yup.string().nullable().required('Please select one Service'),
						monthFrom: yup.string().nullable().required('Select One'),
						yearFrom: yup.string().nullable().required('Select One'),

						monthTo: yup.string().nullable().required('Select One'),
						yearTo: yup.string().nullable().required('Select One'),

						role: yup.string().nullable().required('Select role'),
						country: yup.object().nullable().required('Please select country'),
						state: yup.object().nullable().required('Please select state'),
						establishmentName: yup.string().required('Please enter establishment name'),
					})
				)
				.required('Required'),
		}),
		onSubmit: onSubmit,
	})
	const saveServiceDetails = (e) => {
		dispatch(showLoader('Loading please wait...'))
		let body = []
		_.map(formik?.values?.serviceDetails, (serviceDetail) => {
			body.push({
				serviceList: [
					{
						serviceName: serviceDetail?.serviceList?.specialityName,
						progressStatus: 'IPG',
						specialityUuid: serviceDetail?.serviceList?.specialityUuid,
					},
				],
				yearFrom: parseInt(serviceDetail?.yearFrom),
				monthFrom: parseInt(moment().month(serviceDetail?.monthFrom).format('M')),
				yearTo: parseInt(serviceDetail?.yearTo),
				monthTo: parseInt(moment().month(serviceDetail?.monthTo).format('M')),
				role: serviceDetail?.role,
				establishmentName: serviceDetail?.establishmentName,
				country: serviceDetail?.country,
				state: serviceDetail?.state,
				progressStatus: 'IPG',
				isAmend: false,
				profileExpUuid: serviceDetail?.profileExpUuid || null,
			})
		})
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		!_.isEmpty(emptyCheck)
			? ServiceDetailsApi.updateServiceList(userType, tentUserId, body).then(onSuccess, onFailure)
			: ServiceDetailsApi.saveServiceList(userType, tentUserId, body).then(onSuccess, onFailure)
	}

	const deleteServiceDetails = useCallback(
		(tentUserId, arrayHelpers, index) => {
			dispatch(showLoader('Deleting please wait'))
			const onSuccess = () => {
				dispatch(hideLoader())
				arrayHelpers.remove(index)
			}
			const onFailure = () => {
				dispatch(hideLoader())
			}
			ServiceDetailsApi.deleteService(tentUserId).then(onSuccess, onFailure)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setEmptyCheck(res?.data?.data)
			let tempArr = []
			if (!_.isEmpty(res?.data?.data || [])) {
				_.map(res?.data?.data || [], (item) => {
					let fetchCountry = _.find(countryCodeList, { country: item?.country?.country })
					setStateParams((prevState) => ({ ...prevState, mastCountryCode: fetchCountry?.countryCode }))
					tempArr.push({
						country: fetchCountry,
						state: item?.state,
						establishmentName: item?.establishmentName,
						monthFrom: moment(item?.monthFrom, 'M').format('MMMM'),
						monthTo: moment(item?.monthTo, 'M').format('MMMM'),
						profileExpUuid: item?.profileExpUuid,
						progressStatus: item?.progressStatus,
						role: item?.role,
						serviceList: item?.serviceList[0],
						yearFrom: item?.yearFrom,
						yearTo: item?.yearTo,
					})
				})
			}
			setServiceDetails(tempArr)
		}
		const onFailure = (res) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		!_.isEmpty(countryCodeList) && ServiceDetailsApi.getServiceList(userType, tentUserId).then(onSuccess, onFailure)
	}, [countryCodeList, dispatch, errorMessages, tentUserId])

	useEffect(() => {
		if (!_.isEmpty(emptyCheck)) {
			formik?.setFieldValue('serviceDetails', serviceDetails)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceDetails, emptyCheck])

	useEffect(() => {
		dispatch(setServiceListData(formik?.values?.serviceDetails || []))
	}, [formik?.values?.serviceDetails, dispatch])
	return (
		<>
			<Grid container>
				<FormikProvider value={formik}>
					<FieldArray
						name='serviceDetails'
						render={(arrayHelpers) => (
							<>
								{_.map(formik?.values?.serviceDetails, (data, index) => {
									return (
										<>
											<Grid container className={classes.container} lg={12} justifyContent='space-between' alignItems='end'>
												<Grid item lg={6} sm={12}>
													<Typography pt={1} pb={1}>
														Add Service
													</Typography>
													<AdvancedAutocomplete
														loading={optionsLoading}
														id={`serviceDetails.[${index}].serviceList`}
														name={`serviceDetails.[${index}].serviceList`}
														value={
															formik?.values?.serviceDetails[index].serviceList?.specialityName ||
															formik?.values?.serviceDetails[index].serviceList?.serviceName
														}
														options={services}
														getOptionLabel={(option) => option?.specialityName || ''}
														onChange={(e, value) => {
															formik?.setFieldValue(`serviceDetails.[${index}].serviceList`, value || '')
														}}
														getOptionSelected={(option, value) => option?.specialityName === value?.specialityName || ''}
														placeholder='Search and add one service'
													/>
													<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].serviceList`} />
												</Grid>
												<Grid item lg={6} pt={2} alignItems='end'>
													{!isSmall ? (
														formik?.values?.serviceDetails?.length > 1 ? (
															<IconButton
																className={classes.cancelExperience}
																onClick={() => {
																	!_.isEmpty(emptyCheck) && formik?.values?.serviceDetails[index]?.profileExpUuid
																		? deleteServiceDetails(formik?.values?.serviceDetails[index]?.profileExpUuid, arrayHelpers, index)
																		: arrayHelpers.remove(index)
																}}
															>
																<HighlightOffIcon />
															</IconButton>
														) : (
															''
														)
													) : (
														<Button
															className={classes.cancelBtn}
															onClick={() => {
																!_.isEmpty(emptyCheck) && formik?.values?.serviceDetails[index]?.profileExpUuid
																	? deleteServiceDetails(formik?.values?.serviceDetails[index]?.profileExpUuid, arrayHelpers, index)
																	: arrayHelpers.remove(index)
															}}
														>
															Remove
														</Button>
													)}
												</Grid>
											</Grid>
											<Grid container>
												<Grid item pt={2}>
													<Typography fontWeight='bold'>Experience</Typography>
												</Grid>
											</Grid>
											<Grid container spacing={1} className={clsx(classes.container, classes.tablet)}>
												<Grid item lg={6} md={6}>
													<Typography pt={1} pb={1}>
														Duration(Select year & month)*
													</Typography>
													<Grid container spacing={2} className={classes.container}>
														<Grid item xs>
															<AdvancedAutocomplete
																loading={optionsLoading}
																id={`serviceDetails.[${index}].monthFrom`}
																name={`serviceDetails.[${index}].monthFrom`}
																options={fromMonth}
																value={formik?.values?.serviceDetails[index].monthFrom}
																getOptionLabel={(option) => option?.mastLookupValue || ''}
																getOptionSelected={(option, value) => option?.mastLookupValue === value?.mastLookupValue || ''}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].monthFrom`, value?.mastLookupValue || '')
																}}
																placeholder='From month'
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].monthFrom`} />
														</Grid>
														<Grid item xs>
															<AdvancedAutocomplete
																loading={optionsLoading}
																id={`serviceDetails.[${index}].yearFrom`}
																name={`serviceDetails.[${index}].yearFrom`}
																value={formik?.values?.serviceDetails[index].yearFrom}
																options={fromYear}
																disableClearable
																className={classes.years}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].yearFrom`, value?.mastLookupValue || '')
																	setYearFromParams((prevState) => ({ ...prevState, search: value }))
																}}
																getOptionLabel={(option) => option?.mastLookupValue || ''}
																onInputChange={onFromYearSearch}
																getOptionSelected={(option, value) => {
																	return option?.mastLookupValue === value?.mastLookupValue || ''
																}}
																placeholder='From year'
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].yearFrom`} />
														</Grid>
														{isSmall ? null : (
															<Grid item>
																<Typography pt={1} align='center'>
																	to
																</Typography>
															</Grid>
														)}
														<Grid item xs>
															<AdvancedAutocomplete
																loading={optionsLoading}
																id={`serviceDetails.[${index}].monthTo`}
																name={`serviceDetails.[${index}].monthTo`}
																value={formik?.values?.serviceDetails[index].monthTo}
																options={monthTo}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].monthTo`, value?.mastLookupValue || '')
																}}
																getOptionSelected={(option, value) => {
																	return option?.mastLookupValue === value?.mastLookupValue || ''
																}}
																getOptionLabel={(option) => option?.mastLookupValue || ''}
																placeholder='To month'
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].monthTo`} />
														</Grid>
														<Grid item xs>
															<AdvancedAutocomplete
																loading={optionsLoading}
																id={`serviceDetails.[${index}].yearTo`}
																name={`serviceDetails.[${index}].yearTo`}
																value={formik?.values?.serviceDetails[index].yearTo}
																options={selectToYear}
																className={classes.years}
																disableClearable
																size='small'
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].yearTo`, value?.mastLookupValue || '')
																	setYearToParams((prevState) => ({ ...prevState, search: value?.mastLookupValue || '' }))
																}}
																onInputChange={onToYearSearch}
																getOptionSelected={(option, value) => {
																	return option?.mastLookupValue === value?.mastLookupValue || ''
																}}
																getOptionLabel={(option) => option?.mastLookupValue || ''}
																placeholder='To year'
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].yearTo`} />
														</Grid>
													</Grid>
												</Grid>
												<Grid item lg={3} md={3}>
													<Grid container className={clsx(classes.container, classes.tablet)}>
														<Grid item lg={12} md={12}>
															<Typography pt={1} pb={1}>
																Role*
															</Typography>
															<AdvancedAutocomplete
																loading={optionsLoading}
																id={`serviceDetails.[${index}].role`}
																name={`serviceDetails.[${index}].role`}
																value={formik?.values?.serviceDetails[index].role}
																options={roleOptions}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].role`, value?.mastLookupValue || '')
																}}
																getOptionSelected={(option, value) => {
																	return option?.mastLookupValue === value?.mastLookupValue || ''
																}}
																getOptionLabel={(option) => option?.mastLookupValue || ''}
																placeholder='Select your role'
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].role`} />
														</Grid>
													</Grid>
												</Grid>
												<Grid item lg={3} md={3}>
													<Grid container className={clsx(classes.container, classes.tablet)}>
														<Grid item lg={12} md={12}>
															<Typography pt={1} pb={1}>
																Country*
															</Typography>
															<Autocomplete
																id={`serviceDetails.[${index}].country`}
																name={`serviceDetails.[${index}].country`}
																options={countryCodeList}
																disableClearable
																autoHighlight
																openOnFocus
																getOptionLabel={(option) => option?.country || ''}
																value={formik?.values?.serviceDetails[index]?.country}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].country`, value)
																	setCountryParams((prevState) => ({ ...prevState, search: value?.country }))
																	setStateParams((prevState) => ({ ...prevState, mastCountryCode: value?.countryCode }))
																	formik.setFieldValue(`serviceDetails.[${index}].state`, {})
																}}
																onInputChange={onCountrySearch}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		color='secondary'
																		size='small'
																		className={classes.textField}
																		inputProps={{
																			...params.inputProps,
																		}}
																	/>
																)}
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].country`} />
														</Grid>
													</Grid>
												</Grid>
												<Grid item lg={3} md={3}>
													<Grid container className={clsx(classes.container, classes.tablet)}>
														<Grid item lg={12} md={12}>
															<Typography pt={1} pb={1}>
																State*
															</Typography>
															<Autocomplete
																name={`serviceDetails.[${index}].state`}
																id={`serviceDetails.[${index}].state`}
																options={statesOption}
																disableClearable='true'
																openOnFocus
																noOptionsText={_.isEmpty(formik?.values?.serviceDetails[index].country) ? 'Please select country' : 'No options'}
																getOptionLabel={(option) => option?.mastState || ''}
																value={formik?.values?.serviceDetails[index].state}
																onChange={(e, value) => {
																	formik?.setFieldValue(`serviceDetails.[${index}].state`, value || '')
																	// formik?.setFieldValue('pincode', '')
																}}
																getOptionSelected={(option, value) => {
																	return option?.mastState === value?.mastState
																}}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		color='secondary'
																		size='small'
																		className={classes.textField}
																		inputProps={{
																			...params.inputProps,
																		}}
																	/>
																)}
															/>
															<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].state`} />
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid container className={classes.container}>
												<Grid item lg={6} sm={12} md={12}>
													<Typography pt={1} pb={1}>
														Establishment Name
													</Typography>
													<TextField
														variant='outlined'
														id={`serviceDetails.[${index}].establishmentName`}
														name={`serviceDetails.[${index}].establishmentName`}
														color='secondary'
														size='small'
														fullWidth
														value={formik?.values?.serviceDetails[index]?.establishmentName}
														onChange={(e) => formik?.handleChange(e)}
														placeholder='Enter Establishment Name'
														InputProps={{ className: classes.input }}
													/>
													<ErrorMessage component={FormHelperText} error name={`serviceDetails.[${index}].establishmentName`} />
												</Grid>
											</Grid>
										</>
									)
								})}
								<Grid container>
									<Button
										className={classes.addExperiencebtn}
										onClick={() => {
											arrayHelpers.push({
												serviceList: [''],
												monthFrom: null,
												yearFrom: null,
												monthTo: null,
												yearTo: null,
												role: null,
												city: null,
												establishmentName: '',
											})
											setYearFromParams({
												limit: 10,
												offset: 1,
												search: '',
											})
											setYearToParams({
												limit: 10,
												offset: 1,
												search: '',
											})
											// setCityParams({
											// 	limit: 10,
											// 	offset: 1,
											// 	search: '',
											// })
										}}
									>
										+ Add Experience
									</Button>
								</Grid>
							</>
						)}
					/>
				</FormikProvider>
			</Grid>
			<Grid container className={classes.navigationButtons}>
				<ButtonComp onClick={() => moveBackTab()}>Back</ButtonComp>
				<ButtonComp
					onClick={(e) => {
						clickResponseType.current = 'formSubmission'
						formik.handleSubmit(e)
					}}
				>
					Next
				</ButtonComp>
			</Grid>
		</>
	)
}
export default ServiceDetails
