import { Typography } from '@mui/material'

function DrivePage() {
	return (
		<div>
			<Typography variant='h5'>Drive</Typography>
		</div>
	)
}

export default DrivePage
