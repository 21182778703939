import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	treatmentHome: {},
	aboutCommunication: {
		fontSize: 20,
		color: '#33333F',
		paddingBlock: 8,
		fontFamily: 'poppins',
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 16,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
		},
	},
	whatCommunication: {
		fontSize: 20,
		color: '#33333F',
		paddingBlock: 16,
		fontFamily: 'poppins',
		textAlign: 'center',
		[theme.breakpoints.up('xs')]: {
			fontSize: 14,
			paddingBlock: 0,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 14,
			paddingBlock: 18,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 20,
			paddingBlock: 24,
		},
	},
	aboutCommunicationDetails: {
		fontSize: 12,
		color: '#33333F',
		textAlign: 'center',
		fontFamily: 'poppins',
	},
	aboutCommunicationTwo: {},
	stepperOne: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	divider: {
		border: ' 1px solid #999',
		minWidth: 136,
	},
	tradeMade: {},
	cardOne: {
		display: 'flex',
		marginBlock: 24,
	},
	cardPart: {
		background: '#fff',
		padding: 28,
		textAlign: 'center',
		borderRadius: 12,
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		boxShadow: '0px 0px 20px #0000001A',
	},
	tradeTitle: {
		fontSize: 14,
		color: '#2D2D2D',
		fontFamily: 'poppins',
	},
	tradeDetails: {
		fontSize: 11,
		color: '#2D2D2D',
		fontFamily: 'poppins',
	},
	cardPartTwo: {
		textAlign: 'center',
	},
	cardPartMain: {
		[theme.breakpoints.up('xs')]: {
			gap: 20,
			display: 'flex',
			padding: 12,
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			padding: 12,
			gap: 16,
		},
	},
	mainCardTwo: {
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	gotoStart: {
		position: 'sticky',
		bottom: 0,
		paddingBlock: 12,
		backdropFilter: 'saturate(180%) blur(5px)',
		boxShadow: 'inset 0 0px 0 0 rgba(0,0,0,0.1)',
		backgroundColor: 'hsla(0,0%,100%,0.8)',
	},
}))
export default useStyles
