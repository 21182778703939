import { ACTION_TYPES } from '../../../constants'

const initialState = {
	advanceSearchOptions: [],
	advancedSearchValue: null,
	searchOptions: [],
	searchInput: '',
	searchValue: null,
}

const GlobalSearchReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.ADVANCED_SEARCH_OPTIONS: {
			return {
				...state,
				advanceSearchOptions: action.payload,
			}
		}
		case ACTION_TYPES.ADVANCED_SEARCH_VALUE: {
			return {
				...state,
				advancedSearchValue: action.payload,
			}
		}
		case ACTION_TYPES.SEARCH_OPTIONS: {
			return {
				...state,
				searchOptions: action.payload,
			}
		}
		case ACTION_TYPES.SEARCH_INPUT: {
			return {
				...state,
				searchInput: action.payload,
			}
		}
		case ACTION_TYPES.SEARCH_VALUE: {
			return {
				...state,
				searchValue: action.payload,
			}
		}

		default:
			return state
	}
}

export default GlobalSearchReducer
