import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
		margin: '8px 0px',
	},
	heading: {
		color: themesConfig.greyDark.palette.primary.main,
		fontWeight: 'bold',
		fontFamily: 'Poppins',
		margin: '12px 0px',
	},
	subheading: {
		color: '#707680',
		margin: '8px 0px',
	},
	autocomplete: {
		'& .inputFocused': {
			borderColor: theme.palette.secondary.dark,
		},
	},
	btn: {
		backgroundColor: '#414A58',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#252F3E',
		},
	},
}))

export default useStyles
