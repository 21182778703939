import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

const communicationApi = {
	createPlanArticle: (data, tentUserId) => {
		return axios.post(API_ENDPOINTS.PLAN_ARTICLE_POST, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'multipart/form-data', 'X-SECURITY': csrf(), path: { tentUserId } },
		})
	},
	assignPlanReminder: (data) => {
		return axios.post(API_ENDPOINTS.ASSIGN_ARTICLE_POST, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf() },
		})
	},
	getArticleDetails: (articleUuid) => {
		return axios.get(`${API_ENDPOINTS.PLAN_ARTICLE_GET}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { articleUuid },
			},
		})
	},
	articleUpdate: (data) => {
		return axios.post(`${API_ENDPOINTS.PLAN_ARTICLE_PUT}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'multipart/form-data',
				'X-SECURITY': csrf(),
			},
		})
	},
	articleDelete: (data) => {
		return axios.delete(`${API_ENDPOINTS.PLAN_ARTILCE_DELETE}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			data: { ...data },
		})
	},
}
export default communicationApi
