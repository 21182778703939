import React from 'react'
import useStyles from './style'
import { useSelector } from 'react-redux'
import { FormControlLabel, Checkbox, Box, TextField, Typography, Skeleton, InputAdornment } from '@mui/material'
import _ from 'lodash'
import { Search, Clear } from '@mui/icons-material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect } from 'react'

function Patients(props) {
	const classes = useStyles()
	const {
		patientList,
		state,
		dispatch,
		loading,
		checkAll,
		setCheckAll,
		patientSearchValue,
		setPatientSearchValue,
		onSearch,
		setPageNo,
		hasMore,
		setHasMore,
		patientsData,
	} = props

	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName || {})
	const isCare = _.isEqual(groupName, 'health care')

	const toggleCheckAll = (e) => {
		setCheckAll((prev) => !prev)
		let currentCheckAllState = e.target.checked
		if (currentCheckAllState === false) {
			dispatch({
				type: 'setPatientList',
				data: [],
			})
		}
	}

	const handleCheckboxChange = (patient) => {
		if (state?.patientList.some((item) => item.custUuid === patient?.custUuid)) {
			// removing item,  when click is made on already selected item
			let unSelectingTheSelectedPatient = state?.patientList.filter((s) => s?.custUuid !== patient?.custUuid)
			dispatch({
				type: 'setPatientList',
				data: unSelectingTheSelectedPatient,
			})
			if (unSelectingTheSelectedPatient?.length !== state?.patientList?.length) {
				setCheckAll(false)
			}
		} else {
			// adding to the seleted Patients/clients
			let selectedPatients = [...state?.patientList, patient]
			dispatch({
				type: 'setPatientList',
				data: selectedPatients,
			})
			if (selectedPatients?.length !== state?.patientList?.length) {
				setCheckAll(false)
			}
		}
	}

	useEffect(() => {
		if (checkAll) {
			if (patientSearchValue?.length >= 2) {
				setCheckAll(false)
				return
			} else {
				// making all the patients sekected
				dispatch({
					type: 'setPatientList',
					data: patientList,
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkAll, patientSearchValue, patientList])

	const fetchMoreData = () => {
		if (patientList.length >= patientsData?.totalCount) {
			setHasMore(false)
			return
		} else {
			setPageNo((prev) => prev + 1)
		}
	}

	return (
		<div className={classes.mainContainer}>
			{/* SEARCH FIELD */}
			<TextField
				fullWidth
				size='small'
				id='searchpatienttextfield'
				name='searchpatienttextfield'
				label='Search by Name/ Mobile No/ ID'
				color='secondary'
				value={patientSearchValue}
				onChange={(e, value) => {
					setPatientSearchValue(e.target.value)
					if (e.target.value?.length >= 2) {
						onSearch(e.target.value)
						setPageNo(0)
						setHasMore(true)
					} else if (e.target.value?.length === 0) {
						onSearch(null)
					}
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment position='end'>
							{patientSearchValue === '' ? (
								<Search />
							) : (
								<Clear
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setPatientSearchValue('')
										onSearch(null)
									}}
								/>
							)}
						</InputAdornment>
					),
				}}
			/>

			{/* SELECT-ALL CHECKBOX */}
			<FormControlLabel
				label='Select All'
				className={classes.checkBox}
				control={
					<Checkbox
						id='selectallpatinet'
						name='selectallpatient'
						checked={checkAll}
						disabled={loading || patientSearchValue?.length >= 2}
						onChange={toggleCheckAll}
					/>
				}
			/>

			{/* PATIENTS/CLIENT SELECTION AREA */}
			<div>
				<InfiniteScroll
					dataLength={patientList.length}
					next={fetchMoreData}
					hasMore={hasMore}
					height={260}
					loader={
						<>
							{loading ? (
								<div style={{ marginLeft: 20 }}>
									<Skeleton variant='text' height={40} width={'60%'} />
									<Skeleton variant='text' height={40} width={'60%'} />
									<Skeleton variant='text' height={40} width={'60%'} />
									<Skeleton variant='text' height={40} width={'60%'} />
								</div>
							) : (
								<></>
							)}
						</>
					}
					endMessage={<Typography className={classes.listComp}>You have seen it all</Typography>}
				>
					{_.map(patientList, (item) => (
						<Box key={item?.custUuid} sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
							<div className={classes.groupText}>
								<FormControlLabel
									className={classes.checkBox}
									control={
										<Checkbox checked={state?.patientList.some((sp) => sp.custUuid === item?.custUuid)} onChange={() => handleCheckboxChange(item)} />
									}
									label={item?.custName || ''}
								/>
							</div>
						</Box>
					))}
					{!loading && _.isEmpty(patientList) && <Typography className={classes.noData}>No {isCare ? 'patients' : 'clients'} found</Typography>}
				</InfiniteScroll>
			</div>
		</div>
	)
}

export default Patients
