import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		// marginBlockEnd: 14,
		padding: 14,
		borderRadius: 10,
	},
	card: {
		padding: 16,
		borderRadius: 10,
	},
	customerCard: {
		background: '#E4F5FF',
		cursor: 'pointer',
	},
	appointmentsCard: {
		background: '#E2EFFF',
		cursor: 'pointer',
	},
	revenueCard: {
		background: '#E5FFEC',
	},
	growthCard: {
		background: '#F0EEFF',
	},
	cardTitle: {
		display: 'flex',
		alignItems: 'center',
		gap: 8,
		'& .MuiSvgIcon-root': {
			fontSize: 18,
		},
		'& span': {
			padding: 4,
			background: '#FFF',
			borderRadius: '30%',
		},
		'& .MuiTypography-body1': {
			fontWeight: 600,
		},
	},
	cardContent: {
		paddingBlockStart: 8,
		'& .MuiTypography-h6': {
			fontWeight: 600,
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 600,
			fontSize: '2.1rem',
		},
	},
	cardContentRight: {
		display: 'flex',
		alignItems: 'baseline',
		gap: 4,

		[theme.breakpoints.down('lg')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'row',
		},
		'& .MuiTypography-body2': {
			fontWeight: 500,
		},
		'& span': {
			fontWeight: 600,
		},
	},
	positiveResult: {
		'& span': {
			color: '#2dcd7a',
		},
	},
	negativeResult: {
		'& span': {
			color: 'rgb(249 76 97)',
		},
	},
	cardContainer: {
		padding: 10,
		display: 'grid',
		[theme.breakpoints.up('xs')]: {
			gridTemplateColumns: '1fr',
			gridRowGap: 14,
		},
		[theme.breakpoints.up('sm')]: {
			gridTemplateColumns: 'repeat(2, 1fr)',
			gridRowGap: 18,
			gridColumnGap: 18,
		},
	},
	growthCardTitle: {
		'& .MuiSvgIcon-root': {
			color: '#8c80ed',
		},
	},
	revenueCardTitle: {
		'& .MuiSvgIcon-root': {
			color: '#ffc107',
		},
	},
	appointmentCardTitle: {
		'& .MuiSvgIcon-root': {
			color: '#28a745',
		},
	},
	customerCardTitle: {
		'& .MuiSvgIcon-root': {
			color: '#17a2b8',
		},
	},
}))

export default useStyles
