import React from 'react'
import useStyles from './style'
import { Grid, Typography, Divider } from '@mui/material'
import ButtonComp from 'app/sharedComponents/Button'

function HomePage({ goStart }) {
	const classes = useStyles()
	return (
		<div className={classes.treatmentHome}>
			<Grid container spacing={1}>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container justifyContent='center'>
						<Typography variant='h6' className={classes.aboutCommunication}>
							What is Treatment Communications ?
						</Typography>
						<Typography variant='h6' className={classes.aboutCommunicationDetails}>
							{`This feature allows doctors to assign a ‘treatment communications plan’ for patients with a single click. The ‘treatment communications
							plan’ is the complete package of information and reminders a patient needs during the course of their treatment.`}
						</Typography>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container justifyContent='center' className={classes.aboutCommunicationTwo}>
						<Grid item lg={6} md={6} sm={12}>
							<Typography variant='h6' className={classes.whatCommunication}>
								What does it Offers
							</Typography>
						</Grid>
						<Grid item lg={10} md={10} sm={12} className={classes.stepperOne} justifyContent='space-evenly'>
							<img alt='tailormade' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Tailormade.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='timely' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Timely.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='just' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Just.svg`}></img>
						</Grid>
						<Grid item lg={12} md={12} sm={12} className={classes.cardOne} justifyContent='space-between'>
							<Grid container justifyContent='space-between' className={classes.cardPartMain}>
								<Grid item lg={3.5} md={3.5} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Tailormade Content
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Pre-set plans with customisable content .
									</Typography>
								</Grid>
								<Grid item lg={4} md={4} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Timely Communnication
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Set the day & time to ensure perfect delivery of your communications.
									</Typography>
								</Grid>
								<Grid item lg={3.5} md={3.5} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Just One Click
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Prescribe plans in one click & educate patients for months
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container justifyContent='center' className={classes.aboutCommunicationTwo}>
						<Grid item lg={6} md={6} sm={12}>
							<Typography variant='h6' className={classes.whatCommunication}>
								What does it Work ?
							</Typography>
						</Grid>
						<Grid item lg={10} md={10} sm={12} className={classes.stepperOne} justifyContent='space-evenly'>
							<img alt='one' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/numberone.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='two' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/numbertwo.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='three' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/numberthree.svg`}></img>
						</Grid>
						<Grid item lg={12} md={12} sm={12} className={classes.cardOne} justifyContent='space-between'>
							<Grid container justifyContent='center' className={classes.mainCardTwo}>
								<Grid item lg={3.5} md={3.5} sm={12} className={classes.cardPartTwo}>
									<Typography variant='h6' className={classes.tradeDetails}>
										Pre-set plans with customisable content.
									</Typography>
								</Grid>
								<Grid item lg={4} md={4} sm={12} className={classes.cardPartTwo}>
									<Typography variant='h6' className={classes.tradeDetails}>
										Set the day & time to ensure perfect delivery of your communications.
									</Typography>
								</Grid>
								<Grid item lg={3.5} md={3.5} sm={12} className={classes.cardPartTwo}>
									<Typography variant='h6' className={classes.tradeDetails}>
										Prescribe plans in one click & educate patients for months
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container justifyContent='center' className={classes.aboutCommunicationTwo}>
						<Grid item lg={6} md={6} sm={12}>
							<Typography variant='h6' className={classes.whatCommunication}>
								What are the benefits ?
							</Typography>
						</Grid>
						<Grid item lg={11} md={11} sm={12} className={classes.stepperOne} justifyContent='space-evenly'>
							<img alt='tailormade' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Tailormade.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='timely' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Timely.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='just' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/Just.svg`}></img>
							<Divider className={classes.divider} />
							<img alt='document' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/document.svg`}></img>
						</Grid>
						<Grid item lg={12} md={12} sm={12} className={classes.cardOne} justifyContent='space-between'>
							<Grid container justifyContent='space-between' className={classes.cardPartMain}>
								<Grid item lg={2.8} md={2.8} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Better care for Patients
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Pre-set plans with customisable content.
									</Typography>
								</Grid>
								<Grid item lg={2.8} md={2.8} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Timely Communnication
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Set the day & time to ensure perfect delivery of your communications.
									</Typography>
								</Grid>
								<Grid item lg={2.8} md={2.8} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Just One Click
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Prescribe plans in one click & educate patients for months
									</Typography>
								</Grid>
								<Grid item lg={2.8} md={2.8} sm={12} className={classes.cardPart}>
									<Typography variant='h6' className={classes.tradeTitle}>
										Just One Click
									</Typography>
									<Typography variant='h6' className={classes.tradeDetails}>
										Prescribe plans in one click & educate patients for months
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Divider />
				</Grid>
			</Grid>
			<Grid container justifyContent='center' pt={2} className={classes.gotoStart}>
				<Grid item lg={3} sm={12} md={3}></Grid>
				<Grid item lg={3} sm={12} md={3}></Grid>
				<Grid item lg={3} sm={12} md={3}></Grid>
				<Grid item lg={1.5} sm={12} md={1.5}>
					<ButtonComp onClick={goStart}>Got it, Lets start</ButtonComp>
				</Grid>
			</Grid>
		</div>
	)
}

export default HomePage
