import AidivaaPrime from './AidivaaPrime'

const AidivaaPrimeConfig = {
	routes: [
		{
			path: '/aidivaaPrime',
			component: AidivaaPrime,
		},
	],
}

export default AidivaaPrimeConfig
