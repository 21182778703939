import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		boxSizing: 'border-box',
		margin: '0 auto',
	},
	desc: {
		color: theme.palette.grey[500],
		fontSize: 16,
	},
	infoIco: {
		fontSize: '20em',
		[theme.breakpoints.down('xs')]: {
			fontSize: '15em',
		},
	},
	errTxtWrapper: {
		textAlign: 'center',
		padding: theme.spacing(2),
		boxSizing: 'border-box',
	},
	goBackBtn: {
		marginTop: theme.spacing(2),
		color: themesConfig.greyDark.palette.primary.main,
	},
}))

const PageNotAllowed = ({ isGoBack }) => {
	const classes = useStyles()
	const history = useHistory()

	const onClickNavBtn = () => {
		history.goBack()
	}

	// useEffect(() => {
	// 	if (history?.location?.pathname !== '/setting') {
	// 		history.push('/dashboard')
	// 	}
	// }, [history])

	// useEffect(() => {
	// 	let cartUrl = history?.location?.pathname
	// 	let slicedUrl = cartUrl.slice(0, 5)
	// 	if (history?.location?.pathname !== '/setting' || slicedUrl !== '/cart') {
	// 		history.push('/dashboard')
	// 	}
	// }, [history])

	return (
		<div className={classes.root}>
			{/* <AccessDeniedPage className={classes.infoIco}></AccessDeniedPage> */}
			<div className={classes.errTxtWrapper}>
				<Typography variant='h4' component='h1' gutterBottom>
					Access denied
				</Typography>
				<Typography variant='body1' className={classes.desc}>
					You don't have permissions to access this page.{' '}
				</Typography>
				{/* <Typography variant='body1' className={classes.desc} gutterBottom>
					Please contact your administrator.
				</Typography> */}
				<Button color='primary' onClick={onClickNavBtn} className={classes.goBackBtn}>
					{/* {isGoBack ? '' : 'Go To Home Page'} */}
					Go Back
				</Button>
			</div>
		</div>
	)
}

export default PageNotAllowed
