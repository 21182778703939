import React from 'react'
import useStyles from './style'
import { FormControlLabel, Checkbox, Box, Typography, Skeleton } from '@mui/material'
import _ from 'lodash'

const Children = ({ classes, checked, handleChange, name, count }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
			<div className={classes.groupText}>
				<FormControlLabel label={name} control={<Checkbox checked={checked} onChange={handleChange} />} />
				{/* <Typography>{count} Patient(s)</Typography> */}
			</div>
		</Box>
	)
}

function Staffs({ staffList, state, dispatch, loading }) {
	const classes = useStyles()
	const [checkAll, setCheckAll] = React.useState([])

	const handleChange = (item, idx) => {
		const isExist = _.findIndex(state?.staffList, (o) => o?.tentUserUuid === item?.tentUserUuid) > -1
		if (isExist) {
			let removedData = _.filter(state?.staffList, (o) => o?.tentUserUuid !== item?.tentUserUuid)
			dispatch({
				type: 'setStaffList',
				data: removedData,
			})
		} else {
			let addedData = [...state?.staffList, { ...item }]
			dispatch({
				type: 'setStaffList',
				data: addedData,
			})
		}
	}
	React.useEffect(() => {
		let consolidatedStaffList = _.filter(staffList, (item) => item.mastRoleName !== 'Owner')
		setCheckAll(state?.staffList?.length === consolidatedStaffList.length)
	}, [state?.staffList, staffList])
	const toggleCheckAll = (e) => {
		if (!checkAll === true) {
			dispatch({
				type: 'setStaffList',
				data: staffList,
			})
		} else {
			dispatch({
				type: 'setStaffList',
				data: [],
			})
		}
		setCheckAll(!checkAll)
	}

	return (
		<div className={classes.staffsMain}>
			<div className={classes.listSection}>
				{!loading && !_.isEmpty(staffList || []) && (
					<FormControlLabel label='Select All' control={<Checkbox checked={checkAll} onChange={toggleCheckAll} />} />
				)}
				<div className={classes.listItem}>
					{!loading && !_.isEmpty(staffList || []) ? (
						_.map(staffList, (staff, idx) => {
							const isChecked = _.findIndex(state?.staffList, (o) => o?.tentUserUuid === staff?.tentUserUuid) > -1
							return (
								<>
									{staff?.mastRoleName !== 'Owner' && (
										<Children
											key={idx}
											classes={classes}
											name={`${staff?.tentUserSalutation ? staff?.tentUserSalutation + '. ' : ''} ${staff?.tentUserFirstName || ''}`}
											count={0}
											checked={isChecked}
											handleChange={() => handleChange(staff, idx)}
										/>
									)}
								</>
							)
						})
					) : loading ? (
						<>
							<Skeleton variant='text' height={60} />
							<Skeleton variant='text' height={50} width={'60%'} />
							<Skeleton variant='text' height={50} width={'60%'} />
						</>
					) : (
						<Typography align='center' className='mt-10'>
							No Staff Found
						</Typography>
					)}
				</div>
			</div>
		</div>
	)
}

export default Staffs
