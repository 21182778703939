export const initialState = {
	patientList: [],
	groupList: [],
	staffList: [],
	leadsList: [],
	getTemplate: {},
	getRouteFetchCustData: {},
}

export function reducer(state = initialState, action) {
	switch (action.type) {
		case 'setPatientList':
			return { ...state, patientList: action.data }
		case 'setgroupList':
			return { ...state, groupList: action.data }
		case 'setStaffList':
			return { ...state, staffList: action.data }
		case 'setLeadsList':
			return { ...state, leadsList: action.data }
		case 'resetState':
			return initialState
		default:
			throw new Error()
	}
}
