import Profile from './Profile'

const ProfileConfig = {
	routes: [
		{
			path: '/profile',
			component: Profile,
		},
	],
}

export default ProfileConfig
