import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getCallAccept: (data) => {
		return axios.put(`${API_ENDPOINTS.JITSI_CALL_ACCEPT}`, data, {
			headers: { 'X-SECURITY': csrf(), isAuthRequired: true },
		})
	},
	getRejoinData: (data) => {
		return axios.get(`${API_ENDPOINTS.REJOIN_STATUS_DATA}`, {
			headers: { isAuthRequired: true },
			params: { ...data },
		})
	},
}
