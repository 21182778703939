import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { EffectFade, Autoplay, Pagination, Parallax } from 'swiper'
import { makeStyles } from '@mui/styles'
import { getImgUrl } from 'utils'
SwiperCore.use([EffectFade, Autoplay, Pagination, Parallax])

const useStyles = makeStyles({
	mainContain: {
		'& .swiper-container-android .swiper-slide, .swiper-wrapper': {
			maxWidth: 544,
		},
		'& .swiper-pagination-bullet-active': {
			background: (props) => props.colorTheme,
			width: 20,
			transition: 'width .5s',
			borderRadius: 5,
		},
		'& .swiper-pagination-bullet': {
			background: (props) => props.colorTheme,
		},
	},
	imageContain: {
		display: 'flex',
		justifyContent: 'center',
		'& img': {
			borderRadius: 18,
			width: 1237,
			height: 312,
		},
	},
	textImageContainer: {
		fontSize: 14,
		color: '#000',
		// lineHeight: '22px',
		textAlign: 'center',
	},
	contentTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 42,
	},
})

const Swipper = ({ previewData, BearerToken, formik, establishmentFileResponse }) => {
	const classes = useStyles({ colorTheme: formik?.values?.colorCode })
	return (
		<Swiper
			className={classes.mainContain}
			spaceBetween={0}
			parallax={true}
			centeredSlides
			// effect={'fade'}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
			speed={1200}
			pagination={{
				clickable: true,
			}}
			loop={true}
		>
			{establishmentFileResponse &&
				establishmentFileResponse?.map((item, idx) => (
					<SwiperSlide key={idx}>
						<div className={classes.imageContain}>
							<img src={getImgUrl(item?.uuid || item, BearerToken)} alt='slide1' type='image/webp' />
						</div>
					</SwiperSlide>
				))}
		</Swiper>
	)
}

export default Swipper
