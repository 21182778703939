import { API_ENDPOINTS } from '../constants'
import _ from 'lodash'
import moment from 'moment'
const CryptoJS = require('crypto-js')

function randomString(length, chars) {
	var result = ''
	for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
	return result
}

var secretKey = CryptoJS.enc.Latin1.parse(process.env.REACT_APP_SECRET_KEY)
var ivKey = CryptoJS.enc.Latin1.parse(randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'))
var publicKey = CryptoJS.enc.Latin1.stringify(ivKey)

export const randomUpdate = (length, chars) => {
	var result = ''
	for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
	return result
}

export const getInfoFromErr = (response, list, defaultMsg) => {
	const err = _.get(response, 'message', 'defaultMsg')
	return _.get(list, err, defaultMsg)
}

export const getResponseMessage = (err, list, defaultMsg) => {
	const errMsg = getInfoFromErr(err, list, defaultMsg || err?.message || 'Please try after sometime!')
	return errMsg
}

export const getInfoFromResp = (response, defaultMsg) => {
	return _.get(response, 'message', defaultMsg)
}

export const getSuccessMsg = (response) => {
	const successMsg = getInfoFromResp(response, 'Success!')
	return successMsg
}
export const getImgUrl = (uuid, token) => {
	return `${API_ENDPOINTS.DOWNLOAD_FILE}/${uuid}?token=${encodeURIComponent(token)}&isThumbnail=false`
	// return `${API_ENDPOINTS.DOWNLOAD_FILE}/${uuid}?isThumbnail=true`
}

export const currencyCode = (inputValue, currencyType) => {
	let x = inputValue ? parseFloat(inputValue) : parseFloat(0)
	let result = x.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
	return `${currencyType} ${result}`
}

export const timeFormat = (value, timeZone) => {
	let result = moment(value, `HH:mm:ss${timeZone}`).format('hh:mm A')
	return result
}

export const customDateFormat = (value, format) => {
	return moment(value ? value : new Date()).format(format)
}
export const numberToIndianvalue = (inputValue) => {
	let x = inputValue ? inputValue : 0
	x = x.toString()
	let lastThree = x.substring(x.length - 3)
	let otherNumbers = x.substring(0, x.length - 3)
	if (otherNumbers !== '') lastThree = ',' + lastThree
	let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
	return res
}

export const getDwonload = (tentOrdersUuid) => {
	return `${API_ENDPOINTS.DOWNLOAD_INVOICE}?tentOrdersUuid=${tentOrdersUuid}`
}
export const getImageDownload = (fileUuid, token) => {
	return `${API_ENDPOINTS.CLIENT_FILE_DOWNLOAD}/${fileUuid}/?token=${encodeURIComponent(token)}`
}

export const invoiceDwonload = (tentOrderHeaderUuid) => {
	return `${API_ENDPOINTS.DOWNLOAD_tentOrderHeaderUuid}?tentOrderHeaderUuid=${tentOrderHeaderUuid}`
}
export const toFixed = (e) => {
	return Number.parseFloat(e).toFixed(2)
}

export const fixedValidate = (e, data) => {
	var t = e.target.value
	e.target.value = t.indexOf('.') >= 0 ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3) : t
	return data === 'discount' ? e.target.value.replace(/[^0-9.]/g, '') : e.target.value
}
export const findValue = (data, value) => {
	return _.find(data, (item) => item?.mastLookupValue === value)
}
export const toDecimalFixed = (num, fixed) => {
	fixed = fixed || 0
	fixed = Math.pow(10, fixed)
	return Math.floor(num * fixed) / fixed
}

//fileSize calculation in KB and MB

export const formatFileSize = (bytes) => {
	if (bytes < 1024) {
		return bytes + ' bytes'
	} else if (bytes < 1024 * 1024) {
		return (bytes / 1024).toFixed(2) + ' KB'
	} else {
		return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
	}
}

//X-SECURITY
export const csrf = () => {
	var timeStamp = Date.now() + 1 * 60 * 60 * 1000
	var productName = 'LYFnGO-FLASH'
	var encode = Buffer.from(`${timeStamp + '###' + productName}`).toString('base64')
	return encode
}

//encryption
export const encryption = (data) => {
	var plainText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, { iv: ivKey }).toString()
	return { plainText, publicKey }
}

export const encodeText = (data) => {
	var encode = Buffer.from(data).toString('base64')
	return encode
}

//decryption
export const decryption = (res, key) => {
	let tempKey = key ? CryptoJS.enc.Latin1.parse(key) : CryptoJS.enc.Latin1.parse(res?.headers?.key)
	var cipherText = CryptoJS.enc.Base64.parse(res?.data ? res?.data : res)
	var isDecrypt = CryptoJS.lib.CipherParams.create({ ciphertext: cipherText })
	var bytes = CryptoJS.AES.decrypt(isDecrypt, secretKey, { iv: tempKey })
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	return decryptedData
}

export const failureLogin = (res) => {
	var cipherText = CryptoJS.enc.Base64.parse(res?.response?.data)
	var isDecrypt = CryptoJS.lib.CipherParams.create({ ciphertext: cipherText })
	var bytes = CryptoJS.AES.decrypt(isDecrypt, secretKey, { iv: CryptoJS.enc.Latin1.parse(res?.response?.headers?.key) })
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
	return decryptedData
}

export const getUserPermissions = (state) => _.get(state, 'dynamicMenu.features', [])

export const getModulePerms = (menuName, featureName, permissions) => {
	if (_.isEmpty(menuName)) return {}
	else {
		const modulePerms = _.find(permissions, (o) => o?.mastMenuUrl === menuName && o?.mastFeatureName === featureName)
		return _.get(modulePerms, 'permissions', {})
	}
}

export const getMenuFeatures = (menuList) => {
	let tempArr = []
	_.map(menuList?.topMenu || [], (item) => {
		_.map(item?.featureList, (feature) => {
			tempArr.push({ menuName: item?.mastMenuName, display: item?.display, menuOrder: item?.menuOrder, mastMenuUrl: item?.mastMenuUrl, ...feature })
		})
		if (!_.isEmpty(item.subMenu)) {
			_.map(item.subMenu, (item2) => {
				_.map(item2?.featureList, (feature) => {
					tempArr.push({
						menuName: item2?.mastMenuName,
						display: item2?.display,
						menuOrder: item?.menuOrder,
						mastMenuUrl: item?.mastMenuUrl,
						...feature,
					})
				})
			})
		}
	})
	_.map(menuList?.bottomMenu || [], (item) => {
		_.map(item?.featureList, (feature) => {
			tempArr.push({ menuName: item?.mastMenuName, display: item?.display, menuOrder: item?.menuOrder, mastMenuUrl: item?.mastMenuUrl, ...feature })
		})
		if (!_.isEmpty(item.subMenu)) {
			_.map(item.subMenu, (item2) => {
				_.map(item2?.featureList, (feature) => {
					tempArr.push({
						menuName: item2?.mastMenuName,
						display: item2?.display,
						menuOrder: item?.menuOrder,
						mastMenuUrl: item?.mastMenuUrl,
						...feature,
					})
				})
			})
		}
	})
	_.forEach(menuList?.rightMenu, (item) => {
		_.map(item?.featureList, (feature) => {
			tempArr.push({ menuName: item?.mastMenuName, display: item?.display, menuOrder: item?.menuOrder, mastMenuUrl: item?.mastMenuUrl, ...feature })
		})
	})
	return tempArr
}

export const combineMenus = (menuList) => {
	let tempArr = []
	_.forEach(menuList?.topMenu, (item) => {
		if (item?.subMenu) {
			_.forEach(item?.subMenu, (subItem) => {
				tempArr.push(subItem)
			})
		}
		tempArr.push(item)
	})
	_.forEach(menuList?.bottomMenu, (item) => {
		if (item?.subMenu) {
			_.forEach(item?.subMenu, (subItem) => {
				tempArr.push(subItem)
			})
		}
		tempArr.push(item)
	})
	_.forEach(menuList?.rightMenu, (item) => {
		tempArr.push(item)
	})
	return tempArr
}
//add bill calculation
export const Calculation = (formUnit, formCost, formDiscount, formDiscountUnit, formTax) => {
	var unit = formUnit
	var cost = formCost
	var discount = formDiscount
	var discountUnit = formDiscountUnit
	var costofunits = unit * cost
	var tax = formTax
	var total
	var totalTax = taxCal(tax)
	function taxCal(tax) {
		let temp = []
		_.map(tax, (tx) => {
			const txFloat = +tx?.taxPercent
			temp.push(txFloat)
		})

		return _.map(temp, (retTax) => retTax).reduce((sum, i) => sum + i, 0)
	}

	let totalValue = []
	if (discountUnit?.mastLookupKey === 'PER') {
		var discountAmt = (costofunits / 100) * discount
		total = costofunits - discountAmt
		var c = (total / 100) * totalTax
		total = total + c

		totalValue.push(total)
	} else if (discountUnit?.mastLookupKey === 'AMT') {
		total = costofunits - discount
		var c1 = (total / 100) * totalTax
		total = total + c1
	} else {
		var discountAmnt = (costofunits / 100) * discount
		total = costofunits - discountAmnt
		var c2 = (total / 100) * totalTax
		total = total + c2
	}

	totalValue.push(total)
	return totalValue
}

export const BalanceAmount = (totalValue, paidAmount) => {
	var payable = totalValue
	var paidAmnt = paidAmount
	var temp = []
	var temptotal
	if (paidAmnt > payable) {
		temptotal = paidAmnt - payable
		temp.push(temptotal)
	}

	return temp
}
export const fixedTrunc = (x, n, currencyType) => {
	function toFixed(x) {
		if (Math.abs(x) < 1.0) {
			let e = parseInt(x.toString().split('e-')[1])
			if (e) {
				x *= Math.pow(10, e - 1)
				x = '0.' + new Array(e).join('0') + x.toString().substring(2)
			}
		} else {
			let e = parseInt(x.toString().split('+')[1])
			if (e > 20) {
				e -= 20
				x /= Math.pow(10, e)
				x += new Array(e + 1).join('0')
			}
		}
		return x
	}

	x = toFixed(x)

	// From here on the code is the same than the original answer
	const v = (typeof x === 'string' ? x : x.toString()).split('.')
	if (n <= 0) return v[0]
	let f = v[1] || ''
	if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
	while (f.length < n) f += '0'

	return `${currencyType}${v[0]}.${f}`
}

export function prevent(fn, defaultOnly) {
	return (e, ...params) => {
		e && e.preventDefault()
		!defaultOnly && e && e.stopPropagation()
		fn(e, ...params)
	}
}

export function formatMinutes(minutes) {
	return minutes < 10 ? `0${minutes}` : minutes
}

export function formatSeconds(seconds) {
	return seconds < 10 ? `0${seconds}` : seconds
}
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function generateString(length) {
	let result = ''
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}

	return result
}
export function getDifference(a, b) {
	return Math.abs(a - b)
}
export function capitalizeTemplateName(template_name) {
	if (template_name && template_name.includes('_')) {
		return _.capitalize(template_name.replace(/_/g, ' '))
	} else {
		return template_name
	}
}

export function getBrowserIcon() {
	let imgSrc = null

	const userAgent = navigator.userAgent.toLowerCase()
	if (userAgent.includes('edg')) {
		imgSrc = 'https://img.icons8.com/color/48/ms-edge-new.png' // Edge icon
	} else if (userAgent.includes('chrome')) {
		imgSrc = 'https://img.icons8.com/color/48/chrome--v1.png'
	} else if (userAgent.includes('firefox')) {
		imgSrc = 'https://img.icons8.com/external-those-icons-flat-those-icons/48/external-Firefox-logos-and-brands-those-icons-flat-those-icons.png' // Firefox icon
	} else if (userAgent.includes('safari')) {
		imgSrc = 'https://img.icons8.com/color/48/safari--v1.png' // Safari icon
	} else if (userAgent.includes('opera') || userAgent.includes('opr')) {
		imgSrc = 'https://img.icons8.com/fluency/48/opera.png' // Opera icon
	}

	return imgSrc
}
