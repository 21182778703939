import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, TextField, Typography, InputAdornment, Autocomplete, Chip, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomBtn from 'app/sharedComponents/Button'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import _, { filter } from 'lodash'
import { Cancel } from '@mui/icons-material'
import themesConfig from 'app/fuse-configs/themesConfig'
import { hideLoader, showLoader } from 'services/loader/action'
import seoDetailsApi from '../../../../services/ProfileSetup/SearchEngineOptimization/api'
import { getResponseMessage } from 'utils'

const useStyles = makeStyles((theme) => ({
	domainField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-error': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused.Mui-error': {
			border: 'none',
			borderRadius: 8,
		},
	},
	domainLabel: {
		fontWeight: 600,
		fontSize: 13,
		marginBottom: 6,
	},
	seoDescriptionLength: {
		position: 'absolute',
		bottom: '8px',
		right: '8px',
		fontWeight: 500,
		color: '#000',
	},
	buttonMain: {
		display: 'flex',
		gap: 16,
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		padding: '30px',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	errorText: {
		'& .MuiTypography-root': {
			color: 'red',
		},
	},
}))

const SearchEngineOptimization = ({ history, moveBackTab, moveNextTab }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const groupUserName = useSelector((state) => state?.GroupUser?.groupUserName)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const [isUpdate, setIsUpdate] = useState(false)
	const errorJson = useSelector((state) => state?.errorMessages || {})

	const initialValues = {
		metaTitle: '',
		seoKeywords: [],
		seoDescription: '',
	}

	const validationSchema = Yup.object({
		metaTitle: Yup.string()
			.min(50, 'Meta Title must atleast 50 characters or above')
			.max(60, 'Meta Title must be 60 characters or less')
			.required('Meta title is required'),
		seoKeywords: Yup.array().of(Yup.string()).min(1, 'Minimum 1 Keyword should be added').max(5, 'Maximum 5 Keyword can be added'),
		seoDescription: Yup.string()
			.min(50, 'SEO Description must be atleast 50 characters or above')
			.max(160, 'SEO Description must be 160 characters or less')
			.required('SEO description is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			postSeoDetails(values)
		},
	})

	const postSeoDetails = (values) => {
		const body = {
			seoTitle: values.metaTitle,
			seoKeywords: values.seoKeywords,
			description: values.seoDescription,
			headerUuid: profileHeaderUuid,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		isUpdate ? seoDetailsApi.putSeoDetails(body).then(onSuccess, onFailure) : seoDetailsApi.postSeoDetails(body).then(onSuccess, onFailure)
	}

	const getSeoDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			dispatch(hideLoader())
			formik.setValues({
				metaTitle: res?.data?.data?.seoTitle || '',
				seoKeywords: res?.data?.data?.seoKeywords || [],
				seoDescription: res?.data?.data?.description || '',
			})
			res?.data?.data?.seoTitle && setIsUpdate(true)
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			setIsUpdate(false)
		}

		seoDetailsApi.getSeoDetails(profileHeaderUuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, profileHeaderUuid])

	useEffect(() => {
		getSeoDetails()
	}, [getSeoDetails])

	return (
		<Box minHeight={'55vh'}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>Meta Title*</Typography>
						<TextField
							size='small'
							fullWidth
							id='metaTitle'
							placeholder='Enter meta title'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography fontWeight={500} color={'#000'}>
											{formik.values.metaTitle.length}/60
										</Typography>
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 60 }}
							value={formik.values.metaTitle}
							onChange={(e) => formik.setFieldValue('metaTitle', e.target.value)}
							onBlur={formik.handleBlur}
							error={formik.touched.metaTitle && Boolean(formik.errors.metaTitle)}
							helperText={formik.touched.metaTitle && formik.errors.metaTitle}
						/>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>SEO Keywords*</Typography>
						<Autocomplete
							id='seoKeywords'
							name='seoKeywords'
							value={formik.values.seoKeywords}
							multiple
							size='small'
							options={[]}
							noOptionsText={'Add Keywords'}
							disableCloseOnSelect
							filterSelectedOptions
							limitTags={3}
							onChange={(_, newValue) => {
								formik.setFieldValue('seoKeywords', newValue)
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params)
								const { inputValue } = params
								const isExisting = options.some((option) => inputValue.toLowerCase() === option.toLowerCase())
								const isNewValue = !formik.values.seoKeywords.some((val) => val.toLowerCase() === inputValue.toLowerCase())

								if (inputValue !== '' && !isExisting && isNewValue) {
									filtered.push(inputValue)
								}

								return filtered
							}}
							getOptionLabel={(option) => {
								if (typeof option === 'string') {
									return option
								}
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										key={index}
										variant='outlined'
										label={option}
										size='small'
										sx={{ borderRadius: '30px', border: '0.5px solid #0062DD' }}
										deleteIcon={
											<IconButton
												onClick={() => {
													formik.setFieldValue(
														'seoKeywords',
														filter((val) => val !== option)
													)
												}}
												sx={{}}
											>
												<Cancel
													fontSize='small'
													sx={{
														background: '#fff',
														color: themesConfig.lyfngo.palette.primary.main,
													}}
												/>
											</IconButton>
										}
										{...getTagProps({ index })}
									/>
								))
							}
							error={formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)}
							helperText={formik.touched.seoKeywords && formik.errors.seoKeywords}
							renderInput={(params) => (
								<div>
									<TextField
										{...params}
										size='small'
										placeholder='Enter SEO keywords'
										error={formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)}
										helperText={formik.touched.seoKeywords && formik.errors.seoKeywords}
									/>
								</div>
							)}
						/>

						<Typography sx={{ color: '#727272', fontSize: '12px', mt: 1 }}>
							Note: Use relevant keywords of your microsite, that'll help your site be quickly found online
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>Meta Description*</Typography>
						<TextField
							multiline
							rows={5}
							size='small'
							fullWidth
							id='seoDescription'
							placeholder='Enter meta description'
							value={formik.values.seoDescription}
							onChange={(e) => formik.setFieldValue('seoDescription', e.target.value)}
							onBlur={formik.handleBlur}
							inputProps={{ maxLength: 160 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography className={classes.seoDescriptionLength}>{formik.values.seoDescription.length}/160</Typography>
									</InputAdornment>
								),
							}}
							error={formik.touched.seoDescription && Boolean(formik.errors.seoDescription)}
							helperText={formik.touched.seoDescription && formik.errors.seoDescription}
						/>
					</Grid>
				</Grid>
				<div className={classes.buttonMain}>
					<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
					<CustomBtn type='submit'>{_.isEqual(groupUserName, 'owner') ? 'Save' : 'Save & Next'}</CustomBtn>
				</div>
			</form>
		</Box>
	)
}

export default SearchEngineOptimization
