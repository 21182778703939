import { ACTION_TYPES } from '../../../../constants'

const initialState = {
	appointment: {},
	blockAppointment: {},
	remainder: {},
	doctorId: '',
	categoryId: '',
	openDate: '',
	specialistId: '',
	patientspecialistId: '',
}

const Calendar = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_DOCTOR_ID: {
			return {
				...state,
				doctorId: action.payload,
			}
		}
		case ACTION_TYPES.SET_APPCAT_ID: {
			return {
				...state,
				categoryId: action.payload,
			}
		}
		case ACTION_TYPES.SET_SPECIALIST_ID: {
			return {
				...state,
				specialistId: action.payload,
			}
		}
		case ACTION_TYPES.SET_PATIENT_SPECIALIST_ID: {
			return {
				...state,
				patientspecialistId: action.payload,
			}
		}
		default:
			return state
	}
}

export default Calendar
