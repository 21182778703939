import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	commText: {
		fontSize: 14,
		color: '#414A58',
		paddingBlock: 12,
	},
	createPlanBtn: {
		padding: '8px 4px',
		borderRadius: 10,
		marginBlock: 7,
		gap: 12,
		width: '100%',
		[theme.breakpoints.down('lg')]: {
			width: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: '0 auto',
		},
	},
	noPlanImage: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 40,
		maxWidth: 100,
	},
	commPlanText: {
		fontSize: 16,
		color: '#000000',
		paddingBlock: 0,
		fontWeight: 500,
		[theme.breakpoints.up('xs')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	patientsStatusTextOne: {
		fontSize: 11,
		color: '#FF0000',
		paddingBlock: 0,
		textTransform: 'uppercase',
	},
	patientsStatusTextTwo: {
		fontSize: 11,
		color: '#3B44F6',
		paddingBlock: 0,
		textTransform: 'uppercase',
	},
	patientsStatusText: {
		fontSize: 11,
		color: '#088424',
		paddingBlock: 0,
		textTransform: 'uppercase',
		[theme.breakpoints.up('xs')]: {
			fontSize: 10,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 10,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 11,
		},
	},
}))
export default useStyles
