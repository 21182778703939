import axios from 'axios'
import { API_ENDPOINTS } from '../../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	AddGroup: (mastuuid, body) => {
		return axios.post(API_ENDPOINTS.ADD_PROCEDURE, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	UpdateGroup: (editiUuid, data) => {
		return axios.put(API_ENDPOINTS.EDIT_PROCEDURE, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { editiUuid } },
		})
	},
	GetGroup: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_PROCEDURE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	DeleteGroup: (editiUuid) => {
		return axios.delete(API_ENDPOINTS.DELETE_PROCEDURE, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { editiUuid } },
		})
	},
}
