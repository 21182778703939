import { ACTION_TYPES } from '../../../../constants'

export const tentUuid = (payload) => {
	return {
		type: ACTION_TYPES.TENT_ROLE_UUID,
		payload,
	}
}
export const roleUuid = (payload) => {
	return {
		type: ACTION_TYPES.ROLE_UUID,
		payload,
	}
}

export const doctorUuid = (payload) => {
	return {
		type: ACTION_TYPES.DOCTOR_UUID,
		payload,
	}
}
export const doctorDetails = (payload) => {
	return {
		type: ACTION_TYPES.DOCTOR_DETAILS,
		payload,
	}
}
export const doctorRoleUuid = (payload) => {
	return {
		type: ACTION_TYPES.DOCTOR_ROLE_UUID,
		payload,
	}
}
export const isEditRecordKey = (payload) => ({
	type: ACTION_TYPES.ISEDITRECORD,
	payload,
})

export const isChartingRecordPresent = (payload) => ({
	type: ACTION_TYPES.ISCHARTINGDATAPRESENT,
	payload,
})

export const chartingTentIdAction = (payload) => ({
	type: ACTION_TYPES.CHARTINGTENTID,
	payload,
})

export const categoryUuidAction = (payload) => ({
	type: ACTION_TYPES.CATEGORY_UUID,
	payload,
})
export const isCurrentAppointmentUuid = (payload) => ({
	type: ACTION_TYPES.ISCURRENTAPPUUID,
	payload,
})

export const customer_Uuid = (payload) => ({
	type: ACTION_TYPES.CUSTOMER_UUID,
	payload,
})

export const profileViewData = (payload) => ({
	type: ACTION_TYPES.PROFILE_VIEW_DATA,
	payload,
})
export const billingOrderUuid = (payload) => {
	return {
		type: ACTION_TYPES.DOCTOR_ROLE_UUID,
		payload,
	}
}
export const updatePaymentModal = (payload) => {
	return {
		type: ACTION_TYPES.PENDING_PAYMENT_MODAL,
		payload,
	}
}
export const billingTentUserUuidAction = (payload) => {
	return {
		type: ACTION_TYPES.BILLING_TENT_USER_UUID,
		payload,
	}
}
export const updateRefundPaymentModal = (payload) => {
	return {
		type: ACTION_TYPES.REFUND_PAYMENT_MODAL,
		payload,
	}
}
export const showBillingInvoiceDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_BILLING_INVOICEDETAILS,
		payload,
	}
}
export const showBillingPaymentDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_BILLING_PAYMENT_DETAILS,
		payload,
	}
}
export const showBillingRefundDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_BILLING_REFUND_DETAILS,
		payload,
	}
}
export const showBillingAppointmentDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_BILLING_APPOINTMENT_DETAILS,
		payload,
	}
}
export const showChartingRecords = (payload) => {
	return {
		type: ACTION_TYPES.CHARTING_RECORDS,
		payload,
	}
}

export const showPendingModalDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_PENDING_DETAILS,
		payload,
	}
}

export const showRefundModalDetails = (payload) => {
	return {
		type: ACTION_TYPES.SHOW_REFUND_DETAILS,
		payload,
	}
}
export const todaysappointmentListData = (payload) => {
	return {
		type: ACTION_TYPES.TODAYS_APPOINTMENTS,
		payload,
	}
}
export const todaysMetAppointmentListData = (payload) => {
	return {
		type: ACTION_TYPES.TODAYS_MET_APPOINTMENTS,
		payload,
	}
}
export const groupSettingsData = (payload) => {
	return {
		type: ACTION_TYPES.GROUP_DATA,
		payload,
	}
}
export const billingHistoryApiTriggerFn = (payload) => {
	return {
		type: ACTION_TYPES.BILLING_HISTORY_API_TRIGGER,
		payload,
	}
}
export const customerListApiTriggerFn = (payload) => {
	return {
		type: ACTION_TYPES.CUSTOMER_LIST_API_TRIGGER,
		payload,
	}
}
export const workoutPlanApiTriggerFn = (payload) => {
	return {
		type: ACTION_TYPES.WORKOUT_PLAN_TRIGGER,
		payload,
	}
}

export const invoiceDetailsTriggerFn = (payload) => {
	return {
		type: ACTION_TYPES.INVOICE,
		payload,
	}
}

export const invoiceDetailsRefundFn = (payload) => {
	return {
		type: ACTION_TYPES.INVOICE_REFUND,
		payload,
	}
}

export const setCurrentSelectedClient = (payload) => {
	return {
		type: ACTION_TYPES.SET_CURRENT_CLIENT,
		payload: payload,
	}
}
