import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Avatar, Grid, Typography, MenuItem, Popover, ListItemText } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getImgUrl, getResponseMessage } from 'utils'
import useStyles from './style'
import { v4 as uuidv4 } from 'uuid'
import CaptureDialog from '../../CaptureDialog'
import { showMessage } from 'app/store/fuse/messageSlice'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import fileApi from '../../../../services/fileupload/GetApi'
import _ from 'lodash'

//FORMDATA conversion from DATAURL
function blobCreationFromURL(str) {
	let pos = str.indexOf(';base64,')
	let type = str.substring(5, pos)
	let b64 = str.substr(pos + 8)
	let imageContent = atob(b64)
	let buffer = new ArrayBuffer(imageContent.length)
	let view = new Uint8Array(buffer)
	for (var n = 0; n < imageContent.length; n++) {
		view[n] = imageContent.charCodeAt(n)
	}
	let blob = new Blob([buffer], { type: type })
	return blob
}

const CompDropZone = (props) => {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const errorMessages = useSelector((states) => states.errorMessages)
	const dispatch = useDispatch()
	const { BearerToken } = state
	const {
		displayUploadImage,
		imgResponse,
		captureImg,
		setCaptureImg,
		img,
		setImg,
		camOpen,
		setCamOpen,
		handleUploadingFileToTenant,
		setUploadUuid,
		// setIdentityFileResponse,
	} = props
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 2097152
	const [uploadMenu, setUploadMenu] = useState(null)

	const uploadMenuClick = (event) => {
		setUploadMenu(event.currentTarget)
	}

	const uploadMenuClose = () => {
		setUploadMenu(null)
	}
	const webcamRef = useRef(null)

	const videoConstraints = {
		width: 420,
		height: 420,
		facingMode: 'user',
	}

	const handleClose = () => {
		setCamOpen(false)
		setImg(null)
		setCaptureImg(null)
	}
	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		setImg(imageSrc)
		const list = []
		const file = blobCreationFromURL(imageSrc)

		let fileName = new Date().getTime() + `.${file.type.split('/')[1]}`
		let filedata = new File([file], uuidv4() + '___' + fileName, {
			type: file.type,
			lastModified: Date.now(),
		})
		list.push(filedata)
		setCaptureImg(list)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webcamRef])

	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxSize: formatBytes(maxFileSize),
		maxFiles: 1,
		accept: 'image/jpeg, image/jpg, image/jpe, image/png',
		// validator: fileLengthValidator,
		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (acceptedFiles && fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				displayUploadImage(acceptedFiles)
			}
		},
	})

	const deleteImage = () => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setUploadUuid(null)
				// setIdentityFileResponse([])
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorMessages, 'Removed successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err.response.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		fileApi.deleteFile(imgResponse).then(onSuccess, onFailure)
	}
	return (
		<Grid container className={classes.container}>
			{!_.isEmpty(imgResponse) ? (
				<Grid item>
					<Avatar variant='rounded' src={getImgUrl(imgResponse, BearerToken)} alt='' className={classes.userLogo} />
				</Grid>
			) : (
				<Grid item {...getRootProps()}>
					<input {...getInputProps()} />
					<Avatar
						variant='rounded'
						src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
						className={classes.userLogo}
						alt='Profile'
					/>
				</Grid>
			)}
			<Grid item className={classes.mainContainer}>
				<PhotoCamera onClick={uploadMenuClick} />
				<div>
					<Popover
						open={Boolean(uploadMenu)}
						anchorEl={uploadMenu}
						onClose={uploadMenuClose}
						PaperProps={{
							elevation: 1,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							},
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						classes={{
							paper: 'py-8',
						}}
					>
						<MenuItem
							onClick={() => {
								setCamOpen(true)
								uploadMenuClose()
							}}
						>
							<ListItemText primary='Camera' />
						</MenuItem>
						<MenuItem
							onClick={() => {
								uploadMenuClose()
								open()
							}}
							disabled={!_.isEmpty(imgResponse) ? true : false}
						>
							<ListItemText primary='Upload Image' />
						</MenuItem>
						{imgResponse ? (
							<MenuItem
								onClick={() => {
									deleteImage()
									uploadMenuClose()
								}}
							>
								Remove
							</MenuItem>
						) : null}
					</Popover>
				</div>
			</Grid>
			<div className={classes.helperTextContainer}>
				<Typography variant='body1'>Maximum file size 2 MB</Typography>
				<Typography variant='body1'>Format PNG,JPG,JPEG</Typography>
			</div>

			<CaptureDialog
				open={camOpen}
				uploadBtnText={'Upload'}
				handleClose={handleClose}
				capture={capture}
				videoConstraints={videoConstraints}
				webcamRef={webcamRef}
				img={img}
				setImg={setImg}
				captureImg={captureImg}
				setCaptureImg={setCaptureImg}
				handleUploadingFileToTenant={handleUploadingFileToTenant}
			/>
		</Grid>
	)
}

export default CompDropZone
