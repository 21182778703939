import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	createPlanReminder: (data, tentUserId) => {
		return axios.post(API_ENDPOINTS.PLAN_REMINDER_POST, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'multipart/form-data', 'X-SECURITY': csrf(), path: { tentUserId } },
		})
	},
	assignPlanReminder: (data) => {
		return axios.post(API_ENDPOINTS.ASSIGN_REMINDER_POST, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf() },
		})
	},
	getReminderDetails: (planReminderUuid) => {
		return axios.get(`${API_ENDPOINTS.PLAN_REMINDER_GET}`, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { planReminderUuid },
			},
		})
	},
	reminderUpdate: (data) => {
		return axios.post(`${API_ENDPOINTS.PLAN_REMINDER_PUT}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'multipart/form-data',
				'X-SECURITY': csrf(),
			},
		})
	},
	reminderDelete: (data) => {
		return axios.delete(`${API_ENDPOINTS.PLAN_REMINDER_DELETE}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			data: { ...data },
		})
	},
}
