import Chart from 'react-apexcharts'
import _ from 'lodash'
import moment from 'moment'

function PieChart({ dashboardData }) {
	const chartSettings = {
		options: {
			chart: {
				type: 'line',
				id: 'revenue-chart',
				toolbar: {
					show: true,
					offsetX: -40,
					offsetY: -8,
					tools: {
						download: false,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: false,
						reset: true,
					},

					autoSelected: 'zoom',
				},
			},

			stroke: {
				curve: 'straight',
				width: 2,
				colors: ['#5EB842'],
			},

			markers: {
				size: 5,
				colors: ['#5EB842'],
				strokeColors: '#fff',
				strokeWidth: 2,
				strokeOpacity: 0.9,
				strokeDashArray: 0,
				fillOpacity: 1,
				discrete: [],
				shape: 'circle',
				radius: 2,
				showNullDataPoints: true,
				hover: {
					size: 6,
				},
			},

			xaxis: {
				type: 'datetime',
			},

			grid: {
				show: true,
				borderColor: '#d9d9d9',
				strokeDashArray: 0,
				position: 'back',
				xaxis: {
					lines: {
						show: true,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				row: {
					opacity: 0.3,
				},
				column: {
					opacity: 0.3,
				},
			},

			responsive: [
				{
					breakpoint: 6560,
					options: {
						chart: {
							width: '98%',
							height: 400,
						},
					},
				},
				{
					breakpoint: 2500,
					options: {
						chart: {
							width: '98%',
							height: 362,
						},
					},
				},
				{
					breakpoint: 1600,
					options: {
						chart: {
							width: '98%',
							height: 362,
						},
					},
				},
			],
		},
	}

	// the below code converts the data we get for the chart to data format in which chart accepts
	const inputData = !_.isEmpty(dashboardData?.LineGraphValue?.paymentList)
		? dashboardData?.LineGraphValue?.paymentList
		: [
				{
					x_axis: moment().format('YYYY-MM-DD'),
					value: 0,
				},
		  ]

	const requiredformat = {
		x_axis: 'x',
		value: 'y',
	}

	const outputData = inputData.map(function (obj) {
		return _.mapKeys(obj, function (value, key) {
			return requiredformat[key]
		})
	})

	const chartData = {
		series: [
			{
				name: 'revenue',
				data: outputData,
			},
		],
	}

	return <Chart options={chartSettings.options} series={chartData?.series} type='line' height={314} />
}
export default PieChart
