import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	registerForm: {
		'& .MuiOutlinedInput-root': {
			minWidth: 320,
			[theme.breakpoints.down(550)]: {
				minWidth: 300,
			},
		},
	},
	section2: {
		display: 'flex',
		flexDirection: 'row',
		marginBlockEnd: 8,
		'& .MuiOutlinedInput-root': {
			paddingRight: theme.spacing(1),
		},
	},
	country: {
		width: 'auto',
		flex: 1,
		borderLeft: 'none',
		'& .MuiOutlinedInput-root': {
			padding: '14px!important',
			width: 99,
		},
		'& .muiltr-e82l64-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
			width: 35,
		},
	},
	termsandConditions: {
		color: 'red !important',
		cursor: 'pointer',
	},
	googleBtn: {
		'& .MuiButtonBase-root': {
			display: 'flex',
			alignItems: 'center',
			marginBlockStart: 8,
			paddingBlock: 7,
			paddingInline: 8,
			width: '100%',
			background: '#fff',
			borderRadius: 24,
			border: '1px solid #C3C3C3',
			maxHeight: 34,
			fontWeight: 600,
			gap: 4,
			color: '#000',
			'&:hover': {
				background: '#fff',
				color: '#887E89',
			},
			'&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
				background: '#fff',
				color: 'rgba(0, 0, 0, 0.26)',
			},
		},
	},
	number: {
		// minWidth: 400,
		// [theme.breakpoints.down(550)]: {
		// 	minWidth: 300,
		// },
		'&[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			display: 'none',
		},
		'& .MuiOutlinedInput-root': {
			paddingInline: 0,
		},
		'& .MuiAutocomplete-root': {
			'& .MuiAutocomplete-input': {
				padding: '7.5px 4px 7.5px 0px',
			},
			'& .MuiAutocomplete-endAdornment': {
				right: 0,
			},
		},
		'& .MuiInputAdornment-positionStart': {
			'& .MuiAutocomplete-root': {
				'& .MuiTextField-root': {
					'& .MuiOutlinedInput-root': {
						padding: '6px',
						'& fieldset': {
							border: 'none',
							// borderRight: '1px solid #959ca9',
							borderRadius: 0,
						},
					},
				},
			},
		},
		'& .MuiInputAdornment-positionEnd': {
			paddingRight: '16px',
		},
	},
	visibleBtn: {
		padding: 0,
	},
	termsCondition: {
		color: themesConfig.greyDark.palette.primary.main,
		cursor: 'pointer',
		fontSize: '13px',
		borderBottom: `1px solid ${themesConfig.greyDark.palette.primary.main}`,
	},
	errorMessage: {
		margin: '3px 14px 0px',
		paddingBottom: 8,
	},
	whatappContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: 12,
		[theme.breakpoints.down(550)]: {
			flexDirection: 'column',
		},
		'& .MuiButtonBase-root-MuiRadio-root': {
			color: '#0062DD',
		},
	},
	privacyContainer: {
		display: 'block',
		textAlign: 'center',
		color: '#000',
		fontWeight: 500,
		[theme.breakpoints.down(550)]: {
			textAlign: 'start',
		},
	},
	progressbar: {
		height: 6,
		borderRadius: 4,
		background: '#E8E8E8',
		'& .MuiLinearProgress-bar': {
			background: (props) =>
				props?.passwordStatus === 'Weak'
					? '#E93B3E'
					: props?.passwordStatus === 'Fair'
					? '#EFCE4D'
					: props?.passwordStatus === 'Good'
					? '#185D7F'
					: '#12E334',
		},
	},
}))

export default useStyles
