import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

const micrositeBaseURL = process.env.REACT_APP_MICROSITE_URL

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDomainValidate: (body, mastuuid, uuid) => {
		return axios.post(API_ENDPOINTS.DOMAIN_VALIDATE, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid, uuid } },
		})
	},
	addSiteSetup: (body, mastuuid, uuid) => {
		return axios.post(API_ENDPOINTS.SAVE_SITEUP, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid, uuid } },
		})
	},
	updateSiteSetup: (body, mastuuid, uuid) => {
		return axios.put(API_ENDPOINTS.UPDATE_SITEUP, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid, uuid } },
		})
	},
	getSiteData: (mastuuid, uuid) => {
		return axios.get(API_ENDPOINTS.GET_SITE_DATA, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, uuid } },
		})
	},

	getSpecialityDetails: (mastuuid) => {
		return axios.get(micrositeBaseURL + API_ENDPOINTS.GET_PROFILE_SPECIALITY, {
			headers: { isAuthRequired: false, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},

	getProfileTentUserDetails: (mastuuid, params) => {
		return axios.get(micrositeBaseURL + API_ENDPOINTS.GET_COMPLETE_PROFILE_TENT_USERS, {
			headers: { isAuthRequired: false, 'Content-Type': 'application/json', path: { mastuuid } },
			params: { ...params },
		})
	},
}
