import { combineReducers } from '@reduxjs/toolkit'
import auth from 'app/auth/store'
import fuse from './fuse'
import i18n from './i18nSlice'
import Authentication from '../session/reducer'
import Snackbar from '../../services/snackbar/reducer'
import GeoInfo from '../../services/geoInfo/reducer'
import dynamicMenuReducer from '../../services/DynamicMenu/reducer'
import errorMessages from '../../services/error/reducer'
import loader from '../../services/loader/reducer'
import ProfileSetupReducer from 'services/ProfileSetup/list/reducer'
import GroupUserNameReducer from 'services/ProfileSetup/groupUser/reducers'
import CustomerUserIdReducer from 'services/patient/patientDetails/reducer/index'
import addAppointmentIdReducer from '../../services/patient/addAppointment/reducer'
import chartingReducer from 'services/charting/Redux/reducer'
import ClientReducer from 'services/Client/reducer'
import prompt from 'services/Prompt/reducer'
import RoleIdReducer from 'services/patient/roleBasedUuid/reducer'
import AppointmentDataReducer from 'services/patient/AppointmentData/reducer'
import LetsStartBtn from 'services/Communication/reducer'
import Verification from 'services/verification/reducer'
import SettingsReducer from 'services/SettingRedux/reducer/index'
import SideMenuReducer from 'services/sidemenu/reducer/index'
import CustomerUuidAction from '../../services/patient/patientDetails/reducer'
import SettingsChartingReducer from '../../services/settingCharting/reducer'
import lookupData from '../../services/LookupData/reducer'
import calendar from '../../services/CalendarApi/CalendarData/reducer'
import WalkthroughReducer from 'services/walkthrough/reducer'
import GlobalSearchReducer from 'services/AdvancedSearch/reducer'
import verificationMail from 'services/VerficationModal/reducer'
import otpverificationModal from 'services/otpModal/reducer'
import SubscriptionReducer from 'services/SubscriptionRedux/reducer'
import ImportExport from 'services/ImportExport/reducer'
import ConsentForm from 'services/consentformApi/reducer'
import socketNotification from 'services/PushNotification/reducer'
import AppointmentReducer from 'services/Appointments/reducer'
import ReminderReducer from 'services/Reminder/reducer'
import LeadsReducer from 'services/Leads/reducer'
import PracticeDetails from 'services/Settings/PracticeDetails/reducer'
import UserManagementDetails from 'services/Settings/UserManagement/reducer'
import WhatsappCenter from 'services/WhatsappCenter/reducer'
import chatConsultReducer from 'services/chatConsult/reducers'
import eventAction from 'services/EventManagement/storage/reducer'
import dietPlanReducer from 'services/DietPlan/reducer'
import Feedback from 'services/Feedback/reducer'
import JistConsultReducer from 'services/JitsiConsult/reducers'

const createReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
		fuse,
		i18n,
		Authentication,
		GeoInfo,
		errorMessages,
		Snackbar,
		dynamicMenu: dynamicMenuReducer,
		profileSetup: ProfileSetupReducer,
		GroupUser: GroupUserNameReducer,
		CustomerUserIdReducer,
		CustomerUuidAction,
		AppointmentDataReducer,
		addAppointmentIdReducer,
		Charting: chartingReducer,
		Client: ClientReducer,
		Leads: LeadsReducer,
		loader,
		prompt,
		RoleIdReducer,
		PracticeDetails,
		UserManagementDetails,
		LetsStartBtn,
		SettingsReducer,
		SideMenuReducer,
		Verification,
		SettingsChartingReducer,
		WhatsappCenter,
		lookupData,
		calendar,
		walkthrough: WalkthroughReducer,
		GlobalSearchReducer,
		verificationMail,
		otpverificationModal,
		SubscriptionReducer,
		ImportExport,
		ConsentForm,
		socketNotification,
		createAppointment: AppointmentReducer,
		calendarReminder: ReminderReducer,
		chatConsult: chatConsultReducer,
		dietPlanReducer: dietPlanReducer,
		EventDetails: eventAction,
		JistConsult: JistConsultReducer,
		Feedback,
		...asyncReducers,
	})

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		state = undefined
	}

	return combinedReducer(state, action)
}

export default createReducer
