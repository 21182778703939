import { Card, CardContent } from '@mui/material'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../sharedComponents/Button'
import secureLocalStorage from 'react-secure-storage'
import ForgetPassword from 'images/login/ForgetPassword.png'

const Root = styled('div')(({ theme }) => ({
	background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {
		maxWidth: 400,
	},

	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .cardContent': {
		paddingBlock: '12px',
		paddingInline: '28px',
	},
	'& .text': {
		margin: '16px 0',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid black',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .btn': {
		marginBlock: '20px',
	},
}))

function Reset({ history }) {
	const isAuthenticated = useSelector((states) => states?.Authentication?.isAuthenticated)
	const navigateTo = () => {
		secureLocalStorage.clear()
		history.push('/')
	}

	useEffect(() => {
		if (isAuthenticated) {
			history.push('/')
		}
	}, [history, isAuthenticated])

	return (
		<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
			<div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className='flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden'
			>
				<Card className='Login-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0' square>
					<CardContent className='cardContent flex flex-col items-center justify-center w-full py-96 max-w-320'>
						<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className='flex items-left mb-16'>
								<div>
									<Typography style={{ paddingBlock: 16, fontSize: 16, color: '#6B7280' }}>Password Reset Successful!</Typography>
									<Typography style={{ fontSize: 14, color: '#6B7280' }}>
										Your password has been successfully changed, so kindly Login again to continue using your account.
									</Typography>
									<form className='flex flex-col justify-center w-full'>
										<Button onClick={navigateTo} style={{ marginBlock: '20px' }}>
											Back to Login
										</Button>
									</form>
								</div>
							</div>
						</div>
					</CardContent>
				</Card>

				<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
					<div className='max-w-320'>
						<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
							<img src={ForgetPassword} alt='ResetPassword' />
						</div>
					</div>
				</div>
			</div>
		</Root>
	)
}

export default Reset
