import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, Checkbox, Divider, Grid, ListItem, ListItemIcon, ListItemText, TextField, Typography, useMediaQuery } from '@mui/material'
import useStyles from './style'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import { showMessage } from 'app/store/fuse/messageSlice'
import { decryption, getResponseMessage } from 'utils'
import CalendarTimingApi from 'services/Settings/Calendar/index'
import establishmentApi from 'services/ProfileSetup/EstablishmentDetails/api'
import CancelIcon from '@mui/icons-material/Cancel'
import profiletable from 'services/ProfileSetup/ProfileTable/api'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import moment from 'moment'
import * as Yup from 'yup'
import { useTheme } from '@mui/styles'
import { useFormik } from 'formik'
import SlotForm2 from 'app/main/apps/settings/Calendar/EditDialog/SlotForm/slotForm2'

const formikFormatedData = {
	specialityName: null,
	// isPaymentMandatory: false,
	details: [
		{
			timings: [
				{
					dayOn: [],
					allDay: 'false',
					sessionStartTime: null,
					sessionEndTime: null,
					consultantionFees: '',
					consultantionMode: [],
					consultantionDuration: null,
					isPaymentMandatory: false,
				},
			],
		},
	],
}

const IndividualTimings = () => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const classes = useStyles({ isMobile })
	const state = useSelector((state) => state.Authentication)
	const { uuid, identityUuid, mastuuid } = state
	const errorJson = useSelector((states) => states?.errorMessages || {})
	const groupId = useSelector((state) => state.dynamicMenu?.userData?.groupId)
	const groupUser = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = groupUser
	const [list, setList] = useState([])
	const [loading, setLoading] = useState(true)
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [calTime, setCalTime] = useState('')
	const [selectedDays, setSelectedDays] = useState([])
	const [practiceList, setPracticeList] = useState([])
	const [specialityList, setSpecialityList] = useState([])
	const [specialityLoading, setSpecialityLoading] = useState(false)
	const [currentTentTypeId, setCurrentTentTypeId] = useState(null)
	const [editData, setEditData] = useState({
		practiceName: null,
		dayOn: [],
		specialityDetails: [{ ...formikFormatedData }],
	})
	const timeZone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezoneOffset)
	const [paymentRequired, setPaymentRequired] = useState(false)
	const [allDaySelected, setAllDaySelected] = useState(false)
	const [timeFormat, setTimeFormat] = useState(null)
	const [days, setDays] = useState([
		{
			day: 'Sunday',
			label: 'Sun',
			isSelected: false,
		},
		{
			day: 'Monday',
			label: 'Mon',
			isSelected: false,
		},
		{
			day: 'Tuesday',
			label: 'Tue',
			isSelected: false,
		},
		{
			day: 'Wednesday',
			label: 'Wed',
			isSelected: false,
		},
		{
			day: 'Thursday',
			label: 'Thu',
			isSelected: false,
		},
		{
			day: 'Friday',
			label: 'Fri',
			isSelected: false,
		},
		{
			day: 'Saturday',
			label: 'Sat',
			isSelected: false,
		},
	])

	// Timing hours and minute Conversion
	function timeConvert(n) {
		var num = n
		var hours = num / 60
		var rhours = Math.floor(hours)
		var minutes = (hours - rhours) * 60
		var rminutes = Math.round(minutes)
		return rhours === 0 ? rminutes + ' mins' : rminutes === 0 ? rhours + ' hour ' : rhours + ' hour ' + rminutes + ' min'
	}

	const togglePayment = (status) => {
		setPaymentRequired(status)
	}

	const handleAllDay = () => {
		setAllDaySelected(!allDaySelected)
		let tempDays = []
		_.map(days, (day, idx) => {
			tempDays.push({
				...day,
				isSelected: !allDaySelected ? true : false,
			})
		})
		setDays(tempDays)
	}

	const toggleDayClick = (index) => {
		let tempDays = []
		_.map(days, (day, idx) => {
			if (idx === index) {
				tempDays.push({
					...day,
					isSelected: !day?.isSelected,
				})
			} else {
				tempDays.push(day)
			}
		})

		setDays(tempDays)
	}

	useEffect(() => {
		let tempSelectedDays = []
		_.map(days, (day) => day?.isSelected && tempSelectedDays.push(day?.label))
		setAllDaySelected(tempSelectedDays?.length === 7)
		formik?.setFieldValue('dayOn', tempSelectedDays)
		setSelectedDays(tempSelectedDays)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [days])

	const changeSelectedDays = (currentDay) => {
		let tempDays = []
		_.map(days, (day) => {
			if (day.label === currentDay) {
				tempDays.push({
					...day,
					isSelected: false,
				})
			} else {
				tempDays.push(day)
			}
		})

		setDays(tempDays)
	}

	const resetDays = () => {
		let tempDays = []
		_.map(days, (day) => {
			tempDays.push({ ...day, isSelected: false })
		})
		setDays(tempDays)
	}

	const changeSpecialityBasedDays = (specialityHelpers, index, splData) => {
		specialityHelpers.remove(index)
	}

	const getUserTiming = React.useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				let tempArr = []
				_.map(res?.data?.data || [], (item, idx) => {
					tempArr.push({
						tentUserFirstName: `${item?.tentUserFirstName} ${' '}`,
						timings: item?.timings,
						type: item?.timings?.consultantionMode || [],
						edit: item,
					})
				})
				let convertedData = _.map(tempArr, (data, i) => ({ ...data, id: i + 1 }))
				setList(convertedData)
				dispatch(hideLoader())
				setLoading(false)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			setLoading(false)
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson), //text or html
					autoHideDuration: 3000, //ms
					anchorOrigin: {
						vertical: 'top', //top bottom
						horizontal: 'right', //left center right
					},
					variant: 'error', //success error info warning null
				})
			)
		}
		setList([])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : ''
		CalendarTimingApi.GetIndividualUserIdTiming(isTent, groupId).then(onSuccess, onFailure)
		// if (bool === 'individual' && value === 1) {
		// 	CalendarTimingApi.GetIndividualUserIdTiming(data, groupId).then(onSuccess, onFailure)
		// } else if (bool === 'individual' && value === 0) {

		// } else CalendarTimingApi.GetUserIdTiming(data, groupId).then(onSuccess, onFailure)
	}, [dispatch, errorJson, groupId, groupUserName, tentUserId])

	useEffect(() => {
		getUserTiming()
	}, [getUserTiming])

	const onSubmit = () => {
		console.log('next')
	}

	useEffect(() => {
		const onSuccess = (res) => {
			setSpecialityList(res?.data?.data || [])
			setSpecialityLoading(false)
		}
		const onFailure = (err) => {
			setSpecialityList([])
			setSpecialityLoading(false)
		}
		if (!_.isEmpty(currentTentTypeId)) {
			setSpecialityLoading(true)
			establishmentApi.getSpeciality(currentTentTypeId).then(onSuccess, onFailure)
		}
	}, [currentTentTypeId])

	useEffect(() => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setPracticeList(successData?.data?.data || [])
			setCurrentTentTypeId(successData?.data?.data[0]?.mastTentTypeUuid)
		}
		const onFailure = (err) => {
			setPracticeList([])
		}
		identityUuid && profiletable.getPracticeList(identityUuid).then(onSuccess, onFailure)
	}, [identityUuid])

	useEffect(() => {
		if (!_.isEmpty(practiceList)) {
			let currentPractice = _.find(practiceList, { masterTenantUuid: mastuuid, tentUserUuid: uuid })
			formik?.setFieldValue('practiceName', currentPractice)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, practiceList, uuid])

	// Time error

	useEffect(() => {
		if (!_.isEmpty(startDate && endDate)) {
			var now = `04/09/2013 ${startDate}`
			var then = `04/09/2013 ${endDate}`
			setCalTime(moment.utc(moment(then, 'DD/MM/YYYY HH:mm:ss').diff(moment(now, 'DD/MM/YYYY HH:mm:ss'))).format('HH:mm:ss'))
		}
		let minuteConversion = moment.duration(calTime).asMinutes()
		// let conversion = timeConvert(minuteConversion)
		setTimeFormat(minuteConversion)
	}, [calTime, endDate, setTimeFormat, startDate])

	const validationSchema = Yup.object().shape({
		practiceName: Yup.object().nullable().required('Please select practice name'),
		dayOn: Yup.array().min(1, 'Please choose atleast one day').required('Please choose atleast one day'),
		specialityDetails: Yup.array()
			.min(1)
			.of(
				Yup.object().shape({
					details: Yup.array()
						.min(1)
						.of(
							Yup.object().shape({
								timings: Yup.array()
									.min(1)
									.required('Please fill atleast one time slot')
									.of(
										Yup.object().shape({
											sessionStartTime: Yup.string().nullable().required('Required'),
											sessionEndTime: Yup.string().nullable().required('Required'),
											consultantionFees: Yup.string()
												.matches(/^[0-9]*\.?[0-9]*$/, 'numbers only')
												.nullable()
												.max(9999, 'Invalid')
												.required('Required'),
											consultantionMode: Yup.array().min(1, 'Required').required('Required'),
											isPaymentMandatory: Yup.boolean(),
											consultantionDuration: Yup.object()
												.nullable()
												.required('Required')
												.test('consultantionDuration', 'Time limit exceeds', function (value) {
													const { sessionEndTime, sessionStartTime } = this.parent
													let end = moment(sessionEndTime, 'HH')
													let start = moment(sessionStartTime, 'HH')
													var duration = moment.duration(end.diff(start))
													var mins = duration.asMinutes()
													return !_.isNaN(mins) && parseInt(value?.minutes) <= parseInt(mins)
												}),
										})
									),
							})
						)
						.required('Please fill atleast one timing'),
				})
			)
			.required('Please enter the mandatory field'),
	})

	const formik = useFormik({
		initialValues: editData,
		validationSchema,
		enableReinitialize: true,
		onSubmit: onSubmit,
	})

	const changeDaysToPrevious = useCallback(
		(selectedDays) => {
			let currentDays = _.map(days, (d) => ({ ...d, isSelected: false }))
			_.map(selectedDays, (day) => {
				let index = _.findIndex(currentDays, (d) => _.isEqual(day, d?.label))
				if (index !== -1) {
					currentDays[index] = {
						...currentDays[index],
						isSelected: true,
					}
				}
			})
			setDays(currentDays)
		},
		[days]
	)

	useEffect(() => {
		if (!_.isEmpty(list)) {
			let timings = _.map(list[0]?.timings, (slot) => ({
				dayOn: slot?.dayOn || [],
				allDay: slot?.allDay || 'false',
				sessionStartTime: slot?.sessionStartTime || null,
				sessionEndTime: slot?.sessionEndTime || null,
				consultantionFees: slot?.consultantionFees || '',
				consultantionMode: slot?.consultantionMode || [],
				consultantionDuration: {
					consultantionDuration: timeConvert(slot?.consultantionDuration) || null,
					minutes: slot?.consultantionDuration || null,
				},
				isPaymentMandatory: slot?.isPaymentMandatory || false,
			}))
			let data = {
				practiceName: formik?.values?.practiceName ? formik?.values?.practiceName : null,
				dayOn: _.map(list?.timings, (slot) => slot?.dayOn) || [],
				specialityDetails: [
					{
						specialityName: list?.specialityName,
						// isPaymentMandatory: false,
						details: [
							{
								timings: [
									...timings,
									// {
									// 	dayOn: [],
									// 	allDay: 'false',
									// 	sessionStartTime: null,
									// 	sessionEndTime: null,
									// 	consultantionFees: '',
									// 	consultantionMode: [],
									// 	consultantionDuration: null,
									// },
								],
							},
						],
					},
				],
			}
			// setPaymentRequired(details?.edit?.isPaymentMandatory || false)
			setEditData(data)
			var specIdx = data.specialityDetails?.length - 1
			var detailIdx = data?.specialityDetails[specIdx]?.details?.length - 1
			var timingIdx = data?.specialityDetails[specIdx]?.details[detailIdx]?.timings?.length - 1
			var curDayOn = data?.specialityDetails[specIdx]?.details[detailIdx]?.timings[timingIdx]?.dayOn
			changeDaysToPrevious(curDayOn)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list])

	const onPracticeChange = (newValue) => {
		setCurrentTentTypeId(newValue?.mastTentTypeUuid)
		formik?.setFieldValue('practiceName', newValue)
	}

	return (
		<>
			<Grid container className={classes.root}>
				<Grid item lg={12} container alignItems={'center'}>
					<Grid item lg={3} xs={12}>
						<Autocomplete
							id='practice-details'
							disablePortal
							options={practiceList}
							autoHighlight
							disableClearable={true}
							getOptionLabel={(option) => option?.practiceName || ''}
							value={formik?.values?.practiceName}
							onChange={(e, newValue) => {
								onPracticeChange(newValue)
							}}
							disabled={list ? true : false}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.textField}
									color='secondary'
									size='small'
									label={'Practice name *'}
									error={formik.touched.practiceName && formik.errors.practiceName}
									helperText={formik.touched.practiceName && formik.errors.practiceName && formik.errors.practiceName}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item lg={9}></Grid>
				</Grid>
				{/* Slot section */}
				<Grid item lg={12} container className={classes.slotSection}>
					<Grid item lg={12} xs={12}>
						<Typography className={classes.titleSlot}>Slot bookings for:</Typography>
					</Grid>
					{/* days */}
					<Grid item lg={3} xs={12} style={{ flexBasis: '22%' }}>
						<div className={classes.stickyDiv}>
							<div className={formik?.errors?.dayOn && formik?.touched?.dayOn ? classes.dayContainErr : classes.dayContain}>
								<ListItem role='listitem' button disabled>
									<ListItemText primary='Schedule for' />
									<ListItemIcon>
										<ListItemText style={{ marginTop: 10 }} primary='All Day' />
										<Checkbox
											checked={allDaySelected}
											inputProps={{
												'aria-label': 'all items selected',
											}}
											color='default'
											icon={<CancelIcon fontSize='medium' />}
											checkedIcon={<CheckCircleIcon fontSize='medium' />}
										/>
									</ListItemIcon>
								</ListItem>
								<Divider />
								{_.map(days, (slot, index) => (
									<div className={classes.mainList}>
										<ListItem role='listitem' button onClick={() => toggleDayClick(index)} disabled>
											<ListItemText primary={slot.day} />
											<ListItemIcon>
												<Checkbox
													id={`custom-checkbox-${index}`}
													name={slot.day}
													value={slot.day}
													checked={slot.isSelected}
													color='default'
													icon={<CancelIcon fontSize='medium' />}
													checkedIcon={<CheckCircleIcon fontSize='medium' />}
												/>
											</ListItemIcon>
										</ListItem>
									</div>
								))}
							</div>
							{formik?.errors.dayOn && formik?.touched.dayOn ? <div className={classes.errorColor}>{formik?.errors?.dayOn}</div> : null}
						</div>
					</Grid>
					{/* Slots */}
					<Grid item lg={9} xs={12}>
						<SlotForm2
							formikFormatedData={formikFormatedData}
							formik={formik}
							isMobile={isMobile}
							specialityList={specialityList}
							specialityLoading={specialityLoading}
							selectedDays={selectedDays}
							changeSelectedDays={changeSelectedDays}
							changeSpecialityBasedDays={changeSpecialityBasedDays}
							resetDays={resetDays}
							changeDaysToPrevious={changeDaysToPrevious}
							// value={value}
							currentTentTypeId={currentTentTypeId}
							groupId={groupId}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
							togglePayment={togglePayment}
							paymentRequired={paymentRequired}
							slotType={'profile'}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default IndividualTimings
