import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import _ from 'lodash'
import themesConfig from 'app/fuse-configs/themesConfig'
import termsConditionsApi from '../../../../src/services/register/api'
import { hideLoader, showLoader } from '../../../services/loader/action'
import { useDispatch } from 'react-redux'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
	'& .MuiDialog-paper': {
		borderRadius: '0px',
	},
	'& .MuiDialogTitle-root': {
		background: themesConfig.greyDark.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		color: '#fff',
		paddingBlock: 24,
		fontSize: 28,
		fontWeight: 600,
		paddingInline: 100,
		[theme.breakpoints.down('md')]: {
			fontSize: 14,
			paddingBlock: 24,
			whiteSpace: 'nowrap',
		},
	},
	'& .MuiDialogContent-root': {
		paddingInline: 148,
		[theme.breakpoints.down('md')]: {
			paddingInline: 20,
			overflowX: 'hidden',
		},
	},
	'& .MuiIconButton-root': {
		color: '#fff',
		marginBlock: 14,
		[theme.breakpoints.down('md')]: {
			marginBlock: 8,
		},
	},
}))
const useStyles = makeStyles((theme) => ({
	content: {
		textAlign: 'justify',
		fontSize: 14,
		lineHeight: 2,
		color: 'rgb(85, 85, 85)',
	},
	fcon: {
		paddingBlock: 20,
	},
	heading: {
		color: '#555555',
		fontWeight: 600,
	},
	thirdPart: {
		paddingInline: 40,
		[theme.breakpoints.down('md')]: {
			paddingInline: 4,
		},
	},
	thirdPartOne: {
		paddingInline: 28,
		[theme.breakpoints.down('md')]: {
			paddingInline: 4,
		},
	},
}))
const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					{/* <CloseIcon /> */}
				</IconButton>
			) : null}
		</DialogTitle>
	)
}
BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export default function Termsandconditions() {
	const scroll = 'paper'
	const [open, setOpen] = useState(false)
	const [termsPage, setTermsPage] = useState()
	const dispatch = useDispatch()
	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{
			showClose: true,
		},

		{
			showClose: Boolean,
		}
	)
	const popupOpen = pageQuery?.showClose
	const onClose = () => {
		if (popupOpen === false) {
			setOpen(true)
			setPageQuery({
				showClose: false,
			})
		} else {
			//setOpen = true is for user stay in same page  when click the 'Esc" btn
			setOpen(true)
			// const win = window.close('/terms', '_blank')
		}
	}
	useEffect(() => {
		popupOpen ? setOpen(true) : onClose()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [popupOpen])
	//start: Terms and condition API //
	const getTermsandConditions = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setTermsPage(res?.data)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			setTermsPage()
		}
		termsConditionsApi.getTermsAndCondition({ type: 'flash' }).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		getTermsandConditions()
	}, [getTermsandConditions])
	//End: Terms and condition API //
	return (
		<>
			<BootstrapDialog
				onClose={onClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				fullScreen
				scroll={scroll}
				TransitionComponent={Transition}
			>
				{_.isEqual(popupOpen, true) ? (
					<BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
						LYFnGO Terms and Conditions
					</BootstrapDialogTitle>
				) : (
					''
				)}
				<DialogContent dividers>
					<div dangerouslySetInnerHTML={{ __html: termsPage }} />
				</DialogContent>
			</BootstrapDialog>
		</>
	)
}
