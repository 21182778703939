import React, { useState, useEffect } from 'react'
import { Box, Modal, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import successEmailApi from '../../../services/successEmail/api'
import { styled } from '@mui/material/styles'
import history from '@history'
import { makeStyles } from '@mui/styles'
import { showMessage } from 'app/store/fuse/messageSlice'
import { onEmailSuccess } from 'app/session/actions'
import themesConfig from 'app/fuse-configs/themesConfig'
import { encryption, decryption } from 'utils'
import secureLocalStorage from 'react-secure-storage'

const useStyles = makeStyles((theme) => ({
	modalbox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		background: '#fff',
		border: '1px solid #fff',
		borderRadius: '12px',
		boxShadow: 24,
		paddingBlock: '40px',
		paddingInline: '40px',
		'& .muiltr-1auo3o6-MuiTypography-root': {
			fontSize: '14px',
			color: '#263238',
			paddingInline: '30px',
		},
		'& .muiltr-w6yxov-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '54px',
			paddingBlock: '0px',
		},
		'& .muiltr-nia2q8-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '45px',
			'& a:not([role=button]):not(.MuiButtonBase-root)': {
				color: '#2658DC',
			},
		},
	},
	emailtext: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
}))
const ButtonStyle = styled('Button')(({ theme }) => ({
	width: '100%',
	'& .btn': {
		color: '#FFFFFF',
		backgroundColor: themesConfig.greyDark.palette.primary.main,

		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
const SuccessEmail = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const reduxState = useSelector((state) => state.Authentication)
	const { identityUuid } = reduxState
	const [state, setState] = useState({
		specializationRedirect: false,
		invalidVerificationTOken: false,
		showModal: false,
		showfailModal: false,
		showAlready: false,
	})
	const navigate = () => {
		history.push({
			pathname: '/establishmentselection',
			state: {
				createDemo: true,
			},
		})
	}
	const navigateto = () => {
		history.push('/')
	}
	useEffect(() => {
		var url = window.location.href
		var parts = url.split('?')
		let token = ''
		for (let i = 1; parts.length > i; i++) {
			token += parts[i]
		}
		let queryValue = token.replace('token=', '')

		const body = {
			verificationToken: queryValue,
			uuid: identityUuid || null,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				if (!successData.isAlreadyVerified) {
					dispatch(onEmailSuccess({ ...successData, queryValue }))
					setState((prevState) => ({ ...prevState, showModal: true }))
				} else if (successData.isAlreadyVerified) {
					dispatch(onEmailSuccess({ ...successData, queryValue }))
					setState((prevState) => ({ ...prevState, showAlready: true }))
				} else {
					setState((prevState) => ({ ...prevState, showfailModal: true }))
				}
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: 'Email token expired!',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
			secureLocalStorage.clear()
			history.push('/')
		}
		successEmailApi.setSuccessMail(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<>
			<Modal keepMounted open={state.showModal} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<>
						<div className={classes.emailtext}>
							<div className='d-flex fl-jus-cntr pad-t-20-i'>
								<img
									className='emaill_succes_img'
									src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/successmail.png`}
									alt='emaill_succes_img'
								/>
							</div>
							<div className='text-center'>
								<p className='fw-500 font-14'>Hi your E-mail has been verified successfully </p>
								<p className=''>Click below to continue </p>
							</div>
							<ButtonStyle>
								<Button type='submit' variant='contained' className='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit' onClick={navigate}>
									Continue
								</Button>
							</ButtonStyle>
						</div>
					</>
				</Box>
			</Modal>
			<Modal keepMounted open={state.showfailModal} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<>
						<div className='d-flex fl-jus-cntr pad-t-20-i'>
							<img
								className='emaill_succes_img'
								src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/successmail.png`}
								alt='emaill_succes_img'
							/>
						</div>
						<div className='text-center'>
							<p className='fw-500 font-14'>Your E-mail has not verified please try again</p>
							<p className=''>Click below to continue </p>
						</div>
						<ButtonStyle>
							<Button type='submit' variant='contained' className='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit' onClick={navigateto}>
								Continue
							</Button>
						</ButtonStyle>
					</>
				</Box>
			</Modal>
			<Modal keepMounted open={state.showAlready} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<>
						<div className='d-flex fl-jus-cntr pad-t-20-i'>
							<img
								className='emaill_succes_img'
								src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/successmail.png`}
								alt='emaill_succes_img'
							/>
						</div>
						<div className='text-center'>
							<p className='fw-500 font-14'>Hi your E-mail already verified successfully </p>
							<p className=''>Click below to continue </p>
						</div>
						<ButtonStyle>
							<Button type='submit' variant='contained' className='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit' onClick={navigate}>
								Continue
							</Button>
						</ButtonStyle>
					</>
				</Box>
			</Modal>
		</>
	)
}
export default SuccessEmail
