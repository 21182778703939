import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flex: 'column',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '50%',
		height: '80vh',
		boxShadow: 24,
		zIndex: 9999,
		backdropFilter: 'blur(8px)',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		overflow: 'auto',
		transition: 'all 0.3s linear',
	},
	mainImg: {
		width: '60%',
		height: '50vh',
		borderRadius: 12,
		marginBlock: 12,
		margin: 'auto',
		'& img': {
			objectFit: 'contain',
		},
	},
	downloadIcon: {
		color: 'white',
		border: '1px solid blue',
		borderRadius: '14%',
		backgroundColor: 'blue',
		fill: 'blue',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
		zIndex: 999,
	},
	deleteIcon: {
		color: 'red',
		border: '1px solid red',
		borderRadius: '14%',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
		zIndex: 999,
	},
	closeIcon: {
		color: 'white',
		border: '1px solid white',
		borderRadius: '14%',
		opacity: 1,
		fontSize: 4,
		cursor: 'pointer',
		zIndex: 999,
	},
	pdfName: {
		textAlign: 'center',
		'& .MuiSvgIcon-root': {
			color: themesConfig.greyDark.palette.primary.main,
			fontSize: 50,
		},
	},
}))

export default useStyles
