import React, { useEffect, useState } from 'react'
import Header from './Header'
import AiServices from '../../../../services/AIServices/api'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ThumbUp, ThumbDown, SmartToyRounded, AccountCircleRounded } from '@mui/icons-material'
function AiChatHistory() {
	const useStyles = makeStyles((theme) => ({
		custMessageContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			marginBlock: 8,
		},
		aiMessageContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			marginBlock: 8,
			borderRadius: 8,
		},
		iconContainer: {
			display: 'flex',
			gap: 5,
			paddingTop: 4,
			justifyContent: 'end',
			'& .MuiSvgIcon-root': {
				fontSize: 14,
				color: 'gray',
			},
		},
		icons: {
			'& .MuiSvgIcon-root': {
				fontSize: 14,
				color: 'gray',
			},
		},
		thumbsUp: {
			'& .MuiSvgIcon-root': {
				fontSize: 14,
				color: 'green',
			},
		},
		thumbsDown: {
			'& .MuiSvgIcon-root': {
				fontSize: 14,
				color: 'red',
			},
		},
		leftAlign: {
			background: '#e5f6ff',
			padding: 10,
			borderRadius: '10px',
			maxWidth: 500,
			marginLeft: 8,
			'& .MuiTypograhy-root': {
				fontSize: 15,
			},
		},
		rightAlign: {
			background: '#F4F5F6',
			paddingInline: 8,
			borderRadius: '10px',
			padding: 10,
			marginRight: 8,
			opacity: 1,
			maxWidth: 534,
			'& .MuiTypograhy-root': {
				fontSize: 15,
			},
		},
		rightMsgTxt: {
			fontSize: 14,
			color: '#000',
			fontWeight: 500,
			opacity: 1,
		},
		leftMsgTxt: {
			fontSize: 14,
			color: '#000000',
			fontWeight: 500,
		},
	}))
	const auth = useSelector((states) => states?.Authentication)
	const { mastuuid } = auth
	const [loading, setLoading] = useState(false)
	const [sessions, setSessions] = useState([])
	const [selectedSession, setSelectedSession] = useState(null)
	const [chatHistory, setChatHistory] = useState([])
	const classes = useStyles()

	useEffect(() => {
		AiServices.getChatBotSessions(mastuuid).then(
			(res) => {
				setSessions(res?.data?.data)
				setSelectedSession(res?.data?.data[0])
			},
			(err) => {
				setSessions([])
			}
		)
	}, [mastuuid])
	useEffect(() => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setChatHistory(res?.data?.data)
		}
		const onFailure = (err) => {
			setLoading(false)
			setChatHistory([])
		}
		selectedSession &&
			AiServices.getChatHistory({
				page: 0,
				limit: 250,
				all: false,
				session_id: selectedSession?.session_id,
			}).then(onSuccess, onFailure)
	}, [selectedSession])
	function getText(data) {
		try {
			let unescapedString = data.replace(/\\\\/g, '\\').replace(/\\"/g, '"')
			let jsonObject = JSON.parse(unescapedString)
			return jsonObject?.response ? jsonObject?.response : JSON.parse(data)
		} catch (error) {
			return data
		}
	}
	return (
		<div style={{ width: '100%' }}>
			<div style={{ paddingBottom: 10, position: 'sticky', top: 5, background: '#fff' }}>
				<Autocomplete
					disablePortal
					disableClearable={true}
					id='session'
					name='sessionOptions'
					options={sessions || []}
					value={selectedSession}
					getOptionLabel={(option) => option?.session_id}
					sx={{ width: 350 }}
					size='small'
					onChange={(e, value) => {
						setSelectedSession(value)
					}}
					renderInput={(params) => <TextField {...params} placeholder='Select Session' color='secondary' />}
				/>
			</div>
			{!_.isEmpty(chatHistory)
				? _.map(chatHistory, (data, idx) => {
						return (
							<div id={idx} className={_.isEqual(data?.sender, 'cust') ? classes?.custMessageContainer : classes.aiMessageContainer}>
								{_.isEqual(data?.sender, 'ai') && <SmartToyRounded />}
								<div className={_.isEqual(data?.sender, 'cust') ? classes?.rightAlign : classes.leftAlign}>
									<Typography>{getText(data?.message)}</Typography>
									{_.isEqual(data?.sender, 'ai') && (
										<div className={classes.iconContainer}>
											<div className={_.isEqual(data?.thumbsUp, true) ? classes.thumbsup : classes.icons}>
												<ThumbUp />
											</div>
											<div className={_.isEqual(data?.thumbsDown, true) ? classes.thumbsdown : classes.icons}>
												<ThumbDown />
											</div>
										</div>
									)}
								</div>
								{_.isEqual(data?.sender, 'cust') && <AccountCircleRounded />}
							</div>
						)
				  })
				: null}
		</div>
	)
}

export default AiChatHistory
