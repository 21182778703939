import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

const CancelToken = axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setWelcomecareUserIdentity: (publicKey, ivKey, identityUuid) => {
		const result = { data: publicKey }
		return axios.post(
			`${API_ENDPOINTS.WELCOME_SCREEN_IDENTITY}`,
			{ ...result },
			{ headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}`, path: { identityUuid } } }
		)
	},
	setWelcomecare: (publicKey, ivKey, uuid) => {
		const result = { data: publicKey }
		return axios.post(
			`${API_ENDPOINTS.WELCOME_SCREEN}`,
			{ ...result },
			{ headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', key: `${ivKey}`, path: { uuid } } }
		)
	},
	getCountry: (dataType, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.LOOKUP, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},

	getCountryList: () => {
		return axios.get(API_ENDPOINTS.COUNTRY_LIST, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	getCountryFetch: () => {
		return axios.get(API_ENDPOINTS.COUNTRY_FETCH, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	getSupscriptionCountryList: () => {
		return axios.get(API_ENDPOINTS.SUPSCRIPTION_COUNTRY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	getTenantType: (dataType, cancelExecutor) => {
		return axios.get(API_ENDPOINTS.TENANTTYPE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
		})
	},
	getTenantTypePractice: () => {
		return axios.get(API_ENDPOINTS.TENANTTYPEPRACTICE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	getPracticeSpeciality: (dataType, params) => {
		return axios.get(API_ENDPOINTS.PRACTICESPECIALITY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
		})
	},
}
