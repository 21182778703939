import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		'& .inputFocused': {
			borderColor: theme.palette.secondary.dark,
		},
	},
	addSpeciality: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	listItemCont: {
		display: 'flex',
		flexDirection: 'column',
		gap: 6,
		paddingBlock: 12,
	},
	textfield: {
		margin: '10px 0px',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('up')]: {
			display: 'flex',
		},
	},
	skincontent: {
		background: 'rgb(248 218 179)',
		width: '100%',
		padding: '15px',
		fontFamily: 'Poppins',
	},
	cancelExperience: {
		marginTop: theme.spacing(1.5),
		'& .MuiSvgIcon-root': {
			fontSize: 26,
			color: 'red',
			cursor: 'pointer',
			[theme.breakpoints.down('sm')]: {
				marginTop: 0,
			},
		},
	},
	cancelBtn: {
		color: 'red',
		paddingLeft: 0,
		marginBottom: theme.spacing(1),
	},
	addExperiencebtn: {
		color: '#0088ff',
		paddingLeft: 0,
	},
	addExperience: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},

	mainButton: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
		gap: 12,
	},
	mainList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		paddingTop: '0 !important',
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	getvalue: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: '0',
		},
	},
	addedArr: {
		marginBottom: theme.spacing(4),
	},
}))

export default useStyles
