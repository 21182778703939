import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	patientsMain: {
		'& .MuiInputLabel-outlined.Mui-focused': {
			color: '#000',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#000',
			border: '1px solid',
		},
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
	},
	listSection: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 0,
		paddingInline: 8,
		height: 276,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
			paddingBlock: 12,
		},
	},
	searchAuto: {
		[theme.breakpoints.up('xs')]: {
			marginBlock: 0,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 0,
		},
		[theme.breakpoints.up('md')]: {
			maxWidth: 340,
		},
	},
	listItem: {
		height: 168,
		overflow: 'auto',
	},
	checkBox: {},
	patientText: {},
}))
export default useStyles
