import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ModuleSelectionAPI from 'services/ModuleSelection/api'
import _ from 'lodash'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import ButtonBase from '../../../sharedComponents/Button'
import history from '@history'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import { failureLogin, getMenuFeatures, getResponseMessage } from 'utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import { makeStyles } from '@mui/styles'
import secureLocalStorage from 'react-secure-storage'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import DynamicMenuApi from '../../../../services/DynamicMenu/api'
import { updateIswelcome, updateNewUser } from 'app/session/actions'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	mainConatiner: {
		width: '75%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: 8,
		},
		[theme.breakpoints.up('sm')]: {
			width: '100%',
			padding: 8,
		},
		[theme.breakpoints.up('xl')]: {
			width: '75%',
			padding: 8,
		},
	},
	selectedContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 8,
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	selectedCard: {
		borderRadius: 12,
		height: 70,
		width: 110,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: '#E6F4FF',
	},
	addonCard: {
		borderRadius: 12,
		height: 70,
		width: 110,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'relative',
		background: '#E6F4FF',
	},
	closeIcon: { position: 'absolute', right: -4, top: -4, color: '#7D7D7D', cursor: 'pointer' },
	btnContainer: { display: 'flex', justifyContent: 'center', width: '100%', paddingBlock: 12 },
}))

const ModuleSelection = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const errorMessages = useSelector((state) => state?.errorMessages)
	const [loading, setLoading] = useState(false)
	const [modulesList, setModulesList] = useState([])
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid, identityUuid, mastTentTypeUuid } = state
	const [selectedModule, setSelectedModule] = useState([])
	const selectedModuleUuid = selectedModule?.map((uuid, index) => uuid?.mastModuleUuid)
	//Module get api
	const getModulesList = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setModulesList(res?.data?.data)
		}
		const onFailure = (err) => {
			setLoading(false)
			setModulesList([])
		}
		ModuleSelectionAPI.moduleSelectionNew(mastTentTypeUuid).then(onSuccess, onFailure)
	}, [mastTentTypeUuid])
	useEffect(() => {
		getModulesList()
	}, [getModulesList])
	const defualtModule = modulesList?.filter((data) => data?.isDefault === true)
	const defaultModuleUuid = defualtModule?.map((uuid, item) => uuid?.mastModuleUuid)
	const addOnModules = modulesList?.filter((data) => data?.isDefault === false)
	const handleAddonModules = (data) => {
		const isModuleSelected = selectedModule.some((module) => module.mastModuleUuid === data.mastModuleUuid)

		if (!isModuleSelected) {
			setSelectedModule((prevState) => [...prevState, data])
			setModulesList((prevModules) => prevModules.filter((module) => module.mastModuleUuid !== data.mastModuleUuid))
		} else {
			setSelectedModule((prevState) => prevState.filter((module) => module.mastModuleUuid !== data.mastModuleUuid))
			setModulesList((prevModules) => [...prevModules, data])
		}
	}
	const handleRemoveModule = (moduleToRemove) => {
		setSelectedModule((prevState) => prevState.filter((module) => module.mastModuleUuid !== moduleToRemove.mastModuleUuid))
		setModulesList((prevModules) => [...prevModules, moduleToRemove])
	}
	const handleSelectAll = () => {
		if (_.isEqual(selectedModule, [])) {
			setSelectedModule([...defualtModule, ...addOnModules])
			setModulesList([])
		} else {
			setSelectedModule([...defualtModule, ...selectedModule, ...addOnModules])
			setModulesList([])
		}
	}
	const roleNavigated = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(dynamicMenuFetching())
		DynamicMenuApi.getDynamicMenu(uuid, mastuuid).then(onSuccess, onFailure)
	}, [dispatch, mastuuid, uuid])
	//post API
	const postModuleSelection = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		let concatArr = selectedModuleUuid.concat(defaultModuleUuid)
		const body = concatArr
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				// dispatch(
				// 	Login({
				// 		isAuthenticated: secureLocalStorage.setItem('isAuthenticated', true),
				// 		isMobile: secureLocalStorage.getItem('isMobile'),
				// 		uuid: secureLocalStorage.getItem('uuid'),
				// 		isEmail: secureLocalStorage.getItem('isEmail'),
				// 		isPractice: secureLocalStorage.getItem('isPractice'),
				// 		isOtp: secureLocalStorage.getItem('isOtp'),
				// 		BearerToken: secureLocalStorage.getItem('AidiBearer'),
				// 		groupId: secureLocalStorage.getItem('groupId'),
				// 		userType: secureLocalStorage.getItem('userType'),
				// 		userEmail: secureLocalStorage.getItem('userEmail'),
				// 		mastuuid: secureLocalStorage.getItem('mastuuid'),
				// 		emailId: secureLocalStorage.getItem('emailId'),
				// 		mobileNo: secureLocalStorage.getItem('mobileNo'),
				// 		userRole: secureLocalStorage.getItem('userRole'),
				// 		userName: secureLocalStorage.getItem('userName'),
				// 		profilepic: secureLocalStorage.getItem('profilepic') || '',
				// 		countryCode: secureLocalStorage.getItem('countryCode') || '',
				// 		isTokenValid: secureLocalStorage.getItem('isTokenValid') || '',
				// 		identityUuid: secureLocalStorage.getItem('identityUuid') || '',
				// 		owner: secureLocalStorage.getItem('owner'),
				// 		isPinSet: secureLocalStorage.getItem('isPinSet'),
				// 		isModuleSelected: true,
				// 		countryName: secureLocalStorage.getItem('countryName'),
				// 		countryNameAbbreviation: secureLocalStorage.getItem('countryNameAbbreviation'),
				// 		mastTentTypeUuid: secureLocalStorage.getItem('mastTentTypeUuid'),
				// 		signUpViaName: secureLocalStorage.getItem('signUpViaName'),
				// 	})
				// )
				dispatch(updateIswelcome(true))
				dispatch(updateNewUser(true))
				secureLocalStorage.setItem('isWelcome', true)
				secureLocalStorage.setItem('isNewUser', true)
				roleNavigated()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			const failureData = failureLogin(err)
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ModuleSelectionAPI.postModule(mastuuid, identityUuid, uuid, body).then(onSuccess, onFailure)
	}, [defaultModuleUuid, dispatch, errorMessages, identityUuid, mastuuid, roleNavigated, selectedModuleUuid, uuid])
	return (
		<div className={classes?.mainConatiner}>
			{_.isEqual(loading, true) ? (
				<></>
			) : (
				<>
					{/* <div style={{ width: '50%' }}> */}
					<Typography variant='h6'>Module Selection for trial</Typography>
					<Typography variant='subtitle1' fontSize={16} fontWeight={600} style={{ paddingBlockEnd: 12 }}>
						Selected Modules{' '}
					</Typography>
					<div className={classes.selectedContainer}>
						{_.map(
							defualtModule?.map((item, index) => (
								<div className={classes.selectedCard}>
									<img src={item?.imageUrl} alt={item?.moduleName} />
									<Typography variant='subtitle1' fontSize={12}>
										{item?.moduleName}
									</Typography>
								</div>
							))
						)}
						{selectedModule &&
							_.map(
								selectedModule?.map((item, index) => (
									<div className={classes.addonCard} key={index}>
										{!_.isEqual(item?.isDefault, true) && (
											<div className={classes.closeIcon} onClick={() => handleRemoveModule(item)}>
												<CancelSharpIcon />
											</div>
										)}
										<img src={item?.imageUrl} alt={item?.moduleName} />
										<Typography variant='subtitle1' fontSize={12}>
											{item?.moduleName}
										</Typography>
									</div>
								))
							)}
					</div>
					{!_.isEqual(addOnModules, []) && (
						<div className='flex justify-between items-center' style={{ paddingBlock: 12 }}>
							<Typography variant='subtitle1' fontSize={16} fontWeight={600}>
								Add-ons Modules
							</Typography>
							<Typography
								variant='subtitle1'
								fontSize={16}
								fontWeight={500}
								color={themesConfig.lyfngo.palette.primary.main}
								className='underline cursor-pointer'
								onClick={handleSelectAll}
							>
								Select all
							</Typography>
						</div>
					)}
					<div className={classes.selectedContainer}>
						{_.map(
							addOnModules?.map((item, index) => (
								<div
									style={{
										border: '1px solid #0062DD',
										borderRadius: 12,
										height: 70,
										width: 110,
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
									onClick={() => {
										handleAddonModules(item)
									}}
								>
									<img src={item?.imageUrl} alt={item?.moduleName} />
									<Typography variant='subtitle1' fontSize={12}>
										{item?.moduleName}
									</Typography>
								</div>
							))
						)}
					</div>
					<div className={classes.btnContainer}>
						<ButtonBase onClick={() => postModuleSelection()}>Start free trial now</ButtonBase>
					</div>
					{/* </div> */}
				</>
			)}
		</div>
	)
}

export default ModuleSelection
