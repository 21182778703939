import useStyles from './style'
import { useCallback, useEffect } from 'react'
import { Grid, Autocomplete, TextField, Popper, Typography, createFilterOptions, Avatar } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useDispatch, useSelector } from 'react-redux'
import { getAdvancedSearchOptions, addAdvancedSearchValue, getSearchOptions, addSearchInput } from 'services/AdvancedSearch/action'
import _ from 'lodash'
import { upcomingAppointmentData } from 'services/patient/AppointmentData/action'
import globalSearchApi from 'services/GlobalSearch/api'
import history from '@history'
import { decryption } from 'utils'
import secureLocalStorage from 'react-secure-storage'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'

const SearchOptions = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const filterOptions = createFilterOptions({
		// matchFrom: 'start',
		stringify: (option) =>
			option.custName +
			option.custCustomId +
			option.custMobileNo +
			option.custEmail +
			option.custAge +
			option.custDob +
			option.custGender +
			option.postalCode +
			option.custBloodGroup +
			option.address1, // make it one for it
	})
	const searchState = useSelector((state) => state.GlobalSearchReducer)
	const { advanceSearchOptions, advancedSearchValue, searchInput, searchOptions, searchValue } = searchState
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const { groupName } = mainmenuState
	const mastUuid = useSelector((state) => state.Authentication?.mastuuid)
	const advancedParamsConstants = {
		name: `mast_tent_uuid~eq~${mastUuid}~string&cust_name~like~${searchInput}~string`,
		id: `mast_tent_uuid~eq~${mastUuid}~string&cust_custom_id~like~${searchInput}~string`,
		mobileNo: `mast_tent_uuid~eq~${mastUuid}~string&cust_mobile_no~like~${searchInput}~string`,
		age: `mast_tent_uuid~eq~${mastUuid}~string&cust_age~eq~${searchInput}~int`,
		email: `mast_tent_uuid~eq~${mastUuid}~string&cust_email~like~${searchInput}~string`,
		gender: `mast_tent_uuid~eq~${mastUuid}~string&cust_gender~like~${searchInput}~string`,
		bloodGroup: `mast_tent_uuid~eq~${mastUuid}~string&cust_blood_group~like~${searchInput}~string`,
		postalCode: `mast_tent_uuid~eq~${mastUuid}~string&postal_code~like~${searchInput}~string`,
		address1: `mast_tent_uuid~eq~${mastUuid}~string&address_1~like~${searchInput}~string`,
		dob: `mast_tent_uuid~eq~${mastUuid}~string&cust_dob~eq~${searchInput}~date`,
	}

	// Designing Autocomplete Popper
	const CustomPopper = function (props) {
		const classes = useStyles()
		return <Popper {...props} className={classes.popperStyle} placement='bottom-start' />
	}

	const getAdvancedSearchOption = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success' || res?.data?.status === 'SUCCESS' || res?.data?.status === 'Success') {
				let temp = res?.data?.data
				dispatch(getAdvancedSearchOptions(temp))
			}
		}
		const onFailure = (err) => {}
		globalSearchApi.advanceSearchOptions({ mastLookupType: 'ASD' }).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		getAdvancedSearchOption()
	}, [getAdvancedSearchOption])

	useEffect(() => {
		if (!_.isEmpty(advanceSearchOptions)) {
			let init = _.find(advanceSearchOptions, { mastLookupValue: 'Mobile No' })
			dispatch(addAdvancedSearchValue(init))
		}
	}, [advanceSearchOptions, dispatch])

	// Global Search Api
	const getCustomerListSearch = useCallback(() => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success' || successData?.status === 'SUCCESS' || successData?.status === 'Success') {
				if (!_.isEmpty(searchInput) || !_.isEmpty(searchValue) || searchInput?.length > 0) {
					if (!_.isEmpty(advancedSearchValue)) {
						dispatch(getSearchOptions(successData?.data?.items))
					} else {
						dispatch(getSearchOptions(successData?.data))
					}
				}
			} else {
				// dispatch(getSearchOptions([]))
			}
		}
		const onFailure = (err) => {}
		if (!_.isEmpty(advancedSearchValue) && !_.isEmpty(searchInput)) {
			globalSearchApi
				.advancedGlobalSearch({
					filterAnd:
						advancedSearchValue?.mastLookupKey === 'ASD01'
							? advancedParamsConstants?.name
							: advancedSearchValue?.mastLookupKey === 'ASD02'
							? advancedParamsConstants?.id
							: advancedSearchValue?.mastLookupKey === 'ASD03'
							? advancedParamsConstants?.mobileNo
							: advancedSearchValue?.mastLookupKey === 'ASD04'
							? advancedParamsConstants?.email
							: advancedSearchValue?.mastLookupKey === 'ASD05'
							? advancedParamsConstants?.age
							: advancedSearchValue?.mastLookupKey === 'ASD06'
							? advancedParamsConstants?.dob
							: advancedSearchValue?.mastLookupKey === 'ASD07'
							? advancedParamsConstants?.gender
							: advancedSearchValue?.mastLookupKey === 'ASD08'
							? advancedParamsConstants?.postalCode
							: advancedSearchValue?.mastLookupKey === 'ASD09'
							? advancedParamsConstants?.bloodGroup
							: advancedSearchValue?.mastLookupKey === 'ASD10'
							? advancedParamsConstants?.address1
							: null,
				})
				.then(onSuccess, onFailure)
		} else if (!_.isEmpty(searchInput)) {
			globalSearchApi.globalSearch(mastUuid, { name: searchInput }).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advancedSearchValue, mastUuid, searchInput])
	useEffect(() => {
		getCustomerListSearch()
	}, [getCustomerListSearch, advancedSearchValue, mastUuid, searchInput])

	// Resetting Search Options
	useEffect(() => {
		if (_.isEmpty(searchInput) || searchInput === null || _.isEmpty(searchValue) || searchInput?.length === 0 || searchInput?.length < 1) {
			dispatch(getSearchOptions([]))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchInput, searchValue])
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	return (
		<Grid container xs={12} className={classes.gridMain}>
			<Grid item xs={3.5} className={classes.advanceAutoComplete}>
				<Autocomplete
					noOptionsText={'No options'}
					disablePortal
					id='advancefilter'
					name='advancefilter'
					popupIcon={<KeyboardArrowDownIcon />}
					disableClearable
					options={advanceSearchOptions}
					getOptionLabel={(option) => option?.mastLookupValue || ''}
					value={advancedSearchValue}
					onChange={(e, value) => {
						dispatch(addAdvancedSearchValue(value))
					}}
					size='small'
					fullWidth
					renderInput={(params) => <TextField {...params} placeholder='Advance' />}
				/>
			</Grid>
			<Grid item xs={8.5}>
				<Grid container xs={12} className={classes.gridMainTwo}>
					<Grid item xs={12} className={classes.searchAutoComplete}>
						<Autocomplete
							id='advanceFilterSearch'
							name='advanceFilterSearch'
							loadingText={'...Loading'}
							noOptionsText={'No options Found'}
							disablePortal
							popupIcon={<KeyboardArrowDownIcon />}
							options={searchOptions}
							size='small'
							value={searchInput}
							clearOnEscape
							onChange={(e, value) => {
								value && dispatch(upcomingAppointmentData([]))
								dispatch(addSearchInput(''))
								if (!_.isEmpty(value?.custUuid)) {
									dispatch(customer_Uuid(`${value?.custUuid}`))
									secureLocalStorage.setItem('custUuid', `${value?.custUuid}`)
									history.push({
										pathname: `/clientinfo/${value?.custUuid}`,
									})
								}
							}}
							onInputChange={(e, value) => {
								dispatch(addSearchInput(value || ''))
							}}
							filterOptions={filterOptions}
							fullWidth
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={
										advancedSearchValue?.mastLookupKey === 'ASD01'
											? 'Exp - john doe'
											: advancedSearchValue?.mastLookupKey === 'ASD02'
											? 'Exp - p01'
											: advancedSearchValue?.mastLookupKey === 'ASD03'
											? 'Exp - 9000111110'
											: advancedSearchValue?.mastLookupKey === 'ASD04'
											? 'Exp - xyz@gmail.com'
											: advancedSearchValue?.mastLookupKey === 'ASD05'
											? 'Exp - 25'
											: advancedSearchValue?.mastLookupKey === 'ASD06'
											? `Exp -${dateFormatForViewer}`
											: advancedSearchValue?.mastLookupKey === 'ASD07'
											? 'Exp - M/F'
											: advancedSearchValue?.mastLookupKey === 'ASD08'
											? 'Exp - 600003'
											: advancedSearchValue?.mastLookupKey === 'ASD09'
											? 'Exp - B+'
											: advancedSearchValue?.mastLookupKey === 'ASD10'
											? 'Exp - no : 111, new street...'
											: groupName === 'health care'
											? 'Search patients'
											: 'Search clients'
									}
								/>
							)}
							PopperComponent={CustomPopper}
							renderOption={(props, option) => (
								<li {...props}>
									{/* <div className='flex items-center' style={{ width: '100%' }}>
										<Avatar
											alt='client'
											src={option?.custThumbNailPath || '${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png'}
											style={{ height: '34px', width: '34px', marginRight: 10 }}
										/>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{' '}
											<Typography>{option.custName}</Typography>
											<Typography>{option.custCustomId ? `(${option?.custCustomId})` : ''}</Typography>
										</div>
									</div> */}

									<div className={classes.clients_list_item}>
										<Avatar
											alt='client'
											src={option?.custThumbNailPath || `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}
											style={{ height: '34px', width: '34px', marginRight: 10 }}
										/>
										<div>
											<Typography>
												{option.custName} {option?.custCustomId ? `(${option?.custCustomId})` : ''}
											</Typography>
											<Typography>
												{option.custCountryCode} {option.custMobileNo}
											</Typography>
										</div>
									</div>
								</li>
							)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SearchOptions
