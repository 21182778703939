import React, { useState, useEffect, useCallback } from 'react'
import { Typography, Grid, TextField, Button, Tooltip, Link, IconButton, FormHelperText } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useFormik } from 'formik'
import AdvancedAutocomplete from '../../../sharedComponents/AdvancedAutocomplete'
import * as yup from 'yup'
import ButtonComp from '../../../sharedComponents/Button'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import LookupApi from '../../../../services/lookup/api'
import { useSelector, useDispatch } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import _ from 'lodash'
import CompDropZone from '../../../sharedComponents/DropzoneUpload/Dropzone'
import fileUploadDetailsApi from '../../../../services/fileupload/GetApi'
import sendUploadFileApi from '../../../../services/fileupload/PostApi'
import RegisterDetails from '../../../../services/ProfileSetup/RegisterDetail/Getapi'
import { showLoader, hideLoader } from '../../../../services/loader/action'
import { getResponseMessage } from '../../../../utils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from './style'
import { setRegistrationDocument, setRegistrationPersonalProof, setRegistrationEstablishmentProof } from 'services/ProfileSetup/list/actions'

import { FormikProvider, FieldArray, ErrorMessage } from 'formik'

const RegisterDocumention = ({ moveNextTab, moveBackTab, match }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const action = _.get(match, 'params.action', '')
	const routeUuid = _.get(match, 'params.uuid', '')
	const isEditMode = _.isEqual(action, 'edit')
	const isViewMode = _.isEqual(action, 'view')
	const classes = useStyles()
	const dispatch = useDispatch()
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
	const state = useSelector((state) => state.Authentication)
	const { uuid, userType, groupName, groupId, mastuuid } = state
	const [yearOptions, setYearOptions] = useState([])
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [emptyCheck, setEmptyCheck] = useState([])

	const personalProof = useSelector((state) => state?.profileSetup?.registrationDocuments?.personalProof || {})
	const establishmentProof = useSelector((state) => state?.profileSetup?.registrationDocuments?.establishmentProof || {})
	const groupUser = useSelector((state) => state?.GroupUser)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const { groupUserName, tentUserId } = groupUser
	// const [identityFileResponse, setIdentityFileResponse] = useState(personalProof ? personalProof : [])
	const [identityFileResponse, setIdentityFileResponse] = useState([])
	// const [establishmentFileResponse, setEstablishmentFileResponse] = useState(establishmentProof ? establishmentProof : [])
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState([])
	const [uploaduuid, setUploadUuid] = useState('')
	// const profileSetup = useSelector((state) => state?.profileSetup?.registrationDocuments || {})
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || '')
	// const { list } = profileSetup
	const [list, setList] = useState([])
	const [uploadone, setUploadUuidOne] = useState('')
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const errorMessages = useSelector((state) => state?.errorMessages || {})
	const [loader, setLoader] = useState(false)

	const onSubmit = (e) => {
		submitRegisterDetails(e)
	}
	const formik = useFormik({
		initialValues: {
			registrationDocs: !_.isEmpty(list)
				? list
				: [
						{
							councilRegistrationNumber: '',
							councilName: null,
							registrationYear: null,
						},
				  ],
		},
		enableReinitialize: true,
		validationSchema: yup.object().shape({
			registrationDocs: yup
				.array()
				.min(1)
				.of(
					yup.object().shape({
						councilRegistrationNumber: yup
							.string('Value must be a number')
							.nullable()
							.max(50, 'Please enter valid registration number')
							.required('Council registration number is required')
							.matches(/^[aA-zZ0-9\s ]+$/, 'Only characters and numbers are allowed'),
						councilName: yup
							.string()
							.nullable()
							.max(50, '“Please enter valid council name')
							// .matches(/^[aA-zZ0-9\s]+$/, 'Only characters and numbers are allowed')
							.required('Council Name is required'),
						registrationYear: yup.string().nullable().required('Year is required'),
					})
				)
				.required('Required'),
		}),
		onSubmit: onSubmit,
	})
	const [yearParams, setYearParams] = useState({
		limit: 10,
		offset: 1,
		search: '',
	})

	const onYearSearch = _.debounce(
		(e, value) => {
			setYearParams({
				...yearParams,
				search: value,
			})
		},

		[300]
	)

	const getCompletionYear = useCallback(() => {
		setLoader(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setLoader(false)
				setYearOptions(res.data.data)
			}
		}
		const onFailure = (err) => {
			setLoader(false)
			setYearOptions([])
		}
		LookupApi.getLookup('getLookup', { ...yearParams, mastLookupType: 'YER' }).then(onSuccess, onFailure)
	}, [yearParams])

	useEffect(() => {
		getCompletionYear()
	}, [getCompletionYear])
	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				setFileCategoryList(res?.data?.data)
			} else {
				setFileCategoryList([])
			}
		}
		const onFailure = (err) => {
			setFileCategoryList([])
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		fileUploadDetailsApi.getFileUploadDetails(isTent).then(onSuccess, onFailure)
	}, [groupUserName, tentUserId, uuid])

	useEffect(() => {
		// if (isEditMode || isViewMode) {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				let docUuid = _.map(res?.data?.data?.supportingDocUuid, (item, i) => item?.docUuidList)
				let isArrValidate = Array.isArray(docUuid[0]) ? docUuid.flat(1) : docUuid
				// let profileDocMapUuid = _.map(res?.data?.data?.supportingDocUuid, (item, i) => item?.profileDocMapUuidList)
				// let merged = docUuid
				// console.log('merges', merged)
				setIdentityFileResponse(isArrValidate[1])
				setEstablishmentFileResponse(isArrValidate[0])
				setEmptyCheck(res?.data?.data?.registrationList)
				if (_.isEmpty(res?.data?.data?.registrationList)) {
					formik?.setFieldValue('registrationDocs', [
						{
							councilRegistrationNumber: '',
							councilName: null,
							registrationYear: null,
						},
					])
				} else {
					let tempArr = []
					_.map(res?.data?.data?.registrationList || [], (item) => {
						tempArr.push({
							councilRegistrationNumber: item?.councilRegistrationNumber || '',
							councilName: item?.councilName || '',
							registrationYear: item?.registrationYear,
							profileRegistrationUuid: item?.profileRegistrationUuid,
							supportingDocUuid: [
								{
									docUuidList: _.map(res?.data?.data?.supportingDocUuid, (item, i) => item?.docUuidList).flat(2),
									profileDocMapUuidList: _.map(res?.data?.data?.supportingDocUuid, (item, i) => item?.profileDocMapUuidList).flat(2),
								},
							],
						})
					})
					formik?.setFieldValue('registrationDocs', tempArr)
				}
			} else {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		// let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : uuid
		RegisterDetails.RegisterDetails(profileHeaderUuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, uuid, mastuuid, groupUserName, profileHeaderUuid])
	// }, [dispatch, isEditMode, isViewMode, routeUuid])

	useEffect(() => {
		setList(formik?.values?.registrationDocs || [])
	}, [formik?.values?.registrationDocs])

	const deleteEducationDetails = useCallback(
		(uuid, index, arrayHelpers) => {
			dispatch(showLoader('Deleting please wait...'))
			const onSuccess = (res) => {
				if (res?.data?.status === 'success') {
					dispatch(hideLoader())
					arrayHelpers.remove(index)
				}
			}
			const onFailure = () => {
				dispatch(hideLoader())
			}
			let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
			RegisterDetails.deleteEducation(isTent).then(onSuccess, onFailure)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	const displayEstablishmentImage = (acceptedFiles) => {
		dispatch(showLoader('Uploading document. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setEstablishmentFileResponse(res?.data?.data)
				dispatch(setRegistrationEstablishmentProof(res?.data?.data?.uuid))
				setUploadUuid(res?.data?.data?.uuid)
			} else {
				setEstablishmentFileResponse()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'CERTIFICATES')
		let categoryUuid = identityCategoryId?.uuid
		var formData2 = new FormData()
		formData2.append('FILE', acceptedFiles[0])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		sendUploadFileApi.sendUploadFile(mastuuid, isTent, categoryUuid, formData2).then(onSuccess, onFailure)
	}

	const displayIdentityImage = (acceptedFiles) => {
		dispatch(showLoader('uploading image. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setIdentityFileResponse(res?.data?.data)
				dispatch(setRegistrationPersonalProof(res?.data?.data?.uuid))
				setUploadUuidOne(res?.data?.data?.uuid)
			} else {
				setIdentityFileResponse()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'CERTIFICATES')
		let categoryUuid = identityCategoryId?.uuid
		var formData1 = new FormData()
		formData1.append('FILE', acceptedFiles[0])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		sendUploadFileApi.sendUploadFile(mastuuid, isTent, categoryUuid, formData1).then(onSuccess, onFailure)
	}

	const submitRegisterDetails = (e) => {
		// dispatch(showLoader('Loading please wait...'))
		let body = []
		let uploadedMedia = [uploaduuid, uploadone]
		let isDocCheck = !_.isEmpty(_.compact(uploadedMedia)) ? _.compact(uploadedMedia) : []
		// eslint-disable-next-line no-lone-blocks
		{
			_.isEmpty(emptyCheck)
				? _.map(e?.registrationDocs, (registrationDoc) => {
						const filteredUploadedMedia = _.filter(uploadedMedia, (uuid) => !_.isEmpty(uuid))
						body.push({
							councilRegistrationNumber: registrationDoc?.councilRegistrationNumber,
							councilName: registrationDoc?.councilName,
							registrationYear: parseInt(registrationDoc?.registrationYear),
							progressStatus: !_.isEmpty(progressStatus) ? progressStatus : 'IPG',
							supportingDocUuid: [
								{
									docUuidList: filteredUploadedMedia.length > 0 ? filteredUploadedMedia : [],
									profileDocMapUuidList: [],
								},
							],
						})
				  })
				: _.map(e?.registrationDocs, (registrationDoc) => {
						let merged = _.concat(
							_.map(registrationDoc?.supportingDocUuid, (item, i) => item?.docUuidList),
							isDocCheck
						)
						body.push({
							councilRegistrationNumber: registrationDoc?.councilRegistrationNumber,
							councilName: registrationDoc?.councilName,
							registrationYear: parseInt(registrationDoc?.registrationYear),
							progressStatus: !_.isEmpty(progressStatus) ? progressStatus : 'IPG',
							supportingDocUuid: [
								{
									docUuidList: isDocCheck
										? merged.flat(1)
										: _.map(registrationDoc?.supportingDocUuid, (item, i) => item?.docUuidList).flat(1) || uploadedMedia,
									profileDocMapUuidList: _.map(registrationDoc?.supportingDocUuid, (item, i) => item?.profileDocMapUuidList).flat(1) || [],
								},
							],
							profileRegistrationUuid: registrationDoc?.profileRegistrationUuid,
							isAmend: false,
						})
				  })
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			} else if (res?.data?.status === 'failure') {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		!_.isEmpty(emptyCheck)
			? RegisterDetails.updateRegistrationDetails(body, isTent, userType).then(onSuccess, onFailure)
			: RegisterDetails.PostRegisterDetails(body, isTent, userType).then(onSuccess, onFailure)
	}
	useEffect(() => {
		dispatch(hideLoader())
	}, [dispatch])

	return (
		<>
			{/* <div className={classes.mainContain}>
				<Typography>Allied healthcare professional do not need to enter registration details.</Typography>
			</div> */}
			<FormikProvider value={formik}>
				<FieldArray
					name='registrationDocs'
					render={(arrayHelpers) => (
						<div>
							{formik?.values?.registrationDocs.length > 0 &&
								_.map(formik?.values?.registrationDocs, (data, index) => {
									return (
										<Grid container spacing={2} mb={2} key={index} className={classes.mainFeild}>
											<Grid item xs={12} sm={6}>
												<Typography sx={{ fontWeight: 600, fontSize: 14 }}>Council Registration Number*</Typography>
												<TextField
													fullWidth
													type='text'
													color='secondary'
													size='small'
													name={`registrationDocs[${index}].councilRegistrationNumber`}
													id={`registrationDocs[${index}].councilRegistrationNumber`}
													value={formik?.values?.registrationDocs[index].councilRegistrationNumber}
													onChange={(e) => formik?.handleChange(e)}
													InputProps={{ className: classes.input }}
												/>
												<ErrorMessage component={FormHelperText} error name={`registrationDocs.${index}.councilRegistrationNumber`} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<Typography sx={{ fontWeight: 600, fontSize: 14 }}>Council Name*</Typography>
												<TextField
													fullWidth
													placeholder='Council Name*'
													type='text'
													color='secondary'
													size='small'
													name={`registrationDocs[${index}].councilName`}
													id={`registrationDocs[${index}].councilName`}
													value={formik?.values?.registrationDocs[index].councilName}
													onChange={(e) => formik?.handleChange(e)}
													InputProps={{ className: classes.input }}
												/>
												<ErrorMessage component={FormHelperText} error name={`registrationDocs.${index}.councilName`} />
											</Grid>
											<Grid item xs={12} sm={6}>
												<Typography sx={{ fontWeight: 600, fontSize: 14 }}>Year*</Typography>
												<AdvancedAutocomplete
													name={`registrationDocs[${index}].registrationYear`}
													id={`registrationDocs[${index}].registrationYear`}
													loading={loader}
													options={yearOptions}
													value={formik?.values?.registrationDocs[index].registrationYear}
													getOptionLabel={(option) => option?.mastLookupValue || ''}
													getOptionSelected={(option, value) => option?.mastLookupValue === value?.mastLookupValue}
													onChange={(e, value) => {
														formik?.setFieldValue(`registrationDocs[${index}].registrationYear`, value?.mastLookupValue)
														setYearParams((prevState) => ({ ...prevState, search: value || '' }))
													}}
													onInputChange={onYearSearch}
													placeholder='year'
												/>
												<ErrorMessage component={FormHelperText} error name={`registrationDocs.${index}.registrationYear`} />
											</Grid>
											{/* <div className={classes.cancelExperience}>
												{formik?.values?.registrationDocs.length > 0 && !isSmall ? (
													<IconButton
														onClick={() => {
															isEditMode && formik?.values?.registrationDocs[index]?.profileRegistrationUuid
																? deleteEducationDetails(formik?.values?.registrationDocs[index]?.profileRegistrationUuid, index, arrayHelpers)
																: arrayHelpers.remove(index)
														}}
													>
														<HighlightOffIcon />
													</IconButton>
												) : (
													<Button
														className={classes.cancelBtn}
														onClick={() => {
															isEditMode && formik?.values?.registrationDocs[index]?.profileRegistrationUuid
																? deleteEducationDetails(formik?.values?.registrationDocs[index]?.profileRegistrationUuid, index, arrayHelpers)
																: arrayHelpers.remove(index)
														}}
													>
														Remove
													</Button>
												)}
											</div> */}
										</Grid>
									)
								})}
							{/* <div className={classes.addExperience}>
								<Button
									className={classes.addExperiencebtn}
									disabled={formik?.values?.registrationDocs.length > 4 ? true : false}
									onClick={() => {
										arrayHelpers.push({
											councilRegistrationNumber: '',
											councilName: null,
											registrationYear: null,
										})
										setYearParams({
											limit: 10,
											offset: 1,
											search: '',
										})
									}}
								>
									+ Add Registration
								</Button>
							</div> */}
						</div>
					)}
				/>
			</FormikProvider>

			<div className={classes.allContain}>
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>Documents to Upload:</Typography>
				{/* <div className={classes.mainContain}>
					<Typography>
						Add photo proof for Registration certificate Er identity card to publish your profile. We require photo proofs to ensure that only you get
						access to your profile and nobody else.{' '}
						<Tooltip
							arrow='true'
							title='Please upload any one of the following valid personal proofs which are Aadhaar card, Driving license, Voter ID in PDF format below
								5MB.'
							placement='right-end'
						>
							<Link style={{ textDecoration: 'none', cursor: 'pointer' }}>
								<span style={{ color: 'red' }}> Know more</span>
							</Link>
						</Tooltip>
					</Typography>
				</div> */}
			</div>
			<div className={classes.footListItem}>
				<div>
					<div>
						<CompDropZone
							imgResponse={identityFileResponse}
							setImage={setIdentityFileResponse}
							displayUploadImage={displayIdentityImage}
							formik={formik}
							isType={'profile'}
							setUploadUuid={setUploadUuidOne}
						/>
						<Typography style={{ paddingTop: 12, fontWeight: 600 }}>Personal Proof</Typography>
						<div className={classes.listItemCont}>
							<div className={classes.profList}>
								<KeyboardArrowRightIcon />
								<Typography>Aadhar card</Typography>
							</div>
							<div className={classes.profList}>
								<KeyboardArrowRightIcon />
								<Typography>Driving license</Typography>
							</div>
							<div className={classes.profList}>
								<KeyboardArrowRightIcon />
								<Typography>Voter ID</Typography>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div>
						<CompDropZone
							imgResponse={establishmentFileResponse}
							setImage={setEstablishmentFileResponse}
							setUploadUuid={setUploadUuid}
							displayUploadImage={displayEstablishmentImage}
							formik={formik}
							isType={'profile'}
						/>

						<Typography style={{ paddingTop: 12, fontWeight: 600 }}>Establishment proof</Typography>
						{groupName === 'health care' || groupId === 'fyi6pmtm' ? (
							<div className={classes.listItemCont}>
								<Typography>Acceptable proofs</Typography>
								<div className={classes.profList}>
									<KeyboardArrowRightIcon />
									<Typography>Clinical registration proof</Typography>
								</div>
								<div className={classes.profList}>
									<KeyboardArrowRightIcon />
									<Typography>Document for waste disposal</Typography>
								</div>
								<div className={classes.profList}>
									<KeyboardArrowRightIcon />
									<Typography>Tax receipt</Typography>
								</div>
							</div>
						) : (
							<div className={classes.listItemCont}>
								<Typography>Acceptable proofs</Typography>
								<div className={classes.profList}>
									<KeyboardArrowRightIcon />
									<Typography>Clinical registration proof</Typography>
								</div>
								{/* <div className={classes.profList}>
							<KeyboardArrowRightIcon />
							<Typography>Document for waste disposal</Typography>
						</div> */}
								<div className={classes.profList}>
									<KeyboardArrowRightIcon />
									<Typography>Tax receipt</Typography>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{!isViewMode && (
				<div class={classes.buttonMain}>
					<ButtonComp style={{ marginRight: 16 }} onClick={() => moveBackTab()}>
						Back
					</ButtonComp>
					<ButtonComp
						onClick={(e) => {
							formik.handleSubmit(e)
						}}
					>
						Save & Next
					</ButtonComp>
				</div>
			)}
		</>
	)
}
export default RegisterDocumention
