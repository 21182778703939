import useStyles from './style'
import { React, useState, useEffect } from 'react'
import { Grid, Typography, Avatar } from '@mui/material'
import Button from '../../../sharedComponents/Button'
import RegisterDetails from '../../../../services/ProfileSetup/RegisterDetail/Getapi'
import { useDispatch, useSelector } from 'react-redux'
import { getImgUrl } from 'utils'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'

function RegistrationViewEdit({ history, match }) {
	const classes = useStyles()
	const [registerDetails, setRegisterDetails] = useState([])
	const state = useSelector((state) => state.Authentication)
	const { BearerToken } = state
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus?.progressStatus || '')
	const uuid = _.get(match, 'params.uuid', '')
	const mastUuid = _.get(match, 'params.mastuuid', '')
	const dispatch = useDispatch()

	useEffect(() => {
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				setRegisterDetails(res?.data?.data)
			} else {
				setRegisterDetails([])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader)
			setRegisterDetails([])
		}
		if (!_.isEmpty(uuid)) {
			dispatch(showLoader('Loading please wait...'))
			RegisterDetails.RegisterDetails(uuid).then(onSuccess, onFailure)
		}
	}, [dispatch, uuid])

	const onEditClick = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastUuid}`)
	}

	return (
		<div className={classes.RegistrationViewEditMain}>
			<Grid container className={classes.registerViewEdit}>
				<Grid item lg={11} sm={12}>
					{registerDetails?.registrationList?.map((data) => (
						<Grid container className={classes.editPartMain} alignItems='center' key={data?.id}>
							<Grid item lg={3} sm={12}>
								<Typography variant='h2' className={classes.coinsilRegistartion}>
									Council Registration Number
								</Typography>
							</Grid>
							<Grid item lg={3} sm={12}>
								<Typography variant='h3' className={classes.coinsilRegistartionNumber}>
									{data?.councilRegistrationNumber}
								</Typography>
							</Grid>
							<Grid container className={classes.editPartMain} alignItems='center'>
								<Grid item lg={3} sm={12}>
									<Typography variant='h2' className={classes.coinsilRegistartion}>
										Council Name
									</Typography>
								</Grid>
								<Grid item lg={3} sm={12}>
									<Typography variant='h3' className={classes.coinsilRegistartionNumber}>
										{data?.councilName}
									</Typography>
								</Grid>
								<Grid container className={classes.editPartMain} alignItems='center'>
									<Grid item lg={3} sm={12}>
										<Typography variant='h2' className={classes.coinsilRegistartion}>
											Council Year
										</Typography>
									</Grid>
									<Grid item lg={3} sm={12}>
										<Typography variant='h3' className={classes.coinsilRegistartionNumber}>
											{data?.registrationYear}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
				{progressStatus === 'SUB' ? null : (
					<Grid item lg={1} sm={12} className={classes.editButton}>
						<Button
							onClick={() => {
								onEditClick()
							}}
						>
							Edit Profile
						</Button>
					</Grid>
				)}
				<div>
					<div className={classes.documentsList}>
						<Typography variant='h6' className={classes.documentText}>
							Documents:
						</Typography>
					</div>
					<div className={classes.viewDocumentPart}>
						{registerDetails?.supportingDocUuid?.map((item) => {
							return <Avatar variant='square' src={getImgUrl(item, BearerToken)} className={classes.uploadedImages} />
						})}
					</div>
				</div>
			</Grid>
		</div>
	)
}
export default RegistrationViewEdit
