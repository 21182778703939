import { ACTION_TYPES } from '../../../constants'

export const Register = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_REGISTER,
		payload: { ...payload },
	}
}

export const Login = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_LOGIN,
		payload: { ...payload },
	}
}
export const SetPinStatus = (payload) => {
	return {
		type: ACTION_TYPES.SET_PIN_STATUS,
		payload: { ...payload },
	}
}
export const PhoneNumberLength = (payload) => {
	return {
		type: ACTION_TYPES.MOBILE_NUMBER_LENGTH,
		payload: { ...payload },
	}
}

export const UpdateMail = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_MAIL,
		payload: { ...payload },
	}
}

export const ResetPassword = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_RESETPASSWORD,
		payload: { ...payload },
	}
}
export const estSelection = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_ESTSELECTION,
		payload: { ...payload },
	}
}

export const ForgotPassword = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_FORGOTPASSWORD,
		payload: { ...payload },
	}
}

export const Otp = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_OTP,
		payload: { ...payload },
	}
}

export const updateDeviceId = (id) => ({
	type: ACTION_TYPES.UPDATE_DEVICE_ID,
	id,
})

export const updatePracticeDetails = (data) => ({
	type: ACTION_TYPES.UPDATE_PRACTICEDETAILS,
	payload: data,
})

export const updateIswelcome = (isWelcome) => ({
	type: ACTION_TYPES.UPDATE_ISWELCOME,
	isWelcome,
})
export const updateNewUser = (payload) => ({
	type: ACTION_TYPES.UPDATE_NEW_USER,
	payload,
})

export const GroupSelection = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_GROUPSELECTION,
		payload: { ...payload },
	}
}
export const onEmailSuccess = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_EMAIL_SUCCESS,
		payload,
	}
}

export const Logout = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_LOGOUT,
		payload: { ...payload },
	}
}

export const Calendar = (payload) => {
	return {
		type: ACTION_TYPES.AUTH_CALENDAR,
		payload: { ...payload },
	}
}

export const currentUser = (payload) => {
	return {
		type: ACTION_TYPES.CURRENT_USER,
		payload: { ...payload },
	}
}
export const tentTypeId = (payload) => {
	return {
		type: ACTION_TYPES.TENANT_TYPE,
		payload: { ...payload },
	}
}
export const tentName = (payload) => {
	return {
		type: ACTION_TYPES.TENANT_NAME,
		payload: { ...payload },
	}
}
export const updateMastUuid = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_MASTER_UUID,
		payload,
	}
}
export const updateTentUserUuid = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_TENTUSER_UUID,
		payload,
	}
}
export const updateOwner = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_OWNER_STATUS,
		payload,
	}
}
export const getplanReminderUuid = (payload) => {
	return {
		type: ACTION_TYPES.PLAN_REMINER_UUID_GET,
		payload,
	}
}
export const setUserName = (payload) => {
	return {
		type: ACTION_TYPES.USER_MENU,
		payload,
	}
}
export const updateEmail = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_EMAIL,
		payload,
	}
}
export const updateMobileNumber = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_MOBILE_NUMBER,
		payload,
	}
}
export const updateBearerToken = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_BEARER,
		payload,
	}
}
export const updateProfilePhoto = (payload) => {
	return {
		type: ACTION_TYPES.UPDATE_PROFILEPIC,
		payload,
	}
}
