import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import _ from 'lodash'
import PageNotAllowed from 'app/main/PageNotAllowed'
import { combineMenus } from 'utils'
import ExpirePlan from 'app/sharedComponents/ExpirePlan'
import { ROLE_MODULES } from '../../../constants'
import { setCurrentModule } from 'services/DynamicMenu/actions'

const PrivateRoutes = ({ component: Component, ...rest }) => {
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state) => state?.Authentication?.isAuthenticated || false)
	const daysLeft = useSelector((state) => state?.dynamicMenu?.userData?.daysLeft || 0)
	const menuList = useSelector((state) => state?.dynamicMenu?.list || [])
	const { moduleName } = rest

	const checkPermAndRenderScreen = (props) => {
		const wholeList = combineMenus(menuList)
		// const currentModule = _.find(wholeList, { mastMenuName: moduleName })
		// const isAccessGranted = _.get(currentModule, 'display', false)
		const currentModule = _.find(wholeList, { mastMenuUrl: moduleName })
		const isAccessGranted = _.get(currentModule, 'display', false)
		dispatch(setCurrentModule(currentModule))

		return isAccessGranted ? (
			daysLeft > 0 ? (
				<Component {...{ ...props, moduleName }} />
			) : _.isEqual(moduleName, ROLE_MODULES.SUBSCRIPTION) ? (
				<Component {...{ ...props, moduleName }} />
			) : (
				<ExpirePlan />
			)
		) : (
			<PageNotAllowed isGoBack={true} />
		)
	}

	const renderScreen = (props) => {
		if (isAuthenticated) return checkPermAndRenderScreen(props)
		else return <Redirect to='/' />
	}
	return <Route {...rest} render={renderScreen} />
}

export default PrivateRoutes
