import { ACTION_TYPES } from '../../../constants/index'
import secureLocalStorage from 'react-secure-storage'
import _ from 'lodash'

const msg = secureLocalStorage.getItem('errmsg')
const initialValues = JSON.parse(JSON.stringify(msg))

const getErrorMessages = (state = initialValues, action) => {
	switch (action.type) {
		case ACTION_TYPES.ERROR_MESSAGES_START: {
			secureLocalStorage.setItem('errmsg', JSON.stringify({}))
			return {
				...state,
				errmsg: {},
			}
		}
		case ACTION_TYPES.ERROR_MESSAGES_SUCCESS: {
			secureLocalStorage.setItem('errmsg', _.isEmpty(action.payload) ? {} : action.payload)
			return action.payload
		}

		default:
			return state
	}
}

export default getErrorMessages
