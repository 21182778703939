import { createContext, useState } from 'react'

const SidebarContext = createContext()

export function SidebarProvider({ children }) {
	const [state, setState] = useState('')
	return <SidebarContext.Provider value={{ state, setState }}>{children}</SidebarContext.Provider>
}

export default SidebarContext
