import { authRoles } from 'app/auth'
import Terms from '.'

const TermsandConditionsConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/terms',
			exact: true,
			component: Terms,
		},
	],
}

export default TermsandConditionsConfig
