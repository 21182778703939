import { ACTION_TYPES } from '../../../constants/index'
import secureLocalStorage from 'react-secure-storage'

const initialValues = {
	countryLookupData: secureLocalStorage.getItem('country') || [],
}

const getErrorMessages = (state = initialValues, action) => {
	switch (action.type) {
		case ACTION_TYPES.COUNTRY_LOOKUP_DATA: {
			return {
				...state,
				countryLookupData: action.payload,
			}
		}
		default:
			return state
	}
}

export default getErrorMessages
