import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getActiveList: (identityUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_PRACTISE}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { identityUuid } },
		})
	},

	getSpecialityList: (params) => {
		return axios.get(API_ENDPOINTS.SPA_SPECIALITY_DROP, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	getPriceList: (params) => {
		return axios.get(API_ENDPOINTS.APPOIMTMENT_PRICE_AUTO_FIELD, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
	postTimeAccessed: (tentUserUuid) => {
		const body = {}
		return axios.post(`${API_ENDPOINTS.GET_TIME_ACCESSED}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { tentUserUuid } },
		})
	},
}
