import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants/index'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getEdudetails: (userType, uuid) => {
		return axios.get(API_ENDPOINTS.EDU_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	setEducation: (data, userType, uuid) => {
		return axios.post(`${API_ENDPOINTS.EDUCATION_DETAILS}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { userType, uuid } },
		})
	},
	updateEducationDetails: (data, userType, uuid) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_EDUCATION}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	deleteEducation: (profileEducationUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_EDUCATION}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, 'X-SECURITY': csrf(), path: { profileEducationUuid } },
		})
	},
}
