import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import PatientVisitChart from './PatientVisitChart'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: 'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
		marginBlockEnd: 14,
		padding: 14,
		borderRadius: 10,
		width: '100%',
	},

	totalprice: {
		display: 'flex',
		marginBlockStart: 6,
		'& .MuiTypography-subtitle1': {
			maxWidth: 'fit-content',
			background: '#f0eeff',
			paddingBlock: 6,
			paddingInline: 14,
			borderRadius: 8,
			'& span': {
				fontWeight: 600,
			},
		},
	},
}))
function PatientVisitTimeLine({ getDateRange, dashboardData, isCare }) {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography variant='h6'> {isCare ? 'Patient' : 'Client'} Visit Timeline</Typography>
			<div className={classes.totalprice}>
				<Typography variant='subtitle1'>
					Total:<span> {dashboardData?.cutomerVistGraph?.TotalCount || ''}</span>
				</Typography>
			</div>
			<PatientVisitChart dashboardData={dashboardData} isCare={isCare} />
		</div>
	)
}

export default PatientVisitTimeLine
