import FuseLayout from '@fuse/core/FuseLayout'
import FuseTheme from '@fuse/core/FuseTheme'
import history from '@history'
import { LicenseInfo } from '@mui/x-license-pro'
import { Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import withAppProviders from './withAppProviders'
import Loader from './sharedComponents/Loader'
import { useEffect, useState } from 'react'
import { getErrData, setErrorData } from 'services/error/action'
import loginApi from '../services/login/api'
import { SidebarProvider } from '../lib/Utils/Context/sidebarContext'
import { ACTION_TYPES } from '../constants'
import _ from 'lodash'
// import secureLocalStorage from 'react-secure-storage'
import { hideLoader, showLoader } from 'services/loader/action'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import CacheBuster from 'react-cache-buster'
import { version } from '../../package.json'

import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// GTM:
import TagManager from 'react-gtm-module'
import secureLocalStorage from 'react-secure-storage'
import { GoogleMapsProvider } from './sharedComponents/GoogleMapsProvider'

const App = () => {
	const loader = useSelector((state) => state.loader)
	const dispatch = useDispatch()
	const [isInFrame, setIsInFrame] = useState(false)

	useEffect(() => {
		if (window.top !== window.self) {
			setIsInFrame(true)
		}
	}, [])

	useEffect(() => {
		dispatch(showLoader('Loading please wait ...'))
		dispatch(getErrData())
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(setErrorData(res?.data))
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(setErrorData({}))
		}
		loginApi.setErrorResponse().then(onSuccess, onFailure)
	}, [dispatch])

	useEffect(() => {
		dispatch({
			type: ACTION_TYPES.GET_GEO_LOCATIONS_STARTED,
		})
		fetch('https://ipapi.co/json/')
			.then(function (response) {
				response.json().then((res) => {
					secureLocalStorage.setItem('geoInfo', _.isEmpty(res) ? {} : res)
					secureLocalStorage.setItem('geoCountryCode', res?.country_calling_code || '')
					dispatch({
						type: ACTION_TYPES.GET_GEO_LOCATIONS_SUCCESS,
						payload: res,
					})
				})
			})
			.catch(function (error) {
				dispatch({
					type: ACTION_TYPES.GET_GEO_LOCATIONS_FAILED,
				})
			})
	}, [dispatch])
	// if ('serviceWorker' in navigator) {
	// 	navigator.serviceWorker
	// 		.register('./firebase-messaging-sw.js')
	// 		.then(function (registration) {
	// 			console.log('Registration successful, scope is:', registration.scope)
	// 		})
	// 		.catch(function (err) {
	// 			console.log('Service worker registration failed, error:', err)
	// 		})
	// }
	const styles = {
		snackbar: {
			// background: 'black',
			// color: 'white',
			width: '440px',
			height: '100%',
			flexDirection: 'row',
			justifyContent: 'center',
			alignContent: 'center',
			margin: '0 auto',
		},
	}

	useEffect(() => {
		if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
			TagManager.initialize({ gtmId: 'GTM-W6MZMBW' })
		}
		// else if (process.env.REACT_APP_NODE_ENV === 'SIT') {
		// 	TagManager.initialize({ gtmId: 'GTM-W6MZMBW' })
		// }
	}, [])
	LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENESE_KEY)
	return (
		<CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={false} loadingComponent={<Loader {...loader} />} metaFileDirectory={'.'}>
			<GoogleMapsProvider>
				<SidebarProvider>
					<Router history={history}>
						<FuseTheme>
							<ToastContainer
								position='bottom-right'
								autoClose={1500}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme='colored'
								transition={Slide}
							/>
							<SnackbarProvider
								autoHideDuration={8000}
								maxSnack={5}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								style={styles?.snackbar}
								iconVariant={{
									default: <CircleNotificationsIcon style={{ color: 'grey', paddingRight: 8, fontSize: 42 }} />,
								}}
							>
								{isInFrame ? <p>Security Warning: This content cannot be displayed within a frame for security reasons.</p> : <FuseLayout />}
							</SnackbarProvider>
						</FuseTheme>
					</Router>
				</SidebarProvider>
				<Loader {...loader} />
			</GoogleMapsProvider>
		</CacheBuster>
	)
}

export default withAppProviders(App)()
