import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	establishmentDetailsMain: {
		paddingInline: 24,
		paddingBlock: 0,
		[theme.breakpoints.down('sm')]: {
			paddingInline: 0,
		},
		[theme.breakpoints.up('sm')]: {
			paddingInline: 40,
		},
	},
	star: {
		color: 'red',
	},
	mainImg: {
		width: 150,
		height: 140,
		borderRadius: 12,
		marginBlock: 12,
	},
	deletIcon: {
		position: 'absolute',
		right: '5px',
		top: 15,
		color: 'red',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
		zIndex: 999,
	},
	imgContainer: {
		position: 'relative',
	},
	userMessage: {
		background: '#FFF0E0 0% 0% no-repeat padding-box',
		display: 'flex',
		alignItems: 'flex-start',
		gap: 8,
		paddingBlock: 8,
		paddingInline: 8,
		borderRadius: 4,
		[theme.breakpoints.down('sm')]: {
			marginBlockStart: 12,
			marginInlineStart: 18,
		},
	},
	mapPart: {
		display: 'flex',
		gap: 36,
		paddingBlockStart: 28,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			gap: 26,
			alignItems: 'flex-start',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'column',
			gap: 26,
			alignItems: 'flex-start',
		},
		[theme.breakpoints.up('lg')]: {
			flexDirection: 'row',
		},
	},
	mapPartOne: {
		display: 'flex',
		gap: 4,
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			width: '100%',
		},
	},
	mapPartRight: {
		display: 'flex',
		gap: 4,
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {},
	},
	locationText: {
		display: 'inline-block',
		lineHeight: '1.25rem',
		color: '#636669',
		'& .MuiSvgIcon-root': {
			color: '#e02e23',
		},
		'&:hover': {
			color: '#034efc',
			cursor: 'pointer',
			fontWeight: 'bold',
		},
	},
	section2FullWidth: {
		marginTop: theme.spacing(1),
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			minHeight: 100,
		},
	},
	location: {
		width: '100%',
		height: '200px',
	},
	uploadDots: {
		display: 'flex',
		gap: 32,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 8,
		},
	},
	uploadFile: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		cursor: 'pointer',
		justifyContent: 'center',
		alignItems: 'center',
		width: 150,
		height: 150,
		background: '#fff 0% 0% no-repeat padding-box',
		boxShadow: '0 4px 8px 0 rgb(0 0 0 / 6%), 0px 1px 20px 0 rgb(255 255 255 /12%)',
		opacity: 1,
		marginBlock: 12,
		borderRadius: 12,
	},
	buttonList: {
		display: 'flex',
		justifyContent: 'center',
		paddingBlock: 12,
	},
	paymentOptions: {
		fontWeight: 600,
		color: '#000',
	},
	dayBtnSelected: {
		background: '#2a2a2a',
		borderRadius: '50%',
		color: '#fff',
		border: '0.5px solid',
		minWidth: 36,
		minHeight: 36,
		'&:hover': {
			background: '#2a2a2a',
			color: '#fff',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 32,
		},
	},
	dayBtn: {
		background: '#fff',
		borderRadius: '50%',
		color: '#000',
		border: '0.5px solid',
		minWidth: 36,
		minHeight: 36,
		'&:hover': {
			background: '#fff',
			color: '#000',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 32,
		},
	},
	slotButtons: {
		display: 'flex',
		gap: 4,
	},
	addServiceAdd: {
		display: 'flex',
		flexDirection: 'column',
		gap: 0,
		'& .MuiChip-root': {
			background: 'antiquewhite',
		},
	},
	addServiceOne: {
		width: '100%',
		position: 'relative',
	},
	addSpeciality: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	ammenitiesSection: {
		display: 'flex',
		gap: 60,
		paddingBlock: 12,
		flexWrap: 'wrap',
		'& .MuiAppBar-root': {
			boxShadow: 'none',
			background: 'none',
		},
		'& .MuiTouchRipple-root': {
			border: '1px solid #999',
			borderRadius: 12,
		},
		'& .MuiTab-root': {
			minHeight: 40,
		},
		'& .MuiTabs-flexContainer': {
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				gap: '8px !important',
			},
		},
		'&:focus': {
			background: '#2a2a2a',
			color: '#fff',
		},
	},
	ammenitiesButton: {
		display: 'flex',
		justifyContent: 'center',
		paddingBlock: 5,
	},
	ammenitiesTextArea: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			flexWrap: 'nowrap',
		},
	},
	sectionAmminities: {
		cursor: 'pointer',
	},
	firstSection: {
		gap: 12,
		flexWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	secondSection: {
		gap: 12,
		flexWrap: 'nowrap',
		marginTop: 0,
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		gap: 16,
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'right',
	},
	payment: {
		display: 'flex',
	},
	variableField: { display: 'none' },
	errorMessage: {
		marginInline: '16px',
		color: '#f94c61',
	},
	aboutTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))
export default useStyles
