import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 5,
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: 5,
		marginRight: 8,
		width: 50,
		height: 50,
		padding: 4,
		boxSizing: 'border-box',
	},
	thumbInne: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
	},
	container: {
		position: 'relative',
		justifyContent: 'center',
	},
	dropzone: {
		display: 'flex',
		cursor: 'pointer',
		gap: 2,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		height: 100,
		opacity: 1,
		marginBlock: 12,
		borderRadius: 12,
		'& .MuiButton-root': {
			color: '#636669',
			border: '1px solid #636669',
		},
	},
	icon: {
		color: '#767676',
		marginTop: theme.spacing(1),
		fontSize: 38,
	},
	dragText: {
		color: '#767676',
		marginTop: theme.spacing(1),
	},

	deletIcon: {
		position: 'absolute',
		right: 10,
		top: 15,
		color: 'red',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
		zIndex: 999,
	},
	mainImgUpload: {
		display: 'flex',
		gap: 6,
	},
	multiImgs: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		gap: 10,
	},
	userLogo: {
		width: 100,
		height: 100,
		borderRadius: 56,
		// '& .MuiAvatar-img': {
		// 	'&:hover': {
		// 		background: 'red',
		// 	},
		// },
	},
	userAvatar: {
		position: 'relative',
		'& .MuiSvgIcon-root': {
			position: 'absolute',
			bottom: 11,
			left: 64,
			border: '1px solid',
			borderRadius: 12,
			padding: 2,
			color: 'white',
			background: 'blue',
			fontSize: 26,
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: 100,
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: 100,
		},
	},
	imgContainer: {
		position: 'relative',
	},
	mainContainer: {
		position: 'absolute',
		transform: 'translate(40px, 75px)',
		'& .MuiSvgIcon-root': {
			color: 'blue',
			cursor: 'pointer',
			background: '#fff',
			fontSize: 28,
			borderRadius: '50%',
		},
		'& .MuiListItemText-root:hover': {
			background: '#E4F0FF',
			color: '#0062DD',
		},
	},
	helperTextContainer: {
		marginTop: 8,
		'& .MuiTypography-body1 ': {
			fontSize: 11,
			color: '#808080',
			whiteSpace: 'pre',
		},
	},
}))

export default useStyles
