import moment from 'moment'

const initialState = {
	appointmentModes: [],
	specialistList: [],
	specialityList: [],
	facilityList: [],
	procedureList: [],
	timeSlotList: [],
	membershipDropdown: [],
	serial: false,
	chipDays: [],
	serialErr: '',
	pickerValue: [new Date(moment().format('YYYY-MM-DD')), new Date(moment().format('YYYY-MM-DD'))],
	pickerDaysSet: [
		{ id: 0, label: 'Sun', isSelected: false },
		{ id: 1, label: 'Mon', isSelected: false },
		{ id: 2, label: 'Tue', isSelected: false },
		{ id: 3, label: 'Wed', isSelected: false },
		{ id: 4, label: 'Thu', isSelected: false },
		{ id: 5, label: 'Fri', isSelected: false },
		{ id: 6, label: 'Sat', isSelected: false },
	],
}

function reducerMethod(state = initialState, action) {
	switch (action.type) {
		case 'setAppointmentModes':
			return {
				...state,
				appointmentModes: action?.data,
			}

		case 'setSpecialistList':
			return {
				...state,
				specialistList: action?.data,
			}

		case 'setProcedureList':
			return {
				...state,
				procedureList: action?.data,
			}

		case 'setSpecialityList':
			return {
				...state,
				specialityList: action?.data,
			}
		case 'setFacilityList':
			return {
				...state,
				facilityList: action?.data,
			}

		case 'setTimeSlotList':
			return {
				...state,
				timeSlotList: action?.data,
			}
		case 'setMembershipDropdown':
			return {
				...state,
				membershipDropdown: action?.data,
			}

		case 'setSerial':
			return {
				...state,
				serial: action?.data,
			}

		case 'setPickerValue':
			return {
				...state,
				pickerValue: action?.data,
			}

		case 'setPickerDaysSet':
			return {
				...state,
				pickerDaysSet: action?.data,
			}

		case 'setChipDays':
			return {
				...state,
				chipDays: action?.data,
			}
		case 'setSerialErr':
			return {
				...state,
				serialErr: action?.data,
			}

		case 'reset':
			return initialState

		default:
			throw new Error()
	}
}

// CLIENT DATA REDUCER
const clientInitialState = {
	clientsList: [],
	clientsLoading: false,
	isNewClient: false,
	clientSearchText: '',
	clientStatus: '',
	clientsDataList: [],
	isShowDropdown: false,
	isClientLoading: false,
}

function clientReducerMethod(state = clientInitialState, action) {
	switch (action.type) {
		case 'setClientsList':
			return {
				...state,
				clientsList: action?.data,
			}

		case 'setClientsLoading':
			return {
				...state,
				clientsLoading: action?.data,
			}
		case 'setIsNewClient':
			return {
				...state,
				isNewClient: action?.data,
			}

		case 'reset':
			return clientInitialState

		//New
		case 'setClientSearchText':
			return {
				...state,
				clientSearchText: action?.data,
			}
		case 'setclientStatus':
			return {
				...state,
				clientStatus: action?.data,
			}
		case 'setClientDataList':
			return {
				...state,
				clientsDataList: action?.data,
			}
		case 'setIsShowDropdown':
			return {
				...state,
				isShowDropdown: action?.data,
			}
		case 'setIsClientLoading':
			return {
				...state,
				isClientLoading: action?.data,
			}

		default:
			throw new Error()
	}
}

export { initialState, reducerMethod, clientInitialState, clientReducerMethod }
