import { ACTION_TYPES } from '../../../constants/index'

const initialValues = {
	consentFormData: {
		data: null,
		isEditMode: false,
		formData: [],
	},
}

const getFormData = (state = initialValues, action) => {
	switch (action.type) {
		case ACTION_TYPES.CONSENT_FORM_DATA: {
			return {
				...state,
				consentFormData: {
					data: action.payload.data,
					isEditMode: action.payload.isEditMode,
					formData: action.payload.formData,
				},
			}
		}
		default:
			return state
	}
}

export default getFormData
