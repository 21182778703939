import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	smsModalMain: {
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	smsModalMainOne: {
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	textpart: {
		[theme.breakpoints.up('xs')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('sm')]: {},
	},
	addRecipient: {
		[theme.breakpoints.up('xs')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('md')]: {
			marginBlock: 0,
		},
	},
	btnActions: {
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'space-between',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'space-between',
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-end',
		},
	},
	smsPlanMain: {
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 948,
			height: '80vh',
		},
	},
	media: {
		paddingTop: 12,
		display: 'flex',
		gap: 12,
	},
	cancelButton: {
		background: '#EFEFEF',
		color: 'black',
		paddingBlock: 4,
		paddingInline: 20,
		'&:hover': {
			background: '#EFEFEF',
			color: 'black',
		},
	},
	messageCount: {
		display: 'flex',
		justifyContent: 'right',
		gap: 6,
		paddingBlock: 4,
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'right',
		},
	},
	typeSelect: {
		marginBlock: 16,
		'& .MuiTextField-root': {
			[theme.breakpoints.up('xs')]: {
				marginBlock: 0,
			},
			[theme.breakpoints.up('sm')]: {
				marginBlock: 16,
			},
		},
	},
	clearAllRec: {
		textAlign: 'right',
		textDecoration: 'underline',
		cursor: 'pointer',
		fontSize: 12,
		color: 'gray',
		marginBlockEnd: 18,
	},
	recp: {
		fontSize: 14,
		color: '#000',
	},
	recipientsArea: {
		marginTop: 4,
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		height: 200,
		overflowY: 'auto',
		padding: 12,
	},
}))
export default useStyles
