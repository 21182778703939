import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		paddingBlock: 16,
		paddingInline: 24,
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
	},
	label: {
		fontSize: 14,
	},
	value: {
		fontSize: 16,
		color: '#2a2a2a',
		fontWeight: 500,
	},
	uploadedImages: {
		width: '120px',
		height: '100px',
		borderRadius: 12,
		[theme.breakpoints.down('sm')]: {
			width: '116px',
		},
	},
}))
export default useStyles
