import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import secureLocalStorage from 'react-secure-storage'
import { csrf } from 'utils'

const CancelToken = axios.CancelToken

const headers = {
	isAuthRequired: true,
	'Content-Type': 'application/json',
	Authorization: `Bearer ${secureLocalStorage.getItem('access_token')}`,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	updateConsultMeeting: (meetingUuid, body) => {
		return axios.put(API_ENDPOINTS.UPDATE_CONSULT_MEETING, body, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { meetingUuid } },
		})
	},
	getFileDownloadUrl: (uuid, token) => {
		return `${API_ENDPOINTS.DOWNLOAD_FILE}/${uuid}?token=${encodeURIComponent(token)}`
	},
	generateTokenOnCustomer: (custUuid) => {
		return axios.get(API_ENDPOINTS.GENERATE_TOKEN_ONCUSTOMER, {
			headers: { ...headers, path: { custUuid } },
		})
	},
	consultChatMessageSave: (body) => {
		return axios.post(API_ENDPOINTS.CONSULT_CHATMESSAGE_SAVE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	getConsultCustId: (custUuid) => {
		return axios.get(API_ENDPOINTS.CONSULT_DETAILS, {
			headers: { ...headers, path: { custUuid } },
		})
	},
	getTenantAppointmentList: (body) => {
		return axios.post(API_ENDPOINTS.LIST_OF_TENANT_APPOINTMENT, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	getTenantAppointmentListSearch: (body, params, cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.post(API_ENDPOINTS.LIST_OF_TENANT_APPOINTMENT, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
			params: { ...params },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	getMessageFromTwoSide: (body) => {
		return axios.post(API_ENDPOINTS.GET_MESSAGE_TWOSIDE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	getChatImageDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.GET_CHAT_IMAGE_DETAILS, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},
	getChatDocumentDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.ONLINECONSULT_READ_TENANT_DOCUMENT, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},
	getChatVideoDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.ONLINECONSULT_READ_TENANT_VIDEO, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},
	getChatLinkDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.ONLINECONSULT_READ_TENTANT_LINK, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},
	clearChatHistoryFromTenantSide: (appointmentUuid) => {
		return axios.delete(API_ENDPOINTS.ONLINECONSULT_CLEARCHAT_HISTORY_TENANT, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { appointmentUuid } },
		})
	},
	//tenantMsgRead

	readTenantMessage: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.ONLINECONSULT_READTENTMSG, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},
	consultMessageSaveText: (body) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_MESSAGE_SAVE_TEXT, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultSeenStatus: (body) => {
		return axios.post(API_ENDPOINTS.ONLINE_CONSULT_SEEN_STATUS, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMessageSaveEmoji: (body) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_MESSAGE_SAVE_EMOJI, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMessageSaveFile: (body) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_MESSAGE_SAVE_FILE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMessageSaveDeclineCall: (body) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_MESSAGE_SAVEDECLINECALL, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	uploadingFileToTenant: (body, tentMastUuid, tentUserUuid, consultUuid) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_UPLOAD_TENANTSIDE, body, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { tentMastUuid, tentUserUuid, consultUuid } },
		})
	},
	consultMeetingInitiate: (body) => {
		return axios.post(API_ENDPOINTS.ONLINECONSULT_MEETING_SAVE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMeetingCallLeave: (body) => {
		return axios.put(API_ENDPOINTS.ONLINECONSULT_MEETING_CALL_LEAVE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMeetingTenantCallAccept: (body) => {
		return axios.put(API_ENDPOINTS.ONLINECONSULT_MEETING_TENANT_CALLACCEPT, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultDeleteSpecificMeeting: (appointmentUuid, meetingUuid) => {
		return axios.delete(`${API_ENDPOINTS.ONLINECONSULT_DELETE_SPECIFICMEETING}?appointmentUuid=${appointmentUuid}&meetingUuid=${meetingUuid}`, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},

	consultMeetingCallDecline: (body) => {
		return axios.put(API_ENDPOINTS.ONLINECONSULT_MEETING_CALLDECLINE, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	consultMeetingCallDisconnect: (body) => {
		return axios.put(API_ENDPOINTS.ONLINECONSULT_MEETING_DISCONNECT, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
	readTenantMediaDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.ONLINECONSULT_READ_TENANT_MEDIADETAILS, {
			headers: { ...headers, path: { appointmentUuid } },
		})
	},

	// decline call Notification

	findCallNotification: (callNotificationUuid) => {
		return axios.get(API_ENDPOINTS.CONSULT_CALLNOTIFICATION_FINDCALL, {
			headers: { ...headers, path: { callNotificationUuid } },
		})
	},

	updateCallDeclineNotification: (body, callNotificationUuid, tentUserUuid) => {
		return axios.put(API_ENDPOINTS.CONSULT_UPDATE_CALLDECLINE_NOTIFICATION, body, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { callNotificationUuid, tentUserUuid } },
		})
	},

	callDeleteNotification: (callNotificationUuid) => {
		return axios.delete(API_ENDPOINTS.CONSULT_CALLNOTIFICATION_DELETE, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { callNotificationUuid } },
		})
	},

	updateCallNotification: (body, callNotificationUuid) => {
		return axios.put(API_ENDPOINTS.CONSULT_CALLNOTIFICATION_UPDATE, body, {
			headers: { ...headers, 'X-SECURITY': csrf(), path: { callNotificationUuid } },
		})
	},

	saveAppointmentNotification: (custUuid, tentUserUuid, callNotificationUuid) => {
		return axios.post(
			API_ENDPOINTS.CONSULT_CALLNOTIFICATION_SAVE,
			{},
			{
				headers: { ...headers, 'X-SECURITY': csrf(), path: { custUuid, tentUserUuid, callNotificationUuid } },
			}
		)
	},
	getTenantDetails: (tentUserUuid) => {
		return axios.get(API_ENDPOINTS.GET_TENANT_DETAILS, {
			headers: { ...headers, path: { tentUserUuid } },
		})
	},
	closeChat: (body) => {
		return axios.post(API_ENDPOINTS.CLOSE_CHAT_AREA, body, {
			headers: { ...headers, 'X-SECURITY': csrf() },
		})
	},
}
