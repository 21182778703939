import { ACTION_TYPES } from '../../../constants'
import _ from 'lodash'

const initialState = {
	customerList: [],
	customerListEmpty: [],
	openAddClient: {
		open: false,
		isEditMode: false,
		data: {},
	},
	openAddPatient: {
		open: false,
		isEditMode: false,
		data: {},
	},
	customerData: {},
	upcomingAppointment: [],
	pastAppointment: [],
	practiceListName: {},
	globalListView: 'list',
	globalAdvancedFilter: false,
}

const clientReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.CUSTOMER_LIST: {
			let updatedCustomerList

			if (typeof action.payload === 'function') {
				updatedCustomerList = action.payload(state.customerList)
			} else {
				updatedCustomerList = action.payload
			}
			return {
				...state,
				customerList: _.uniqBy(updatedCustomerList, 'custUuid'),
			}
		}
		case ACTION_TYPES.CUSTOMER_LIST_EMPTY: {
			return {
				...state,
				customerListEmpty: action.payload,
			}
		}
		case ACTION_TYPES.GLOBAL_GRID_VIEW: {
			return {
				...state,
				globalListView: action.payload,
			}
		}
		case ACTION_TYPES.OPEN_ADD_CLIENT_MODAL: {
			return {
				...state,
				openAddClient: {
					open: true,
					isEditMode: action?.payload?.isEditMode || false,
					data: action?.payload?.data || {},
				},
			}
		}
		case ACTION_TYPES.CLOSE_ADD_CLIENT_MODAL: {
			return {
				...state,
				openAddClient: {
					open: false,
					isEditMode: false,
					data: {},
				},
			}
		}
		case ACTION_TYPES.OPEN_ADD_PATIENT_MODAL: {
			return {
				...state,
				openAddPatient: {
					open: true,
					isEditMode: action?.payload?.isEditMode || false,
					data: action?.payload?.data || {},
				},
			}
		}
		case ACTION_TYPES.CLOSE_ADD_PATIENT_MODAL: {
			return {
				...state,
				openAddPatient: {
					open: false,
					isEditMode: false,
					data: {},
				},
			}
		}
		case ACTION_TYPES.SET_CUSTOMER_DATA: {
			return {
				...state,
				customerData: action.payload,
			}
		}
		case ACTION_TYPES.SET_UPCOMING_APPOINTMENT: {
			return {
				...state,
				upcomingAppointment: action.payload,
			}
		}
		case ACTION_TYPES.SET_PAST_APPOINTMENT: {
			return {
				...state,
				pastAppointment: action.payload,
			}
		}
		case ACTION_TYPES.SET_PRACTICE_LIST_NAME: {
			return {
				...state,
				practiceListName: action.payload,
			}
		}
		case ACTION_TYPES.GLOBAL_ADVANCED_FILTER: {
			return {
				...state,
				globalAdvancedFilter: action.payload,
			}
		}
		default:
			return state
	}
}
export default clientReducer
