import { ACTION_TYPES } from '../../../../constants'
import secureLocalStorage from 'react-secure-storage'

const string1 = secureLocalStorage.getItem('profileStatus')

const initialState = {
	personalDetails: {
		emailList: [],
		emailFormData: {},
		phoneList: [],
		phoneFormData: {},
	},
	registrationDocuments: {
		list: [],
		formData: {},
		personalProof: '',
		establishmentProof: '',
	},
	educationDetails: {
		educationList: [],
		educationFormData: {},
	},
	establishment: {
		list: [],
		formData: {},
	},
	establishmentDetails: secureLocalStorage.getItem('establishmentGetDetails') || {},
	awardDetails: {
		awardList: [],
		awardFormData: {},
		memberList: [],
		memberFormData: {},
	},
	timingAndPayments: {
		timingList: [],
		timingFormData: {},
	},
	serviceExperience: {
		serviceExpList: [],
		serviceExpFormData: {},
	},
	profileStatus: JSON.parse(string1) || {},
}

const ProfileSetupReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_PERSONALDETAILS_EMAIL_LIST: {
			return {
				personalDetails: {
					...state?.personalDetails,
					emailList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_PERSONALDETAILS_EMAIL_FORMDATA: {
			return {
				personalDetails: {
					...state?.personalDetails,
					emailFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_PERSONALDETAILS_PHONE_LIST: {
			return {
				personalDetails: {
					...state?.personalDetails,
					phoneList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_PERSONALDETAILS_PHONE_FORMDATA: {
			return {
				personalDetails: {
					...state?.personalDetails,
					phoneFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_REGISTRATION_DETAILS: {
			return {
				...state,
				registrationDocuments: {
					...state?.registrationDocuments,
					list: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_REGISTRATION_FORM_DATA: {
			return {
				...state,
				registrationDocuments: {
					...state?.registrationDocuments,
					formData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_PERSONAL_PROOF: {
			return {
				...state,
				registrationDocuments: {
					...state?.registrationDocuments,
					personalProof: action.payload,
				},
			}
		}
		case ACTION_TYPES.UPDATE_ESTABLISHMENT_PROOF: {
			return {
				...state,
				registrationDocuments: {
					...state?.registrationDocuments,
					establishmentProof: action.payload,
				},
			}
		}
		case ACTION_TYPES.UPDATE_EDUCATION_DETAILS: {
			return {
				...state,
				educationDetails: {
					...state?.educationDetails,
					educationList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_EDUCATION_FORMDATA: {
			return {
				...state,
				educationDetails: {
					...state?.educationDetails,
					educationFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_ESTABLISHMENT_LIST_DATA: {
			return {
				...state,
				establishment: {
					...state?.establishment,
					list: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_ESTABLISHMENT_FORM_DATA: {
			return {
				...state,
				establishment: {
					...state?.establishment,
					formData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_AWARD_DETAILS: {
			return {
				...state,
				awardDetails: {
					...state?.awardDetails,
					awardList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_AWARD_FORMDATA: {
			return {
				...state,
				awardDetails: {
					...state?.awardDetails,
					awardFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_MEMBER_DETAILS: {
			return {
				...state,
				awardDetails: {
					...state?.awardDetails,
					memberList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_MEMBER_FORMDATA: {
			return {
				...state,
				awardDetails: {
					...state?.awardDetails,
					memberFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_TIMING_DETAILS: {
			return {
				...state,
				timingAndPayments: {
					...state?.timingAndPayments,
					timingList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_TIMING_FORMDATA: {
			return {
				...state,
				timingAndPayments: {
					...state?.timingAndPayments,
					timingFormData: { ...action.payload },
				},
			}
		}
		case ACTION_TYPES.UPDATE_SERVICEEXP_LIST_DATA: {
			return {
				...state,
				serviceExperience: {
					...state?.serviceExperience,
					serviceExpList: [...action.payload],
				},
			}
		}
		case ACTION_TYPES.UPDATE_SERVICEEXP_FORM_DATA: {
			return {
				...state,
				serviceExperience: {
					...state?.serviceExperience,
					serviceExpFormData: { ...action.payload },
				},
			}
		}

		case ACTION_TYPES.ESTABLISHMENT_DETAILS: {
			return {
				...state,
				establishmentDetails: {
					...state?.establishmentDetails,
					establishmentDetails: action.payload,
				},
			}
		}
		case ACTION_TYPES.SET_PROFILE_STATUS: {
			return {
				...state,
				profileStatus: action.payload,
			}
		}
		default:
			return state
	}
}

export default ProfileSetupReducer
