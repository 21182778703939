import React, { useCallback, useEffect, useState } from 'react'
import { Chip, Grid, Skeleton, Stack, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MenuSelectionApi from 'services/ProfileSetup/MenuSelection/api'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import CustomBtn from 'app/sharedComponents/Button'
import SuccessModal from '../SuccessModal'

const useStyles = makeStyles((theme) => ({
	checked: {
		fontWeight: 500,
		border: '0.5px solid #0062DD',
		// background: '#0062DD',
		'& .MuiSvgIcon-root': {
			color: '#0062DD',
		},
	},
	selectionStack: {
		'& .MuiTypography-subtitle1': {
			cursor: 'pointer',
			color: '#0062DD',
			fontWeight: 500,
			textDecoration: 'underline',
		},
	},
	chipContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
		gap: 8,
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr 1fr',
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr 1fr',
		},
	},
}))

const MenuSelection = ({ history, moveBackTab }) => {
	const classes = useStyles()
	const state = useSelector((state) => state.Authentication)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const dispatch = useDispatch()
	const { mastuuid } = state
	const [menuData, setMenuData] = useState([])
	const [editMenuData, setEditMenuData] = useState([])
	const [selectedChips, setSelectedChips] = useState(editMenuData ? editMenuData.map((item) => item.micrositeMenuUuid) : [])
	const [loading, setLoading] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)

	const handleChipClick = (micrositeMenuUuid) => {
		setSelectedChips((prevSelectedChips) =>
			prevSelectedChips.includes(micrositeMenuUuid)
				? prevSelectedChips.filter((uuid) => uuid !== micrositeMenuUuid)
				: [...prevSelectedChips, micrositeMenuUuid]
		)
	}

	const handleSelectAll = () => {
		setSelectedChips(menuData?.map((item) => item.micrositeMenuUuid))
	}

	const handleDeselectAll = () => {
		setSelectedChips([])
	}

	const getMenuData = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			setLoading(false)
			setMenuData(res?.data?.data)
		}
		const onFailure = (err) => {
			setLoading(false)
			setMenuData([])
		}
		MenuSelectionApi.getMicrositeMenuData(mastuuid).then(onSuccess, onFailure)
	}, [mastuuid])

	const postMenuData = () => {
		const body = selectedChips
		dispatch(showLoader('Loading Please Wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
			setModalOpen(true)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		MenuSelectionApi.postMicrositeMenuData(body, mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}

	const getAssignedMicrositeMenu = useCallback(() => {
		const onSuccess = (res) => {
			setEditMenuData(res?.data?.data)
			setSelectedChips(res?.data?.data.map((item) => item.micrositeMenuUuid))
		}
		const onFailure = (err) => {
			setEditMenuData([])
			setSelectedChips([])
		}
		MenuSelectionApi.getAssignedMicrositeMenuData(mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}, [mastuuid, profileHeaderUuid])

	useEffect(() => {
		getMenuData()
		getAssignedMicrositeMenu()
	}, [getMenuData, getAssignedMicrositeMenu])

	const closeSuccessModal = () => {
		setModalOpen(false)
		history.push(`/profile`)
	}

	return (
		<>
			<SuccessModal open={modalOpen} closeSuccessModal={closeSuccessModal} />
			{_.isEqual(loading, true) ? (
				<>
					<Stack direction={'row'} gap={2} className={classes.selectionStack} marginBottom={1}>
						<Skeleton width={120} height={30}></Skeleton>
						<Skeleton width={120} height={30}></Skeleton>
					</Stack>
					<div className={classes.chipContainer}>
						{Array.from({ length: 10 }).map((_, idx) => (
							<Skeleton key={idx} width={200} sx={{ borderRadius: 30 }} height={50}></Skeleton>
						))}
					</div>
				</>
			) : (
				<>
					<Stack direction={'row'} gap={2} className={classes.selectionStack} marginBottom={1}>
						<Typography onClick={handleSelectAll} variant='subtitle1'>
							Select All
						</Typography>
						<Typography onClick={handleDeselectAll} variant='subtitle1'>
							Unselect All
						</Typography>
					</Stack>

					{menuData.length > 0 && (
						<div className={classes.chipContainer}>
							{menuData?.map((item, index) => (
								<Chip
									key={index}
									sx={{ display: 'flex', justifyContent: 'flex-start', width: 200 }}
									label={item?.micrositeMenuName}
									icon={selectedChips.includes(item.micrositeMenuUuid) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
									variant='outlined'
									onClick={() => handleChipClick(item.micrositeMenuUuid)}
									className={selectedChips.includes(item.micrositeMenuUuid) ? classes.checked : ''}
								/>
							))}
						</div>
					)}
					<Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'end', gap: 24, marginTop: 18 }}>
						<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
						<CustomBtn onClick={() => postMenuData()} disabled={selectedChips.length === 0}>
							Save
						</CustomBtn>
					</Grid>
				</>
			)}
		</>
	)
}

export default MenuSelection
