import { authRoles } from 'app/auth'
import EmailSuccesful from './EmailSuccessful'

const LoginConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/emailsuccesfull',
			component: EmailSuccesful,
		},
	],
}

export default LoginConfig
