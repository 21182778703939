import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

const CancelToken = axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getLookup: (dataType, params, cancelExecutor) => {
		// if (cancelExecutor?.current) {
		// 	cancelExecutor?.current()
		// }
		return axios.get(API_ENDPOINTS.LOOKUP, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
			// cancelToken: new CancelToken(function executor(c) {
			// 	cancelExecutor.current = c
			// }),
		})
	},
	getLookupNew: (dataType, params, cancelExecutor) => {
		// if (cancelExecutor?.current) {
		// 	cancelExecutor?.current()
		// }
		return axios.get(API_ENDPOINTS.LOOKUP, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
			// cancelToken: new CancelToken(function executor(c) {
			// 	cancelExecutor.current = c
			// }),
		})
	},
	// getReferedBy: (mastuuid) => {
	// 	return axios.get(API_ENDPOINTS.REFERRED_BY_GET, {
	// 		headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
	// 	})
	// },
	// postReferedBy: (body) => {
	// 	return axios.post(API_ENDPOINTS.REFERRED_BY_POST, body, {
	// 		headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
	// 	})
	// },
}
