import { CameraAlt, DescriptionOutlined, Image, Help } from '@mui/icons-material'
import { ClickAwayListener, Grid, Grow, IconButton, Paper, Popper, Tooltip } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

export const AttachmentModal = (props) => {
	const { fileDetails, handleAttachmentClose, anchorRef, setFileDetails, handleUploadingFileToTenant = () => {} } = props
	const classes = fileStyles()

	const handleChange = (event) => {
		handleUploadingFileToTenant(event.target.files)
	}
	return (
		<>
			<Popper placement='top-end' open={fileDetails.showFileModal} anchorEl={anchorRef.current} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleAttachmentClose}>
								<Grid container direction='row' alignItems='center' className={classes.gridStyle}>
									<Tooltip title='Accept file format jpg/jpeg/png/mp4/pdf' placement='right-end' style={{ cursor: 'pointer' }}>
										<Help className={classes.helpIcon} />
									</Tooltip>
									<IconButton className={clsx(classes.items, classes.docImg)}>
										<label htmlFor='selectFile' className={classes.labelStyle}>
											<DescriptionOutlined style={{ color: '#FFFFFF' }} />
										</label>
									</IconButton>
									<input
										name='docFile'
										id='selectFile'
										type='file'
										style={{ display: 'none' }}
										accept='application/pdf'
										onChange={handleChange}
										multiple
									/>
									<IconButton className={clsx(classes.items, classes.gallaryImg)}>
										<label htmlFor='selectGallery' className={classes.labelStyle}>
											<Image style={{ color: '#FFFFFF' }} />
										</label>
									</IconButton>
									<input
										name='galleryFile'
										id='selectGallery'
										type='file'
										style={{ display: 'none' }}
										accept='image/png,video/mp4,image/jpeg,image/jpg'
										onChange={handleChange}
										multiple
									/>
									<IconButton className={clsx(classes.items, classes.photoImg)} onClick={() => setFileDetails({ ...fileDetails, showCamera: true })}>
										<label htmlFor='openCamera' className={classes.labelStyle}>
											<CameraAlt style={{ color: '#FFFFFF' }} />
										</label>
									</IconButton>
									<input
										name='photoFile'
										id='openCamera'
										style={{ display: 'none' }}
										accept='image/*'
										capture='environment'
										onChange={handleChange}
									/>
								</Grid>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	)
}

const fileStyles = makeStyles((theme) => ({
	items: {
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '25px',
		width: '50px',
		height: '50px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0px 8px',
	},
	docImg: {
		background: 'transparent linear-gradient(180deg, #548CCB 0%, #040FA8 100%) 0% 0% no-repeat padding-box',
	},
	photoImg: {
		background: 'transparent linear-gradient(180deg, #6B18E8 0%, #8B17EF 100%) 0% 0% no-repeat padding-box',
	},
	gallaryImg: {
		background: 'transparent linear-gradient(180deg, #E81879 0%, #D439BF 100%) 0% 0% no-repeat padding-box',
	},
	gridStyle: {
		background: '#FFFFFF',
		boxShadow: '0px 0px 10px #00000014',
		border: '1px solid #DBE5ED',
		borderRadius: '10px',
		padding: '12px 9px',
	},
	labelStyle: {
		display: 'flex',
		cursor: 'pointer',
	},
	helpIcon: {
		position: 'absolute',
		top: '5px',
		right: '5px',
		fontSize: '14px',
		color: '#e22c24',
	},
}))
