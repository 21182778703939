import React from 'react'
import Box from '@mui/material/Box'
import useStyles from './style'
import Patients from './Patients/index'
import Groups from './Groups/index'
import Staffs from './Staff/index'
import _ from 'lodash'

export default function BasicTabs({
	patientList,
	groupList,
	staffList,
	loading,
	state,
	dispatch,
	templateType,
	checkAll,
	setCheckAll,
	patientSearchValue,
	setPatientSearchValue,
	onSearch,
	pageNo,
	setPageNo,
	hasMore,
	setHasMore,
	patientsData,
}) {
	const classes = useStyles()

	return (
		<div className={classes.tabsMain}>
			<Box sx={{ width: '100%' }}>
				{(_.isEqual(templateType, 'Patients') || _.isEqual(templateType, 'Clients')) && (
					<Patients
						patientList={patientList}
						state={state}
						dispatch={dispatch}
						loading={loading}
						checkAll={checkAll}
						setCheckAll={setCheckAll}
						patientSearchValue={patientSearchValue}
						setPatientSearchValue={setPatientSearchValue}
						onSearch={onSearch}
						pageNo={pageNo}
						setPageNo={setPageNo}
						hasMore={hasMore}
						setHasMore={setHasMore}
						patientsData={patientsData}
					/>
				)}
				{_.isEqual(templateType, 'Groups') && (
					<>
						<Groups groupList={groupList} state={state} dispatch={dispatch} loading={loading} />
					</>
				)}
				{_.isEqual(templateType, 'Staff') && (
					<>
						<Staffs staffList={staffList} state={state} dispatch={dispatch} loading={loading} />
					</>
				)}
			</Box>
		</div>
	)
}
