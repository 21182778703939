import { authRoles } from 'app/auth'
import ProfileTable from './ProfileManagement'

const ProfileTableConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: true,
				},
				toolbar: {
					display: true,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: true,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/profileTable',
			component: ProfileTable,
		},
	],
}

export default ProfileTableConfig
