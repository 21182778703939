import React, { useState, useEffect, useMemo } from 'react'
import Radio from '@mui/material/Radio'
import _ from 'lodash'
import { Typography, FormControlLabel, FormHelperText } from '@mui/material'
import useStyles from './style'

export default function EmailTemplate({ cost, available, templateList, formik, loading }) {
	const [value, setValue] = useState('')
	const classes = useStyles()
	const hasError = useMemo(() => formik?.touched?.template && formik?.errors?.template, [formik])

	const handleChange = (newVal) => {
		setValue(newVal)
		formik?.setFieldValue('template', newVal)
	}

	useEffect(() => {
		!_.isEmpty(templateList) && setValue(templateList[0]?.mastCommName)
		formik?.setFieldValue('template', templateList[0]?.mastCommName || '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateList])

	return (
		<>
			<div className={hasError ? classes?.listContentWithError : classes.listContent}>
				<div className={classes.messageCost}>
					<Typography>
						{cost}
						<span>{available}</span>
					</Typography>
				</div>
				<div>
					<Typography>Templates</Typography>
					<div className={classes.listItem}>
						{!loading && !_.isEmpty(templateList) ? (
							_.map(templateList, (template, idx) => (
								<FormControlLabel
									key={idx}
									value='top'
									control={
										<Radio
											id='templatename'
											name='templatename'
											checked={template?.mastCommName === value}
											onChange={() => handleChange(template?.mastCommName)}
											value={formik?.template?.mastCommName}
											inputProps={{ 'aria-label': 'A' }}
										/>
									}
									label={_.capitalize(template?.mastCommName)}
									labelPlacement='right'
								/>
							))
						) : loading ? (
							<Typography align='left' className='mt-10'>
								Loading templates...
							</Typography>
						) : (
							<Typography align='center' className='mt-10'>
								No Templates Found
							</Typography>
						)}
					</div>
				</div>
			</div>
			{hasError && <FormHelperText error>{formik?.errors?.template}</FormHelperText>}
		</>
	)
}
