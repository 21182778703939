import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	communicationMain: {
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			// padding: 20,
		},
	},
	communicationMainOne: {
		paddingInline: 22,
		paddingBlock: 20,
		borderRadius: 8,
		background: '#fff',
		boxShadow: 'inset 0 -1px 0 0 rgba(0,0,0,0.1)',
		position: 'sticky',
		top: 60,
		backdropFilter: 'saturate(180%) blur(5px)',
		backgroundColor: 'hsla(0,0%,100%,0.8)',
		[theme.breakpoints.up('xs')]: {
			paddingInline: 12,
			zIndex: '9999',
		},
		[theme.breakpoints.up('sm')]: {
			paddingInline: 12,
			zIndex: '9999',
		},
		[theme.breakpoints.up('md')]: {
			paddingInline: 12,
			zIndex: '9999',
		},
	},
	communicationMainTwo: {
		borderRadius: 8,
		background: '#fff',
		marginBlockStart: 12,
	},
	communicationTabPart: {
		'& .MuiTab-root': {
			[theme.breakpoints.up('xs')]: {
				// minWidth: 56,
				maxHeight: 38,
			},
		},
		'& .MuiTabs-flexContainer': {
			marginInline: 0,
			justifyContent: 'space-evenly',
		},
		'& .MuiTabs-scroller': {
			background: '#E8EAEC',
			borderRadius: 8,
			maxHeight: 48,
			paddingInline: 4,
			[theme.breakpoints.up('xs')]: {
				marginInline: 12,
				marginBlock: 12,
			},
			[theme.breakpoints.up('sm')]: {
				marginInline: 12,
				marginBlock: 12,
			},
		},
		'& .MuiTypography-root ': {
			paddingInline: 16,
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				paddingInline: 0,
			},
		},
		'& .Mui-selected': {
			color: '#252F3E',
			background: '#FFFFFF',
			marginBlock: 4,
			borderRadius: 8,
			minHeight: '0px',
			[theme.breakpoints.up('xs')]: {
				fontSize: 13,
			},
			[theme.breakpoints.up('sm')]: {
				fontSize: 13,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 13,
			},
		},
		'& .MuiTabs-indicator': {
			left: '0px !important',
			width: '0px !important',
		},
		'& .MuiButtonBase-root-MuiTab-root': {
			minHeight: '40px !important',
		},
	},
	commText: {
		fontSize: 16,
		color: '#fff',
	},
	profileEstablishment: {
		'& .MuiInputBase-root ': {
			color: '#FFFFFF',
			'& .MuiSvgIcon-root ': {
				color: '#FFFFFF',
			},
		},
		'& .MuiAutocomplete-endAdornment': {
			position: 'relative',
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qvsu1l-MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
			paddingRight: 26,
		},
	},
	namePart: {
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root': {
			padding: '0px',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 20,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
		'& .MuiSvgIcon-root': {
			color: '#000',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
			padding: '2.5px 4px 2.5px 0px',
			color: '#000',
		},
	},
	newSmsPart: {
		justifyContent: 'end',
		alignItems: 'center',
		gap: 12,
		[theme.breakpoints.up('xs')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 0,
		},
		[theme.breakpoints.down('md')]: {
			marginBlock: 8,
		},
	},
	availableText: {
		fontSize: 12,
		color: '#535353',
	},
	createPlanBtn: {
		backgroundColor: '#FFFFFF',
		borderRadius: 20,
		color: '#535353',
		paddingInline: 16,
		paddingBlock: 8,
		'&:hover': {
			background: themesConfig.lyfngo.palette.tertiary.light,
			border: 'none',
		},
		'& .MuiSvgIcon-root': {
			fill: themesConfig.lyfngo.palette.tertiary.main,
			color: '#fff',
			fontSize: 24,
			'&:hover': {
				fill: themesConfig.lyfngo.palette.tertiary.dark,
			},
		},
	},
	walletBtn: {
		backgroundColor: '#2EB2FF',
		borderRadius: 20,
		color: '#fff',
		paddingInline: 16,
		paddingBlock: 8,
		marginRight: 20,
		'&:hover': {
			backgroundColor: '#2EB2FF',
		},
		'& .MuiSvgIcon-root': {
			color: '#fff',
			fontSize: 24,
		},
		'& span': {
			marginInline: 8,
		},
	},
	addNewSms: {
		background: '#fff',
		color: '#000',
		borderRadius: 24,
		paddingInline: 16,
		paddingBlock: 8,
		'&:hover': {
			background: '#fff',
			color: '#000',
		},
	},
	gotoStart: {
		position: 'sticky',
		bottom: 0,
		paddingBlock: 12,
		backdropFilter: 'saturate(180%) blur(5px)',
		boxShadow: 'inset 0 0px 0 0 rgba(0,0,0,0.1)',
		backgroundColor: 'hsla(0,0%,100%,0.8)',
	},
	communicationMainThree: {
		background: 'red',
	},

	// #################################################################################

	sectionHead: {
		padding: 12,
		background: '#0062DD',
		position: 'sticky',
		top: 64,
		zIndex: 12,
	},
	titleText: {
		display: 'flex',
		gap: 4,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	buttonside: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: '100%',
	},
	TabHeadContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiTabs-root': {
			minHeight: 36,
		},
		'& .MuiTabs-scroller': {
			background: '#fff',
			color: '#727272',
			borderRadius: 20,
			'& .MuiButtonBase-root': {
				minWidth: 140,
			},
			'& .Mui-selected': {
				background: themesConfig.lyfngo.palette.secondary.main,
				color: '#fff',
				borderRadius: 20,
				'&:hover': {
					borderRadius: 20,
					background: themesConfig.lyfngo.palette.secondary.main,
				},
			},
			'& .MuiTab-root': {
				minHeight: 36,
				'&:hover': {
					borderRadius: 20,
					background: themesConfig.lyfngo.palette.secondary.light,
				},
			},
			'& .MuiTabs-indicator': {
				left: '0px !important',
				width: '0px !important',
			},
		},
	},
	mainContainer: {
		marginBlockStart: 10,
		background: '#FFF',
		boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
		borderRadius: 8,
		height: '80vh',
	},

	contentGridItem: {
		'& .MuiTabPanel-root': {
			padding: 14,
		},
	},

	// #################################################################################
}))
export default useStyles
