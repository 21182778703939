import { Typography } from '@mui/material'

function TaskPage() {
	return (
		<div>
			<Typography variant='h5'>Task</Typography>
		</div>
	)
}

export default TaskPage
