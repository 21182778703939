import React, { useState } from 'react'
import { Autocomplete, Icon, InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	Autocomplete: {
		'& .MuiSvgIcon-root': { color: '#000' },
	},
	textField: {
		'& .MuiInputBase-adornedEnd': {
			paddingRight: theme.spacing(1),
		},
	},
}))

const AdvancedAutocomplete = (props) => {
	const {
		value,
		onChange,
		name,
		options,
		loading,
		label,
		className,
		error,
		helperText,
		onInputChange,
		getOptionLabel,
		getOptionSelected,
		disableClearable,
		noOptionsText,
		multiple,
		disabled,
		InputLabelProps,
		InputProps,
	} = props
	const [toggledView, setToggledView] = useState(false)
	const toggleField = () => {
		setToggledView(!toggledView)
	}
	const classes = useStyles()
	return (
		<>
			{toggledView ? (
				<Autocomplete
					{...props}
					name={name}
					id={name}
					className={className}
					loading={loading}
					noOptionsText={noOptionsText}
					options={options}
					size={'small'}
					multiple={multiple || false}
					onBlur={toggleField}
					disabled={disabled}
					fullWidth
					openOnFocus
					disablePortal={true}
					disableClearable={disableClearable}
					getOptionLabel={getOptionLabel}
					getOptionSelected={getOptionSelected}
					onChange={(e, v) => {
						onChange(e, v)
						toggleField()
					}}
					onInputChange={onInputChange}
					value={value}
					InputLabelProps={InputLabelProps}
					InputProps={InputProps}
					renderInput={(params) => (
						<TextField
							size='small'
							className={className}
							color='secondary'
							label={label}
							fullWidth
							autoFocus
							{...params}
							id='advanceautocomplete'
							name='advanceautocomplete'
							variant='outlined'
						/>
					)}
				/>
			) : (
				<TextField
					value={value?.mastLookupValue || value?.mastCities || value?.mastState || value || ''}
					label={label}
					size='small'
					color='secondary'
					className={classes.textField}
					fullWidth
					variant='outlined'
					id='advanceautocomplete'
					name='advanceautocomplete'
					error={error}
					helperText={helperText}
					disabled={disabled}
					onClick={toggleField}
					onFocus={toggleField}
					InputLabelProps={{
						shrink: InputLabelProps,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Icon>arrow_drop_down</Icon>
							</InputAdornment>
						),
						readOnly: InputProps,
					}}
				/>
			)}
		</>
	)
}
export default AdvancedAutocomplete
