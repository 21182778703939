import { Grid, Typography } from '@mui/material'
import React from 'react'

const Header = ({ HeaderName }) => {
	return (
		<Grid container alignItems='center'>
			<Grid item>
				<Typography variant='h6' style={{ textTransform: 'capitalize' }}>
					{HeaderName === 'setting' ? `${HeaderName}s` : HeaderName && HeaderName === 'patientemr' ? 'EMR' : HeaderName}
				</Typography>
			</Grid>
		</Grid>
	)
}

export default Header
