import { ACTION_TYPES } from '../../../../constants'

const initialState = {
	upcomingAppointmentData: [],
	patientVisitCount: '',
	pastAppointmentData: {},
}
const AppointmentDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPCOMING_DATA: {
			return {
				...state,
				upcomingAppointmentData: {},
			}
		}
		case ACTION_TYPES.UPCOMING_DATAA: {
			return {
				...state,
				upcomingAppointmentData: action.payload,
			}
		}
		case ACTION_TYPES.PAST_APPOINTMENT_DATA: {
			return {
				...state,
				pastAppointmentData: action.payload,
			}
		}
		case ACTION_TYPES.PATIENT_VISIT_COUNT: {
			return {
				...state,
				patientVisitCount: '',
			}
		}
		case ACTION_TYPES.PATIENT_VISIT_COUNT_LIST: {
			return {
				...state,
				patientVisitCount: action.payload,
			}
		}
		default:
			return state
	}
}

export default AppointmentDataReducer
