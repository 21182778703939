import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	container: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	tablet: {
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	chip: {
		backgroundColor: 'antiquewhite',
		margin: '0px 4px 4px 0',
	},
	addExperiencebtn: {
		color: '#0088ff',
	},
	cancelExperience: {
		marginTop: '20px',
		alignItems: 'center',
		color: themesConfig.greyDark.palette.primary.link,
	},
	cancelBtn: {
		color: themesConfig.greyDark.palette.primary.link,
	},
	removeBtn: {
		paddingTop: '0px !important',
	},
	navigationButtons: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
		gap: 12,
	},
	viewContainer: {
		paddingInline: 36,
		paddingBlock: 18,
	},
	label: {
		fontSize: 14,
	},
	value: {
		color: '#2A2A2A',
		fontSize: 14,
		fontWeight: 500,
	},
}))
export default useStyles
