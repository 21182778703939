import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { EffectFade, Autoplay, Pagination, Parallax } from 'swiper'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import _ from 'lodash'
import themesConfig from 'app/fuse-configs/themesConfig'
SwiperCore.use([EffectFade, Autoplay, Pagination, Parallax])
const useStyles = makeStyles({
	mainContain: {
		'& .swiper-container-android .swiper-slide, .swiper-wrapper': {
			maxWidth: 544,
		},
		'& .swiper-pagination-bullet-active': {
			background: themesConfig.greyDark.palette.primary.main,
			width: 20,
			transition: 'width .5s',
			borderRadius: 5,
		},
	},
	// contentmain: {
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	padding: 32,
	// 	gap: 12,
	// },
	// imageContain: {
	// 	display: 'flex',
	// 	flexDirection: 'column',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	height: '60vh',
	// 	// position: 'relative',
	// },
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		// padding: 74,
		height: '70vh',
	},
	contentmain: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		// padding: 74,
		// height: '60vh',
	},
	imageContain: {
		display: 'flex',
		// flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',

		// height: '60vh',
		// position: 'relative',
	},
	textImageContainer: {
		fontSize: 14,
		color: '#000',
		// lineHeight: '22px',
		textAlign: 'center',
	},
	contentTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 42,
	},
})

export default function SwiperLogin({ location, styleStatus }) {
	const classes = useStyles()
	return (
		<Swiper
			className={classes.mainContain}
			spaceBetween={0}
			parallax={true}
			centeredSlides
			// effect={'fade'}
			autoplay={{
				delay: 3000,
				disableOnInteraction: false,
			}}
			pagination={{
				clickable: true,
			}}
			loop={true}
		>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/Dashboard_Reports.svg`}
							alt='Dashboard_Reports'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Dashboard and Reports
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Dashboard and Reports
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Assisting in monitoring finances with its Simplified data analysis. View all your income and invoices in one place.
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/Consult_Facility.svg`}
							alt='Consultation_Facility'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Consultation and Facility booking
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Consultation and Facility booking
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Easily book appointments, Set reminders, follow-up visits, and many more. Get assistance in facilitating your facility. {' '}
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/WhatsApp_Online.svg`}
							alt='WhatsApp_Onlineconsult'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									WhatsApp bot and Online consult
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									WhatsApp bot and Online consult
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Getting connected and giving personalized guidance to your patient’s query is now as easy as a few clicks. 
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>

			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/Membership.svg`}
							alt='membership'
							type='image/webp'
							width={400}
							height={400}
						/>{' '}
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Membership
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Membership
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Consider memberships for long-term commitments and increase customer satisfaction and retention. {' '}
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/QuickSale.svg`}
							alt='Quicksale'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Quick sale
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Quick sale
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								One-stop sales point for promoting and trading your services, membership, equipment, or instruments, and more. {' '}
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/iCalendar.svg`}
							alt='Icalendar'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									iCalendar
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									iCalendar
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Multiple calendars will lead to more confusion. Start using one single iCalendar and allocate your time reasonably. {' '}
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className={classes.imageContain}>
					<div className={_.isEqual(styleStatus, true) ? classes?.mainContainer : classes.contentmain}>
						<img
							src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/Integration.svg`}
							alt='integration'
							type='image/webp'
							width={400}
							height={400}
						/>
						<div className={classes.contentTextContainer}>
							{_.isEqual(location, '/register') ? (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Integration
								</Typography>
							) : (
								<Typography variant='body1' style={{ fontSize: 16, color: '#000', fontWeight: 600 }}>
									Integration
								</Typography>
							)}
							<Typography variant='body1' className={classes.textImageContainer}>
								Integrate contactless transaction modes for a sound and ceaseless experience. {' '}
							</Typography>
						</div>
					</div>
				</div>
			</SwiperSlide>
		</Swiper>
	)
}
