import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	cancelButton: {
		background: '#EFEFEF',
		color: 'black',
		paddingBlock: 4,
		paddingInline: 20,
		'&:hover': {
			background: '#EFEFEF',
			color: 'black',
		},
	},
	dayText: {
		fontSize: 14,
		color: '#414A58',
	},
	txt: {
		fontSize: theme.spacing(1.6),
		marginTop: theme.spacing(2.5),
	},
	newReminderDetails: {
		'& .MuiSvgIcon-root': {
			fill: '#000',
		},
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	weekList: {
		width: '22ch',
		'& .MuiList-root-MuiMenu-list': {
			maxHeight: '32ch',
		},
		'& .MuiButtonBase-root-MuiListItemButton-root': {
			padding: 0,
			textAlign: 'start',
		},
	},
	listPart: {
		padding: 0,
		'& .MuiListItem-root': {
			padding: '0px !important',
		},
		'& .MuiListItemIcon-root': {
			minWidth: '20px !important',
		},
	},
	smsPart: {},
	tooltip: {
		width: '130px',
		marginBottom: 2,
	},
	smsText: {
		fontSize: 14,
		cursor: 'pointer',
		color: themesConfig.greyDark.palette.primary.main,
		textAlign: 'right',
	},
	selectWeekandDay: {
		[theme.breakpoints.up('xs')]: {
			gap: 16,
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			gap: 16,
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			gap: 16,
			display: 'flex',
		},
		'& .MuiAutocomplete-root': {
			[theme.breakpoints.up('xs')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.up('sm')]: {
				paddingBlock: 8,
			},
			[theme.breakpoints.up('md')]: {
				paddingBlock: 0,
			},
		},
	},
	btnActions: {
		'& .MuiDialogActions-root': {
			display: 'flex',
			justifyContent: 'center',
			[theme.breakpoints.up('xs')]: {
				justifyContent: 'space-between',
			},
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'space-between',
			},
			[theme.breakpoints.up('md')]: {
				justifyContent: 'flex-end',
			},
		},
	},
	messageCount: {
		display: 'flex',
		justifyContent: 'right',
		gap: 6,
		paddingBlock: 10,
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'right',
		},
	},
}))
export default useStyles
