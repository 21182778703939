import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Autocomplete, Button, ButtonGroup, Grid, Skeleton, TablePagination, TextField, Typography } from '@mui/material'
import DashBoardAPI from 'services/DashBoard/DashBoardList/api'
import themesConfig from 'app/fuse-configs/themesConfig'
import ClientInfoCard from './ClientInfoCard'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { decryption } from 'utils'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		borderRadius: 10,
		width: '100%',
	},
	sectionHead: {
		background: '#E1F4FF',
		padding: 14,
	},
	grouped_button: {
		borderRadius: 18,
		height: 'fit-content',
		background: '#fff',

		'& .MuiButton-outlinedPrimary': {
			paddingInline: 20,
			border: '0px',
			height: 32,
		},
		'& .Mui-disabled': {
			background: '#fff',
			color: '#000',
		},
	},
	button: {
		width: 126,
		color: '#727272',
		'&:hover': {
			color: '#727272',
		},
	},
	button_active: {
		width: 126,
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#FFF',
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
			color: '#FFF',
		},
	},
	mainContainer: {
		width: '100%',
		padding: 14,
		minHeight: 300,
		maxHeight: '55vh',
		overflow: 'scroll',
	},
	sectionBox: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '1%',
	},
	sectionRight: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	select_payment_type: {
		width: 248,
		marginRight: 24,
		'& .MuiOutlinedInput-root': {
			backgroundColor: '#fff',
			'& fieldset': {
				border: 'none',
			},
		},
	},
	referenceLabel: {
		display: 'flex',
		gap: 16,
		width: '100%',
		paddingInline: 14,
		paddingBlock: 12,
	},
	label: {
		'& div': {
			borderRadius: '50%',
			height: 14,
			width: 14,
		},
		fontSize: 13,
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		gap: 6,
	},
	paginationsec: {
		justifyContent: 'flex-end',
	},
	emptyRecordBox: {
		display: 'grid',
		placeItems: 'center',
		'& img': {
			width: 320,
		},
	},
}))

function PaymentWidget({ isCareFlow }) {
	const classes = useStyles()
	const [period, setPeriod] = useState('lastweek')
	const { mastuuid } = useSelector((state) => state.Authentication)
	const paymentTypeOptions = [
		{ label: 'All', type: 'all', color: null },
		{ label: 'Appointments', type: 'emr', color: '#4A9AFF' },
		{ label: 'Membership', type: 'membership', color: '#FF83C6' },
		{ label: 'Quick sale', type: 'quicksale', color: '#68C8FF' },
		...(isCareFlow ? [{ label: 'Pharma', type: 'pharma', color: '#75E252' }] : []),
	]
	const [paymentType, setPaymentType] = useState(paymentTypeOptions[0])
	const [paymentPendingData, setPaymentPendingData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [totalCount, setTotalCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const paginationParams = {
		page: page,
		size: rowsPerPage,
	}

	const handleChangePage = (event, newPage) => {
		setLoading(true)
		setPaymentPendingData([])
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setLoading(true)
		setPaymentPendingData([])
		setRowsPerPage(parseInt(event.target.value))
		setPage(0)
	}
	const resetToInitialPage = () => setPage(0)

	useEffect(() => {
		setLoading(true)
		DashBoardAPI.paymentpending(mastuuid, period, { category: paymentType?.type, ...paginationParams }).then(
			(res) => {
				const successRes = decryption(res)
				if (successRes?.status === 'Success') {
					setTotalCount(successRes?.data?.totalElements)
					let pendingPaymentDataRes = successRes?.data?.pendingPaymentData
					setPaymentPendingData(pendingPaymentDataRes)
					setLoading(false)
					// if (paymentType?.type === 'all') {
					// 	setPaymentPendingData(pendingPaymentDataRes)
					// 	// setPaymentPendingData({
					// 	// 	Appointments: (pendingPaymentDataRes || [])?.filter((o) => o?.billingModuleType === 'emr'),
					// 	// 	Membership: (pendingPaymentDataRes || [])?.filter((o) => o?.billingModuleType === 'membership'),
					// 	// 	QuickSale: (pendingPaymentDataRes || [])?.filter((o) => o?.billingModuleType === 'quicksale'),
					// 	// 	Pharma: (pendingPaymentDataRes || [])?.filter((o) => o?.billingModuleType === 'pharma'),
					// 	// })
					// } else {
					// 	setPaymentPendingData(pendingPaymentDataRes)
					// }
				}
			},
			(err) => {
				setPaymentPendingData([])
				setLoading(false)
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, period, paymentType, page, rowsPerPage])

	return (
		<Grid xs={12} container className={classes.root}>
			<Grid xs={12} container className={classes.sectionHead}>
				<Grid xs={4} item>
					<Typography variant='h6'>Payment Pending Status</Typography>
				</Grid>
				<Grid xs={8} item className={classes.sectionRight}>
					<div className={classes.select_payment_type}>
						<Autocomplete
							id='paymentType'
							name='paymentType'
							options={paymentTypeOptions}
							getOptionLabel={(option) => option.label || ''}
							value={paymentType}
							isOptionSelected={(option, value) => option?.type === value?.type}
							filterSelectedOptions={false}
							onChange={(e, value) => {
								setPaymentType(value)
								resetToInitialPage()
							}}
							disableClearable={true}
							disablePortal={true}
							size='small'
							fullWidth
							renderInput={(params) => <TextField {...params} variant='outlined' color='secondary' />}
						/>
					</div>
					<ButtonGroup color='primary' aria-label='medium' className={classes.grouped_button}>
						<Button
							className={_.isEqual(period, 'today') ? classes.button_active : classes.button}
							disabled={false}
							style={{ borderRadius: 18, transition: '0.7s all' }}
							onClick={() => setPeriod('today')}
						>
							Today
						</Button>
						<Button
							className={_.isEqual(period, 'lastweek') ? classes.button_active : classes.button}
							disabled={false}
							style={{ borderRadius: 18, transition: '0.7s all' }}
							onClick={() => setPeriod('lastweek')}
						>
							Last 7 Days
						</Button>
						<Button
							className={_.isEqual(period, 'lastmonth') ? classes.button_active : classes.button}
							disabled={false}
							style={{ borderRadius: 18, transition: '0.7s all' }}
							onClick={() => setPeriod('lastmonth')}
						>
							Last Month
						</Button>
						<Button
							className={_.isEqual(period, 'lastyear') ? classes.button_active : classes.button}
							disabled={false}
							style={{ borderRadius: 18, transition: '0.7s all' }}
							onClick={() => setPeriod('lastyear')}
						>
							Last Year
						</Button>
					</ButtonGroup>
				</Grid>
			</Grid>

			<Grid xs={12} container className={classes.referenceLabel}>
				{paymentTypeOptions?.map(
					(i) =>
						i?.color && (
							<Typography className={classes.label} key={i?.label}>
								<div style={{ background: i?.color }}></div>
								{i?.label}
							</Typography>
						)
				)}
			</Grid>

			<div className={classes.mainContainer}>
				{loading && (
					<div style={{ display: 'flex', flexWrap: 'wrap', gap: '1%' }}>
						{Array.from({ length: rowsPerPage }).map((_, index) => (
							<Skeleton key={index} variant='rounded' width={'32%'} height={90} sx={{ marginBottom: '14px' }} />
						))}
					</div>
				)}
				{!loading && !_.isEmpty(paymentPendingData) && (
					<div className={classes.sectionBox}>
						{paymentPendingData?.map((item) => (
							<ClientInfoCard isCareFlow={isCareFlow} cardType={item?.billingModuleType} data={item} key={item?.invoiceUuid} />
						))}
					</div>
				)}
				{/* {!_.isEmpty(paymentPendingData?.Appointments) && (
					<div className={classes.sectionBox}>
						{paymentPendingData?.Appointments?.map((item) => (
							<ClientInfoCard isCareFlow={isCareFlow} cardType={'appointments'} data={item} key={item?.invoiceUuid} />
						))}
					</div>
				)}

				{!_.isEmpty(paymentPendingData?.Membership) && (
					<div className={classes.sectionBox}>
						{paymentPendingData?.Membership?.map((item) => (
							<ClientInfoCard isCareFlow={isCareFlow} cardType={'membership'} data={item} key={item?.invoiceUuid} />
						))}
					</div>
				)}

				{!_.isEmpty(paymentPendingData?.QuickSale) && (
					<div className={classes.sectionBox}>
						{paymentPendingData?.QuickSale?.map((item) => (
							<ClientInfoCard isCareFlow={isCareFlow} cardType={'quicksale'} data={item} key={item?.invoiceUuid} />
						))}
					</div>
				)}

				{!_.isEmpty(paymentPendingData?.Pharma) && (
					<div className={classes.sectionBox}>
						{paymentPendingData?.Pharma?.map((item) => (
							<ClientInfoCard isCareFlow={isCareFlow} cardType={'pharma'} data={item} key={item?.invoiceUuid} />
						))}
					</div>
				)} */}

				{!loading && _.isEmpty(paymentPendingData) && (
					<div className={classes.emptyRecordBox}>
						<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/No_Pending_Payments.png`} alt='No pending payments' />
					</div>
				)}
			</div>

			<Grid xs={12} container className={classes.paginationsec}>
				<TablePagination
					component='div'
					count={totalCount}
					page={page}
					rowsPerPage={rowsPerPage}
					onPageChange={handleChangePage}
					rowsPerPageOptions={[10, 20, 50, 100]}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Grid>
		</Grid>
	)
}

export default PaymentWidget
