import React, { useEffect, useState } from 'react'
import { Typography, Avatar, IconButton, Grid, MenuItem, FormControl, Select } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import useStyles from './style'
import _ from '@lodash'
import moment from 'moment'
import history from '@history'

export default function UpcommingAppointments({ dashboardData, getAppointmentType, isCare }) {
	const classes = useStyles()
	let upcommingAppointmentData = dashboardData?.UpComingAppointments
	const [appointmentMethod, setAppointmentMethod] = useState('all')
	const [appointmentsData, setAppointmentsData] = useState([])
	let allAppointmentsData = upcommingAppointmentData?.ALL
	let inpersonAppointmentData = upcommingAppointmentData?.INP
	let onlineAppointmentData = upcommingAppointmentData?.ONL
	let homevisitAppointmentData = upcommingAppointmentData?.HVT

	useEffect(() => {
		if (appointmentMethod === 'all') {
			setAppointmentsData(_.sortBy(allAppointmentsData, ['scheduledOn', 'scheduledTime'], ['asc', 'asc']))
			getAppointmentType('ALL')
		} else if (appointmentMethod === 'inperson') {
			setAppointmentsData(_.sortBy(inpersonAppointmentData, ['scheduledOn', 'scheduledTime'], ['asc', 'asc']))
			getAppointmentType('INP')
		} else if (appointmentMethod === 'online') {
			setAppointmentsData(_.sortBy(onlineAppointmentData, ['scheduledOn', 'scheduledTime'], ['asc', 'asc']))
			getAppointmentType('ONL')
		} else if (appointmentMethod === 'homevisit') {
			setAppointmentsData(_.sortBy(homevisitAppointmentData, ['scheduledOn', 'scheduledTime'], ['asc', 'asc']))
			getAppointmentType('HVT')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appointmentMethod, upcommingAppointmentData])

	return (
		<div className={classes.root}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>
					<Typography variant='h6'>Upcoming Appointments</Typography>
					<Typography
						variant='body1'
						onClick={() => {
							history.push('/calendar')
						}}
					>
						View All
					</Typography>
				</div>
				<div className={classes.buttonContainer}>
					<FormControl sx={{ width: 140 }}>
						<Select
							value={appointmentMethod}
							color='secondary'
							onChange={(event) => {
								setAppointmentMethod(event.target.value)
							}}
						>
							<MenuItem value={'all'}>All</MenuItem>
							<MenuItem value={'inperson'}>Inperson</MenuItem>
							<MenuItem value={'online'}>Online</MenuItem>
							<MenuItem value={'homevisit'}>Home Visit</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			<div className={classes.mainContainer}>
				<div className={`${classes.listContainer} ${_.isEmpty(appointmentsData) && classes.listContainernoData}`}>
					{!_.isEmpty(appointmentsData) ? (
						_.map(appointmentsData, (item, index) => (
							<Grid key={item?.appointmentUuid} container className={classes.list}>
								<Grid item xs={1.5} sm={1}>
									<div className={classes.cardImage}>
										<Avatar alt={item?.custName} variant='square' src={'/a'} />
									</div>
								</Grid>
								<Grid item xs={10.5} sm={3.8}>
									<div className={classes.cardDetailName}>
										<Typography variant='body1'> {isCare ? 'Patient' : 'Client'} name</Typography>
										<Typography variant='subtitle1'>{item?.custName}</Typography>
									</div>
								</Grid>
								<Grid item xs={5} sm={3.5}>
									<div className={classes.cardDetail}>
										<Typography variant='body1'>Timing</Typography>
										<Typography variant='subtitle1'>{moment(`${item?.scheduledOn}T${item?.scheduledTime}`).calendar()}</Typography>
									</div>
								</Grid>
								<Grid item xs={5} sm={3}>
									<div className={classes.cardDetailAmount}>
										<Typography variant='body1'>For</Typography>
										<Typography variant='subtitle1'>
											{item?.category === 'ONL' && 'Online Consult'}
											{item?.category === 'INP' && 'Inperson Consult'}
											{item?.category === 'HVT' && 'Home Consult'}
										</Typography>
									</div>
								</Grid>
								<Grid item xs={2} sm={0.7}>
									<div
										className={classes.cardNavigate}
										onClick={() => {
											history.push('/calendar')
										}}
									>
										<IconButton aria-label='navigate' size='small'>
											<NavigateNext />
										</IconButton>
									</div>
								</Grid>
							</Grid>
						))
					) : (
						<div className={classes.noAppointments}>
							<img
								src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/No_appointment_yet.svg`}
								alt='no appointments'
								width='318'
								height='318'
							></img>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
