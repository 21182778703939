import axios from 'axios'
import { API_ENDPOINTS } from '../../constants/index'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setProfileStatus: (data, uuid, mastuuid) => {
		return axios.post(`${API_ENDPOINTS.PROFILE_STATUS}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { uuid, mastuuid } },
		})
	},
}
