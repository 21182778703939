import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, Tooltip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getImgUrl, getResponseMessage } from 'utils'
// import useStyles from './style'
import useStyles from '../Dropzone/style.js'
import Button from '../../../../sharedComponents/Button'
import _ from 'lodash'
import fileApi from '../../../../../services/fileupload/GetApi'
import { showMessage } from 'app/store/fuse/messageSlice'
const CompDropZone = (props) => {
	const classes = useStyles()
	const state = useSelector((states) => states.Authentication)
	const errorMessages = useSelector((states) => states.errorMessages)
	const dispatch = useDispatch()
	const { BearerToken } = state
	const { displayUploadImage, imgResponse, setEstablishmentFileResponse, title, details, deatilshere, dropzonestyle, upload, dropType } = props

	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 2097152
	const { getRootProps, getInputProps, open } = useDropzone({
		noClick: true,
		noKeyboard: true,
		maxSize: formatBytes(maxFileSize),
		maxFiles: 1,
		accept: 'image/jpg,image/jpeg,image/png,image/svg',
		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				displayUploadImage(acceptedFiles)
			}
		},
	})
	const deleteImage = () => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				setEstablishmentFileResponse(null)
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorMessages, 'Removed successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err.response.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		fileApi.deleteFile(imgResponse).then(onSuccess, onFailure)
	}

	let backgroundlogo = `url('${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/user-logo.png`}')`
	return (
		<div className={`${classes.container} ${dropzonestyle}`}>
			{!_.isEmpty(imgResponse) ? (
				<div className={classes.mainImg} style={{ backgroundImage: `url('${getImgUrl(imgResponse, BearerToken)}')` }}></div>
			) : (
				<div className={classes.mainImg} {...getRootProps()} style={{ backgroundImage: backgroundlogo }}>
					<input {...getInputProps()} />
				</div>
			)}

			<div className={classes.profileData}>
				<Typography className={classes.profilephoto}>{title}</Typography>
				<Typography>
					{details}
					<Tooltip
						className={classes.tooltip}
						arrow='true'
						title='Do not use group photos, photos with pets or photos with sunglasses. 
								Do not expose contact details such as mobile number email or url. '
					>
						<span style={{ color: 'red', cursor: 'pointer' }}> {deatilshere}</span>
					</Tooltip>
				</Typography>
				<div className={classes.mainContainer}>
					<Button
						onClick={open}
						disabled={imgResponse ? true : false}
						style={{ display: !_.isEmpty(imgResponse) && _.isEqual(dropType, 'profile') ? 'none' : 'block' }}
					>
						{upload || 'Upload'}
					</Button>

					<div className={classes.deletIcon}>
						{imgResponse ? (
							<Button onClick={() => deleteImage()} customStyle={classes.removeBtn}>
								Remove
							</Button>
						) : null}
					</div>
				</div>
				<div className={classes.helperTextContainer}>
					<Typography variant='body1'>File types: PNG, JPG, JPEG</Typography>
					<Typography variant='body1'>Maximum file size: 2 MB</Typography>
				</div>
			</div>
		</div>
	)
}

export default CompDropZone
