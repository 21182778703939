import { authRoles } from 'app/auth'
import ChangeEmail from './changeEmail'

const LoginConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/changeemail',
			component: ChangeEmail,
		},
	],
}

export default LoginConfig
