import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDoctorList: (tentMasterUuid, params) => {
		return axios.get(`${API_ENDPOINTS.DOCTOR_LIST}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { tentMasterUuid } },
			params: { ...params },
		})
	},

	getApptModesBasedOnTent: (params) => {
		return axios.get(`${API_ENDPOINTS.GET_TENANT_APPOINTMENT_MODES}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getDoctorListBasedOnApptmodeAndSpeciality: (params) => {
		return axios.get(API_ENDPOINTS.GET_DOCTORLIST_BASED_ON_SPECIALITY_AND_APPT_MODE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getEstablishmentDoctorList: (params) => {
		return axios.get(API_ENDPOINTS.DOCTOR_LIST_ESTABLISHMENT, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},

	getPatientList: (uuid) => {
		return axios.get(API_ENDPOINTS.PATIENT_ID_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	postPatientList: (uuid, publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.AUTO_SEARCH_PATIENT_LIST_POST}`, result, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				path: { uuid },
			},
		})
	},
	updatePatientdetails: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.put(`${API_ENDPOINTS.UPDATE_PATIENT_DETAILS}`, result, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
}
