import axios from 'axios'
import { csrf } from 'utils'
import { API_ENDPOINTS } from '../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setRegister: (data) => {
		return axios.post(`${API_ENDPOINTS.REGISTER}`, data, { headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf() } })
	},
	getLookup: (dataType, params) => {
		return axios.get(API_ENDPOINTS.COUNTRY_CODE, {
			headers: { 'Content-Type': 'application/json', path: { dataType } },
			params: { ...params },
		})
	},
	getCountryCode: () => {
		return axios.get(API_ENDPOINTS.COUNTRY_CODE_MODE, {
			headers: { 'Content-Type': 'application/json' },
		})
	},
	setRegisterNew: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.REGISTER_NEW}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
	getTermsAndCondition: (params) => {
		return axios.get(API_ENDPOINTS.GET_TERMS_AND_CONDITION, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: {} },
			params: { ...params },
		})
	},
	getInsuranceField: (params) => {
		return axios.get(API_ENDPOINTS.GET_INSURANCE_NAME, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: {} },
			params: params,
		})
	},
	setInsuranceName: (data, params) => {
		return axios.post(`${API_ENDPOINTS.GET_INSURANCE_NAME}`, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf() },
			params: { ...params },
		})
	},
	deleteInsurance: (params) => {
		return axios.delete(`${API_ENDPOINTS.GET_INSURANCE_NAME}`, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
			},
			params: { ...params },
		})
	},
}
