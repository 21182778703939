import { ACTION_TYPES } from '../../../../constants'

export const customerDataList = (payload) => {
	return {
		type: ACTION_TYPES.CUSTOMER_LIST_DATA,
		payload,
	}
}
export const specialistDataList = (payload) => {
	return {
		type: ACTION_TYPES.SPECIALIST_LIST_DATA,
		payload,
	}
}

export const isEditPatient = (payload) => ({
	type: ACTION_TYPES.ISEDITPATIENT,
	payload,
})
export const getCustomerMasterData = (payload) => {
	return {
		type: ACTION_TYPES.GETCUST_MASTER,
		payload,
	}
}
