import Report from '.'

const ReportConfig = {
	routes: [
		{
			path: '/report',
			component: Report,
		},
	],
}

export default ReportConfig
