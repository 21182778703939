import { ACTION_TYPES } from '../../constants'

const initialState = {
	CalSettingsShowCanceledAppts: true,
	CalSettingsShowCompletedAppts: true,
	openAddAppointmentModal: false,
	countryCodeList: [],
	editAppointmentData: {},
	appointmentRefresh: null,
	preSelectedClient: null,
	openedFrom: null,
	prepopulationData: null,
	appointmentDate: null,
}

const AppointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SHOW_CANCELLED_APPOINTMENTS: {
			return {
				...state,
				CalSettingsShowCanceledAppts: action.payload,
			}
		}
		case ACTION_TYPES.SHOW_COMPLETED_APPOINTMENTS: {
			return {
				...state,
				CalSettingsShowCompletedAppts: action.payload,
			}
		}

		case ACTION_TYPES.ADD_APPOINTMENT_MODAL: {
			return {
				...state,
				openAddAppointmentModal: action.payload,
			}
		}

		case ACTION_TYPES.APPOINTMENT_REFRESH: {
			return {
				...state,
				appointmentRefresh: action.payload,
			}
		}

		case ACTION_TYPES.SET_COUNTRY_CODES: {
			return {
				...state,
				countryCodeList: action.payload,
			}
		}

		case ACTION_TYPES.EDIT_APPOINTMENT: {
			return {
				...state,
				editAppointmentData: action.payload,
			}
		}

		case ACTION_TYPES.APPOINTMENT_FOR_SELECTED_CLIENT: {
			return {
				...state,
				preSelectedClient: action.payload,
			}
		}

		case ACTION_TYPES.WHERE_APPOINTMENT_OPENED_FROM: {
			return {
				...state,
				openedFrom: action.payload,
			}
		}
		case ACTION_TYPES.APPOINTMENT_PREPOPULATION_DATA: {
			return {
				...state,
				prepopulationData: action.payload,
			}
		}
		case ACTION_TYPES.APPOINTMENT_DATE: {
			return {
				...state,
				appointmentDate: action.payload,
			}
		}

		default:
			return state
	}
}

export default AppointmentReducer
