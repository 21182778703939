import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { useState, useEffect, useCallback } from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Link } from 'react-router-dom'
import { groupUserNameAction, individualTentUser, individualTentUserId } from '../../../../../services/ProfileSetup/groupUser/actions'
import { useFormik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'
import profiletable from '../../../../../services/ProfileSetup/ProfileTable/api'
import { useSelector } from 'react-redux'
import secureLocalStorage from 'react-secure-storage'
import themesConfig from 'app/fuse-configs/themesConfig'
import profilestatusapi from 'services/golive/api'
import { setProfileStatus } from 'services/ProfileSetup/list/actions'
import profileStatusApi from 'services/ProfileSetup/profileStatus/api'

const ButtonStyle = styled('Button')(({ theme }) => ({
	'& .btn': {
		color: '#FFF',
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		// '&:hover': {
		// 	backgroundColor: themesConfig.greyDark.palette.primary.link,
		// },
	},
}))
const useStyles = makeStyles((theme) => ({
	tabMain: {
		'& .MuiTab-root': {
			minHeight: 36,
			minWidth: 152,
			[theme.breakpoints.down('md')]: {
				minWidth: 56,
			},
		},
		'& .MuiTabs-flexContainer': {
			marginInline: 16,
			justifyContent: 'space-between',
		},
		'& .MuiTabs-scroller': {
			marginInline: 48,
			background: '#E8EAEC',
			borderRadius: 8,
			[theme.breakpoints.down('sm')]: {
				marginInline: 12,
				minHeight: 48,
			},
		},
		'& .MuiTypography-root ': {
			paddingInline: 16,
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				paddingInline: 0,
			},
		},
		'& .Mui-selected': {
			color: '#252F3E',
			background: '#FFFFFF',
			marginBlock: 4,
			borderRadius: 8,
		},
		'& .MuiAutocomplete-root': {
			marginInline: 8,
			paddingBlock: 12,
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},

			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},

			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},

			'& .MuiFormControl-fullWidth': {
				minWidth: 246,
				[theme.breakpoints.down('sm')]: {
					minWidth: 200,
				},
			},
			'& .MuiInputLabel-shrink': {
				color: '#263238',
			},
			[theme.breakpoints.down('sm')]: {
				marginInline: 0,
			},
		},
	},
	yourSelfText: {
		color: '#263238',
		fontSize: 12,
	},
	continueBtn: {},
	noOptions: {
		color: 'red',
		backgroundColor: '#E8EAEC',
	},
}))
function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default function ProfileTab({ isOwnerLive }) {
	const [value, setValue] = React.useState(0)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [profileUserList, setProfileUserList] = useState([])
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, groupId, uuid } = state
	const handleChange = (event, newValue) => {
		setValue(newValue)
		formik?.resetForm()
	}

	const submitProfileStatus = (data) => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			profilePercentage: 5,
			progressStatus: 'IPG',
			status: true,
			isOwner: false,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(setProfileStatus(res?.data?.data || {}))
			secureLocalStorage.setItem('profileStatus', JSON.stringify(res?.data?.data || {}))
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		profilestatusapi.setProfileStatus(body, data?.tentUserUuid === '' || undefined ? uuid : data?.tentUserUuid, mastuuid).then(onSuccess, onFailure)
	}

	const ownerProfileStatus = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			profilePercentage: 5,
			progressStatus: 'IPG',
			status: true,
			isOwner: true,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(setProfileStatus(res?.data?.data || {}))
			secureLocalStorage.setItem('profileStatus', JSON.stringify(res?.data?.data || {}))
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		!isOwnerLive || isOwnerLive === null ? profilestatusapi.setProfileStatus(body, uuid, mastuuid).then(onSuccess, onFailure) : <></>
	}

	const setcurrentUserOwner = () => {
		let userTabTypeOwner =
			_.isEqual(groupId, 'fyi6pmtm') ||
			_.isEqual(groupId, 'e7z11j8m') ||
			_.isEqual(groupId, 'irokb9b8') ||
			_.isEqual(groupId, 'ztyxtevg') ||
			_.isEqual(groupId, '2nzdfwug')
				? 'Owner'
				: ''
		secureLocalStorage.setItem('groupUserName', _.toLower(userTabTypeOwner) || '')
		dispatch(
			groupUserNameAction({
				groupUserName: _.toLower(userTabTypeOwner),
			})
		)
	}

	const setCurrentUser = (data) => {
		let userTabType = _.isEqual(groupId, 'fyi6pmtm')
			? 'Doctor'
			: _.isEqual(groupId, 'e7z11j8m')
			? 'Trainer'
			: _.isEqual(groupId, 'irokb9b8')
			? 'Beautician' || 'Therapist' || 'Stylist'
			: _.isEqual(groupId, 'ztyxtevg')
			? 'Coach'
			: _.isEqual(groupId, '2nzdfwug')
			? 'Yoga Master' || 'Consultant' || 'Therapist'
			: ''
		secureLocalStorage.setItem('groupUserName', _.toLower(userTabType) || '')
		secureLocalStorage.setItem('profileCurrentTentUserId', data?.tentUserUuid || '')
		secureLocalStorage.setItem('profileCurrentTentUser', data)
		dispatch(
			groupUserNameAction({
				groupUserName: _.toLower(userTabType),
			})
		)
		dispatch(individualTentUserId(data?.tentUserUuid))
		dispatch(individualTentUser(data))
	}

	const handleNavigtion = () => {}

	const formik = useFormik({
		initialValues: {
			practise: '',
		},
		validationSchema: yup.object({
			practise: yup.string().trim('please select any one practise').strict(true).required('Practise is required'),
		}),
	})
	const getProfileUserData = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setProfileUserList(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		profiletable.getProfileUserList(mastuuid).then(onSuccess, onFailure)
	}, [dispatch, mastuuid])
	useEffect(() => {
		getProfileUserData()
	}, [getProfileUserData])

	useEffect(() => {
		if (isOwnerLive) {
			setValue(1)
		}
	}, [isOwnerLive])

	return (
		<Box sx={{ width: '100%' }} className={classes.tabMain}>
			<Box sx={{}}>
				<Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
					<Tab label='Microsite' {...a11yProps(0)} disabled={isOwnerLive} />
					<Tab label='Staff' {...a11yProps(1)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<Typography className={classes.yourSelfText}>
					Make sure you have all the relevant detail if you are creating profile for someone else in your organization
				</Typography>
				<ButtonStyle>
					<Link className='font-normal' to='/profilesetup?tabId=microsetup' style={{ color: '#2A2A2A', textDecoration: 'none' }}>
						<Button
							type='submit'
							variant='contained'
							className='btn w-full mx-auto mt-32'
							aria-label='LOG IN'
							onClick={() => {
								ownerProfileStatus()
								setcurrentUserOwner()
							}}
							disable
						>
							Continue
						</Button>
					</Link>
				</ButtonStyle>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Autocomplete
					id='combo-box-demo'
					options={profileUserList}
					getOptionLabel={(option) => option.tentUserSalutation + ' ' + option.tentUserFirstName}
					size='small'
					fullWidth
					classes={{
						noOptions: classes.noOptions,
					}}
					noOptionsText={'No Data Found'}
					renderInput={(params) => <TextField {...params} label='Select Any One Name' shrink={true} />}
					onChange={(e, value) => {
						formik?.setFieldValue('practise', value)
					}}
				/>
				<Typography className={classes.yourSelfText}>
					Someone else have all the relevant detail if you are creating profile for someone else in your organization
				</Typography>
				<ButtonStyle>
					<Link className='font-normal' to='/profilesetup?tabId=personalDetails' style={{ color: '#2A2A2A', textDecoration: 'none' }}>
						<Button
							disabled={formik.values.practise ? false : true}
							type='submit'
							variant='contained'
							className='btn w-full mx-auto mt-32'
							aria-label='LOG IN'
							onClick={() => {
								submitProfileStatus(formik?.values?.practise)
								setCurrentUser(formik?.values?.practise)
							}}
						>
							Continue
						</Button>
					</Link>
				</ButtonStyle>
			</TabPanel>
		</Box>
	)
}
