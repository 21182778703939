import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, hideLoader } from '../../../../../services/loader/action'
import { showMessage } from 'app/store/fuse/messageSlice'
import ServiceDetailsApi from '../../../../../services/ProfileSetup/serviceAndExperience/api'
import { getResponseMessage } from 'utils'
import Button from '../../../../sharedComponents/Button'
import _ from 'lodash'
import useStyles from '../style'
import moment from 'moment'

const ViewService = ({ history, match }) => {
	const mastUuid = _.get(match, 'params.mastuuid', '')
	const dispatch = useDispatch()
	const classes = useStyles()
	const errorMessages = useSelector((state) => state?.errorMessages)
	const state = useSelector((state) => state?.Authentication)
	const { uuid } = state
	const [serviceDetails, setServiceDetails] = useState([])
	const getServiceDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setServiceDetails(res?.data?.data)
		}
		const onFailure = (res) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(res?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ServiceDetailsApi.getServiceList(uuid).then(onSuccess, onFailure)
	}, [dispatch, errorMessages, uuid])
	useEffect(() => {
		getServiceDetails()
	}, [getServiceDetails])
	const navigation = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastUuid}?tabId=serviceAndExperience`)
	}
	return (
		<Grid container className={classes.viewContainer}>
			<Grid item lg={10} pb={3}>
				{!_.isEmpty(serviceDetails) &&
					_.map(serviceDetails, (item) => {
						return (
							<>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Service :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.serviceList.map((item) => item.serviceName)} :
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											From month & year :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{moment(item?.monthFrom, 'MM').format('MMMM')} - {item.yearFrom} :
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											To month & year :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{moment(item?.monthTo, 'M').format('MMMM')} - {item?.yearTo} :
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Role :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.role} :
										</Typography>
									</Grid>
								</Grid>
								<Grid item container>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											City :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.city} :
										</Typography>
									</Grid>
								</Grid>
								<Grid item container pb={4}>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.label}>
											Establishment Name :
										</Typography>
									</Grid>
									<Grid item lg={3}>
										<Typography pt={1} className={classes.value}>
											{item?.establishmentName} :
										</Typography>
									</Grid>
								</Grid>
							</>
						)
					})}
			</Grid>
			<Grid item lg={2}>
				<Button onClick={navigation}>Edit profile</Button>
			</Grid>
		</Grid>
	)
}

export default ViewService
