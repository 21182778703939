import { useEffect, useState, useCallback } from 'react'
import Button from '../../sharedComponents/Button'
import useStyles from './style'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateIswelcome, updateNewUser } from 'app/session/actions'
import { styled } from '@mui/material/styles'
import ModuleSelectionApi from '../../../services/ModuleSelection/api'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import secureLocalStorage from 'react-secure-storage'
import DynamicMenuApi from '../../../services/DynamicMenu/api'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import { getMenuFeatures } from 'utils'
import { useTheme } from '@mui/styles'

function ModuleSelection({ history }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { isAuthenticated, mastuuid } = state

	useEffect(() => {
		if (isAuthenticated || mastuuid === '' || mastuuid === undefined) {
			history.push('/')
		}
	}, [history, isAuthenticated, mastuuid])
	const roleNavigated = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		let uuid = secureLocalStorage.getItem('uuid')
		let mastUuid = secureLocalStorage.getItem('mastuuid')
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(dynamicMenuFetching())
		DynamicMenuApi.getDynamicMenu(uuid, mastUuid).then(onSuccess, onFailure)
	}, [dispatch, history])

	const navigateTo = () => {
		dispatch(updateIswelcome(true))
		dispatch(updateNewUser(true))
		secureLocalStorage.setItem('isWelcome', true)
		secureLocalStorage.setItem('isNewUser', true)
		roleNavigated()
	}
	const [data, setData] = useState([])

	const Root = styled('div')(({ theme }) => ({
		color: theme.palette.primary.contrastText,

		'& .Register-rightSection': {},

		'& .Register-leftSection': {
			background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
			color: '#707680',
		},
		'& .CardContent': {
			paddingBlock: 10,
			paddingInline: 12,
		},
		'& .right-content': {
			fontWeight: 'bold',
		},
	}))

	const Main = styled('div')(({ theme }) => ({
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
		},
	}))

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			dispatch(hideLoader())
			setData(res?.data?.data)
		}
		const onFailure = () => {
			dispatch(hideLoader())
			setData([])
		}
		ModuleSelectionApi.moduleSelection().then(onSuccess, onFailure)
	}, [dispatch])
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-8 md:p-24'>
			<Grid containerjustifyContent={isSmall ? 'start' : 'center'} spacing={2}>
				<Grid item>
					<Main>
						{!_.isEmpty(data) && (
							<div className=' h-full flex items-center justify-center'>
								<div className={classes.root}>
									<div className={classes.containMain}>
										<div className={classes.containBox}>
											<div className={classes.contentList}>
												<Typography variant='h4'>Welcome to LYFnGO flash</Typography>
												<Typography variant='h6' style={{ fontSize: 14, color: '#707680' }}>
													Your 14-days trial has been activated
												</Typography>
												<div className={classes.btnCont}>
													<Button type='submit' variant='contained' onClick={navigateTo}>
														Let's go
													</Button>
												</div>
											</div>
										</div>
									</div>
									<div className={classes.containMain2}>
										<div className={classes.containBox2}>
											{_.map(data, (item) => {
												return (
													<div className={classes.contenImage}>
														<div className={classes.background} style={{ backgroundImage: `url(${item?.imageUrl})` }}></div>
														<div className={classes.contentHead}>
															<Typography variant='h5' style={{ fontSize: '13px', color: '#707680' }}>
																{item?.mastModuleName}
															</Typography>
														</div>
													</div>
												)
											})}
										</div>
										<div className={classes.btnMobile}>
											<Button type='submit' variant='contained' color='secondary' onClick={navigateTo}>
												Let's go
											</Button>
										</div>
									</div>
								</div>
							</div>
						)}
					</Main>
				</Grid>
			</Grid>
		</Root>
	)
}

export default ModuleSelection
