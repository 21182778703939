import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	verifiedMail: '',
	verifiedMobile: secureLocalStorage.getItem('verifiedNumber') || '',
	imageId: '',
}

const verificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_VERIFIEDMAIL: {
			return {
				...state,
				verifiedMail: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_VERIFIEDMOBILENUMBER: {
			return {
				...state,
				verifiedMobile: action.payload,
			}
		}
		case ACTION_TYPES.UPDATE_IMAGEID: {
			return {
				...state,
				imageId: action.payload,
			}
		}
		default:
			return state
	}
}

export default verificationReducer
