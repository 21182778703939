import React, { useCallback, useEffect, useState, useRef } from 'react'
import useStyles from './style'
import { Box, Button, ButtonGroup, Grid, Skeleton, Typography, Tooltip } from '@mui/material'
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import SubSearch from '../../../../../../app/sharedComponents/SubSearch'
import _ from 'lodash'
import history from '@history'
import MembershipApi from '../../../../../../services/Settings/Membership/api'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import { DataGridPro } from '@mui/x-data-grid-pro'

const headerButtons = [
	{ id: 'today', title: 'Today' },
	{ id: 'tommrow', title: 'Tomorrow' },
	{ id: 'sevendays', title: '7 Days' },
]

function MemberShipDetails() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [membershipData, setMembershipData] = useState([])
	const [pageSize, setPageSize] = useState(5)
	const [pageParams, setPageParams] = useState({ page: 0, customerName: null })
	const [totalCount, setTotalCount] = useState(0)
	const cancelExecutor = useRef()
	const [membershipSearchValue, setMembershipSearchValue] = useState('')
	const [membershipSearchParams, setMembershipSearchParams] = useState({
		customerName: null,
	})
	const [period, setPeriod] = useState('sevendays')
	const [loading, setLoading] = useState(false)
	const mastTentUuid = useSelector((state) => state?.Authentication?.mastuuid || '')
	// const custUuid = useSelector((state) => state?.RoleIdReducer?.cust_Uuid)
	const groupSelection = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const isCare = _.isEqual(groupSelection, 'health care')

	const onSearch = _.debounce(
		(value) => {
			setMembershipSearchParams({
				...membershipSearchParams,
				customerName: value,
			})
		},
		[300]
	)

	const handleChangePage = (event) => {
		setPageParams({ page: event })
	}

	const getMembershipDetails = useCallback(() => {
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				setMembershipData(res?.data?.data)
				setLoading(false)
				setTotalCount(res?.data?.totalCount)
			}
		}
		const onFailure = (err) => {
			setMembershipData([])
			setLoading(false)
		}
		!_.isEmpty(mastTentUuid) &&
			MembershipApi.getMembershipListForDashboard(
				mastTentUuid,
				{ limit: pageSize, page: pageParams?.page, ...membershipSearchParams, particularDate: period },
				cancelExecutor
			).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, mastTentUuid, membershipSearchParams, pageParams, pageSize, period])

	useEffect(() => {
		getMembershipDetails()
	}, [getMembershipDetails])

	const handleOnCellClick = (params) => {
		history.push(`/clientinfo/${params?.row?.custUuid}?tabId=membership`)
		dispatch(customer_Uuid(`${params?.row?.custUuid}`))
	}
	const tableColumn = [
		{
			field: 'patientname',
			headerName: `${isCare ? 'Patient' : 'Client'} name`,
			// sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.patientname}>
						<Typography align='right' className='ml-12' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							{params.row.patientname}
						</Typography>
					</Tooltip>
				)
			},
		},
		{
			field: 'mobilenumber',
			headerName: 'Mobile number',
			// sortable: false,
			width: 150,
		},
		{
			field: 'membershipName',
			headerName: 'Membership name',
			// sortable: false,
			width: 240,
		},
		{
			field: 'dateOfJoin',
			headerName: 'Date of join',
			// sortable: false,
			width: 120,
			renderCell: (params) => {
				return <Typography>{(params?.row?.dateOfJoin).split(' ')[0]}</Typography>
			},
		},
		{
			field: 'dateOfExpiry',
			headerName: 'Date of expiry',
			// sortable: false,
			width: 130,
			renderCell: (params) => {
				return <Typography>{(params?.row?.dateOfExpiry).split(' ')[0]}</Typography>
			},
		},
		{
			field: 'lastReminder',
			headerName: 'Last Reminder',
			// sortable: false,
			width: 180,
			renderCell: (params) => {
				return (
					<Grid container xs={12}>
						<Grid xs={6}>
							<Typography>Email</Typography>
							<Typography style={{ color: '#727272' }}>{params?.row?.lastMailReminder || '-'}</Typography>
						</Grid>
						<Grid xs={6}>
							<Typography>SMS</Typography>
							<Typography style={{ color: '#727272' }}>{params?.row?.lastSmsReminder || '-'}</Typography>
						</Grid>
					</Grid>
				)
			},
		},
		{
			field: 'DurationSession',
			headerName: 'Duration & Sessions',
			// sortable: false,
			width: 240,
			renderCell: (params) => {
				return (
					<Grid container xs={12} spacing={1}>
						<Grid xs={6}>
							<Typography>{`${params?.row?.validityPeriod} month(s)`} </Typography>
						</Grid>
						<Grid xs={6}>
							<Typography>{params?.row?.DurationSession && `${params?.row?.DurationSession} session(s)`}</Typography>
						</Grid>
					</Grid>
				)
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			// sortable: false,
			width: 120,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.status === 'Expired' ? (
							<Typography style={{ color: 'red' }}>{params?.row?.status}</Typography>
						) : (
							<Typography style={{ color: 'green' }}>{params?.row?.status}</Typography>
						)}
					</>
				)
			},
		},
	]

	const SkeletonColumns = [
		{
			field: 'patientname',
			headerName: `${isCare ? 'Patient' : 'Client'} name`,
			width: 180,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
		{ field: 'mobilenumber', headerName: 'Mobile number', width: 150, renderCell: (params) => <Skeleton width='100%' height='40px' /> },

		{
			field: 'membershipName',
			headerName: 'Membership name',
			width: 240,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},

		{
			field: 'dateOfJoin',
			headerName: 'Date of join',
			width: 120,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
		{
			field: 'dateOfExpiry',
			headerName: 'Date of expiry',
			width: 130,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
		{
			field: 'lastReminder',
			headerName: 'Last Reminder',
			width: 180,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
		{
			field: 'DurationSession',
			headerName: 'Duration & Sessions',
			width: 240,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			renderCell: (params) => <Skeleton width='100%' height='40px' />,
		},
	]

	const tableRow = _.map(membershipData, (item, index) => ({
		id: index,
		patientname: item?.cust_name,
		mobilenumber: item?.customer_mobile_number,
		membershipName: item?.membership_name,
		dateOfJoin: item?.assigned_on,
		dateOfExpiry: item?.expired_on,
		lastMailReminder: item?.last_mail_reminder,
		lastSmsReminder: item?.last_sms_reminder,
		DurationSession: item?.total_session,
		thumNail: item?.cust_dp_thumbnail_file_path,
		validityPeriod: item?.validity_period,
		custUuid: item?.cust_uuid,
		status: item?.total_days,
	}))

	const SkeletonRows = _.map(_.range(5), (item, idx) => ({
		id: idx,
		patientname: '',
		mobilenumber: '',
		membershipName: '',
		dateOfJoin: '',
		dateOfExpiry: '',
		status: '',
	}))

	function CustomToolbar() {
		return (
			<Box
				sx={{
					'& .MuiButton-root': {
						background: '#0062DD !important',
						color: '#fff !important',
						':hover': {
							background: '#0062DD',
							color: '#fff !important',
						},
					},
					padding: '6px',
				}}
			>
				<GridToolbarColumnsButton />
			</Box>
		)
	}
	return (
		<div className={classes.root}>
			<Grid xs={12} container className={classes.sectionHead}>
				<Grid xs={4} item>
					<Typography variant='h6'>Membership expiry</Typography>
				</Grid>
				<Grid xs={8} item className={classes.sectionRight}>
					<div className={classes.select_payment_type}>
						<SubSearch
							value={membershipSearchValue}
							onChange={(e, value) => {
								setMembershipSearchValue(e.target.value)
								onSearch(e.target.value)
							}}
							placeholder={`Search ${isCare ? 'patient' : 'client'} list`}
						/>
					</div>
					<ButtonGroup color='primary' aria-label='medium' className={classes.grouped_button}>
						{headerButtons.map((item) => (
							<Button
								key={item.id}
								className={period === item.id ? classes.button_active : classes.button}
								style={{ borderRadius: 18, transition: '0.7s all' }}
								onClick={() => setPeriod(item.id)}
							>
								{item.title}
							</Button>
						))}
					</ButtonGroup>
				</Grid>
			</Grid>

			<Box className={classes.container}>
				{_.isEqual(loading, true) && (
					<div style={{ height: '55vh' }}>
						<DataGrid
							columns={SkeletonColumns}
							rows={SkeletonRows}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							paginationModel={pageParams}
							rowsPerPageOptions={[5, 10, 25]}
							pagination={true}
							paginationMode='server'
							onPageChange={handleChangePage}
							disableColumnMenu
							disableSelectionOnClick
							rowCount={totalCount}
						/>
					</div>
				)}
				{!_.isEmpty(tableRow) ? (
					<div style={{ height: '55vh' }}>
						<DataGrid
							columns={tableColumn}
							rows={tableRow}
							pageSize={pageSize}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							paginationModel={pageParams}
							rowsPerPageOptions={[5, 10, 25]}
							pagination={true}
							paginationMode='server'
							onPageChange={handleChangePage}
							// disableColumnMenu
							components={{
								Toolbar: CustomToolbar,
							}}
							disableSelectionOnClick
							onCellClick={handleOnCellClick}
							rowCount={totalCount}
						/>
					</div>
				) : (
					<div className={classes.emptyRecordBox}>
						<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/No_Membership_Expiry.png`} alt='No membership  found' />
					</div>
				)}
			</Box>
		</div>
	)
}

export default MemberShipDetails
