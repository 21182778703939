import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button, Box } from '@mui/material'
import MenuSelectionApi from 'services/ProfileSetup/MenuSelection/api'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'
import profileStatusApi from 'services/ProfileSetup/profileStatus/api'
import { useState } from 'react'
import { useEffect } from 'react'
import themesConfig from 'app/fuse-configs/themesConfig'
import { ArrowBackIos } from '@mui/icons-material'
import SuccessModal from './SuccessModal'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		background: themesConfig.lyfngo.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
	},
	goBack: {
		background: themesConfig.lyfngo.palette.tertiary.main,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '18px 0',
		height: '100%',
		width: '50px',
		cursor: 'pointer',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '16px 24px',
		width: '100%',
	},
	headerText: {
		color: themesConfig.lyfngo.palette.light.main,
		fontSize: '16px',
		fontWeight: 500,
	},
	publishBtn: {
		'& .MuiButton-root': {
			background: '#deedff',
			borderRadius: '50px',
			padding: '6px 24px',
			'&:hover': {
				background: '#cfe4fc',
			},
		},
	},
}))

export default function ProgressDetail(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { history } = props
	const state = useSelector((state) => state.Authentication)
	const { mastuuid, uuid } = state
	const [profileStatus, setProfileStatus] = useState({})
	const [menuData, setMenuData] = useState([])
	const groupuser = useSelector((state) => state?.GroupUser)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const { groupUserName } = groupuser

	const [modalOpen, setModalOpen] = useState(false)

	const currentUrl = new URL(window.location.href)
	const tabId = currentUrl.searchParams.get('tabId')

	const navigateTo = () => {
		history.goBack()
	}

	const getMenuData = useCallback(() => {
		const onSuccess = (res) => {
			setMenuData(res?.data?.data)
		}
		const onFailure = (err) => {
			setMenuData([])
		}
		MenuSelectionApi.getMicrositeMenuData(mastuuid).then(onSuccess, onFailure)
	}, [mastuuid])

	const postMenuData = () => {
		const body = menuData?.map((item) => item?.micrositeMenuUuid)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
			}
		}
		const onFailure = (err) => {
			console.log(err)
		}
		MenuSelectionApi.postMicrositeMenuData(body, mastuuid, profileHeaderUuid).then(onSuccess, onFailure)
	}

	const submitDetails = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			profilePercentage: profileStatus?.profilePercentage,
			profileStatusId: profileStatus?.profileStatusId,
			progressStatus: 'SUB',
			status: profileStatus?.status,
			tentId: mastuuid,
			tentUserId: !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : uuid,
			profileHeaderUuid: profileHeaderUuid,
		}
		setModalOpen(true)
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEqual(groupUserName, 'owner')) {
				postMenuData()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}

		profileStatusApi.updateProfileStatus(body).then(onSuccess, onFailure)
	}

	const closeSuccessModal = () => {
		setModalOpen(false)
		history.push(`/profile`)
	}

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setProfileStatus(res?.data?.data || {})
		}
		const onFailure = (err) => {
			setProfileStatus({})
			dispatch(hideLoader())
		}
		let isNotOwner = !_.isEqual(groupUserName, 'owner')
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		profileStatusApi.getProfileStatus(isTent, mastuuid, isNotOwner).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupUserName, mastuuid, profileHeaderUuid, uuid])

	useEffect(() => {
		_.isEqual(groupUserName, 'owner') && getMenuData()
	}, [getMenuData, groupUserName])

	return (
		<Box className={classes.mainContainer}>
			<SuccessModal open={modalOpen} closeSuccessModal={closeSuccessModal} />
			<div className={classes.goBack} onClick={navigateTo}>
				<ArrowBackIos size='small' style={{ cursor: 'pointer', color: '#fff', marginLeft: 1 }} />
			</div>
			<div className={classes.header}>
				<div className={''}>
					<Typography className={classes.headerText}>Microsite</Typography>
				</div>
				{profileStatus?.progressStatus !== 'LIV' && (
					<div className={classes.publishBtn}>
						{/* <Button variant='contained'>Publish Now</Button> */}
						<Button variant='contained' onClick={submitDetails} disabled={_.isEqual(tabId, 'microsetup') || _.isEqual(tabId, 'personalDetails')}>
							Publish Now
						</Button>
					</div>
				)}
			</div>
		</Box>
	)
}
