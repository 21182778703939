import axios from 'axios'
// import { tentUuid } from 'services/patient/roleBasedUuid/action'
import { API_ENDPOINTS } from '../../../../constants/index'
import { csrf } from 'utils'
import secureLocalStorage from 'react-secure-storage'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getCatlogItem: (mastuuid) => {
		return axios.get(API_ENDPOINTS.DRUG_CATLOG_GET, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},

	getInvoice: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_INVOICE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getReceiptData: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_RECEIPT, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getPaymentModes: (mastuuid) => {
		return axios.get(API_ENDPOINTS.PAYMENT_MODES, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getTaxModes: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_BILLING, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getSpecialiyBillingDetails: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.GET_SPECIALITY_BILLING_DATA, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid } },
		})
	},
	// SAVE_BILLING_ORDER_ITEM

	saveBillingOrderItem: (data, appointmentUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_BILLING_ORDER_ITEM}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { appointmentUuid } },
		})
	},
	savingInvoiceOrder: (data, billingOrderUuid, tentUserUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_INVOICE_ORDER_ITEM}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { billingOrderUuid, tentUserUuid } },
		})
	},
	savePaymentOrderItem: (data, appointmentUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_PAYMENT_ORDER_ITEM}`, data, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { appointmentUuid } },
		})
	},
	getBillingHistory: (custUuid, params) => {
		return axios.get(API_ENDPOINTS.GET_BILLING_HISTORY, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { custUuid } },
			params: { ...params },
		})
	},
	getShowBill: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.GET_SHOW_BILL, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid } },
		})
	},
	getParamaBill: (appointmentUuid) => {
		return axios.get(API_ENDPOINTS.GET_SHOW_PARMA_BILL, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid } },
		})
	},
	saveBillingDetails: (publicKey, ivKey, appointmentUuid, custUuid, params) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.SAVE_BILLING}`, result, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				Custuuid: custUuid,
				'Content-Type': 'application/json',
				path: { appointmentUuid },
			},
			params: { ...params },
		})
	},
	saveDraftBillingDetails: (body, appointmentUuid, params) => {
		// const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.SAVE_DRAFT_BILLING}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { appointmentUuid } },
			params: { ...params },
		})
	},
	onlineBillingDetails: (publicKey, ivKey, custUuid) => {
		const result = { data: publicKey }
		const deviceToken = secureLocalStorage.getItem('deviceToken')
		return axios.post(`${API_ENDPOINTS.ONLINE_ADD_BILLING}`, result, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				Custuuid: custUuid,
				deviceToken: deviceToken,
				key: `${ivKey}`,
			},
		})
	},
	upiBillingDetails: (publicKey, ivKey) => {
		const result = { data: publicKey }
		const deviceToken = secureLocalStorage.getItem('deviceToken')
		return axios.post(`${API_ENDPOINTS.UPI_BILLING}`, result, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', deviceToken: deviceToken, key: `${ivKey}` },
		})
	},

	savePendingDetails: (data, tentUuid, custUuid) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_BILLING}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid, path: { tentUuid } },
		})
	},
	cancelPaymentDetails: (data, uuid, custUuid) => {
		return axios.post(`${API_ENDPOINTS.CANCEL_PAYMENT}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid, path: { uuid } },
		})
	},
	cancelPaymentInvoicee: (data, params, custUuid) => {
		return axios.put(`${API_ENDPOINTS.CANCEL_INVOICE}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid },
			params: { ...params },
		})
	},
	confirmUpiPayment: (data) => {
		return axios.put(`${API_ENDPOINTS.UPI_PAYMENT_CONFIRMATION}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json' },
		})
	},
	saveRefundDetails: (data, tentUuid, custUuid) => {
		return axios.put(`${API_ENDPOINTS.SAVE_REFUND_ORDER_ITEM}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid, path: { tentUuid } },
		})
	},
	UpdateDraftBillingDetails: (body, billingOrderUuid, params) => {
		// const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.UPDATE_DRAGT_BILLING}`, body, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { billingOrderUuid } },
			params: { ...params },
		})
	},
	saveOnlinePayment: (data, custUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVEONLINEPAYMENT}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid },
		})
	},
	getBillingDropdownData: (appointmentUuid, mastTentUuid, params, cancelExecutor) => {
		return axios.get(`${API_ENDPOINTS.GET_BILLING_DROPDOWN_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid, mastTentUuid } },
			params: { ...params },
		})
	},
	getBillingProcedureDropdownData: (mastTentUuid, custUuid, params, cancelExecutor) => {
		return axios.get(`${API_ENDPOINTS.GET_BILLING_PROCEDURE_DETAILS}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid, custUuid } },
			params: { ...params },
		})
	},
	getBillingDataForPharmacist: (mastTentUuid) => {
		return axios.get(`${API_ENDPOINTS.GET_BILLING_DROPDOWN_DETAILS_PHARMACIST}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
		})
	},
	getPrepopulatePrescriptionData: (appointmentUuid, dataType) => {
		return axios.get(`${API_ENDPOINTS.GET_PRESCRIPTION_BILL_DATA}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { appointmentUuid, dataType } },
		})
	},

	saveBillingPharmacistDetails: (data, appointmentUuid, params) => {
		return axios.post(`${API_ENDPOINTS.SAVE_BILLING_PHARMACIST}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { appointmentUuid } },
			params: { ...params },
		})
	},
	saveAllBillingDetails: (data, appointmentUuid, params, custUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_ALL_BILLING}`, data, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', Custuuid: custUuid, path: { appointmentUuid } },
			params: { ...params },
		})
	},
	// Google conatact import
	getImportContacts: (params) => {
		return axios.get(`${API_ENDPOINTS.GOOGLE_IMPORT_CONATACT}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
			params: { ...params },
		})
	},
}
