import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles'
import { Typography, TextField, useMediaQuery, IconButton, Badge, Tooltip, Avatar, Box, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { InputAdornment } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SearchIcon from '@mui/icons-material/Search'
import AddProfile from './AddProfile'
import profiletable from '../../../../services/ProfileSetup/ProfileTable/api'
import { useSelector, useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import { useTheme } from '@emotion/react'
import themesConfig from 'app/fuse-configs/themesConfig'
import profileStatusApi from '../../../../services/ProfileSetup/profileStatus/api'
import { setProfileStatus } from 'services/ProfileSetup/list/actions'
import { groupUserNameAction, individualTentUserId } from 'services/ProfileSetup/groupUser/actions'
import secureLocalStorage from 'react-secure-storage'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
import { styled } from '@mui/material/styles'
import { getResponseMessage } from 'utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import ProfileHeader from './ProfileHeader'
import { EditNote } from '@mui/icons-material'
const useStyles = makeStyles((theme) => ({
	mainConainer: {},
	topContainer: {
		position: 'sticky',
		top: '64px',
		zIndex: 66,
		// position: 'fixed',
	},
	profileTable: {
		width: '100%',
		paddingInline: 28,
		paddingBlock: 10,

		'& .MuiDataGrid-iconSeparator ': {
			display: 'none',
		},
		'& .MuiDataGrid-columnHeaderTitleContainer': {
			padding: '0px 0px',
		},
		'& .MuiDataGrid-dataContainer': {
			backgroundColor: '#fff',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '600',
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor: '#C3EAFF',
			border: 'none',
		},
		'& .MuiDataGrid-columnHeaderWrapper': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: '#EBF4FF',
		},
		'& .MuiDataGrid-row:nth-child(even)': {
			backgroundColor: '#fff',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF',
		},
		'& .MuiDataGrid-cell': {
			border: 'none',
			color: '#000',
		},
		'& .MuiDataGrid-root': {
			border: 'none',
			'& .MuiDataGrid-cell--withRendere': {
				[theme.breakpoints.down('sm')]: {
					display: 'block !important',
				},
			},
			'& .MuiDataGrid-footerContainer': {
				borderBottomLeftRadius: 8,
				borderBottomRightRadius: 8,
				background: '#ffffff',
				border: 'none',
			},
		},
		'& .MuiDataGrid-row': {
			cursor: 'pointer',
			background: '#fff',
		},
		[theme.breakpoints.down('md')]: {
			height: 200,
			width: '100%',
			paddingInline: 12,
			paddingBlock: 10,
		},
	},
	wrapper: {
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
	},
	imageContainer: {
		width: '100%',
		height: '68vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconEdit: {
		border: '1px solid #0062DD',
		color: ' #0062DD',
		'&:hover': {
			background: '#0062DD2e',
		},
		'&:hover .MuiSvgIcon-root': {
			color: '#0062DD',
		},
	},
	tableAboveSection: {
		// paddingBlock: 16,
		position: 'sticky',
		width: '100%',
		top: 0,
		left: 0,
		paddingInline: 28,
		background: themesConfig.greyDark.palette.primary.main,
		[theme.breakpoints.down('sm')]: {},
	},
	dashboardText: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
		color: '#2A2A2A',
		'& .MuiSvgIcon-root': {
			color: '#fff',
		},
	},
	tableAboveSectionTwo: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBlock: 12,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	tableAboveSectionTwoSub: {
		display: 'flex',
		gap: 12,
		paddingInline: 8,
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			width: 45,
			height: 40,
		},
	},
	profileText: {
		fontSize: 16,
		fontWeight: 600,
		color: '#fff',
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
	profileEstablishment: {
		fontSize: 12,
		'& .MuiSvgIcon-root': {
			width: 20,
			height: 20,
			color: '#fff',
		},
		'& .MuiInputLabel-root': {
			top: -19,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
	},
	tableAboveSectionTwoSubTwo: {
		display: 'flex',
		alignItems: 'center',
		gap: 20,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 4,
		},
	},
	addProfile: {
		display: 'flex',
		alignItems: 'center',
		gap: 6,
		border: '1px solid #fff',
		background: '#fff',
		color: '#414a58',
		borderRadius: 22,
		cursor: 'pointer',
		padding: '6px 15px',
		'& .MuiButton-root:hover': {
			backgroundColor: '#fff',
		},
		'& .MuiSvgIcon-root': {
			color: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
			'& .MuiSvgIcon-root': {
				width: 30,
				height: 20,
			},
		},
		[theme.breakpoints.down('md')]: {
			'& .MuiSvgIcon-root': {
				width: 30,
				height: 20,
			},
		},
	},
	textfieldStyle: {
		position: 'relative',
		'& .MuiOutlinedInput-input': {
			padding: 0,
		},
		'& .MuiOutlinedInput-root': {
			background: '#fff',
			borderRadius: 8,
			padding: '10px 15px',
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		[theme.breakpoints.down('md')]: {},
	},
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},

	input: {
		[theme.breakpoints.down('sm')]: {
			width: '190px',
			height: '36px',
			borderRadius: 20,
			backgroundColor: '#fff',
		},
		[theme.breakpoints.down('md')]: {
			width: '280px',
		},
	},
	establishmentName: {
		'& .MuiInput-input': {
			color: '#fff',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
	progress: {
		marginLeft: '10px',
	},
	main: {
		background: '#f6f7f9',
		borderRadius: '22px',
		border: '1px solid #f6f7f9',
		height: '6px',
		width: '140px',
	},
	low: {
		backgroundColor: '#FED664',
		height: '4px',
	},
	high: {
		background: '#4CAF50',
		height: '4px',
	},
	nodata: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		height: 500,
		fontSize: '24px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	badgeContainer: {
		'& .MuiBadge-badge': {
			boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				content: '""',
			},
		},
		'& .MuiBadge-colorPrimary': {
			backgroundColor: '#4CAF50',
		},
		'& .MuiBadge-colorSecondary': {
			backgroundColor: '#FED664',
		},
	},
	addProfileLink: {
		fontSize: '24px',
		fontWeight: 600,
		textAlign: 'center',
		cursor: 'pointer',
	},
}))

export default function ProfileManagement({ history }) {
	const classes = useStyles()
	const [openModal, setOpenModal] = useState(false)
	const dispatch = useDispatch()
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const [profileEditData, setProfileEditData] = useState({})
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [loading, setLoading] = useState(true)
	const [listData, setListData] = useState([])
	const [userProfileDetails, setUserProfileDetails] = useState({})
	const [isOwnerLive, setOwnerLive] = useState(false)
	const [page, setPage] = useState(10)
	const onClickAddProfile = () => {
		setOpenModal(true)
	}
	//Custom switch
	const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform: 'translateX(16px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			},
			'&.Mui-disabled .MuiSwitch-thumb': {
				color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 22,
			height: 22,
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	}))
	const state = useSelector((state) => state.Authentication)
	const { mastuuid } = state
	const onClickEdit = (row, e) => {
		let countryCode = row?.countryCode
		e.stopPropagation()
		dispatch(
			groupUserNameAction({
				groupUserName: _.toLower(row?.role),
			})
		)
		dispatch(individualTentUserId(row?.action))
		secureLocalStorage.setItem('groupUserName', _.toLower(row?.role) || '')
		secureLocalStorage.setItem('profileCurrentTentUserId', row?.action || '')
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			let response = res?.data?.data
			setProfileEditData(response)
			response.countryCode = countryCode
			response.isEditMode = true
			dispatch(setProfileStatus(response || {}))
			secureLocalStorage.setItem('profileStatus', JSON.stringify(response || {}))
			if (!_.isEqual(_.toLower(row?.role), 'owner')) {
				history.push('/profilesetup?tabId=personalDetails')
			} else {
				history.push('/profilesetup?tabId=microsetup')
			}
			// const { action } = row
			// history.push(`profilesetup/edit/${action}/${mastuuid}`)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		const isNotOwner = !_.isEqual(_.toLower(row?.role), 'owner')
		profileStatusApi.getProfileStatus(row?.headerId, mastuuid, isNotOwner, { isOwner: row?.isOwner }).then(onSuccess, onFailure)
	}

	// const onClickROw = (row, e) => {
	// 	e.stopPropagation()
	// 	dispatch(
	// 		groupUserNameAction({
	// 			groupUserName: _.toLower(row?.role),
	// 		})
	// 	)
	// 	dispatch(individualTentUserId(row?.action))
	// 	secureLocalStorage.setItem('groupUserName', _.toLower(row?.role) || '')
	// 	dispatch(showLoader('Loading please wait...'))
	// 	const onSuccess = (res) => {
	// 		dispatch(hideLoader())
	// 		dispatch(setProfileStatus(res?.data?.data || {}))
	// 		secureLocalStorage.setItem('profileStatus', JSON.stringify(res?.data?.data || {}))
	// 		history.push('/profilesetup?tabId=personalDetails')
	// 		_.isEqual(res?.data?.data?.progressStatus, 'IPG')
	// 			? history.push(`/profilestatus/view/${uuid}/${mastuuid}`)
	// 			: _.isEqual(res?.data?.data?.progressStatus, 'SUB') || _.isEqual(res?.data?.data?.progressStatus, 'RIP')
	// 			? history.push(`/profileunderverification/view/${uuid}/${mastuuid}`)
	// 			: _.isEqual(res?.data?.data?.progressStatus, 'APP')
	// 			? history.push(`/readytogolive/view/${uuid}/${mastuuid}`)
	// 			: _.isEqual(res?.data?.data?.progressStatus, 'LIV') && history.push(`/readytogolive/view/${uuid}/${mastuuid}`)
	// 	}
	// 	const onFailure = (err) => {
	// 		dispatch(hideLoader())
	// 	}
	// 	const isNotOwner = !_.isEqual(_.toLower(row?.role), 'owner')
	// 	profileStatusApi.getProfileStatus(uuid, mastuuid, isNotOwner).then(onSuccess, onFailure)
	// }

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: isMobile ? 100 : 190,
			// sortable: true,
			headerAlign: 'left',
			renderCell: (params) => {
				return (
					<Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
						<Avatar src={''} sx={{ width: 24, height: 24 }} size='small' alt={params?.row?.name.charAt(0)} />
						<Tooltip title={params?.row?.name} arrow>
							<Typography variant='body1' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{params?.row?.name}
							</Typography>
						</Tooltip>
					</Box>
				)
			},
		},
		{
			field: 'email',
			headerName: 'Email',
			minWidth: isMobile ? 100 : 190,
			// sortable: true,
			renderCell: (params) => {
				return (
					<>
						<Tooltip title={params?.row?.email} arrow>
							<Typography variant='body1' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{params?.row?.email}
							</Typography>
						</Tooltip>
					</>
				)
			},
		},
		{
			field: 'mobile',
			headerName: 'Mobile',
			// sortable: true,
			minWidth: isMobile ? 100 : 190,
		},
		{
			field: 'role',
			headerName: 'Role',
			// sortable: true,
			minWidth: isMobile ? 100 : 190,
		},
		// {
		// 	field: 'progress',
		// 	headerName: 'Progress',
		// 	sortable: true,
		// 	minWidth: isMobile ? 100 : 190,
		// 	renderCell: (params) => {
		// 		return (
		// 			<>
		// 				<div className={classes.main}>
		// 					<div className={params?.row?.progress < 100 ? classes.low : classes.high} style={{ width: `${_.ceil(params?.row?.progress)}%` }}></div>
		// 				</div>
		// 				<Typography className={classes.progress}>{_.ceil(params?.row?.progress)}%</Typography>
		// 			</>
		// 		)
		// 	},
		// },
		{
			field: 'status',
			headerName: 'Status',
			// sortable: true,
			minWidth: isMobile ? 100 : 190,
			renderCell: (params) => {
				return (
					<>
						{params?.row?.status === true ? (
							<>
								<Badge
									className={classes.badgeContainer}
									color='primary'
									overlap='circular'
									anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
									variant='dot'
								/>
								<Typography className={classes.progress}>{'Live'}</Typography>
							</>
						) : (
							<>
								<Badge
									className={classes.badgeContainer}
									color='secondary'
									overlap='circular'
									anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
									variant='dot'
								/>
								<Typography className={classes.progress}>{'Not Live'}</Typography>
							</>
						)}
					</>
				)
			},
		},
		{
			field: 'isActive',
			headerName: 'Active',
			minWidth: isMobile ? 100 : 260,
			renderCell: (params) => {
				return (
					<div className={classes.tableRow}>
						<FormGroup>
							<FormControlLabel
								control={<IOSSwitch sx={{ m: 1 }} defaultChecked={params?.row?.isActive} onChange={(e) => saveActiveStatus(params?.row, e)} />}
								label={params?.row?.isActive ? 'Active' : 'InActive'}
							/>{' '}
						</FormGroup>
					</div>
				)
			},
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Tooltip title='Edit' arrow>
							<IconButton size='small' className={classes.iconEdit} onClick={(e) => onClickEdit(params?.row, e)}>
								<EditNote />
							</IconButton>
						</Tooltip>
					</>
				)
			},
			minWidth: 100,
		},
	]

	const rows = listData
		.filter((item) => !item.isOwner)
		.map(
			(item, index) =>
				({
					id: index + 1,
					name: item?.name ? item?.name : '',
					email: item?.email,
					mobile: item?.mobile,
					role: item?.role,
					status: item?.status,
					action: item?.tentUserUuid,
					headerId: item?.profileHeaderUuid,
					userLogo: item?.docDriveUuid,
					isActive: item?.isActive,
					isOwner: item?.isOwner,
					countryCode: item?.tentUserCountryCode,
				} || [])
		)

	const getProfileTablelist = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		setLoading(true)
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setLoading(false)
				let tempArr = _.map(res?.data?.data?.userProfileDetails || [], (data, idx) => ({ ...data, id: idx }))
				setListData(tempArr)
				setUserProfileDetails(res?.data?.data)
				setOwnerLive(res?.data?.data?.isOwnerLive)
			}
		}
		const onFailure = (err) => {
			setLoading(false)
			dispatch(hideLoader())
		}
		profiletable.getProfileTable(mastuuid).then(onSuccess, onFailure)
	}, [dispatch, mastuuid])
	const saveActiveStatus = (row, e) => {
		const body = {
			profileHeaderUuid: row?.headerId,
			isActive: e.target.checked ? true : false,
		}
		dispatch(showLoader('Updating please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				getProfileTablelist()
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		profileStatusApi.updateProfileToggleStatus(body).then(onSuccess, onFailure)
	}
	// const getPracticeListData = useCallback(() => {
	// 	dispatch(showLoader('Loading please wait...'))
	// 	const onSuccess = (res) => {
	// 		if (res.data.status === 'success') {
	// 			dispatch(hideLoader())
	// 			setPracticeList(res?.data?.data?.data)
	// 			secureLocalStorage.setItem('tentTypeId', res?.data?.data?.data[0]?.mast_tent_type_uuid)
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		dispatch(hideLoader())
	// 	}
	// 	profiletable.getPracticeList(uuid).then(onSuccess, onFailure)
	// }, [dispatch, uuid])

	// useEffect(() => {
	// 	if (!_.isEmpty(practicelist)) {
	// 		setPracticeName(practicelist[0])
	// 		dispatch(tenentNameAction(practicelist[0]?.practice_name))
	// 		secureLocalStorage.setItem('practicelist', practicelist[0]?.practice_name || '')
	// 	}
	// }, [dispatch, practicelist])

	// useEffect(() => {
	// 	getPracticeListData()
	// }, [getPracticeListData])

	useEffect(() => {
		getProfileTablelist()
	}, [getProfileTablelist])

	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})

	return (
		<div className={classes.mainConainer}>
			<div className={classes.topContainer}>
				<div className={classes.tableAboveSection}>
					{/* <Typography className={classes.dashboardText}>
					<Link className='font-normal' to='/dashboard' style={{ color: '#fff', textDecoration: 'none' }}>
						<ArrowBackIcon style={{ cursor: 'pointer' }} />
						Dashboard
					</Link>
				</Typography> */}
					<div className={classes.tableAboveSectionTwo}>
						<div className={classes.tableAboveSectionTwoSub}>
							{/* <img src='${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/clock.png' alt='clock' /> */}
							<div>
								<ModuleTitle>{currentModule?.mastMenuName || ''}</ModuleTitle>
							</div>
						</div>
						<div className={classes.tableAboveSectionTwoSubTwo}>
							<TextField
								fullWidth
								className={classes.textfieldStyle}
								placeholder='Search Name '
								sx={{ width: 250 }}
								type='text'
								name='search'
								id='search'
								InputProps={{
									className: classes.input,
									endAdornment: (
										<InputAdornment position='end'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
							></TextField>
							<div
								className={classes.addProfile}
								onClick={() => {
									onClickAddProfile()
									profileEditData.isEditMode = false
									dispatch(setProfileStatus(profileEditData || {}))
									secureLocalStorage.setItem('profileStatus', JSON.stringify(profileEditData || {}))
								}}
							>
								<span>
									<AddCircleIcon />
								</span>
								Add Profile
							</div>
						</div>
					</div>
					<AddProfile
						open={openModal}
						isOwnerLive={isOwnerLive}
						handleClose={() => {
							setOpenModal(false)
						}}
					/>
				</div>
				{_.isEmpty(listData) ? (
					<></>
				) : (
					<ProfileHeader listData={listData} row={rows} getProfileTablelist={getProfileTablelist} userProfileDetails={userProfileDetails} />
				)}
			</div>
			<div className={classes.profileTable}>
				<div className={classes.wrapper}></div>
				{_.isEqual(loading, false) && _.isEmpty(listData) ? (
					<div className={classes.imageContainer}>
						<div
							className=''
							onClick={() => {
								onClickAddProfile()
							}}
							style={{ cursor: 'pointer' }}
						>
							<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/Create_your_microsite.svg`} alt='No Data list ' width='400' height='400' />
							<Typography className={classes.addProfileLink}>Create your microsite</Typography>
						</div>
					</div>
				) : (
					_.isEqual(loading, false) &&
					_.isEmpty(rows) && (
						<div className={classes.imageContainer} style={{ height: '60vh' }}>
							<div
								className=''
								onClick={() => {
									onClickAddProfile()
								}}
								style={{ cursor: 'pointer' }}
							>
								<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/No_specialists_found_.svg`} alt='No Data list ' width='400' height='400' />
							</div>
						</div>
					)
				)}
				{_.isEqual(loading, false) && !_.isEmpty(rows) && (
					<DataGrid
						rows={rows}
						columns={columns}
						pageSize={page}
						rowsPerPageOptions={[10, 25, 50]}
						onPageSizeChange={(newPageSize) => setPage(newPageSize)}
						pagination
						// autoPageSize={true}
						autoHeight={true}
						disableSelectionOnClick
						disableColumnMenu={true}
					/>
				)}
			</div>
		</div>
	)
}
// import { Typography } from '@mui/material'
// import { makeStyles } from '@mui/styles'
// import React from 'react'
// const useStyles = makeStyles((theme) => ({
// 	container: {
// 		height: '100%',
// 		display: 'flex',
// 		flexDirection: 'column',
// 		gap: 6,
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 	},
// 	content: {
// 		width: '80%',
// 		height: '20%',
// 	},
// 	image: {
// 		width: '100%',
// 		height: '80%',
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 	},
// 	comingsoon: {
// 		width: '80%',
// 		maxHeight: '500px',
// 	},

// 	// 	profileTable: {
// 	// 		height: 600,
// 	// 		width: '100%',
// 	// 		paddingInline: 28,
// 	// 		paddingBlock: 10,

// 	// 		'& .MuiDataGrid-iconSeparator ': {
// 	// 			display: 'none',
// 	// 		},
// }))
// const ProfileManagement = () => {
// 	const classes = useStyles()
// 	return (
// 		<div className={classes.container}>
// 			<div className={classes.content}>
// 				<Typography align='center' variant='h4' pt={2}>
// 					Launching this feature in Q4 2023
// 				</Typography>
// 				<Typography align='center' variant='h6' pt={2}>
// 					You can create profile and publish them to thrive customers to reach you and book appointment seamlessesly in different mode of appointment
// 					such as in-person, online and home service.
// 				</Typography>
// 			</div>
// 			<div className={classes.image}>
// 				<img className={classes.comingsoon} src='https://ik.imagekit.io/lyfngo/web_b2b/assets/images/Coming_soon/profile.png' alt='coming soon' />
// 			</div>
// 		</div>
// 		// <Grid container justifyContent={'center'} align={'center'}>
// 		// 	<Grid item lg={12}>
// 		// 		<Typography>We are launching this feature in Q4 2022</Typography>
// 		// 	</Grid>
// 		// 	<Grid container>
// 		// 		<img src='https://ik.imagekit.io/lyfngo/web_b2b/assets/images/Coming_soon/profile.png' alt='coming soon' />
// 		// 	</Grid>
// 		// </Grid>
// 	)
// }

// export default ProfileManagement
