import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	MainContain: {
		display: 'flex',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	firstContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	contBox: {
		display: 'flex',
		alignItems: 'center',
	},
	secContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	bookingsContent: {
		paddingBlock: 10,
		fontSize: 14,
	},
	listDay: {},
	dayContain: {
		border: '1px solid #E9E8E9',
		borderRadius: 7,
		opacity: 1,
		'& .MuiListItemText-root': {
			marginTop: 12,
		},
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItem-root': {
			paddingRight: 0,
		},
	},
	headCont: {
		paddingInline: 12,
		paddingBloc: 6,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiTypography-root': {
			fontSize: 12,
		},
		MuiCheckbox: {
			colorSecondary: {
				color: '#custom color',
				'&$checked': {
					color: '#custom color',
				},
			},
		},
	},
	mainDays: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		paddingBlock: 12,
	},
	mainList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingInlineStart: 12,
		cursor: 'pointer',
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItem-root': {
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: 0,
		},
		'& .MuiListItemIcon-root': {
			minWidth: 36,
		},
	},
	thirdCont: {
		display: 'flex',
		flexDirection: 'column',
	},
	slotEveryHours: {
		display: 'flex',
		'& .MuiAutocomplete-root': {
			width: 240,
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	slotEveryHours2: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	autoMain: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	label: {
		marginTop: '2.5%',
	},
	slotautoMain: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	autoMainList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
	},
	listIemMain: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
	},
	contFlex: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		width: '100%',
		paddingInline: 24,
	},
	mainSlot: {
		display: 'flex',
		gap: 92,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 12,
		},
	},
	mainSlot1: {
		display: 'flex',
		gap: 12,
		alignItems: 'center',
		'& .muiltr-3sosnl-MuiInputBase-input-MuiOutlinedInput-input': {
			padding: '9.5px 10px',
			textAlign: 'center',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 0,
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
		},
	},
	mainTextFild: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	listItem: {
		display: 'flex',
		flexDirection: 'column',
		paddingBlock: 12,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	flexroot: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	finalList: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
	},
	addExperiencebtn: {
		cursor: 'pointer',
		color: 'red',
		padding: 0,
	},
	addExperiencebtn1: {
		cursor: 'pointer',
		color: '#000000',
		opacity: 0.8,
	},
	cancelExperience: {
		cursor: 'pointer',
	},
	mainButton: {
		display: 'flex',
		justifyContent: 'center',
	},
	AutocompleteField: {
		display: 'flex',
		'& .MuiAutocomplete-root': {
			width: 240,
		},
		[theme.breakpoints.down('md')]: {
			'& .MuiAutocomplete-root': {
				width: '100%',
			},
		},
	},
	PaymentField: {
		display: 'flex',
		flexDirection: 'row',
		color: '#000000',
		alignItems: 'center',
		opacity: 0.5,
		gap: 12,
	},
	ButtonField: {
		display: 'flex',
		gap: 8,

		'& .MuiButton-root': {
			color: '#000',
			borderRadius: '2px',
			border: '1px solid gray',
			'&.active': {
				backgroundColor: '#000',
				border: '1px solid gray',
				color: '#fff',
			},
		},
	},
	autocompleteDelete: {
		display: 'flex',
		maxWidth: '60%',
	},
	everySlot: {
		display: 'flex',
		gap: 6,
		alignItems: 'center',
		MuiCheckbox: {
			padding: 0,
		},
	},
	buttonYesHandler: {
		border: '1px solid grey',
		'& .active': {
			backgroundColor: '#2DCD7A',
		},
	},
	buttonNoHandler: {
		border: '1px solid grey',
		'& .active': {
			backgroundColor: '#2DCD7A',
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'space-between',
	},
}))

export default useStyles
