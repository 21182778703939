import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	isAccountCompleted: false,
	isUsermanagementCompleted: false,
	isSettingCalendarCompleted: false,
	isPriceCompleted: false,
	isDrugCompleted: false,
	isClinicalCompleted: false,
	isBillingCompleted: false,
	isCalendarCompleted: false,
	isPatientCardCompleted: false,
	isPatientMainCompleted: false,
	account: secureLocalStorage.getItem('persistTour') || {
		stepIndex: 0,
		run: false,
	},
	isWalthroughSkip: secureLocalStorage.getItem('skipTour') || false,
}

const WalkthroughReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.IS_ACCOUNT_COMPLETED: {
			return {
				...state,
				isAccountCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_USERMANAGEMNET_COMPLETED: {
			return {
				...state,
				isUsermanagementCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_SETTING_CALENDAR_COMPLETED: {
			return {
				...state,
				isSettingCalendarCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_PRICE_COMPLETED: {
			return {
				...state,
				isPriceCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_DRUG_COMPLETED: {
			return {
				...state,
				isDrugCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_CLINICAL_COMPLETED: {
			return {
				...state,
				isClinicalCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_BILLING_COMPLETED: {
			return {
				...state,
				isBillingCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_CALENDAR_COMPLETED: {
			return {
				...state,
				isCalendarCompleted: action.payload,
			}
		}
		case ACTION_TYPES.IS_PATIENT_CARD_COMPLETED: {
			return {
				...state,
				isPatientCardCompleted: action.payload,
			}
		}

		case ACTION_TYPES.IS_PATIENT_MAIN_COMPLETED: {
			return {
				...state,
				isPatientMainCompleted: action.payload,
			}
		}
		// isSettingCalendarCompleted
		case ACTION_TYPES.UPDATE_USER_MANAGEMENT_STEPS: {
			return {
				...state,
				userManagement: {
					...action?.payload,
				},
			}
		}
		case ACTION_TYPES.UPDATE_ACCOUNT_STEPS: {
			return {
				...state,
				account: {
					...action?.payload,
				},
			}
		}
		case ACTION_TYPES.UPDATE_SETTING_CALENDAR_STEPS: {
			return {
				...state,
				settingCalendar: {
					...action?.payload,
				},
			}
		}
		case ACTION_TYPES.IS_WALKTHROUGH_SKIPPED: {
			return {
				isAccountCompleted: true,
				isUsermanagementCompleted: true,
				isSettingCalendarCompleted: true,
				isPriceCompleted: true,
				isDrugCompleted: true,
				isclinicalCompleted: true,
				isBillingCompleted: true,
				isCalendarCompleted: true,
				isPatientCardCompleted: true,
				isPatientMainCompleted: true,
				isWalthroughSkip: true,
				account: {
					stepIndex: 0,
					run: false,
				},
				userManagement: {
					stepIndex: 0,
					run: false,
				},
				settingCalendar: {
					stepIndex: 0,
					run: false,
				},
			}
		}
		default:
			return state
	}
}

export default WalkthroughReducer
