import React, { useState } from 'react'
import useStyles from './style'
import { Typography, Grid, Divider, IconButton, Tooltip, Skeleton } from '@mui/material'
import { useDispatch } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import CancelBtn from '../../../../../sharedComponents/CancelButton'
import EditPlanModal from '../../Modal/EditPlan'
import Prompt from '../../../../../sharedComponents/Prompt'
import { showPrompt, closePrompt } from '../../../../../../../src/services/Prompt/actions'
import AddReminder from '../../Modal/AddReminder'
import AddArticle from '../../Modal/AddArticle'
import Accordionlist from '../Accordion'
import ButtonComp from 'app/sharedComponents/Button'
import _ from 'lodash'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, PERMISSION_TYPE, FEATURES_LIST } from '../../../../../../constants'

function PlanDetails({
	match,
	plans,
	planDetails,
	setPlanDetails,
	setListOne,
	stopAllPlan,
	updatePlanName,
	getPlanIndividualDetails,
	duplicatePlanName,
	getPlanList,
	deletePlanName,
	onArticleAddSuccess,
	onRemindAddSuccess,
	onReminderUpdateSuccess,
	loading,
	planName,
	listOne,
	count,
	setCount,
}) {
	const classes = useStyles()
	const [open1, setOpen1] = useState(false)
	const [open2, setOpen2] = useState(false)
	const [open4, setOpen4] = useState(false)
	const [planNameList, setPlanNameList] = useState([])
	const [reminderList, setReminderList] = useState([])
	const [articleList, setArticleList] = useState([])
	const [initialField, setInitialField] = useState('')
	const planUuid = _.get(match, 'params.id', '')
	const dispatch = useDispatch()
	//permissions
	const communicationPlanPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.COMMUNICATION_PLAN)
	const communicationReminderPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.REMINDER)
	const communicationArticlePerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.ARTICLE)

	const addReminder = () => {
		setOpen1(true)
	}
	const addArticle = () => {
		setOpen2(true)
	}
	const openEdit = (data) => {
		setOpen4(true)
		setInitialField(data)
	}
	// Start : copy plan
	const copyPlan = (data) => {
		duplicatePlanName(data)
	}
	// End : copy plan
	//start : Delete Plan
	const onClickDeltePlan = () => {
		deletePlanName()
	}
	//End : Delete Plan
	//Start : Stop all Plan //
	const stopPlan = () => {
		stopAllPlan(planUuid)
	}
	//End : Stop all Plan //

	return (
		<>
			<Grid container alignItems='center' justifyContent='space-between' className={classes.detailsHeader}>
				<Grid item lg={3} md={3} sm={3}>
					<Typography variant='h6' className={classes.commPlanText}>
						{loading ? <Skeleton variant='text' width='50%' /> : planDetails?.title}
					</Typography>
					<Typography
						variant='h6'
						className={
							planDetails?.planMappingPatientCount === 'never used'
								? classes.patientsStatusTextOne
								: planDetails?.planMappingPatientCount === 'not in use'
								? classes.patientsStatusTextTwo
								: classes.patientsStatusText
						}
					>
						{loading ? <Skeleton variant='text' width='50%' /> : planDetails?.planMappingPatientCount}
					</Typography>
				</Grid>
				<Grid item lg={5} md={3} sm={3}></Grid>
				<Grid item lg={2} md={3} sm={3}>
					{loading ? (
						<>
							<Skeleton variant='rectangular' style={{ height: 25, width: 25, margin: '0 4px', display: 'inline-block' }} />
							<Skeleton variant='rectangular' style={{ height: 25, width: 25, margin: '0 4px', display: 'inline-block' }} />
							<Skeleton variant='rectangular' style={{ height: 25, width: 25, margin: '0 4px', display: 'inline-block' }} />
						</>
					) : (
						<>
							{_.get(communicationPlanPerms, PERMISSION_TYPE.EDIT, false) && (
								<Tooltip title='Edit' arrow placement='top'>
									<IconButton id='editplanicon' name='editplanicon'>
										<EditIcon onClick={() => openEdit(planDetails?.title)} />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip title='Duplicate' arrow placement='top'>
								<IconButton id='copyplanicon' name='copyplanicon' onClick={() => copyPlan()}>
									<ContentCopyIcon />
								</IconButton>
							</Tooltip>
							{_.get(communicationPlanPerms, PERMISSION_TYPE.DELETE, false) && (
								<Tooltip title='delete' arrow placement='top'>
									<IconButton id='deleteplanicon' name='deleteplanicon'>
										<DeleteIcon
											onClick={() =>
												dispatch(
													showPrompt(
														'Are you sure want to delete?',
														'Yes',
														'No',
														() => {
															onClickDeltePlan()
															dispatch(closePrompt())
														},
														() => dispatch(closePrompt())
													)
												)
											}
										/>
									</IconButton>
								</Tooltip>
							)}
						</>
					)}
				</Grid>
				<Prompt />
				<EditPlanModal
					open4={open4}
					updatePlanName={updatePlanName}
					setListOne={setListOne}
					match={match}
					getPlanList={getPlanList}
					initialField={initialField}
					planNameList={planNameList}
					setPlanNameList={setPlanNameList}
					planName={planName}
					setOpen4={setOpen4}
				/>
				<Grid item lg={1.5} md={1.5} sm={3}>
					{loading ? (
						<Skeleton variant='rectangle' width={100} height={30}></Skeleton>
					) : (
						<CancelBtn
							id='stopallplanbtn'
							name='stopallplanbtn'
							disabled={planDetails?.planMappingPatientCount === 'never used' ? true : false}
							onClick={() =>
								dispatch(
									showPrompt(
										'Are you sure want to stop?',
										'Yes',
										'No',
										() => stopPlan(),
										() => dispatch(closePrompt())
									)
								)
							}
						>
							Stop for all
						</CancelBtn>
					)}
				</Grid>
				<Divider />
			</Grid>
			{/* start : add reminder and arcticle part */}
			<Grid container className={classes.addNotificationPart}>
				<Grid item lg={10} md={10}>
					<Grid container alignItems='center' spacing={1}>
						<Grid item lg={3} md={4} sm={3}>
							{loading ? <Skeleton variant='text' width='90%' height={30} /> : <Typography>What do you like to add?</Typography>}
						</Grid>
						<Grid item lg={2.5} md={3} sm={2.5}>
							{loading ? (
								<Skeleton variant='rectangle' width={120} height={30}></Skeleton>
							) : (
								<>
									{_.get(communicationReminderPerms, PERMISSION_TYPE.CREATE, false) && (
										<ButtonComp onClick={() => addReminder()}>Add reminder</ButtonComp>
									)}
								</>
							)}
							<AddReminder
								match={match}
								open1={open1}
								count={count}
								setCount={setCount}
								handleClose1={() => {
									setOpen1(false)
								}}
								getPlanIndividualDetails={getPlanIndividualDetails}
								setReminderList={setReminderList}
								setOpen1={setOpen1}
								onRemindAddSuccess={onRemindAddSuccess}
							/>
						</Grid>
						<Grid item lg={2.5} md={3} sm={2.5}>
							{loading ? (
								<Skeleton variant='rectangle' width={120} height={30}></Skeleton>
							) : (
								<>
									{_.get(communicationArticlePerms, PERMISSION_TYPE.CREATE, false) && (
										<ButtonComp onClick={() => addArticle()}>Add article</ButtonComp>
									)}
								</>
							)}
							<AddArticle
								match={match}
								open2={open2}
								handleClose2={() => {
									setOpen2(false)
								}}
								setArticleList={setArticleList}
								setOpen2={setOpen2}
								onArticleAddSuccess={onArticleAddSuccess}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={2} md={2}></Grid>
			</Grid>
			{/* End : add reminder and arcticle part */}
			{/* start : detail part section */}
			{loading ? (
				<div style={{ padding: '16px', width: '100%' }}>
					<Skeleton variant='rectangle' width={'100%'} height={50} style={{ marginBottom: 4 }}></Skeleton>
					<Skeleton variant='rectangle' width={'100%'} height={50} style={{ marginBottom: 4 }}></Skeleton>
				</div>
			) : (
				<>
					{_.isEmpty(planDetails?.data) ? (
						<img className={classes.detailsImage} alt='nodata' src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/nodata.png`}></img>
					) : (
						<>
							<Grid container>
								<Grid item={12} md={12} sm={12}>
									<Grid container>
										<Grid item lg={4} md={4} sm={12}></Grid>
										<Grid item lg={4} md={4} sm={12}></Grid>
										<Grid item lg={4} md={4} sm={12}>
											<Grid container justifyContent='flex-end' className={classes.countDetailssection}>
												<Grid item lg={4} md={5} sm={12}>
													<Typography variant='h6' className={classes.total}>
														{loading ? <Skeleton variant='text' width='90%' height={30} /> : `${plans.remindersCount} Reminders`}
													</Typography>
												</Grid>
												<Grid item lg={4} md={5} sm={12}>
													<Typography variant='h6' className={classes.total}>
														{loading ? <Skeleton variant='text' width='90%' height={30} /> : `${plans.articlesCount} Articles`}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container className={classes.accordionSection}>
								<Grid item={12} md={12} sm={12}>
									{loading ? (
										<>
											<Skeleton variant='rectangle' width={'100%'} height={50} style={{ marginBottom: 4 }}></Skeleton>
											<Skeleton variant='rectangle' width={'100%'} height={50} style={{ marginBottom: 4 }}></Skeleton>
											{/* <Skeleton variant='rectangle' width={'100%'} height={50} style={{ marginBottom: 4 }}></Skeleton> */}
										</>
									) : (
										<Accordionlist
											count={count}
											setCount={setCount}
											getPlanIndividualDetails={getPlanIndividualDetails}
											planDetails={planDetails}
											setPlanDetails={setPlanDetails}
											reminderList={reminderList}
											match={match}
											articleList={articleList}
											onArticleAddSuccess={onArticleAddSuccess}
											onReminderUpdateSuccess={onReminderUpdateSuccess}
											planUuid={planUuid}
										/>
									)}
								</Grid>
							</Grid>
						</>
					)}
				</>
			)}

			{/* start : detail part section */}
		</>
	)
}
export default PlanDetails
