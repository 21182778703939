import { MobileTimePicker } from '@mui/lab'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	muiTimeDialogPaper: {
		'& .PrivatePickersToolbar-root': {
			justifyContent: 'flex-start',
			'& .MuiIconButton-root': {
				display: 'none',
			},
		},
		'& .PrivateTimePickerToolbar-ampmSelection': {
			margin: 0,
		},
		'& .PrivatePickersToolbar-dateTitleContainer': {
			justifyContent: 'space-evenly',
		},
		'& .MuiDialogActions-root': {
			'& button': {
				color: '#000',
			},
		},
		'& .Mui-selected': {
			color: '#000',
		},
	},
}))

const CustomTimePicker = (props) => {
	const classes = useStyles()
	const { value, minTime, onAccept, label, id, error, helperText, needLabel, disabled } = props
	return (
		<MobileTimePicker
			{...props}
			label={label ? label : needLabel ? '' : 'Time *'}
			name={id ? id : 'time'}
			id={id ? id : 'time'}
			showToolbar={true}
			value={value}
			DialogProps={{ classes: { scrollPaper: classes.muiTimeDialogPaper } }}
			minTime={minTime}
			onChange={(newDate) => {}}
			onAccept={(newDate) => onAccept(newDate)}
			disabled={disabled}
			renderInput={(params) => (
				<TextField
					size='small'
					color='secondary'
					name={id ? id : 'time'}
					error={error}
					className={error ? classes.error : classes.texfield}
					helperText={helperText}
					fullWidth
					{...params}
				/>
			)}
		/>
	)
}

export default CustomTimePicker
