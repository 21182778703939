import { ACTION_TYPES } from '../../../constants'

const initialState = {
	jitsiRequestData: {},
	activeCall: false,
}

const JistConsultReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.JITSI_CALL_REQUEST: {
			return {
				...state,
				jitsiRequestData: {
					...state.jitsiRequestData,
					...action.payload,
				},
			}
		}
		case ACTION_TYPES.JITSI_CALLER_ACTIVE: {
			return {
				...state,
				activeCall: action.payload,
			}
		}
		default:
			return state
	}
}
export default JistConsultReducer
