import Error403Page from '.'

const Error403Config = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	routes: [
		{
			path: '/403',
			Component: Error403Page,
		},
	],
}

export default Error403Config
