/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-keys */
import { React } from 'react'
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
const filter = createFilterOptions()
const AddressFile = ({ occupationOptions, occupation, setOccupation }) => {
	return (
		<>
			<Autocomplete
				id='Occupation'
				value={occupation || null}
				onChange={(e, value) => {
					setOccupation(value)
				}}
				filterOptions={(options, params) => {
					const filtered = filter(options, params)
					const { inputValue } = params
					const isExisting = options.some((option) => inputValue === option?.mastLookupValue)
					if (inputValue.trim() !== '' && !isExisting) {
						let format = /^[!@#$%^&*()_+\-=[\]{};':"\\|,.0-9<>/?]*$/
						if (!inputValue.match(format)) {
							filtered.push({
								inputValue,
								mastLookupValue: `Add  New Occupation "${inputValue.replace(/[^A-Za-z ]/gi, '')}"`,
							})
						}
					}
					return filtered
				}}
				selectOnFocus
				disableClearable={false}
				// clearIcon=''
				options={occupationOptions || []}
				getOptionLabel={(option) => {
					if (typeof option === 'string') {
						return option
					}
					if (option.inputValue) {
						return option.inputValue
					}
					return option.mastLookupValue
				}}
				renderOption={(props, option) => <li {...props}>{option.mastLookupValue}</li>}
				renderInput={(params) => (
					<TextField
						{...params}
						label='Occupation'
						size='small'
						color='secondary'
						InputProps={{
							...params.InputProps,
						}}
					/>
				)}
			/>
		</>
	)
}

export default AddressFile
