import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getFilterOptions: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_REPORT_FILTER_OPTIONS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
}
