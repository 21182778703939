import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { currencyCode } from '../../../utils'
import { useSelector } from 'react-redux'
const useStyle = makeStyles((theme) => ({
	totalContainer: {
		display: 'flex',
		gap: 12,
	},
}))
const CustomConrtent = ({ totalExpenses, tableData, editMode }) => {
	const classes = useStyle()
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	return (
		<div className={classes.totalContainer}>
			{_.isEqual(editMode, true) ? (
				<></>
			) : (
				<>
					<Typography variant='body'> Total Expenses:</Typography>
					{!_.isEmpty(tableData, []) ? (
						<Typography variant='body' style={{ color: '#098405' }}>
							{currencyCode(totalExpenses, currencyType)}
						</Typography>
					) : (
						<>
							<Typography variant='body' style={{ color: '#098405' }}>
								0
							</Typography>
						</>
					)}
				</>
			)}
		</div>
	)
}

export default CustomConrtent
