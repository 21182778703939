import Hidden from '@mui/material/Hidden'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { navbarCloseFolded, navbarOpenFolded, navbarCloseMobile } from 'app/store/fuse/navbarSlice'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NavbarStyle2Content from './NavbarStyle2Content'
import DynamicMenuApi from '../../../../../../services/DynamicMenu/api'
import _ from 'lodash'
import { dynamicMenuFetching, isFetchingPracticeList, updateDynamicMenu, updatePracticeList } from 'services/DynamicMenu/actions'
import history from '@history'
import Lottie from 'react-lottie'
import Loading from '../../../../../../lottieFiles/loading1.json'
import { decryption, getMenuFeatures } from 'utils'
import CurrencyList from './currency'
import secureLocalStorage from 'react-secure-storage'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import PracticeList from 'services/CalendarApi/PracticeList/api'

const navbarWidth = 100

const Root = styled('div')(({ theme, folded }) => ({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 999,
	[theme.breakpoints.up('lg')]: {
		width: navbarWidth,
		minWidth: navbarWidth,
	},

	...(folded && {
		[theme.breakpoints.up('lg')]: {
			width: 100,
			minWidth: 100,
		},
	}),
}))

const StyledNavbar = styled('div')(({ theme, position, folded, foldedandopened, foldedandclosed }) => ({
	minWidth: navbarWidth,
	width: navbarWidth,
	maxWidth: navbarWidth,
	maxHeight: '100%',
	transition: theme.transitions.create(['width', 'min-width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shorter,
	}),

	...(position === 'left' && {
		left: 0,
	}),

	...(position === 'right' && {
		right: 0,
	}),

	// ...(folded && {
	// 	position: 'absolute',
	// 	width: 100,
	// 	minWidth: 100,
	// 	top: 0,
	// 	bottom: 0,
	// }),

	// ...(foldedandopened && {
	// 	width: navbarWidth,
	// 	minWidth: navbarWidth,
	// 	'& .NavbarStyle2-content': {
	// 		'& .logo-icon': {
	// 			width: 30,
	// 			height: 48,
	// 		},
	// 	},
	// }),

	// ...(foldedandclosed && {
	// 	'& .NavbarStyle2-content': {
	// 		'& .logo-icon': {
	// 			width: 30,
	// 			height: 48,
	// 		},
	// 		'& .logo-text': {
	// 			opacity: 0,
	// 		},
	// 		'& .react-badge': {
	// 			opacity: 0,
	// 		},
	// 		'& .fuse-list-item-text, & .arrow-icon, & .item-badge': {
	// 			opacity: 0,
	// 		},
	// 		'& .fuse-list-subheader .fuse-list-subheader-text': {
	// 			opacity: 0,
	// 		},
	// 		'& .fuse-list-subheader:before': {
	// 			content: '""',
	// 			display: 'block',
	// 			position: 'absolute',
	// 			minWidth: 16,
	// 			borderTop: '2px solid',
	// 			opacity: 0.2,
	// 		},
	// 		'& .collapse-children': {
	// 			display: 'none',
	// 		},
	// 		'& .user': {
	// 			'& .username, & .email': {
	// 				opacity: 0,
	// 			},
	// 			'& .avatar': {
	// 				width: 40,
	// 				height: 40,
	// 				bottom: 104,
	// 				padding: 0,
	// 			},
	// 		},
	// 		'& .fuse-list-item.active': {
	// 			width: 44,
	// 		},
	// 	},
	// }),
}))

const StyledNavbarMobile = styled(SwipeableDrawer)(({ theme, position }) => ({
	'& > .MuiDrawer-paper': {
		minWidth: navbarWidth,
		width: navbarWidth,
		maxWidth: navbarWidth,
		maxHeight: '100%',
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter,
		}),
	},
}))

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: Loading,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}

function NavbarStyle2(props) {
	const dispatch = useDispatch()
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
	const navbar = useSelector(({ fuse }) => fuse.navbar)
	const auth = useSelector((state) => state?.Authentication || {})
	const fetchingConfig = useSelector((state) => state?.dynamicMenu?.isFetchingMenu || false)
	const { uuid, mastuuid, identityUuid } = auth

	const getPracticeListData = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		dispatch(isFetchingPracticeList(true))
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success') {
				dispatch(hideLoader())
				dispatch(isFetchingPracticeList(false))
				dispatch(updatePracticeList(successData?.data?.data))
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(isFetchingPracticeList(false))
			dispatch(updatePracticeList([]))
		}
		PracticeList.getActiveList(identityUuid).then(onSuccess, onFailure)
	}, [dispatch, identityUuid])

	useEffect(() => {
		const onSuccess = (res) => {
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
				getPracticeListData()
			}
		}
		const onFailure = (err) => {
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		if (uuid && mastuuid) {
			dispatch(dynamicMenuFetching())
			DynamicMenuApi.getDynamicMenu(uuid, `${mastuuid}`).then(onSuccess, onFailure)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uuid, mastuuid])

	const { folded } = config.navbar
	const foldedandclosed = folded && !navbar.foldedOpen
	const foldedandopened = folded && navbar.foldedOpen
	return (
		<>
			{fetchingConfig ? (
				<div
					style={{
						background: '#fff',
						height: '100vh',
						width: '100%',
						position: 'fixed',
						zIndex: 999,
						top: 0,
						left: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Lottie options={defaultOptions} height={180} width={260} />
				</div>
			) : (
				<Root folded={folded ? 1 : 0} open={navbar.open} id='fuse-navbar' className='sticky top-0 h-screen flex-shrink-0 shadow-5'>
					{/*  */}
					<Hidden only='xs'>
						<StyledNavbar
							className='flex-col flex-auto'
							position={config.navbar.position}
							folded={folded ? 1 : 0}
							foldedandopened={foldedandopened ? 1 : 0}
							foldedandclosed={foldedandclosed ? 1 : 0}
							onClick={() => (foldedandclosed && dispatch(navbarOpenFolded())) || (foldedandopened && dispatch(navbarCloseFolded()))}
							onMouseEnter={() => foldedandclosed && dispatch(navbarOpenFolded())}
							onMouseLeave={() => foldedandopened && dispatch(navbarCloseFolded())}
						>
							<NavbarStyle2Content className='NavbarStyle2-content' />
						</StyledNavbar>
					</Hidden>

					{/* <Hidden lgUp>
						<StyledNavbarMobile
							classes={{
								paper: 'flex-col flex-auto h-full',
							}}
							// folded={folded ? 1 : 0}
							// foldedandopened={foldedandopened ? 1 : 0}
							// foldedandclosed={foldedandclosed ? 1 : 0}
							anchor={config.navbar.position}
							variant='temporary'
							open={navbar.mobileOpen}
							onClose={() => dispatch(navbarCloseMobile())}
							onOpen={() => {}}
							disableSwipeToOpen
							ModalProps={{
								keepMounted: true,
							}}
						>
							<NavbarStyle2Content className='NavbarStyle2-content' />
						</StyledNavbarMobile>
					</Hidden> */}
				</Root>
			)}
		</>
	)
}

export default NavbarStyle2
