import { ACTION_TYPES } from '../../../../constants'

export const setPersonalDetailsEmailList = (payload) => ({
	type: ACTION_TYPES.UPDATE_PERSONALDETAILS_EMAIL_LIST,
	payload,
})
export const setPersonalDetailsEmailFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_PERSONALDETAILS_EMAIL_FORMDATA,
	payload,
})
export const setPersonalDetailsPhoneNumberList = (payload) => ({
	type: ACTION_TYPES.UPDATE_PERSONALDETAILS_PHONE_LIST,
	payload,
})
export const setPersonalDetailsPhoneNumberFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_PERSONALDETAILS_PHONE_FORMDATA,
	payload,
})
export const setRegistrationDocument = (payload) => ({
	type: ACTION_TYPES.UPDATE_REGISTRATION_DETAILS,
	payload,
})

export const setRegistrationFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_REGISTRATION_FORM_DATA,
	payload,
})
export const setRegistrationPersonalProof = (payload) => ({
	type: ACTION_TYPES.UPDATE_PERSONAL_PROOF,
	payload,
})
export const setRegistrationEstablishmentProof = (payload) => ({
	type: ACTION_TYPES.UPDATE_ESTABLISHMENT_PROOF,
	payload,
})

export const setEducationData = (payload) => ({
	type: ACTION_TYPES.UPDATE_EDUCATION_DETAILS,
	payload,
})

export const setEducationFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_EDUCATION_FORMDATA,
	payload,
})
export const setEstablishmentListData = (payload) => ({
	type: ACTION_TYPES.UPDATE_ESTABLISHMENT_LIST_DATA,
	payload,
})
export const setEstablishmentFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_ESTABLISHMENT_FORM_DATA,
	payload,
})
export const setAwardData = (payload) => ({
	type: ACTION_TYPES.UPDATE_AWARD_DETAILS,
	payload,
})
export const setAwardFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_AWARD_FORMDATA,
	payload,
})
export const setMemberData = (payload) => ({
	type: ACTION_TYPES.UPDATE_MEMBER_DETAILS,
	payload,
})
export const setMemberFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_MEMBER_FORMDATA,
	payload,
})
export const setTimingData = (payload) => ({
	type: ACTION_TYPES.UPDATE_TIMING_DETAILS,
	payload,
})
export const setTimingFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_TIMING_FORMDATA,
	payload,
})
export const establishmentGetDetails = (payload) => ({
	type: ACTION_TYPES.ESTABLISHMENT_DETAILS,
	payload,
})
export const setServiceListData = (payload) => ({
	type: ACTION_TYPES.UPDATE_SERVICEEXP_LIST_DATA,
	payload,
})
export const setServiceFormData = (payload) => ({
	type: ACTION_TYPES.UPDATE_SERVICEEXP_FORM_DATA,
	payload,
})
export const setProfileStatus = (payload) => ({
	type: ACTION_TYPES.SET_PROFILE_STATUS,
	payload,
})
