import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#EFEFEF',
		color: '#000',
		borderRadius: 21,
		textTransform: 'none',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		boxShadow: 'none',
		gap: 4,
		border: '1px solid #727272',
		'&:hover': {
			backgroundColor: '#EFEFEF',
		},
	},
}))
function CancelBtn({ children, onClick, disabled, type }) {
	const classes = useStyles()
	return (
		<Button variant='contained' type={type} className={classes.root} onClick={onClick} disabled={disabled}>
			{children}
		</Button>
	)
}
export default CancelBtn
