import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Card, CardContent, TextField, Typography, IconButton } from '@mui/material'
import Button from '../../sharedComponents/Button'
import { styled } from '@mui/material/styles'
import * as yup from 'yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ResetPasswordApi from '../../../services/ResetPassword/api'
import { ResetPassword } from '../../session/actions'
import { showMessage } from 'app/store/fuse/messageSlice'
import { showLoader, hideLoader } from '../../../services/loader/action'
import { encryption, decryption, getResponseMessage, failureLogin } from 'utils'
import themesConfig from 'app/fuse-configs/themesConfig'
import { setVerifyModal } from '../../../services/VerficationModal/actions'
import VerificationModal from '../EmailVerificationModal/EmailVerificationModal'
import secureLocalStorage from 'react-secure-storage'
// import ForgetPassword from 'images/login/ForgetPassword.png'
import LyfngoFlashLogo from '../../../images/logos/LyfngoFlash.png'
import _ from 'lodash'
const Root = styled('div')(({ theme }) => ({
	// background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,
	'& .card-height': {
		height: '70vh',
		boxShadow: '0px 1px 6px 2px #0000001A',
	},

	'& .Login-leftSection': {
		boxShadow: 'none',
		paddingBlock: '1.6rem',
		paddingInline: '3.4rem',
		[theme.breakpoints.down('sm')]: {
			borderTopRightRadius: '2rem',
			borderBottomRightRadius: '2rem',
		},
	},

	'& .Login-rightSection': {
		display: 'flex',
		flexDirection: 'column',
		background: '#fff',
		// background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	'& .cardContent': {
		paddingBlock: '12px',
		paddingInline: '12px',
	},
	'& .text': {
		margin: '16px 0',
		color: '#6B7280',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid black',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .backButton': {
		background: themesConfig.greyDark.palette.primary.main,
		'& .MuiSvgIcon-root': {
			fill: '#fff',
		},
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.main,
		},
	},
	'& .shadow': {
		[theme.breakpoints.down('sm')]: {
			boxShadow: 'none',
		},
	},
	'& .Login-LogoSection': {
		width: '100%',
		display: 'flex',
	},
	'& .back-Cointainer': {
		paddingBlock: 8,
		cursor: 'pointer',
		display: 'flex',
		color: '#0062DD',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiSvgIcon-root': {
			fill: '#0062DD',
			fontSize: 14,
		},
	},
}))
const Footer = styled('div')(({ theme }) => ({
	position: 'relative',
	top: 48,
	'& .MuiTypography-body1': {
		color: '#000',
		fontWeight: 600,
	},
}))

const schema = yup.object().shape({
	email: yup.string().email('Please enter your registered email id').required('Please enter your registered email id'),
})
const defaultValues = {
	email: '',
}
export function getExplore() {
	return `${
		(_.isEqual(window.location.hostname, 'flash.sit.rigelsoft.com') && window.open('https://web.sit.rigelsoft.com')) ||
		(_.isEqual(window.location.hostname, 'flash.uat.rigelsoft.com') && window.open('https://web.uat.rigelsoft.com')) ||
		(_.isEqual(window.location.hostname, 'flash.lyfngo.com') && window.open('https://lyfngo.com'))
	}`
}

function ForgotPassword({ history }) {
	const dispatch = useDispatch()
	const errorMessages = useSelector((states) => states?.errorMessages)
	const { control, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	})
	// const [userEmail, setUserEmail] = useState('')

	const handleSubmitPassword = (data) => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			userName: data.email,
			userType: 'TNT',
			isForgotPassword: true,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(setVerifyModal({ open: true, data: data?.email, mode: 'forgotPassword' }))
				secureLocalStorage.setItem('resetPasswordMail', data?.email)
				dispatch(
					ResetPassword({
						resetPasswordMail: data?.email,
						message: successData?.message,
						userType: successData?.data?.userType,
					})
				)
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorMessages, 'OTP sent successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (successData?.status === 'failure') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ResetPasswordApi.setResetPassword(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const { errors } = formState
	function onSubmit(data) {
		handleSubmitPassword(data)
	}
	const handleClick = () => {
		history.goBack()
	}

	// useEffect(() => {
	// 	if (history?.location?.state?.userRole) {
	// 		setUserEmail(history?.location?.state?.userRole)
	// 	}
	// }, [history])
	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				<div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='card-height flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden'
				>
					<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
						<div className='Login-LogoSection' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className=' flex items-center mb-48' style={{ cursor: 'pointer' }} onClick={getExplore}>
								<img className='logo-icon' src={LyfngoFlashLogo} alt='logo' width='120' height='72' />
							</div>
						</div>
						<div className='max-w-320'>
							<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
								<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2C/Staticpages/Images/Login/forgot_password.svg`} alt='forgetPassword' />
							</div>
						</div>
					</div>
					<Card className='Login-leftSection flex flex-col w-full max-w-400 items-start justify-evenly' square>
						<CardContent className='cardContent flex flex-col items-start justify-center w-full max-w-340'>
							<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-left'>
									<div>
										<Typography className='text-18 font-semibold logo-text mb-12' color='#000000' pb={1}>
											Forgot Password?
										</Typography>
										<Typography className='text-14 logo-text' color='#000000' pb={1}>
											Provide us the email address of your <span className='font-600'>LYFnGO</span> account and we will send you an{' '}
											<span className='font-600'>OTP to reset your password.</span>
										</Typography>
										<form className='flex flex-col justify-center w-full' onSubmit={handleSubmit(onSubmit)}>
											<Controller
												name='email'
												control={control}
												render={({ field }) => (
													<TextField
														{...field}
														className='mt-16'
														type='text'
														name='email'
														// value={userEmail}
														// inputProps={{
														// 	readOnly: true,
														// }}
														error={!!errors.email}
														helperText={errors?.email?.message}
														label='Email id *'
														color='secondary'
														InputProps={{
															endAdornment: (
																<InputAdornment position='end'>
																	<Icon className='text-20' color='secondary'>
																		email
																	</Icon>
																</InputAdornment>
															),
														}}
													/>
												)}
											/>
											<Button type='submit' variant='secondary' customStyle='btn w-full mx-auto mt-32' aria-label='REGISTER' value='legacy'>
												Send me instructions
											</Button>
										</form>
										<div className='back-Cointainer' onClick={handleClick}>
											<IconButton fill={'#0062DD'}>
												<ArrowBackIcon />
											</IconButton>
											<Typography variant='body1'>Back to login</Typography>
										</div>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>
				<Footer className='footer-Container'>
					<Typography variant='body1'>© {new Date().getFullYear()} LYFnGO, All Rights Reserved.</Typography>
				</Footer>
			</Root>

			<VerificationModal history={history} />
		</>
	)
}

export default ForgotPassword
