import React from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	caremaincard: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		gap: '16px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	carecard1: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(5).png`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	carecard2: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(2).jpeg`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	carecard3: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(4).jpeg`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	carecard4: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(3).jpeg`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	carecard5: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(1).jpeg`})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 12,
	},
	content: {
		transform: 'translate(4px, 99px)',
		color: '#fff',
	},
}))
function Care() {
	const classes = useStyles()
	return (
		<Grid container className={classes.caremaincard}>
			<Grid item className={classes.carecard1}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Doctor
				</Typography>
			</Grid>
			<Grid item className={classes.carecard2}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Hospital/ Clinic
				</Typography>
			</Grid>
			<Grid item className={classes.carecard3}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Lab centre
				</Typography>
			</Grid>
			<Grid item className={classes.carecard4}>
				<Typography className={classes.content} justifyContent='center' align='bottom' textAlign='center'>
					Scan centre
				</Typography>
			</Grid>
			<Grid item className={classes.carecard5}>
				<Typography className={classes.content} justifyContent='center' align='justify' textAlign='center'>
					Home service
				</Typography>
			</Grid>
		</Grid>
	)
}
export default Care
