import axios from 'axios'
import { API_ENDPOINTS } from '../../constants/index'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	sendUploadFile: (tentMasterUuid, tentUserUuid, categoryUuid, data) => {
		return axios.post(`${API_ENDPOINTS.FILE_POST}`, data, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'multipart/form-data',
				'X-SECURITY': csrf(),
				path: { tentMasterUuid, tentUserUuid, categoryUuid },
			},
		})
	},
	multiFileUpload: (categoryUuid, mastuuid, uuid, data, params) => {
		return axios.post(`${API_ENDPOINTS.MULTI_FILE_UPLOAD}`, data, {
			headers: { isAuthRequired: true, 'Content-Type': 'multipart/form-data', 'X-SECURITY': csrf(), path: { mastuuid, uuid, categoryUuid } },
			params: { ...params },
		})
	},
}
