import React from 'react'
import { Dialog, DialogContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Lottie from 'react-lottie'
import Loading from '../../../lottieFiles/loading2.json'

const useStyles = makeStyles((theme) => ({
	container: {
		textAlign: 'center',
		// padding: 8,
		// '& .MuiCircularProgress-svg': {
		// 	color: themesConfig.greyDark.palette.primary.main,
		// },
	},
	loaderTxt: {
		display: 'block',
	},
}))

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: Loading,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}

const Loader = ({ showLoader, loaderTxt }) => {
	const classes = useStyles()
	return (
		<Dialog fullWidth={true} maxWidth='xs' open={showLoader} aria-labelledby='Loader Popup'>
			<DialogContent>
				<div className={classes.container}>
					{/* <Lottie options={defaultOptions} height={80} width={120} /> */}
					<Lottie options={defaultOptions} height={100} />
					<Typography className={classes.loaderTxt} variant='subtitlte2'>
						{loaderTxt}
					</Typography>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default Loader
