import { NavigateBefore, ChevronRight, DescriptionOutlined, Image, InsertLink, InsertDriveFile, Download } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid, Typography, Card, CardContent, IconButton, Skeleton, Dialog, DialogContent, Avatar } from '@mui/material'
import secureLocalStorage from 'react-secure-storage'
import { useState, useEffect } from 'react'
import { useStyles } from './style'
import clsx from 'clsx'
import _ from 'lodash'
import { ImagePreviewLayout } from './ImagePreviewLayout'
import consultApi from '../../../../../services/consult/api'
import moment from 'moment'

const previewData = {
	isModalOpen: false,
	activeModal: '',
}

const ChatDetails = (props) => {
	const {
		activeCustDetails = {},
		headerText,
		setHeaderText,
		isMediaLoading = false,
		mediaDetails = {},
		isMobileDevice,
		isTabletDevice,
		// isLargeDevice,
		setCurrentPatientMediaOpen,
		handleTenantMediaChatDetails,
		handleActiveChatDetailsChange,
	} = props

	const [loading, setLoading] = useState(false)
	const classes = useStyles()

	let bearerToken = typeof window !== 'undefined' ? secureLocalStorage.getItem('AidiBearer') : null

	const [previewDetails, setPreviewDetails] = useState(_.cloneDeep(previewData))
	const { isModalOpen, activeModal } = previewDetails
	const [imageDetails, setImageDetails] = useState({ activeImage: '', activeImageUrl: '', imageObj: {}, videoObj: {}, activeImageObj: {} })
	const [chatDocumentsDetails, setChatDocumentDetails] = useState([])
	const [chatLinkDetails, setChatLinkDetails] = useState([])

	useEffect(() => {
		setHeaderText(headerText)
		handleChangeHeader(headerText, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaDetails])

	useEffect(() => {
		setLoading(true)
		setHeaderText(headerText)
		handleChangeHeader(headerText)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [headerText])

	const handleChangeHeader = (name) => {
		setHeaderText(name)
		if (name === 'Images/Videos' && activeCustDetails?.appointmentUuid) {
			getChatImageDetails(activeCustDetails?.appointmentUuid)
		} else if (name === 'Documents' && activeCustDetails?.appointmentUuid) {
			getChatDocumentDetails(activeCustDetails?.appointmentUuid)
		} else if (name === 'Links' && activeCustDetails?.appointmentUuid) {
			getChatLinkDetails(activeCustDetails?.appointmentUuid)
		}
	}

	const getChatImageDetails = (appointmentUuid) => {
		consultApi.getChatImageDetails(appointmentUuid).then((res) => {
			if (res && res?.data?.status === 'success') {
				getChatVideoDetails(activeCustDetails?.appointmentUuid, res?.data?.data)
				setImageDetails({ ...imageDetails, imageObj: res?.data?.data })
			}
		})
	}

	const getChatVideoDetails = (appointmentUuid, obj) => {
		consultApi.getChatVideoDetails(appointmentUuid).then((res) => {
			if (res && res?.data?.status === 'success') {
				setImageDetails({ ...imageDetails, videoObj: res?.data?.data, imageObj: obj })
				setLoading(false)
			}
		})
	}

	const getChatDocumentDetails = (appointmentUuid) => {
		consultApi.getChatDocumentDetails(appointmentUuid).then((res) => {
			if (res && res?.data?.status === 'success') {
				setLoading(false)
				setChatDocumentDetails(res?.data?.data)
			}
		})
	}

	const getChatLinkDetails = (appointmentUuid) => {
		consultApi.getChatLinkDetails(appointmentUuid).then((res) => {
			if (res && res?.data?.status === 'success') {
				setLoading(false)
				setChatLinkDetails(res?.data?.data?.TenantLink)
			}
		})
	}

	function renderActiveContent(header) {
		switch (header) {
			case 'Chat Details':
				let content = mediaDetails?.TenantChatDetails
					? _.map(mediaDetails?.TenantChatDetails, (x, keyName, i) => {
							let iconDetails = {}
							if (keyName === 'imageVideo') {
								iconDetails = { color: 'pink', icon: <Image className={classes.fileIcon} />, name: 'Images/Videos' }
							} else if (keyName === 'link') {
								iconDetails = { color: 'skyBlue', icon: <InsertLink className={classes.fileIcon} />, name: 'Links' }
							} else if (keyName === 'document') {
								iconDetails = { color: 'blue', icon: <DescriptionOutlined className={classes.fileIcon} />, name: 'Documents' }
							}
							return (
								<>
									<Grid
										container
										md={12}
										lg={12}
										className={classes.accordianWrap}
										onClick={() => {
											setHeaderText(iconDetails.name)
										}}
									>
										<Grid item xs={8} md={8} lg={8} direction='row' className={clsx(classes.flex)}>
											<IconButton className={classes[`${iconDetails.color}_iconBg`]}>{iconDetails.icon}</IconButton>
											<div style={{ marginLeft: '20px' }} className={classes.alignSelf}>
												<Typography className={classes.chat_heading}>{iconDetails.name}</Typography>
												<Typography className={classes.chat_fileCount}>{`${x?.totalCount} files`}</Typography>
											</div>
										</Grid>
										<Grid item xs={4} md={4} lg={4} direction='row' className={clsx(classes.flex, classes.alignSelf)}>
											<Typography className={classes.chat_subHead}>{x?.storage || ''}</Typography>
											<ChevronRight className={classes.ArrowIcon} />
										</Grid>
									</Grid>
								</>
							)
					  })
					: 'No Content'
				return content
			case 'Images/Videos':
				let imgContent = (
					<Grid container direction='column'>
						<Typography className={classes.imgContent}>Images</Typography>
						{loading ? (
							<Grid container direction='row'>
								{_.times(3, (x) => {
									return <Skeleton key={x} variant='rectangular' width={70} height={80} style={{ margin: '5px' }} />
								})}
							</Grid>
						) : Object.keys(imageDetails.imageObj).length > 0 ? (
							<Grid container direction='row'>
								{Object.keys(imageDetails.imageObj).map((data) => {
									return (
										<div
											className={classes.docImage}
											onClick={() => {
												setPreviewDetails({ ...previewDetails, isModalOpen: true, activeModal: 'image' })
												setImageDetails({
													...imageDetails,
													activeImage: imageDetails.imageObj[data].file.viewFileUrl,
													activeImageUrl: imageDetails.imageObj[data].file.downloadFileUrl,
													activeImageObj: imageDetails.imageObj[data],
												})
											}}
										>
											<img src={`${imageDetails.imageObj[data].file.viewFileUrl}`} alt='docImg' className={classes.imgDisplay} />
										</div>
									)
								})}
							</Grid>
						) : (
							<Grid textAlign='center' className={classes.noContentStyle}>
								No images
							</Grid>
						)}
						<Typography className={classes.imgContent} style={{ marginTop: '20px' }}>
							Videos
						</Typography>
						{loading ? (
							<Grid container direction='row'>
								{_.times(3, (x) => {
									return <Skeleton key={x} variant='rectangular' width={70} height={80} style={{ margin: '5px' }} />
								})}
							</Grid>
						) : Object.keys(imageDetails.videoObj).length > 0 ? (
							<Grid container direction='row'>
								{Object.keys(imageDetails.videoObj).map((data) => {
									return (
										<a
											href={`${consultApi.getFileDownloadUrl(imageDetails.videoObj[data]?.file?.documentUuid, bearerToken)}`}
											download
											className={classes.chatDetailsVideo}
										>
											<video src={`${imageDetails.videoObj[data].file.viewFileUrl}`} style={{ height: '100px', width: '130px' }} type='video/mp4' />
										</a>
									)
								})}
							</Grid>
						) : (
							<Grid textAlign='center' className={classes.noContentStyle}>
								No videos
							</Grid>
						)}
					</Grid>
				)
				return imgContent
			case 'Links':
				return <LinkLayout loading={loading} chatLinkDetails={chatLinkDetails} />
			case 'Documents':
				return <DocumentLayout loading={loading} chatDocumentsDetails={chatDocumentsDetails} />
			default:
				return (
					<Grid textAlign='center' className={classes.noContentStyle}>
						No content
					</Grid>
				)
		}
	}

	const renderActiveModal = (activeComponent) => {
		switch (activeComponent) {
			case 'image':
				return (
					<ImagePreviewLayout
						activeCustDetails={activeCustDetails}
						handleClose={() => {
							setPreviewDetails({ ...previewDetails, isModalOpen: false })
						}}
						imageDetails={imageDetails}
						setImageDetails={setImageDetails}
					/>
				)
			default:
				return <></>
		}
	}

	return (
		<>
			<Card
				style={{
					height: '100%',
					borderRadius: isMobileDevice ? '0px' : '10px',
				}}
			>
				<CardContent style={{ padding: '0px', height: '100%' }}>
					<Grid className={clsx(classes.chatDtHeader)} direction='row'>
						{headerText !== 'Chat Details' && !isMobileDevice && (
							<IconButton
								className={clsx(classes.IconBtnSq)}
								onClick={() => {
									handleTenantMediaChatDetails(activeCustDetails)
									setHeaderText('Chat Details')
								}}
							>
								<NavigateBefore />
							</IconButton>
						)}
						{headerText !== 'Chat Details' && isMobileDevice && (
							<IconButton
								className={clsx(classes.IconBtnSq)}
								onClick={() => {
									handleTenantMediaChatDetails(activeCustDetails)
									setHeaderText('Chat Details')
								}}
							>
								<NavigateBefore />
							</IconButton>
						)}
						{headerText === 'Chat Details' && isMobileDevice && (
							<IconButton
								onClick={() => {
									setHeaderText('')
									setCurrentPatientMediaOpen(false)
									handleActiveChatDetailsChange(activeCustDetails)
								}}
								className='mr-4'
							>
								<ArrowBackIcon />
							</IconButton>
						)}
						{headerText === 'Chat Details' && !isMobileDevice && (
							<IconButton
								onClick={() => {
									setHeaderText('')
									setCurrentPatientMediaOpen(false)
									handleActiveChatDetailsChange(activeCustDetails)
								}}
								className='mr-4'
							>
								<ArrowBackIcon />
							</IconButton>
						)}
						<Typography className={classes.HeadingTxt}>{headerText}</Typography>
					</Grid>
					<Grid direction='column' className={clsx('h-screen', classes.chatDetailsWrap)} style={{ textAlign: loading ? 'center' : '' }}>
						{isMediaLoading ? (
							<>
								{_.times(3, (x) => {
									return (
										<div style={{ display: 'flex' }} key={x}>
											<Skeleton variant='circular' height={40} width={50} style={{ alignSelf: 'center' }}>
												<Avatar />
											</Skeleton>
											<Skeleton variant='rectangular' width={320} height={30} style={{ margin: '15px', background: '#F5F4F6' }} />
										</div>
									)
								})}
							</>
						) : (
							renderActiveContent(headerText)
						)}
					</Grid>
				</CardContent>
			</Card>
			{!isMobileDevice && (
				<Dialog open={isModalOpen} maxWidth='md' fullScreen className={classes.dialogLayout}>
					<DialogContent>{renderActiveModal(activeModal)}</DialogContent>
				</Dialog>
			)}
		</>
	)
}
export default ChatDetails

export const LinkLayout = (props) => {
	const { loading = false, chatLinkDetails = [] } = props
	const classes = useStyles()
	return (
		<>
			{loading ? (
				<>
					<Grid container direction='column'>
						{_.times(5, (x) => {
							if (x % 2 === 0) {
								return <Skeleton variant='rectangular' width={240} height={45} style={{ margin: '5px', borderRadius: '8px 8px 0px' }} />
							} else {
								return <Skeleton variant='rectangular' width={240} height={45} style={{ margin: '20px 5px', borderRadius: '8px 8px 0px' }} />
							}
						})}
					</Grid>
				</>
			) : Object.values(chatLinkDetails).length > 0 ? (
				chatLinkDetails &&
				_.map(chatLinkDetails, (data) => {
					return (
						<Grid>
							<div className={clsx(classes.linkData)}>
								<Typography className={clsx(classes.linkItem)}>
									<a href={data.link} target='_blank' rel='noreferrer'>
										{data.link}
									</a>
								</Typography>
								<Typography className={clsx(classes.smallText)}>{moment(data?.dateTime).format('DD/MM/YY [at] hh:mm A')}</Typography>
							</div>
						</Grid>
					)
				})
			) : (
				<Grid textAlign='center' className={classes.noContentStyle}>
					No links
				</Grid>
			)}
		</>
	)
}

export const DocumentLayout = (props) => {
	const { loading = false, chatDocumentsDetails = {} } = props
	const classes = useStyles()
	let BearerToken = secureLocalStorage.getItem('AidiBearer')
	return (
		<>
			{loading ? (
				<>
					<Grid container direction='column'>
						{_.times(5, (x) => {
							if (x % 2 === 0) {
								return <Skeleton variant='rectangular' width={240} height={45} style={{ margin: '5px', borderRadius: '8px 8px 0px' }} />
							} else {
								return <Skeleton variant='rectangular' width={240} height={45} style={{ margin: '20px 5px', borderRadius: '8px 8px 0px' }} />
							}
						})}
					</Grid>
				</>
			) : Object.values(chatDocumentsDetails).length > 0 ? (
				Object.values(chatDocumentsDetails).map((detail) => {
					return (
						<div className={clsx(classes.docData, !detail?.file?.isFileUploadedByCustomer && classes.marginLeftAuto)}>
							<Grid
								container
								direction='row'
								alignItems='center'
								flexWrap='nowrap'
								className={clsx(
									detail?.file?.isFileUploadedByCustomer
										? `${classes.docCust} ${classes.custDownload}`
										: `${classes.docTenet} ${classes.tenetDownload}`,
									classes.downloadItem
								)}
							>
								<InsertDriveFile />
								<Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
									{detail?.file?.documentName}
								</Typography>
								<a href={consultApi.getFileDownloadUrl(detail?.file?.documentUuid, BearerToken)} download>
									<IconButton id='d-btn' className={classes.iconBtn}>
										<Download fontSize='17px' style={{ margin: '-14px 0px 0px -4px' }} />
									</IconButton>
								</a>
							</Grid>
							<Grid container alignItems='center' justifyContent='space-between' style={{ marginTop: '5px' }}>
								<Typography className={clsx(classes.smallText)}>{moment(detail?.currentDateTime).format('DD/MM/YY [at] hh:mm A')}</Typography>
							</Grid>
						</div>
					)
				})
			) : (
				<Grid textAlign='center' className={classes.noContentStyle}>
					No documents
				</Grid>
			)}
		</>
	)
}
