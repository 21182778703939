import _ from 'lodash'
import { ACTION_TYPES } from '../../../constants'

const initialState = {
	FeedbackFilter: false,
}
const feedbackAction = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FEEDBACK_FILTER: {
			return {
				...state,
				FeedbackFilter: action.payload,
			}
		}
		default:
			return state
	}
}

export default feedbackAction
