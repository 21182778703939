import { Typography, Grid, Paper, Table, TableHead, TableContainer, TableRow, TableBody } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { customDateFormat } from 'utils'

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		paddingTop: 16,
	},
	container: {
		display: 'flex',
	},
	notesContainer: {
		paddingBlock: 16,
	},
	headerText: {
		color: '#727272',
		fontWeight: '400',
	},
	contentText: {
		color: '#000',
		fontWeight: '500',
	},
	tableCont: {
		width: '100%',
		marginTop: theme.spacing(2),
		overflowX: 'auto',
		borderRadius: 2,
		boxShadow: 'none',
	},
	tableRow: {
		'&:last-child th, &:last-child td': {
			borderBottom: 0,
		},
	},
}))

const ViewDetails = ({ isView, showViewPopup, viewWhatsappMsg, setOpenSms, setViewWhatsappMsgText }) => {
	const classes = useStyles()
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	const onClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			setOpenSms(false)
			setViewWhatsappMsgText({})
		}
	}

	return (
		<CustomDialog
			open={isView && showViewPopup}
			headerText={`View WhatsApp message history`}
			onClose={onClose}
			maxWidth='md'
			hideCloseBtn
			hideSaveBtn
			hideSaveAndAddBtn
			hideDivider
		>
			<Grid container xs={12} className={classes.mainContainer}>
				<Grid item xs={12} className={classes.container}>
					<Grid item xs={3}>
						<Typography variant='body1' className={classes.headerText}>
							Type
						</Typography>
						<Typography variant='body1' className={classes.contentText}>
							{viewWhatsappMsg?.type || '-'}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant='body1' className={classes.headerText}>
							Name
						</Typography>
						<Typography variant='body1' className={classes.contentText}>
							{viewWhatsappMsg?.recipients || '-'}
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant='body1' className={classes.headerText}>
							No.of.messages sent
						</Typography>
						<Typography variant='body1' className={classes.contentText}>
							{viewWhatsappMsg?.messageHistory}
						</Typography>
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
				<Grid item xs={12} className={classes.notesContainer}>
					<Typography variant='body1' className={classes.headerText}>
						Messages
					</Typography>
					<TableContainer component={Paper} className={classes.tableCont}>
						{' '}
						<Table
							sx={{
								[`& .${tableCellClasses.root}`]: {
									borderBottom: '2px dashed #2DBD96',
									background: '#DEFBF3',
									// minWidth: 650,
								},
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>Template Name</TableCell>
									<TableCell>Message</TableCell>
									<TableCell style={{ width: '15%' }}>Delivery Date</TableCell>
									<TableCell style={{ width: '12%' }}>Time</TableCell>
									<TableCell>Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(viewWhatsappMsg?.message, (data, i) => (
									<TableRow key={i} className={classes.tableRow}>
										<TableCell omponent='th' scope='row'>
											{data?.templateName}
										</TableCell>
										<TableCell>{data?.message}</TableCell>
										<TableCell>{data?.createdOn ? customDateFormat(data?.createdOn, dateFormatForViewer) : '-'}</TableCell>
										<TableCell>{moment(`${data?.createdOn}`).format('LT')}</TableCell>
										<TableCell>{_.capitalize(data?.status)}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}

export default ViewDetails
