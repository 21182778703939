/* eslint-disable no-const-assign */
import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DialogTitle, TextField, Autocomplete, Typography, Paper, useMediaQuery } from '@mui/material'
import { hideLoader, showLoader } from 'services/loader/action'
import { useDispatch, useSelector } from 'react-redux'
import { EmailOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { showMessage } from 'app/store/fuse/messageSlice'
import Button from 'app/sharedComponents/Button'
import ButtonCancel from 'app/sharedComponents/CancelButton'
import themesConfig from 'app/fuse-configs/themesConfig'
import _ from 'lodash'
import ReportsData from '../../../../../services/Reports/api'
import { customDateFormat, getResponseMessage } from '../../../../../utils'
import moment from 'moment'
import Draggable from 'react-draggable'
import CustomIconButtonBlue from 'app/sharedComponents/CustomIconButtonBlue'

const useStyles = makeStyles((theme) => ({
	root: {},
	dialogRoot: {
		'& .MuiDialog-paper': {
			'& .MuiDialogContent-root': {
				paddingBlock: 44,
				'& .MuiFormControl-root': {
					'& .Mui-focused': {
						color: 'inherit',
					},
				},
			},

			'& .MuiDialogActions-root': {
				paddingBlock: 14,
				paddingInline: 24,
				'& button': { paddingInline: 36 },
			},

			'& .MuiDialogTitle-root': {
				borderBottom: '2px solid #AEB6BF',
				fontSize: 14,
				color: '#fff',
				background: themesConfig.greyDark.palette.primary.main,
			},

			[theme.breakpoints.up('xs')]: {
				minWidth: 300,
			},
			[theme.breakpoints.up('sm')]: {
				minWidth: 380,
			},
		},
	},
	headerIcon: {
		background: '#FFFFFF25 ',
		borderRadius: '50%',
		padding: 8,
		'& .MuiSvgIcon-root': {
			color: '#FFFFFF',
			fontSize: 18,
		},
	},
	selectStaff: {
		width: '100%',
	},

	dropDownText: {
		paddingInline: 12,
		paddingBlock: 8,
		cursor: 'pointer',
		'&:hover': {
			background: '#f6f7f9',
		},
	},
}))

export default function MailModal({ allUsersList, report, pageData }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false)
	const [staff, setStaff] = useState(null)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	const testArray = []

	function PaperComponent(props) {
		return (
			<Draggable bounds={{ left: -400, top: -200, right: 400, bottom: 200 }}>
				<Paper {...props} />
			</Draggable>
		)
	}

	const sendMailExcel = () => {
		dispatch(showLoader('Sending mail please wait...'))
		let sampleArray = []
		if (report === 'InvoicedIncome') {
			sampleArray = _.map(pageData?.items, (item) => ({
				createdOn: item?.createdOn,
				cost: item?.custBillingCost,
				discount: item?.custBillingDiscount,
				Income_After_Discount: item?.invoiceAfterDiscount,
				Tax: item?.custBillingTax,
				Invoice_Amount: item?.custBillingTotal,
			}))
			testArray.push(sampleArray)
		} else if (report === 'Payments') {
			sampleArray = _.map(pageData?.items, (item) => ({
				createdOn: item?.createdOn,
				Patient_Name: item?.custName,
				Receipt_No: item?.custPaymentNo,
				Invoice_No: item?.custInvoiceNo,
				Treatment: item?.procedureName,
				Amount_Paid: item?.paymentAmount,
				Advance_Amount: item?.advancePayment,
				Payment_Info: item?.paymentMode,
			}))
			testArray.push(sampleArray)
		} else if (report === 'NewPatients') {
			sampleArray = _.map(pageData?.items, (item, index) => ({
				sNo: index + 1,
				createdOn: item?.createdOn,
				patientName: item?.custName,
				patientID: item?.custCustomId,
			}))
		} else if (report === 'Appointments') {
			sampleArray = _.map(pageData?.items, (item, index) => ({
				sNo: index + 1,
				date: item?.scheduledOn ? customDateFormat(item?.scheduledOn, dateFormatForViewer) : '-',
				scheduledAt: item?.scheduledTime ? moment(item?.scheduledTime, ['h:mm A']).format('LT') : '-',
				checkIn: item?.checkInTime ? moment(item?.checkInTime.slice(0, -1)).format('LT') : '-',
				engagedAt: item?.engageStartTime ? moment(item?.engageStartTime.slice(0, -1)).format('LT') : '-',
				waited: item?.waitingTime || '-',
				checkedAt: item?.checkOutTime ? moment(item?.checkOutTime.slice(0, -1)).format('LT') : '-',
				doctorName: `${item?.tentUserSalutation ? item?.tentUserSalutation + '.' : ''} ${item?.tentUserFirstName || '-'}`,
				patientName: item?.custName || '-',
				category: item?.categoryName || '-',
			}))
			testArray.push(sampleArray)
		} else if (report === 'BillSummary') {
			sampleArray = _.map(pageData?.items, (item) => ({
				Patient_Name: item?.custName,
				Treatment: item?.procedureName,
				cost: item?.custBillingCost,
				discount: item?.custBillingDiscount,
				Tax: item?.custBillingTax,
				Invoice_No: item?.custInvoiceNo,
				Receipt_No: item?.custPaymentNo,
				Payment_Info: item?.paymentMode,
				paymentRefNo: item?.paymentRefNo,
				Amount_Paid: item?.paymentAmount,
				Total_Amount: item?.custBillingTotal,
			}))
			testArray.push(sampleArray)
		} else if (report === 'InvoicedDetail') {
			sampleArray = _.map(pageData?.items, (item, index) => ({
				sNo: index + 1,
				patientname: item?.custName || '-',
				treatmentprocedure: item?.procedures || '-',
				cost: (item?.custBillingCost || 0).toLocaleString('en-IN'),
				discount: (item?.custBillingDiscount || 0).toLocaleString('en-IN'),
				tax: (item?.custBillingTax || 0).toLocaleString('en-IN'),
				invoiceNo: item?.custInvoiceNo || '-',
				receiptNo: item?.paymentDetails?.at(0)?.custPaymentNo || '',
				modeOfPayment: item?.paymentDetails?.at(0)?.paymentMode || '',
				paymentReferenceNumber: item?.paymentDetails?.at(0)?.paymentReference || '',
				amountPaid: (item?.paymentDetails || []).reduce((total, i) => parseFloat(i?.paymentAmount) + total, 0) || 0,
				totalAmount: (item?.custBillingTotal || 0).toLocaleString('en-IN'),
				amountDue: (item?.amountDue || 0).toLocaleString('en-IN'),
			}))
			testArray.push(sampleArray)
		}

		let body = {
			mastCommName: 'B2B reports share header',
			mastCommMode: 'mail',
			tentUserUuid: staff?.tentUserUuid,
			customEmail: staff?.tentUserEmail,
			excelData: sampleArray,
			fileName: report,
		}

		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Mail sent Successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		ReportsData.sendMailViaExcel(body).then(onSuccess, onFailure)
	}

	return (
		<div className={classes.root}>
			<Tooltip title='Send E-mail' arrow enterDelay={500}>
				<CustomIconButtonBlue onClick={() => setOpen(true)}>
					<EmailOutlined />
				</CustomIconButtonBlue>
			</Tooltip>
			<Dialog open={open} onClose={() => setOpen(false)} className={classes.dialogRoot} PaperComponent={PaperComponent}>
				<DialogTitle style={{ cursor: 'move' }}>Choose staff to send excel</DialogTitle>
				<DialogContent>
					<Autocomplete
						id='selectStaff'
						name='selecStaff'
						color='secondary'
						value={staff}
						options={allUsersList}
						getOptionLabel={(option) => `${option?.tentUserSalutation ? option?.tentUserSalutation + '. ' : ''} ${option?.tentUserFirstName || ''}`}
						clearIcon=''
						disableClearable
						isOptionEqualToValue={(option, value) => option?.tentUserFirstName === value?.tentUserFirstName}
						renderOption={(props, option) => (
							<Typography {...props} className={classes.dropDownText}>
								{option?.tentUserSalutation ? option?.tentUserSalutation + '. ' : ''}
								{option?.tentUserFirstName} {option?.mastRoleName && `(${option?.mastRoleName})`}
							</Typography>
						)}
						onChange={(e, value) => {
							setStaff(value)
						}}
						renderInput={(params) => <TextField {...params} size='small' color='secondary' label='Select staff' />}
					/>
				</DialogContent>
				<DialogActions>
					<ButtonCancel
						onClick={() => {
							setOpen(false)
							setStaff(null)
						}}
					>
						Cancel
					</ButtonCancel>

					<Button
						onClick={() => {
							setOpen(false)
							sendMailExcel()
						}}
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
