import { Close, ContentCopy } from '@mui/icons-material'
import { Box, Dialog, Divider, IconButton, Slide, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MicroSetupApi from 'services/MicroSetup/api'
import ProfileDetails from 'services/ProfileSetup/profileDetails/api'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import themesConfig from 'app/fuse-configs/themesConfig'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	topBox: {
		display: 'flex',
		justifyContent: 'end',
	},
	mainContent: {
		textAlign: 'center',
		padding: 24,
	},
	typographyOne: {
		fontSize: 15,
		fontWeight: 600,
	},
	typographyTwo: { fontSize: 14, fontWeight: 500 },
	link: {
		fontSize: 13,
		color: '#0062DD',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
			textDecorationColor: '#0062DD',
		},
	},
	typographyThree: {
		fontSize: 12,
	},
	socialIconsContainer: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 16,
	},
	ContentCopyIcon: {
		color: themesConfig.lyfngo.palette.tertiary.main,
		fontSize: 16,
		cursor: 'pointer',
	},
	image: {
		width: '27px',
		height: '27px',
		objectFit: 'cover',
	},
}))

const SuccessModal = ({ open, closeSuccessModal }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { uuid, mastuuid } = state
	const userData = useSelector((state) => state.dynamicMenu.userData)
	const profileStatus = useSelector((state) => state.profileSetup.profileStatus)
	const individualDetails = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = individualDetails
	const [micrositeData, setMicrositeData] = useState({})
	const [profileDetails, setProfileDetails] = useState({})

	// console.log('profileStatus', profileStatus)

	const getMicroSiteDta = () => {
		const onSuccess = (res) => {
			setMicrositeData(res?.data?.data)
		}
		const onFailure = (err) => {}
		MicroSetupApi.getSiteData(mastuuid, uuid).then(onSuccess, onFailure)
	}

	useEffect(() => {
		getMicroSiteDta()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, open])

	const handleCopyContent = (previewData) => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)
		navigator.clipboard.writeText(
			profileStatus.tentUserFirstName || profileDetails?.userName
				? `https://${micrositeData?.domainName}/profile/${profileDetails?.userName}`
				: `https://${micrositeData?.domainName}`
		)
	}

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				// console.log('successModal', res?.data?.data)
				setProfileDetails(res?.data?.data)
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		ProfileDetails.getPracticeDetails(isTent).then(onSuccess, onFailure)
	}, [dispatch, groupUserName, open, tentUserId, uuid])

	return (
		<Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description' maxWidth='md'>
			<Box className={classes.topBox}>
				<IconButton onClick={() => closeSuccessModal()}>
					<Close />
				</IconButton>
			</Box>
			<Box className={classes.mainContent}>
				<Box mb={1} display={'flex'} justifyContent={'center'}>
					<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/Congratulation.svg`} alt='Congratulation' />
				</Box>
				<Box mb={3}>
					<Typography variant='h5' mb={1} className={classes.typographyOne}>
						Congratulations,
						<span style={{ color: '#0062DD' }}> {profileStatus.tentUserFirstName ? profileStatus.tentUserFirstName : userData.tentName} </span>
					</Typography>
					<Typography variant='h6' className={classes.typographyTwo}>
						Profile published on microsite
					</Typography>
				</Box>
				<Divider />
				<Box mt={2}>
					<Typography variant='h6' mb={2} className={classes.typographyTwo}>
						Here’s your {!profileStatus.tentUserFirstName || profileDetails?.userName ? 'microsite' : 'specialist'} profile URL
					</Typography>
					{micrositeData?.domainName && (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography
								variant='h6'
								mb={1}
								className={classes.link}
								onClick={() =>
									window.open(
										profileStatus.tentUserFirstName || profileDetails?.userName
											? `https://${micrositeData?.domainName}/profile/${profileDetails?.userName}`
											: `https://${micrositeData?.domainName}`
									)
								}
							>
								{profileStatus.tentUserFirstName || profileDetails?.userName
									? `https://${micrositeData?.domainName}/profile/${profileDetails?.userName}`
									: `https://${micrositeData?.domainName}`}
							</Typography>
							<IconButton onClick={handleCopyContent}>
								<ContentCopy className={classes.ContentCopyIcon} />
							</IconButton>
						</Box>
					)}
				</Box>
				<Box>
					<Typography variant='h6' mb={1} className={classes.typographyThree}>
						Promote specialist profile link to your customer
					</Typography>
				</Box>
				<Box className={classes.socialIconsContainer}>
					{micrositeData?.media?.fb && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/facebook_icon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.twitter && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/x_icon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.insta && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/instagramicon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.thread && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/threads_icon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.youtube && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/youtube_icon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.linkedin && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/linkedin_icon_microsite.svg`} alt='C' />
					)}
					{micrositeData?.media?.whatsApp && (
						<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/whatsapp_icon_microsite.svg`} alt='C' />
					)}
				</Box>
			</Box>
		</Dialog>
	)
}

export default SuccessModal
