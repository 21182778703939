import React from 'react'
import { InputAdornment, Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	contactNumber: {
		'& .MuiOutlinedInput-root': {
			paddingInline: 0,
		},
		'& .MuiAutocomplete-root': {
			'& .MuiAutocomplete-endAdornment': {
				right: 0,
			},
		},
		'& .MuiInputAdornment-positionStart': {
			'& .MuiAutocomplete-root': {
				'& .MuiTextField-root': {
					'& .MuiOutlinedInput-root': {
						padding: '6px',
						'& fieldset': {
							border: 'none',
							borderRight: '1px solid #959ca9',
							borderRadius: 0,
						},
					},
				},
			},
		},
		'& .MuiInputAdornment-positionEnd': {
			paddingRight: 8,
		},
		'& .MuiInputAdornment-root': {
			marginLeft: 8,
		},
		[theme.breakpoints.down('lg')]: {
			'& .MuiInputAdornment-positionEnd': {
				paddingRight: 0,
			},
			'& .MuiInputAdornment-root': {
				marginLeft: 0,
			},
		},
	},
	TextField: {
		'& .MuiOutlinedInput-root': {
			paddingLeft: 0,
			'& fieldset': {
				border: 'none',
			},
		},
	},
}))
const CountryCodeAdornment = (props) => {
	const classes = useStyles()
	const { disabled, value, onChange, countryCodeList, loading } = props
	return (
		<>
			<InputAdornment position='start' className={classes.adornment}>
				<Autocomplete
					id='countrycode'
					name='countrycode'
					size='small'
					sx={{ width: 69 }}
					disableClearable
					loading={loading}
					loadingText='Loading...'
					noOptionsText=''
					disabled={disabled}
					options={countryCodeList || []}
					value={value || null}
					getOptionLabel={(option) => option?.mastLookupKey || ''}
					onChange={onChange}
					renderInput={(params) => <TextField size='small' className={classes.TextField} {...params} />}
				/>
			</InputAdornment>
		</>
	)
}
const CountryCodeEndAdornment = ({ endAdornment }) => {
	return (
		<>
			<InputAdornment position='end'>{endAdornment}</InputAdornment>
		</>
	)
}
const CustomTextFieldWithCountryCode = (props) => {
	const {
		loading,
		value,
		onChange,
		countryCodeValue,
		countryCodeList,
		disabled,
		countryCodeOnChange,
		error,
		helperText,
		disableCountryCode = false,
		endAdornment,
		onBlur,
		formik,
	} = props
	const classes = useStyles()

	return (
		<TextField
			id='mobilenumber'
			name='mobilenumber'
			{...props}
			value={value ? value : ''}
			fullWidth
			disabled={disabled}
			className={classes.contactNumber}
			onChange={(e) => onChange(e)}
			onBlur={onBlur}
			InputProps={{
				startAdornment: (
					<CountryCodeAdornment
						disabled={disableCountryCode}
						value={countryCodeValue || {}}
						onChange={(e, newValue) => {
							countryCodeOnChange(e, newValue)
						}}
						countryCodeList={countryCodeList}
						loading={loading}
						formik={formik}
					/>
				),
				endAdornment: <CountryCodeEndAdornment endAdornment={endAdornment} />,
			}}
			inputProps={{ maxLength: countryCodeValue?.mastLookupValue || 15 }}
			error={error}
			helperText={helperText}
		/>
	)
}
export default CustomTextFieldWithCountryCode
