import React, { useState, useRef, useEffect, useMemo } from 'react'
import { TextField, Autocomplete, Box, Grid, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { debounce } from '@mui/material/utils'
import parse from 'autosuggest-highlight/parse'
import themesConfig from 'app/fuse-configs/themesConfig'
import { makeStyles } from '@mui/styles'
import { useGoogleMaps } from '../GoogleMapsProvider'

const useStyles = makeStyles((theme) => ({
	textfield: {
		paddingBlock: 8,
		minWidth: 300,
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderRadius: 8,
			borderColor: 'red',
		},
		'& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
			borderRadius: 8,
			borderColor: 'red',
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: themesConfig?.lyfngo.palette.Gray.main,
		},
		'& .MuiOutlinedInput-input': {
			borderRadius: '4px',
		},
		'& .descriptionfield .MuiOutlinedInput-root ': {
			paddingBottom: '36px',
		},
	},
}))

const PlacesAutocomplete = ({ onPlaceSelected, setValue, value, placeholder, label, error, helperText, onBlur }) => {
	const classes = useStyles()
	const [inputValue, setInputValue] = useState('')
	const [options, setOptions] = useState([])
	const autocompleteService = useRef(null)
	const { isLoaded } = useGoogleMaps()

	const fetch = useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback)
			}, 400),
		[]
	)

	useEffect(() => {
		let active = true

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === '') {
			setOptions(value ? [value] : [])
			return undefined
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = []

				if (value) {
					newOptions = [value]
				}

				if (results) {
					newOptions = [...newOptions, ...results]
				}

				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	const handleOptionSelected = (event, newValue) => {
		if (newValue) {
			const placeId = newValue.place_id
			const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
			setValue(newValue.description)

			placesService.getDetails({ placeId }, (place, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					const addressComponents = place.address_components
					const postalCode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || ''
					const country = addressComponents.find((component) => component.types.includes('country'))?.long_name || ''
					const countryShortName = addressComponents.find((component) => component.types.includes('country'))?.short_name || ''
					const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || ''
					const lat = place.geometry?.location?.lat() || ''
					const lng = place.geometry?.location?.lng() || ''
					onPlaceSelected({
						...place,
						postalCode,
						country,
						countryShortName,
						state,
						lat,
						lng,
					})
				}
			})
		}
	}

	if (!isLoaded) {
		return <div></div>
	}

	return (
		<Autocomplete
			disableClearable
			value={value}
			onChange={handleOptionSelected}
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
			options={options}
			noOptionsText={'Enter search location'}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x) => x}
			autoComplete
			includeInputInList
			filterSelectedOptions
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={placeholder}
					variant='outlined'
					size='small'
					error={error}
					helperText={helperText}
					onBlur={onBlur}
				/>
			)}
			renderOption={(props, option) => {
				const matches = option.structured_formatting.main_text_matched_substrings || []
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				)

				return (
					<li {...props}>
						<Grid container alignItems='center'>
							<Grid item sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }} />
							</Grid>
							<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								{parts.map((part, index) => (
									<Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
										{part.text}
									</Box>
								))}
								<Typography variant='body2' color='text.secondary'>
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				)
			}}
		/>
	)
}

export default PlacesAutocomplete
