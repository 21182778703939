import { React, useState, useEffect, useCallback } from 'react'
import ChangeMailApi from 'services/changeMailId/api'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Typography, useMediaQuery, Button, IconButton } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import ButtonComp from '../../../../sharedComponents/Button'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { Link } from 'react-router-dom'
import { Otp, updateDeviceId, updateIswelcome, updatePracticeDetails } from 'app/session/actions'
import history from '@history'
import 'react-confirm-alert/src/react-confirm-alert.css'
import OtpApi from 'services/otp/api'
import LoginApi from 'services/login/api'
import DynamicMenuApi from 'services/DynamicMenu/api'
import { detect } from 'detect-browser'
import { encryption, decryption, getResponseMessage, failureLogin, getMenuFeatures } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import OtpInput from 'react-otp-input'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import CloseIcon from '@mui/icons-material/Close'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import secureLocalStorage from 'react-secure-storage'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import themesConfig from 'app/fuse-configs/themesConfig'
import moment from 'moment'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import { setVerifyModal } from 'services/VerficationModal/actions'
import { closeOtpModal } from 'services/otpModal/action'

const useStyles = makeStyles((theme) => ({
	modalbox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		background: '#fff',
		border: '1px solid #fff',
		borderRadius: '12px',
		boxShadow: 24,
		paddingBlock: '40px',
		paddingInline: '40px',
		'& .MuiTypography-h6': {
			fontSize: '16px',
		},
		'& .MuiTypography-h5': {
			fontSize: '14px',
		},
		'& .MuiTypography-h4': {
			fontSize: '14px',
		},
		[theme.breakpoints.down('xs')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
		[theme.breakpoints.down('md')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
		[theme.breakpoints.down('sm')]: {
			paddingBlock: '20px',
			paddingInline: '20px',
			width: 250,
		},
	},
	emailtext: {
		display: 'flex',
		flexDirection: 'column',
		gap: 18,
	},
	textField: {
		width: '100%',
		margin: '10px 0px',
	},
	heading: {
		color: '#E22C24',
		fontWeight: 'bold',
		fontFamily: 'Poppins',
	},
	subheading: {
		color: '#707680',
	},
	autocomplete: {
		marginBottom: '18px',
		height: '5rem',
	},
	btn: {
		backgroundColor: '#414A58',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#252F3E',
		},
	},
	Link: {
		color: '#2658DC',
	},

	enabled: {
		textDecoration: 'underline',
		color: themesConfig.greyDark.palette.primary.main,
		cursor: 'pointer',
	},
	disabled: {
		color: '#e0e0e0',
	},
	otpSection: {
		display: 'flex',
		justifyContent: 'center',
	},
}))
export default function OTPVerificationModal({ onClose, handleNext }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const browser = detect()
	const [otpNumber, setOtpNumber] = useState('')
	const [otpError, setOtpError] = useState('')
	const modalOpen = useSelector((state) => state?.otpverificationModal?.open)
	const state = useSelector((state) => state.Authentication)
	const geoInfo = useSelector((state) => state?.GeoInfo?.geoInfo)
	const {
		isWhatsappOtp,
		mobileNo,
		countryCode,
		userType,
		identityUuid,
		isOtp,
		AidiPracticeDetails,
		uuid,
		newOwner,
		groupId,
		userEmail,
		isEmail,
		isPractice,
		isWelcome,
		userRole,
	} = state
	const errorJson = useSelector((states) => states.errorMessages || {})
	const [resendDisabled, setResendDisabled] = useState(false)
	const [countdown, setCountdown] = useState(60) // 2 minutes in seconds
	const [autoSubmit, setAutoSubmit] = useState(false)
	const [deviceData, setDeviceData] = useState({})

	useEffect(() => {
		const onSuccess = (res) => {
			setDeviceData(res?.data)
		}
		const onFailure = (err) => {
			console.log(err)
			setDeviceData({})
		}
		LoginApi.getDeviceData().then(onSuccess, onFailure)
	}, [])

	const handleChange = (value) => {
		setOtpNumber(value)
		if (value.length === 4) {
			setAutoSubmit(true)
		}
	}

	useEffect(() => {
		if (modalOpen) {
			setResendDisabled(true)
			setCountdown(120)
			startCountdown()
		}
	}, [modalOpen])

	const startCountdown = () => {
		let timer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown === 0) {
					clearInterval(timer)
					setResendDisabled(false)
					return 0
				}
				return prevCountdown - 1
			})
		}, 1000) // Decrease countdown every second
	}

	// const roleNavigated = useCallback(() => {
	// 	dispatch(showLoader('Loading please wait...'))
	// 	let uuid = secureLocalStorage.getItem('uuid')
	// 	let mastUuid = secureLocalStorage.getItem('mastuuid')
	// 	const onSuccess = (res) => {
	// 		dispatch(hideLoader())
	// 		if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
	// 			secureLocalStorage.clear()
	// 			history.push('/')
	// 			dispatch(
	// 				showMessage({
	// 					message: 'Please Re-login Due to some technical issue',
	// 					autoHideDuration: 3000,
	// 					anchorOrigin: {
	// 						vertical: 'top',
	// 						horizontal: 'right',
	// 					},
	// 					variant: 'error',
	// 				})
	// 			)
	// 		} else {
	// 			history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
	// 			let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
	// 			let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
	// 			let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
	// 			dispatch(
	// 				updateDynamicMenu({
	// 					userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
	// 					list: res?.data?.data?.dynamicMenu || [],
	// 					features: getMenuFeatures(res?.data?.data?.dynamicMenu),
	// 				})
	// 			)
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		dispatch(hideLoader())
	// 		secureLocalStorage.clear()
	// 		history.push('/')
	// 		dispatch(
	// 			showMessage({
	// 				message: 'Please Re-login Due to some technical issue',
	// 				autoHideDuration: 3000,
	// 				anchorOrigin: {
	// 					vertical: 'top',
	// 					horizontal: 'right',
	// 				},
	// 				variant: 'error',
	// 			})
	// 		)
	// 	}
	// 	dispatch(dynamicMenuFetching())
	// 	DynamicMenuApi.getDynamicMenu(uuid, mastUuid).then(onSuccess, onFailure)
	// }, [dispatch])

	// useEffect(() => {
	// 	if (uuid === '' || uuid === undefined) {
	// 		history.push('/')
	// 	}
	// }, [uuid])

	const updateDeviceDetails = (userType, uuid) => {
		const os = browser.os
		const osName = os.split(' ')
		dispatch(showLoader('Loading please wait...'))
		const body = {
			browserName: browser.name,
			deviceId: '',
			deviceName: '',
			deviceToken: '',
			ipAddress: deviceData?.ipAddress || '',
			locCity: deviceData?.cityName || '',
			locCountry: deviceData?.countryName || '',
			locRegion: deviceData?.regionName || '',
			loginTime: moment().format(),
			logoutTime: '',
			model: '',
			osName: os,
			osVersion: osName[1],
			qrStatus: false,
			sessionDetails: '',
			status: true,
			version: '',
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				if (_.isEqual(newOwner, true)) {
					// setGenerate(true)
					dispatch(hideLoader())
					secureLocalStorage.setItem('AidiDeviceId', successData?.data?.deviceUuid)
					dispatch(updateDeviceId(successData?.data?.deviceUuid))
					let practice_details = AidiPracticeDetails
					if (practice_details === undefined || practice_details === null) {
						// getPracticeDetails(uuid)
					}
					if (uuid === '' || uuid === undefined) {
						history.push('/')
					} else if (!isPractice) {
						// history.push('/establishmentselection')
					} else if (!isWelcome) {
						if (userRole === 'Owner') {
							// history.push('/moduleselection')
						} else {
							secureLocalStorage.setItem('isWelcome', true)
							dispatch(updateIswelcome(true))
							// roleNavigated()
						}
					} else {
						// roleNavigated()
					}
				} else {
					// setGenerate(true)
					dispatch(hideLoader())
					secureLocalStorage.setItem('AidiDeviceId', successData?.data?.deviceUuid)
					dispatch(updateDeviceId(successData?.data?.deviceUuid))
					let practice_details = AidiPracticeDetails
					if (practice_details === undefined || practice_details === null) {
						// getPracticeDetails(uuid)
					}
					if (uuid === '' || uuid === undefined) {
						history.push('/')
					} else if (groupId === 'null' || groupId === '' || groupId === null) {
						// history.push('/groupselection')
					}
					//  else if (isEmail === 'false' || isEmail === false) {
					// 	dispatch(setVerifyModal({ open: true, data: userEmail, mode: 'userVerification' }))
					// }
					else if (!isPractice) {
						// history.push('/establishmentselection')
					} else if (!isWelcome) {
						if (userRole === 'Owner') {
							// history.push('/moduleselection')
						} else {
							secureLocalStorage.setItem('isWelcome', true)
							dispatch(updateIswelcome(true))
							// roleNavigated()
						}
					} else {
						// roleNavigated()
					}
				}
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		LoginApi.saveActiveDevices(userType, identityUuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const submitOtpConfirmation = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			otpNumber: otpNumber,
			sentEmail: false,
			userType: userType,
			uuid: identityUuid,
			isExpired: counter === 3 ? true : false,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				updateDeviceDetails(userType, uuid)
				secureLocalStorage.setItem('AidiBearer', successData?.data?.Bearer)
				secureLocalStorage.setItem('aidivaTenantUserEmail', successData.emailId)
				secureLocalStorage.setItem('isMobile', true)
				if (!isOtp) {
					secureLocalStorage.setItem('isEmail', false)
				}
				let dispData = !isOtp
					? {
							emailId: successData.emailId,
							bearer: successData?.data?.Bearer || null,
							isEmail: 'false',
					  }
					: {
							emailId: successData.emailId,
							bearer: successData?.data?.Bearer || null,
							isMobile: true,
					  }
				dispatch(Otp(dispData))
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				handleNext()
				dispatch(closeOtpModal({ open: false }))
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.setOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const validate = () => {
		if (otpNumber === '') {
			setOtpError('Please enter OTP')
		} else if (otpNumber.length !== 4) {
			setOtpError('Enter a valid otp')
		} else if (otpNumber.length === 4) {
			setOtpError('')
			submitOtpConfirmation()
		}
	}

	// const getPracticeDetails = (uuid) => {
	// 	dispatch(showLoader('Loading please wait...'))
	// 	const onSuccess = (res) => {
	// 		const successData = decryption(res)
	// 		dispatch(hideLoader())
	// 		if (successData.status === 'success') {
	// 			let data = !_.isEmpty(successData.data.data) ? successData.data.data[0] : []
	// 			secureLocalStorage.setItem('mastuuid', data.master_tenant_uuid)
	// 			secureLocalStorage.setItem('tentantuuid', data.mast_tent_type_uuid)
	// 			if (data.length > 0) {
	// 				dispatch(updatePracticeDetails(data))
	// 			}
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		const failureData = failureLogin(err)
	// 		dispatch(hideLoader())
	// 		dispatch(
	// 			showMessage({
	// 				message: getResponseMessage(failureData, errorJson),
	// 				autoHideDuration: 3000,
	// 				anchorOrigin: {
	// 					vertical: 'top',
	// 					horizontal: 'right',
	// 				},
	// 				variant: 'error',
	// 			})
	// 		)
	// 	}
	// 	OtpApi.getActiveList(identityUuid).then(onSuccess, onFailure)
	// }

	const [counter, setCounter] = useState(0)
	const [disable, setDisable] = useState(false)
	const [checkColor, setCheckColor] = useState('')
	const colorStyle = { color: themesConfig.greyDark.palette.primary.main }
	const disabled = { color: '#e0e0e0' }
	const resendOtp = () => {
		setCounter(counter + 1)
		secureLocalStorage.setItem('resendOtpBtn', counter)
		if (counter === 2) {
			setDisable(true)
			setCheckColor(!checkColor)
		} else {
			setDisable(false)
		}
		dispatch(showLoader('Loading please wait...'))
		const body = {
			countryCode: countryCode,
			mobileNo: mobileNo,
			userType: userType,
			uuid: identityUuid,
			email: userEmail,
			isWhatsApp: isWhatsappOtp,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			setOtpNumber('')
			dispatch(hideLoader())
			if (successData.status === 'success') {
				let successMessage = successData.message
				if (successMessage === 'suc_sendOtp') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorJson, 'OTP sent successully'),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
				}
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		OtpApi.resendOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const onSubmit = (e) => {
		// e.preventDefault()
		validate()
	}
	useEffect(() => {
		if (autoSubmit && otpNumber.length === 4) {
			onSubmit()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoSubmit, otpNumber])

	return (
		<>
			<Modal keepMounted open={modalOpen} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<IconButton
						aria-label='close'
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: themesConfig.lyfngo.palette.dark.main,
						}}
					>
						<CloseIcon />
					</IconButton>
					<form
						onSubmit={(e) => {
							onSubmit(e)
						}}
					>
						<div className={classes.emailtext}>
							<Typography
								id='keep-mounted-modal-title'
								fontSize={18}
								color={themesConfig.lyfngo.palette.primary.main}
								fontWeight={600}
								variant='body'
								align='center'
							>
								Verify your mobile number
							</Typography>
							<Typography
								id='keep-mounted-modal-title'
								fontSize={'14px'}
								color={themesConfig.lyfngo.palette.dark.main}
								fontWeight={600}
								variant='body1'
								align='center'
							>
								Enter the OTP sent to your mobile number
							</Typography>
							<Typography
								id='keep-mounted-modal-title'
								fontSize={'14px'}
								color={themesConfig.lyfngo.palette.dark.main}
								fontWeight={600}
								className='flex items-center justify-center gap-3'
								variant='body1'
								align='center'
							>
								{isWhatsappOtp ? (
									<span style={{ color: '#25D366' }}>
										<WhatsAppIcon />
									</span>
								) : (
									<span style={{ color: '#0062DD' }}>
										<TextsmsOutlinedIcon />
									</span>
								)}
								{countryCode} {mobileNo}
							</Typography>
							<div className={classes.otpSection}>
								<OtpInput
									type='password'
									value={otpNumber}
									onChange={handleChange}
									numInputs={4}
									isInputNum={true}
									separator={<span>&nbsp;&nbsp;&nbsp;</span>}
									focusStyle={{
										border: '1px solid #0062DD',
										borderRadius: 4,
										color: '#0062DD',
										outline: 'none',
									}}
									// shouldAutoFocus={shoudFocus}
									inputStyle={{
										border: '1px solid #6b7280',
										borderRadius: 4,
										width: '40px',
										height: '40px',
										fontSize: '12px',
										fontWeight: 600,
										color: '#000',
									}}
								/>
							</div>
							{otpError ? (
								<Typography align='center' color='error'>
									{otpError}
								</Typography>
							) : null}
							<ButtonComp type='submit' onClick={onSubmit}>
								Verify Now
							</ButtonComp>
							{resendDisabled && (
								<Typography variant='subtitle1' fontSize={14} fontWeight={500} align='center'>
									Resend OTP in: {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}
									{countdown % 60} seconds
								</Typography>
							)}
							{!resendDisabled && (
								<div className='flex sm:flex-col xs:flex-col md:flex-row justify-between items-center '>
									<Typography fontWeight={500} fontSize={14}>
										Don't receive the OTP?
									</Typography>
									<Button
										// className='underline cursor-pointer'
										// fontSize={14}
										// fontWeight={500}
										// color={themesConfig.lyfngo.palette.primary.main}
										style={checkColor ? disabled : colorStyle}
										onClick={resendOtp}
										disabled={disable ? true : false}
									>
										Resend Now
									</Button>
								</div>
							)}
							{/* <Typography
								align='center'
								className='cursor-pointer'
								fontWeight={500}
								fontSize={14}
								color={themesConfig.lyfngo.palette.primary.main}
								onClick={() => onClose()}
							>
								<span style={{ color: themesConfig.lyfngo.palette.primary.main }}>
									<KeyboardBackspaceIcon />
								</span>{' '}
								Change mobile number
							</Typography> */}
						</div>
					</form>
				</Box>
			</Modal>
		</>
	)
}
