import { Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import secureLocalStorage from 'react-secure-storage'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
	link: {
		cursor: 'pointer',
		fontSize: '20px',
	},
}))

function Error403Page({ history }) {
	const classes = useStyles()
	const backToLogin = () => {
		secureLocalStorage.clear()
		history.push('/')
	}
	return (
		<div className='flex flex-col flex-1 items-center justify-center p-16'>
			<div className='max-w-512 text-center'>
				<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}>
					<img width='350' src={`${process.env.REACT_APP_IMAGEKIT_URL}/LyfngoDev/B2B_Flash/Icons/error.svg`} alt='something went wrong' />
				</motion.div>
			</div>
			<Link pt={2} className={classnames(classes.link, 'font-normal')} onClick={backToLogin}>
				Go back
			</Link>
		</div>
	)
}

export default Error403Page
