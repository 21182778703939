import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 5,
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: 5,
		marginRight: 8,
		width: 50,
		height: 50,
		padding: 4,
		boxSizing: 'border-box',
	},
	thumbInne: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
	},
	container: {
		// padding: 14,
		borderRadius: 14,
		display: 'flex',
		alignItems: 'center',
		gap: 16,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			gap: 0,
		},
	},
	dropzone: {
		display: 'flex',
		cursor: 'pointer',
		gap: 8,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: 100,
		height: 100,
		boxShadow: '0 4px 8px 0 rgb(0 0 0 / 6%), 0px 1px 20px 0 rgb(255 255 255 /12%)',
		opacity: 1,
		marginBlock: 12,
		borderRadius: 18,
		'& .MuiButton-root': {
			color: '#636669',
			border: '1px solid #636669',
		},
	},
	icon: {
		color: '#767676',
		marginTop: theme.spacing(1),
		fontSize: 38,
	},
	dragText: {
		color: '#767676',
		marginTop: theme.spacing(1),
	},
	mainImg: {
		width: 100,
		height: 100,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		overflow: 'hidden',
		borderRadius: 12,
		marginBlock: 12,
	},
	deletIcon: {
		cursor: 'pointer',
	},
	mainImgUpload: {
		display: 'flex',
		gap: 6,
	},
	mainContainer: {
		display: 'flex',
		gap: 24,
		alignItems: 'center',
	},
	profileData: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
		},
	},
	userLogo: {
		width: 100,
		height: 100,
		borderRadius: 12,
	},
	tooltip: {
		background: '#fff',
		color: '000',
		'& .MuiTooltip-tooltip': {
			color: '#000',
			background: '#fff',
		},
	},
	profilephoto: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
			fontWeight: 600,
		},
		[theme.breakpoints.up('sm')]: {
			fontWeight: 600,
			fontSize: 16,
		},
	},
	removeBtn: {
		color: '#2A2A2A',
		backgroundColor: '#F5F4F6',
		'&:hover': {
			backgroundColor: '#f5f4f6',
		},
	},
	helperTextContainer: {
		marginTop: 8,
		'& .MuiTypography-body1 ': {
			fontSize: 12,
			color: '#808080',
		},
	},
}))

export default useStyles
