import { ACTION_TYPES } from '../../../constants'

export const setVerifyModal = (payload) => ({
	type: ACTION_TYPES.SET_VERIFY_MODAL,
	payload,
})
export const closeVerifyModal = (payload) => ({
	type: ACTION_TYPES.CLOSE_VERIFY_MODAL,
	payload,
})
