import React from 'react'
import { makeStyles } from '@mui/styles'
import { Drawer } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 324,
		flexShrink: 0,
	},
	drawerPaper: {
		width: (props) => (props?.infoDrawer ? 512 : 386),
		position: 'fixed',
		top: 120,
		padding: (props) => (props?.infoLeadsDrawer ? 0 : 16),
	},
}))

const FilterDrawer = (props) => {
	const classes = useStyles(props)
	const { children, toggleDrawer, isOpen, variant = 'temporary' } = props
	return (
		<div>
			<Drawer
				className={classes.drawer}
				anchor='right'
				variant={variant}
				open={isOpen}
				onClose={toggleDrawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				BackdropProps={{
					invisible: true,
				}}
			>
				{children}
			</Drawer>
		</div>
	)
}

export default FilterDrawer
