import Provider from 'react-redux/es/components/Provider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { StyledEngineProvider } from '@mui/material/styles'
import AppContext from './AppContext'
import routes from './fuse-configs/routesConfig'
import NetworkService from '../services/network'
import store from './store'
import FuseSuspense from '@fuse/core/FuseSuspense'

NetworkService.setupInterceptors(store)
const withAppProviders = (Component) => (props) => {
	const WrapperComponent = () => (
		<AppContext.Provider
			value={{
				routes,
			}}
		>
			<FuseSuspense>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<Provider store={store}>
						<StyledEngineProvider injectFirst>
							<Component {...props} />
						</StyledEngineProvider>
					</Provider>
				</LocalizationProvider>
			</FuseSuspense>
		</AppContext.Provider>
	)

	return WrapperComponent
}

export default withAppProviders
