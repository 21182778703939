import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	letsStart: secureLocalStorage.getItem('letsStart') ? (secureLocalStorage.getItem('letsStart') === 'true' ? true : false) : true,
}

const letsStartBtn = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.LETS_START_BTN: {
			secureLocalStorage.setItem('letsStart', action.payload)
			return { letsStart: action.payload }
		}
		default:
			return state
	}
}
export default letsStartBtn
