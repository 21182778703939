import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	moduleSelection: () => {
		return axios.get(`${API_ENDPOINTS.MODULE_SELECTION}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true },
		})
	},
	moduleSelectionNew: (mastTentTypeUuid) => {
		return axios.get(`${API_ENDPOINTS.MODULE_SELECTION_NEW}`, {
			headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { mastTentTypeUuid } },
		})
	},
	postModule: (mastUuid, identityUuid, tentUserUuid, body) => {
		return axios.post(`${API_ENDPOINTS.POST_MODULES_SELECTION}`, body, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf(), path: { mastUuid, identityUuid, tentUserUuid } },
		})
	},
}
