import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, MenuItem, Menu, IconButton } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateRangePicker from '@mui/lab/DateRangePicker'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { customDateFormat } from 'utils'
import clsx from 'clsx'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	datePickerContainer: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiFormControl-root': {
			width: 120,
		},
		'& .Mui-focused': {
			color: 'inherit',
		},
		'& fieldset': {
			border: 'none',
		},
		'& input': {
			fontWeight: 500,
			fontSize: '1.34rem',
		},
		'& label': {
			background: '#FFF',
			paddingInline: 4,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 'unset',
		},
	},
	dropDown: {
		border: '1px solid rgb(189 189 189)',
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		'& ul': {
			background: '#B2c',
		},
	},
	datePickerContainerBorder: {
		border: '1px solid rgb(189 189 189)',
		paddingBlock: 1,
		borderRightStyle: 'none',
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4,
	},

	menuContainer: {
		'& .MuiPaper-root': {
			width: 198,
		},
	},
	menuListItem: {
		marginInline: 12,
		marginBlock: 4,
		borderRadius: 4,
		'&:hover': {
			background: themesConfig.lyfngo.dropDown.light,
		},
	},
	selectedItem: {
		background: themesConfig.lyfngo.dropDown.main,
		'&:hover': {
			background: themesConfig.lyfngo.dropDown.main,
		},
	},
}))

function DateRangePick({ getDateRange, getDateRangeForPrint }) {
	const classes = useStyles()
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)

	const SelectableOptions = [
		{
			mastLookupKey: 'TDY',
			mastLookupValue: 'Today',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'L7D',
			mastLookupValue: 'Last 7 days',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'MTH',
			mastLookupValue: 'Last month',
			mastLookupType: 'WFL',
		},

		{
			mastLookupKey: 'LYR',
			mastLookupValue: 'Last year',
			mastLookupType: 'WFL',
		},
	]

	const [value, setValue] = useState([moment().format(), moment().format()])
	const [anchorEl, setAnchorEl] = useState(null)
	const [selectedIndex, setSelectedIndex] = useState(0)

	const open = Boolean(anchorEl)

	const handleClose = (event) => {
		setAnchorEl(null)
	}

	const dateFromSelect = (selectedOption) => {
		if (selectedOption === 'L7D') {
			setValue([moment().subtract(1, 'w').format(), moment().format()])
			getDateRange([`${moment().subtract(1, 'w').format('YYYY-MM-DD')}`, `${moment().format('YYYY-MM-DD')}`])
			// getDateRangeForPrint(`${moment().subtract(1, 'w').format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`)
			getDateRangeForPrint(
				`${customDateFormat(new Date(moment().subtract(1, 'w')), dateFormatForViewer)} - ${customDateFormat(new Date(moment()), dateFormatForViewer)}`
			)

			setSelectedIndex(1)
		} else if (selectedOption === 'LYR') {
			setValue([moment().subtract(1, 'y').format(), moment().format()])
			getDateRange([`${moment().subtract(1, 'y').format('YYYY-MM-DD')}`, `${moment().format('YYYY-MM-DD')}`])
			// getDateRangeForPrint(`${moment().subtract(1, 'y').format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`)
			getDateRangeForPrint(
				`${customDateFormat(new Date(moment().subtract(1, 'y')), dateFormatForViewer)} - ${customDateFormat(new Date(moment()), dateFormatForViewer)}`
			)

			setSelectedIndex(3)
		} else if (selectedOption === 'MTH') {
			setValue([moment().subtract(1, 'M').format(), moment().format()])
			getDateRange([`${moment().subtract(1, 'M').format('YYYY-MM-DD')}`, `${moment().format('YYYY-MM-DD')}`])
			// getDateRangeForPrint(`${moment().subtract(1, 'M').format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`)
			getDateRangeForPrint(
				`${customDateFormat(new Date(moment().subtract(1, 'M')), dateFormatForViewer)} - ${customDateFormat(new Date(moment()), dateFormatForViewer)}`
			)

			setSelectedIndex(2)
		} else if (selectedOption === 'TDY') {
			setValue([moment().format(), moment().format()])
			getDateRange([`${moment().format('YYYY-MM-DD')}`])
			// getDateRangeForPrint(moment().format('DD MMM YYYY'))
			getDateRangeForPrint(`${customDateFormat(new Date(moment()), dateFormatForViewer)}`)

			setSelectedIndex(0)
		} else {
			if (moment(selectedOption[0]).format('DD-MM-YYYY') !== 'Invalid date' && moment(selectedOption[1]).format('DD-MM-YYYY') !== 'Invalid date') {
				if (moment(selectedOption[0]).format('DD-MM-YYYY') === moment(selectedOption[1]).format('DD-MM-YYYY')) {
					getDateRange([`${moment().format('YYYY-MM-DD')}`])
					// getDateRangeForPrint(moment().format('DD MMM YYYY'))
					getDateRangeForPrint(`${customDateFormat(new Date(moment()), dateFormatForViewer)}`)
					setSelectedIndex(10)
				} else {
					getDateRange([`${moment(selectedOption[0]).format('YYYY-MM-DD')}`, `${moment(selectedOption[1]).format('YYYY-MM-DD')}`])
					// getDateRangeForPrint(`${moment(selectedOption[0]).format('DD MMM YYYY')} - ${moment(selectedOption[1]).format('DD MMM YYYY')}`)
					getDateRangeForPrint(
						`${customDateFormat(new Date(moment(selectedOption[0])), dateFormatForViewer)} - ${customDateFormat(
							new Date(moment(selectedOption[1])),
							dateFormatForViewer
						)}`
					)
					setSelectedIndex(10)
				}
			}
		}

		handleClose()
	}
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)
	return (
		<div className={classes.datePickerContainer}>
			<div className={classes.datePickerContainerBorder}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateRangePicker
						startText={'Select period'}
						endText={''}
						calendars={1}
						value={value}
						inputFormat={dateFormat}
						minDate={new Date('2016-01-01')}
						disableFuture={true}
						onChange={(newValue) => {
							setValue(newValue)
							dateFromSelect(newValue)
						}}
						renderInput={(startProps, endProps) => (
							<React.Fragment>
								<TextField size='small' {...startProps} />
								<span> to </span>
								<TextField size='small' {...endProps} />
							</React.Fragment>
						)}
					/>
				</LocalizationProvider>
			</div>
			<div className={classes.dropDown}>
				<IconButton
					id='basic-button'
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
					onClick={(event) => {
						setAnchorEl(event.currentTarget)
					}}
					size='medium'
				>
					{open ? <ArrowDropUp /> : <ArrowDropDown />}
				</IconButton>
				<Menu
					id='basic-menu'
					className={classes.menuContainer}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					{SelectableOptions.map((option, idx) => (
						<MenuItem
							key={idx}
							value={option?.mastLookupKey}
							onClick={() => dateFromSelect(option?.mastLookupKey)}
							className={clsx(classes.menuListItem, idx === selectedIndex && classes.selectedItem)}
						>
							{option?.mastLookupValue}
						</MenuItem>
					))}
				</Menu>
			</div>
		</div>
	)
}

export default DateRangePick
