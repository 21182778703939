import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getAccessRole: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_MEDEACCESS_ROLE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getAccessRoleTableList: (mastuuid, role, params) => {
		return axios.get(API_ENDPOINTS.GET_ACCESSROLE_TABLE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid, role } },
			params: { ...params },
		})
	},
	addNewUser: (publicKey, ivKey, mastuuid, identityUuid) => {
		const result = { data: publicKey }
		return axios.post(API_ENDPOINTS.ADD_NEW_USER, result, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { mastuuid, identityUuid }, key: `${ivKey}` },
		})
	},
	getUserIndividualDetails: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_INDIVIDUAL_USER, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	getCommissionManagement: (mastTentUuid) => {
		return axios.get(API_ENDPOINTS.GET_COMMISSION_TYPE, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
		})
	},

	getPracticeSpecaility: (mastuuid) => {
		return axios.get(API_ENDPOINTS.GET_SPECIALITY_TENTID, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastuuid } },
		})
	},
	updateUserDetails: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.patch(`${API_ENDPOINTS.USER_DETAILS_PUT}`, result, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				key: `${ivKey}`,
			},
		})
	},
	userDetailsDelete: (data) => {
		return axios.delete(`${API_ENDPOINTS.USER_DELETE}`, data, {
			headers: {
				isAuthRequired: true,
				'X-SECURITY': csrf(),
				'Content-Type': 'application/json',
				// path: { editUuid },
			},
		})
	},
}
