import { ACTION_TYPES } from '../../../../constants'

export const setCurrentEventList = (payload) => ({
	type: ACTION_TYPES.CURRENT_EVENT_LIST,
	payload,
})

export const setIsEditEvent = (payload) => ({
	type: ACTION_TYPES.IS_EDIT_EVENT,
	payload,
})

export const setSavedEvent = (payload) => ({
	type: ACTION_TYPES.SAVED_EVENT,
	payload,
})
