import { Card, CardContent, TextField, IconButton } from '@mui/material'
import { Icon, Button, InputAdornment, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as yup from 'yup'
import { showMessage } from 'app/store/fuse/messageSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import generateMailToken from '../../../services/generateEmailToken/api'
import { hideLoader, showLoader } from 'services/loader/action'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { encryption, decryption, getResponseMessage, failureLogin } from 'utils'
import themesConfig from 'app/fuse-configs/themesConfig'
import { setVerifyModal } from 'services/VerficationModal/actions'
import EmailVerificationModal from '../EmailVerificationModal/EmailVerificationModal'
import { useEffect } from 'react'
import { closeVerifyModal } from 'services/VerficationModal/actions'
import ForgetPassword from 'images/login/ForgetPassword.png'

const Root = styled('div')(({ theme }) => ({
	background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {
		maxWidth: 400,
	},

	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .cardContent': {
		paddingBlock: '12px',
		paddingInlineStart: '28px',
	},
	'& .text': {
		margin: '16px 0',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid black',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .backButton': {
		background: themesConfig.greyDark.palette.primary.main,
		marginInline: '20px',
		'& .MuiSvgIcon-root': {
			fill: '#fff',
		},
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.main,
		},
	},
}))

const ButtonStyle = styled('Button')(({ theme }) => ({
	'& .btn': {
		color: '#fff',
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
export default function ResetEmail({ history }) {
	const state = useSelector((state) => state.Authentication)
	const { uuid, userType, groupId, identityUuid } = state
	const dispatch = useDispatch()
	const schema = yup.object().shape({
		email: yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
	})
	const { control, formState, handleSubmit, reset } = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema),
		enableReinitialize: true,
	})
	useEffect(() => {
		dispatch(closeVerifyModal())
	}, [dispatch])
	const { errors } = formState
	const errorJson = useSelector((states) => states.errorMessages)
	function onSubmit(data) {
		GenerateMail(data)
	}
	const GenerateMail = (data) => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			emailType: 'new',
			isForgotPassword: false,
			isGenerateEmail: false,
			mastTentGroupUuid: groupId,
			userName: data?.email,
			userType: userType,
			uuid: identityUuid,
			isWelcomeMail: true,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(setVerifyModal({ open: true, data: data?.email, mode: 'changeEmail' }))
				reset({
					email: '',
				})
			} else {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		generateMailToken.setEmail(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const handleClick = () => {
		history.goBack()
	}

	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				<div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden'
				>
					<Card className='Login-leftSection flex flex-col w-full max-w-sm items-start justify-evenly shadow-0 p-16' square>
						<IconButton className='backButton' onClick={handleClick}>
							<ArrowBackIcon />
						</IconButton>
						<CardContent className='cardContent flex flex-col items-center justify-center w-full py-96 max-w-320'>
							<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
								<div className='flex items-left mb-16'>
									<div>
										<Typography style={{ paddingBlock: 16, fontSize: 16, color: '#6B7280', fontWeight: 600 }}>Change Your Email</Typography>
										<Typography style={{ paddingBottom: 16, fontSize: 14, color: '#6B7280' }}>
											This will change the email address, you use to login into Lyfngo. You will need to verify the new email address, by entering the
											OTP sent to you.
										</Typography>
										<form className='flex flex-col justify-center w-full' onSubmit={handleSubmit(onSubmit)}>
											<Controller
												name='email'
												control={control}
												render={({ field }) => {
													return (
														<TextField
															{...field}
															className='mb-16'
															type='text'
															name='email'
															color='secondary'
															error={!!errors.email}
															helperText={errors?.email?.message}
															label='Email'
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<Icon className='text-20' color='action'>
																			email
																		</Icon>
																	</InputAdornment>
																),
															}}
															variant='outlined'
															required
														/>
													)
												}}
											/>

											<ButtonStyle>
												<Button type='submit' variant='secondary' className='btn w-full mx-auto mt-16' aria-label='REGISTER' value='legacy'>
													Submit
												</Button>
											</ButtonStyle>
										</form>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>

					<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
						<div className='max-w-320'>
							<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
								<img src={ForgetPassword} alt='ResetPassword' />
							</div>
						</div>
					</div>
				</div>
			</Root>
			<EmailVerificationModal history={history} />
		</>
	)
}
