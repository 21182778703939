import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	tabsMain: {
		'& .MuiTab-root': {
			minHeight: 36,
			alignItem: 'center',
			border: '1px solid #414A58',
			padding: '0px 16px',
			borderRadius: 20,
			[theme.breakpoints.down('md')]: {
				minWidth: 56,
			},
		},
		'& .MuiTabs-flexContainer': {
			justifyContent: 'flex-start',
			gap: 20,
			paddingInline: 12,
		},
		'& .MuiTabs-root': {
			alignItems: 'center',
		},
		'& .Mui-selected': {
			color: '#fff',
			background: themesConfig.greyDark.palette.primary.main,
			padding: 10,
			border: themesConfig.greyDark.palette.primary.main,
			borderRadius: 20,
			'& .MuiTypography-root ': {
				color: '#fff',
			},
		},
		'& .MuiTabs-indicator': {
			display: 'none',
		},
		'& .MuiAutocomplete-root': {
			marginInline: 8,
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: '#999',
			},
			'& .MuiFormControl-fullWidth': {
				[theme.breakpoints.down('sm')]: {
					minWidth: 200,
				},
			},
			'& .MuiInputLabel-shrink': {
				color: '#263238',
			},
			[theme.breakpoints.down('sm')]: {
				marginInline: 0,
			},
		},
	},
}))
export default useStyles
