import { React, useState } from 'react'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getResponseMessage } from '../../../../../../../src/utils'
import { Typography, Grid, TextField, Autocomplete, FormHelperText } from '@mui/material'
import useStyles from './style'
import ArticleApi from '../../../../../../../src/services/Communication/Article/api'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { showLoader, hideLoader } from 'services/loader/action'
import { useSelector, useDispatch } from 'react-redux'
import _ from '@lodash'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw } from 'draft-js'
import Weeks from '../../../../../../model/Communication/Weeks/data'
import Days from '../../../../../../model/Communication/Days/data'
import OperationHours from '../../../../../../model/Communication/OperationHours/data'
import moment from 'moment'
import draftToHtml from 'draftjs-to-html'
import CustomDialog from 'app/sharedComponents/CustomDialog'

export default function AddArticle({ open2, handleClose2, setOpen2, planName, setArticleList, match, onArticleAddSuccess }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const { uuid } = state
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const timezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezoneOffset || '')
	const planUuid = _.get(match, 'params.id', '')
	const onSubmit = (e) => {
		submitArticle(e)
		formik?.resetForm()
	}
	//Formik Validation
	const formik = useFormik({
		initialValues: {
			selectWeek: [],
			selectDay: [],
			operationTime: '',
			educatePatient: '',
			editorState: '',
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			selectWeek: yup.object().nullable().required('Please choose a week'),
			selectDay: yup.object().nullable().required('Please choose a day'),
			operationTime: yup.string().nullable().required('Please choose a scheduled time '),
			educatePatient: yup
				.string()
				.required('Please enter the article title')
				.matches(/^[0-9a-zA-Z\s]+$/, 'Only Number and Character are allowed')
				// /^[ A-Za-z0-9_@./#&+-]*$/
				// .matches(/^[a-zA-Z0-9()&_`.,\"]$/, 'Only numbers,characters and can containt ()&_`., special characters in the article title')
				.max(100, 'Article title is maximum 100 character only'),
			editorState: yup.string().required('Article content should not be empty'),
		}),
		onSubmit: onSubmit,
	})
	//start: Plan Article-POST //
	const submitArticle = (e) => {
		const formData4 = new FormData()
		formData4.append('articleName', e?.educatePatient)
		formData4.append('weekNo', e?.selectWeek?.WeekNo)
		formData4.append('dayNo', e?.selectDay?.dayNo)
		formData4.append('articleTime', moment(e?.operationTime, 'h:mm:ss A').format(`HH:mm:ss${timezone}`))
		formData4.append('articleContent', JSON.stringify(e?.editorState))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				res?.data?.data?.articleUuid && assignArticle(res?.data?.data?.articleUuid)
			}
			formik?.resetForm()
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader('Creating article please wait...'))
		ArticleApi.createPlanArticle(formData4).then(onSuccess, onFailure)
	}
	//start: Plan Article-POST //
	//start : Assign Article
	const assignArticle = (planArticleUUid) => {
		const body = {
			planId: planUuid,
			tentUserId: uuid,
			articleId: planArticleUUid,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				onArticleAddSuccess()
				setOpen2(false)
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Article created successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
			setEditorState(EditorState.createEmpty())
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader('Assigning article please wait...'))
		ArticleApi.assignPlanReminder(body).then(onSuccess, onFailure)
	}
	//End : Assign Article
	//Start : editor section
	const handleEditorChange = (state) => {
		setEditorState(state)
		formik?.setFieldValue('editorState', draftToHtml(convertToRaw(state.getCurrentContent())))
	}
	const onClose = (e, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			formik?.resetForm()
			handleClose2()
			setEditorState(EditorState.createEmpty())
		}
	}
	return (
		<CustomDialog onClose={onClose} open={open2} headerText='Add article' formikValues={formik} onSubmit={() => formik?.handleSubmit()}>
			<Grid container spacing={1} className={classes.newAddDetails}>
				<Grid item lg={12} md={12} sm={12}>
					<Typography className={classes.txt}>
						Add educational content about DOs and DON’Ts, dietary recommendations, exercises, things to expect or anything else to your communication
						plan.
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Typography variant='h6' className={classes.dayText}>
						Select day and time to schedule your article
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Grid container className={classes.selectDayWeek} justifyContent='space-between'>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='selectweek'
								name='selectweek'
								size='small'
								fullWidth
								clearIcon=''
								options={Weeks}
								getOptionLabel={(option) => option?.week || ''}
								value={formik?.values?.selectWeek}
								onChange={(e, value) => {
									formik?.setFieldValue('selectWeek', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select week *'
										color='secondary'
										error={formik.touched.selectWeek && formik.errors.selectWeek}
										helperText={formik.touched.selectWeek && formik.errors.selectWeek && formik.errors.selectWeek}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='selectday'
								name='selectday'
								size='small'
								fullWidth
								clearIcon=''
								options={Days}
								getOptionLabel={(option) => option?.day || ''}
								value={formik?.values?.selectDay}
								onChange={(e, value) => {
									formik?.setFieldValue('selectDay', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Select day *'
										color='secondary'
										error={formik.touched.selectDay && formik.errors.selectDay}
										helperText={formik.touched.selectDay && formik.errors.selectDay && formik.errors.selectDay}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={2} md={2} sm={12}>
							<Autocomplete
								id='operationtime'
								name='operationtime'
								size='small'
								fullWidth
								clearIcon=''
								options={OperationHours}
								getOptionLabel={(option) => option || ''}
								value={formik?.values?.operationTime}
								onChange={(e, value) => {
									formik?.setFieldValue('operationTime', value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Time *'
										color='secondary'
										error={formik.touched.operationTime && formik.errors.operationTime}
										helperText={formik.touched.operationTime && formik.errors.operationTime && formik.errors.operationTime}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={5} md={5} sm={12}>
							<TextField
								id='educatePatient'
								name='educatePatient'
								variant='outlined'
								placeholder='What do you want to educate your patients about? *'
								multiline
								fullWidth
								focused={false}
								size='small'
								color='secondary'
								value={formik?.values?.educatePatient}
								onChange={(e, value) => {
									formik?.setFieldValue('educatePatient', e.target.value)
								}}
								error={formik.touched.educatePatient && formik.errors.educatePatient}
								helperText={formik.touched.educatePatient && formik.errors.educatePatient && formik.errors.educatePatient}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={12} md={12} sm={12}>
					<Editor
						editorState={editorState}
						onEditorStateChange={handleEditorChange}
						wrapperClassName={classes.wrapperClass}
						editorClassName={classes.editorClass}
						toolbarClassName={classes.toolbarClass}
					/>
					<FormHelperText error>{formik?.errors.editorState && formik?.touched.editorState && formik?.errors.editorState} </FormHelperText>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}
