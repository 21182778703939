import React from 'react'
import { TextField } from '@mui/material'

const ClientInfo = ({ formik }) => {
	return (
		<TextField
			id='clientInfo'
			name='clientInfo'
			fullWidth
			value={formik?.values?.clientInfo}
			onChange={(e) => formik.setFieldValue('clientInfo', e.target.value)}
			color='secondary'
			size='small'
			label='Client info'
			error={formik.touched.clientInfo && formik.errors.clientInfo}
			helperText={formik.touched.clientInfo && formik.errors.clientInfo && formik.errors.clientInfo}
		/>
	)
}

export default ClientInfo
