import { authRoles } from 'app/auth'
import WelcomeCare from './welcomeCare'

const WelcomeConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/establishmentselection',
			component: WelcomeCare,
		},
	],
}

export default WelcomeConfig
