/* eslint-disable no-useless-escape */
import { Card, CardContent, TextField } from '@mui/material'
import { Icon, IconButton, Button, InputAdornment, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { showLoader, hideLoader } from '../../../services/loader/action'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// eslint-disable-next-line no-unused-vars
import _ from '@lodash'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import UpdatePasswordApi from '../../../services/updateForgetPassword/api'
import { makeStyles } from '@mui/styles'
import { encryption, decryption, getResponseMessage, failureLogin } from 'utils'
import secureLocalStorage from 'react-secure-storage'
import themesConfig from 'app/fuse-configs/themesConfig'
import ForgetPassword from 'images/login/ForgetPassword.png'

const useStyles = makeStyles((theme) => ({
	modalbox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		background: '#fff',
		border: '1px solid #fff',
		borderRadius: '12px',
		boxShadow: 24,
		paddingBlock: '40px',
		paddingInline: '40px',
		'& .muiltr-1auo3o6-MuiTypography-root': {
			fontSize: '14px',
			color: '#263238',
			paddingInline: '30px',
		},
		'& .muiltr-w6yxov-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '54px',
			paddingBlock: '0px',
		},
		'& .muiltr-nia2q8-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '45px',
			'& a:not([role=button]):not(.MuiButtonBase-root)': {
				color: '#2658DC',
			},
		},
	},
	emailtext: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	visibility: {
		cursor: 'pointer',
	},
}))
const Root = styled('div')(({ theme }) => ({
	background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {
		maxWidth: 400,
	},

	'& .Login-rightSection': {
		background: `transparent linear-gradient(90deg, #F5F5F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box`,
		color: '#707680',
	},
	'& .cardContent': {
		paddingBlock: '12px',
		paddingInline: '12px',
	},
	'& .text': {
		margin: '16px 0',
	},
	'& .showNumber': {
		textAlign: 'center',
		background: '#f5f5f5',
	},
	'& .otp-input': {
		margin: '12px 0',
	},
	'& .form': {
		paddingInline: 40,
		[theme.breakpoints.down('sm')]: {
			paddingInline: 0,
		},
	},
	'& .otp-input input': {
		width: '40px !important',
		height: '40px',
		border: '1px solid black',
	},
	'& .otp-input input:focus': {
		border: '2px solid #000',
	},
	'& .backButton': {
		background: themesConfig.greyDark.palette.primary.main,
		marginInline: '-12px',
		'& .MuiSvgIcon-root': {
			fill: '#fff',
		},
		'&:hover': {
			background: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
const ButtonStyle = styled('Button')(({ theme }) => ({
	'& .btn': {
		color: '#FFF',
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
/**
 * Form Validation Schema
 */
function Reset({ history }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const browserName = secureLocalStorage.getItem('browserName')
	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	})
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}
	const defaultValues = {
		password: '',
		passwordConfirm: '',
	}
	const schema = yup.object().shape({
		password: yup
			.string()
			.required('Please enter your password.')
			.min(8, 'Password is too short - should be 8 characters minimum.')
			.max(25, 'Password is too long - should be 25 characters only.')
			.trim('Spaces are not allowed')
			.strict(true, 'Spaces are not allowed')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				'Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character'
			),
		passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
	})
	const { control, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema),
	})
	const state = useSelector((states) => states.Authentication)
	const { userEmail } = state
	const errorMessage = useSelector((states) => states.errorMessages)
	const { errors } = formState
	function onSubmit(data) {
		handleSubmitPassword(data)
	}

	const handleSubmitPassword = (data) => {
		dispatch(showLoader('Loading please wait...'))
		let cnfrmPassEnc = encryption(data?.passwordConfirm)
		let newPassEnc = encryption(data?.password)
		const body = {
			confirmPassword: cnfrmPassEnc?.plainText + ':' + cnfrmPassEnc?.publicKey,
			newPassword: newPassEnc?.plainText + ':' + newPassEnc?.publicKey,
			userName: data.userName || userEmail || secureLocalStorage.getItem('resetPasswordMail'),
			userType: 'TNT',
			isPasswordEncoded: true,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorMessage, 'Password updated successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				secureLocalStorage.clear()
				history.push('/')
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessage),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		UpdatePasswordApi.setUpdatePassword(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const handleClick = () => {
		history.goBack()
	}
	return (
		<>
			<Root className='flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'>
				<div className='flex w-full max-w-400 md:max-w-3xl rounded-20 overflow-hidden'>
					<Card className='Login-leftSection flex flex-col w-full max-w-360 items-start justify-evenly' square>
						<form className='form flex flex-col justify-center w-full' onSubmit={handleSubmit(onSubmit)}>
							<CardContent className='cardContent flex flex-col items-start justify-center w-full py-96 max-w-320'>
								<IconButton className='backButton' onClick={handleClick}>
									<ArrowBackIcon />
								</IconButton>
								<div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
									<div className='flex items-left mb-16'>
										<div>
											<Typography style={{ paddingBlock: 16, fontSize: 14, color: '#6B7280' }}>Set your new password</Typography>
											<Controller
												name='password'
												control={control}
												render={({ field }) => (
													<TextField
														{...field}
														className='mb-16 w-full'
														type='New Password*'
														name='New Password*'
														label='New Password*'
														color='secondary'
														error={!!errors.password}
														helperText={errors?.password?.message}
														InputProps={{
															type: values.showPassword ? 'text' : 'password',
															endAdornment:
																browserName === 'chrome' || browserName === 'firefox' ? (
																	<InputAdornment position='end' className={classes.visibility} style={{ cursor: 'pointer' }}>
																		{values.showPassword ? (
																			<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
																				visibility
																			</Icon>
																		) : (
																			<Icon className='visibility text-20' color='action' onClick={handleClickShowPassword}>
																				visibility_off
																			</Icon>
																		)}
																	</InputAdornment>
																) : null,
														}}
														variant='outlined'
													/>
												)}
											/>
											<Controller
												name='passwordConfirm'
												control={control}
												render={({ field }) => (
													<TextField
														{...field}
														className='mb-16 w-full'
														type='password'
														name='Confirm Password*'
														label='Confirm Password*'
														color='secondary'
														error={!!errors.passwordConfirm}
														helperText={errors?.passwordConfirm?.message}
														variant='outlined'
													/>
												)}
											/>

											<ButtonStyle className='w-full'>
												<Button type='submit' variant='secondary' className='btn w-full mx-auto mt-16' aria-label='REGISTER' value='legacy'>
													Submit
												</Button>
											</ButtonStyle>
										</div>
									</div>
								</div>
							</CardContent>
						</form>
					</Card>

					<div className='Login-rightSection hidden md:flex flex-1 items-center justify-center p-64'>
						<div className='max-w-320'>
							<div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
								<img src={ForgetPassword} alt='ResetPassword' />
							</div>
						</div>
					</div>
				</div>
			</Root>
		</>
	)
}

export default Reset
