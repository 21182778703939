import { Typography } from '@mui/material'

function AidivaaPrimePage() {
	return (
		<div>
			<Typography variant='h5'>Lyfngo Prime</Typography>
		</div>
	)
}

export default AidivaaPrimePage
