import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setResetPassword: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.RESETPASSWORD}`, result, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), key: `${ivKey}` },
		})
	},
}
