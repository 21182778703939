import Prime from './Prime'

const PrimeConfig = {
	routes: [
		{
			path: '/prime',
			component: Prime,
		},
	],
}

export default PrimeConfig
