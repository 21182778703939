import * as React from 'react'
import { useState, useCallback, useEffect, useRef } from 'react'
import _ from '@lodash'
import TextField from '@mui/material/TextField'
import AdvancedAutocomplete from '../../../sharedComponents/AdvancedAutocomplete'
import { Typography, Grid, Button, IconButton, FormHelperText } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import ButtonComp from '../../../sharedComponents/Button'
import LookupApi from '../../../../services/lookup/api'
import CompDropZone from '../../../sharedComponents/DropzoneUpload/Dropzone'
import fileUploadDetailsApi from '../../../../services/fileupload/GetApi'
import sendUploadFileApi from '../../../../services/fileupload/PostApi'
import awardDetailsApi from '../../../../services/ProfileSetup/AwardsDetails/api'
import { showLoader, hideLoader } from '../../../../services/loader/action'
import { getResponseMessage } from '../../../../utils'
import { showMessage } from 'app/store/fuse/messageSlice'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from './style'
import { setAwardData, setMemberData, setProfileStatus } from '../../../../services/ProfileSetup/list/actions'
import { FieldArray, ErrorMessage, FormikProvider } from 'formik'
import profileStatusApi from 'services/ProfileSetup/profileStatus/api'
import { AddCircle } from '@mui/icons-material'
import themesConfig from 'app/fuse-configs/themesConfig'
const AwardsMamber = ({ history, match, setStatus, moveBackTab, moveNextTab }) => {
	const classes = useStyles()
	const action = _.get(match, 'params.action', '')
	const isViewMode = _.isEqual(action, 'view')
	const isEditMode = _.isEqual(action, 'edit')
	const [yearOptions, setYearOptions] = useState([])
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const { userType, uuid, mastuuid } = state
	const validationType = useRef('formSubmission')
	// eslint-disable-next-line no-undef
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
	const [identityFileResponse, setIdentityFileResponse] = useState('')
	const [emptyCheck, setEmptyCheck] = useState([])
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState('')
	// const awardArray = useSelector((state) => (!isEditMode ? state?.profileSetup?.awardDetails?.awardList : []))
	const [awardArray, setAwardArray] = useState([])
	const memberArray = useSelector((state) => (!isEditMode ? state?.profileSetup?.awardDetails?.memberList : []))
	const [inputFieldsEmail, setInputFieldsEmail] = useState([])
	const [inputFieldMember, setInputFieldMember] = useState([])
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const progressStatus = useSelector((state) => state?.profileSetup?.profileStatus || 'IPG')
	const [profileStatus, setProfileStatus] = useState({})
	const groupuser = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = groupuser
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)

	const addRowMember = (member) => {
		setInputFieldMember((prevState) => [
			...prevState,
			{
				member: memberformik?.values?.member,
				attachment: establishmentFileResponse?.uuid,
				uuid: null,
			},
		])
		memberformik?.resetForm()
		setEstablishmentFileResponse()
	}

	const onAwardSubmit = (e) => {
		setInputFieldsEmail((prevState) => [
			...prevState,
			{
				awards: e?.awards,
				awardYear: e?.awardYear?.mastLookupValue,
				attachment: identityFileResponse?.uuid,
				uuid: null,
			},
		])
		awardformik?.resetForm()
		setIdentityFileResponse()
	}
	const onMemberSubmit = (e) => {
		if (validationType.current === 'MemberValidation') {
			addRowMember(e)
		}
	}

	const awardValidation = yup.object({
		awards: yup.string().trim('The contact member cannot include leading and trailing spaces').max(100).strict(true),
	})

	const memberValidation = yup.object({
		member: yup.string().trim('The contact name cannot include leading and trailing spaces').max(100).strict(true),
	})

	const dataq = {
		awardDetails: _.isEmpty(awardArray)
			? [
					{
						awardName: '',
						awardYear: null,
						supportingDocUuid: '',
					},
			  ]
			: awardArray,
	}

	const awardformik = useFormik({
		initialValues: {
			awardDetails: _.isEmpty(awardArray)
				? [
						{
							awardName: '',
							awardYear: null,
							supportingDocUuid: '',
							attachment: '',
						},
				  ]
				: awardArray,
		},
		enableReinitialize: true,
		validationSchema: awardValidation,
		onSubmit: onAwardSubmit,
	})
	const memberformik = useFormik({
		initialValues: {
			memberDetails: !_.isEmpty(memberArray)
				? memberArray
				: [
						{
							membership: '',
							supportingDocUuid: '',
						},
				  ],
		},
		validationSchema: memberValidation,
		onSubmit: onMemberSubmit,
	})
	const [yearParams, setYearParams] = useState({
		limit: 10,
		offset: 1,
		search: '',
	})
	const onYearSearch = _.debounce(
		(e, value) => {
			setYearParams({
				...yearParams,
				search: value,
			})
		},

		[300]
	)
	const getCompletionYear = useCallback(() => {
		setLoader(true)
		const onSuccess = (res) => {
			setLoader(false)
			if (res?.data?.status === 'success') {
				setYearOptions(res.data.data)
			}
		}
		const onFailure = (err) => {
			setYearOptions([])
		}
		LookupApi.getLookup('getLookup', { ...yearParams, mastLookupType: 'YER' }).then(onSuccess, onFailure)
	}, [yearParams])

	useEffect(() => {
		// if (isEditMode) {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			let tempAwardsArr = []
			let tempMemArr = []
			if (res?.data?.status === 'success') {
				if (!_.isEmpty(res?.data?.data?.awardsList || [])) {
					setEmptyCheck(res?.data?.data?.awardsList)
					setAwardArray(res?.data?.data?.awardsList)
					_.map(res?.data?.data?.awardsList || [], (item) => {
						tempAwardsArr.push({
							awardName: item?.awardName,
							awardYear: item?.awardYear,
							attachment: _.isEmpty(item?.supportingDocUuid) ? '' : item?.supportingDocUuid.pop(),
							uuid: item?.profileAwardUuid,
						})
					})
				}
				// if (!_.isEmpty(res?.data?.data?.membershipList || [])) {
				// 	_.map(res?.data?.data?.membershipList || [], (item) => {
				// 		tempMemArr.push({
				// 			membership: item?.membership,
				// 			attachment: item?.supportingDocUuid.pop(),
				// 			uuid: item?.profileAwardUuid,
				// 		})
				// 	})
				// }
				setInputFieldsEmail(tempAwardsArr || [])
				// setInputFieldMember(tempMemArr || [])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		awardDetailsApi.AwardDetails(isTent, userType).then(onSuccess, onFailure)
		// }
	}, [dispatch, userType, uuid, mastuuid, groupUserName, profileHeaderUuid])
	// }, [dispatch, isEditMode, userType, uuid])

	useEffect(() => {
		if (!_.isEmpty(emptyCheck)) {
			awardformik?.setFieldValue(`awardDetails`, inputFieldsEmail)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emptyCheck, inputFieldsEmail])

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setProfileStatus(res?.data?.data || {})
			// _.isEqual(res?.data?.data?.progressStatus, 'IPG')
			// 	? history.push(`/profilestatus/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'SUB') || _.isEqual(res?.data?.data?.progressStatus, 'RIP')
			// 	? history.push(`/profileunderverification/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'APP')
			// 	? history.push(`/readytogolive/view/${uuid}/${mastUuid}`)
			// 	: _.isEqual(res?.data?.data?.progressStatus, 'LIV') && history.push(`/readytogolive/view/${uuid}/${mastUuid}`)
			// dispatch(setProfileStatus(res?.data?.data || {}))
		}
		const onFailure = (err) => {
			setProfileStatus({})
			dispatch(hideLoader())
		}
		let isNotOwner = !_.isEqual(groupUserName, 'owner')
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		profileStatusApi.getProfileStatus(isTent, mastuuid, isNotOwner).then(onSuccess, onFailure)
	}, [dispatch, groupUserName, mastuuid, profileHeaderUuid, uuid])

	useEffect(() => {
		getCompletionYear()
	}, [getCompletionYear])

	const submitAwardDetails = () => {
		let awardArr = []
		let memArr = []
		if (!_.isEmpty(awardformik?.values?.awardDetails)) {
			_.map(awardformik?.values?.awardDetails, (awd) => {
				if (!_.isEmpty(awd.awardName)) {
					awardArr.push({
						awardName: awd?.awardName,
						awardYear: parseInt(awd.awardYear),
						membership: null,
						supportingDocUuid: _.isEmpty(awd?.attachment) ? [] : [awd?.attachment],
						progressStatus: progressStatus?.progressStatus || 'IPG',
						profileAwardUuid: awd?.uuid || null,
						isAmend: false,
					})
				}
			})
		}
		if (!_.isEmpty(memberformik?.values?.memberDetails)) {
			_.map(memberformik?.values?.memberDetails, (mem) => {
				if (!_.isEmpty(mem?.membership)) {
					memArr.push({
						awardName: null,
						awardYear: null,
						membership: mem?.membership,
						supportingDocUuid: _.compact([mem?.supportingDocUuid || '']),
						progressStatus: progressStatus?.progressStatus || 'IPG',
						profileAwardUuid: mem?.uuid || null,
						isAmend: false,
					})
				}
			})
		}

		dispatch(showLoader('Loading please wait...'))
		const body = [...awardArr, ...memArr]
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				// if (inputFieldsEmail?.progressStatus === 'IPG') {
				// 	history.push(`/profilestatus/view/${uuid}/${mastuuid}`)
				// } else if (inputFieldsEmail?.progressStatus === 'SUB' || 'RIP') {
				// 	// history.push(`/profileunderverification/view/${uuid}/${mastuuid}`)
				// 	history.push('/profile')
				// } else {
				// 	history.push(`/readytogolive/view/${uuid}/${mastuuid}`)
				// }
				// dispatch(
				// 	showMessage({
				// 		message: getResponseMessage(res?.data, errorJson),
				// 		autoHideDuration: 3000,
				// 		anchorOrigin: {
				// 			vertical: 'top',
				// 			horizontal: 'right',
				// 		},
				// 		variant: 'success',
				// 	})
				// )
				submitDetails()
			} else {
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		// isEditMode
		// 	? awardDetailsApi.putAwardDetails(body, uuid, userType).then(onSuccess, onFailure)
		// 	: !_.isEmpty(body)
		// 	? awardDetailsApi.PostAwardDetails(body, uuid, userType).then(onSuccess, onFailure)
		// 	: history.push(`/profilestatus/view/${uuid}/${mastuuid}`)
		let isTent = !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : profileHeaderUuid
		_.isEmpty(emptyCheck)
			? awardDetailsApi.PostAwardDetails(body, isTent, userType).then(onSuccess, onFailure)
			: awardDetailsApi.putAwardDetails(body, isTent, userType).then(onSuccess, onFailure)
	}
	const getFileCategoryList = useCallback(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				setFileCategoryList(res?.data?.data)
			} else {
				setFileCategoryList([])
			}
		}
		const onFailure = (err) => {
			console.log('Error', err)
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		fileUploadDetailsApi.getFileUploadDetails(isTent).then(onSuccess, onFailure)
	}, [groupUserName, tentUserId, uuid])

	useEffect(() => {
		getFileCategoryList()
	}, [getFileCategoryList])

	const displayEstablishmentImage = (acceptedFiles, index) => {
		dispatch(showLoader('uploading image. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())

			if (res?.data?.status === 'SUCCESS') {
				memberformik?.setFieldValue(`memberDetails[${index}].supportingDocUuid`, res?.data?.data?.uuid)
			} else {
				memberformik?.setFieldValue(`memberDetails[${index}].supportingDocUuid`, '')
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'MEMEBERSHIP')
		let categoryUuid = identityCategoryId?.uuid
		var formData2 = new FormData()
		formData2.append('FILE', acceptedFiles[0])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		sendUploadFileApi.sendUploadFile(mastuuid, isTent, categoryUuid, formData2).then(onSuccess, onFailure)
	}

	const displayIdentityImage = (acceptedFiles, index) => {
		dispatch(showLoader('uploading image. Please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				awardformik?.setFieldValue(`awardDetails[${index}].attachment`, res?.data?.data?.uuid)
			} else {
				awardformik?.setFieldValue(`awardDetails[${index}].attachment`, '')
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'AWARDS')
		let categoryUuid = identityCategoryId?.uuid
		var formData1 = new FormData()
		formData1.append('FILE', acceptedFiles[0])
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		sendUploadFileApi.sendUploadFile(mastuuid, isTent, categoryUuid, formData1).then(onSuccess, onFailure)
	}

	const deleteAwards = useCallback(
		(uuid, index, arrayHelpers) => {
			dispatch(showLoader('Deleting please wait...'))
			const onSuccess = (res) => {
				if (res?.data?.status === 'success') {
					dispatch(hideLoader())
					arrayHelpers.remove(index)
				}
			}
			const onFailure = () => {
				dispatch(hideLoader())
			}
			awardDetailsApi.deleteAward(uuid).then(onSuccess, onFailure)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)
	const deleteMemberAwards = useCallback(
		(uuid, index, arrayHelpers) => {
			dispatch(showLoader('Deleting please wait...'))
			const onSuccess = (res) => {
				if (res?.data?.status === 'success') {
					dispatch(hideLoader())
					arrayHelpers.remove(index)
				}
			}
			const onFailure = () => {
				dispatch(hideLoader())
			}
			awardDetailsApi.deleteAward(uuid).then(onSuccess, onFailure)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	)

	// useEffect(() => {
	// 	dispatch(setAwardData(awardformik?.values?.awardDetails || []))
	// }, [dispatch, awardformik?.values?.awardDetails])

	// useEffect(() => {
	// 	dispatch(setMemberData(memberformik?.values?.memberDetails || []))
	// }, [dispatch, memberformik?.values?.memberDetails])

	const navigationFn = () => {
		if (!_.isEmpty(awardformik?.values?.awardDetails)) {
			submitAwardDetails()
		}
	}

	const submitDetails = () => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			profilePercentage: profileStatus?.profilePercentage,
			profileStatusId: profileStatus?.profileStatusId,
			progressStatus: 'SUB',
			status: profileStatus?.status,
			tentId: mastuuid,
			tentUserId: !_.isEqual(groupUserName, 'owner') ? profileHeaderUuid : uuid,
			profileHeaderUuid: profileHeaderUuid,
		}

		const onSuccess = (res) => {
			dispatch(hideLoader())
			moveNextTab()
			// history.push(`/profile`)
			// history.push(`/profileunderverification/view/${uuid}/${mastUuid}`)
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}

		profileStatusApi.updateProfileStatus(body).then(onSuccess, onFailure)
	}

	return (
		<>
			<div className={classes.mainRoot}>
				{/* <Typography style={{ fontSize: 16, fontWeight: 500 }}>Awards & Recognition</Typography> */}
				<FormikProvider value={awardformik}>
					<FieldArray
						name='awardDetails'
						render={(arrayHelpers) => (
							<>
								<div>
									{_.map(awardformik?.values?.awardDetails, (awardDetails, index) => (
										<div className='mb-10'>
											<Grid container justifyContent='flex-start' key={index} spacing={2} className={classes.mainField}>
												<Grid item xs={11.8} sm={5.9}>
													<Typography sx={{ fontWeight: 600, fontSize: 13, marginBottom: 1 }}>Awards</Typography>
													<TextField
														fullWidth
														placeholder='Awards'
														type='text'
														size='small'
														color='secondary'
														name={`awardDetails[${index}].awardName`}
														id={`awardDetails[${index}].awardName`}
														value={awardformik.values.awardDetails[index]?.awardName}
														onChange={awardformik.handleChange}
														InputProps={{ className: classes.input }}
													/>
													<ErrorMessage component={FormHelperText} error name={`awardDetails.${index}.awards`} />
												</Grid>
												<Grid item xs={11.8} sm={5.9}>
													<Typography sx={{ fontWeight: 600, fontSize: 13, marginBottom: 1 }}>Year</Typography>
													<AdvancedAutocomplete
														id={`awardDetails[${index}].awardYear`}
														loading={loader}
														name={`awardDetails[${index}].awardYear`}
														options={yearOptions}
														value={awardformik?.values?.awardDetails[index].awardYear}
														onChange={(e, value) => {
															awardformik?.setFieldValue(`awardDetails[${index}].awardYear`, value?.mastLookupValue || '')
															setYearParams((prevState) => ({ ...prevState, search: value?.mastLookupValue || '' }))
														}}
														getOptionSelected={(option, value) => {
															return option?.mastLookupValue === value?.mastLookupValue || ''
														}}
														onInputChange={onYearSearch}
														getOptionLabel={(option) => {
															return option?.mastLookupValue || ''
														}}
														placeholder='year'
													/>
													<ErrorMessage component={FormHelperText} error name={`awardDetails.${index}.awards`} />
												</Grid>
												<Grid item xs={0.4} sm={0.2} className={classes.cancelExperience}>
													{!isSmall ? (
														awardformik?.values?.awardDetails?.length > 1 ? (
															<IconButton
																onClick={() => {
																	!_.isEmpty(emptyCheck) && awardformik?.values?.awardDetails[index]?.uuid
																		? deleteAwards(awardformik?.values?.awardDetails[index]?.uuid, index, arrayHelpers)
																		: arrayHelpers.remove(index)
																}}
															>
																<HighlightOffIcon />
															</IconButton>
														) : (
															''
														)
													) : (
														<Button
															className={classes.cancelBtn}
															onClick={() => {
																!_.isEmpty(emptyCheck) && awardformik?.values?.awardDetails[index]?.uuid
																	? deleteAwards(awardformik?.values?.awardDetails[index]?.uuid, index, arrayHelpers)
																	: arrayHelpers.remove(index)
															}}
														>
															Remove
														</Button>
													)}
												</Grid>
											</Grid>
											{/* supportingDocUuid */}
											<div className='' style={{ display: 'flex', alignItems: 'end' }}>
												<CompDropZone
													imgResponse={
														awardformik?.values?.awardDetails[index]?.supportingDocUuid || awardformik?.values?.awardDetails[index]?.attachment
													}
													setImage={() => {
														awardformik?.setFieldValue(`awardDetails[${index}].supportingDocUuid`, '')
														awardformik?.setFieldValue(`awardDetails[${index}].attachment`, '')
													}}
													displayUploadImage={(files) => displayIdentityImage(files, index)}
													isType={'profile'}
												/>
												<div style={{ marginLeft: 16 }}>
													<Typography sx={{ fontWeight: 500 }}>Award Image</Typography>
													<Typography sx={{ color: '#727272' }}>JPG, PNG & JPEG Max 2MB/File</Typography>
												</div>
											</div>
										</div>
									))}
								</div>
								<Grid container className={classes.contentPage}>
									<Grid item className={classes.addbutton}>
										<Button
											className={classes.addExperiencebtn}
											variant='contained'
											align
											disabled={awardformik?.values?.awardDetails.length > 4 ? true : false}
											onClick={(e) => {
												validationType.current = 'awardValidation'
												awardformik?.awardDetails?.handleSubmit(e)
												arrayHelpers.push({
													awards: '',
													awardYear: null,
													supportingDocUuid: '',
													attachment: '',
												})
												setYearParams({
													limit: 10,
													offset: 1,
													search: '',
												})
												setIdentityFileResponse('')
											}}
											startIcon={<AddCircle sx={{ color: '#fff' }} />}
											sx={{ color: '#fff', background: themesConfig.lyfngo.palette.primary.main }}
										>
											Add More Awards
										</Button>
									</Grid>
									{/* <Grid item>
										<Typography>* complete your previous award details</Typography>
									</Grid> */}
								</Grid>
							</>
						)}
					/>
				</FormikProvider>
			</div>
			{/* <div className={classes.mainRoot}>
				<Typography style={{ fontSize: 16, fontWeight: 500 }}>Membership</Typography>

				<FormikProvider value={memberformik}>
					<FieldArray
						name='memberDetails'
						render={(arrayHelpers) => (
							<>
								<div>
									{_.map(memberformik?.values?.memberDetails, (memberDetail, index) => (
										<div className='mb-10'>
											<Grid container justifyContent='flex-start' spacing={2} className={classes.mainField} key={index}>
												<Grid item lg={4} sm={12}>
													<Typography>Member</Typography>
													<TextField
														fullWidth
														placeholder='Member*'
														type='text'
														size='small'
														color='secondary'
														name={`memberDetails[${index}].membership`}
														id={`memberDetails[${index}].membership`}
														value={memberformik.values.memberDetails[index]?.membership}
														onChange={memberformik.handleChange}
														InputProps={{ className: classes.input }}
													/>
													<ErrorMessage component={FormHelperText} error name={`memberDetails.${index}.membership`} />
												</Grid>

												<Grid item lg={2} sm={12} className={classes.cancelExperience}>
													{!isSmall ? (
														<IconButton
															onClick={() => {
																isEditMode && memberformik?.values?.memberDetails[index]?.uuid
																	? deleteMemberAwards(memberformik?.values?.memberDetails[index]?.uuid, index, arrayHelpers)
																	: arrayHelpers.remove(index)
															}}
														>
															<HighlightOffIcon />
														</IconButton>
													) : (
														<Button
															className={classes.cancelBtn}
															onClick={() => {
																isEditMode && memberformik?.values?.memberDetails[index]?.uuid
																	? deleteMemberAwards(memberformik?.values?.memberDetails[index]?.uuid, index, arrayHelpers)
																	: arrayHelpers.remove(index)
															}}
														>
															Remove
														</Button>
													)}
												</Grid>
											</Grid>
											<CompDropZone
												imgResponse={
													memberformik?.values?.memberDetails[index]?.supportingDocUuid || memberformik?.values?.memberDetails[index]?.attachment
												}
												setImage={() => {
													memberformik?.setFieldValue(`memberDetails[${index}].supportingDocUuid`, '')
												}}
												displayUploadImage={(files) => displayEstablishmentImage(files, index)}
											/>
										</div>
									))}
								</div>
								<div className={classes.contentPage}>
									<div className={classes.addExperience}>
										<Button
											className={classes.addExperiencebtn}
											disabled={memberformik?.values?.memberDetails.length > 4 ? true : false}
											onClick={(e) => {
												arrayHelpers.push({
													member: '',
													supportingDocUuid: '',
												})
												setYearParams({
													limit: 10,
													offset: 1,
													search: '',
												})
											}}
										>
											+ Add Membership
										</Button>
									</div>
									<Typography>* complete your previous member details</Typography>
								</div>
							</>
						)}
					/>
				</FormikProvider>
			</div> */}

			{!isViewMode && (
				<div className={classes.mainButtonList}>
					<ButtonComp onClick={() => moveBackTab()}>Back</ButtonComp>
					<ButtonComp onClick={navigationFn}>Save & Next</ButtonComp>
					{/* <ButtonComp onClick={}>Submit Profile</ButtonComp> */}
				</div>
			)}
		</>
	)
}
export default AwardsMamber
