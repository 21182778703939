import themesConfig from 'app/fuse-configs/themesConfig'
import { makeStyles } from '@mui/styles'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideLoader, showLoader } from '../../../../../services/loader/action'
import IndividualDasBoard from '../../../../../services/DashBoard/IndividualDashboard/api'
import moment from 'moment'
import AppointmentCalender from './AppointmentCalender'
import TotalAppointments from './TotalAppointments'
import UpCommingAppointments from './UpCommingAppointments'
import PatientVisitTimeLine from './PatientVisitTimeline'
import { decryption } from 'utils'
import { motion } from 'framer-motion'
import DateRangeSelector from '../DateRangeSelector'
import MemberShipDetails from '../EstablishmentOwner/MemberShipDetails'

const useStyles = makeStyles((theme) => ({
	root: {},
	header: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		position: 'sticky',
		top: 64,
		paddingInline: 20,
		paddingBlock: 10,
		zIndex: 12,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
			padding: 12,
		},
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			gap: 14,
			padding: 14,
		},
	},
	firstSection: {
		[theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			width: '50%',
		},
	},
	secondSection: {
		[theme.breakpoints.up('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			marginBlockStart: 'unset',
			width: '49.5%',
		},
	},
	membershipContainer: {
		width: '100%',
		paddingInline: 14,
		marginBlockStart: -6,
		minWidth: 1300,
		overflow: 'auto',
	},
}))

function IndividualDashboard({ isCare, setOwnerState }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})
	const { uuid } = useSelector((state) => state.Authentication)
	const [dashboardData, setDashboardData] = useState({})
	const [appointmentCalenderDate, setAppointmentCalenderDate] = useState(moment().format('YYYY-MM-DD'))
	const [upCommingAppointmentsType, setUpCommingAppointmentsType] = useState('ALL')
	const [selectedDateRange, setSelectedDateRange] = useState(`${moment().subtract(1, 'M').format('DD-MM-YYYY')}~${moment().format('DD-MM-YYYY')}`)

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'SUCCESS') {
				dispatch(hideLoader())
				setDashboardData(successData?.data)
			} else if (res.data.status === 'FAILURE') {
				dispatch(hideLoader())
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		IndividualDasBoard.getDashBoardData(uuid, appointmentCalenderDate, selectedDateRange, upCommingAppointmentsType).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uuid, appointmentCalenderDate, selectedDateRange, upCommingAppointmentsType])

	const container = {
		show: {
			transition: {
				delayChildren: 0.25,
				staggerChildren: 0.25,
			},
		},
	}
	const item = {
		hidden: { opacity: 0, y: 45 },
		show: { opacity: 1, y: 0 },
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.sectionLeft}>
					<ModuleTitle>{currentModule?.mastMenuName || ''}</ModuleTitle>
				</div>
				<div className={classes.sectionRight}>
					<DateRangeSelector getDateRange={(date) => setSelectedDateRange(date)} />
				</div>
			</div>

			<motion.div className={classes.container} variants={container} initial='hidden' animate='show'>
				<motion.div variants={item} className={classes.firstSection}>
					<AppointmentCalender
						dashboardData={dashboardData}
						getDatetoParent={(date) => {
							setAppointmentCalenderDate(date)
						}}
					/>
					<PatientVisitTimeLine dashboardData={dashboardData} isCare={isCare} />
				</motion.div>

				<motion.div variants={item} className={classes.secondSection}>
					<TotalAppointments dashboardData={dashboardData} />
					<UpCommingAppointments
						dashboardData={dashboardData}
						isCare={isCare}
						getAppointmentType={(type) => {
							setUpCommingAppointmentsType(type)
						}}
					/>
				</motion.div>
			</motion.div>
			<div className={classes.membershipContainer}>
				<MemberShipDetails />
			</div>
		</div>
	)
}

export default IndividualDashboard
