import React from 'react'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {},
	textAlignLeftTH: {
		textAlign: 'left',
		fontWeight: 600,
		paddingInline: 4,
		fontSize: 10.5,
		paddingBlock: 8,
	},
	textAlignRightTH: {
		textAlign: 'right',
		fontWeight: 600,
		paddingInline: 4,
		fontSize: 10.5,
		paddingBlock: 8,
	},
	textAlignCenterTH: {
		textAlign: 'center',
		fontWeight: 600,
		paddingInline: 4,
		fontSize: 10.5,
	},

	textAlignLeft: {
		textAlign: 'left',
		paddingInline: 4,
		fontSize: 10.5,
		paddingBlock: 8,
	},
	textAlignRight: {
		textAlign: 'right',
		paddingInline: 4,
		fontSize: 10.5,
		paddingBlock: 8,
	},
	textAlignCenter: {
		textAlign: 'center',
		paddingInline: 4,
		fontSize: 10.5,
	},
	contentRow: {
		borderBottom: '1px solid #B2BABB70',
	},
	headerRow: {
		borderTop: '1px solid #B2BABB70',
		borderBottom: '1px solid #B2BABB70',
	},
}))

function DataTable({ printRow, isCare, isAppointments, isInvoicedDetail, isNewPatients, isDistributor, isMembership }) {
	const classes = useStyles()
	const currency = useSelector((state) => state?.dynamicMenu?.userData?.currencyCode)

	return (
		<div className={classes.root}>
			{/* NEW PATIENTS / CLIENTS */}
			{isNewPatients && !_.isEmpty(printRow) && (
				<table style={{ width: '100%' }}>
					<thead>
						<tr className={classes.headerRow}>
							<th className={classes.textAlignLeftTH} style={{ width: '8%' }}>
								S.No
							</th>
							<th className={classes.textAlignLeftTH} style={{ width: '12%' }}>
								Date
							</th>
							<th className={classes.textAlignLeftTH} style={{ width: '20%' }}>
								{isCare ? 'Patient' : 'Client'} name
							</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient' : 'Client'} ID</th>
						</tr>
					</thead>
					<tbody>
						{printRow.map((item) => (
							<tr className={classes.contentRow} key={item}>
								<td className={classes.textAlignLeft}>{item?.sno}</td>
								<td className={classes.textAlignLeft}>{item?.date}</td>
								<td className={classes.textAlignLeft}>{item?.patientname}</td>
								<td className={classes.textAlignLeft}>{item?.patientID}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			{/* Appointments Detail */}
			{isAppointments && !_.isEmpty(printRow) && (
				<table style={{ width: '100%' }}>
					<thead>
						<tr className={classes.headerRow}>
							<th className={classes.textAlignLeftTH}>S.No</th>
							<th className={classes.textAlignLeftTH}>Date</th>
							<th className={classes.textAlignLeftTH}>Scheduled at</th>
							<th className={classes.textAlignLeftTH}>Check in at</th>
							<th className={classes.textAlignLeftTH}>Waited for {'[HH: MM: SS]'}</th>
							<th className={classes.textAlignLeftTH}>Engaged at</th>
							<th className={classes.textAlignLeftTH}>Checked at</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient' : 'Client'}</th>
							<th className={classes.textAlignLeftTH}>Specialist</th>
							{/* <th className={classes.textAlignLeftTH}>category</th> */}
						</tr>
					</thead>
					<tbody>
						{printRow.map((item) => (
							<tr className={classes.contentRow} key={item?.sno}>
								<td className={classes.textAlignLeft}>{item?.sno}</td>
								<td className={classes.textAlignLeft}>{item?.date}</td>
								<td className={classes.textAlignLeft}>{item?.scheduledAt}</td>
								<td className={classes.textAlignLeft}>{item?.checkin}</td>
								<td className={classes.textAlignLeft}>{item?.waited}</td>
								<td className={classes.textAlignLeft}>{item?.engagedAt}</td>
								<td className={classes.textAlignLeft}>{item?.checkedAt}</td>
								<td className={classes.textAlignLeft}>{item?.patientname}</td>
								<td className={classes.textAlignLeft}>{item?.doctorname}</td>
								{/* <td className={classes.textAlignLeft}>{item?.category}</td> */}
							</tr>
						))}
					</tbody>
				</table>
			)}

			{/* Invoiced Detail */}
			{isInvoicedDetail && !_.isEmpty(printRow) && (
				<table style={{ width: '100%' }}>
					<thead>
						<tr className={classes.headerRow}>
							<th className={classes.textAlignLeftTH}>S.No</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient' : 'Client'} name</th>
							<th className={classes.textAlignLeftTH}>Procedures</th>
							<th className={classes.textAlignRightTH}>Cost ({currency})</th>
							<th className={classes.textAlignRightTH}>Discount ({currency})</th>
							<th className={classes.textAlignRightTH}>Tax ({currency})</th>
							<th className={classes.textAlignLeftTH}>Invoice No</th>
							<th className={classes.textAlignLeftTH}>Receipt No</th>
							<th className={classes.textAlignLeftTH}>Mode of payment</th>
							<th className={classes.textAlignLeftTH}>
								Payment <br /> ref. no.
							</th>
							<th className={classes.textAlignRightTH}>
								Amount <br /> received ({currency})
							</th>
							<th className={classes.textAlignRightTH}>
								Amount <br /> invoiced ({currency})
							</th>
							<th className={classes.textAlignRightTH}>
								Amount <br /> due ({currency})
							</th>
						</tr>
					</thead>
					<tbody>
						{printRow.map((item) => (
							<tr className={classes.contentRow} key={item}>
								<td className={classes.textAlignLeft}>{item?.sno}</td>
								<td className={classes.textAlignLeft}>{item?.patientname}</td>
								<td className={classes.textAlignLeft}>{item?.treatmentprocedure}</td>
								<td className={classes.textAlignRight}>{item?.cost}</td>
								<td className={classes.textAlignRight}>{item?.discount}</td>
								<td className={classes.textAlignRight}>{item?.tax}</td>
								<td className={classes.textAlignLeft}>{item?.invoiceNo}</td>
								<td className={classes.textAlignLeft}>{item?.receiptNo}</td>
								<td className={classes.textAlignLeft}>{item?.modeOfPayment}</td>
								<td className={classes.textAlignLeft}>{item?.paymentReferenceNumber}</td>
								<td className={classes.textAlignRight}>{item?.amountPaid}</td>
								<td className={classes.textAlignRight}>{item?.invoiceCost}</td>
								<td className={classes.textAlignRight}>{item?.amountDue}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			{/* Distributor  */}
			{isDistributor && !_.isEmpty(printRow) && (
				<table style={{ width: '100%' }}>
					<thead>
						<tr className={classes.headerRow}>
							<th className={classes.textAlignLeftTH}>S.No</th>
							<th className={classes.textAlignLeftTH}>Distributor name</th>
							<th className={classes.textAlignLeftTH}>Commission name & value</th>
							<th className={classes.textAlignLeftTH}>Product name</th>
							<th className={classes.textAlignLeftTH}>
								Client name <br /> & ID
							</th>
							<th className={classes.textAlignLeftTH}>Invoice no</th>
							<th className={classes.textAlignLeftTH}>Payment status</th>
							<th className={classes.textAlignLeftTH}>Bill date</th>
							<th className={classes.textAlignRightTH}>Product price ({currency})</th>
							<th className={classes.textAlignRightTH}>Tax ({currency})</th>
							<th className={classes.textAlignRightTH}>Discount ({currency})</th>
							<th className={classes.textAlignRightTH}>Final price ({currency})</th>
							<th className={classes.textAlignRightTH}>
								Distributor
								<br /> comm. value ({currency})
							</th>
							<th className={classes.textAlignLeftTH}>RO name & comm. value </th>
						</tr>
					</thead>
					<tbody>
						{printRow.map((item) => (
							<tr className={classes.contentRow} key={item?.sNo}>
								<td className={classes.textAlignLeft}>{item?.sNo}</td>
								<td className={classes.textAlignLeft}>{item?.distributorName}</td>
								<td className={classes.textAlignLeft}>
									{item?.commissionName} ({item?.commissionValue})
								</td>
								<td className={classes.textAlignLeft}>{item?.productName}</td>
								<td className={classes.textAlignLeft}>
									{item?.clientName} ({item?.clientID})
								</td>
								<td className={classes.textAlignLeft}>{item?.invoiceNo}</td>
								<td className={classes.textAlignLeft}>{item?.paymentStatus}</td>
								<td className={classes.textAlignLeft}>{item?.billDate}</td>
								<td className={classes.textAlignRight}>{item?.productPrice}</td>
								<td className={classes.textAlignRight}>{item?.tax}</td>
								<td className={classes.textAlignRight}>{item?.discount}</td>
								<td className={classes.textAlignRight}>{item?.finalPrice}</td>
								<td className={classes.textAlignRight}>{item?.distributorCommissionValue}</td>
								<td className={classes.textAlignLeft}>
									{item?.roUserName} ({item?.ROCommissionValue})
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			{/* Membership  */}
			{isMembership && !_.isEmpty(printRow) && (
				<table style={{ width: '100%' }}>
					<thead>
						<tr className={classes.headerRow}>
							<th className={classes.textAlignLeftTH}>S.No</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient name' : 'Client name'}</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient ID' : 'Client ID'}</th>
							<th className={classes.textAlignLeftTH}>{isCare ? 'Patient mobile no.' : 'Client  mobile no.'}</th>
							<th className={classes.textAlignLeftTH}>Date of joined </th>
							<th className={classes.textAlignLeftTH}>Membership name</th>
							<th className={classes.textAlignLeftTH}>Membership status</th>
							<th className={classes.textAlignLeftTH}>Validity</th>
							<th className={classes.textAlignLeftTH}>Session</th>
							<th className={classes.textAlignLeftTH}>Start date</th>
							<th className={classes.textAlignLeftTH}>End date</th>
							<th className={classes.textAlignLeftTH}>Expiry status</th>
							<th className={classes.textAlignLeftTH}>Invoice no.</th>
							<th className={classes.textAlignRightTH}>Price ({currency})</th>
							<th className={classes.textAlignLeftTH}>Payment status</th>
						</tr>
					</thead>
					<tbody>
						{printRow.map((item) => (
							<tr className={classes.contentRow} key={item?.sNo}>
								<td className={classes.textAlignLeft}>{item?.sno}</td>
								<td className={classes.textAlignLeft}>{item?.patientname}</td>
								<td className={classes.textAlignLeft}>{item?.patientID}</td>
								<td className={classes.textAlignLeft}>{item?.mobileNumber}</td>
								<td className={classes.textAlignLeft}>{item?.dateofJoin}</td>
								<td className={classes.textAlignLeft}>{item?.membershipName}</td>
								<td className={classes.textAlignLeft}>{item?.membershipStatus}</td>
								<td className={classes.textAlignLeft}>{item?.validity}</td>
								<td className={classes.textAlignLeft}>{item?.session}</td>
								<td className={classes.textAlignLeft}>{item?.startDate}</td>
								<td className={classes.textAlignLeft}>{item?.endDate}</td>
								<td className={classes.textAlignLeft}>{item?.expiryStatus}</td>
								<td className={classes.textAlignLeft}>{item?.invoiceNo}</td>
								<td className={classes.textAlignRight}>{item?.price}</td>
								<td className={classes.textAlignLeft}>{item?.paymentStatus}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

		</div>
	)
}

export default DataTable
