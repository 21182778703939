import React, { useState, useEffect } from 'react'
import { Typography, Avatar, IconButton, Link, Grid } from '@mui/material'
import { NavigateNext } from '@mui/icons-material'
import useStyles from './style'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import _ from 'lodash'
import history from '@history'
import { useSelector } from 'react-redux'
import Nousers from 'images/calender/Nousers.png'
import { currencyCode, customDateFormat } from 'utils'

function TopPerformers({ getMedeAccessRole, practiceName, dashboardData, setMastDataId, isCareFlow }) {
	const classes = useStyles()
	const [userRole, setUserRole] = useState('')
	const [userRoleName, setUserRoleName] = useState('')
	const ListData = _.orderBy(dashboardData?.ProfileList || [], ['paymentAmount'], ['desc'])
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	useEffect(() => {
		setUserRole(getMedeAccessRole[0]?.mastRoleUuid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getMedeAccessRole])

	useEffect(() => {
		!_.isEmpty(userRole) && setMastDataId(userRole)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRole])

	useEffect(() => {
		if (userRole !== '') {
			let userRoleId = [userRole]
			let tempArr = []
			_.map(userRoleId || [], (roleId) => {
				let foundRoles = _.find(getMedeAccessRole, { mastRoleUuid: roleId })
				tempArr.push(foundRoles)
			})
			setUserRoleName(tempArr)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRole])

	return (
		<div className={classes.root}>
			<div className={classes.titleContainer}>
				<Typography variant='h6'>Top performers at your {isCareFlow ? 'practice' : 'establishment'}</Typography>
				<div className={classes.buttonContainer}>
					<FormControl sx={{ width: 148 }}>
						<Select
							id='selectdoctor'
							name='selectdoctor'
							value={userRole}
							color='secondary'
							disabled={_.isEmpty(getMedeAccessRole) ? true : false}
							onChange={(event) => {
								setUserRole(event.target.value)
							}}
						>
							{!_.isEmpty(getMedeAccessRole) &&
								getMedeAccessRole?.map((option) => (
									<MenuItem key={option?.mastRoleUuid} value={option?.mastRoleUuid}>
										{option?.mastRoleName}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</div>
			</div>

			<div className={!_.isEmpty(ListData) ? classes.listContainer1 : classes.listContainer2}>
				{!_.isEmpty(ListData) ? (
					_.map(ListData, (item, index) => (
						<Grid key={item?.tentUserUuid} container className={classes.list}>
							<Grid item xs={1.5} sm={1.2}>
								<div className={classes.cardImage}>
									<Avatar alt={item?.tentUserFirstName} variant='square' src={item?.thumbnailFilePath} />
								</div>
							</Grid>
							<Grid item xs={10.5} sm={3.3}>
								<div className={classes.cardDetailName}>
									<Typography variant='body1'>Name &#38; Joined on</Typography>
									<Typography variant='subtitle1'>
										{item?.tentUserFirstName ? (isCareFlow ? 'Dr. ' : 'Mr. ') : '-'}
										{item?.tentUserFirstName} <span>{customDateFormat(item?.createdOn, dateFormatForViewer)}</span>
									</Typography>
								</div>
							</Grid>
							<Grid item xs={5} sm={3}>
								<div className={classes.cardDetail}>
									<Typography variant='body1'>Total Appointments</Typography>
									<Typography variant='subtitle1'>{item?.appointmentCount}</Typography>
								</div>
							</Grid>
							<Grid item xs={5} sm={3}>
								<div className={classes.cardDetailAmount}>
									<Typography variant='body1'>Total Amount</Typography>
									<Typography variant='subtitle1'> {currencyCode(item?.paymentAmount, currencyType)}</Typography>
								</div>
							</Grid>
							<Grid item xs={2} sm={0.5}>
								<div className={classes.cardNavigate}>
									<IconButton id='performerdetails' name='performerdetails' aria-label='navigateNext' size='small'>
										<Link href={`/calendar?specialist=${item?.tentUserUuid || 'select'}`}>
											<NavigateNext />
										</Link>
									</IconButton>
								</div>
							</Grid>
						</Grid>
					))
				) : (
					<div className={classes.noUsers}>
						<div>
							<img
								src={Nousers}
								alt='No appointments found'
								width='280'
								height='280'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									history.push({ pathname: '/setting', search: '?tabId=staffmanagement', state: { openPopup: true } })
								}}
							/>
						</div>
						<Typography
							variant='subtitle1'
							onClick={() => {
								history.push({ pathname: '/setting', search: '?tabId=staffmanagement', state: { openPopup: true } })
							}}
						>
							Click here to add {userRoleName[0]?.mastRoleName ? userRoleName[0]?.mastRoleName : 'User'}
						</Typography>
					</div>
				)}
			</div>
		</div>
	)
}

export default TopPerformers
