import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	profileTable: {
		// height: 600,
		width: '100%',
		'& .MuiDataGrid-iconSeparator ': {
			display: 'none',
		},
		'& .MuiDataGrid-root .MuiDataGrid-cell--withRenderer': {
			// justifyContent: 'center',
			gap: 8,
		},
		'& .MuiDataGrid-row': {
			cursor: 'pointer',
		},
		'& .MuiDataGrid-columnHeaderTitleContainer': {
			padding: '0px 0px',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '600',
		},
		'& .MuiDataGrid-dataContainer': {
			backgroundColor: '#fff',
		},
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-columnHeaderWrapper': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: '#EBF4FF',
		},
		'& .MuiButton-root.MuiButton-text': {
			color: '#000',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF',
		},
		'& .MuiDataGrid-cell': {
			border: 'none',
			color: '#000',
		},
		'& .AlignLeftSide': {
			justifyContent: 'flex-start !important',
		},
		'& .MuiDataGrid-root': {
			border: 'none',
			'& .MuiDataGrid-cell--withRendere': {
				[theme.breakpoints.down('sm')]: {
					display: 'block !important',
				},
			},
			'& .MuiDataGrid-footerContainer': {
				borderBottomLeftRadius: 8,
				borderBottomRightRadius: 8,
				background: '#ffffff',
			},
		},
		[theme.breakpoints.down('md')]: {
			height: 200,
			width: '100%',
		},

		'& .MuiDataGrid-footerContainer': {
			display: 'none',
		},
	},
	progressStatus: {
		fontWeight: 500,
		paddingRight: 4,
		animation: 'dotAnimation 1.5s infinite',
	},
	'@keyframes dotAnimation': {
		'0%': {
			content: ' ',
		},
		'33%': {
			content: '.',
		},
		'66%': {
			content: '..',
		},
		'100%': {
			content: '...',
		},
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		background: '#fff',
		paddingBlock: 8,
		paddingInline: 12,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		'& .MuiSvgIcon-root': {
			cursor: 'pointer',
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
	},
	colorContain: {
		color: '#000',
	},
	messageText: {
		fontSize: 14,
		color: '#2A2A2A',
		fontWeight: 500,
	},
	groupText: {
		fontSize: 12,
	},
	customTooltip: {
		backgroundColor: '#6B7280',
		maxWidth: '300px',
		fontSize: 12,
		color: '#fff',
	},
	arrow: {
		color: '#6B7280',
	},
	noData: {
		background: '#fff',
		width: '100%',
		display: 'grid',
		placeItems: 'center',
		paddingBlock: 20,
	},
	iconView: {
		border: '1px solid #8CC63F',
		color: '#8CC63F',
		borderRadius: '50%',
		'&:hover': {
			background: '#8CC63F',
		},
		'&:hover .MuiSvgIcon-root': {
			color: 'white',
		},
		'& .MuiSvgIcon-root': {
			fontSize: 18,
		},
	},
	iconDelete: {
		border: '1px solid #DF0707',
		borderRadius: '50%',
		color: '#DF0707',
		'&:hover': {
			background: '#DF0707',
		},
		'&:hover .material-icons': {
			color: 'white',
		},
		'& .material-icons': {
			fontSize: 18,
		},
	},
	table_container_draft: {
		height: '65vh',
		width: '100%',
		backgroundColor: '#FFF',
		'& .MuiDataGrid-iconSeparator ': {
			display: 'none',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '600',
		},
		'& .MuiDataGrid-dataContainer': {
			backgroundColor: '#fff',
		},
		'& .MuiDataGrid-columnHeaderWrapper': {
			backgroundColor: '#C3EAFF',
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: '#EBF4FF',
		},
		'& .MuiButton-root.MuiButton-text': {
			color: '#000',
		},
		'& .MuiDataGrid-row:hover': {
			backgroundColor: '#E4FDFF',
		},

		'& .stylingDataGridCellsSno': {},

		// Making the datagrid rows auto height
		'& .MuiDataGrid-renderingZone': {
			maxHeight: 'none !important',
			overflowY: 'scroll',
			width: 'fit-content !important',
		},
		'& .MuiDataGrid-cell': {
			// lineHeight: 'unset !important',
			maxHeight: 'none !important',
			whiteSpace: 'normal',
			paddingBlock: 2,
			display: 'flex',
			border: 'none',
			alignItems: 'center',
		},
		'& .MuiDataGrid-row': {
			maxHeight: 'none !important',
			// overflowY: 'scroll',
		},

		'& .stylingDataGridCellsText': {
			paddingInlineStart: 14,
		},
		'& .stylingDataGridCellsSNO': {
			display: 'flex',
			justifyContent: 'center',
		},
		'& .stylingDataGridCellsPrice': {
			paddingInlineEnd: 24,
			display: 'flex',
			justifyContent: 'flex-end',
		},
		'& .MuiDataGrid-footerContainer': {
			display: 'none',
		},
	},
}))
export default useStyles
