import React from 'react'
import EstablishmentOwner from './EstablishmentOwner'
import IndividualDashboard from './IndividualDashboard/index'
import { useSelector } from 'react-redux'
import _ from 'lodash'

export default function DashBoard() {
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const { userRole, groupName } = mainmenuState
	const isOwner = _.isEqual(userRole, 'Owner')
	const isAdmin = _.isEqual(userRole, 'Admin')
	const isCare = _.isEqual(groupName, 'health care')
	const isFitness = _.isEqual(groupName, 'fitness')
	const isMind = _.isEqual(groupName, 'wellness')
	const isSport = _.isEqual(groupName, 'sports')
	const isSpa = _.isEqual(groupName, 'spa')
	return (
		<>
			{isAdmin || isOwner ? (
				<EstablishmentOwner isCare={isCare} isFitness={isFitness} isMind={isMind} isSport={isSport} isSpa={isSpa} />
			) : (
				<IndividualDashboard isCare={isCare} />
			)}
		</>
	)
}
