import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getServiceList: (userType, uuid) => {
		return axios.get(API_ENDPOINTS.SERVICE_EXPERIENCE_GET, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	saveServiceList: (userType, uuid, data) => {
		return axios.post(`${API_ENDPOINTS.SAVE_SERVICE_EXPERIENCE}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	updateServiceList: (userType, uuid, data) => {
		return axios.put(`${API_ENDPOINTS.UPDATE_SERVICE_EXPERIENCE}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	deleteService: (profileExpUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_SERVICE}`, {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { profileExpUuid } },
		})
	},
}
