import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setChangeMail: (data) => {
		return axios.post(
			`${API_ENDPOINTS.CHANGE_MAIL_ID}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
				},
			}
		)
	},
	setChangeUserMail: (data) => {
		return axios.post(
			`${API_ENDPOINTS.CHANGE_USER_EMAIL}`,
			{ ...data },
			{
				headers: {
					isAuthRequired: true,
					'Content-Type': 'application/json',
					'X-SECURITY': csrf(),
				},
			}
		)
	},
}
