import React, { useEffect } from 'react'
import { Grid, Typography, TextField, Autocomplete, IconButton, Icon, Button, FormHelperText, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FieldArray, ErrorMessage, FormikProvider } from 'formik'
import CustomTimePicker from 'app/sharedComponents/CustomTimePicker'
import moment from 'moment'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { fixedValidate } from 'utils'
import DefaultTimePicker from 'app/sharedComponents/DefaultTimePicker'

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		marginTop: theme.spacing(1),
	},
	suggText: {
		textAlign: ({ isMobile }) => (isMobile ? 'left' : 'center'),
	},
	addExperiencebtn: {
		cursor: 'pointer',
		color: 'red',
		padding: 0,
		margin: theme.spacing(1, 0),
	},
	ButtonField: {
		display: 'flex',
		gap: 8,
		paddingTop: 5,
		'& .MuiButton-root': {
			color: '#000',
			borderRadius: '2px',
			border: '1px solid gray',
			'&.active': {
				backgroundColor: '#000',
				border: '1px solid gray',
				color: '#fff',
			},
		},
	},
	timeSlotsSection: {
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),

		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			marginTop: theme.spacing(1.5),
		},
	},
	slotHead: {
		margin: theme.spacing(1, 0),
	},
	addExperiencebtn1: {
		cursor: 'pointer',
		color: '#000000',
		opacity: 0.8,
	},
	sltHeader: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		'& .MuiTypography-body1': {
			whiteSpace: 'nowrap',
		},
	},
	chip: {
		marginRight: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
}))

const consultantionMode = ['In-person', 'Online', 'Home']
const hours = [
	{
		consultantionDuration: '10 mins',
		minutes: 10,
	},
	{
		consultantionDuration: '15 mins',
		minutes: 15,
	},
	{
		consultantionDuration: '30 mins',
		minutes: 30,
	},
	{
		consultantionDuration: '45 mins',
		minutes: 45,
	},
	{
		consultantionDuration: '1 hour',
		minutes: 60,
	},
	{
		consultantionDuration: '1 hour 15 min',
		minutes: 75,
	},
	{
		consultantionDuration: '1 hour 30 min',
		minutes: 90,
	},
	{
		consultantionDuration: '1 hour 45 min',
		minutes: 105,
	},
	{
		consultantionDuration: '2 hour',
		minutes: 120,
	},
]
const SlotForm = ({
	isMobile,
	isTab,
	formik,
	specialityList,
	specialityLoading,
	formikFormatedData,
	selectedDays,
	changeSelectedDays,
	changeSpecialityBasedDays,
	changeDaysToPrevious,
	resetDays,
	currentTentTypeId,
	groupId,
	setStartDate,
	setEndDate,
	paymentRequired,
	togglePayment,
	slotType,
}) => {
	const classes = useStyles({ isMobile })
	const timeZone = '+05:30'
	const pickerStarttime = new Date('Dec 2 2023 09:00')
	const pickerEndTime = new Date('Dec 2 2023 18:00')
	// Get the timezone
	const countryTimezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezone || '')
	const currentPractice = countryTimezone.match(/\) (.+)/)
	const defaultValueTimezone = currentPractice[1]

	const timeData = {
		dayOn: [],
		allDay: 'false',
		sessionStartTime: moment(pickerStarttime).format(`HH:mm:ss${timeZone}`),
		sessionEndTime: moment(pickerEndTime).format(`HH:mm:ss${timeZone}`),
		consultantionFees: '',
		consultantionDuration: {
			consultantionDuration: '10 mins',
			minutes: 10,
		},
		consultantionMode: [],
		isPaymentMandatory: false,
	}

	const getLastIndexes = () => {
		const specialityIdx = formik?.values?.specialityDetails.length - 1
		const detailIdx = formik?.values?.specialityDetails[specialityIdx].details.length - 1
		const timingIdx = formik?.values?.specialityDetails[specialityIdx].details[detailIdx].timings.length - 1
		let ids = { specialities: specialityIdx, details: detailIdx, timings: timingIdx }
		return ids
	}

	useEffect(() => {
		const specialityLength = formik?.values?.specialityDetails?.length
		const detailsLength = formik?.values?.specialityDetails[specialityLength - 1].details.length
		const timingLength = formik?.values?.specialityDetails[specialityLength - 1].details[detailsLength - 1].timings.length
		formik?.setFieldValue(`specialityDetails[${specialityLength - 1}].details[${detailsLength - 1}].timings[${timingLength - 1}].dayOn`, selectedDays)
		formik?.setFieldValue(
			`specialityDetails[${specialityLength - 1}].details[${detailsLength - 1}].timings[${timingLength - 1}].allDay`,
			`${selectedDays.length === 7}`
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDays])

	const onDeleteChip = (specialityIndex, detailsIndex, index, currentDay, timingLength) => {
		let dates = []
		_.map(formik?.values?.specialityDetails[specialityIndex].details[detailsIndex].timings[index].dayOn, (day) => {
			if (currentDay !== day) {
				dates.push(day)
			}
		})
		if (timingLength === 1 || 2) {
			formik?.setFieldValue(`specialityDetails[${specialityIndex}].details[${detailsIndex}].timings[${index}].dayOn`, dates)
			formik?.setFieldValue(`specialityDetails[${specialityIndex}].details[${detailsIndex}].timings[${index}].allDay`, `${dates.length === 7}`)
			changeSelectedDays(currentDay)
		} else {
			formik?.setFieldValue(`specialityDetails[${specialityIndex}].details[${detailsIndex}].timings[${index}].dayOn`, dates)
			formik?.setFieldValue(`specialityDetails[${specialityIndex}].details[${detailsIndex}].timings[${index}].allDay`, `${dates.length === 7}`)
		}
	}

	const onCloseClick = (timingHelpers, i, currentDayOn) => {
		let removeTime = () => {
			return new Promise((resolve) => {
				timingHelpers.remove(i)
				resolve()
			})
		}
		removeTime().then(() => {
			const timingIdx = formik?.values?.specialityDetails[getLastIndexes().specialities].details[getLastIndexes().details].timings.length - 1
			const dayOn = formik?.values?.specialityDetails[getLastIndexes().specialities].details[getLastIndexes().details].timings[i - 1]?.dayOn
			if (_.isEqual(timingIdx, i)) {
				changeDaysToPrevious(dayOn)
			}
		})
	}

	const onCloseSpecialityClick = (specialityHelper, i) => {
		specialityHelper.remove(i)
		const specialityIdx = formik?.values?.specialityDetails.length - 1
		const dayOn = formik?.values?.specialityDetails[i - 1].details[getLastIndexes().details].timings[getLastIndexes().timings].dayOn
		if (_.isEqual(specialityIdx, i)) {
			changeDaysToPrevious(dayOn)
		}
	}

	useEffect(() => {
		const specialityLength = formik?.values?.specialityDetails?.length
		const detailsLength = formik?.values?.specialityDetails[specialityLength - 1].details.length
		const timingLength = formik?.values?.specialityDetails[specialityLength - 1].details[detailsLength - 1].timings.length
		const modeData = formik?.values?.specialityDetails[specialityLength - 1].details[detailsLength - 1].timings[timingLength - 1]?.consultantionMode
		// formik?.values?.specialityDetails[index].details[idx].timings[i].consultantionMode
		// let tempArr = []
	}, [])

	return (
		<FormikProvider value={formik}>
			<FieldArray
				name='specialityDetails'
				render={(specialityHelpers) => (
					<div>
						{_.map(formik?.values?.specialityDetails, (splData, index) => (
							<div className={classes.timeSlotsSection}>
								<Grid item lg={12} container>
									{formik?.values?.specialityDetails.length > 1 && (
										<IconButton
											style={{ display: 'none' }}
											onClick={() => {
												onCloseSpecialityClick(specialityHelpers, index)
											}}
										>
											<Icon color='error'>cancel</Icon>
										</IconButton>
									)}
								</Grid>
								<Grid item lg={12}>
									<Typography className={classes.slotHead}>Slots:</Typography>
									<Grid container>
										<Grid item container spacing={isMobile ? 0 : 1} className={classes.sltHeader}>
											<Grid item lg={2.8}>
												<Typography>Starts at *</Typography>
											</Grid>
											{/* <Grid item lg={0.5} /> */}
											<Grid item lg={2.8}>
												<Typography>Ends at *</Typography>
											</Grid>
											{/* <Grid item lg={0.5} /> */}
											<Grid item lg={2}>
												<Typography>Price *</Typography>
											</Grid>
											{/* <Grid item lg={0.5} /> */}
											<Grid item lg={2.5}>
												<Typography>Appointment mode *</Typography>
											</Grid>
											{/* <Grid item lg={0.5} /> */}
											<Grid item lg={1}>
												<Typography>Add slot every *</Typography>
											</Grid>
											{/* <Grid item lg={0.5} /> */}
										</Grid>
										<FieldArray
											name={`specialityDetails[${index}].details`}
											render={(detailHelpers) => (
												<div key={index} style={{ width: '100%' }}>
													{_.map(formik?.values?.specialityDetails[index].details, (detail, idx) => (
														<>
															<FieldArray
																key={idx}
																name={`specialityDetails[${index}].details[${idx}].timings`}
																render={(timingHelpers) => (
																	<>
																		{_.map(formik?.values?.specialityDetails[index].details[idx].timings, (slot, i) => (
																			<>
																				<Grid item container spacing={isMobile ? 0 : 1} alignItems={'center'} key={i}>
																					<Grid item lg={2.3} xs={12}>
																						{/* <CustomTimePicker
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`}
																							needLabel={true}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`}
																							placeholder='9.00  AM'
																							className={classes.timePicker}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							value={moment(
																								formik?.values?.specialityDetails[index].details[idx].timings[i].sessionStartTime,
																								'HH:mm'
																							).format()}
																							onAccept={(value) => {
																								console.log('value', value, `${moment(value).format('HH:mm:ss')}${timeZone}`, timeZone)
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`,
																									`${moment(value).format('HH:mm:ss')}${timeZone}`
																								)
																								setStartDate(`${moment(value).format('HH:mm:ss')}`)
																							}}
																							sx={{ width: 130 }}
																						/> */}
																						<DefaultTimePicker
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`}
																							needLabel={true}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`}
																							className={classes.timePicker}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							value={
																								new Date(
																									new Date(
																										`Dec 2 2023 ${formik?.values?.specialityDetails[index].details[idx].timings[i].sessionStartTime}`
																									).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
																								)
																							}
																							onChange={(value) => {
																								let selectedTime = `${moment(value).format('HH:mm:ss')}${timeZone}`
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`,
																									selectedTime
																								)
																								setStartDate(selectedTime)
																							}}
																						/>
																						<ErrorMessage
																							component={FormHelperText}
																							error
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionStartTime`}
																						/>
																					</Grid>
																					<Grid item lg={0.5} xs={12}>
																						<Typography className={classes.suggText}>to</Typography>
																					</Grid>
																					<Grid item lg={2.3} xs={12}>
																						{/* <CustomTimePicker
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`}
																							needLabel={true}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`}
																							placeholder='9.00  AM'
																							className={classes.timePicker}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							value={moment(
																								formik?.values?.specialityDetails[index].details[idx].timings[i].sessionEndTime,
																								'HH:mm'
																							).format()}
																							onAccept={(value) => {
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`,
																									`${moment(value).format('HH:mm:ss')}${timeZone}`
																								)
																								setEndDate(`${moment(value).format('HH:mm:ss')}`)
																							}}
																							sx={{ width: 130 }}
																						/> */}
																						<DefaultTimePicker
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`}
																							needLabel={true}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`}
																							className={classes.timePicker}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							value={
																								new Date(
																									new Date(
																										`Dec 2 2023 ${formik?.values?.specialityDetails[index].details[idx].timings[i].sessionEndTime}`
																									).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
																								)
																							}
																							onChange={(value) => {
																								let selectedTime = `${moment(value).format('HH:mm:ss')}${timeZone}`
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`,
																									selectedTime
																								)
																								setEndDate(selectedTime)
																							}}
																						/>
																						<ErrorMessage
																							component={FormHelperText}
																							error
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].sessionEndTime`}
																						/>
																					</Grid>
																					<Grid item lg={0.5} xs={12}>
																						<Typography className={classes.suggText}>at</Typography>
																					</Grid>
																					<Grid item lg={1.5} xs={12}>
																						<TextField
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionFees`}
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionFees`}
																							value={formik?.values?.specialityDetails[index].details[idx].timings[i].consultantionFees}
																							onChange={(e, value) => {
																								if (e.target.value > 0) {
																									formik?.setFieldValue(
																										`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionFees`,
																										fixedValidate(e)
																									)
																								} else {
																									formik?.setFieldValue(
																										`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionFees`,
																										''
																									)
																								}
																							}}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							size='small'
																							color='secondary'
																							margin='dense'
																							fullWidth
																						/>
																						<ErrorMessage
																							component={FormHelperText}
																							error
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionFees`}
																						/>
																					</Grid>
																					<Grid item lg={0.5} xs={12}>
																						<Typography className={classes.suggText}>: :</Typography>
																					</Grid>

																					<Grid item lg={2.2} xs={12}>
																						<Autocomplete
																							disablePortal
																							multiple
																							disableCloseOnSelect
																							filterSelectedOptions
																							disableClearable
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionMode`}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionMode`}
																							value={formik?.values?.specialityDetails[index].details[idx].timings[i].consultantionMode}
																							options={consultantionMode}
																							limitTags={1}
																							getOptionLabel={(option) => option}
																							size='small'
																							fullWidth
																							className={classes?.autocomplete}
																							getOptionSelected={(option, value) => option === value}
																							isOptionEqualToValue={(option, value) => option === value}
																							onChange={(e, newValue) => {
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionMode`,
																									newValue
																								)
																							}}
																							renderInput={(params) => <TextField fullWidth {...params} size='small' color='secondary' />}
																						/>
																						<ErrorMessage
																							component={FormHelperText}
																							error
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionMode`}
																						/>
																					</Grid>
																					<Grid item lg={0.5} xs={12}>
																						<Typography className={classes.suggText}>: :</Typography>
																					</Grid>
																					<Grid item lg={1.7} xs={12}>
																						<Autocomplete
																							disablePortal
																							id={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionDuration`}
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionDuration`}
																							value={formik?.values?.specialityDetails[index].details[idx].timings[i].consultantionDuration}
																							onChange={(e, newValue) => {
																								formik?.setFieldValue(
																									`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionDuration`,
																									newValue
																								)
																							}}
																							disabled={_.isEqual(slotType, 'profile') ? true : false}
																							options={hours}
																							getOptionLabel={(option) => option?.consultantionDuration}
																							getOptionSelected={(option, value) => option?.minutes === value?.minutes}
																							disableClearable
																							size='small'
																							fullWidth
																							className={classes?.autocomplete}
																							renderInput={(params) => <TextField fullWidth {...params} size='small' color='secondary' />}
																						/>
																						<ErrorMessage
																							component={FormHelperText}
																							error
																							name={`specialityDetails[${index}].details[${idx}].timings[${i}].consultantionDuration`}
																						/>
																					</Grid>
																					<Grid item lg={2} xs={12}>
																						Payment
																						<div className={classes.ButtonField}>
																							<Button
																								onClick={() => {
																									togglePayment(true)
																									formik?.setFieldValue(
																										`specialityDetails[${index}].details[${idx}].timings[${i}].isPaymentMandatory`,
																										true
																									)
																								}}
																								disabled={_.isEqual(slotType, 'profile') ? true : false}
																								sx={{
																									'&.Mui-disabled': {
																										backgroundColor: _.isEqual(slotType, 'profile') ? '#fff' : '',
																									},
																								}}
																								className={
																									formik?.values?.specialityDetails[index].details[idx].timings[i].isPaymentMandatory ? 'active' : ''
																								}
																								variant='contained'
																								size='small'
																							>
																								Yes
																							</Button>
																							<Button
																								onClick={() => {
																									togglePayment(false)
																									formik?.setFieldValue(
																										`specialityDetails[${index}].details[${idx}].timings[${i}].isPaymentMandatory`,
																										false
																									)
																								}}
																								disabled={_.isEqual(slotType, 'profile') ? true : false}
																								className={
																									!formik?.values?.specialityDetails[index].details[idx].timings[i].isPaymentMandatory ? 'active' : ''
																								}
																								variant='contained'
																								size='small'
																							>
																								No
																							</Button>
																						</div>
																					</Grid>
																					{!_.isEqual(slotType, 'profile') && (
																						<Grid item lg={0.1} xs={12}>
																							{isMobile || isTab
																								? formik?.values?.specialityDetails[index].details[idx].timings.length > 1 && (
																										<Button
																											onClick={() => {
																												timingHelpers.remove(i)
																											}}
																											color='error'
																										>
																											Remove
																										</Button>
																								  )
																								: formik?.values?.specialityDetails[index].details[idx].timings.length > 1 && (
																										<IconButton
																											onClick={() => {
																												onCloseClick(
																													timingHelpers,
																													i,
																													formik?.values?.specialityDetails[index].details[idx]?.timings[i]?.dayOn
																												)
																											}}
																										>
																											<Icon color='error'>cancel</Icon>
																										</IconButton>
																								  )}
																						</Grid>
																					)}
																				</Grid>
																				<Grid item xs={12}>
																					{!_.isEmpty(formik?.values?.specialityDetails[index].details[idx]?.timings[i]?.dayOn) &&
																						_.map(formik?.values?.specialityDetails[index].details[idx]?.timings[i]?.dayOn, (day) => {
																							const timingIdx =
																								formik?.values?.specialityDetails[getLastIndexes().specialities].details[getLastIndexes().details]
																									.timings.length - 1
																							const isLastData = timingIdx === i
																							return (
																								<Chip
																									label={_.capitalize(day)}
																									variant='outlined'
																									size='small'
																									disabled={_.isEqual(slotType, 'profile') ? true : false}
																									className={classes.chip}
																									sx={{
																										'&.Mui-disabled': {
																											color: '#000',
																											border: '1px solid #000',
																										},
																									}}
																									onDelete={
																										_.isEqual(slotType, 'profile')
																											? null
																											: isLastData
																											? () => {
																													onDeleteChip(
																														index,
																														idx,
																														i,
																														day,
																														formik?.values?.specialityDetails[index].details[idx].timings.length
																													)
																											  }
																											: null
																									}
																								/>
																							)
																						})}
																				</Grid>
																			</>
																		))}
																		{!_.isEqual(slotType, 'profile') && formik?.values?.specialityDetails.length - 1 === index && (
																			<Grid item container>
																				{formik?.values?.specialityDetails[index].details[idx].timings.length === 12 ? null : (
																					<div className={classes.addExperience}>
																						<Button
																							className={classes.addExperiencebtn}
																							disabled={_.isEmpty(selectedDays)}
																							onClick={() => {
																								resetDays()
																								timingHelpers.push({ ...timeData })
																							}}
																						>
																							+ Add Time Slots
																						</Button>
																					</div>
																				)}
																			</Grid>
																		)}
																	</>
																)}
															/>
														</>
													))}
												</div>
											)}
										/>
									</Grid>
								</Grid>
							</div>
						))}
					</div>
				)}
			/>
		</FormikProvider>
	)
}

export default SlotForm
