import { ACTION_TYPES } from '../../../constants'
// import secureLocalStorage from 'react-secure-storage'

// const setDataToLocalStorage = (open, data, mode) => {
// 	// let obj = { open: open || false, data: data || '', mode: mode || '' }
// 	secureLocalStorage.setItem('verificationModalOpen', open)
// 	secureLocalStorage.setItem('verificationModalData', data)
// 	secureLocalStorage.setItem('verificationModalMode', mode)
// }
// const state = JSON?.parse(secureLocalStorage?.getItem('verificationModal') || {})
const initialState = {
	open: false,
	data: '',
	mode: '',
}

const verificationMail = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_VERIFY_MODAL: {
			return {
				...state,
				open: action?.payload?.open,
				data: action?.payload?.data,
				mode: action?.payload?.mode,
			}
		}
		case ACTION_TYPES.CLOSE_VERIFY_MODAL: {
			return {
				initialState,
			}
		}
		default:
			return state
	}
}

export default verificationMail
