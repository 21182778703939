import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getPatientId: (uuid) => {
		return axios.get(API_ENDPOINTS.PATIENT_ID_DETAILS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { uuid } },
		})
	},
	getBillingOrderID: (mastTentUuid) => {
		return axios.get(API_ENDPOINTS.GET_SALE_ORDER_NUMBER, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastTentUuid } },
		})
	},

	postPatient: (uuid, publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.AUTO_SEARCH_PATIENT_LIST_POST}`, result, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				path: { uuid },
			},
		})
	},

	globalSearch: (mastUuid, params) => {
		return axios.get(API_ENDPOINTS.GLOBAL_SEARCH, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { mastUuid } },
			params: { ...params },
		})
	},

	getAllSalesLists: (params, body) => {
		return axios.post(`${API_ENDPOINTS.GET_ALL_SALE_LIST}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			params: params,
		})
	},

	getDraftAndBilling: (params, body) => {
		return axios.post(`${API_ENDPOINTS.GET_DRAFT_AND_BILLING}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
			params: params,
		})
	},

	deleteDraft: (billingOrderUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_SALE_DRAFT}`, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf(), path: { billingOrderUuid } },
		})
	},

	postGenerateBill: (params, body, custUuid) => {
		return axios.post(`${API_ENDPOINTS.GENERATE_SALE_BILL}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				Custuuid: custUuid,
			},
			params: params,
		})
	},

	postSaveDraft: (params, body, custUuid) => {
		return axios.post(`${API_ENDPOINTS.SAVE_SALE_DRAFT}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				Custuuid: custUuid,
			},
			params: params,
		})
	},

	invoiceReceipt: (body) => {
		return axios.post(`${API_ENDPOINTS.GET_INVOICE_RECEIPT}`, body, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
			},
		})
	},
}
