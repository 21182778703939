import { Close, ArrowDownward } from '@mui/icons-material'
import { Grid, Typography, IconButton, Avatar, Button } from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import { makeStyles } from '@mui/styles'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'
import consultApi from '../../../../../services/consult/api'

export const ImagePreviewLayout = (props) => {
	const classes = imageStyles()
	const { handleClose, imageDetails, setImageDetails } = props

	let BearerToken = secureLocalStorage.getItem('AidiBearer')

	return (
		<div className={classes.dialogLayer}>
			<Grid container direction='row' alignItems='center' style={{ paddingBottom: '25px' }}>
				<Grid item className={classes.leftDetail}>
					<Avatar src={imageDetails?.activeImageObj?.file?.fileUploadedProfilePic} />
					<Grid container direction='column' alignItems='flex-start' style={{ paddingLeft: '12px' }}>
						<Typography className={classes.name}>
							{imageDetails?.activeImageObj?.file?.fileUploaderName !== ' '
								? imageDetails?.activeImageObj?.file?.fileUploaderName
								: imageDetails?.activeImageObj?.file?.fileUploadedUuid}
						</Typography>
						<Typography style={{ color: 'grey' }}>
							{moment(imageDetails?.activeImageObj.currentDateTime).format('DD/MM/YYYY [at] hh:mm A')}
						</Typography>
					</Grid>
				</Grid>
				<a
					href={consultApi.getFileDownloadUrl(imageDetails?.activeImageObj?.file?.documentUuid, BearerToken)}
					target='_blank'
					rel='noreferrer'
					download
					className={classes.videoLinkDoc}
					style={{ padding: '8px 20px', marginRight: 'auto', borderBottom: 'transparent' }}
				>
					<Button
						endIcon={<ArrowDownward />}
						variant='outlined'
						className={clsx(classes.btnStyle)}
						style={{ padding: '8px 20px', marginRight: 'auto' }}
					>
						Download
					</Button>
				</a>
				<IconButton className={clsx(classes.btnStyle)} style={{ padding: '8px' }} onClick={handleClose}>
					<Close />
				</IconButton>
			</Grid>
			<Grid container direction='row'>
				<Grid item xs={9} className={classes.imageLayer}>
					<div
						style={{
							height: '100%',
							width: '100%',
						}}
					>
						<img src={imageDetails.activeImage} width='100%' />
					</div>
				</Grid>
				<Grid item xs={3} className={classes.imgLayout}>
					<Grid container direction='row' className={classes.imageListLayer}>
						{Object.keys(imageDetails.imageObj).map((data) => {
							return (
								<div
									className={classes.docImage}
									onClick={() => {
										setImageDetails({
											...imageDetails,
											activeImage: imageDetails.imageObj[data].file.viewFileUrl,
											activeImageObj: imageDetails.imageObj[data],
										})
									}}
								>
									<img src={`${imageDetails.imageObj[data].file.viewFileUrl}`} alt='docImg' className={classes.imgDisplay} />
								</div>
							)
						})}
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

const imageStyles = makeStyles((theme) => ({
	dialogLayer: {
		// padding: '20px',
	},
	leftDetail: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		marginRight: '230px',
	},
	name: {
		color: '#121213',
		fontSize: '16px',
		fontWeight: 500,
	},
	btnStyle: {
		borderRadius: '8px',
		border: '2px solid #242424',
		color: '#121213',
		fontWeight: 600,
		'&:hover': {
			border: '2px solid #242424',
		},
	},
	docImage: {
		width: '33.33%',
		borderRadius: '6px',
		height: '108px',
		maxWidth: 'calc(33.33% - 8px)',
		margin: '4px',
		cursor: 'pointer',
	},
	imgDisplay: {
		height: '100%',
		width: '100%',
		borderRadius: '6px',
	},
	imageLayer: {
		padding: '0px 70px',
		height: '80vh',
		'& img': {
			borderRadius: '6px',
			height: '100%',
		},
	},
	imgLayout: {
		paddingRight: '40px',
		// height: '100vh',
		overflow: 'scroll',
	},
	videoLinkDoc: {
		backgroundColor: 'transparent !important',
		borderBottom: 'transparent',
	},
}))
