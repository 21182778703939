/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { Avatar, Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from '@mui/material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import useStyles from './style'
import Button from '@mui/material/Button'
import _ from 'lodash'
import fileApi from 'services/fileupload/GetApi'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getImgUrl } from 'utils'
import ViewImage from './viewImage'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Close } from '@mui/icons-material'

const CompDropZone = (props) => {
	const { displayUploadImage, formik, imgResponse, setImage, imageTextOne, setTriggerGetApi, isMulti = false, events, deleteAllFiles } = props
	const classes = useStyles({ events })
	const dispatch = useDispatch()
	const [viewOpen, setViewOpen] = useState(false)
	const token = useSelector((state) => state?.Authentication?.BearerToken)

	// Converting bytes
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 2097152
	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		setStatus: 'success',
		maxSize: formatBytes(maxFileSize),
		maxFiles: isMulti ? 6 : 1,
		multiple: isMulti,
		accept: 'image/jpeg, image/jpg, image/jpe, image/png',
		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				displayUploadImage(acceptedFiles)
			}
		},
	})

	// Rejected
	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<div key={file.path}>
			{errors.map((e) => (
				<Typography style={{ color: 'red' }} variant='caption' key={e.code}>
					{e.message}
				</Typography>
			))}
		</div>
	))

	const deleteImage = (file) => {
		const onSuccess = (res) => {
			if (isMulti) {
				const imgs = _.filter(imgResponse, (img) => !_.isEqual(img, file))
				setImage(imgs)
			} else {
				setImage([])
			}
		}
		const onFailure = (err) => {}

		fileApi
			.deleteFile(file.uuid || file)
			.then(onSuccess)
			.catch(onFailure)
	}
	const [fileSpecificData, setFileSpecificData] = useState('')

	return (
		<>
			{isMulti ? (
				<div className={classes.multiImgs}>
					{imgResponse?.length < 6 && (
						<div className={classes.uploadImage}>
							<div className={classes.dropzone} {...getRootProps()}>
								<input {...getInputProps()} />
								<CloudUploadOutlinedIcon className={classes.icon} />
								<Typography variant='body1' className={classes.dragText}>
									{imageTextOne}
								</Typography>
								<div className={classes.overlay}></div>
								<Button variant='outlined' className={classes.btnContent} onClick={open}>
									Upload Banner
								</Button>
							</div>
						</div>
					)}
					<div className={classes.helperTextContainer}>
						<FormControl sx={{ flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
							<Typography>Need Banner?</Typography>
							<RadioGroup
								row
								aria-labelledby='demo-radio-buttons-group-label'
								name='radio-buttons-group'
								value={formik?.values?.isBanner}
								onChange={(event) => {
									formik?.setFieldValue('isBanner', event.target.value)
								}}
							>
								<FormControlLabel value='yes' control={<Radio />} label='Yes' />
								<FormControlLabel value='no' control={<Radio />} label='No' />
							</RadioGroup>
						</FormControl>
						<Typography variant='body1'>
							PNG, JPG & JPEG <span> Max 2MB/File </span>{' '}
							<span>
								<Tooltip title='Add upto 6 banner images '>
									<HelpOutlineOutlinedIcon />
								</Tooltip>
							</span>
						</Typography>
						<Typography variant='body1'>
							Minimum Size <span> 1237px * 311 PX</span>
						</Typography>
					</div>
					{formik?.values?.isBanner === 'yes' && (
						<div style={{ display: 'flex', gap: 24, flexWrap: 'wrap' }}>
							{!_.isEmpty(imgResponse) &&
								_.map(imgResponse, (file) => {
									return (
										<div className={classes.imgContainer}>
											<div className={classes.deletIcon} onClick={() => deleteImage(file)}>
												<HighlightOffOutlinedIcon />
											</div>
											<Avatar src={file?.filePath || getImgUrl(file, token)} className={classes.mainImg} />
										</div>
									)
								})}
						</div>
					)}
				</div>
			) : (
				<div className={classes.container}>
					{!_.isEmpty(imgResponse) ? ( // eslint-disable-next-line jsx-a11y/alt-text
						<div>
							{/* <div className={classes.deletIcon} onClick={() => deleteImage(imgResponse)}>
								<HighlightOffOutlinedIcon />
							</div> */}
							{!events ? (
								<Avatar src={getImgUrl(imgResponse, token) || imgResponse?.uuid || null} className={classes.mainImg} />
							) : (
								<Box
									sx={{
										borderRadius: '4px',
										width: '240px',
										height: '120px',
										position: 'relative',
									}}
								>
									<img
										src={getImgUrl(imgResponse.length > 1 ? imgResponse[1] : imgResponse, token) || imgResponse?.uuid || null}
										style={{ width: '100%', height: '100%', borderRadius: '4px' }}
									/>
									<div
										className=''
										style={{
											position: 'absolute',
											top: '-4px',
											right: '-4px',
											zIndex: 10,
											background: '#ff2e53',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: '25px',
											height: '25px',
											borderRadius: '50%',
										}}
										onClick={() => deleteAllFiles()}
									>
										<Close sx={{ color: '#fff', fontSize: '16px' }} />
									</div>
								</Box>
							)}
						</div>
					) : !events ? (
						<div className={classes.dropzone} {...getRootProps()}>
							<input {...getInputProps()} />
							<CloudUploadOutlinedIcon className={classes.icon} />
							<Typography variant='body1' className={classes.dragText}>
								{imageTextOne}
							</Typography>
							<Button variant='outlined' className={classes.btnContent} onClick={open}>
								Upload
							</Button>
						</div>
					) : (
						<>
							<Box
								sx={{
									background: '#F1F1F1',
									borderRadius: '4px',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									width: '240px',
									height: '120px',
									cursor: 'pointer',
								}}
								{...getRootProps()}
								onClick={open}
							>
								<input {...getInputProps()} />
								<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Vector.svg`} alt='C' style={{ width: '50px', height: '50px' }} />
								<Typography sx={{ textAlign: 'center', color: '#9c9c9c' }}>Upload Banner</Typography>
							</Box>
						</>
					)}
					<aside>
						<p>{fileRejectionItems}</p>
					</aside>
				</div>
			)}
			<aside>
				<p>{fileRejectionItems}</p>
			</aside>
			<ViewImage
				viewOpen={viewOpen}
				fileSpecificData={fileSpecificData}
				imgResponse={imgResponse}
				setViewOpen={setViewOpen}
				deleteImage={() => deleteImage()}
				isMulti={isMulti}
				setImage={setImage}
				setTriggerGetApi={setTriggerGetApi}
				events={events}
			/>
		</>
	)
}

export default CompDropZone
