// GoogleMapsContext.js
import React, { createContext, useContext } from 'react'
import { useLoadScript } from '@react-google-maps/api'

const GoogleMapsContext = createContext()

const libraries = ['places']

export const GoogleMapsProvider = ({ children }) => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		libraries,
	})

	return <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>{children}</GoogleMapsContext.Provider>
}

export const useGoogleMaps = () => useContext(GoogleMapsContext)
