/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
// import LookupApi from '../../../../../../services/lookup/api'
// import lookupApi from '../../../../../../services/welcomeCare/api'
import LookupApi from '../../../../services/lookup/api'
import MembershipAPI from 'services/Settings/Membership/api'

const Lookup = (props) => {
	const { setStatesOption, stateParams, open, opens, setMemberShipList, mastuuid } = props
	const cancelExecutor = useRef()
	const openCndn = open || opens

	useEffect(() => {
		const onSuccess = (res) => {
			setStatesOption(res.data.data)
		}
		const onFailure = () => {
			setStatesOption([])
		}
		stateParams?.mastCountryCode && LookupApi.getLookup('citiesStates', { ...stateParams }, cancelExecutor).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stateParams])

	// Getting the membership list:
	useEffect(() => {
		if (openCndn) {
			MembershipAPI.getMembershipListForSettings(mastuuid).then(
				(res) => {
					if (res?.data?.status === 'success') {
						setMemberShipList(res?.data?.data?.getTentMembership)
					}
				},
				(err) => {
					setMemberShipList([])
				}
			)
		}
	}, [openCndn])

	return <></>
}

export default Lookup
