/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Radio } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { GroupSelection } from '../../session/actions'
import Button from '../../sharedComponents/Button'
import history from '@history'
import generateEmailToken from '../../../services/generateEmailToken/api'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import { encryption, decryption, getResponseMessage, failureLogin } from 'utils'
import { setVerifyModal } from 'services/VerficationModal/actions'
import EmailVerificationModal from '../EmailVerificationModal/EmailVerificationModal'
import secureLocalStorage from 'react-secure-storage'
import GroupSelectionApi from '../../../services/Groupselection/api'
import accountDetails from '../../../services/AccountSettings/AccountDetail/api'
import themesConfig from 'app/fuse-configs/themesConfig'

const IconContents = {
	initial: {
		title: 'LYFnGO for providers',
		description: 'LYFnGO serves as a medium between providers and customers. Choose your defined service to explore',
		btnText: 'Select a service',
	},
	care: {
		title: 'LYFnGO Care',
		description:
			'You have stepped into the heart of health care Services. LYFnGO promises you with the best features beneficiary for the provider as well as the customer. Welcome to a New beginning',
		btnText: 'Start',
		uuid: 'fyi6pmtm',
	},
	spa: {
		title: 'LYFnGO Wellness',
		description:
			'Beauty brings Confidence And the confidence brings out the best in a person .LYFnGO promises you with the best features beneficiary for the provider as well as the customer. Welcome to a New beginning.',
		btnText: 'Start',
		uuid: 'irokb9b8',
	},
	mind: {
		title: 'LYFnGO Wellness',
		description:
			'For the Wellness and soul. LYFnGO promises you with the best features beneficiary for the provider as well as the customer. Welcome to a New beginning.',
		btnText: 'Start',
		uuid: '2nzdfwug',
	},
	sports: {
		title: 'LYFnGO Sports',
		description:
			'Train to the best version of a winner..LYFnGO promises you with the best features beneficiary for the provider as well as the customer. Welcome to a New beginning.',
		btnText: 'Start',
		uuid: 'ztyxtevg',
	},
	fitness: {
		title: 'LYFnGO Fitness',
		description:
			'First step to create a Fit and a healthy society. LYFnGO promises you with the best features beneficiary for the provider as well as the customer. Welcome to a New beginning.',
		btnText: 'Start',
		uuid: 'e7z11j8m',
	},
}

const useStyles = makeStyles((theme) => ({
	groupmain: {
		paddingInline: 100,
		paddingBlock: 100,
		display: 'flex',
		justifyContent: 'center',
		margin: 'auto',
		[theme.breakpoints.down('sm')]: {
			margin: 0,
			paddingBlock: 40,
			paddingInline: 10,
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			margin: 0,
			paddingInline: 0,
			paddingBlock: 0,
		},
	},
	groupmaincard: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		gap: 16,
		maxWidth: 500,
		background: 'transparent linear-gradient(90deg, #E9E8E9 0%, #F5F4F6 100%) 0% 0% no-repeat padding-box',
		paddingInline: 40,
		paddingBlock: 100,
		borderBottomRightRadius: 26,
		borderTopRightRadius: 26,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			background: 'none',
			paddingBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			paddingBlock: 70,
			maxWidth: 660,
		},
	},
	cardone: {
		minWidth: 128,
		minHeight: 120,
		// backgroundImage: `url(${'${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/care.png'})`,
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			minWidth: 200,
			minHeight: 140,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 152,
			minHeight: 160,
		},
	},
	caretext: {
		color: '#fff',
		// transform: 'translate(20px, 97px)',
		[theme.breakpoints.down('sm')]: {
			transform: 'translate(20px, 114px)',
			fontSize: 14,
		},
		[theme.breakpoints.up('sm')]: {
			transform: 'translate(20px, 126px)',
			fontSize: 16,
		},
	},
	cardtwo: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/fitness.png`})`,
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			minWidth: 200,
			minHeight: 140,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 152,
			minHeight: 160,
		},
	},
	cardthree: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/image%20(4).png`})`,
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			minWidth: 200,
			minHeight: 140,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 152,
			minHeight: 160,
		},
	},
	cardfour: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/sports%20(1).png`})`,
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			minWidth: 200,
			minHeight: 140,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 152,
			minHeight: 160,
		},
	},
	cardfive: {
		minWidth: 128,
		minHeight: 120,
		backgroundImage: `url(${`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/spa%201.png`})`,
		cursor: 'pointer',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 16,
		[theme.breakpoints.down('sm')]: {
			minWidth: 200,
			minHeight: 140,
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 152,
			minHeight: 160,
		},
	},
	spatext: {
		transform: 'translate(6px, 94px)',
		color: '#fff',
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			transform: 'translate(20px, 111px)',
			fontSize: 14,
		},
		[theme.breakpoints.up('sm')]: {
			transform: 'translate(4px, 126px)',
			fontSize: 16,
		},
	},
	content: {
		transform: 'translate(0px, 97px)',
		color: '#fff',
		[theme.breakpoints.down('sm')]: {
			transform: 'translate(4px, 112px)',
		},
	},
	welcomeleft: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		gap: 60,
		backgroundColor: '#FFFFFF',
		paddingInline: 42,
		paddingBlock: 108,
		maxWidth: 400,
		borderBottomLeftRadius: 26,
		borderTopLeftRadius: 26,
		[theme.breakpoints.down('sm')]: {
			background: 'none',
			paddingInline: 0,
			paddingBlock: '12px !important',
			justifyContent: 'unset',
			minHeight: '30vh',
			gap: 40,
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: 446,
		},
	},
	welcomeleftone: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		maxHeight: 100,
	},
	heading: {
		textAlign: 'center',
		color: themesConfig.greyDark.palette.primary.main,
		fontSize: 22,
		fontFamily: 'Poppins',
		fontWeight: 600,
	},
	subcontent: {
		textAlign: 'center',
		color: '#707680',
		fontSize: 12,
		fontFamily: 'Poppins',
	},
	selectservice: {
		textAlign: 'center',
		color: '#E22C24',
		fontSize: 12,
		fontFamily: 'Poppins',
		fontWeight: 600,
		cursor: 'pointer',
		'& .muiltr-1axef57-MuiSvgIcon-root': {
			width: '0.5em',
			height: '0.5em',
		},
	},
	welcomerightcontent: {
		textAlign: 'center',
		color: '#707680',
		fontSize: 12,
		fontFamily: 'Poppins',
		width: '40ch',
		[theme.breakpoints.down('sm')]: {
			width: '33ch',
		},
	},
	check: {
		float: 'right',
		top: '0',
		'& .MuiSvgIcon-root': {
			fill: '#2DCD7A',
			borderRadius: '30px',
		},
		'&. makeStyles-check-193 ': {
			fill: '#2DCD7A',
		},
	},
}))

function GroupSelectionModule() {
	const classes = useStyles()
	const [currentId, setCurrentId] = useState(null)
	const groupState = useSelector((states) => states.Authentication)
	const { userEmail, userType, uuid, isAuthenticated, identityUuid } = groupState
	const dispatch = useDispatch()
	const [iconDescription, setIconDescription] = React.useState(IconContents.initial)
	const { title, description } = iconDescription
	const errorJson = useSelector((states) => states?.errorMessages || {})

	useEffect(() => {
		if (isAuthenticated || uuid === '' || uuid === undefined) {
			history.push('/')
		}
	}, [isAuthenticated, uuid])

	useEffect(() => {
		if (!_.isEmpty(currentId)) {
			dispatch(GroupSelection({ groupId: currentId }))
			secureLocalStorage.setItem('groupId', currentId)
			secureLocalStorage.setItem(
				'groupName',
				currentId === 'fyi6pmtm'
					? 'health care'
					: currentId === 'irokb9b8'
					? 'spa'
					: currentId === '2nzdfwug'
					? 'wellness'
					: currentId === 'ztyxtevg'
					? 'sports'
					: currentId === 'e7z11j8m'
					? 'fitness'
					: ''
			)
		}
	}, [currentId, dispatch])

	const [state, setState] = useState([])

	useEffect(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			setState(res?.data?.data)
		}
		const onFailure = () => {
			dispatch(hideLoader())
			setState([])
		}
		GroupSelectionApi.groupSelection().then(onSuccess, onFailure)
	}, [dispatch])
	const [selectedValue, setSelectedValue] = React.useState('')
	const handleRadio = (value) => {
		setSelectedValue(value)
		if (value === 'Health Care') {
			setIconDescription(IconContents.care)
			setCurrentId(IconContents.care.uuid)
		} else if (value === 'Fitness') {
			setIconDescription(IconContents.fitness)
			setCurrentId(IconContents.fitness.uuid)
		} else if (value === 'Wellness') {
			setIconDescription(IconContents.mind)
			setCurrentId(IconContents.mind.uuid)
		} else if (value === 'Sports') {
			setIconDescription(IconContents.sports)
			setCurrentId(IconContents.sports.uuid)
		} else if (value === 'spa') {
			setIconDescription(IconContents.spa)
			setCurrentId(IconContents.spa.uuid)
		}
	}
	const handleChange = (value) => {
		setSelectedValue(value)
		if (value === 'Health Care') {
			setIconDescription(IconContents.care)
			setCurrentId(IconContents.care.uuid)
		} else if (value === 'Fitness') {
			setIconDescription(IconContents.fitness)
			setCurrentId(IconContents.fitness.uuid)
		} else if (value === 'Wellness') {
			setIconDescription(IconContents.mind)
			setCurrentId(IconContents.mind.uuid)
		} else if (value === 'Sports') {
			setIconDescription(IconContents.sports)
			setCurrentId(IconContents.sports.uuid)
		} else if (value === 'spa') {
			setIconDescription(IconContents.spa)
			setCurrentId(IconContents.spa.uuid)
		}
	}
	const updateTenant = () => {
		const body = {
			tentGroupUuid: currentId,
			tentUserUuid: uuid,
			isAddress: false,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		accountDetails.accountPostDetail(decryptKey?.plainText, decryptKey?.publicKey, uuid).then(onSuccess, onFailure)
	}
	const handleServiceSelection = () => {
		dispatch(showLoader('Loading please wait...'))
		let data = {
			isGenerateEmail: true,
			mastTentGroupUuid: currentId,
			userName: userEmail,
			userType: userType,
			uuid: identityUuid,
			isWelcomeMail: false,
			isForgotPassword: false,
		}
		let decryptKey = encryption(data)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				dispatch(setVerifyModal({ open: true, data: userEmail, mode: 'userVerification' }))
				updateTenant()
			} else if (successData.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		generateEmailToken.setEmail(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	return (
		<>
			{!_.isEmpty(state) && (
				<div className='w-full h-full flex items-center justify-center'>
					<div className={classes.groupmain}>
						<div className={classes.welcomeleft}>
							<div className={classes.welcomeleftone}>
								<Typography variant='h5' className={classes.heading}>
									{title}
								</Typography>
								<Typography variant='h6' className={classes.subcontent}>
									{description}
								</Typography>
							</div>
							<div className={classes.welcomelefttwo}>
								<Button style={{ width: '120px' }} onClick={handleServiceSelection} disabled={selectedValue ? false : true}>
									Continue
								</Button>
							</div>
						</div>
						<Grid container xs={12} className={classes.groupmaincard}>
							{_.map(state, (item) => {
								return (
									item?.mastTentGroupStatus && (
										<Grid
											item
											xs={4}
											className={classes.cardone}
											style={{ backgroundImage: `url(${item?.webImageUrl})` }}
											onClick={() => handleRadio(item?.mastTentGroupName)}
											value={item?.mastTentGroupName}
										>
											<Radio
												checked={selectedValue === item?.mastTentGroupName}
												onChange={() => handleChange(item?.mastTentGroupName)}
												value={item?.mastTentGroupName}
												name='radiobutton'
												id='radiobutton'
												className={classes.check}
											/>
											<Typography
												className={item?.mastTentGroupName !== 'spa and wellness' ? classes.caretext : classes.spatext}
												justifyContent='center'
												align='justify'
												textAlign='center'
											>
												{item?.mastTentGroupName}
											</Typography>
										</Grid>
									)
								)
							})}
							<Typography variant='subtitle1' className={classes.welcomerightcontent}>
								Select any of the services that you would like to provide to the customers.
							</Typography>
						</Grid>
					</div>
				</div>
			)}
			<EmailVerificationModal history={history} />
		</>
	)
}
export default GroupSelectionModule
