import React, { useEffect, useState } from 'react'
import ButtonComp from '../../../sharedComponents/Button'
import { makeStyles } from '@mui/styles'
import { Autocomplete, Button, Checkbox, Chip, Divider, Grid, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CalendarTimingApi from 'services/Settings/Calendar/index'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { hideLoader, showLoader } from 'services/loader/action'

const useStyles = makeStyles((theme) => ({
	root: {},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		gap: 16,
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	daysContainer: {
		border: '1px solid #72727240',
		borderRadius: 4,
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
	},
	loadingContainer: {
		height: 300,
		width: '100%',
	},
	specialityType: {
		width: 240,
	},
	slotItemRow: {
		padding: 6,
		width: '100%',
		display: 'flex',
		gap: 20,
	},
	helperText: {
		fontSize: 14,
		marginTop: 28,
	},
	ButtonField: {
		display: 'flex',
		gap: 8,
		paddingTop: 5,
		'& .MuiButton-root': {
			color: '#000',
			borderRadius: '2px',
			border: '1px solid gray',
			cursor: 'normal',
			'&.Mui-disabled': {
				backgroundColor: '#fff',
			},

			'&:hover': {
				color: '#000',
				border: '1px solid gray',
				backgroundColor: '#fff',
			},

			'&.active': {
				backgroundColor: '#000',
				border: '1px solid gray',
				color: '#fff',
				'&:hover': {
					backgroundColor: '#000',
					border: '1px solid gray',
					color: '#fff',
				},
			},
		},
	},
	chip: {
		marginRight: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
}))

function ConsultationTimingNoncare({ moveNextTab, moveBackTab }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { mastuuid } = useSelector((states) => states.Authentication)
	const groupId = useSelector((state) => state.dynamicMenu?.userData?.groupId)
	const [specialityList, setSpecialityList] = useState([])
	const [loading, setLoading] = useState(false)
	const days = [
		{
			day: 'Sunday',
			label: 'Sun',
			isSelected: false,
		},
		{
			day: 'Monday',
			label: 'Mon',
			isSelected: false,
		},
		{
			day: 'Tuesday',
			label: 'Tue',
			isSelected: false,
		},
		{
			day: 'Wednesday',
			label: 'Wed',
			isSelected: false,
		},
		{
			day: 'Thursday',
			label: 'Thu',
			isSelected: false,
		},
		{
			day: 'Friday',
			label: 'Fri',
			isSelected: false,
		},
		{
			day: 'Saturday',
			label: 'Sat',
			isSelected: false,
		},
	]

	useEffect(() => {
		setLoading(true)
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				let tempArr = []
				_.map(res?.data?.data, (item, idx) => {
					tempArr.push({
						paymentRequired: item?.isPaymentMandatory || false,
						specialityName: item?.specialityName || '-',
						timings: item?.timings,
						edit: item,
					})
				})
				let convertedData = _.map(tempArr, (data, i) => ({ ...data, id: i + 1 }))
				setSpecialityList(convertedData)
			}
			dispatch(hideLoader())
			setLoading(false)
		}
		const onFailure = (err) => {
			setSpecialityList([])
			setLoading(false)
			dispatch(hideLoader())
		}
		CalendarTimingApi.GetSpecialityBasedFilter(mastuuid, groupId).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, groupId])

	return (
		<>
			<div className={classes.root}>
				{loading ? (
					<div className={classes.loadingContainer}></div>
				) : (
					<>
						{!_.isEmpty(specialityList) &&
							specialityList?.map((item) => (
								<Grid container xs={12} spacing={0.2} sx={{ justifyContent: 'space-between', marginBottom: 8 }}>
									<Grid item xs={2.8}>
										<div className={classes.daysContainer}>
											<ListItem role='listitem'>
												<ListItemText primary='Schedule for' />
												<ListItemIcon>
													<Checkbox
														checked={(item?.timings[item?.timings?.length - 1]?.dayOn).length === 7}
														disabled
														color='default'
														icon={<CancelIcon fontSize='medium' />}
														checkedIcon={<CheckCircleIcon fontSize='medium' />}
													/>
												</ListItemIcon>
											</ListItem>
											<Divider />
											{_.map(days, (slot, index) => (
												<ListItem role='listitem'>
													<ListItemText primary={slot.day} />
													<ListItemIcon>
														<Checkbox
															value={slot.day}
															disabled
															checked={(item?.timings[item?.timings?.length - 1]?.dayOn).includes(slot.label)}
															color='default'
															icon={<CancelIcon fontSize='medium' />}
															checkedIcon={<CheckCircleIcon fontSize='medium' />}
														/>
													</ListItemIcon>
												</ListItem>
											))}
										</div>
									</Grid>

									<Grid item xs={9}>
										<Grid container xs={12}>
											<Grid item xs={12}>
												<Autocomplete
													className={classes.specialityType}
													disabled
													options={[]}
													value={item?.specialityName || ''}
													renderInput={(params) => (
														<TextField
															{...params}
															color='secondary'
															size='small'
															label='Speciality type *'
															inputProps={{
																...params.inputProps,
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography className='my-6'>Slots:</Typography>
											</Grid>
											<Grid item xs={12} sx={{}}>
												{item?.timings?.map((slot) => (
													<Grid item xs={12} style={{ width: '100%', marginBottom: 10 }}>
														<Grid item xs={12} className={classes.slotItemRow}>
															<div>
																<Typography>Starts at *</Typography>
																<TextField
																	color='secondary'
																	size='small'
																	sx={{ width: 120 }}
																	disabled
																	value={moment(slot?.sessionStartTime, 'HH:mm').format('LT')}
																/>
															</div>
															<Typography className={classes.helperText}>to</Typography>
															<div>
																<Typography>Ends at *</Typography>
																<TextField
																	color='secondary'
																	size='small'
																	sx={{ width: 120 }}
																	disabled
																	value={moment(slot?.sessionEndTime, 'HH:mm').format('LT')}
																/>
															</div>
															<Typography className={classes.helperText}>at</Typography>
															<div>
																<Typography>Price *</Typography>
																<TextField color='secondary' size='small' sx={{ width: 120 }} disabled value={slot?.consultantionFees} />
															</div>
															<Typography className={classes.helperText}>::</Typography>
															<div>
																<Typography>Space *</Typography>
																<TextField color='secondary' size='small' sx={{ width: 100 }} disabled value={`x ${slot?.venueSpace || ''}`} />
															</div>
															<Typography className={classes.helperText}>::</Typography>
															<div>
																<Typography>Hours *</Typography>
																<TextField
																	color='secondary'
																	size='small'
																	sx={{ width: 120 }}
																	disabled
																	value={`${slot?.consultantionDuration} Mins`}
																/>
															</div>
														</Grid>
														<Grid item xs={12} sx={{ paddingInlineStart: 1 }}>
															Payment
															<div className={classes.ButtonField}>
																<Button className={slot?.isPaymentMandatory ? 'active' : ''} disabled variant='contained' size='small'>
																	Yes
																</Button>
																<Button className={!slot?.isPaymentMandatory ? 'active' : ''} disabled variant='contained' size='small'>
																	No
																</Button>
															</div>
														</Grid>
														<Grid item xs={12} sx={{ paddingInlineStart: 1 }}>
															{slot?.dayOn?.map((day) => (
																<Chip label={_.capitalize(day || '')} variant='outlined' size='small' className={classes.chip} />
															))}
														</Grid>
													</Grid>
												))}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							))}
					</>
				)}
			</div>

			<div className={classes.buttonMain}>
				<ButtonComp onClick={moveBackTab}>Back</ButtonComp>
				<ButtonComp onClick={moveNextTab}>Next</ButtonComp>
			</div>
		</>
	)
}

export default ConsultationTimingNoncare
