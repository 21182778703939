import React from 'react'
import { makeStyles } from '@mui/styles'
import { Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	inputBaseColor: {
		'& .MuiOutlinedInput-root': {
			backgroundColor: '#fff',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: (props) => (props?.searchBorder ? '1px solid #c4c4c4' : 'none'),
			'& :hover': {
				border: (props) => (props?.searchBorder ? '1px solid #c4c4c4' : 'none'),
			},
		},
	},
}))

export default function OutlineFilter(props) {
	const { value, searchBorder, onChange, placeholder } = props
	const classes = useStyles(props)
	return (
		<TextField
			name='searchOptions'
			id='searchOptions'
			className={classes.inputBaseColor}
			fullWidth
			size='small'
			color='secondary'
			value={value}
			onChange={onChange}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<Search style={{ color: '#0062DD' }} />
					</InputAdornment>
				),
			}}
			placeholder={placeholder}
		/>
	)
}
