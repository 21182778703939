import React from 'react'
import FuncComponent from '../FuncComponent'

export default class ComponentToPrint extends React.Component {
	render() {
		return (
			<FuncComponent
				report={this.props.report}
				pageData={this.props.pageData}
				printColoumn={this.props.printColoumn}
				printRow={this.props.printRow}
				dateForPrint={this.props.dateForPrint}
				appliedFiltersForprint={this.props.appliedFiltersForprint}
				practiceName={this.props.practiceName}
				isCare={this.props.isCare}
				selectedFilters={this.props.selectedFilters}
			/>
		)
	}
}
