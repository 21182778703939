import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 16,
		color: themesConfig.lyfngo.palette.light.main,
		letterSpacing: 0.6,
	},
}))

export default function ModuleTitle({ children }) {
	const classes = useStyles()
	return (
		<Typography variant='h6' className={classes.title}>
			{children || ''}
		</Typography>
	)
}
