import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	filterBackground: {
		background: (props) => (props?.isOpen ? themesConfig.lyfngo.palette.tertiary.main : themesConfig.lyfngo.palette.light.main),
		borderRadius: 6,
		minWidth: 34,
		minHeight: 34,
		display: 'block',
		padding: '4px 0px',
		'&:hover': {
			background: (props) => (props?.isOpen ? themesConfig.lyfngo.palette.tertiary.main : themesConfig.lyfngo.palette.light.main),
		},
		'& .MuiSvgIcon-root': {
			fontSize: 24,
			color: (props) => (props?.isOpen ? themesConfig.lyfngo.palette.light.main : `${themesConfig.lyfngo.palette.tertiary.main} !important`),
			height: 24,
		},
	},
}))

export default function OutlineFilter({ children, onClick = () => {}, disabled, isOpen }) {
	const classes = useStyles({ isOpen })
	return (
		<Button variant='contained' className={classes.filterBackground} disabled={disabled} onClick={onClick}>
			{children}
		</Button>
	)
}
