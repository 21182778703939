const settingsConfig = {
	layout: {
		style: 'layout1',
		config: {},
	},
	customScrollbars: true,
	direction: 'ltr',
	theme: {
		main: 'default',
		navbar: 'greyDark',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeDark',
	},
}

export default settingsConfig
