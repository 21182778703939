import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	timingpaymentsMain: {
		paddingInline: 40,
		paddingBlock: 18,
		[theme.breakpoints.down('sm')]: {
			paddingInline: 26,
			paddingBlock: 0,
			gap: 4,
		},
	},
	star: {
		color: '#2A2A2A',
		fontWeight: 500,
	},
	timingpaymentsMainOne: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	editButton: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('lg')]: {
			display: 'none',
		},
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
	},
	editButtonMob: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.down('lg')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'space-between',
	},
}))
export default useStyles
