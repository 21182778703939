import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	RegistrationViewEditMain: {
		paddingInline: 36,
		paddingBlock: 18,
	},
	coinsilRegistartion: {
		fontSize: 12,
	},
	coinsilRegistartionNumber: {
		fontSize: 14,
		color: '#2A2A2A',
		fontWeight: 500,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	documentText: {
		fontSize: 14,
		color: '#2A2A2A',
		paddingInline: 0,
		[theme.breakpoints.down('sm')]: {
			paddingInline: 0,
			fontSize: 12,
		},
	},
	documentViewPart: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
	editPartMain: {
		paddingBlock: 16,
		[theme.breakpoints.down('sm')]: {
			paddingBlock: 4,
			gap: 18,
		},
	},
	uploadedImages: {
		width: '140px',
		height: '120px',
		borderRadius: 12,
		[theme.breakpoints.down('sm')]: {
			width: '116px',
		},
	},
	viewDocumentPart: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
	},
	documentsList: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			gap: 12,
			alignItems: 'center',
			flexDirection: 'row',
			paddingBlock: 6,
		},
		[theme.breakpoints.up('sm')]: {
			paddingBlock: 4,
		},
	},
	editButton: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.up('md')]: {
			display: 'block',
		},
	},
	editButtonMob: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	registerViewEdit: {
		[theme.breakpoints.down('sm')]: {
			gap: 8,
		},
		[theme.breakpoints.down('md')]: {
			gap: 8,
		},
	},
}))
export default useStyles
