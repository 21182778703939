import { ACTION_TYPES } from '../../../constants'
import secureLocalStorage from 'react-secure-storage'

const initialState = {
	subscription: false,
	buyNow: false,
	tentUuid: '',
}
const SubscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_SUBSCRIPTION: {
			secureLocalStorage.setItem('subscription', action.payload)
			return {
				...state,
				subscription: action.payload,
			}
		}
		case ACTION_TYPES.SET_BUY_NOW: {
			secureLocalStorage.setItem('buyNow', action.payload)
			return {
				...state,
				buyNow: action.payload,
			}
		}
		case ACTION_TYPES.SUBSCRIPTION_TENTUSER_UUID: {
			return {
				...state,
				tentUuid: action.payload,
			}
		}
		default:
			return state
	}
}

export default SubscriptionReducer
