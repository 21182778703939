import React from 'react'
import { makeStyles } from '@mui/styles'
import { Button, ButtonGroup, Typography } from '@mui/material'
import TotalSalesChart from './TotalSalesChart'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		padding: 14,
		borderRadius: 10,
		width: '100%',
	},
	secHead: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	grouped_button: {
		borderRadius: 18,
		height: 'fit-content',
		background: '#F8F7F7',

		'& .MuiButton-outlinedPrimary': {
			paddingInline: 20,
			border: '0px',
			height: 30,
		},
		'& .Mui-disabled': {
			background: '#fff',
			color: '#000',
		},
	},
	button: {
		width: 126,
		color: '#727272',
		'&:hover': {
			color: '#727272',
		},
	},
	button_active1: {
		width: 126,
		backgroundColor: '#2EB2FF',
		color: '#FFF',
		'&:hover': {
			backgroundColor: '#2EB2FF',
			color: '#FFF',
		},
	},
	button_active2: {
		width: 126,
		backgroundColor: '#4A9AFF',
		color: '#FFF',
		'&:hover': {
			backgroundColor: '#4A9AFF',
			color: '#FFF',
		},
	},
	button_active3: {
		width: 126,
		backgroundColor: '#8CC63F',
		color: '#FFF',
		'&:hover': {
			backgroundColor: '#8CC63F',
			color: '#FFF',
		},
	},
}))

function TotalSales({ dashboardData, donutChartVisiblity, saleType, setSaleType, isCareFlow }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.secHead}>
				<Typography variant='h6'>Total sales</Typography>
				<ButtonGroup color='primary' aria-label='medium' className={classes.grouped_button}>
					<Button
						className={_.isEqual(saleType, 'all') ? classes.button_active1 : classes.button}
						style={{ borderRadius: 18, transition: '0.7s all' }}
						onClick={() => setSaleType('all')}
					>
						All
					</Button>
					<Button
						className={_.isEqual(saleType, 'appointments') ? classes.button_active2 : classes.button}
						style={{ borderRadius: 18, transition: '0.7s all' }}
						onClick={() => setSaleType('appointments')}
					>
						Appointments
					</Button>
					<Button
						className={_.isEqual(saleType, 'otherSales') ? classes.button_active3 : classes.button}
						style={{ borderRadius: 18, transition: '0.7s all' }}
						onClick={() => setSaleType('otherSales')}
					>
						Other Sales
					</Button>
				</ButtonGroup>
			</div>
			<TotalSalesChart dashboardData={dashboardData} saleType={saleType} isCareFlow={isCareFlow} />
		</div>
	)
}

export default TotalSales
