import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	slotsMainSection: {
		'& .MuiTab-root': {
			border: '1px solid #C4C4C4',
			borderRadius: 30,
			minHeight: 36,
			minWidth: 60,
			padding: '8px 16px',
			[theme.breakpoints.down('sm')]: {
				minHeight: 32,
				minWidth: 34,
			},
		},
		'& .Mui-selected': {
			color: '#FFFFFF',
			background: '#414A58',
			borderRadius: 30,
		},
		'& .MuiTabs-flexContainer': {
			gap: 20,
			[theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap',
				gap: 10,
			},
		},
		'& .MuiTabs-indicator': {
			display: 'none',
		},
		'& .MuiBox-root': {
			padding: 4,
			[theme.breakpoints.down('sm')]: {
				padding: 4,
			},
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'space-between',
	},
}))
export default useStyles
