/* eslint-disable prettier/prettier */
import { Redirect } from 'react-router-dom'
import FuseUtils from '@fuse/utils'
import appsConfigs from 'app/main/apps/appsConfigs'
import FuseLoading from '@fuse/core/FuseLoading'
import Error404Page from 'app/main/404'
import mainConfigs from 'app/main/MainPagesConfig'
import pagesConfig from 'app/main/pages/pagesConfig'
import DashBoard from 'app/main/apps/dashboard'
import Consultation from 'app/main/apps/Consultation'
import Login from 'app/main/login/Login'
const routeConfigs = [...appsConfigs, ...mainConfigs, ...pagesConfig]

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/example',
		component: () => <Redirect to='/example' />,
	},
	{
		path: '/dashboard',
		exact: true,
		component: () => <DashBoard />,
	},
	{
		path: '*',
		exact: true,
		component: () => <Error404Page />,
	},
	{
		path: '/',
		exact: true,
		component: () => <Redirect to='/' />,
	},
	{
		path: '/loading',
		exact: true,
		component: () => <FuseLoading />,
	},
	{
		path: '/consult',
		exact: true,
		// type: 'guest',
		component: () => <Consultation />,
	},
	{
		path: '/login',
		exact: true,
		component: () => <Login />,
	},
]
export default routes
