import { ACTION_TYPES } from '../../../constants'

export const isAccountWalkthroughCompleted = (payload) => {
	return {
		type: ACTION_TYPES.IS_ACCOUNT_COMPLETED,
		payload,
	}
}
export const isUserManagemnetWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_USERMANAGEMNET_COMPLETED,
	payload,
})
export const isSettingCalendarWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_SETTING_CALENDAR_COMPLETED,
	payload,
})
export const isPricingWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_PRICE_COMPLETED,
	payload,
})
export const isDrugWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_DRUG_COMPLETED,
	payload,
})
export const isClinicalWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_CLINICAL_COMPLETED,
	payload,
})
export const isBillingWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_BILLING_COMPLETED,
	payload,
})
export const isCalendarWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_CALENDAR_COMPLETED,
	payload,
})
export const isPatientMainWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_PATIENT_MAIN_COMPLETED,
	payload,
})
export const isPatientCardWalkthroughCompleted = (payload) => ({
	type: ACTION_TYPES.IS_PATIENT_CARD_COMPLETED,
	payload,
})

export const updateUserSteps = (payload) => ({
	type: ACTION_TYPES.UPDATE_USER_MANAGEMENT_STEPS,
	payload,
})
export const updateAccountSteps = (payload) => ({
	type: ACTION_TYPES.UPDATE_ACCOUNT_STEPS,
	payload,
})
export const updateSettingCalendarSteps = (payload) => ({
	type: ACTION_TYPES.UPDATE_SETTING_CALENDAR_STEPS,
	payload,
})
export const walkthroughSkipped = () => ({
	type: ACTION_TYPES.IS_WALKTHROUGH_SKIPPED,
})
