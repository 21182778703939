import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		paddingBlock: 50,
		paddingInline: 16,
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			paddingBlock: 28,
			paddingInline: 6,
			width: '100%',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			paddingBlock: 0,
			paddingInline: 6,
			width: '100%',
		},
	},
	containBox: {
		display: 'flex',
	},
	containMain: {
		borderRadius: '21px 0px 0px 21px',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		opacity: 1,
		display: 'flex',
		height: '538px',
		padding: 24,
		[theme.breakpoints.down('xs')]: {
			height: 'auto',
			padding: 4,
			borderRadius: '21px 21px 0px 0px  ',
			justifyContent: 'center',
		},
		[theme.breakpoints.down('md')]: {
			padding: 4,
			height: 'auto',
			borderRadius: '21px 21px 0px 0px ',
			justifyContent: 'center',
		},
	},
	containMain2: {
		background: 'transparent linear-gradient(271deg, #E9E8E9 0%, #F5F4F6 100%) 0% 0% no-repeat padding-box',
		borderRadius: '0px 21px 21px 0px',
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		height: '538px',
		width: '788px',
		// padding: 130,
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			background: '#fff',
			width: 'auto',
			borderRadius: '0px 0px 21px 21px ',
			height: 'auto',
			padding: 4,
		},
		[theme.breakpoints.down('md')]: {
			background: '#fff',
			height: 'auto',
			width: 'auto',
			padding: 4,
			borderRadius: '0px 0px 21px 21px ',
		},
	},
	contentList: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		padding: 20,
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiTypography-root': {
			fontSize: 20,
			color: themesConfig.greyDark.palette.primary.main,
			fontWeight: 600,
		},
		[theme.breakpoints.down('xs')]: {
			paddingInline: 0,
			paddingBlock: 10,
		},
		[theme.breakpoints.down('md')]: {
			paddingBlock: 10,
			paddingInline: 0,
		},
	},
	contenImage: {
		display: 'flex',
		textAlign: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 6,
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'start',
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'start',
			width: 'auto',
		},
	},
	containBox2: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr 1fr',
		gap: 4,
		height: '65vh',
		overflowY: 'auto',
		alignItems: 'start',
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '1fr ',
			justifyContent: 'center',
			gap: 12,
		},
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '1fr 1fr',
			justifyContent: 'center',
			gap: 12,
		},
	},
	background: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		width: 120,
		height: 120,
		backgroundRepeat: 'no-repeat',
		cursor: 'pointer',
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		boxShadow: '0px 0px 6px #0000000F',
		border: '1px solid #66666626',
		borderRadius: 13,
		opacity: 1,
		background: '#fff',
		backdropFilter: 'blur(50px)',
		'-webkit-backdrop-filter': 'blur(50px)',
		[theme.breakpoints.down('xs')]: {
			width: 134,
			height: 134,
		},
		[theme.breakpoints.down('md')]: {
			width: 134,
			height: 134,
		},
	},
	check: {
		float: 'right',
		top: '0',
		'& .MuiSvgIcon-root': {
			fill: 'green',
			borderRadius: '30px',
		},
		'&. makeStyles-check-193 ': {
			fill: 'green',
		},
	},
	contentHead: {
		paddingInline: 28,
	},
	contentHead1: {
		paddingInline: 46,
	},
	btnCont: {
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	btnMobile: {
		display: 'none',
		'& :hover': {
			backgroundColor: '#1b2330',
		},

		[theme.breakpoints.down('xs')]: {
			display: 'block',
			margin: 'auto',
			marginBlock: 12,
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
			margin: 'auto',
			marginBlock: 12,
		},
	},
	checkList: {
		'& .muiltr-zgjvrg-MuiSvgIcon-root': {
			fontSize: 24,
			'& .muiltr-zgjvrg-MuiSvgIcon-root': {
				color: 'green',
			},
		},
	},
	backButton: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
			color: '#fff',
		},
	},
}))

export default useStyles
