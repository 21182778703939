import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStyles from './style'
import { useState, useCallback } from 'react'
import { Divider, Grid, IconButton, Tooltip } from '@mui/material'
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms'
import ArticleIcon from '@mui/icons-material/Article'
import _ from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { showPrompt, closePrompt } from '../../../../../../../src/services/Prompt/actions'
import { getResponseMessage } from '../../../../../../../src/utils'
import Prompt from '../../../../../sharedComponents/Prompt'
import EditReminder from '../../Modal/EditReminder/index'
import EditArticle from '../../Modal/EditArticle/index'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import PlanReminder from '../../../../../../services/Communication/Reminder/api'
import PlanArticle from '../../../../../../services/Communication/Article/api'
import { usePermissions } from 'hooks/usePermissions'
import { ROLE_MODULES, PERMISSION_TYPE, FEATURES_LIST } from '../../../../../../constants'

export default function DetailsAccordion({
	reminderList,
	articleList,
	planDetails,
	setPlanDetails,
	getPlanIndividualDetails,
	match,
	count,
	setCount,
	onArticleAddSuccess,
	onReminderUpdateSuccess,
	planUuid,
}) {
	const [expanded, setExpanded] = React.useState(false)
	const classes = useStyles()
	const dispatch = useDispatch()
	const [open5, setOpen5] = useState(false)
	const [open6, setOpen6] = useState(false)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const communicationReminderPerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.REMINDER)
	const communicationArticlePerms = usePermissions(ROLE_MODULES.COMMUNICATION, FEATURES_LIST.ARTICLE)

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	const editReminder = (data) => {
		getPlanList(data)
	}

	const editArticle = (data) => {
		getArticleList(data)
	}

	//Start : GET Reminder details //
	const [reminderData, setReminderData] = useState()

	const getPlanList = useCallback(
		(planReminderUuid) => {
			const onSuccess = (res) => {
				dispatch(hideLoader())
				if (res?.data?.status === 'success') {
					setReminderData(res?.data?.data)
					setOpen5(true)
				}
			}
			const onFailure = () => {
				dispatch(hideLoader())
				setReminderData([])
			}
			dispatch(showLoader('Loading reminder please wait..'))
			PlanReminder.getReminderDetails(planReminderUuid).then(onSuccess, onFailure)
		},
		[dispatch]
	)

	//End : GET Reminder details  //
	//End : Delete Reminder
	const onClickDelteReminder = (uuid) => {
		dispatch(showLoader('Deleting the Reminder please wait...'))
		const data = {
			uuid: uuid,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(closePrompt())
			if (res?.data?.status === 'success') {
				getPlanIndividualDetails(planUuid)
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res.data.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		PlanReminder.reminderDelete(data).then(onSuccess, onFailure)
	}
	//End : Delete reminder
	//Start : GET Article Details
	const [articleData, setArticleData] = useState()
	const getArticleList = useCallback(
		(articleUuid) => {
			dispatch(showLoader('Loading article please wait...'))
			const onSuccess = (res) => {
				dispatch(hideLoader())
				if (res?.data?.status === 'success') {
					setArticleData(res?.data?.data)
					setOpen6(true)
				}
			}
			const onFailure = () => {
				dispatch(hideLoader())
				setArticleData([])
			}
			PlanArticle.getArticleDetails(articleUuid).then(onSuccess, onFailure)
		},
		[dispatch]
	)
	//End : GET Article Details
	//End : Delete Article
	const onClickDelteAricle = (uuid) => {
		dispatch(showLoader('Deleting the article please wait...'))
		const data = {
			uuid: uuid,
		}
		const onSuccess = (res) => {
			dispatch(hideLoader())
			dispatch(closePrompt())
			if (res?.data?.status === 'success') {
				getPlanIndividualDetails(planUuid)
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			} else if (res.data.status === 'failure') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		PlanArticle.articleDelete(data).then(onSuccess, onFailure)
	}
	//End : Delete Article

	return (
		<div className={classes.accordionMain}>
			{_.map(planDetails?.data || [], (data) => (
				<Accordion expanded={expanded === data.weekName} onChange={handleChange(data.weekName)}>
					<AccordionSummary
						className={classes.accordionSummary}
						expandIcon={<ExpandMoreIcon />}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography sx={{ width: '33%', flexShrink: 0 }} className={classes.weektext}>
							Week {data.weekName}
						</Typography>
					</AccordionSummary>
					<Divider className={classes.dividerColor} />
					<AccordionDetails>
						{_.map(data?.days, (day, idx) => (
							<Grid container className={classes.dayDetails} alignItems='center'>
								<Grid item lg={1} md={1} sm={12}>
									<Typography variant='h6' className={classes.dayText}>
										Day {day?.dayName || ''}
									</Typography>
								</Grid>
								<Grid item lg={11} md={11} sm={12} className={classes.scheduleWrapper}>
									{day?.dayData?.map((dataTwo) => (
										<Grid container justifyContent='space-between' alignItems='center'>
											<Grid
												item
												lg={7}
												md={7}
												sm={12}
												spacing={1}
												m={1}
												className={dataTwo.type === 'reminder' ? classes.weekDetails : classes.weekDetailsOne}
											>
												{dataTwo.type === 'reminder' ? <AccessAlarmsIcon /> : <ArticleIcon />}
												<Typography variant='h6' className={classes.timeText}>
													{dataTwo.type === 'article' ? dataTwo.time : null || dataTwo.time}
												</Typography>
												<Typography variant='h6' className={classes.tittleText}>
													{dataTwo.title}
												</Typography>
											</Grid>
											<Grid item lg={1.5} md={2} sm={12}>
												{(_.get(communicationReminderPerms, PERMISSION_TYPE.EDIT, false) ||
													_.get(communicationArticlePerms, PERMISSION_TYPE.EDIT, false)) && (
													<Tooltip title='Edit' arrow placement='top'>
														<IconButton>
															<EditIcon
																onClick={() => {
																	dataTwo.type === 'reminder' ? editReminder(dataTwo?.uuid) : editArticle(dataTwo.uuid)
																}}
															/>
														</IconButton>
													</Tooltip>
												)}
												{(_.get(communicationReminderPerms, PERMISSION_TYPE.DELETE, false) ||
													_.get(communicationArticlePerms, PERMISSION_TYPE.DELETE, false)) && (
													<Tooltip title='delete' arrow placement='top'>
														<IconButton>
															<DeleteIcon
																onClick={() =>
																	dispatch(
																		showPrompt(
																			'Are you sure want to delete?',
																			'Yes',
																			'No',
																			() => (dataTwo.type === 'reminder' ? onClickDelteReminder(dataTwo.uuid) : onClickDelteAricle(dataTwo?.uuid)),
																			() => dispatch(closePrompt())
																		)
																	)
																}
															/>
														</IconButton>
													</Tooltip>
												)}
											</Grid>
										</Grid>
									))}
								</Grid>
							</Grid>
						))}
					</AccordionDetails>
				</Accordion>
			))}
			<Prompt />
			{open5 && (
				<EditReminder
					match={match}
					count={count}
					setCount={setCount}
					reminderData={reminderData}
					onReminderUpdateSuccess={onReminderUpdateSuccess}
					open5={open5}
					handleClose5={() => {
						setOpen5(false)
					}}
				/>
			)}
			{open6 && (
				<EditArticle
					match={match}
					open6={open6}
					articleData={articleData}
					onArticleAddSuccess={onArticleAddSuccess}
					handleClose6={() => {
						setOpen6(false)
					}}
				/>
			)}
		</div>
	)
}
