import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	mainRoot: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		paddingBlock: 4,
	},
	autoComplete: {
		display: 'grid',
		gap: 24,
		alignItems: 'center',
		gridTemplateColumns: '1fr 0.4fr 1fr',
		maxWidth: 1000,
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '3fr 0.3fr 0.01fr',
		},
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '3fr 0.3fr 0.01fr',
		},
	},
	autoCompleteTwo: {
		display: 'grid',
		gap: 24,
		alignItems: 'center',
		gridTemplateColumns: '1fr 0.4fr 1fr',
		maxWidth: 1000,
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: '2fr  0.01fr',
		},
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '2fr  0.01fr',
		},
	},
	listAuto: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
	iconList: {
		'& .MuiSvgIcon-root': {
			fontSize: 26,
			position: 'absolute',
			color: 'red',
			cursor: 'pointer',
		},
	},
	contentPage: {
		display: 'flex',
		gap: 24,
		paddingBottom: 20,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'right',

		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			gap: 4,
			alignItems: 'flex-start',
		},
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			gap: 8,
		},
	},
	mainButtonList: {
		display: 'flex',
		justifyContent: 'end',
		padding: '24px 12px',
		gap: 12,
		bottom: 0,
		position: 'sticky',
		background: '#fff',
	},
	addExperiencebtn: {},
	addbutton: {
		'&. MuiButton-root': {
			background: themesConfig.lyfngo.palette.primary.main,
		},
		[theme.breakpoints.down('md')]: {
			alignItems: 'left',
			textAlign: 'left',
		},
		[theme.breakpoints.down('sm')]: {
			alignItems: 'left',
			textAlign: 'left',
		},
	},
	cancelExperience: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: 26,
			position: 'absolute',
			color: 'red',
			cursor: 'pointer',
		},
	},
	mainField: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
	imageResponse: {
		width: 155,
		height: 146,
		borderRadius: 16,
	},
	cancelBtn: {
		color: 'red',
		paddingLeft: 0,
	},
}))
export default useStyles
