import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBlock: 12,
	},
	timingpaymentsMain: {
		// paddingInline: 40,
		// paddingBlock: 18,
		[theme.breakpoints.down('sm')]: {
			paddingInline: 26,
			paddingBlock: 0,
			gap: 4,
		},
	},
	star: {
		color: '#2A2A2A',
		fontWeight: 500,
	},

	timingpaymentsMainOne: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	editButton: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('lg')]: {
			display: 'none',
		},
		[theme.breakpoints.up('lg')]: {
			display: 'block',
		},
	},
	editButtonMob: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.down('lg')]: {
			display: 'block',
			paddingBlock: 12,
		},
		[theme.breakpoints.up('lg')]: {
			display: 'none',
		},
	},
	buttonMain: {
		display: 'flex',
		position: 'sticky',
		bottom: 0,
		padding: '30px 0',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'space-between',
	},
	ButtonField: {
		display: 'flex',
		gap: 8,

		'& .MuiButton-root': {
			color: '#000',
			borderRadius: '2px',
			border: '1px solid gray',
			'&.active': {
				backgroundColor: '#000',
				border: '1px solid gray',
				color: '#fff',
			},
		},
	},
	PaymentField: {
		display: 'flex',
		flexDirection: 'row',
		color: '#414A58',
		alignItems: 'center',
		// opacity: 0.5,
		gap: 12,
		flexWrap: 'wrap',
		margin: (isMobile) => (isMobile ? theme.spacing(1, 0) : 0),
	},
	titleSlot: {
		margin: theme.spacing(1, 0),
	},
	dayContain: {
		border: '1px solid #E9E8E9',
		borderRadius: 7,
		opacity: 1,
		'& .muiltr-cveggr-MuiListItemIcon-root': {
			alignItems: 'center',
		},
		'& .MuiListItemText-root': {
			// marginTop: 12,
		},
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItem-root': {
			paddingRight: 0,
			alignItems: 'center',
		},
	},
	dayContainErr: {
		border: '1px solid red',
		borderRadius: 7,
		opacity: 1,
		'& .muiltr-cveggr-MuiListItemIcon-root': {
			alignItems: 'center',
		},
		'& .MuiListItemText-root': {
			// marginTop: 12,
		},
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItem-root': {
			paddingRight: 0,
			alignItems: 'center',
		},
	},
	mainList: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		// paddingInlineStart: 12,
		cursor: 'pointer',
		'& .Mui-checked': {
			color: '#2DCD7A',
		},
		'& .MuiListItemIcon-root': {
			minWidth: 36,
		},
	},
	stickyDiv: {
		position: 'sticky',
		top: 0,
	},
	autocomplete: {
		marginTop: theme.spacing(1),
	},
	suggText: {
		textAlign: ({ isMobile }) => (isMobile ? 'left' : 'center'),
	},
	addExperiencebtn: {
		cursor: 'pointer',
		color: 'red',
		padding: 0,
		margin: theme.spacing(1, 0),
	},
	timeSlotsSection: {
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			marginTop: theme.spacing(1.5),
		},
	},
	slotHead: {
		margin: theme.spacing(1, 0),
	},
	addExperiencebtn1: {
		cursor: 'pointer',
		color: '#000000',
		opacity: 0.8,
	},
	errorColor: {
		color: 'red',
		fontSize: '1.1142857142857143rem',
	},
	sltHeader: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	chip: {
		marginRight: theme.spacing(0.5),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	gridAlign: {
		alignItems: 'center',
	},
	optionHead: {
		alignItems: 'center',
		paddingLeft: 16,
	},
}))
export default useStyles
