import { Close } from '@mui/icons-material'
import { Dialog, Divider, IconButton, Slide, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import themesConfig from 'app/fuse-configs/themesConfig'
import CopyIcon from 'app/sharedComponents/CopyIcon'
import { showMessage } from 'app/store/fuse/messageSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import secureLocalStorage from 'react-secure-storage'
import { setCurrentEventList } from 'services/EventManagement/storage/action'
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
	topBox: {
		display: 'flex',
		justifyContent: 'end',
	},
	mainContent: {
		textAlign: 'center',
		padding: 24,
	},
	typographyOne: {
		fontSize: 15,
		fontWeight: 600,
	},
	typographyTwo: { fontSize: 14, fontWeight: 500 },
	link: {
		fontSize: 13,
		color: '#0062DD',
		cursor: 'pointer',
		display: 'flex',
		'&:hover': {
			textDecoration: 'underline',
			textDecorationColor: '#0062DD',
		},
	},
	typographyThree: {
		fontSize: 12,
	},
	socialIconsContainer: {
		display: 'flex',
		gap: 8,
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 16,
	},
}))

const SuccessModal = ({ open, setOpen, history, currentEventUrl, allEvents, getAllEvent }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const modalClose = () => {
		setOpen(false)
		history.push('/events?tabName=all')
	}
	const handleCopyDomain = () => {
		dispatch(
			showMessage({
				message: 'Copied',
				autoHideDuration: 3000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				variant: 'success',
			})
		)
		navigator.clipboard.writeText(currentEventUrl)
	}
	useEffect(() => {
		if (open) {
			const handlePopState = (event) => {
				event.preventDefault()
				history.push('/events?tabName=all')
			}

			window.history.pushState(null, null, window.location.href)
			window.addEventListener('popstate', handlePopState)

			return () => {
				window.removeEventListener('popstate', handlePopState)
			}
		}
	}, [open, history])
	useEffect(() => {
		getAllEvent()
	}, [getAllEvent])

	return (
		<Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description' maxWidth='lg'>
			<Box className={classes.topBox}>
				<IconButton onClick={modalClose}>
					<Close />
				</IconButton>
			</Box>
			<Box className={classes.mainContent}>
				<Box mb={3} display={'flex'} justifyContent={'center'}>
					<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/Congratulation.svg`} alt='Congratulation' />
				</Box>
				<Typography variant='h5' mb={2} className={classes.typographyOne}>
					Congratulations, Your event is published!
				</Typography>
				<Divider />
				<Typography mt={3} variant='h6' className={classes.typographyTwo}>
					Your event microsite is live, celebrate at
				</Typography>
				<Box mt={3} display={'flex'} alignItems={'center'} gap={1}>
					<Typography variant='h6' className={classes.link} onClick={() => window.open(currentEventUrl)}>
						{currentEventUrl || ''}
					</Typography>
					<CopyIcon onClick={handleCopyDomain} />
				</Box>
				<Typography
					mt={2}
					variant='h6'
					className={classes.typographyTwo}
					sx={{ textDecoration: 'underline', cursor: 'pointer' }}
					onClick={() => {
						const matchingEvent = allEvents.find((event) => event.domainUrl === currentEventUrl)
						dispatch(setCurrentEventList(matchingEvent))
						secureLocalStorage.setItem('currentEvent', matchingEvent)
						history.push('/eventinfo?tabName=overview')
					}}
				>
					View Event Info
				</Typography>
				<Typography mt={2} variant='h6' className={classes.typographyTwo} sx={{ color: themesConfig.lyfngo.palette.primary.main }}>
					Promote your event and share the event link.
				</Typography>
				<Box className={classes.socialIconsContainer}>
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/facebook_icon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/x_icon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/instagramicon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/threads_icon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/youtube_icon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/linkedin_icon_microsite.svg`} alt='C' />
					<img className={classes.image} src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/whatsapp_icon_microsite.svg`} alt='C' />
				</Box>
			</Box>
		</Dialog>
	)
}

export default SuccessModal
