import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getDashBoardData: (mastuuid, calenderDate, selectedDateRange, upcommingAppointments) => {
		return axios.get(API_ENDPOINTS.GET_INDIVIDUAL_DASHBOARD_LIST, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				path: { mastuuid, calenderDate, selectedDateRange, upcommingAppointments },
			},
		})
	},
}
