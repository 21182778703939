import { ACTION_TYPES } from '../../../constants'

export const showPrompt = (promptText, successBtnText, cancelBtnText, successBtnCallback, cancelBtnCallback) => ({
	type: ACTION_TYPES.SHOW_PROMPT,
	data: {
		promptText,
		successBtnText,
		cancelBtnText,
		successBtnCallback,
		cancelBtnCallback,
	},
})
export const closePrompt = () => ({
	type: ACTION_TYPES.CLOSE_PROMPT,
})
