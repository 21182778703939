import themesConfig from 'app/fuse-configs/themesConfig'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		id: 0,
		title: (
			<div style={{ color: '#000', fontWeight: 500 }}>
				Welcome to <span style={{ color: themesConfig.greyDark.palette.primary.main, fontWeight: 600 }}>LYFnGO..!</span>
			</div>
		),
		content: 'Please update your personal information in my profile.',
		target: '.profileMenu',
		disableBeacon: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
		placement: 'top',
	},
	{
		id: 1,
		title: <div style={{ color: '#000', fontWeight: 500 }}>My profile</div>,
		content: <div style={{ fontSize: 14 }}>Update and save all your personal information</div>,
		target: '.myProfile',
		disableBeacon: true,
		spotlightClicks: true,
		options: {
			zIndex: 99999,
		},
	},
	{
		id: 2,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Add Employee</div>,
		content: <div style={{ fontSize: 14 }}>Add and notify all your employees here</div>,
		target: '.userManagement',
		disableBeacon: true,
		spotlightClicks: true,
		options: {
			zIndex: 99999,
		},
	},
	{
		id: 3,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Calendar settings</div>,
		content: <div style={{ fontSize: 14 }}>Maintain consultation timings and modes here</div>,
		target: '.calendarSettings',
		disableBeacon: true,
		spotlightClicks: true,
		hideNextutton: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
	},
	{
		id: 4,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Billing</div>,
		content: <div style={{ fontSize: 14 }}>Add your tax, payment and currency here</div>,
		target: '.billing',
		disableBeacon: true,
		spotlightClicks: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
	},
	{
		id: 5,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Price catalog</div>,
		content: <div style={{ fontSize: 14 }}>Add and update your price catalog here</div>,
		target: '.addPrice',
		disableBeacon: true,
		spotlightClicks: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
	},
	{
		id: 6,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Drug catalog</div>,
		content: <div style={{ fontSize: 14 }}>Add and search your drug catalog here</div>,
		target: '.addDrug',
		disableBeacon: true,
		spotlightClicks: true,
		options: {
			zIndex: 99999,
		},
		placement: 'bottom',
	},
	{
		id: 7,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Clinical Notes</div>,
		content: <div style={{ fontSize: 14 }}>Add and search your complaints, observation, diagnosis, notes here</div>,
		target: '.clinicalNotes',
		disableBeacon: true,
		spotlightClicks: true,
		options: {
			zIndex: 99999,
		},
	},
	{
		id: 8,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Patient</div>,
		content: <div style={{ fontSize: 14 }}>Click the first patient in the queue</div>,
		target: '.allPatientCard',
		disableBeacon: true,
		spotlightClicks: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
		placement: 'bottom-end',
	},
	{
		id: 9,
		title: <div style={{ color: '#000', fontWeight: 500 }}>Patients</div>,
		content: (
			<div style={{ fontSize: 14 }}>
				Book your timings
				<br />
				Maintain patient records
				<br />
				Add billing to the patients
			</div>
		),
		target: '.patientGroup',
		disableBeacon: true,
		spotlightClicks: true,
		styles: {
			options: {
				zIndex: 99999,
			},
		},
		placement: 'top-left',
	},
	// {
	// 	id: 8,
	// 	content: 'Add personal details of your patient here.',
	// 	target: '.addPatient',
	// 	disableBeacon: true,
	// 	spotlightClicks: true,
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 9,
	// 	target: '.addAppointment',
	// 	title: 'Add on Appointment',
	// 	content: (
	// 		<div>
	// 			Add an appointment for any patient listed in your patient list.
	// 			<br />
	// 			Click on the "Add Appointment"
	// 		</div>
	// 	),
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'left',
	// },
	// {
	// 	id: 10,
	// 	target: '.allPatientCard',
	// 	content: 'Select a patient from patient list to view and add appointment for the patient.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
	// {
	// 	id: 11,
	// 	target: '.addChartingAppointment',
	// 	title: 'Add on Appointment',
	// 	content: (
	// 		<div>
	// 			Add an appointment for any patient listed in your patient list.
	// 			<br />
	// 			Click on the "Add Appointment"
	// 		</div>
	// 	),
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 12,
	// 	target: '.addDoctor',
	// 	content: 'Please select a doctor from the dropdown to book an appointment.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 13,
	// 	target: '.addMode',
	// 	content: 'Please select the appointment mode',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 14,
	// 	target: '.addDate',
	// 	content: 'Please select the appointment date',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 15,
	// 	target: '.addTime',
	// 	content: 'Please select the appointment time',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 16,
	// 	target: '.SaveAppointment',
	// 	content: 'To save your appointment details, Click Done.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
	// {
	// 	id: 17,
	// 	target: '.vital',
	// 	content: 'you can add vital sign for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 18,
	// 	target: '.clinical',
	// 	content: 'you can add clinical notes for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 19,
	// 	target: '.prescription',
	// 	content: 'you can add prescription for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 20,
	// 	target: '.files',
	// 	content: 'you can add files for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 21,
	// 	target: '.labOrder',
	// 	content: 'you can add lab order for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 22,
	// 	target: '.treatmentPlan',
	// 	content: 'you can add treatment plan for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 23,
	// 	target: '.completedProcedure',
	// 	content: 'you can add completed procedure for your patient here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'right',
	// },
	// {
	// 	id: 24,
	// 	target: '.addBill',
	// 	content: 'Click here to add a bill for your patient.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
	// {
	// 	id: 25,
	// 	target: '.addBillSave',
	// 	content: 'After filling all the details click save otherwise click next.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
	// {
	// 	id: 26,
	// 	target: '.CloseCharting',
	// 	content: 'Click close to close the dialog. Otherwise please click the next to continue the tour',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
	// {
	// 	id: 27,
	// 	target: '.print',
	// 	content: 'Click here to take the print out',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 28,
	// 	target: '.reportFilter',
	// 	content: 'Click here to hide and unhide the filter.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 29,
	// 	target: '.resetFilter',
	// 	content: 'You can reset your filter here.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'bottom',
	// },
	// {
	// 	id: 30,
	// 	target: '.stats',
	// 	content: 'You can see the statistics according your filter.',
	// 	styles: {
	// 		options: {
	// 			zIndex: 99999,
	// 		},
	// 	},
	// 	spotlightClicks: true,
	// 	placement: 'top',
	// },
]

// {
// 	id: 17,
// 	target: '.patientName',
// 	content: 'Please click on "Add Reminder" to fill the details to add the reminder',
// 	spotlightClicks: true,
// 	styles: {
// 		tooltipFooter: {
// 			opacity: 0,
// 			display: 'none',
// 		},
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 18,
// 	target: '.addReminder',
// 	title: 'Add on Reminder',
// 	content: (
// 		<div>
// 			A new patient wants to remind the appointments. Let's add his reminder.
// 			<br />
// 			Click on the "Add Reminder"
// 		</div>
// 	),
// 	styles: {
// 		tooltipFooter: {
// 			opacity: 0,
// 			display: 'none',
// 		},
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// 	spotlightClicks: true,
// },
// {
// 	id: 19,
// 	target: '.blockCalendar',
// 	title: 'Block on Calendar',
// 	content: (
// 		<div>
// 			A new patient wants to block the calendar. Let's add block calendar.
// 			<br />
// 			Click on the "Block Calendar"
// 		</div>
// 	),
// 	styles: {
// 		tooltipFooter: {
// 			opacity: 0,
// 			display: 'none',
// 		},
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// 	spotlightClicks: true,
// },
// {
// 	id: 20,
// 	target: '.appointmentType',
// 	content: 'Please select the "All Type" to see the Total,Cancelled,Queued,Engaged,Missed and Waiting details',
// 	placement: 'left',
// 	spotlightClicks: true,
// },
// {
// 	id: 21,
// 	target: '.calendar',
// 	content: (
// 		<div>
// 			A new patient wants to add the appointments,reminders and block the calendar.
// 			<br />
// 			Click on "Any Day" in the calendar
// 		</div>
// 	),
// 	spotlightClicks: true,
// },
// {
// 	id: 22,
// 	target: '.totalBlock',
// 	content: 'System display the Total,Cancelled,Queued,Engaged,Missed and Waiting details',
// 	placement: 'left',
// 	spotlightClicks: true,
// },
// {
// 	id: 23,
// 	target: '.appointmentList',
// 	content: 'Please select any establishment to Edit and Cancel Appointments',
// 	spotlightClicks: true,
// },

// {
// 	id: 9,
// 	target: '.practice',
// 	content: (
// 		<div>
// 			Please select the Establishment name from dropdown list to display the Appointment and Reminder details.
// 			<br />
// 			Click in the "Dropdown List".
// 		</div>
// 	),
// 	isFixed: true,
// 	placement: 'left',
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },

// {
// 	id: 10,
// 	target: '.monthNav',
// 	content: 'Please select the month to see the Appointments and Reminders details',
// 	offset: 20,
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 11,
// 	target: '.dayNav',
// 	content: 'Please select the Today,Day,Week and Month to see the appointments and reminder details',
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 12,
// 	target: '.print',
// 	content: ' Please click the Print icon to print all the Appointments and Reminder details',
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 13,
// 	target: '.settings',
// 	content: (
// 		<div>
// 			Please click the Setting icon to Modify the calendar timings,view,add,edit and notification for patients.
// 			<br />
// 			Click in the 'Setting' Icon above
// 		</div>
// 	),
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 14,
// 	target: '.doctorDrop',
// 	content: (
// 		<div>
// 			Please select the user to see the Appointments and Reminders details.
// 			<br />
// 			Click in the 'Select User' dropdown above
// 		</div>
// 	),
// 	placement: 'left',
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
// {
// 	id: 15,
// 	target: '.categoryDrop',
// 	content: 'Please select the category to see the Appointments and Reminders details',
// 	styles: {
// 		options: {
// 			zIndex: 99999,
// 		},
// 	},
// },
