import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import history from '@history'
import {
	Autocomplete,
	ButtonBase,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Icon,
	IconButton,
	LinearProgress,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { useEffect, useState, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import _ from '@lodash'
import RegisterApi from '../../../../services/register/api'
import { Register, PhoneNumberLength, updateBearerToken } from '../../../session/actions'
import useStyles from './style'
import { showMessage } from 'app/store/fuse/messageSlice'
import classnames from 'classnames'
import { hideLoader, showLoader } from 'services/loader/action'
import { getResponseMessage } from 'utils'
import Button from '../../../sharedComponents/Button'
import { encryption, decryption, failureLogin } from '../../../../utils/index'
import secureLocalStorage from 'react-secure-storage'
import Prompt from '../../../sharedComponents/Prompt/index'
import { showPrompt, closePrompt } from 'services/Prompt/actions'
import OtpApi from 'services/otp/api'
import LoginApi from 'services/login/api'
import moment from 'moment'
import { dynamicMenuFetching, updateDynamicMenu } from 'services/DynamicMenu/actions'
import { getMenuFeatures } from 'utils'
import { detect } from 'detect-browser'
import DynamicMenuApi from 'services/DynamicMenu/api'
import CurrencyList from 'app/fuse-layouts/layout1/components/navbar/style-2/currency.js'
import { updateDeviceId } from 'app/session/actions'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import GoogleIcon from 'images/logos/Google_logo.svg'
import themesConfig from 'app/fuse-configs/themesConfig'
import OTPVerificationModal from './ValidateOTP'
import { closeOtpModal, setOpenOtpModal } from 'services/otpModal/action'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
function Registerform(props) {
	const { setActiveStep, pageQuery, setPageQuery } = props
	const dispatch = useDispatch()

	const geoInfo = useSelector((state) => state?.GeoInfo?.geoInfo)

	// const handleOpen = () => {
	// 	dispatch(setOpenOtpModal({ open: true }))
	// }
	const [agree, setAgree] = useState(true)
	const handleClose = () => dispatch(closeOtpModal({ open: false }))
	const browser = detect()
	const [deviceData, setDeviceData] = useState({})
	const roleNavigated = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		let uuid = secureLocalStorage.getItem('uuid')
		let mastUuid = secureLocalStorage.getItem('mastuuid')
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (_.isEmpty(res?.data?.data?.dynamicMenu || [])) {
				secureLocalStorage.clear()
				history.push('/')
				dispatch(
					showMessage({
						message: 'Please Re-login Due to some technical issue',
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				history.push(res?.data?.data?.dynamicMenu?.topMenu[0]?.mastMenuUrl)
				let currencyCode = _.find(CurrencyList, (item) => item?.code === res?.data?.data?.userData?.currencyCode)
				let dateTimeFormat = res?.data?.data?.userData?.dateTimeFormat || ''
				let formatForViewer = dateTimeFormat !== 'MMM do yy' && dateTimeFormat !== 'MMM do yyyy' ? dateTimeFormat.toUpperCase() : dateTimeFormat
				dispatch(
					updateDynamicMenu({
						userData: { ...res?.data?.data?.userData, currencySymbol: currencyCode?.symbol, dateFormatForViewer: formatForViewer } || [],
						list: res?.data?.data?.dynamicMenu || [],
						features: getMenuFeatures(res?.data?.data?.dynamicMenu),
					})
				)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			secureLocalStorage.clear()
			history.push('/')
			dispatch(
				showMessage({
					message: 'Please Re-login Due to some technical issue',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(dynamicMenuFetching())
		DynamicMenuApi.getDynamicMenu(uuid, mastUuid).then(onSuccess, onFailure)
	}, [dispatch])

	const [values, setValues] = useState({
		password: '',
		showPassword: false,
	})
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword })
	}
	const [passwordStatus, setPasswordStatus] = useState('weak')
	const classes = useStyles({ passwordStatus })
	const [countryCode, setcountryCode] = useState([])
	const [country, setCountry] = useState({})

	const [validationNumber, setValidationNumber] = useState(10)
	const [countryCodeMinLength, setCountryCodeMinLength] = useState(1)
	const errorMessages = useSelector((state) => state?.errorMessages)
	const currentCountryCode = useSelector((state) => state?.GeoInfo?.countryCode)
	const defaultValues = {
		phoneNumber: '',
		email: '',
		password: '',
		passwordConfirm: '',
		countryCode: '',
		mobileNoLength: '',
	}
	const schema = yup.object().shape({
		phoneNumber: yup
			.string()
			.required('Please enter the valid mobile number')
			.matches(/^([1-9][0-9]*)?$/, 'Please enter the valid mobile number')
			.min(countryCodeMinLength, `Minimum ${countryCodeMinLength} digit`)
			.max(validationNumber, `Maximum ${validationNumber} digit`),
		email: yup
			.string()
			.email('Please enter a valid email address')
			.max(100, 'Maximum 100 characters only allowed')
			.required('Please enter a valid email address'),
		password: yup
			.string()
			.required('Please enter the password')
			.min(8, 'Minimum 8 & Maximum 25 characters only allowed')
			.max(25, 'Maximum 25 characters only')
			.matches(
				// eslint-disable-next-line no-useless-escape
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				'Must contain 8 characters, 1 uppercase, 1 lowercase, 1 number and one special case character'
			),
		passwordConfirm: yup
			.string()
			.max(25, 'Maximum 25 characters only')
			.oneOf([yup.ref('password'), null], 'Passwords must match')
			.required('Please enter your password to confirm'),
	})
	const { control, formState, handleSubmit, setValue, watch } = useForm({
		mode: 'onChange',
		defaultValues,
		values,
		resolver: yupResolver(schema),
	})
	//Password strength meter
	const passwordValue = watch('password')
	const containsUppercase = (string) => /[A-Z]/.test(string)
	const containsLowercase = (string) => /[a-z]/.test(string)
	const containsNumber = (string) => /[0-9]/.test(string)
	const containsSpecialCharacter = (string) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string)
	const [percent, setPercent] = useState(0)
	useEffect(() => {
		if (containsLowercase(passwordValue) && containsUppercase(passwordValue) && containsNumber(passwordValue) && passwordValue.length >= 8) {
			setPercent(1)
			setPasswordStatus('Strength')
		} else if (containsLowercase(passwordValue) && containsUppercase(passwordValue) && containsNumber(passwordValue)) {
			setPercent(0.75)
			setPasswordStatus('Good')
		} else if (containsLowercase(passwordValue) && containsUppercase(passwordValue)) {
			setPercent(0.5)
			setPasswordStatus('Fair')
		} else if (
			containsLowercase(passwordValue) ||
			containsUppercase(passwordValue) ||
			containsNumber(passwordValue) ||
			containsSpecialCharacter(passwordValue)
		) {
			setPercent(0.25)
			setPasswordStatus('Weak')
		}
		// else if (passwordValue.length === 0) {
		// 	setPercent(0)
		// 	setPasswordStatus('Weak')
		// }
	}, [passwordValue])
	useEffect(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			setOptionsLoading(false)
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let initialCountryCode = _.find(data, { mastLookupKey: currentCountryCode || '+91' })
				setCountry(initialCountryCode ? initialCountryCode : '')
				setValidationNumber(initialCountryCode?.mastLookupValue || '')
				setCountryCodeMinLength(initialCountryCode?.minimumLength)
				const code = _.orderBy(data, 'mastLookupKey', 'desc')
				setcountryCode(code)
			}
		}
		const onFailure = (err) => {
			setOptionsLoading(false)
		}
		RegisterApi.getCountryCode().then(onSuccess, onFailure)
	}, [currentCountryCode])
	// useEffect(() => {
	// 	let randomId = uuidv4()
	// 	const onSuccess = (res) => {}
	// 	const onFailure = (err) => {
	// 		console.log(err)
	// 	}
	// 	encryptionApi.getToken(randomId).then(onSuccess, onFailure)
	// }, [])

	const sendOtp = (countryCode, email, mobileNo, userType, identityUuid) => {
		// history.push({
		dispatch(setOpenOtpModal({ open: true }))
		// })
		const body = {
			email: email,
			countryCode: countryCode,
			mobileNo: mobileNo,
			userType: userType,
			signup: true,
			uuid: identityUuid,
		}
		let decryptKey = encryption(body)

		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(successData, errorMessages, 'OTP sent successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		OtpApi.resendOtp(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	useEffect(() => {
		const onSuccess = (res) => {
			setDeviceData(res?.data)
		}
		const onFailure = (err) => {
			console.log(err)
			setDeviceData({})
		}
		LoginApi.getDeviceData().then(onSuccess, onFailure)
	}, [])

	const updateDeviceDetails = (userType, isMobile, isBusiness, isModuleSelected, identityUuid, uuid, newOwner) => {
		const os = browser.os
		const osName = os.split(' ')
		dispatch(showLoader('Loading please wait...'))
		const body = {
			browserName: browser.name,
			deviceId: '',
			deviceName: '',
			deviceToken: '',
			ipAddress: deviceData?.ipAddress || '',
			locCity: deviceData?.cityName || '',
			locCountry: deviceData?.countryName || '',
			locRegion: deviceData?.regionName || '',
			loginTime: moment().format(),
			logoutTime: '',
			model: '',
			osName: os,
			osVersion: osName[1],
			qrStatus: false,
			sessionDetails: '',
			status: true,
			version: '',
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				dispatch(updateDeviceId(successData.data.deviceUuid))
				dispatch(updateBearerToken(successData?.data?.Bearer || ''))
				secureLocalStorage.setItem('AidiDeviceId', successData.data.deviceUuid)
				secureLocalStorage.setItem('AidiBearer', successData?.data?.Bearer || '')
				if (_.isEqual(newOwner, true)) {
					// setGenerate(true)
					dispatch(hideLoader())
					secureLocalStorage.setItem('AidiDeviceId', successData?.data?.deviceUuid)
					dispatch(updateDeviceId(successData?.data?.deviceUuid))
					if (isMobile && !isBusiness && !isModuleSelected) {
						history.push('/register?tab=setupbusiness')
					} else if (isMobile && isBusiness && !isModuleSelected) {
						history.push('/register?tab=moduleselection')
					} else {
						roleNavigated()
					}
				}
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		LoginApi.saveActiveDevices(userType, identityUuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	const newOwnerFlow = (countryCode, email, isMobile, isBusiness, isModuleSelected, mobileNo, userType, identityUuid, newOwner, uuid) => {
		if (_.isEqual(isMobile, false)) {
			sendOtp(countryCode, email, mobileNo, userType, identityUuid)
		} else if (_.isEqual(isMobile, true)) {
			updateDeviceDetails(userType, isMobile, isBusiness, isModuleSelected, identityUuid, uuid, newOwner)
		}
	}
	const [optionsLoading, setOptionsLoading] = useState(false)
	const submitRegisterForm = (data, value) => {
		dispatch(showLoader('Loading please wait...'))
		if (isValid) {
			const passEnc = encryption(data?.password)
			const body = {
				countryCode: country?.mastLookupKey,
				email: data.email,
				isActivate: false,
				isSignUp: true,
				mandatory: true,
				mobileNo: parseInt(data.phoneNumber),
				password: passEnc?.plainText + ':' + passEnc?.publicKey,
				promotions: false,
				userType: 'TNT',
				isWhatsApp: _.isEqual(IsWhatsapp, 'yes') ? true : false,
				signUpViaName: 'lyfngo',
				isPasswordEncoded: true,
			}

			let decryptKey = encryption(body)
			const onSuccess = (res) => {
				const successData = decryption(res)
				if (successData?.newOwner === false) {
					dispatch(hideLoader())
					dispatch(
						Register({
							uuid: successData?.data?.uuid,
							userType: successData?.data?.userType,
							mobileNo: successData?.userMobile,
							emailId: successData?.userEmail,
							userEmail: successData?.userEmail,
							countryCode: country?.mastLookupKey,
							identityUuid: successData?.data?.identityUuid,
							isWhatsappOtp: successData?.isWhatsApp,
							countryName: country?.country,
							countryNameAbbreviation: country?.countryCode,
							BearerToken: successData?.bearer,
						})
					)
					secureLocalStorage.setItem('uuid', successData?.data?.uuid)
					secureLocalStorage.setItem('userType', successData?.data?.userType)
					secureLocalStorage.setItem('mobileNo', successData?.userMobile)
					secureLocalStorage.setItem('emailId', successData?.userEmail)
					secureLocalStorage.setItem('userEmail', successData?.userEmail)
					secureLocalStorage.setItem('countryCode', country?.mastLookupKey)
					secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
					secureLocalStorage.setItem('newOwner', successData?.newOwner)
					secureLocalStorage.setItem('isWhatsappOtp', successData?.isWhatsApp)
					secureLocalStorage.setItem('countryName', country?.country)
					secureLocalStorage.setItem('countryNameAbbreviation', country?.countryCode)
					secureLocalStorage.setItem('AidiBearer', successData?.bearer)
					dispatch(setOpenOtpModal({ open: true }))
				} else if (successData?.newOwner === true) {
					dispatch(hideLoader())
					dispatch(
						Register({
							uuid: successData?.data?.uuid,
							userType: successData?.data?.userType,
							mobileNo: successData?.userMobile,
							emailId: successData?.userEmail,
							userEmail: successData?.userEmail,
							countryCode: country?.mastLookupKey,
							identityUuid: successData?.data?.identityUuid,
							newOwner: successData?.newOwner,
							isWhatsappOtp: successData?.isWhatsApp,
							countryName: country?.country,
							countryNameAbbreviation: country?.countryCode,
							BearerToken: successData?.bearer,
						})
					)
					secureLocalStorage.setItem('uuid', successData?.data?.uuid)
					secureLocalStorage.setItem('userType', successData?.data?.userType)
					secureLocalStorage.setItem('mobileNo', successData?.userMobile)
					secureLocalStorage.setItem('emailId', successData?.userEmail)
					secureLocalStorage.setItem('userEmail', successData?.userEmail)
					secureLocalStorage.setItem('countryCode', country?.mastLookupKey)
					secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
					secureLocalStorage.setItem('newOwner', successData?.newOwner)
					secureLocalStorage.setItem('AidiBearer', successData?.bearer)
					secureLocalStorage.setItem('isWhatsappOtp', successData?.isWhatsApp)
					secureLocalStorage.setItem('countryName', country?.country)
					secureLocalStorage.setItem('countryNameAbbreviation', country?.countryCode)

					dispatch(
						showPrompt(
							`User account already exists. Would you like to continue with same password for ${successData?.userEmail} account`,
							'Yes',
							'No',
							() => {
								dispatch(closePrompt())
								newOwnerFlow(
									country?.mastLookupKey,
									successData?.userEmail,
									successData?.isMobile,
									successData?.isBusinessSelected,
									successData?.isModuleSelected,
									successData?.userMobile,
									successData?.data?.userType,
									successData?.data?.identityUuid,
									successData?.newOwner,
									successData?.data?.uuid
								)
							},
							() => dispatch(closePrompt())
						)
					)
				} else if (res.data.status === 'failure') {
					dispatch(hideLoader())
				}
			}

			const onFailure = (err) => {
				const failureData = failureLogin(err)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(failureData, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			RegisterApi.setRegisterNew(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
		} else if (_.isEqual(value, 'google')) {
			const body = {
				email: _.map(data, (item) => item?.email)?.toString(),
				userType: 'TNT',
				isSignUp: true,
				signUpViaName: 'google',
			}
			let decryptKey = encryption(body)
			const onSuccess = (res) => {
				const successData = decryption(res)
				if (successData?.newOwner === false) {
					dispatch(hideLoader())
					dispatch(
						Register({
							uuid: successData?.data?.uuid,
							userType: successData?.data?.userType,
							mobileNo: successData?.userMobile,
							emailId: successData?.userEmail,
							userEmail: successData?.userEmail,
							countryCode: country?.mastLookupKey,
							identityUuid: successData?.data?.identityUuid,
							isWhatsAppOTP: successData?.isWhatsApp,
							countryName: country?.country,
							countryNameAbbreviation: country?.countryCode,
							BearerToken: successData?.bearer,
							isWhatsappOtp: successData?.isWhatsApp,
						})
					)
					secureLocalStorage.setItem('uuid', successData?.data?.uuid)
					secureLocalStorage.setItem('userType', successData?.data?.userType)
					secureLocalStorage.setItem('mobileNo', successData?.userMobile)
					secureLocalStorage.setItem('emailId', successData?.userEmail)
					secureLocalStorage.setItem('userEmail', successData?.userEmail)
					secureLocalStorage.setItem('countryCode', country?.mastLookupKey)
					secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
					secureLocalStorage.setItem('newOwner', successData?.newOwner)
					secureLocalStorage.setItem('isWhatsappOtp', successData?.isWhatsApp)
					secureLocalStorage.setItem('countryName', country?.country)
					secureLocalStorage.setItem('countryNameAbbreviation', country?.countryCode)
					secureLocalStorage.setItem('AidiBearer', successData?.bearer)
					history.push('/register?tab=setupbusiness')
				} else if (successData?.newOwner === true) {
					dispatch(hideLoader())
					dispatch(
						Register({
							uuid: successData?.data?.uuid,
							userType: successData?.data?.userType,
							mobileNo: successData?.userMobile,
							emailId: successData?.userEmail,
							userEmail: successData?.userEmail,
							countryCode: country?.mastLookupKey,
							identityUuid: successData?.data?.identityUuid,
							newOwner: successData?.newOwner,
							isWhatsappOtp: successData?.isWhatsApp,
							countryName: country?.country,
							countryNameAbbreviation: country?.countryCode,
							BearerToken: successData?.bearer,
						})
					)
					secureLocalStorage.setItem('uuid', successData?.data?.uuid)
					secureLocalStorage.setItem('userType', successData?.data?.userType)
					secureLocalStorage.setItem('mobileNo', successData?.userMobile)
					secureLocalStorage.setItem('emailId', successData?.userEmail)
					secureLocalStorage.setItem('userEmail', successData?.userEmail)
					secureLocalStorage.setItem('countryCode', country?.mastLookupKey)
					secureLocalStorage.setItem('identityUuid', successData?.data?.identityUuid)
					secureLocalStorage.setItem('newOwner', successData?.newOwner)
					secureLocalStorage.setItem('AidiBearer', successData?.bearer)
					secureLocalStorage.setItem('isWhatsappOtp', successData?.isWhatsApp)
					secureLocalStorage.setItem('countryName', country?.country)
					secureLocalStorage.setItem('countryNameAbbreviation', country?.countryCode)

					dispatch(
						showPrompt(
							`User account already exists. Would you like to continue with same password for ${successData?.userEmail} account`,
							'Yes',
							'No',
							() => {
								dispatch(closePrompt())
								newOwnerFlow(
									country?.mastLookupKey,
									successData?.userEmail,
									successData?.isMobile,
									successData?.isBusinessSelected,
									successData?.isModuleSelected,
									successData?.userMobile,
									successData?.data?.userType,
									successData?.data?.identityUuid,
									successData?.newOwner,
									successData?.data?.uuid
								)
							},
							() => dispatch(closePrompt())
						)
					)
				} else if (res.data.status === 'failure') {
					dispatch(hideLoader())
				}
			}

			const onFailure = (err) => {
				const failureData = failureLogin(err)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(failureData, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			RegisterApi.setRegisterNew(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
		}
	}
	const { isValid, errors, isSubmitting } = formState

	function onSubmit(data) {
		if (_.isEqual(agree, false)) {
			dispatch(
				showMessage({
					message: 'Terms and conditions agreement required',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		} else {
			submitRegisterForm(data)
		}
	}

	const onClickTerms = () => {
		// dispatch(showLoader('Loading please wait...'))
		window.open('/terms', '_blank')
	}
	// const onClickPrivacy = () => {
	// 	// dispatch(showLoader('Loading please wait...'))
	// 	window.open('/terms', '_blank')
	// }
	// useEffect(() => {
	// 	setActiveStep('setupbusiness')
	// }, [pageQuery?.tab, setActiveStep])

	const handleNext = () => {
		setPageQuery({ ...pageQuery, tab: 'setupbusiness' })
		setActiveStep('setupbusiness')
	}

	const [IsWhatsapp, setIsWhatsapp] = useState('yes')
	const signup = async () => {
		try {
			// setLoading(true)
			return firebase
				.auth()
				.signInWithPopup(new firebase.auth.GoogleAuthProvider())
				.then((response) => {
					// setUser(response.user?.multiFactor?.user?.providerData)
					submitRegisterForm(response.user?.multiFactor?.user?.providerData, 'google')
				})
		} finally {
			// setLoading(false)
		}
	}
	return (
		<>
			{/* className='flex flex-col justify-center' */}
			<div style={{ padding: 6 }}>
				<Typography variant='h6'>Create a free trial account</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name='email'
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className='mb-16'
								type='text'
								name='email'
								size='small'
								fullWidth
								color='secondary'
								error={!!errors.email}
								helperText={errors?.email?.message}
								label='Email *'
								inputProps={{ maxLength: 100 }}
								// InputProps={{
								// 	endAdornment: (
								// 		<InputAdornment position='end'>
								// 			<Icon className='text-20' color='action'>
								// 				email
								// 			</Icon>
								// 		</InputAdornment>
								// 	),
								// }}
								variant='outlined'
							/>
						)}
					/>
					<div className={classes.section2}>
						<Controller
							name='phoneNumber'
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									label='Mobile number *'
									loading={optionsLoading}
									className={classnames(classes.number)}
									inputProps={{ maxLength: country?.mastLookupValue }}
									error={!!errors.phoneNumber}
									size='small'
									type='tel'
									fullWidth
									name='phoneNumber'
									color='secondary'
									onKeyPress={(e) => {
										if (new RegExp(/[0-9]/).test(e.key)) {
										} else e.preventDefault()
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Autocomplete
													value={country}
													sx={{ width: 65 }}
													disableClearable
													name='registercountrycode'
													id='registercountrycode'
													options={countryCode}
													getOptionLabel={(option) => option?.mastLookupKey || ''}
													// renderOption={(props, option) => {
													// 	return (
													// 		<Box component='li' {...props}>
													// 			{<img loading='lazy' width='20' src={`https://flagcdn.com/${option.countryCode.toLowerCase()}.svg`} alt='' />}
													// 			&nbsp;&nbsp;{option.mastLookupKey}
													// 		</Box>
													// 	)
													// }}
													onChange={(e, value) => {
														setCountry(value)
														setValue('phoneNumber', '')
														setValidationNumber(value?.mastLookupValue)
														setCountryCodeMinLength(value?.minimumLength)

														secureLocalStorage.setItem('phoneNumberLength', value?.mastLookupValue)
														dispatch(
															PhoneNumberLength({
																phoneNumberLength: value.mastLookupValue,
															})
														)
													}}
													renderInput={(params) => (
														<TextField
															size='small'
															className={classes.TextField}
															color='secondary'
															{...params}
															InputProps={{
																...params.InputProps,
																// startAdornment: (
																// 	<InputAdornment position='start'>
																// 		<img
																// 			loading='lazy'
																// 			width='20'
																// 			style={{ marginLeft: '8px', display: 'none' }}
																// 			src={`https://flagcdn.com/${country?.countryCode?.toLowerCase()}.svg`}
																// 			alt=''
																// 		/>
																// 	</InputAdornment>
																// ),
															}}
														/>
													)}
												/>
											</InputAdornment>
										),
										// endAdornment: (
										// 	<InputAdornment position='end'>
										// 		<Icon className='text-20' color='action'>
										// 			phone
										// 		</Icon>
										// 	</InputAdornment>
										// ),
									}}
								/>
							)}
						/>
					</div>
					<Typography component={FormHelperText} className={classes?.errorMessage} color='error' variant='caption' align='left'>
						{' '}
						{errors?.phoneNumber?.message}
					</Typography>
					<Controller
						name='password'
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								// className='mb-16'
								type='password'
								label='Enter Password *'
								error={!!errors.password}
								size='small'
								color='secondary'
								fullWidth
								helperText={errors?.password?.message}
								inputProps={{
									maxLength: 25,
								}}
								InputProps={{
									type: values.showPassword ? 'text' : 'password',
									endAdornment: (
										<InputAdornment position='end'>
											{values.showPassword ? (
												<IconButton tabIndex={-1} size='small' onClick={handleClickShowPassword} className={classes.visibleBtn}>
													<Icon fontSize='small' color='action'>
														visibility
													</Icon>
												</IconButton>
											) : (
												<IconButton tabIndex={-1} size='small' onClick={handleClickShowPassword} className={classes.visibleBtn}>
													<Icon fontSize='small' color='action'>
														visibility_off
													</Icon>
												</IconButton>
											)}
										</InputAdornment>
									),
								}}
								variant='outlined'
							/>
						)}
					/>
					<div style={{ paddingBlock: !_.isEqual(passwordValue?.length, 0) ? 12 : 8 }}>
						{!_.isEqual(passwordValue?.length, 0) && (
							<>
								<LinearProgress
									className={classes.progressbar}
									// style={{ background: _.isEqual(passwordStatus, 'Weak') ? 'red' : _.isEqual(passwordStatus, 'Fair') ? 'yellow' : 'blue' }}
									color='secondary'
									variant='determinate'
									value={percent * 100}
								/>
								<Typography variant='subtitle1' fontSize={12} fontWeight={500}>
									Password Strength : {passwordStatus}
								</Typography>
							</>
						)}
					</div>
					<Controller
						name='passwordConfirm'
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								// className='mb-16'
								fullWidth
								type='password'
								size='small'
								label='Confirm Password *'
								error={!!errors.passwordConfirm}
								color='secondary'
								helperText={errors?.passwordConfirm?.message}
								inputProps={{
									maxLength: 25,
								}}
								InputProps={{
									className: 'pr-2',
									type: 'password',
								}}
								variant='outlined'
							/>
						)}
					/>
					<div className={classes.privacyContainer}>
						<Checkbox
							checked={agree}
							onChange={(e) => setAgree(e.target.checked)}
							sx={{
								color: themesConfig.greyDark.palette.primary.main,
								'&.Mui-checked': {
									color: themesConfig.greyDark.palette.primary.main,
								},
							}}
						/>
						I agree to the 
						<span>
							<ButtonBase
								size='small'
								className={classes.termsCondition}
								onClick={() => {
									onClickTerms()
								}}
							>
								Terms and conditions
							</ButtonBase>
						</span>
						{/*  and 
						<span>
							<ButtonBase
								size='small'
								className={classes.termsCondition}
								onClick={() => {
									onClickTerms()
								}}
							>
								Privacy policy
							</ButtonBase>
						</span> */}
						.
					</div>
					<Typography component={FormHelperText} className={classes?.errorMessage} color='error' variant='caption' align='left'>
						{_.isEqual(agree, false) && 'Terms and conditions agreement required'}
					</Typography>
					<div className={classes.whatappContainer}>
						<Typography variant='subtitle1' fontWeight={600} fontSize={12} color={'#000'}>
							Do you have{' '}
							<span style={{ color: '#25D366' }}>
								<WhatsAppIcon />
							</span>{' '}
							WhatsApp?
						</Typography>

						<RadioGroup
							row
							aria-labelledby='demo-row-radio-buttons-group-label'
							name='row-radio-buttons-group'
							value={IsWhatsapp} // Use state variable as value
							onChange={(e) => setIsWhatsapp(e.target.value)}
						>
							<FormControlLabel
								value='yes'
								control={
									<Radio
										sx={{
											color: themesConfig.greyDark.palette.primary.main,
											'&.Mui-checked': {
												color: themesConfig.greyDark.palette.primary.main,
											},
										}}
									/>
								}
								label='Yes'
							/>
							<FormControlLabel
								value='no'
								control={
									<Radio
										sx={{
											color: themesConfig.greyDark.palette.primary.main,
											'&.Mui-checked': {
												color: themesConfig.greyDark.palette.primary.main,
											},
										}}
									/>
								}
								label='No'
							/>
						</RadioGroup>
					</div>
					<motion.div whileTap={{ scale: 0.95, transform: 'transition .3s ease' }}>
						<Button type='submit' disabled={isSubmitting} variant='contained' customStyle='btn flex w-full mt-16'>
							{_.isEqual(IsWhatsapp, 'yes') ? (
								<Typography variant='body1' fontWeight={500} fontSize={12}>
									Send OTP Via
									<span style={{ padding: 6 }}>
										<WhatsAppIcon />
									</span>{' '}
								</Typography>
							) : (
								'Send OTP Via SMS'
							)}
						</Button>
					</motion.div>
					<motion.div whileTap={{ scale: 0.95, transform: 'transition .3s ease' }} className={classes.googleBtn}>
						<Button size='medium' onClick={() => signup()}>
							<span>
								<img src={GoogleIcon} alt='google-icon' width={30} height={30} />
							</span>
							Sign up with Google
						</Button>
					</motion.div>
					<div className='flex flex-col items-center justify-center pt-8'>
						<div>
							<span className='font-semibold mr-8'> Already have an account?</span>
							<Link className='font-semibold' to='/login' style={{ color: themesConfig?.lyfngo?.palette?.primary?.main }}>
								Log In
							</Link>
						</div>
					</div>
				</form>
			</div>
			<OTPVerificationModal onClose={handleClose} handleNext={handleNext} />
			<Prompt />
		</>
	)
}

export default Registerform
