import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	treatmentCommunicationMain: {
		[theme.breakpoints.up('xs')]: {
			display: 'block',
			margin: 0,
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			padding: 20,
		},
	},
	treatmentCommunicationMainOne: {
		paddingInline: 22,
		paddingBlock: 12,
		borderRadius: 8,
		background: '#fff',
		[theme.breakpoints.up('xs')]: {
			paddingInline: 12,
			margin: 12,
		},
		[theme.breakpoints.up('sm')]: {
			paddingInline: 12,
			margin: 12,
		},
		[theme.breakpoints.up('md')]: {
			paddingInline: 12,
			margin: 0,
		},
	},
	treatmentCommunicationMainTwo: {
		marginBlockStart: 20,
		'& .MuiBox-root': {
			padding: 0,
		},
		[theme.breakpoints.down('xs')]: {
			display: 'block',
		},
	},
	countDetailssectionMain: {},
	commText: {
		fontSize: 14,
		color: '#414A58',
		paddingBlock: 12,
	},
	namePart: {
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			border: 'none',
			'&:hover': {
				border: 'none',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root': {
			padding: '0px',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
			right: 20,
		},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-e82l64-MuiAutocomplete-root .MuiOutlinedInput-root': {
			paddingRight: 0,
		},
		'& .MuiSvgIcon-root': {
			color: '#000',
		},
		'& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
			padding: '2.5px 4px 2.5px 0px',
			color: '#000',
		},
	},
	newSmsPart: {
		justifyContent: 'end',
		alignItems: 'center',
		gap: 12,
		[theme.breakpoints.up('xs')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
		[theme.breakpoints.up('md')]: {
			marginBlock: 0,
		},
	},
	availableText: {
		fontSize: 12,
		color: '#535353',
	},
	addNewSms: {
		background: '#2EB2FF',
		color: '#fff',
		borderRadius: 12,
		'&:hover': {
			background: '#2EB2FF',
			color: '#fff',
		},
	},
	detailsPart: {
		minHeight: 460,
		'& 	.Mui-selected': {
			background: 'aliceblue',
		},
		[theme.breakpoints.up('xs')]: {
			display: 'block',
		},
		[theme.breakpoints.up('sm')]: {
			// display: 'flex',
		},
		[theme.breakpoints.up('md')]: {},
	},
	createCommunictaionpart: {
		borderRadius: 6,
		background: '#fff',
		padding: 14,
		minHeight: 460,
		[theme.breakpoints.up('xs')]: {
			display: 'block',
			margin: 12,
		},
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			margin: 12,
		},
		[theme.breakpoints.up('md')]: {
			padding: 12,
			margin: 0,
		},
	},
	detailsCommunictaionpart: {
		borderRadius: 6,
		background: '#fff',
		[theme.breakpoints.up('xs')]: {
			margin: 12,
		},
		[theme.breakpoints.up('sm')]: {
			margin: 12,
		},
		[theme.breakpoints.up('md')]: {
			margin: 0,
		},
	},
	noPlanImage: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 100,
		maxWidth: 100,
	},
	detailsImage: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 100,
		maxWidth: 260,
	},
	accordionSection: {
		padding: 16,
	},
	total: {
		fontSize: 13,
		color: '#000000',
		fontWeight: 500,
	},
	countDetailssection: {
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	addPlanMsg: {
		height: 400,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.up('xs')]: {
			height: 40,
			fontSize: 12,
			padding: 30,
		},
		[theme.breakpoints.up('sm')]: {
			height: 200,
			fontSize: 12,
			padding: 0,
		},
		[theme.breakpoints.up('md')]: {
			height: 420,
			fontSize: 14,
			padding: 0,
		},
	},
	choosePlan: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
		textAlign: 'center',
		fontSize: 14,
		[theme.breakpoints.up('xs')]: {
			fontSize: 10,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 14,
		},
	},
	choosePlanImage: {
		[theme.breakpoints.up('xs')]: {
			width: 60,
			height: 60,
		},
		[theme.breakpoints.up('sm')]: {
			width: 200,
			height: 200,
		},
	},
}))
export default useStyles
