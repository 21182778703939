import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { Avatar, Grid, IconButton, Typography } from '@mui/material'
import { getImageDownload, getImgUrl, formatFileSize } from 'utils'
import DownloadIcon from '@mui/icons-material/Download'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'

const useStyles = makeStyles({
	root: {
		'& .MuiPaper-elevation': {
			background: 'none',
		},
	},
	container: {
		padding: 12,
		backdropFilter: 'blur(8px)',
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
	},
	content: {
		marginBlockStart: 6,
		maxHeight: '77vh',
		overflowY: 'scroll',
	},
	downloadIcon: {
		color: 'white',
		border: '1px solid blue',
		borderRadius: '14%',
		backgroundColor: 'blue',
		fill: 'blue',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
	},
	closeIcon: {
		color: 'white',
		border: '1px solid white',
		borderRadius: '14%',
		opacity: 1,
		fontSize: 3,
		cursor: 'pointer',
	},

	deleteIcon: {
		color: '#FB1A1A',
		border: '1px solid #FB1A1A',
		borderRadius: '14%',
		opacity: 1,
		fontSize: 3,
		cursor: 'pointer',
		marginInlineStart: 14,
	},
	typeDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 4,
		alignItems: 'center',
		flexWrap: 'wrap',
		'& .MuiTypography-body1': {
			fontSize: 14,
			color: '#fff',
			fontWeight: 500,
		},
	},
	imageAvatar: {
		paddingTop: 8,
	},
})

export default function ViewModal({ setViewOpen, type = null, viewOpen, token, fileSpecificData, deleteFile, enableDelete = false }) {
	const classes = useStyles()
	const handleClose = () => setViewOpen(false)
	const downloadFile = (uuid) => window.open(getImageDownload(uuid, token), '_blank')

	const timeConvert = (data) => {
		let time = moment.utc(data).toDate()
		return moment(time).format('hh:mm A')
	}

	const dateConvert = (data) => {
		let time = moment.utc(data).toDate()
		return moment(time).format('DD/MM/YYYY')
	}

	return (
		<div>
			<Dialog open={viewOpen} onClose={handleClose} fullWidth={true} maxWidth={'sm'} className={classes.root}>
				<section className={classes.container}>
					<Grid container xs={12} className={classes.header}>
						<Grid item xs={3}></Grid>
						<Grid item xs={6} align='center'>
							<IconButton className={classes.downloadIcon}>
								<DownloadIcon onClick={() => downloadFile(fileSpecificData?.uuid || fileSpecificData?.documentUuid)} />
							</IconButton>
							{enableDelete && (
								<IconButton
									className={classes.deleteIcon}
									onClick={() => {
										deleteFile(fileSpecificData?.uuid)
									}}
								>
									<DeleteIcon />
								</IconButton>
							)}
						</Grid>
						<Grid item xs={3} align='end'>
							<IconButton className={classes.closeIcon} onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{type ? (
						type === 'image' ? (
							<>
								<div className={classes.content}>
									<div className={classes.typeDetails}>
										<Typography variant='body1'>Name: {fileSpecificData?.documentName}</Typography>
										<Typography variant='body1'>Size: {formatFileSize(fileSpecificData?.fileSize)}</Typography>
										{fileSpecificData?.created_on?.$date && (
											<>
												{' '}
												<Typography variant='body1'>Date: {dateConvert(fileSpecificData?.created_on?.$date)}</Typography>
												<Typography variant='body1'>Time: {timeConvert(fileSpecificData?.created_on?.$date)}</Typography>
											</>
										)}
									</div>
									<div className={classes.imageAvatar}>
										<Avatar
											className={classes.avatar}
											src={getImgUrl(fileSpecificData?.uuid || fileSpecificData?.documentUuid, token)}
											sx={{ width: '100%', height: '100%', borderRadius: 1 }}
											variant='square'
										/>
									</div>
								</div>
							</>
						) : type === 'document' ? (
							<div className={classes.content}>
								<div className={classes.typeDetails}>
									<Typography variant='body1'>Name: {fileSpecificData?.documentName}</Typography>
									<Typography variant='body1'>Size: {formatFileSize(fileSpecificData?.fileSize)}</Typography>
									{fileSpecificData?.created_on?.$date && (
										<>
											{' '}
											<Typography variant='body1'>Date: {dateConvert(fileSpecificData?.created_on?.$date)}</Typography>
											<Typography variant='body1'>Time: {timeConvert(fileSpecificData?.created_on?.$date)}</Typography>
										</>
									)}
								</div>
								<div style={{ width: '100%', height: '77vh' }}>
									<iframe
										src={`${fileSpecificData?.originalFilePath || fileSpecificData?.viewFileUrl}`}
										title='PDF file preview'
										height='100%'
										width='100%'
									/>
								</div>
							</div>
						) : (
							<div style={{ width: '100%', height: '77vh' }}>
								<div className={classes.typeDetails}>
									{fileSpecificData?.documentName && <Typography variant='body1'>Name: {fileSpecificData?.documentName}</Typography>}
									{fileSpecificData?.fileSize && <Typography variant='body1'>Size: {formatFileSize(fileSpecificData?.fileSize)}</Typography>}
									{fileSpecificData?.created_on?.$date && (
										<Typography variant='body1'>Date: {dateConvert(fileSpecificData?.created_on?.$date)}</Typography>
									)}
									{fileSpecificData?.created_on?.$date && (
										<Typography variant='body1'>Time: {timeConvert(fileSpecificData?.created_on?.$date)}</Typography>
									)}
								</div>
								<iframe
									src={`${fileSpecificData?.originalFilePath || fileSpecificData?.viewFileUrl}`}
									title='Video file preview'
									height='100%'
									width='100%'
								/>
							</div>
						)
					) : (
						<div className={classes.content}>
							<div className={classes.typeDetails}>
								{fileSpecificData?.documentName && <Typography variant='body1'>Name: {fileSpecificData?.documentName}</Typography>}
								{fileSpecificData?.fileSize && <Typography variant='body1'>Size: {formatFileSize(fileSpecificData?.fileSize)}</Typography>}
								{fileSpecificData?.created_on?.$date && (
									<Typography variant='body1'>Date: {dateConvert(fileSpecificData?.created_on?.$date)}</Typography>
								)}
								{fileSpecificData?.created_on?.$date && (
									<Typography variant='body1'>Time: {timeConvert(fileSpecificData?.created_on?.$date)}</Typography>
								)}
							</div>
							{!(fileSpecificData?.documentExtension === 'pdf') ? (
								<div className={classes.imageAvatar}>
									<Avatar
										className={classes.avatar}
										src={getImgUrl(fileSpecificData?.uuid, token)}
										sx={{ width: '100%', height: '100%', borderRadius: 1 }}
										variant='square'
									/>
								</div>
							) : (
								<div style={{ width: '100%', height: '77vh' }}>
									<iframe src={`${fileSpecificData?.originalFilePath}`} title='PDF file preview' height='100%' width='100%' />
								</div>
							)}
						</div>
					)}
				</section>
			</Dialog>
		</div>
	)
}
