import { Autocomplete, Button, Checkbox, Chip, FormControlLabel, InputAdornment, TextField, Tooltip, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import WelcomeCareapi from '../../../../services/welcomeCare/api'
import { decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import _ from 'lodash'
import PracticeDetailsApi from '../../../../services/Settings/PracticeDetails/api'
import secureLocalStorage from 'react-secure-storage'
import lookupApi from '../../../../services/welcomeCare/api'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { Search } from '@mui/icons-material'
import { hideLoader } from 'services/loader/action'
import { Register, estSelection } from 'app/session/actions'
import { showMessage } from 'app/store/fuse/messageSlice'
import themesConfig from 'app/fuse-configs/themesConfig'
import InfoIcon from '@mui/icons-material/Info'
const BusinessSetup = ({ setActiveStep, setPageQuery, pageQuery }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [loader, setLoader] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedArray, setSelectedArray] = useState([])
	const [ownerSpecialist, setOwnerSpecialist] = useState(true)
	const [demoResponseData, setDemoResponseData] = useState(true)
	const [tenantType, setTenantType] = useState([])
	const [practiseLoading, setPractiseLoading] = useState(false)
	const state = useSelector((state) => state.Authentication)
	const { identityUuid, userType, userEmail, mobileNo, countryName, countryNameAbbreviation, uuid, newOwner } = state
	const errorMessages = useSelector((state) => state.errorMessages)

	const [selectedChip, setSelectedChip] = useState(new Set())

	const [specializationSearchValue, setSpecializationSearchValue] = useState('')
	const [specializationSearchParams, setSpecializationSearchParams] = useState({
		specSearch: null,
	})
	const onSearch = _.debounce(
		(value) => {
			setSpecializationSearchParams({
				...specializationSearchParams,
				specSearch: value,
			})
		},
		[300]
	)
	const [specialityParams, setSpecialityParams] = useState({ tentTypeUuid: '' })
	const [practiceSpeciality, setPracticeSpeciality] = useState([])
	const getTenantType = useCallback(() => {
		setLoader(true)
		const onSuccess = (res) => {
			setLoading(false)
			setLoader(false)
			setTenantType(res.data.data)
		}
		const onFailure = (err) => {
			setLoading(false)
		}
		lookupApi.getTenantTypePractice().then(onSuccess, onFailure)
	}, [])
	const getPracticeSpeciality = useCallback((specialityParams, specializationSearchParams) => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			setPractiseLoading(false)
			setPracticeSpeciality(successData?.data)
		}
		const onFailure = (err) => {
			setPractiseLoading(false)
			setPracticeSpeciality([])
		}
		if (!_.isEmpty(specialityParams?.tentTypeUuid || '')) {
			PracticeDetailsApi.getPracticeSpeciality({ ...specialityParams, ...specializationSearchParams }).then(onSuccess, onFailure)
		}
	}, [])
	useEffect(() => {
		getPracticeSpeciality(specialityParams, specializationSearchParams)
	}, [getPracticeSpeciality, specialityParams, specializationSearchParams])

	useEffect(() => {
		getTenantType()
	}, [getTenantType])

	useEffect(() => {
		setActiveStep(pageQuery?.tab)
	}, [pageQuery?.tab, setActiveStep])

	const handleNext = () => {
		setPageQuery({ ...pageQuery, tab: 'moduleselection' })
		setActiveStep('moduleselection')
	}
	// const onSubmit = () => {
	// 	submitWelcomeCareidentity()
	// }
	const setWelcomecare = (e, tenantUuid) => {
		// dispatch(showLoader('Loading please wait...'))
		const body = {
			mastTentTypeUuid: e.tenantType?.mastTtypeUuid,
			tentEmail: userEmail,
			tentName: e.businessName,
			tentStatus: true,
			specialityUuid: _.map(selectedArray, (spl) => spl),
			tentUserIdentityUuid: identityUuid,
			userType: userType,
			country: { countryName: countryName, countryNameAbbreviation: countryNameAbbreviation } || {},
			state: e?.state || '',
			createDemo: demoResponseData,
			duplicate: 'both',
			isAddress: true,
			countryAbbrev: countryNameAbbreviation,
			tentCommLanguage: [{ mastLookupKey: 'en', mastLookupValue: 'English', mastLookupType: 'PLA' }],
			isOwnerSpecialist: ownerSpecialist,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				secureLocalStorage.setItem('mastuuid', successData?.data?.tenantuuid)
				secureLocalStorage.setItem('isPractice', successData?.data?.isPractice)
				secureLocalStorage.setItem('mastTentTypeUuid', successData?.data?.mastTentTypeUuid)
				secureLocalStorage.setItem('userRole', 'Owner')
				// setcountryAbbrev(null)
				dispatch(
					estSelection({
						mastuuid: successData?.data?.tenantuuid,
						isPractice: successData?.data?.isPractice,
						mastTentTypeUuid: successData?.data?.mastTentTypeUuid,
						userRole: 'Owner',
					})
				)
				handleNext()
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		_.isEqual(newOwner, true)
			? WelcomeCareapi.setWelcomecare(decryptKey?.plainText, decryptKey?.publicKey, tenantUuid).then(onSuccess, onFailure)
			: WelcomeCareapi.setWelcomecare(decryptKey?.plainText, decryptKey?.publicKey, uuid).then(onSuccess, onFailure)
	}
	const tentTypeGroupId = secureLocalStorage.getItem('tentTypeGroupId')
	const submitWelcomeCareidentity = (e) => {
		const body = {
			tentUserEmail: userEmail,
			tentUserFirstName: e.businessName,
			userType: userType,
			country: { countryName: countryName, countryNameAbbreviation: countryNameAbbreviation } || {},
			state: e?.state || '',
			tentUserPhone: mobileNo,
			isAddress: true,
			tentUserCountryCode: countryNameAbbreviation,
			tentGroupUuid: tentTypeGroupId,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData?.status === 'success') {
				setWelcomecare(e, successData?.data?.tenantUuid)
				dispatch(
					Register({
						uuid: successData?.data?.tenantUuid,
						identityUuid: identityUuid,

						userType: userType,
						mobileNo: mobileNo,
						emailId: userEmail,
						userEmail: userEmail,
						// countryCode: country?.mastLookupKey,

						newOwner: newOwner,
					})
				)
				secureLocalStorage.setItem('uuid', successData?.data?.tenantUuid)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		WelcomeCareapi.setWelcomecareUserIdentity(decryptKey?.plainText, decryptKey?.publicKey, identityUuid).then(onSuccess, onFailure)
	}
	const formik = useFormik({
		initialValues: {
			businessName: '',
			tenantType: null,
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			businessName: yup
				.string()
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the notes')
				.min(3, 'Practice name should minimum 3 characters')
				.max(50, 'Practice name should maximum 50 characters')
				.required('Please enter the business name')
				.trim(),
			tenantType: yup.object().nullable().required('Please choose the tenant type'),
		}),
		onSubmit: _.isEqual(newOwner, true) ? submitWelcomeCareidentity : setWelcomecare,
	})

	const handleClick = (data) => {
		const newSet = new Set(selectedChip)

		if (newSet.has(data)) newSet.delete(data)
		else newSet.add(data)
		const arraySelected = Array.from(newSet)
		setSelectedChip(newSet)
		setSelectedArray(arraySelected)
	}

	return (
		// onClick={handleNext}
		<div className={classes?.businessNameContainer}>
			<Typography variant='h6'>Setup your business</Typography>
			<div className={classes?.TextFieldContent}>
				<TextField
					variant='outlined'
					label='Business name*'
					color='secondary'
					placeholder='Business name*'
					type='text'
					name='businessName'
					id='businessName'
					size='small'
					value={formik.values.businessName}
					onChange={formik.handleChange}
					error={formik.touched.businessName && formik.errors.businessName}
					helperText={formik.touched.businessName && formik.errors.businessName && formik.errors.businessName}
					fullWidth
				/>
				<Autocomplete
					id='selecttenttype'
					name='selecttenttype'
					options={tenantType}
					disableClearable={true}
					loading={loader}
					autoHighlight
					getOptionLabel={(option) => option?.mastTentTypeName || ''}
					value={formik?.values?.tenantType}
					onChange={(e, value) => {
						formik?.setFieldValue('tenantType', value)

						secureLocalStorage.setItem('tenantName', value?.mastTentTypeName)
						secureLocalStorage.setItem('tentTypeId', value?.mastTtypeUuid)
						secureLocalStorage.setItem('tentTypeGroupId', value?.mastTentGroupUuid)
						setSpecialityParams((prevState) => ({
							...prevState,
							tentTypeUuid: value.mastTtypeUuid,
						}))
						setSelectedChip(new Set())
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							color='secondary'
							label='Tenant type *'
							size='small'
							error={formik.touched.tenantType && formik.errors.tenantType}
							helperText={formik.touched.tenantType && formik.errors.tenantType}
							inputProps={{
								...params.inputProps,
							}}
						/>
					)}
				/>
				<TextField
					name='searchOptions'
					id='searchOptions'
					fullWidth
					size='small'
					color='secondary'
					disableClearable={false}
					value={specializationSearchValue}
					onChange={(e, value) => {
						setSpecializationSearchValue(e.target.value)
						onSearch(e.target.value)
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Search style={{ color: '#000' }} />
							</InputAdornment>
						),
					}}
					label={'Specialization*'}
					placeholder={'Search the specialization'}
				/>

				<div className={_.isEqual(formik?.values?.tenantType, null) ? classes?.chipVisiablityContainer : classes?.chipContainer}>
					{!_.isEqual(practiceSpeciality, []) ? (
						(practiceSpeciality || []).map((data) => (
							<Chip
								variant={selectedChip.has(data.specialityUuid) ? 'contained' : 'outlined'}
								key={data?.specialityUuid}
								icon={selectedChip.has(data.specialityUuid) ? <CheckCircleOutlineOutlinedIcon /> : <ControlPointOutlinedIcon />}
								label={data?.specialityName}
								onClick={() => handleClick(data?.specialityUuid)}
								loader={practiseLoading}
							/>
						))
					) : (
						<Typography variant='body1' className={classes?.emptySpecialization}>
							Specialization field has no options
						</Typography>
					)}
				</div>
				{_.isEqual(selectedChip?.size, 0) && !_.isEqual(practiceSpeciality, []) && !_.isEqual(formik?.values?.tenantType, null) && (
					<Typography color={'error'}>Please select any one specialization </Typography>
				)}
				<div className={classes?.checkboxOwnerContainer}>
					<div className={classes?.checkboxOwner}>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={ownerSpecialist}
									onChange={(e) => {
										setOwnerSpecialist(e?.target?.checked)
									}}
									size='small'
									sx={{
										color: themesConfig.greyDark.palette.primary.main,
										'&.Mui-checked': {
											color: themesConfig.greyDark.palette.primary.main,
										},
									}}
								/>
							}
							label='Solopreneur'
							//
						/>
						<Tooltip title={'Are you a solorpreneur handling individually your establishment. Click to experience'} followCursor>
							{<InfoIcon fontSize='small' color='#fff' />}
						</Tooltip>
					</div>

					<div className={classes?.checkboxOwner}>
						<FormControlLabel
							control={
								<Checkbox
									color='primary'
									checked={demoResponseData}
									onChange={(e) => {
										setDemoResponseData(e?.target?.checked)
									}}
									size='small'
									sx={{
										color: themesConfig.greyDark.palette.primary.main,
										'&.Mui-checked': {
											color: themesConfig.greyDark.palette.primary.main,
										},
									}}
								/>
							}
							label='Demo Data'
							//
						/>
						<Tooltip title={'System will populate demo data to experience'} followCursor>
							{<InfoIcon fontSize='small' color='#fff' />}
						</Tooltip>
					</div>
				</div>

				<Button
					type='submit'
					onClick={() => {
						formik?.handleSubmit()
					}}
					className={classes.CustomButton}
					variant='contained'
				>
					Just one step to go !
				</Button>
			</div>
		</div>
	)
}

export default BusinessSetup
