import secureLocalStorage from 'react-secure-storage'
import { ACTION_TYPES } from '../../../constants'

const initialState = {
	selectedTemplate: secureLocalStorage?.getItem('selectedTemplate') || {},
}

const CommunicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.TEMPLATE_BASED_DATA: {
			return {
				...state,
				selectedTemplate: action.payload,
			}
		}
		default:
			return state
	}
}

export default CommunicationReducer
