import { LocalPrintshopOutlined } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import ComponentToPrint from './ComponentToPrint'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	headerIcon: {
		background: '#fff',
		color: '#383838',
		minWidth: 36,
		borderRadius: 8,
		'&:hover': {
			background: '#fff',
		},
	},
}))

export default function PrintComponent({
	report,
	printColoumn,
	printRow,
	pageData,
	dateForPrint,
	appliedFiltersForprint,
	practiceName,
	isCare,
	selectedFilters,
}) {
	const classes = useStyles()
	let componentRef = useRef()

	return (
		<>
			<ReactToPrint
				trigger={() => (
					<Tooltip title='Print' arrow enterDelay={500}>
						<div className='print'>
							<Button size='medium' className={classes.headerIcon}>
								<LocalPrintshopOutlined />
							</Button>
						</div>
					</Tooltip>
				)}
				content={() => componentRef}
				documentTitle='Lyfngo Report'
				pageStyle={`@page { size: ${
					report === 'InvoicedDetail' || report === 'Distributor' || report === 'Membership' ? 'landscape' : 'auto'
				}; margin: 8mm; }`}
			/>

			<div
				style={{
					background: '#FFFFFF',
					minWidth: 1020,
					maxWidth: 1020,
					display: 'none',
				}}
			>
				<ComponentToPrint
					ref={(el) => (componentRef = el)}
					report={report}
					printColoumn={printColoumn}
					printRow={printRow}
					pageData={pageData}
					dateForPrint={dateForPrint}
					appliedFiltersForprint={appliedFiltersForprint}
					practiceName={practiceName}
					selectedFilters={selectedFilters}
					isCare={isCare}
				/>
			</div>
		</>
	)
}
