import { Typography, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { motion } from 'framer-motion'

const useStyles = makeStyles((theme) => ({
	link: {
		cursor: 'pointer',
		fontSize: '24px',
	},
}))

function Error404Page({ history }) {
	const classes = useStyles()

	const goBackFxn = () => {
		history.push('/')
	}

	return (
		<div className='flex flex-col flex-1 items-center justify-center p-16'>
			<div className='max-w-512 text-center'>
				<motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}>
					<Typography variant='h2' color='inherit' className='font-medium mb-16'>
						Page Not Found
					</Typography>
				</motion.div>

				<Link className={classnames(classes.link, 'font-normal')} onClick={goBackFxn}>
					Go to LYFnGo.com Home Page
				</Link>
			</div>
		</div>
	)
}

export default Error404Page
