import { ACTION_TYPES } from '../../../constants'

export const dynamicMenuFetching = () => ({
	type: ACTION_TYPES.DYNAMIC_MENU_FETCHING,
})

export const updateDynamicMenu = (payload) => ({
	type: ACTION_TYPES.DYNAMIC_MENU_SUCCESS,
	payload,
})

export const updateTrialStatus = (payload) => ({
	type: ACTION_TYPES.DYNAMIC_MENU_UPDATE_TRIAL,
	payload,
})

export const updatePracticeList = (payload) => ({
	type: ACTION_TYPES.PRACTICE_LIST_FETCHING,
	payload,
})

export const isFetchingPracticeList = (payload) => ({
	type: ACTION_TYPES.IS_FETCHING_PRACTICE_LIST,
	payload,
})

export const setCurrentPractice = (payload) => ({
	type: ACTION_TYPES.CURRENT_PRACTICE_DATA,
	payload,
})

export const setCurrentModule = (payload) => ({
	type: ACTION_TYPES.UPDATE_CURRENT_MODULE,
	payload: payload,
})
export const updateUserData = (payload) => ({
	type: ACTION_TYPES.UPDATE_USERDATA,
	payload: payload,
})
