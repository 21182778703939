import React from 'react'
import { Dialog, Divider, Slide, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
const useStyles = makeStyles((theme) => ({
	topBox: {
		display: 'flex',
		justifyContent: 'end',
	},
	mainContent: {
		textAlign: 'center',
		padding: 24,
	},
	typographyOne: {
		fontSize: 15,
		fontWeight: 600,
	},
	typographyTwo: { fontSize: 14, fontWeight: 500 },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})
const GoogleSignInDialog = (props) => {
	const { open, setOpen, history } = props
	const classes = useStyles()
	// const modalClose = () => {
	// 	setOpen(false)
	// }
	return (
		<Dialog open={open} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description' maxWidth='sm'>
			{/* <Box className={classes.topBox}>
				<IconButton onClick={modalClose}>
					<Close />
				</IconButton>
			</Box> */}
			<Box className={classes.mainContent}>
				<Box mb={3} display={'flex'} justifyContent={'center'}>
					<img src={`${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/No_data_images/Validate_number.svg`} alt='Validate_number' />
				</Box>
				<Typography variant='h5' mb={2} className={classes.typographyOne}>
					Verify mobile number to validate
				</Typography>
				<Divider />
				<Typography mt={3} variant='h6' className={classes.typographyTwo}>
					Please update your mobile number and verify to seamlessly experience the features which relates to communication
				</Typography>
				<div
					onClick={() => {
						history?.push('/accountdetails')
						setOpen(false)
					}}
					style={{ cursor: 'pointer' }}
				>
					<Typography mt={2} variant='h6' className={classes.typographyTwo} sx={{ textDecoration: 'underline' }}>
						Click to update
					</Typography>
				</div>
			</Box>
		</Dialog>
	)
}

export default GoogleSignInDialog
