import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		borderRadius: 21,
		textTransform: 'none',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		boxShadow: 'none',
		// gap: 4,
		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
function SaveAndNewButton({ children, onClick, disabled, type }) {
	const classes = useStyles()
	return (
		<Button variant='contained' type={type} className={classes.root} onClick={onClick} disabled={disabled}>
			{children}
		</Button>
	)
}
export default SaveAndNewButton
