import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Typography, Grid, TextField, Autocomplete } from '@mui/material'
import useStyles from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import WelcomeCareapi from '../../../../services/welcomeCare/api'
import LookupApi from 'services/lookup/api'
import { estSelection } from 'app/session/actions'
import { hideLoader, showLoader } from 'services/loader/action'
import Button from '../../../sharedComponents/Button'
import history from '@history'
import { decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import secureLocalStorage from 'react-secure-storage'
import Lookup from '../Lookup'
import { Register } from '../../../session/actions'

const WelcomeCare = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const cancelExecutor = useRef()
	const [loader, setLoader] = useState(false)
	const [optionsLoading, setOptionsLoading] = useState(false)
	// const [timeOption, setTimeOption] = useState([])
	// const [statesOption, setStatesOption] = useState([])
	const state = useSelector((state) => state.Authentication)
	const { groupId, groupName, identityUuid, userType, userEmail, uuid, mobileNo, newOwner } = state
	const errorMessages = useSelector((state) => state.errorMessages)
	const [countryOptions, setCountryOptions] = useState([])
	const [cityOptions, setCityOptions] = useState([])
	const [statesOption, setStatesOption] = useState([])
	const [stateParams, setStateParams] = useState({ limit: 50, offset: 1, mastCountryCode: '', search: '' })
	const [tenantType, setTenantType] = useState([])
	const [practiceSpeciality, setPracticeSpeciality] = useState([])
	const [loading, setLoading] = useState(false)
	const [diablePractice, setDisbalePractice] = useState(false)
	const [countryAbbrev, setcountryAbbrev] = useState(null)
	const [countryData, setCountryData] = useState([])
	const [countryName, setCountryName] = useState(null)

	const setWelcomecare = (e, tenantUuid) => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			mastTentTypeUuid: e.tenantType?.mastTtypeUuid,
			tentEmail: userEmail,
			tentName: e.practiceName,
			tentStatus: true,
			specialityUuid: _.map(e.practiceSpeciality, (spl) => spl?.specialityUuid),
			tentUserIdentityUuid: identityUuid,
			userType: userType,
			country: { countryName: countryName, countryNameAbbreviation: countryAbbrev } || {},
			state: e?.state || '',
			createDemo: false,
			duplicate: 'both',
			isAddress: true,
			countryAbbrev: countryAbbrev,
			tentCommLanguage: [{ mastLookupKey: 'en', mastLookupValue: 'English', mastLookupType: 'PLA' }],
			isOwnerSpecialist: true,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData.status === 'success') {
				secureLocalStorage.setItem('mastuuid', successData?.data?.tenantuuid)
				secureLocalStorage.setItem('isPractice', successData?.data?.isPractice)
				secureLocalStorage.setItem('userRole', 'Owner')
				setcountryAbbrev(null)
				dispatch(
					estSelection({
						mastuuid: successData?.data?.tenantuuid,
						isPractice: successData?.data?.isPractice,
						userRole: 'Owner',
					})
				)
				history.push({
					pathname: '/moduleselection',
				})
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		_.isEqual(newOwner, true)
			? WelcomeCareapi.setWelcomecare(decryptKey?.plainText, decryptKey?.publicKey, tenantUuid).then(onSuccess, onFailure)
			: WelcomeCareapi.setWelcomecare(decryptKey?.plainText, decryptKey?.publicKey, uuid).then(onSuccess, onFailure)
	}
	const tentTypeGroupId = secureLocalStorage.getItem('tentTypeGroupId')

	const submitWelcomeCareidentity = (e) => {
		dispatch(showLoader('Loading please wait...'))
		const body = {
			tentUserEmail: userEmail,
			tentUserFirstName: e.practiceName,
			userType: userType,
			country: { countryName: countryName, countryNameAbbreviation: countryAbbrev } || {},
			state: e?.state || '',
			tentUserPhone: mobileNo,
			isAddress: true,
			tentUserCountryCode: countryAbbrev,
			tentGroupUuid: tentTypeGroupId,
		}

		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			if (successData?.status === 'success') {
				setWelcomecare(e, successData?.data?.tenantUuid)
				dispatch(
					Register({
						uuid: successData?.data?.tenantUuid,
						identityUuid: identityUuid,

						userType: userType,
						mobileNo: mobileNo,
						emailId: userEmail,
						userEmail: userEmail,
						// countryCode: country?.mastLookupKey,

						newOwner: newOwner,
					})
				)
				secureLocalStorage.setItem('uuid', successData?.data?.tenantUuid)
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		WelcomeCareapi.setWelcomecareUserIdentity(body, identityUuid).then(onSuccess, onFailure)
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			practiceName: '',
			tenantType: null,
			practiceSpeciality: [],
			city: null,
			country: null,
			state: null,
			// timezone: null,
		},
		validationSchema: yup.object({
			practiceName: yup
				.string()
				.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the notes')
				.min(3, 'Practice name should minimum 3 characters')
				.max(50, 'Practice name should minimum 50 characters')
				.required('Please enter the practice name')
				.trim(),
			tenantType: yup.object().nullable().required('Please choose the tenant type'),
			practiceSpeciality: yup
				.array()
				.nullable()
				.min(1, 'Please choose minimum 1 speciality')
				.max(5, 'Practice speciality is maximum 5')
				.required('Please choose the practice speciality'),
			// city: yup.object().nullable().required('Please choose the city'),
			state: yup.object().nullable().required('Please select the state'),
			country: yup.object().nullable().required('Please choose the country'),
			// timezone: yup.object().nullable().required('Please choose the timezone'),
		}),
		onSubmit: _.isEqual(newOwner, true) ? submitWelcomeCareidentity : setWelcomecare,
	})

	useEffect(() => {
		if (formik?.values?.practiceSpeciality?.length === 5) {
			setDisbalePractice(true)
		} else {
			setDisbalePractice(false)
		}
	}, [formik?.values?.practiceSpeciality])
	const [countryParams, setCountryParams] = useState({
		limit: 50,
		offset: 1,
		mastLookupType: 'CNT',
		search: formik?.values?.country,
	})
	const onCountrySearch = _.debounce(
		(e, value) => {
			setCountryParams({
				...countryParams,
				search: value,
			})
		},
		[300]
	)

	const [cityParams, setCityParams] = useState({
		limit: 50,
		offset: 1,
		team: 'react',
		mastCountryCode: '',
		search: formik?.values?.city,
	})

	const [specialityParams, setSpecialityParams] = useState({
		tentTypeId: '',
	})
	const getTenantType = useCallback(() => {
		setLoader(true)
		const onSuccess = (res) => {
			setLoading(false)
			setLoader(false)
			setTenantType(res.data.data)
		}
		const onFailure = (err) => {
			setLoading(false)
		}
		WelcomeCareapi.getTenantType(groupId).then(onSuccess, onFailure)
	}, [groupId])

	useEffect(() => {
		if (loading) {
			dispatch(showLoader('Loading please wait...'))
		} else {
			dispatch(hideLoader())
		}
	}, [dispatch, loading])

	const [practiseLoading, setPractiseLoading] = useState(false)

	const getPracticeSpeciality = useCallback(() => {
		setPractiseLoading(true)
		const onSuccess = (res) => {
			setPractiseLoading(false)
			setPracticeSpeciality(res.data.data)
		}
		const onFailure = (err) => {
			setPractiseLoading(false)
			setPracticeSpeciality([])
		}
		if (!_.isEmpty(specialityParams?.tentTypeId || '')) {
			WelcomeCareapi.getPracticeSpeciality('SpecialityType', { ...specialityParams }).then(onSuccess, onFailure)
		}
	}, [specialityParams])

	useEffect(() => {
		getTenantType()
		getPracticeSpeciality()
	}, [getPracticeSpeciality, getTenantType])

	//country new dropdown
	useEffect(() => {
		setPractiseLoading(true)
		const onSuccess = (res) => {
			setPractiseLoading(false)
			setCountryData(res.data.data)
		}
		const onFailure = (err) => {
			setPractiseLoading(false)
			setCountryData([])
		}
		WelcomeCareapi.getCountryFetch().then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		if (tenantType !== practiceSpeciality) {
			formik?.setFieldValue('practiceSpeciality', [])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantType])
	useEffect(() => {
		if (countryOptions !== cityOptions) {
			formik?.setFieldValue('city', '')
		}
		// eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
	}, [countryOptions])

	const onStateSearch = _.debounce(
		(e, value) => {
			setStateParams({
				...stateParams,
				search: value,
			})
		},
		[300]
	)

	useEffect(() => {
		setOptionsLoading(true)
		const onSuccess = (res) => {
			setOptionsLoading(false)
			setStatesOption(res.data.data)
		}
		const onFailure = () => {
			setOptionsLoading(false)
			setStatesOption([])
		}
		if (!_.isEmpty(stateParams?.mastCountryCode)) {
			LookupApi.getLookup('citiesStates', { ...stateParams }, cancelExecutor).then(onSuccess, onFailure)
		}
	}, [stateParams, formik?.values?.country])

	return (
		<>
			<Grid container>
				<Grid item>
					<Typography align='center' variant='h5' className={classes.heading}>
						Welcome!
					</Typography>
					{_.isEqual(groupName, 'health care') ? (
						<Typography align='center' variant='body1' className={classes.subheading}>
							You are one step away from choosing the world's no.1 practice management software.
						</Typography>
					) : (
						<Typography align='center' variant='body1' className={classes.subheading}>
							You are one step away from choosing the world's no.1 establishment management software.
						</Typography>
					)}
				</Grid>
				<form autoComplete='on' onSubmit={formik.handleSubmit}>
					<Grid item justifyContent='center' align='center'>
						<TextField
							className={classes.textField}
							variant='outlined'
							placeholder='Your Email'
							type='text'
							label='Email'
							color='secondary'
							name='email'
							id='email'
							disabled
							value={userEmail}
							onChange={formik.handleChange}
							fullWidth
						/>
						{formik.touched.email && formik.errors.email ? (
							<Typography variant='caption' style={{ color: 'red' }}>
								{formik.errors.email}
							</Typography>
						) : null}

						<TextField
							className={classes.textField}
							variant='outlined'
							label='Practice name *'
							color='secondary'
							placeholder='Practice name'
							type='text'
							name='practiceName'
							id='practiceName'
							value={formik.values.practiceName}
							onChange={formik.handleChange}
							error={formik.touched.practiceName && formik.errors.practiceName}
							helperText={formik.touched.practiceName && formik.errors.practiceName && formik.errors.practiceName}
							fullWidth
							InputProps={{
								classes: {
									root: classes.root,
								},
							}}
						/>
						<Autocomplete
							id='selecttenttype'
							name='selecttenttype'
							options={tenantType}
							disableClearable={true}
							loading={loader}
							autoHighlight
							// openOnFocus
							getOptionLabel={(option) => option?.mastTentTypeName || ''}
							value={formik?.values?.tenantType}
							onChange={(e, value) => {
								formik?.setFieldValue('tenantType', value)
								secureLocalStorage.setItem('tenantName', value?.mastTentTypeName)
								secureLocalStorage.setItem('tentTypeId', value?.mastTtypeUuid)
								secureLocalStorage.setItem('tentTypeGroupId', value?.mastTentGroupUuid)
								// formik?.setFieldValue('practiceSpeciality', [])
								setSpecialityParams((prevState) => ({
									...prevState,
									tentTypeId: secureLocalStorage.getItem('tentTypeId'),
								}))
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.textField}
									color='secondary'
									label='Tenant type *'
									error={formik.touched.tenantType && formik.errors.tenantType}
									helperText={formik.touched.tenantType && formik.errors.tenantType}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/>

						<Autocomplete
							id='selectpraticespeciality'
							name='selectpraticespeciality'
							options={practiceSpeciality}
							disableClearable={true}
							autoHighlight
							multiple={true}
							loading={practiseLoading}
							loadingText='Please select Tenant type first'
							openOnFocus
							limitTags={2}
							filterSelectedOptions={true}
							disableCloseOnSelect={true}
							getOptionLabel={(option) => option?.specialityName || ''}
							value={formik?.values?.practiceSpeciality}
							onChange={(e, value) => {
								formik?.setFieldValue('practiceSpeciality', value)
							}}
							getOptionDisabled={(option) => (diablePractice ? true : false)}
							renderInput={(params) => (
								<TextField
									{...params}
									color='secondary'
									className={classes.textField}
									label='Practice speciality*'
									error={formik.touched.practiceSpeciality && formik.errors.practiceSpeciality}
									helperText={
										formik.touched.practiceSpeciality && formik.errors.practiceSpeciality
											? formik.errors.practiceSpeciality
											: diablePractice && 'Add multiple specialties in the settings menu > practice details page'
									}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/>
						<Autocomplete
							id='selectcountryspeciality'
							name='selectcountryspeciality'
							options={countryData}
							disableClearable={true}
							autoHighlight
							openOnFocus
							getOptionLabel={(option) => option?.countryName || ''}
							value={formik?.values?.country}
							onChange={(e, value) => {
								formik?.setFieldValue('country', value)
								setcountryAbbrev(value?.countryNameAbbreviation)
								setCountryName(value?.countryName)
								setCountryParams((prevState) => ({ ...prevState, search: value?.countryNameAbbreviation }))
								setCityParams((prevState) => ({ ...prevState, mastCountryCode: value.countryNameAbbreviation }))
								setStateParams((prevState) => ({ ...prevState, mastCountryCode: value?.countryNameAbbreviation }))
								formik.setFieldValue('state', '')
								formik?.setFieldValue('city', '')
							}}
							onInputChange={onCountrySearch}
							renderInput={(params) => (
								<TextField
									{...params}
									color='secondary'
									className={classes.textField}
									label='Country *'
									error={formik.touched.country && formik.errors.country}
									helperText={formik.touched.country && formik.errors.country && formik.errors.country}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/>
						<Autocomplete
							name='state'
							id='state'
							className='mt-2'
							options={statesOption}
							loading={optionsLoading}
							disableClearable={true}
							openOnFocus
							noOptionsText={_.isEmpty(formik.values.country) ? 'Please choose a country' : 'No options'}
							getOptionLabel={(option) => option?.mastState || ''}
							value={formik?.values?.state}
							onChange={(e, value) => {
								formik?.setFieldValue('state', value || '')
								setCityParams((prevState) => ({ ...prevState, mastState: value?.mastState }))
							}}
							isOptionEqualToValue={(option, value) => option?.mastState === value}
							onInputChange={(e, newValue) => onStateSearch(e, newValue)}
							renderInput={(params) => (
								<TextField
									{...params}
									color='secondary'
									className={classes.textField}
									label='State *'
									error={formik.touched.state && formik.errors.state}
									helperText={formik.touched.state && formik.errors.state && formik.errors.state}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/>

						{/* <Autocomplete
							id='selectcityspeciality'
							name='selectcityspeciality'
							options={cityOptions}
							disableClearable={true}
							loadingText=' Please select Country first'
							openOnFocus
							noOptionsText={_.isEmpty(formik.values.country) ? 'Please select country' : 'No options'}
							autoHighlight
							getOptionLabel={(option) => option?.mastCities || ''}
							value={formik?.values?.city}
							onChange={(e, value) => {
								formik?.setFieldValue('city', value)
								setCityParams((prevState) => ({ ...prevState, search: value }))
							}}
							onInputChange={onCitySearch}
							renderInput={(params) => (
								<TextField
									{...params}
									color='secondary'
									className={classes.textField}
									label='City *'
									error={formik.touched.city && formik.errors.city}
									helperText={formik.touched.city && formik.errors.city && formik.errors.city}
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
						/> */}
						<Button type='submit' onClick={formik.handleSubmit} customStyle='btn w-full mx-auto my-16' variant='contained'>
							Continue
						</Button>
					</Grid>
				</form>
			</Grid>
			<Lookup setCountryOptions={setCountryOptions} setCityOptions={setCityOptions} countryParams={countryParams} cityParams={cityParams} />
		</>
	)
}

export default WelcomeCare
