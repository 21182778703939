import axios from 'axios'
import { API_ENDPOINTS } from '../../../constants'
import { csrf } from 'utils'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	AwardDetails: (uuid, userType) => {
		return axios.get(API_ENDPOINTS.GET_AWARDS, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	PostAwardDetails: (data, uuid, userType) => {
		return axios.post(`${API_ENDPOINTS.POST_AWARD}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	putAwardDetails: (data, uuid, userType) => {
		return axios.put(`${API_ENDPOINTS.PUT_AWARD}`, [...data], {
			headers: { isAuthRequired: true, 'X-SECURITY': csrf(), 'Content-Type': 'application/json', path: { userType, uuid } },
		})
	},
	deleteAward: (profileAwardUuid) => {
		return axios.delete(`${API_ENDPOINTS.DELETE_AWARD}`, {
			headers: { 'Content-Type': 'application/json', 'X-SECURITY': csrf(), isAuthRequired: true, path: { profileAwardUuid } },
		})
	},
}
