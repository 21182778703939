import React from 'react'
import { Typography, Avatar } from '@mui/material'
import { GroupAdd, Timeline, Groups, AccountBalance } from '@mui/icons-material'
import { currencyCode } from 'utils'
import useStyles from './style'
import { useDispatch, useSelector } from 'react-redux'
import history from '@history'
import { updateCurrentIndex } from 'services/sidemenu/action'

function Analytics({ dashboardData, isCareFlow }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const cardData = dashboardData
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)

	return (
		<div className={classes.root}>
			<Typography variant='h6'>Analytics</Typography>
			<div className={classes.cardContainer}>
				<div
					className={`${classes.card} ${classes.customerCard}`}
					onClick={() => {
						history.push('/clients')
						dispatch(updateCurrentIndex('clients'))
					}}
				>
					<div className={`${classes.cardTitle} ${classes.customerCardTitle}`}>
						<span>
							<Groups />
						</span>
						<Typography variant='body1'> {isCareFlow ? 'Patients' : 'Customers'}</Typography>
					</div>
					<div className={classes.cardContent}>
						<Typography variant='subtitle1'>{!!cardData?.Customers?.TotalCustomer ? cardData?.Customers?.TotalCustomer : 0}</Typography>
						<div className={classes.cardContentRight}>
							<Avatar
								alt='increase or decrease icon'
								sx={{ height: 24, width: 24 }}
								variant='square'
								src={
									cardData?.Customers?.symb === '+'
										? `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/increase.svg`
										: `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/decrease.svg`
								}
							/>
							<Typography variant='body2' className={cardData?.Customers?.symb === '+' ? classes.positiveResult : classes.negativeResult}>
								<span>{(cardData?.Customers?.value || 0).toFixed(2)}% </span> {cardData?.Customers?.symb === '+' ? 'above' : 'below'} projected
							</Typography>
						</div>
					</div>
				</div>

				<div
					className={`${classes.card} ${classes.appointmentsCard}`}
					onClick={() => {
						history.push('/calendar')
						dispatch(updateCurrentIndex('calendar'))
					}}
				>
					<div className={`${classes.cardTitle} ${classes.appointmentCardTitle}`}>
						<span>
							<GroupAdd />
						</span>
						<Typography variant='body1'>Appointments</Typography>
					</div>
					<div className={classes.cardContent}>
						<Typography variant='subtitle1'>{!!cardData?.Appointments?.TotalValue ? cardData?.Appointments?.TotalValue : 0}</Typography>
						<div className={classes.cardContentRight}>
							<Avatar
								alt='increase or decrease icon'
								sx={{ height: 24, width: 24 }}
								variant='square'
								src={
									cardData?.Appointments?.symb === '+'
										? `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/increase.svg`
										: `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/decrease.svg`
								}
							/>
							<Typography variant='body2' className={cardData?.Appointments?.symb === '+' ? classes.positiveResult : classes.negativeResult}>
								<span>{(cardData?.Appointments?.value || 0).toFixed(2)}%</span> {cardData?.Appointments?.symb === '+' ? 'above' : 'below'} projected
							</Typography>
						</div>
					</div>
				</div>

				<div className={`${classes.card} ${classes.revenueCard}`}>
					<div className={`${classes.cardTitle} ${classes.revenueCardTitle}`}>
						<span>
							<AccountBalance />
						</span>
						<Typography variant='body1'>Revenue</Typography>
					</div>
					<div className={classes.cardContent}>
						<Typography variant='subtitle1'>{currencyCode(cardData?.Revenue?.TotalRevenue, currencyType)}</Typography>
						<div className={classes.cardContentRight}>
							<Avatar
								alt='increase or decrease icon'
								sx={{ height: 24, width: 24 }}
								variant='square'
								src={
									cardData?.Revenue?.symb === '+'
										? `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/increase.svg`
										: `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/decrease.svg`
								}
							/>
							<Typography variant='body2' className={cardData?.Revenue?.symb === '+' ? classes.positiveResult : classes.negativeResult}>
								<span>{(cardData?.Revenue?.value || 0).toFixed(2)}%</span> {cardData?.Revenue?.symb === '+' ? 'above' : 'below'} projected
							</Typography>
						</div>
					</div>
				</div>

				<div className={`${classes.card} ${classes.growthCard}`}>
					<div className={`${classes.cardTitle} ${classes.growthCardTitle}`}>
						<span>
							<Timeline />
						</span>
						<Typography variant='body1'>Growth</Typography>
					</div>
					<div className={classes.cardContent}>
						<Typography variant='subtitle1'>{cardData?.Growth?.totalGrowth.toFixed(2) || 0}%</Typography>
						<div className={classes.cardContentRight}>
							<Avatar
								alt='increase or decrease icon'
								sx={{ height: 24, width: 24 }}
								variant='square'
								src={
									cardData?.Growth?.symb === '+'
										? `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/increase.svg`
										: `${process.env.REACT_APP_IMAGEKIT_URL}/B2B_Flash/Icons/decrease.svg`
								}
							/>
							<Typography variant='body2' className={cardData?.Growth?.symb === '+' ? classes.positiveResult : classes.negativeResult}>
								<span>{cardData?.Growth?.growthValue || 0}%</span> {cardData?.Growth?.symb === '+' ? 'above' : 'below'} projected
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Analytics
