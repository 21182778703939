import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	newPlanMain: {
		padding: theme.spacing(2, 0, 0, 0),
	},
	cancelButton: {
		background: '#EFEFEF',
		color: 'black',
		paddingBlock: 4,
		paddingInline: 20,
		'&:hover': {
			background: '#EFEFEF',
			color: 'black',
		},
	},
}))
export default useStyles
