/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import {
	Grid,
	Typography,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	Chip,
	IconButton,
	Tooltip,
	InputAdornment,
	FormHelperText,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useState, useEffect, useCallback, useRef } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import { showMessage } from 'app/store/fuse/messageSlice'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded'
import ButtonComp from '../../../sharedComponents/Button'
import ProfileDetails from '../../../../services/ProfileSetup/profileDetails/api'
import { useSelector, useDispatch } from 'react-redux'
import LookupApi from '../../../../services/lookup/api'
import LanguageApi from '../../../../services/lookup/api'
import { showLoader, hideLoader } from 'services/loader/action'
import { decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import useStyles from './style'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SlotData from './SlotData'
import secureLocalStorage from 'react-secure-storage'
import AdvancedAutocomplete from '../../../sharedComponents/AdvancedAutocomplete'
import countryLookup from 'services/register/api'
import WelcomeCareapi from 'services/welcomeCare/api'
import fileUploadDetailsApi from 'services/fileupload/GetApi'
import sendUploadFileApi from 'services/fileupload/PostApi'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import themesConfig from 'app/fuse-configs/themesConfig'
import ProfileToolTip from 'app/sharedComponents/ProfileToolTip'
import CustomDropZone from '../StepperView/Dropzone2/dropzone'
import { FormatBold, FormatItalic, FormatStrikethrough } from '@mui/icons-material'
import RichTextEditor from 'app/sharedComponents/RichTextEditor/RichTextEditor'
import PlacesAutocomplete from 'app/sharedComponents/AddressSearchField'

function PersonalDetails({ moveNextTab, value, matches }) {
	const theme = useTheme()
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
	const [personalDetails, setPersonalDetails] = useState([])
	const dispatch = useDispatch()
	const bodyEditableRef = useRef(null)
	const [countryCodeList, setCountryCodeList] = useState([])
	const [statesOption, setStatesOption] = useState([])
	const [languageLoading, setLanguageLoading] = useState(false)
	const [isEmptyCheck, SetIsEmptyCheck] = useState(null)
	const [establishmentFileResponse, setEstablishmentFileResponse] = useState()
	const [fileCategoryList, setFileCategoryList] = useState([])
	const [availbleuserName, setAvailableuserName] = useState(null)
	const profileHeaderUuid = useSelector((state) => state?.profileSetup?.profileStatus?.profileHeaderUuid)
	const [userNameEditBack, setuserNameEditBack] = useState(null)
	const [enableUserNameClose, setUserNameClose] = useState(null)
	const countryName = useSelector((state) => state?.dynamicMenu?.userData?.countryName)
	const [minMobileLen, setMinMobileLen] = useState([])
	const [maxMobileLen, setMaxMobileLen] = useState([])
	const state = useSelector((state) => state.Authentication)
	const individualDetails = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = individualDetails
	const [hideForm, setHideForm] = useState(false)
	const { uuid, mastuuid, phoneNumberLength } = state
	const countryCode = useSelector((state) => state?.GroupUser?.profileTentuserDetails?.tentUserCountryCode)
	const editCountryCode = useSelector((state) => state?.profileSetup?.profileStatus?.countryCode)
	const isEditMode = useSelector((state) => state?.profileSetup?.profileStatus?.isEditMode)
	const [language, setLanguage] = useState([])
	const [languageChip, setLanguageChip] = useState([])
	const cancelExecutor = useRef()
	const classes = useStyles({ availbleuserName })
	const errorJson = useSelector((state) => state?.errorMessages)
	// const profilePic = secureLocalStorage.getItem('imgUuid')

	const onSubmit = () => {
		updatePracticeDetails()
	}
	const formik = useFormik({
		initialValues: {
			name: personalDetails?.tentUserFirstName || '',
			salutation: personalDetails?.tentUserSalutation || '',
			userName: personalDetails?.userName || '',
			country: null,
			gender: personalDetails?.tentUserGender || '',
			state: null,
			address: personalDetails?.address || '',
			pincode: personalDetails?.pincode || '',
			about: '',
			language: [],
			emailAddress: [],
			phoneNumber: [],
			tentUserCountryCode: {},
			tentSecCountryCode: {},
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			name: yup
				.string()
				.trim('The contact name cannot include leading and trailing spaces')
				.strict(true)
				.max(100, 'Maximum 100 characters allowed')
				.required('Name is required'),
			userName: yup
				.string()
				.trim('The user name cannot include leading and trailing spaces')
				.strict(true)
				.max(25, 'Maximum 25 characters allowed')
				.min(3, 'Minimum 3 characters required')
				.required('user name is required'),
			country: yup.object().nullable().required('Please select any one of the country'),
			gender: yup.string().required('Select one value'),
			address: yup.string().max(500, 'Maximum 500 characters allowed').required('Please provide your address'),
			state: yup.object().nullable().required('Please select any one of the state'),
			pincode: yup
				.string()
				// .required('Please enter the pincode')
				.matches(/^[0-9\s]+$/, 'Only numbers are allowed')
				.min(3, 'Pincode should be minimum 3 numbers only')
				.max(6, 'Pincode should be maximum 6 numbers only'),
			about: yup.string().nullable().required('Please enter about me'),
			language: yup.array().nullable().required('Please select one language'),
			emailAddress: yup
				.array()
				.of(
					yup.object().shape({
						emailId: yup.string().email('You must enter a valid email').required('You must enter a email'),
					})
				)
				.required('Required'),
			phoneNumber: yup
				.array()
				.of(
					yup.object().shape({
						phone: yup
							.string()
							.required('Please enter the Phone Number')
							.matches(/^([1-9][0-9]*)?$/, 'Enter Valid Number')
							.test(function (value) {
								const { path, createError } = this
								const arrayNotation = path.split('.')[0]
								const indexString = arrayNotation.replace(/^[^\[]+\[/, '').replace(/\]$/, '')
								const index = parseInt(indexString, 10)
								const minLength = Number(minMobileLen[index]) || 10
								const maxMobileLen = 15

								if (!value) {
									return createError({
										message: `Please enter the Phone Number`,
										path: this.path,
									})
								} else if (value?.length < minLength) {
									return createError({
										message: `Mobile number must be at least ${minLength} characters`,
										path: this.path,
									})
								} else if (value?.length > maxMobileLen) {
									return createError({
										message: `Mobile number must be ${maxMobileLen} characters`,
										path: this.path,
									})
								}
								return true
							}),
						// .min(minMobileLen, `Mobile number must be at least ${minMobileLen} characters`)
						// .max(maxMobileLen, `Mobile number must be ${maxMobileLen} characters`),
					})
				)
				.required('Required'),
		}),
		onSubmit: onSubmit,
	})

	const [countryParams, setCountryParams] = useState({
		limit: 50,
		offset: 1,
		mastLookupType: 'CNT',
		search: '',
	})
	const onCountrySearch = _.debounce(
		(e, value) => {
			setCountryParams({
				...countryParams,
				search: value,
			})
		},
		[300]
	)
	const [stateParams, setStateParams] = useState({
		limit: 50,
		offset: 1,
		mastCountryCode: '',
		search: '',
	})

	const [languageParams, setLanguageParams] = useState({
		limit: 50,
		offset: 1,
		search: 'English',
	})
	const onLanguageSearch = _.debounce(
		(e, value) => {
			setLanguageParams({
				...languageParams,
				search: value,
			})
		},
		[300]
	)

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				setCountryCodeList(code)
			}
		}
		const onFailure = (err) => {
			setCountryCodeList([])
		}
		countryLookup.getCountryCode().then(onSuccess, onFailure)
	}, [])

	useEffect(() => {
		const onSuccess = (res) => {
			setStatesOption(res?.data?.data)
		}
		const onFailure = () => {
			setStatesOption([])
		}
		stateParams?.mastCountryCode && LookupApi.getLookup('citiesStates', { ...stateParams }, cancelExecutor).then(onSuccess, onFailure)
	}, [stateParams])

	const getLanguage = useCallback(() => {
		setLanguageLoading(true)
		const onSuccess = (res) => {
			setLanguageLoading(false)
			if (res?.data?.status === 'success') {
				setLanguage(res.data.data)
			} else {
				setLanguage([])
			}
		}

		const onFailure = (err) => {
			setLanguage([])
		}
		LanguageApi.getLookupNew('getLookup', { ...languageParams, mastLookupType: 'LAN' }, cancelExecutor).then(onSuccess, onFailure)
	}, [languageParams])

	const onLanguageDelete = (index) => {
		const newArray = [...languageChip]
		newArray.splice(index, 1)
		setLanguageChip(newArray)
	}

	const initialHit = useRef(false)
	const getPersonalDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				// console.log('setPersonalDetails', res?.data?.data)
				if (bodyEditableRef?.current) {
					bodyEditableRef.current.innerHTML = decodeURIComponent((res?.data?.data?.aboutUs && res?.data?.data?.aboutUs) || '')
				}
				formik?.setFieldValue('about', decodeURIComponent((res?.data?.data?.aboutUs && res?.data?.data?.aboutUs) || ''))
				setPersonalDetails(res?.data?.data)
				setAvailableuserName(!_.isEmpty(res?.data?.data?.userName) ? true : null)
				const domainNameParts = res?.data?.data?.userName
				const match = domainNameParts?.length > 1 ? domainNameParts[0] : ''
				setuserNameEditBack(match)
				setEstablishmentFileResponse(res?.data?.data?.tentUserProfilePic)
				let fetchCountry = _.find(countryCodeList, { country: res?.data?.data?.country?.country })
				setStateParams((prevState) => ({ ...prevState, mastCountryCode: res?.data?.data?.country?.countryCode }))
				res?.data?.data?.country?.country ? formik?.setFieldValue('country', fetchCountry) : formik?.setFieldValue('country', null)
				res?.data?.data?.state?.mastState ? formik?.setFieldValue('state', res?.data?.data?.state) : formik?.setFieldValue('state', null)
				setLanguageChip(
					res?.data?.data?.languagesKnown && !_.isEmpty(res?.data?.data?.languagesKnown) ? JSON.parse(res?.data?.data?.languagesKnown) : []
				)
				if (res?.data?.data?.tentUserEmailSecondary !== null) {
					formik?.setFieldValue(`emailAddress[${1}].emailId`, res?.data?.data?.tentUserEmailSecondary)
				}
				if (res?.data?.data?.tentUserEmail !== null) {
					formik?.setFieldValue(`emailAddress[${0}].emailId`, res?.data?.data?.tentUserEmail)
				}
				if (res?.data?.data?.tentUserPhoneSecondary !== null) {
					formik?.setFieldValue(`phoneNumber[${1}].phone`, res?.data?.data?.tentUserPhoneSecondary)
				}
				if (res?.data?.data?.tentUserPhone !== null) {
					formik?.setFieldValue(`phoneNumber[${0}].phone`, res?.data?.data?.tentUserPhone || '')
				}
				// if (res?.data?.data?.tentUserCountryCode !== null) {
				// 	formik?.setFieldValue(`tentUserCountryCode`, { mastLookupKey: res?.data?.data?.tentUserCountryCode } || '')
				// }
				// if (res?.data?.data?.tentSecCountryCode !== null) {
				// 	formik?.setFieldValue(`tentSecCountryCode`, { mastLookupKey: res?.data?.data?.tentSecCountryCode } || '')
				// }
			}
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.message, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let isTent = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
		ProfileDetails.getPracticeDetails(isTent).then(onSuccess, onFailure)
		// eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
	}, [countryCodeList, dispatch, errorJson, groupUserName, tentUserId, uuid])
	useEffect(() => {
		getPersonalDetails()
	}, [getPersonalDetails])

	useEffect(() => {
		if (initialHit.current === true) {
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialHit])

	const userNameCheck = () => {
		let body = {
			userName: formik?.values?.userName,
		}
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setAvailableuserName(res?.data?.data)
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ProfileDetails.getUserNameValidate(body, tentUserId).then(onSuccess, onFailure)
	}

	const displayEstablishmentImage = (acceptedFiles) => {
		dispatch(showLoader('Uploading image, please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'SUCCESS') {
				setEstablishmentFileResponse(res?.data?.data?.uuid)
			} else {
				setEstablishmentFileResponse()
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		let identityCategoryId = fileCategoryList?.find((data) => data.categoryName === 'CERTIFICATES')
		let categoryUuid = identityCategoryId?.uuid
		var formData2 = new FormData()
		formData2.append('FILE', acceptedFiles[0])
		sendUploadFileApi.sendUploadFile(mastuuid, uuid, categoryUuid, formData2).then(onSuccess, onFailure)
	}

	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === 'SUCCESS') {
				setFileCategoryList(res?.data?.data)
			} else {
				setFileCategoryList([])
			}
		}
		const onFailure = (err) => {
			setFileCategoryList([])
		}
		fileUploadDetailsApi.getFileUploadDetails(uuid).then(onSuccess, onFailure)
	}, [uuid])

	const updatePracticeDetails = () => {
		dispatch(showLoader('Loading please wait...'))
		const encodedFormikAbout = encodeURIComponent(formik?.values?.about) || null
		const body = {
			address: formik?.values?.address || '',
			state: formik?.values?.state || '',
			country: formik?.values?.country || {},
			pincode: formik?.values?.pincode || '',
			tentUserFirstName: formik?.values?.name || '',
			userName: formik?.values?.userName || '',
			tentUserEmail: formik?.values?.emailAddress[0]?.emailId || personalDetails.tentUserEmail,
			tentUserEmailSecondary: formik?.values?.emailAddress[1]?.emailId,
			// tentUserTertiaryMail: formik?.values?.emailAddress[1]?.emailId || personalDetails?.tentUserTertiaryMail,
			tentUserGender: formik?.values?.gender || '',
			tentUserPhone: formik?.values?.phoneNumber[0]?.phone || personalDetails.tentUserPhone,
			tentUserPhoneSecondary: formik?.values?.phoneNumber[1]?.phone,
			// tentUserTertiaryPhone: formik?.values?.phoneNumber[1]?.phone || personalDetails?.tentUserTertiaryPhone,
			isDelete: personalDetails.isDelete,
			progresStatus: personalDetails.progressStatus,
			profileDetailsUuid: personalDetails.profileDetailsUuid,
			tentMastUuid: mastuuid,
			parentId: null,
			tentUserUuid: tentUserId || '',
			tentUserId: personalDetails.tentUserId,
			tentUserBloodGroup: personalDetails.tentUserBloodGroup,
			tentUserCountryCode: formik?.values?.tentUserCountryCode?.mastLookupKey,
			profilePicEncryptedKey: personalDetails.profilePicEncryptedKey,
			accessZone: personalDetails.accessZone,
			mastRoleId: personalDetails.mastRoleId,
			tentUserSalutation: personalDetails.tentUserSalutation,
			tentUserRegistrationNum: personalDetails.tentUserRegistrationNum,
			tentUserProfilePic: establishmentFileResponse || personalDetails.tentUserProfilePic,
			tentUserDob: personalDetails.tentUserDob,
			tentUserLanguage: personalDetails.tentUserLanguage,
			tentSecCountryCode: !_.isEmpty(formik?.values?.phoneNumber[1]?.phone) ? formik?.values?.tentSecCountryCode.mastLookupKey : null,
			tentTertiaryCountryCode: personalDetails.tentTertiaryCountryCode,
			tentUserStatus: personalDetails.tentUserStatus,
			profileHeaderUuid: profileHeaderUuid,
			tentUserType: personalDetails.tentUserType,
			aboutUs: encodedFormikAbout || '',
			languagesKnown: JSON.stringify(_.map(languageChip, (item) => item?.mastLookupValue || item)),
		}
		// let decryptKey = encryption(body)
		const onSuccess = (res) => {
			// const successData = decryption(res)
			if (res?.data?.status === 'success') {
				dispatch(hideLoader())
				setEstablishmentFileResponse()
				getPersonalDetails()
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		ProfileDetails.updatePracticeDetails(body).then(onSuccess, onFailure)
	}

	useEffect(() => {
		getLanguage()
	}, [getLanguage])

	// useEffect(() => {
	// 	const onSuccess = (res) => {
	// 		if (res?.data?.status === true) {
	// 			let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
	// 			let code = _.orderBy(data, 'mastLookupKey', 'desc')
	// 			let initCode = _.find(code, { mastLookupKey: _.isEqual(isEditMode, true) ? editCountryCode : countryCode })
	// 			console.log('initCode', initCode)
	// 			setMinMobileLen(initCode?.minimumLength)
	// 			setMaxMobileLen(initCode?.mastLookupValue)
	// 		}
	// 	}
	// 	const onFailure = (err) => {
	// 		console.log(err)
	// 	}

	// 	countryLookup.getCountryCode().then(onSuccess, onFailure)

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [countryCode, editCountryCode, isEditMode, personalDetails])
	useEffect(() => {
		const onSuccess = (res) => {
			if (res?.data?.status === true) {
				let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
				let code = _.orderBy(data, 'mastLookupKey', 'desc')
				if (_.isEqual(isEditMode, true)) {
					if (personalDetails?.tentUserCountryCode !== null) {
						let initCode = _.find(code, { mastLookupKey: personalDetails?.tentUserCountryCode })
						formik?.setFieldValue(`tentUserCountryCode`, initCode || {})
						setMinMobileLen((prevState) => [initCode?.minimumLength, prevState[1]])
					}
					if (personalDetails?.tentSecCountryCode !== null) {
						let initCode = _.find(code, { mastLookupKey: personalDetails?.tentSecCountryCode })
						formik?.setFieldValue(`tentSecCountryCode`, initCode || {})
						setMinMobileLen((prevState) => [prevState[0], initCode?.minimumLength])
					}
				} else {
					let initCode = _.find(code, { mastLookupKey: countryCode })
					formik?.setFieldValue(`tentUserCountryCode`, initCode || {})
					formik?.setFieldValue(`tentSecCountryCode`, initCode || {})
				}
			}
		}
		const onFailure = (err) => {
			console.log(err)
		}

		countryLookup.getCountryCode().then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryCode, isEditMode, personalDetails?.tentSecCountryCode, personalDetails?.tentUserCountryCode])

	const handleFormatClick = (format) => {
		document.execCommand(format)
		bodyEditableRef.current.focus()
		// setContent(bodyEditableRef.current.innerHTML)
	}

	useEffect(() => {
		if (bodyEditableRef?.current?.innerText === '' || bodyEditableRef?.current?.innerText === '\n') {
			bodyEditableRef.current.innerHTML = ''
			formik?.setFieldValue('about', null)
		}
	}, [bodyEditableRef?.current?.innerText, formik.values.about])

	const handleAddressChange = (place) => {
		const selectedCountry = _.find(countryCodeList, { country: place?.country })
		if (selectedCountry) {
			formik?.setFieldValue('pincode', place?.postalCode)
			formik?.setFieldValue('country', selectedCountry)
			setCountryParams((prevState) => ({ ...prevState, search: selectedCountry?.country }))
			setStateParams((prevState) => ({ ...prevState, mastCountryCode: selectedCountry?.countryCode }))
			const params = {
				limit: 50,
				offset: 1,
				mastCountryCode: selectedCountry?.countryCode,
				search: '',
			}
			getStates(place?.state, params)
		} else {
			formik?.setFieldValue('pincode', '')
			formik?.setFieldValue('country', null)
			formik?.setFieldValue('state', null)
		}
	}

	const getStates = (state, params) => {
		const onSuccess = (res) => {
			const states = res.data.data
			const selectedState = _.find(states, { mastState: state })
			if (selectedState) {
				formik?.setFieldValue('state', selectedState)
			} else {
				formik?.setFieldValue('state', null)
			}
		}
		const onFailure = (err) => {
			console.log(err)
		}
		LookupApi.getLookup('citiesStates', { ...params }, cancelExecutor).then(onSuccess, onFailure)
	}

	return (
		<>
			<Grid container lg={10} spacing={2} justifyContent='center' className={classes.root}>
				{/* <Grid item className={classes.listItemCont}>
					<Typography>Please adhere to these guidelines while uploading the Profile Photo</Typography>
					<div className={classes.flexCenter}>
						<KeyboardArrowRightIcon />
						<Typography className={classes.listFont}>Do not use group Photos with sun glasses photos with pets</Typography>
					</div>
					<div className={classes.flexCenter}>
						<KeyboardArrowRightIcon />
						<Typography className={classes.listFont}>Do not expose contact details such as Mobile Number,email id and web URL </Typography>
					</div>
					<div className={classes.flexCenter}>
						<KeyboardArrowRightIcon />
						<Typography className={classes.listFont}>Do not Plagarise</Typography>
					</div>
				</Grid> */}
				{/* section2 */}
				<Grid item>
					<Grid container justifyContent='space-between' alignItems={'center'} spacing={2} className={classes.gridContainer}>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<CustomDropZone
								imgResponse={establishmentFileResponse}
								setEstablishmentFileResponse={setEstablishmentFileResponse}
								displayUploadImage={displayEstablishmentImage}
								dropzonestyle={classes.practiceImage}
								setImage={setEstablishmentFileResponse}
								dropType={'profile'}
								// isEdithide={editMode ? false : true}
								// setEditUuid={editMode ? () => setEditUuid(establishmentFileResponse) : ''}
							/>
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1'>Name *</Typography>
							<TextField
								variant='outlined'
								type='text'
								id='name'
								disabled={true}
								name='name'
								className={classes.textfield}
								color='secondary'
								size='small'
								value={formik.values.name}
								onChange={formik.handleChange}
								fullWidth
								placeholder='Enter name'
								InputProps={{
									className: classes.input,
									startAdornment: <InputAdornment position='start'>{formik?.values?.salutation}</InputAdornment>,
								}}
								error={formik.touched.name && formik.errors.name}
								helperText={formik.touched.name && formik.errors.name && formik.errors.name}
							/>
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1' className={classes.labels}>
								Gender*
							</Typography>
							<FormControl component='fieldset'>
								<RadioGroup name='gender' value={formik.values.gender} style={{ display: 'flex', flexDirection: 'row' }}>
									<FormControlLabel
										value='M'
										name='gender'
										control={<Radio size='small' classes={{ root: classes.radio, checked: classes.checked }} />}
										label='Male'
										onChange={(e, value) => formik.setFieldValue('gender', e.target.value)}
									/>
									<FormControlLabel
										value='F'
										name='gender'
										control={<Radio size='small' classes={{ root: classes.radio, checked: classes.checked }} />}
										label='Female'
										onChange={(e, value) => formik.setFieldValue('gender', e.target.value)}
									/>
								</RadioGroup>
							</FormControl>
							{formik.touched.gender && formik.errors.gender ? (
								<Typography className={classes.genderError} color='error'>
									{formik?.errors?.gender}
								</Typography>
							) : null}
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1'>Address*</Typography>
							{/* <TextField
								fullWidth
								placeholder='Address'
								size='small'
								type='text'
								color='secondary'
								className={classes.textfield}
								value={formik?.values?.address}
								onChange={formik.handleChange}
								name='address'
								id='address'
								error={formik.touched.address && formik.errors.address}
								helperText={formik.touched.address && formik.errors.address && formik.errors.address}
								inputProps={{
									autoComplete: 'new-password',
								}}
							/> */}
							<div className={classes.placeAutoComplete}>
								<PlacesAutocomplete
									value={formik.values.address}
									setValue={(value) => formik.setFieldValue('address', value)}
									onPlaceSelected={(place) => {
										handleAddressChange(place)
									}}
									error={formik.touched.address && Boolean(formik.errors.address)}
									helperText={formik.touched.address && formik.errors.address}
									onBlur={formik.handleBlur}
								/>
							</div>
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1' pb={1}>
								Country*
							</Typography>
							<Autocomplete
								id={`country`}
								name={`country`}
								options={countryCodeList}
								disableClearable
								autoHighlight
								openOnFocus
								getOptionLabel={(option) => option?.country || ''}
								value={formik?.values?.country}
								onChange={(e, value) => {
									formik?.setFieldValue(`country`, value)
									setCountryParams((prevState) => ({ ...prevState, search: value?.country }))
									setStateParams((prevState) => ({ ...prevState, mastCountryCode: value?.countryCode }))
									formik.setFieldValue(`state`, {})
								}}
								onInputChange={onCountrySearch}
								renderInput={(params) => (
									<TextField
										{...params}
										color='secondary'
										size='small'
										className={classes.textField}
										inputProps={{
											...params.inputProps,
										}}
										error={formik.touched.country && formik.errors.country}
										helperText={formik.touched.country && formik.errors.country}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1' pb={1}>
								State*
							</Typography>
							<Autocomplete
								name='state'
								id='state'
								options={statesOption}
								disableClearable={true}
								openOnFocus
								disablePortal={true}
								noOptionsText={_.isEmpty(formik.values.country) ? 'Please select country' : 'No options'}
								getOptionLabel={(option) => option?.mastState || ''}
								value={formik?.values?.state}
								onChange={(e, value) => {
									formik?.setFieldValue('state', value || {})
								}}
								getOptionSelected={(option, value) => {
									return option?.mastState === value?.mastState
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										color='secondary'
										size='small'
										className={classes.textField}
										error={formik.touched.state && formik.errors.state}
										helperText={formik.touched.state && formik.errors.state && formik.errors.state}
										inputProps={{
											...params.inputProps,
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={4} sm={12} className={classes.gridItems}>
							<Typography variant='body1'>Pincode</Typography>
							<TextField
								variant='outlined'
								type='text'
								id='Pincode'
								name='pincode'
								color='secondary'
								className={classes.textfield}
								value={formik.values.pincode}
								onChange={formik.handleChange}
								size='small'
								fullWidth
								placeholder='Enter pincode'
								inputProps={{ maxLength: 6 }}
								error={formik.touched.pincode && formik.errors.pincode}
								helperText={formik.touched.pincode && formik.errors.pincode && formik.errors.pincode}
							/>
						</Grid>
						<Grid item lg={8} sm={12} className={classes.gridItems}>
							<Typography variant='body1'>Profile URL*</Typography>
							<div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
								<TextField
									variant='outlined'
									type='text'
									id='userName'
									name='userName'
									color='secondary'
									className={classes.textfield}
									disabled={availbleuserName}
									value={formik.values.userName}
									onChange={formik.handleChange}
									size='small'
									fullWidth
									placeholder='Enter your profile URL'
									InputProps={{ className: classes.input }}
									error={formik.touched.userName && formik.errors.userName}
									helperText={formik.touched.userName && formik.errors.userName && formik.errors.userName}
								/>

								{/* {profileStatus?.progressStatus === 'LIV' ? (
								<Tooltip
									title='Once your domain name is live, updates cannot be made directly. Please contact LYFNGO Support for assistance in updating your domain information.'
									arrow
								>
									<HelpOutlineOutlinedIcon style={{ color: themesConfig.lyfngo.palette.primary.main }} />
								</Tooltip>
							) : ( */}
								{availbleuserName && (
									<Tooltip title='Edit' arrow>
										<IconButton
											onClick={() => {
												setAvailableuserName(null)
												setUserNameClose(true)
											}}
										>
											<EditNoteRoundedIcon />
										</IconButton>
									</Tooltip>
								)}
								{userNameEditBack && enableUserNameClose && (
									<Tooltip title='Close' arrow>
										<IconButton
											onClick={() => {
												setAvailableuserName(true)
												setUserNameClose(null)
											}}
										>
											<HighlightOffIcon style={{ color: 'red' }} />
										</IconButton>
									</Tooltip>
								)}
								<Typography
									variant='body1'
									className={classes.eligibleColor}
									onClick={() => {
										if (availbleuserName === true) {
										} else if (availbleuserName === false) {
											userNameCheck()
										} else {
											userNameCheck()
										}
									}}
								>
									{availbleuserName === true ? 'user name Available' : availbleuserName === false ? 'user name already taken' : 'Check eligible'}
									<ProfileToolTip
										titleName={`Check the availability of your user name instantly! Simply enter your desired user name, and we'll swiftly assess its availability in real-time. Secure your online identity with ease and make sure your perfect user name is ready for the taking.`}
									>
										{availbleuserName === true ? (
											<CheckCircleRoundedIcon style={{ color: 'green' }} />
										) : availbleuserName === false ? (
											<HelpOutlineOutlinedIcon style={{ color: 'red' }} />
										) : (
											<HelpOutlineOutlinedIcon style={{ color: themesConfig.lyfngo.palette.primary.main }} />
										)}
									</ProfileToolTip>
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid item pt={2}>
					<Typography variant='h6' style={{ marginBottom: '14px' }}>
						Contact Details
					</Typography>
					{/* <div className={classes.skincontent}>
						<Typography variant='subtitle'>
							This will be only visible to you.only Lyfngo use this to update you an anything important .Greyed out details are as same your account
							information & can be edited.
						</Typography>
					</div> */}
				</Grid>
				<SlotData
					formik={formik}
					data={personalDetails || {}}
					isSmall={isSmall}
					hideForm={hideForm}
					setHideForm={setHideForm}
					countryCode={_.isEqual(isEditMode, true) ? editCountryCode : countryCode}
					maxMobileLen={maxMobileLen}
					minMobileLen={minMobileLen}
					phoneNumberLength={phoneNumberLength}
					countryCodeList={countryCodeList}
					setMinMobileLen={setMinMobileLen}
					setMaxMobileLen={setMaxMobileLen}
				/>
				<Grid container className={classes.emailandPhone}>
					<Grid item lg={12} sm={12}>
						<div className='' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant='h6'>About me*</Typography>
							{/* <div className=''>
								<Tooltip title='Bold'>
									<IconButton onClick={() => handleFormatClick('bold')}>
										<FormatBold style={{ color: '#0062DD' }} />
									</IconButton>
								</Tooltip>
								<Tooltip title='Italic'>
									<IconButton onClick={() => handleFormatClick('italic')}>
										<FormatItalic style={{ color: '#0062DD' }} />
									</IconButton>
								</Tooltip>
							</div> */}
						</div>
						{/* <TextField
							placeholder='Add description here...'
							id='about'
							name='about'
							type='text'
							value={formik.values.about}
							onChange={formik.handleChange}
							error={formik.touched.about && formik.errors.about}
							helperText={formik.touched.about && formik.errors.about && formik.errors.about}
							color='secondary'
							fullWidth
							multiline
							rows={4}
							rowsMax={4}
						/> */}
						<div className={classes.bodyField}>
							<TextField
								multiline
								color='secondary'
								fullWidth
								variant='outlined'
								label='Multiline Text'
								onFocus={true}
								onBlur={true}
								// value={formik?.values?.WriteAbout}
								sx={{ display: 'none' }}
							/>
							{/* <div
								ref={bodyEditableRef}
								contentEditable={true}
								style={{
									border: '1px solid #ccc',
									minHeight: '100px',
									padding: '8px',
									lineHeight: '1.6',
									overflowY: 'auto',
									borderRadius: 4,
									outline: 'none', // Remove the default focus outline
									width: '100%',
								}}
								tabIndex={1}
								onInput={() => {
									const newContent = bodyEditableRef.current.innerHTML
									// handleContentChange(newContent)
									// setContent(newContent)
									formik?.setFieldValue('about', newContent)
								}}
								// dangerouslySetInnerHTML={{ __html: formik?.values?.WriteAbout }}
							></div> */}
							<RichTextEditor value={formik?.values?.about} image={true} onChange={(content) => formik?.setFieldValue('about', content)} />
							<FormHelperText className={classes.errorMessage}>{formik.touched.about && formik.errors.about && formik.errors.about}</FormHelperText>
						</div>
					</Grid>
				</Grid>
				<Grid container className={classes.languagePreffered} pt={2}>
					<Grid item lg={6} md={6} sm={12}>
						<Typography variant='caption' className={classes.headerText} style={{ marginBottom: '14px' }}>
							Preferred language
						</Typography>
						<Box mb={0.2}>
							{languageChip.map((item, index) => (
								<Chip
									key={index}
									size='medium'
									className={classes.chip}
									label={item?.mastLookupValue || item}
									onDelete={() => onLanguageDelete(index)}
								/>
							))}
						</Box>
						<Autocomplete
							loading={languageLoading}
							loadingText='Loading...'
							multiple
							id='language'
							name='language'
							disableClearable={true}
							className={classes.months}
							options={language}
							getOptionLabel={(option) => option?.mastLookupValue || ''}
							value={languageChip}
							disabled={languageChip?.length > 4 ? true : false}
							filterSelectedOptions={true}
							disableCloseOnSelect={true}
							onChange={(e, value) => {
								formik?.setFieldValue('language', value)
								setLanguageChip(value)
							}}
							onInputChange={onLanguageSearch}
							renderTags={() => null}
							renderInput={(params) => (
								<TextField
									{...params}
									color='secondary'
									placeholder='Select language'
									disabled={languageChip?.length > 4 ? true : false}
									size='small'
									error={formik.touched.language && formik.errors.language}
									helperText={formik.touched.language && formik.errors.language && formik.errors.language}
									variant='outlined'
								/>
							)}
						/>
					</Grid>
				</Grid>
				{/* </form> */}
			</Grid>
			<div class={classes.buttonMain}>
				<ButtonComp style={{ visibility: 'hidden' }}>Back</ButtonComp>
				<ButtonComp
					onClick={(e) => {
						formik.handleSubmit(e)
					}}
					disabled={availbleuserName !== true}
				>
					Save & Next
				</ButtonComp>
			</div>
		</>
	)
}
export default PersonalDetails
