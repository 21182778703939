import { ACTION_TYPES } from '../../../constants'

const initialState = {
	followDuration: '',
	followOption: '',
	durationType: '',
}

const verificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FOLLOW_DURATION: {
			return {
				...state,
				followDuration: action.payload,
			}
		}
		case ACTION_TYPES.FOLLOW_OPTION: {
			return {
				...state,
				followOption: action.payload,
			}
		}
		case ACTION_TYPES.FOLLOWDURATION_TYPE: {
			return {
				...state,
				durationType: action.payload,
			}
		}
		default:
			return state
	}
}

export default verificationReducer
