import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiAutocomplete-root': {
			'& .MuiOutlinedInput-root': {
				paddingBlock: 8,
				borderRadius: 10,
			},
		},
	},
	normalTextFeild: {
		'& .MuiOutlinedInput-root': {
			paddingBlock: 2,
			borderRadius: 10,
		},
	},
	patId: {
		fontSize: 14,
		marginLeft: 6,
		'& span': {
			color: '#0062dd',
			marginLeft: 12,
			fontWeight: '600',
		},
		display: 'flex',
		alignItems: 'center',
	},
	paymentMandatory: {
		background: '#F4F4F4',
		display: 'flex',
		alignItems: 'center',
		marginLeft: 14,
		borderRadius: 6,
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
		'& p': {
			display: 'flex',
			fontSize: 12,
			width: 50,
			paddingBlock: 4,
			justifyContent: 'center',
		},
	},
	paymentTypeBox: {
		marginLeft: 4,
		fontSize: 14,
		'& .MuiFormGroup-root': {
			flexDirection: 'row',
			gap: 18,
			'& .MuiFormControlLabel-root': {
				'& .Mui-checked': {
					color: '#0062DD',
				},
				'& .Mui-disabled': {
					color: '#B3B3B3',
				},
			},
		},
	},
	dateSelect: {
		marginBlock: 10,
		'& .MuiOutlinedInput-root': {
			paddingBlock: 3,
			borderRadius: 10,
		},
	},
	mobileField: {
		'& .MuiOutlinedInput-root': {
			paddingBlock: 2,
			borderRadius: 10,
		},
	},
	clients_list_item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	circular_progress: {
		color: themesConfig.greyDark.palette.primary.main,
	},

	togglebtngrp: {
		marginInlineStart: 18,
		'& .MuiButtonBase-root': {
			paddingBlock: 2,
		},
		'& .Mui-disabled': {
			backgroundColor: '#0062DD',
			paddingBlock: 2,
			color: '#fff',
			'&:hover': {
				backgroundColor: '#0062DD',
			},
		},
		'& .Mui-selected': {
			backgroundColor: '#0062DD',
			paddingBlock: 2,
			color: '#fff',
			'&:hover': {
				backgroundColor: '#0062DD',
			},
		},
		'& .Mui-disabled': {
			backgroundColor: '#E5E8E8',
			paddingBlock: 2,
			color: '#000',
		},
	},
	activeMem: {
		'& .MuiTypography-h6': {
			fontSize: 15,
		},
	},
	membershipList: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 12,
		marginBlockStart: 12,
		'& .MuiTypography-body2': {
			fontSize: 14,
			background: '#E0FFD9',
			paddingBlock: 5,
			paddingInline: 20,
		},
	},
	meetingLinkContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))
