import { makeStyles } from '@mui/styles'
import { InputLabel, MenuItem, FormControl, Select, Tooltip, Button, Avatar } from '@mui/material'
import { RestartAlt } from '@mui/icons-material'
import themesConfig from 'app/fuse-configs/themesConfig'
import { useState, useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'services/loader/action'
import UserManagementApi from 'services/Settings/UserManagement/api'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import ReportsData from '../../../../services/Reports/api'
import { showMessage } from 'app/store/fuse/messageSlice'
import SingleDatePicker from 'app/sharedComponents/SingleDatePicker'
import DateRangePick from './DateRangePick'
import Statistics from './Statistics'
import Filters from './Filters'
import SummaryTable from './SummaryTable'
import PrintComponent from './PrintComponent'
import MailModal from './MailModal'
import moment from 'moment'
import _ from 'lodash'
import { customDateFormat, decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import xero from '../../../../images/Integration/xero.png'
import XeroDialog from '../../../sharedComponents/XeroDialog'
import ZohoDialog from '../../../sharedComponents/ZohoDialog'
import ModuleTitle from 'app/sharedComponents/ModuleTitle'
import CustomIconButton from 'app/sharedComponents/CustomIconButton'
import CustomFilterButton from 'app/sharedComponents/CustomFilterButton'
import { usePermissions } from 'hooks/usePermissions'
import { FEATURES_LIST, PERMISSION_TYPE, ROLE_MODULES } from '../../../../constants'
import { initialFilters, initialOptions, optionsReducer, selectedFiltersReducer } from './reducers/reducers'

const useStyles = makeStyles((theme) => ({
	root: {},
	headerContainer: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		paddingInline: 20,
		paddingBlock: 12,
		position: 'sticky',
		top: 64,
		zIndex: 50,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.up('xs')]: {
			top: 48,
		},
		[theme.breakpoints.up('md')]: {
			top: 64,
		},
	},

	headerRightSide: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'flex-end',
		[theme.breakpoints.up('xs')]: {
			gap: 14,
			paddingInlineStart: 18,
		},
		[theme.breakpoints.up('sm')]: {
			gap: 18,
			paddingInlineStart: 'unset',
		},
	},
	headerIcon: {
		background: '#FFFFFF25 ',
		borderRadius: '50%',
		padding: 8,
		'& .MuiSvgIcon-root': {
			color: '#FFFFFF',
			fontSize: 18,
		},
	},
	resetButton: {
		borderRadius: '12%',
		'& .MuiSvgIcon-root': {
			color: '#FFFFFF',
			fontSize: 20,
		},
		[theme.breakpoints.up('xs')]: {
			paddingInline: 8,
		},
		[theme.breakpoints.up('sm')]: {
			paddingInline: 16,
		},
	},
	headerIconActive: {
		background: '#FFFFFF64 ',
	},
	container: {
		margin: 10,
		background: '#FFFFFF ',
		borderRadius: 16,
	},
	sectionOne: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',

		[theme.breakpoints.up('xs')]: {
			paddingInline: 16,
			paddingBlock: 16,
			gap: 18,
		},
		[theme.breakpoints.up('md')]: {
			paddingInline: 20,
			paddingBlockEnd: 20,
			paddingBlockStart: 48,
			gap: 20,
		},

		'& .MuiFormControl-root': {
			'& .Mui-focused': {
				color: 'inherit',
			},
		},
	},
	selectReport: {
		[theme.breakpoints.up('xs')]: {
			maxWidth: 168,
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: 240,
		},
	},
	type: {
		[theme.breakpoints.up('xs')]: {
			maxWidth: 100,
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: 140,
		},
	},

	xeroUploadButton: {
		marginRight: 12,
		backgroundColor: '#FFFFFF',
		borderRadius: 25,
		color: '#535353',
		border: 'none',
		width: 'auto',
		'&:hover': {
			background: '#FFFFFF',
			border: 'none',
		},
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
			color: '#fff',
		},
		[theme.breakpoints.up('xs')]: {
			whiteSpace: 'pre',
		},
	},
}))

function ReportPage() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.Authentication)
	const erroJson = useSelector((state) => state.errorMessages)
	const { currentModule } = useSelector((state) => state?.dynamicMenu || {})
	const { uuid, mastuuid } = state
	const states = useSelector((state) => state.Authentication)
	const { xeroStatus, zohoStatus } = states
	const dynamicMenu = useSelector((state) => state?.dynamicMenu || {})
	const { userRole, groupName } = dynamicMenu?.userData
	const isOwner = _.isEqual(userRole, 'Owner')
	const isDoctor = _.isEqual(userRole, 'Doctor')
	const isAdmin = _.isEqual(userRole, 'Admin')
	const isCare = _.isEqual(groupName, 'health care')
	const isFitness = _.isEqual(groupName, 'fitness')
	const isMind = _.isEqual(groupName, 'wellness')
	const isSport = _.isEqual(groupName, 'sports')
	const isSpa = _.isEqual(groupName, 'spa')
	const allAccessTypeUser =
		_.isEqual(userRole, 'Owner') || _.isEqual(userRole, 'Admin') || _.isEqual(userRole, 'Receptionist') || _.isEqual(userRole, 'Staff')
	const practicelist = useSelector((state) => state.dynamicMenu?.practiceListGlobal)
	const [practiceName, setPracticeName] = useState('')
	const dateFormatForViewer = useSelector((state) => state?.dynamicMenu?.userData?.dateFormatForViewer)
	const [dateForPrint, setDateForPrint] = useState(`${customDateFormat(new Date(), dateFormatForViewer)}`)
	const [filter, setFilter] = useState(true)
	const [pageData, setPageData] = useState({})
	const [resetTriggred, setResetTriggred] = useState(false)
	const [date, setDate] = useState([`${moment().format('YYYY-MM-DD')}`])
	const [openXeroDialog, setOpenXeroDialog] = useState(false)
	const [openZohoDialog, setOpenZohoDialog] = useState(false)
	const [printColoumn, setPrintColoumn] = useState([])
	const [printRow, setPrintRow] = useState([])
	const [appliedFiltersForprint, setAppliesFiltersForprint] = useState({})
	const [allUsersList, setAllUsersList] = useState([])
	const [selectedProducts, setSelectedProducts] = useState([])
	const [selectedPractice, setSelectedPractice] = useState([])

	const [selectableOptions, updateOptions] = useReducer(optionsReducer, initialOptions)
	const [selectedFilters, updateSelectedFilters] = useReducer(selectedFiltersReducer, initialFilters)

	// const [pageSize, setPageSize] = useState(10)
	// const [params, setParams] = useState({ size: 10, page: 0 })

	const newPatientsPerms = usePermissions(ROLE_MODULES.REPORT, FEATURES_LIST.REPORT_NEW_CLIENTS)
	const appointmentsPerms = usePermissions(ROLE_MODULES.REPORT, FEATURES_LIST.REPORT_APPOINTMENTS)
	const invoicedDetailsPerms = usePermissions(ROLE_MODULES.REPORT, FEATURES_LIST.REPORTS_INVOICED_DETAIL)
	const distributorPerms = usePermissions(ROLE_MODULES.REPORT, FEATURES_LIST.REPORT_DISTRIBUTOR)
	const membershipPerms = usePermissions(ROLE_MODULES.REPORT, FEATURES_LIST.REPORT_MEMBERSHIP)

	const [pageQuery, setPageQuery] = useUrlSearchParams(
		{
			report: '',
			category: 'procedure',
			doctor: '',
			distributor: '',
			cancelled: false,
			discount: 'all',
			bloodGroup: '',
			referredBy: '',
			patientGroup: '',
			showAll: 'all',
			procedureCategories: '',
			paymentVendor: '',
			taxes: '',
			appointmentCategories: '',
			quickSales: '',
			status: 'paid',
			paymentsOnType: 'Billed date',
			discountType: '',
			tierLevel: '',
		},
		{
			report: String,
			category: String,
			doctor: String,
			distributor: String,
			cancelled: Boolean,
			discount: String,
			bloodGroup: String,
			referredBy: String,
			patientGroup: String,
			showAll: String,
			procedureCategories: String,
			paymentVendor: String,
			taxes: String,
			appointmentCategories: String,
			quickSales: String,
			status: String,
			paymentsOnType: String,
			discountType: String,
			tierLevel: String,
		}
	)

	const report = pageQuery?.report
	const category = pageQuery?.category

	// Report Types:
	const ReportTypes = [
		{ type: 'NewPatients', label: `New ${isCare ? 'patients' : 'clients'}`, show: _.get(newPatientsPerms, PERMISSION_TYPE.VIEW, false) },
		{ type: 'Distributor', label: `Distributor`, show: _.get(distributorPerms, PERMISSION_TYPE.VIEW, false) },
		{ type: 'Appointments', label: `Appointments`, show: _.get(appointmentsPerms, PERMISSION_TYPE.VIEW, false) },
		{ type: 'Membership', label: `Membership`, show: _.get(membershipPerms, PERMISSION_TYPE.VIEW, false) },
		{ type: 'InvoicedDetail', label: `Invoiced detail`, show: _.get(invoicedDetailsPerms, PERMISSION_TYPE.VIEW, false) },
	]

	useEffect(() => {
		if (!_.isEmpty(dynamicMenu)) {
			let currentReportType = pageQuery?.report
			if (_.isEmpty(currentReportType)) {
				let permittedFirstReport = ReportTypes.find((item) => Boolean(item?.show))

				if (!_.isEmpty(permittedFirstReport)) {
					setPageQuery({ ...pageQuery, report: permittedFirstReport?.type })
				}
				setResetTriggred(true)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ReportTypes, dynamicMenu])

	// Practice List Data on the Header
	useEffect(() => {
		setPageQuery({ ...pageQuery, report: '' })

		if (!_.isEmpty(practicelist)) {
			let currentPractice = _.find(practicelist, { masterTenantUuid: mastuuid, tentUserUuid: uuid })
			setPracticeName(currentPractice || {})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, practicelist, uuid])

	const handleDateChange = (dateFromPicker) => {
		setDate(dateFromPicker)
	}

	const handleDateChangeForPrint = (dateFromPicker) => {
		setDateForPrint(dateFromPicker)
	}

	const resetPageQuery = () => {
		setPageQuery({
			doctor: allAccessTypeUser ? '' : pageQuery?.doctor,
			cancelled: false,
			discount: 'all',
			bloodGroup: '',
			referredBy: '',
			patientGroup: '',
			showAll: 'all',
			procedureCategories: '',
			paymentVendor: '',
			taxes: '',
			appointmentCategories: '',
			distributor: userRole === 'Distributor' || userRole === 'Agent' ? pageQuery?.distributor : '',
			tierLevel: '',
		})
		setResetTriggred(true)
		setSelectedPractice([])
		setSelectedProducts([])
	}

	const onQueryChange = (data) => {
		setPageQuery(data)
		setResetTriggred(false)
	}

	useEffect(() => {
		dispatch(showLoader('Applying filters please wait...'))
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'SUCCESS') {
				dispatch(hideLoader())
				setPageData(successData?.data)
			} else if (successData?.status === 'FAILURE') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(successData, erroJson), //text or html
						autoHideDuration: 3000, //ms
						anchorOrigin: {
							vertical: 'top', //top bottom
							horizontal: 'right', //left center right
						},
						variant: 'error', //success error info warning null
					})
				)
			}
		}

		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			setPageData({})
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, erroJson), //text or html
					autoHideDuration: 3000, //ms
					anchorOrigin: {
						vertical: 'top', //top bottom
						horizontal: 'right', //left center right
					},
					variant: 'error', //success error info warning null
				})
			)
		}

		switch (report) {
			case 'NewPatients':
				{
					let body = {
						mast_tent_uuid: mastuuid,
						...(!_.isEmpty(pageQuery.referredBy) && { cust_referred_by: pageQuery.referredBy.split(',') }),
						...(!_.isEmpty(pageQuery.patientGroup) && { group_uuid: pageQuery.patientGroup.split(',') }),
						...(!_.isEmpty(pageQuery.bloodGroup) && { cust_blood_group: pageQuery.bloodGroup }),
						...(date?.length === 2 ? { fromDate: date[0], toDate: date[1] } : { date: date[0] }),
					}
					let decryptKey = encryption(body)
					ReportsData.getReportData('CUSTOMERS', decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
				}
				break
			case 'Appointments':
				{
					let body = {
						mast_tent_uuid: mastuuid,
						is_canceled: pageQuery?.cancelled,
						...(!_.isEmpty(pageQuery.doctor) && { tent_user_uuid: pageQuery.doctor.split(',') }),
						...(!_.isEmpty(pageQuery.appointmentCategories) && { category_uuid: pageQuery.appointmentCategories.split(',') }),
						...(date?.length === 2 ? { fromDate: date[0], toDate: date[1] } : { date: date[0] }),
					}

					let decryptKey = encryption(body)
					ReportsData.getReportData('APPOINTMENT', decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
				}
				break
			case 'InvoicedDetail':
				{
					let selectedQuickSaleOptions = !_.isEmpty(pageQuery?.quickSales) ? pageQuery?.quickSales.split(',') : []
					let body = {
						mast_tent_uuid: mastuuid,
						...(_.isEqual(pageQuery?.paymentsOnType, 'Billed date')
							? date?.length === 2
								? { paymentFromDate: date[0], paymentToDate: date[1] }
								: { paymentDate: date[0] }
							: date?.length === 2
							? { originFromDate: date[0], originToDate: date[1] }
							: { originDate: date[0] }),
						...(!_.isEmpty(pageQuery.doctor) && { tent_user_uuid: pageQuery.doctor.split(',') }),
						...(!_.isEmpty(pageQuery.referredBy) && { cust_referred_by: pageQuery.referredBy.split(',') }),
						...(_.isEqual(pageQuery.discount, 'moreThanZero') && { cust_billing_discount: '1' }),
						...(_.isEqual(pageQuery.discount, 'isZero') && { cust_billing_discount: '0' }),
						...(!_.isEmpty(pageQuery.patientGroup) && { group_uuid: pageQuery.patientGroup.split(',') }),
						...(!_.isEmpty(pageQuery.paymentVendor) && { payment_mode_uuid: pageQuery.paymentVendor.split(',') }),
						...(!_.isEmpty(pageQuery.taxes) && { tax_uuid: pageQuery.taxes.split(',') }),

						category: pageQuery?.category,
						is_cancelled: selectedFilters.paymentCancelled?.value,
						invoiceDetailsDTOS: {
							comp_procedure_uuid:
								category === 'all' || category === 'procedure'
									? !_.isEmpty(pageQuery.procedureCategories)
										? pageQuery.procedureCategories.split(',')
										: []
									: [],
							is_qmembership:
								category === 'all' || category === 'quickSale'
									? _.isEmpty(selectedQuickSaleOptions)
										? true
										: selectedQuickSaleOptions.includes('membership')
									: false,
							is_qproduct:
								category === 'all' || category === 'quickSale'
									? _.isEmpty(selectedQuickSaleOptions)
										? true
										: selectedQuickSaleOptions.includes('product')
									: false,
							is_qservice:
								category === 'all' || category === 'quickSale'
									? _.isEmpty(selectedQuickSaleOptions)
										? true
										: selectedQuickSaleOptions.includes('service')
									: false,
							is_procedure: category === 'all' || category === 'procedure' ? true : false,
							is_pharma: category === 'all' || category === 'pharmaSales' ? true : false,
							is_membership: category === 'all' || category === 'Membership' ? true : false,
							pharma_status: category === 'all' || category === 'pharmaSales' ? pageQuery?.status : null,
							procedure_payment_status: category === 'all' || category === 'procedure' ? pageQuery?.status : null,
							qstatus: category === 'all' || category === 'quickSale' ? pageQuery?.status : null,
							membership_status: category === 'all' || category === 'Membership' ? pageQuery?.status : null,
						},
					}
					let decryptKey = encryption(body)
					ReportsData.getReportData('INVOICEDDETAILS', decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
				}
				break
			case 'Distributor':
				{
					let body = {
						mast_tent_uuid: mastuuid,
						...(date?.length === 2 ? { fromDate: date[0], toDate: date[1] } : { date: date[0] }),
						// distributorUuid is given as tent_user_uuid
						tent_user_uuid: !_.isEmpty(pageQuery?.distributor) ? pageQuery?.distributor?.split(',') : [],
						commission_uuid: !_.isEmpty(pageQuery?.tierLevel) ? pageQuery?.tierLevel?.split(',') : [],
						product_uuid: selectedProducts?.map((product) => product?.uuid),
						tent_uuid: selectedPractice?.map((practice) => practice?.masterTenantUuid),
						is_cancelled: selectedFilters.paymentCancelled?.value,
						billing_module_type:
							category === 'all'
								? null
								: category === 'procedure'
								? 'emr'
								: category === 'pharmaSales'
								? 'pharma'
								: category === 'Membership'
								? 'membership'
								: category === 'quickSale'
								? 'quicksale'
								: null,
						is_paid: pageQuery?.status === 'paid' ? true : false,
						group_uuid: !_.isEmpty(pageQuery.patientGroup) ? pageQuery.patientGroup.split(',') : [],
					}

					let decryptKey = encryption(body)

					ReportsData.getReportData('DISTRIBUTOR', decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
				}
				break
			case 'Membership':
				{
					let body = {
						mast_tent_uuid: mastuuid,
						tent_membership_uuid: selectedFilters?.membership?.map((item) => item?.tentMembershipUuid),
						...(_.isEqual(pageQuery?.paymentsOnType, 'Billed date')
							? date?.length === 2
								? { membershipFromDate: date[0], membershipToDate: date[1] }
								: { membershipDate: date[0] }
							: date?.length === 2
							? { originFromDate: date[0], originToDate: date[1] }
							: { originDate: date[0] }),
						...(!_.isEmpty(pageQuery.paymentVendor) && { payment_mode_uuid: pageQuery.paymentVendor.split(',') }),
						membership_status: selectedFilters?.membershipStatus?.map((item) => item?.value),
						payment_status: pageQuery?.status === 'paid' ? 'Paid' : 'Pending',
						is_cancelled: selectedFilters.paymentCancelled?.value,
					}
					console.log('membody', body)
					let decryptKey = encryption(body)

					ReportsData.getReportData('MEMBERSHIP', decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
				}
				break
			default:
				console.error('Report type not exists')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		mastuuid,
		report,
		pageQuery.doctor,
		pageQuery.referredBy,
		pageQuery.discount,
		pageQuery.patientGroup,
		pageQuery.procedureCategories,
		pageQuery.paymentVendor,
		pageQuery.taxes,
		pageQuery?.cancelled,
		pageQuery.appointmentCategories,
		pageQuery.bloodGroup,
		pageQuery?.category,
		pageQuery?.status,
		pageQuery?.quickSales,
		pageQuery?.paymentsOnType,
		date,
		erroJson,
		category,
		pageQuery?.distributor,
		pageQuery?.tierLevel,
		selectedProducts,
		selectedPractice,
		selectedFilters.membership,
		selectedFilters?.membershipStatus,
		selectedFilters.paymentCancelled,
	])

	console.log('report', report)

	useEffect(() => {
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData?.status === 'success') {
				setAllUsersList(successData?.data?.tentUserRoleBasedDtoList)
			}
		}
		const onFailure = (err) => {
			setAllUsersList([])
		}

		UserManagementApi.getAccessRoleTableList(mastuuid, 'all').then(onSuccess, onFailure) // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	const xeroUploadFxn = () => {
		setOpenXeroDialog(true)
	}

	const onXeroUploadClick = () => {
		xeroUploadFxn()
	}

	const onZohoUploadClick = () => {
		setOpenZohoDialog(true)
	}

	return (
		<>
			<div className={classes.root}>
				<div className={classes.headerContainer}>
					<ModuleTitle>{currentModule?.mastMenuName || ''}</ModuleTitle>
					<div className={classes.headerRightSide}>
						{xeroStatus && report === 'InvoicedIncome' && (
							<>
								<Button
									id='additembtn'
									name='additembtn'
									onClick={() => onXeroUploadClick()}
									className={classes?.xeroUploadButton}
									disabled={_.isEmpty(pageData?.items || [])}
									variant='outlined'
									color='secondary'
								>
									<Avatar alt='xero' src={xero} sx={{ width: 28, height: 28, marginRight: 1.5 }} />
									{'Xero Backup'}
								</Button>
							</>
						)}
						{zohoStatus && report === 'InvoicedIncome' && (
							<>
								<Button
									id='additembtn'
									name='additembtn'
									onClick={() => onZohoUploadClick()}
									className={classes?.xeroUploadButton}
									disabled={_.isEmpty(pageData?.items || [])}
									variant='outlined'
									color='secondary'
								>
									<Avatar
										alt='zoho'
										src={'https://ik.imagekit.io/lyfngo/web_b2b/assets/images/zohologo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1671171166995'}
										sx={{ width: 28, height: 28, marginRight: 1.5 }}
									/>
									{'Zoho Backup'}
								</Button>
							</>
						)}
						<PrintComponent
							report={report}
							printColoumn={printColoumn}
							printRow={printRow}
							pageData={pageData}
							dateForPrint={dateForPrint}
							practiceName={practiceName}
							appliedFiltersForprint={appliedFiltersForprint}
							selectedFilters={selectedFilters}
							isCare={isCare}
						/>
						<Tooltip title='send mail' arrow enterDelay={500}>
							<MailModal allUsersList={allUsersList} report={report} pageData={pageData} />
						</Tooltip>
						<Tooltip title='Reset all filters' arrow enterDelay={500}>
							<CustomIconButton onClick={resetPageQuery}>
								<RestartAlt />
							</CustomIconButton>
						</Tooltip>
						<Tooltip title='Show / Hide filters' arrow enterDelay={500}>
							<CustomFilterButton onClick={() => setFilter(!filter)} />
						</Tooltip>
						<XeroDialog open={openXeroDialog} mastuuid={mastuuid} type={'ACCREC'} handleClose={() => setOpenXeroDialog(false)} />
						<ZohoDialog open={openZohoDialog} mastuuid={mastuuid} type={'ACCREC'} handleClose={() => setOpenZohoDialog(false)} />
					</div>
				</div>
				<div className={classes.container}>
					<div className={classes.sectionOne}>
						<FormControl fullWidth className={classes.selectReport}>
							<InputLabel id='select-report'>Select report</InputLabel>
							<Select
								labelId='select-report'
								size='small'
								color='secondary'
								id='select-report'
								value={report}
								label='Select Report'
								onChange={(event) => {
									setPageQuery({ ...pageQuery, report: event.target.value })
									setResetTriggred(false)
								}}
							>
								{(ReportTypes || [])?.map(
									(item) =>
										item?.show && (
											<MenuItem value={item?.type} key={item?.type}>
												{item?.label}
											</MenuItem>
										)
								)}
							</Select>
						</FormControl>

						{(_.isEqual(report, 'InvoicedDetail') || _.isEqual(report, 'Distributor')) && (
							<FormControl fullWidth className={classes.selectReport}>
								<InputLabel id='select-category'>Select category</InputLabel>
								<Select
									labelId='select-category'
									size='small'
									color='secondary'
									id='select-category'
									value={category}
									label='Select category'
									onChange={(event) => {
										setPageQuery({ ...pageQuery, category: event.target.value })
									}}
								>
									<MenuItem value={'all'}>All</MenuItem>
									<MenuItem value={'procedure'}>Procedure</MenuItem>
									<MenuItem value={'pharmaSales'}>Pharma sales</MenuItem>
									<MenuItem value={'quickSale'}>Quick sales</MenuItem>
									<MenuItem value={'Membership'}>Membership</MenuItem>
								</Select>
							</FormControl>
						)}

						<DateRangePick getDateRange={handleDateChange} getDateRangeForPrint={handleDateChangeForPrint} />

						{_.isEqual(report, 'BillSummary') && <SingleDatePicker getDateRange={handleDateChange} getDateRangeForPrint={handleDateChangeForPrint} />}
					</div>
					<div className={classes.showAdvancedFilters}>
						{filter && (
							<Filters
								report={report}
								category={category}
								onQueryChange={onQueryChange}
								resetTriggred={resetTriggred}
								pageQuery={pageQuery}
								setPageQuery={setPageQuery}
								mastuuid={mastuuid}
								userRole={userRole}
								uuid={uuid}
								allAccessTypeUser={allAccessTypeUser}
								isOwner={isOwner}
								isAdmin={isAdmin}
								isDoctor={isDoctor}
								isCare={isCare}
								isFitness={isFitness}
								isMind={isMind}
								isSport={isSport}
								isSpa={isSpa}
								setdoctorsList={() => {}}
								getAppliedFilters={(appliedFilters) => setAppliesFiltersForprint(appliedFilters)}
								practicelist={practicelist}
								selectedProducts={selectedProducts}
								setSelectedProducts={setSelectedProducts}
								selectedPractice={selectedPractice}
								setSelectedPractice={setSelectedPractice}
								selectableOptions={selectableOptions}
								updateOptions={updateOptions}
								selectedFilters={selectedFilters}
								updateSelectedFilters={updateSelectedFilters}
							/>
						)}
					</div>

					<div className='stats'>
						<Statistics report={report} pageData={pageData} isCare={isCare} pageQuery={pageQuery} />
					</div>

					<div className={classes.summaryTable}>
						<SummaryTable
							report={report}
							pageData={pageData}
							isCare={isCare}
							isFitness={isFitness}
							isMind={isMind}
							isSport={isSport}
							isSpa={isSpa}
							getColumn={(column) => setPrintColoumn(column)}
							getRow={(row) => setPrintRow(row)}
							pageSize={10}
							handlePageSizeChange={() => {}}
							handleChangePage={() => {}}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ReportPage
