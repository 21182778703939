import { ACTION_TYPES } from '../../../constants'
const initialState = {
	open: false,
}

const otpverificationModal = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_OPEN_OTP_MODAL: {
			return {
				...state,
				open: action?.payload?.open,
			}
		}
		case ACTION_TYPES.CLOSE_OTP_MODAL: {
			return {
				...state,
				open: action?.payload?.open,
			}
		}
		default:
			return state
	}
}

export default otpverificationModal
