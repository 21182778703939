import secureLocalStorage from 'react-secure-storage'
import { ACTION_TYPES } from '../../../constants'

const initialState = {
	currentIndex: secureLocalStorage.getItem('currentIndex') || 'dashboard',
	clickPatientData: {},
	clickLeadsData: {},
}

const sideMenuReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.UPDATE_CURRENT_INDEX: {
			return {
				...state,
				currentIndex: action.payload,
			}
		}
		case ACTION_TYPES.CHANNEL_PATIENT_CLICK: {
			return {
				...state,
				clickPatientData: action.payload,
			}
		}
		case ACTION_TYPES.CHANNEL_LEADS_CLICK: {
			return {
				...state,
				clickLeadsData: action.payload,
			}
		}
		default:
			return state
	}
}

export default sideMenuReducer
