import { ACTION_TYPES } from '../../../constants/index'

const initialValues = {
	currentEditFood: {},
	currentClientDietPlan: {},
}

const dietPlanReducer = (state = initialValues, action) => {
	switch (action.type) {
		case ACTION_TYPES.CURRENT_EDIT_FOOD: {
			return {
				...state,
				currentEditFood: action.payload,
			}
		}
		case ACTION_TYPES.CURRENT_CLIENT_DIET_PLAN: {
			return {
				...state,
				currentClientDietPlan: action.payload,
			}
		}
		default:
			return state
	}
}

export default dietPlanReducer
