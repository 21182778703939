import React, { useState, useEffect } from 'react'
import { Box, Modal, Button } from '@mui/material'
import successEmailApi from '../../../../services/successEmail/api'
import { styled } from '@mui/material/styles'
import history from '@history'
import { makeStyles } from '@mui/styles'
import { showMessage } from 'app/store/fuse/messageSlice'
import { encryption, decryption, failureLogin, getResponseMessage } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import themesConfig from 'app/fuse-configs/themesConfig'
import secureLocalStorage from 'react-secure-storage'
import ForgetPassword from 'images/login/ForgetPassword.png'

const useStyles = makeStyles((theme) => ({
	modalbox: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		background: '#fff',
		border: '1px solid #fff',
		borderRadius: '12px',
		boxShadow: 24,
		paddingBlock: '40px',
		paddingInline: '40px',
		[theme.breakpoints.down('sm')]: {
			width: 270,
		},
		'& .muiltr-1auo3o6-MuiTypography-root': {
			fontSize: '14px',
			color: '#263238',
			paddingInline: '30px',
		},
		'& .muiltr-w6yxov-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '54px',
			paddingBlock: '0px',
		},
		'& .muiltr-nia2q8-MuiTypography-root': {
			fontSize: '12px',
			color: '#263238',
			paddingInline: '45px',
			'& a:not([role=button]):not(.MuiButtonBase-root)': {
				color: '#2658DC',
			},
		},
	},
	emailtext: {
		display: 'flex',
		flexDirection: 'column',
		gap: 12,
	},
}))
const ButtonStyle = styled('Button')(({ theme }) => ({
	width: '100%',
	'& .btn': {
		color: '#FFFFFF',
		backgroundColor: themesConfig.greyDark.palette.primary.main,

		'&:hover': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
const ForgetEmail = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const errorMessages = useSelector((states) => states?.errorMessages)

	const [state, setState] = useState({
		specializationRedirect: false,
		invalidVerificationTOken: false,
		showModal: false,
		showfailModal: false,
		showAlready: false,
	})
	const navigate = () => {
		history.push('/resetpassword')
	}

	useEffect(() => {
		validateEmailToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const validateEmailToken = () => {
		var url = window.location.href
		var parts = url.split('?')
		let token = ''
		for (let i = 1; parts.length > i; i++) {
			token += parts[i]
		}
		let queryValue = token.replace('token=', '')

		const body = {
			verificationToken: queryValue,
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			if (successData.status === 'success') {
				if (successData.isAlreadyVerified || successData.isAlreadyVerified === 'true') {
					secureLocalStorage.setItem('aidivaaUser_isEmail', true)
					setState((prevState) => ({ ...prevState, showAlready: true }))
				} else {
					secureLocalStorage.setItem('aidivaaUser_isEmail', true)
					setState((prevState) => ({ ...prevState, showModal: true }))
				}
			} else if (successData.status === 'failure') {
				setState((prevState) => ({ ...prevState, showfailModal: true }))
			}
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			setState((prevState) => ({ ...prevState, showSpinner: true }))
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorMessages, 'Session Expired!'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
			history.push('/')
		}
		successEmailApi.setSuccessMail(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}
	return (
		<>
			<Modal keepMounted open={state.showModal} aria-labelledby='keep-mounted-modal-title' aria-describedby='keep-mounted-modal-description'>
				<Box className={classes.modalbox}>
					<>
						<div className={classes.emailtext}>
							<div className='d-flex fl-jus-cntr pad-t-20-i'>
								<img className='emaill_succes_img' src={ForgetPassword} alt='emaill_succes_img' />
							</div>
							<div className='text-center'>
								<p className=''>Click continue to Reset Your Password</p>
							</div>
							<ButtonStyle>
								<Button type='submit' variant='contained' className='btn w-full mx-auto mt-16' aria-label='LOG IN' value='submit' onClick={navigate}>
									Continue
								</Button>
							</ButtonStyle>
						</div>
					</>
				</Box>
			</Modal>
		</>
	)
}
export default ForgetEmail
