import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	patientsMain: {},
	listSection: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 0,
		paddingInline: 8,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
			paddingBlock: 12,
		},
	},
	listContent: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 20,
		paddingInline: 8,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
	},
	listContentWithError: {
		border: '1px solid #F00',
		borderRadius: 4,
		paddingBlock: 8,
		paddingInline: 8,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
		[theme.breakpoints.up('xs')]: {
			marginBlock: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlock: 8,
		},
	},
	listItem: {
		height: 214,
		overflow: 'auto',
	},
	checkBox: {},
	messageCost: {
		'& .MuiFormControl-root': {
			display: 'flex',
		},
	},
}))
export default useStyles
