import { Button, Grid, Typography } from '@mui/material'
import animationData from '../../../lottieFiles/expireplan.json'
import React, { useState, useEffect } from 'react'
import Lottie from 'react-lottie'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { closePrompt, showPrompt } from 'services/Prompt/actions'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import { getResponseMessage } from 'utils'
import DeleteApi from 'services/AccountSettings/DeleteAccount/api'
import Prompt from '../Prompt'
import Header from './Header/index'
import themesConfig from 'app/fuse-configs/themesConfig'
import history from '@history'
import { setBuyNow } from 'services/SubscriptionRedux/action'
import secureLocalStorage from 'react-secure-storage'
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
	header: {
		background: themesConfig.greyDark.palette.primary.main,
		maxHeight: '80px',
		padding: 14,
		color: '#fff',
		position: 'sticky',
		top: '60px',
		zIndex: 50,
	},
	root: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 0px 20px #00000012',
		borderRadius: '16px',
		opacity: 1,
		height: 'fit-content',
		padding: '14px',
		textAlign: 'center',
	},
	wrapper: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	contentMain: {
		color: '#3B4450',
		fontWeight: 500,
	},
	supportColor: {
		color: '#0077B6 !important',
	},
	buttonList: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 8,
		paddingBottom: theme.spacing(2),
	},
	buyButton: {
		background: '#3B4450',
		borderRadius: 8,
		paddingInline: 22,
		color: '#fff',
		'&:hover': {
			backgroundColor: '#3B4450',
		},
	},
	DeleteButton: {
		color: '#EE122C',
		borderRadius: 8,
		textDecoration: 'underline',
		padding: 2,
	},
}))
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}
const ExpirePlan = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((states) => states.Authentication)
	const errorJson = useSelector((states) => states.errorJson || {})
	const { userType, uuid, mastuuid } = state
	const mainmenuState = useSelector((state) => state?.dynamicMenu?.userData || {})
	const { userRole } = mainmenuState
	const HeaderName = history?.location?.pathname?.split('/').pop()
	//delete account
	const deleteUser = () => {
		dispatch(showLoader('Deleting please wait...'))
		const body = {
			userType: userType,
			uuid: uuid,
		}
		const onSuccess = (res) => {
			if (res.data.status === 'success') {
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(res?.data, errorJson, 'Deleted successfully'),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				secureLocalStorage.clear()
				history.push('/')
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		DeleteApi.delete(body).then(onSuccess, onFailure)
	}

	return (
		<>
			<Grid container className={classes.header} alignItems='center'>
				<Header history={history} HeaderName={HeaderName} />
			</Grid>
			<Grid container className={classes.wrapper}>
				<Grid item className={classes.root}>
					<Lottie options={defaultOptions} height={120} width={150} />
					<Typography variant='h5' pb={1} color='#EE122C'>
						Your flash subscription has expired!!!
					</Typography>
					<Typography variant='subtitle1' pb={1} className={classes.contentMain}>
						In order to continue using LYFnGO flash you need to purchase a subscription.
					</Typography>
					<Typography variant='subtitle1' pb={1} className={classes.contentMain}>
						{(userRole === 'Owner' || userRole === 'Admin') && 'To buy one now click "Buy Now" or'} get in touch with our Support Desk at
					</Typography>
					<Typography variant='subtitle1' pb={1} className={classes.contentMain}>
						<span>
							<a className={classes.supportColor} href='mailto:support@lyfngo.com'>
								support@lyfngo.com
							</a>
						</span>{' '}
						or call{' '}
						<span>
							<a className={classes.supportColor} href='tel:8110071300'>
								+91 81100-71300
							</a>
						</span>
					</Typography>
					<div className={classes.buttonList}>
						{(userRole === 'Owner' || userRole === 'Admin') && (
							<>
								<Button
									variant='contained'
									className={classes.buyButton}
									onClick={() => {
										dispatch(setBuyNow(true))
										history.push(`accountsubscription/practice/view/${mastuuid}`)
									}}
								>
									Buy Now
								</Button>

								<Button
									variant='text'
									className={classes.DeleteButton}
									onClick={() => {
										dispatch(
											showPrompt(
												'Are you sure you want to delete?',
												'Yes',
												'No',
												() => deleteUser(),
												() => dispatch(closePrompt())
											)
										)
									}}
								>
									Delete Account
								</Button>
							</>
						)}
					</div>
				</Grid>
				<Prompt />
			</Grid>
		</>
	)
}
export default ExpirePlan
