import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
	thumbsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 5,
	},
	thumb: {
		display: 'inline-flex',
		borderRadius: 2,
		border: '1px solid #eaeaea',
		marginBottom: 5,
		marginRight: 8,
		width: 50,
		height: 50,
		padding: 4,
		boxSizing: 'border-box',
	},
	thumbInne: {
		display: 'flex',
		minWidth: 0,
		overflow: 'hidden',
	},
	img: {
		display: 'block',
		width: 'auto',
		height: '100%',
	},
	container: {
		paddingLeft: '0',
		// paddingRight: '10px',
		position: 'relative',
		width: 150,
		height: 150,
	},
	dropzone: {
		display: 'flex',
		cursor: 'pointer',
		gap: 2,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: 150,
		height: 150,
		opacity: 1,
		marginBlock: 12,
		borderRadius: 12,
		background: '#F1F1F1',
		'& .MuiButton-root': {
			color: '#636669',
			border: '1px solid #636669',
		},
	},
	icon: {
		color: '#767676',
		marginTop: theme.spacing(1),
		fontSize: 38,
	},
	dragText: {
		color: '#767676',
		marginTop: theme.spacing(1),
	},
	mainImg: {
		width: 150,
		height: 140,
		borderRadius: 12,
		marginBlock: 12,
	},
	deletIcon: {
		position: 'absolute',
		right: '-10px',
		top: '-2px',
		color: 'red',
		opacity: 1,
		fontSize: 6,
		cursor: 'pointer',
		zIndex: 999,
	},
	mainImgUpload: {
		display: 'flex',
		gap: 6,
	},
	multiImgs: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		gap: 10,
	},
	uploadImage: {
		display: 'flex',
		cursor: 'pointer',
		gap: 8,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: 150,
		height: 150,
		background: '#fff 0% 0% no-repeat padding-box',
		boxShadow: '0 4px 8px 0 rgb(0 0 0 / 6%), 0px 1px 20px 0 rgb(255 255 255 /12%)',
		opacity: 1,
		marginBlock: 12,
		borderRadius: 12,
	},
	imgContainer: {
		position: 'relative',
		backround: 'red',
		// '&:hover': {
		// 	filter: 'blur(3px)',
		// },
	},
}))

export default useStyles
