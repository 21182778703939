/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { Avatar, Typography } from '@mui/material'
import useStyles from './style'
import Button from '@mui/material/Button'
import _ from 'lodash'
import fileApi from '../../../services/fileupload/GetApi'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getImgUrl } from 'utils'

const CompDropZone = (props) => {
	const classes = useStyles()
	const { displayUploadImage, setUploadUuid, imgResponse, setImage, imageTextOne, isMulti = false, formik, isType } = props
	const dispatch = useDispatch()
	const token = useSelector((state) => state?.Authentication?.BearerToken)
	const uuid = useSelector((state) => state?.Authentication?.uuid)
	const groupUser = useSelector((state) => state?.GroupUser)
	const { groupUserName, tentUserId } = groupUser

	// Converting bytes
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	const maxFileSize = 5242880
	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		setStatus: 'success',
		maxSize: formatBytes(maxFileSize),
		maxFiles: isMulti ? 10 : 1,
		multiple: isMulti,
		accept: 'image/jpeg, image/jpg, image/jpe, application/pdf, video/mp4, image/png',
		onDrop: (acceptedFiles) => {
			let fileLength = acceptedFiles.map((file) => file.size)
			if (fileLength > maxFileSize) {
				dispatch(
					showMessage({
						message: `File size must be smaller than ${formatBytes(maxFileSize)}`,
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			} else {
				displayUploadImage(acceptedFiles)
			}
		},
	})

	// Rejected
	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<div key={file.path}>
			{errors.map((e) => (
				<Typography style={{ color: 'red' }} variant='caption' key={e.code}>
					{e.message}
				</Typography>
			))}
		</div>
	))

	const deleteImage = (file) => {
		const onSuccess = (res) => {
			if (isMulti) {
				const imgs = _.filter(imgResponse, (img) => !_.isEqual(img?.uuid, file?.uuid))
				setImage(imgs)
			} else if (isType === 'profile') {
				setImage()
				setUploadUuid('')

				const updatedData = formik?.values?.registrationDocs?.map((item) => {
					const updatedSupportingDocUuid = item.supportingDocUuid.map((doc) => {
						const indexOfUuidToRemove = doc.docUuidList.indexOf(file)
						if (indexOfUuidToRemove !== -1) {
							doc.docUuidList.splice(indexOfUuidToRemove, 1)
							if (doc.profileDocMapUuidList && doc.profileDocMapUuidList.length > indexOfUuidToRemove) {
								doc.profileDocMapUuidList.splice(indexOfUuidToRemove, 1)
							}
						}

						return doc
					})

					return { ...item, supportingDocUuid: updatedSupportingDocUuid }
				})
				formik?.setFieldValue('registrationDocs', updatedData)
			} else {
				setImage()
			}
		}
		const onFailure = (err) => {}
		if (isType === 'profile') {
			let isPermAllowed = !_.isEqual(groupUserName, 'owner') ? tentUserId : uuid
			fileApi
				.deleteProfileFile(file.uuid || file, isPermAllowed)
				.then(onSuccess)
				.catch(onFailure)
		} else {
			fileApi
				.deleteFile(file.uuid || file)
				.then(onSuccess)
				.catch(onFailure)
		}
	}

	return (
		<>
			{isMulti ? (
				<div className={classes.multiImgs}>
					{!_.isEmpty(imgResponse) &&
						_.map(imgResponse, (file) => {
							return (
								<div className={classes.imgContainer}>
									<div className={classes.deletIcon} onClick={() => deleteImage(file)}>
										<DeleteOutlineIcon />
									</div>
									<Avatar src={file?.filePath || getImgUrl(file?.uuid, token)} className={classes.mainImg} />
								</div>
							)
						})}
					{imgResponse?.length < 10 && (
						<div className={classes.uploadImage}>
							<div className={classes.dropzone} {...getRootProps()}>
								<input {...getInputProps()} />
								<CloudUploadOutlinedIcon className={classes.icon} />
								<Typography variant='body1' className={classes.dragText}>
									{imageTextOne}
								</Typography>
								<Button variant='outlined' className={classes.btnContent} onClick={open}>
									Upload
								</Button>
							</div>
						</div>
					)}
				</div>
			) : (
				<div className={classes.container}>
					{!_.isEmpty(imgResponse) ? (
						<div>
							<div className={classes.deletIcon} onClick={() => deleteImage(imgResponse)}>
								<DeleteOutlineIcon />
							</div>
							<Avatar src={getImgUrl(imgResponse?.uuid || imgResponse, token) || imgResponse?.filePath || null} className={classes.mainImg} />
						</div>
					) : (
						<div className={classes.dropzone} {...getRootProps()}>
							<input {...getInputProps()} />
							<CloudUploadOutlinedIcon className={classes.icon} />
							<Typography variant='body1' className={classes.dragText}>
								{imageTextOne}
							</Typography>
							<Button variant='outlined' className={classes.btnContent} onClick={open}>
								Upload
							</Button>
						</div>
					)}
					<aside>
						<p>{fileRejectionItems}</p>
					</aside>
				</div>
			)}
		</>
	)
}

export default CompDropZone
