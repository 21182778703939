import { Paper, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MobileDatePicker } from '@mui/lab'
import themesConfig from 'app/fuse-configs/themesConfig'
import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	pickerRoot: {
		// background: '#000',
	},
	muiDialogPaper: {
		'& .PrivatePickersToolbar-root': {
			'& button': {
				display: 'none',
			},
		},
		'& .MuiDialogActions-root': {
			// display: 'none',
			'& button': {
				color: '#000',
			},
		},

		'& .Mui-selected': {
			backgroundColor: themesConfig.greyDark.palette.primary.main,
			color: '#fff',
		},
		'& .MuiOutlinedInput-input': {
			backgroundColor: '#fff',
			borderRadius: '6px',
		},
	},
	error: {
		'& .MuiInputLabel-root': {
			color: themesConfig.greyDark.palette.primary.link,
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: themesConfig.greyDark.palette.primary.link,
			},
			'&:hover fieldset': {
				borderColor: themesConfig.greyDark.palette.primary.link,
			},
			'&.Mui-focused fieldset': {
				borderColor: themesConfig.greyDark.palette.primary.link,
			},
		},
		'& .MuiFormHelperText-root': {
			color: themesConfig.greyDark.palette.primary.link,
		},
	},
	textField: {},
}))

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
	<button className='example-custom-input' onClick={onClick} ref={ref}>
		{value}
	</button>
))

const CustomDatePicker = (props) => {
	const classes = useStyles()
	const { value, onAccept, error, helperText, showTodayButton = true, label, id, isEditMode } = props
	const dateFormat = useSelector((state) => state?.dynamicMenu?.userData?.dateTimeFormat)

	return (
		<MobileDatePicker
			label={label ? label : ''}
			name={id ? id : 'date'}
			id={id ? id : 'date'}
			{...props}
			classes={{ root: classes.pickerRoot }}
			PaperComponent={Paper}
			DialogProps={{ classes: { scrollPaper: classes.muiDialogPaper } }}
			inputFormat={dateFormat || 'dd-MM-yyyy'}
			onChangeRaw={(e) => e.preventDefault()}
			disableToolbar={true}
			value={value}
			showTodayButton={showTodayButton}
			clearText='Clear'
			error={true}
			onChange={(newdate) => {}}
			onAccept={(newdate) => onAccept(newdate)}
			customInput={ExampleCustomInput}
			renderInput={(params) => (
				<TextField
					size='small'
					color='secondary'
					name={id ? id : 'date'}
					disabled={isEditMode}
					className={error ? classes.error : classes.texfield}
					error={error}
					helperText={helperText}
					fullWidth
					{...params}
				/>
			)}
		/>
	)
}

export default CustomDatePicker
