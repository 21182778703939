import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: themesConfig.greyDark.palette.primary.main,
		color: '#fff',
		borderRadius: 21,
		textTransform: 'none',
		padding: '4px 20px',
		whiteSpace: 'nowrap',
		boxShadow: 'none',
		// gap: 4,
		'&:hover': {
			backgroundColor: themesConfig.lyfngo.palette.primary.dark,
		},
	},
}))
function CustomBtn({ children, onClick, disabled, type, customStyle, style, startIcon }) {
	const classes = useStyles()
	return (
		<Button
			variant='contained'
			style={style}
			type={type}
			className={`${classes.root} ${customStyle}`}
			onClick={onClick}
			disabled={disabled}
			startIcon={startIcon}
		>
			{children}
		</Button>
	)
}
export default CustomBtn
