import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUserPermissions, getModulePerms } from '../../utils'

export function usePermissions(menuName, featureName) {
	const userPermissions = useSelector(getUserPermissions)
	const permissionMap = useMemo(() => {
		return getModulePerms(menuName, featureName, userPermissions)
	}, [featureName, menuName, userPermissions])
	return permissionMap
}
