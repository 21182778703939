import React from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import Lottie from 'react-lottie'
import Loading from '../../../lottieFiles/paymentProcessing.json'
import { useState } from 'react'
import { useEffect } from 'react'
import billinggetapi from 'services/patient/Billing/addBill/api'
import billingApi from 'services/patient/Billing/addBill/api'
import { useFormik } from 'formik'
import * as yup from 'yup'
import CustomBtn from 'app/sharedComponents/Button'
import { showMessage } from 'app/store/fuse/messageSlice'
import { hideLoader, showLoader } from 'services/loader/action'
import secureLocalStorage from 'react-secure-storage'
import history from '@history'
import CancelBtn from 'app/sharedComponents/CancelButton'
import { appointmentRefresh } from 'services/Appointments/action'
import { customer_Uuid } from 'services/patient/roleBasedUuid/action'
import { updateCurrentIndex } from 'services/sidemenu/action'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTypography-body1': {
			textAlign: 'center',
			paddingBlock: 10,
			fontSize: 17,
		},
	},
	payOptn: {
		'& .MuiTypography-body1': {
			textAlign: 'left',
			paddingBlock: 10,
			fontSize: 16,
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 20,
		paddingBlockEnd: 10,
	},
	payOptionDropDown: {
		width: 240,
	},
	footerAction: {
		background: '#D9EAFF50',
		paddingInline: 22,
		paddingBlock: 8,
		display: 'flex',
		justifyContent: 'flex-end',
		gap: 22,
	},
	payFieldBox: {
		display: 'flex',
		justifyContent: 'center',
		gap: 18,
		marginBottom: 16,
	},
}))

const PaymentHandler = ({ isCareFlow, billingData, onClose }) => {
	const classes = useStyles()
	const currencyType = useSelector((state) => state?.dynamicMenu?.userData?.currencySymbol)
	const { mastuuid, uuid } = useSelector((state) => state.Authentication)

	const [paymentTypeOptions, setPaymentTypeOptions] = useState([])
	const errorJson = useSelector((state) => state?.errorMessages || {})

	const appointmentInfo = billingData?.rawBody || {}

	const dispatch = useDispatch()
	const selectedClient = billingData?.rawBody?.clientName
	const paymentFormik = useFormik({
		initialValues: {
			invoiceNumber: '',
			receiptNumber: '',
			paymentType: '',
			total: 0,
			upi: secureLocalStorage?.getItem('upiId') || '',
			payeeName: secureLocalStorage?.getItem('upiName') || '',
		},
		validationSchema: yup.object({}),
		onSubmit: (e) => {},
	})

	useEffect(() => {
		// Invoice number :
		billinggetapi.getInvoice(mastuuid).then(
			(res) => {
				if (res?.data?.status === 'success') {
					paymentFormik?.setFieldValue('invoiceNumber', res?.data?.data)
				}
			},
			(err) => {}
		)

		// Receipt number :
		billinggetapi.getReceiptData(mastuuid).then(
			(res) => {
				const successData = decryption(res)
				if (successData?.status === 'success') {
					paymentFormik?.setFieldValue('receiptNumber', successData?.data)
				}
			},
			() => {}
		)

		// Payment mode options:
		billinggetapi?.getPaymentModes(mastuuid).then(
			(res) => {
				if (res?.data?.status === 'success') {
					let paymentModeDtoList = res?.data?.data?.paymentModeDtoList
					setPaymentTypeOptions(paymentModeDtoList)
					let onlineMode = paymentModeDtoList.find((i) => i?.modeType === 'Online')
					let UPIMode = paymentModeDtoList.find((i) => i?.modeType === 'UPI')
					let defaultMode = !_.isEmpty(onlineMode) ? onlineMode : !_.isEmpty(UPIMode) ? UPIMode : paymentModeDtoList.at(0)
					paymentFormik?.setFieldValue('paymentType', defaultMode)
				}
			},
			() => {}
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid])

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Loading,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	const loadingInVRcpt = paymentFormik?.values?.invoiceNumber === '' && paymentFormik?.values?.receiptNumber === ''

	const onBillingSuccess = () => {
		dispatch(appointmentRefresh({ apptRes: billingData?.apptRes, lastBody: billingData?.lastBody }))
		dispatch(customer_Uuid(`${appointmentInfo?.clientName?.custUuid}`))
		secureLocalStorage.setItem('custUuid', `${appointmentInfo?.clientName?.custUuid}`)
		dispatch(updateCurrentIndex('clients'))
		history.push({
			pathname: `/clientinfo/${appointmentInfo?.clientName?.custUuid}?tabId=appointment`,
		})
	}

	const onlineLinkGenerate = (data) => {
		dispatch(showLoader('Generating payment link please wait'))
		const body = {
			amount: data?.paymentAmount,
			custUuid: data?.custUuid,
			tentUuid: mastuuid,
			currency: currencyType,
			tentUserUuid: uuid,
			type: 'BILLING',
			billingPaymentUuid: data?.paymentUuid,
		}
		let decryptKey = encryption(body)

		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			onBillingSuccess()
			onClose(billingData?.apptRes)
			dispatch(
				showMessage({
					message: getResponseMessage(successData, errorJson, 'Payment link sent to WhatsApp number'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson, 'Please try after sometime'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		console.log('custUuid', data?.custUuid)
		billingApi.onlineBillingDetails(decryptKey?.plainText, decryptKey?.publicKey, data?.custUuid).then(onSuccess, onFailure)
	}

	const upiLinkGenerate = (data) => {
		dispatch(showLoader('Loading please wait'))

		let body = {
			amount: Number(data?.paymentAmount),
			custId: data?.custUuid,
			tentId: mastuuid,
			currency: 'INR',
			upiId: paymentFormik?.values?.upi,
			TentUserId: uuid,
			type: 'BILLING',
			appointmentUuid: billingData?.apptRes?.data?.data?.appointmentUuid,
			payName: paymentFormik?.values?.payeeName,
		}

		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(successData, errorJson, 'Link sent to whatsapp number'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
			onBillingSuccess()
			onClose(billingData?.apptRes)
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson, 'Please try after sometime'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		billingApi.upiBillingDetails(decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
	}

	const billingPayment = () => {
		dispatch(showLoader('Generating bill. please wait ...'))
		let totalPrice = isCareFlow
			? appointmentInfo?.paymentMandatory
				? appointmentInfo?.feesCalcMethod === 'specialistConsultationFees'
					? appointmentInfo?.timeSlot?.consultantionFees
					: appointmentInfo?.procedureCategory.totalPrice
				: appointmentInfo?.timeSlot?.consultantionFees
			: appointmentInfo?.timeSlot?.consultantionFees

		let orderName = isCareFlow
			? appointmentInfo?.feesCalcMethod === 'specialistConsultationFees'
				? `Appointment with ${appointmentInfo?.specialistName?.tentUserFirstName || 'Specialist'}` || 'Specialist consultation fess'
				: appointmentInfo?.procedureCategory?.tentProcedureCatalogName
			: appointmentInfo?.facility?.specialityName

		let body = {
			amtPaid: totalPrice,
			balance: '0.00',
			balanceAmtOnRefund: 0,
			bankName: null,
			cardNo: null,
			chequeNo: null,
			custInvoiceNo: paymentFormik?.values?.invoiceNumber,
			isUpi: _.isEqual(paymentFormik?.values?.paymentType?.modeName, 'UPI') ? true : false,
			custItems: [
				{
					custBillingCost: totalPrice,
					custBillingDiscount: 0.0,
					custBillingTax: 0.0,
					custBillingTotal: totalPrice,
					custBillingUnit: 1,
					discountType: '%',
					newProcedure: false,
					isProcedure: isCareFlow ? true : false,
					orderName: orderName,
					procedureUuid: isCareFlow ? appointmentInfo?.procedureCategory?.treatmentCatalogUuid : appointmentInfo?.facility?.specialityUuid,
					taxUuid: [],
				},
			],
			custPaymentNo: paymentFormik?.values?.receiptNumber,
			customInvoiceId: false,
			customRecieptId: false,
			paymentMode: paymentFormik?.values?.paymentType?.modeType,
			modeUuid: paymentFormik?.values?.paymentType?.uuid,
			paymentRefNo: null,
			pending: false,
			vendorFee: '',
		}
		let decryptKey = encryption(body)
		const onSuccess = (res) => {
			const successData = decryption(res)

			if (
				_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Razorpay') ||
				_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Paytm') ||
				_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Hitpay')
			) {
				onlineLinkGenerate(successData?.data)
			} else if (_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'UPI')) {
				upiLinkGenerate(successData?.data)
			} else {
				dispatch(hideLoader())
				onBillingSuccess()
				onClose(billingData?.apptRes)
			}

			dispatch(
				showMessage({
					message: getResponseMessage(successData, errorJson, 'Bill added successfully!'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		const onFailure = (err) => {
			const failureData = failureLogin(err)
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(failureData, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		billingApi
			.saveBillingDetails(
				decryptKey?.plainText,
				decryptKey?.publicKey,
				billingData?.apptRes?.data?.data?.appointmentUuid,
				appointmentInfo?.clientName?.custUuid,
				{
					tentUserUuid: isCareFlow ? uuid : '',
				}
			)
			.then(onSuccess, onFailure)
	}

	return (
		<div className={classes.root}>
			<Typography className='mt-8'>
				{`Appointment payment for ${isCareFlow ? 'patient' : 'client'}`} <b>{billingData?.rawBody?.clientName?.custName || ''}</b>
			</Typography>
			<Typography>{loadingInVRcpt ? 'Generating invoice please wait...' : 'Invoice generated'}</Typography>
			<div style={{ marginTop: -34 }}>
				<Lottie options={defaultOptions} height={220} width={220} />
			</div>

			<div style={{ scale: loadingInVRcpt ? 0 : 'unset', transition: 'scale 0.5s' }}>
				<div className={classes.payOptn}>
					<Typography>Select payment method</Typography>
					<div className={classes.payOptionDropDown}>
						<Autocomplete
							id='Paymentmethods'
							name='Paymentmethods'
							size='small'
							fullWidth
							selectOnFocus
							options={paymentTypeOptions}
							clearOnBlur
							filterSelectedOptions
							getOptionLabel={(option) => option?.modeName || ''}
							noOptionsText='No Payment modes Available'
							clearIcon=''
							disableClearable
							value={paymentFormik?.values?.paymentType}
							onChange={(e, value) => {
								paymentFormik?.setFieldValue('paymentType', value)
							}}
							isOptionEqualToValue={(option, value) => option?.modeName === value?.modeName}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='outlined'
									label='Payment methods *'
									color='secondary'
									InputProps={{ ...params.InputProps }}
									disableUnderline
									error={paymentFormik?.touched.paymentType && paymentFormik?.errors?.paymentType}
									helperText={paymentFormik?.touched.paymentType && paymentFormik?.errors?.paymentType}
								/>
							)}
						/>
					</div>
				</div>

				{(_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Razorpay') ||
					_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Paytm') ||
					_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'Hitpay') ||
					_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'UPI')) && (
					<div className={classes.payFieldBox}>
						<div style={{ width: 202 }}></div>
						<TextField
							id='outlined-basic'
							style={{ width: 242 }}
							color='secondary'
							size='small'
							label='Mobile Number *'
							variant='outlined'
							disabled={true}
							fullWidth
							value={selectedClient ? `${selectedClient.custCountryCode} ${selectedClient.custMobileNo}` : ''}
							error={paymentFormik.touched.custMobileNo && paymentFormik.errors.custMobileNo}
							helperText={paymentFormik.touched.custMobileNo && paymentFormik.errors.custMobileNo && paymentFormik.errors.custMobileNo}
						/>
					</div>
				)}

				{_.isEqual(paymentFormik?.values?.paymentType?.modeName, 'UPI') && (
					<div className={classes.payFieldBox}>
						<TextField
							style={{ width: 202 }}
							id='outlined-basic'
							color='secondary'
							size='small'
							label='UPI *'
							variant='outlined'
							disabled={true}
							value={paymentFormik?.values?.upi}
							onChange={(e, value) => paymentFormik.setFieldValue('upi', e.target.value)}
							error={paymentFormik.touched.upi && paymentFormik.errors.upi}
							helperText={paymentFormik.touched.upi && paymentFormik.errors.upi}
						/>
						<TextField
							style={{ width: 242 }}
							id='outlined-basic'
							color='secondary'
							size='small'
							label='UPI Payee Name *'
							variant='outlined'
							disabled={true}
							value={paymentFormik?.values?.payeeName}
							onChange={(e, value) => paymentFormik.setFieldValue('payeeName', e.target.value)}
							error={paymentFormik.touched.payeeName && paymentFormik.errors.payeeName}
							helperText={paymentFormik.touched.payeeName && paymentFormik.errors.payeeName}
						/>
					</div>
				)}

				<div className={classes.footerAction}>
					<CancelBtn
						onClick={() => {
							onBillingSuccess()
							onClose(billingData?.apptRes)
						}}
					>
						Close
					</CancelBtn>
					<CustomBtn disabled={false} onClick={billingPayment}>
						Next
					</CustomBtn>
				</div>
			</div>
		</div>
	)
}

export default PaymentHandler
