import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	root: {
		background: '#FFF',
		boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.10)',
		padding: 14,
		borderRadius: 10,
	},

	titleContainer: {
		display: 'flex',
		marginBlockEnd: 14,
		[theme.breakpoints.up('xs')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},
	buttonContainer: {
		alignSelf: 'flex-end',
		'& .MuiOutlinedInput-root': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-input': {
			paddingBlock: 10,
			fontWeight: 500,
		},
	},
	cardContainer: {},
	mainContainer: {
		maxHeight: 360,
		overflowY: 'scroll',
	},
	card: {
		// border: '1px solid #D6DBDF',
		borderRadius: 18,
		padding: 12,
		gap: 10,
		marginBlockEnd: 4,
		[theme.breakpoints.up('xs')]: {},
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	cardFirstBlock: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	cardSecondBlock: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 28,
		[theme.breakpoints.up('xs')]: {
			marginBlockStart: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlockStart: 0,
		},
	},
	cardSecondBlockAppointments: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 26,
		[theme.breakpoints.up('xs')]: {
			marginBlockStart: 12,
		},
		[theme.breakpoints.up('sm')]: {
			marginBlockStart: 0,
		},
	},

	cardDetailOneAppointments: {
		[theme.breakpoints.up('xs')]: {},
		[theme.breakpoints.up('sm')]: {},
		'& .MuiTypography-body1': {
			color: '#718499',
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			'& span': {
				fontWeight: 400,
				color: '#718499',
				fontSize: '1.34rem',
			},
		},
	},

	listContainer1: {
		height: 362,
		overflowY: 'scroll',
	},
	listContainer2: {
		maxHeight: 362,
		display: 'grid',
		placeItems: 'center',
		[theme.breakpoints.up('md')]: {
			minHeight: 362,
			maxHeight: 362,
		},
	},
	list: {
		border: '1px solid #CCD1D1 ',
		borderRadius: 8,
		padding: 8,
		marginBlockEnd: 8,
	},
	cardImage: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginBlockStart: 2,
		'& .MuiAvatar-square': {
			borderRadius: 4,
		},
	},

	cardDetail: {
		paddingInlineEnd: 6,
		'& .MuiTypography-body1': {
			color: '#718499',
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
		},
	},

	cardDetailAmount: {
		paddingInlineStart: 10,
		'& .MuiTypography-body1': {
			color: '#718499',
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
		},
	},

	cardDetailName: {
		[theme.breakpoints.down('lg')]: {
			paddingInlineStart: 8,
		},
		'& .MuiTypography-body1': {
			color: '#718499',
		},
		'& .MuiTypography-subtitle1': {
			fontWeight: 500,
			fontSize: '1.41rem',
			textOverflow: 'ellipsis',
			'& span': {
				fontWeight: 400,
				color: '#718499',
				fontSize: '1.34rem',
				marginInlineStart: 3,
				[theme.breakpoints.up('sm')]: {
					display: 'Block',
					marginInlineStart: 0,
					marginTop: -4,
				},
			},
		},
	},

	cardNavigate: {
		marginBlockStart: 8,
		'& .MuiSvgIcon-root': {
			fontSize: 26,
		},
	},
	noUsers: {
		paddingBlockEnd: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& .MuiTypography-subtitle1': {
			textDecoration: 'underline',
			cursor: 'pointer',
			color: themesConfig.greyDark.palette.primary.main,
		},
	},
}))
export default useStyles
