import axios from 'axios'
import { API_ENDPOINTS } from '../../constants'
import { csrf } from 'utils'

const CancelToken = axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	setLogin: (publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.LOGIN}`, result, {
			headers: {
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
			},
		})
	},
	saveActiveDevices: (userType, identityUuid, publicKey, ivKey) => {
		const result = { data: publicKey }
		return axios.post(`${API_ENDPOINTS.UPDATE_DEVICES}`, result, {
			headers: {
				isAuthRequired: true,
				'Content-Type': 'application/json',
				'X-SECURITY': csrf(),
				key: `${ivKey}`,
				path: { userType, identityUuid },
			},
		})
	},
	setErrorResponse: () => {
		return axios.get(API_ENDPOINTS.ERROR_MESSAGE, {
			headers: { 'Content-Type': 'application/json' },
		})
	},
	getCaptcha: (cancelExecutor) => {
		if (cancelExecutor?.current) {
			cancelExecutor?.current()
		}
		return axios.get(API_ENDPOINTS.GET_CAPTCHA, {
			headers: { 'Content-Type': 'application/json' },
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
		})
	},
	setDeviceToken: (deviceToken, deviceUuid) => {
		let body = {}
		return axios.post(`${API_ENDPOINTS.DEVICE_REGISTER}`, body, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json', 'X-SECURITY': csrf(), deviceToken: deviceToken, path: { deviceUuid } },
		})
	},
	getDeviceData: () => {
		return axios.get(API_ENDPOINTS.FREE_IPAPI, {
			headers: { 'Content-Type': 'application/json' },
		})
	},
}
