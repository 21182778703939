import React from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogContent, Typography } from '@mui/material'
import Button from '../../sharedComponents/Button'
import CancelButton from '../../sharedComponents/CancelButton'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { closePrompt } from 'services/Prompt/actions'

const useStyles = makeStyles((theme) => ({
	butoonAll: {
		display: 'flex',
		gap: 10,
		justifyContent: 'center',
		paddingInline: 12,
		paddingBlockStart: 12,
	},
	butoonAll1: {
		display: 'flex',
		justifyContent: 'center',
		paddingInline: 12,
		paddingBlockEnd: 12,
	},
	contentRoot: {
		textAlign: 'center',
	},
	buttonAll: {
		paddingInline: '22px',
		cursor: 'pointer',
	},

	root: {
		backgroundColor: 'rgba(15,15,15, 0.1)',
	},
	rootContentShadow: {
		'& .MuiDialog-paper': {
			boxShadow: 'inset 0 -3em 3em rgba(0, 0, 0, 0), 0 0 0 2px rgb(255, 255, 255,255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.1)',
		},
	},
}))
const Prompt = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const prompt = useSelector((state) => state.prompt)
	const { showPrompt, promptText, successBtnText, cancelBtnText, successBtnCallback, cancelBtnCallback } = prompt
	return (
		<div className={classes.rootContent}>
			<Dialog
				fullWidth={true}
				className={classes.rootContentShadow}
				BackdropProps={{ classes: { root: classes.root } }}
				onClose={() => dispatch(closePrompt())}
				maxWidth='xs'
				open={showPrompt}
			>
				<DialogContent className={classes.butoonAll1}>
					<div className={classes.contentRoot}>
						<Typography variant='subtitle1'>{promptText}</Typography>
					</div>
				</DialogContent>
				<DialogContent className={classes.butoonAll}>
					{cancelBtnText && (
						<CancelButton onClick={cancelBtnCallback}>
							<div className={classes.buttonAll}>{cancelBtnText}</div>
						</CancelButton>
					)}
					<Button onClick={successBtnCallback}>
						<div className={classes.buttonAll}>{successBtnText}</div>
					</Button>
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default Prompt
