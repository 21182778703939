import { ACTION_TYPES } from '../../../../constants'

const initialState = {
	isUserManagementDetailsCount: null,
}

const UserManagementDetails = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.STAFF_MANAGEMENT_DETAILS_COUNT: {
			return {
				...state,
				isUserManagementDetailsCount: action?.payload,
			}
		}

		default:
			return state
	}
}

export default UserManagementDetails
