import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
	detailsHeader: {
		padding: 16,
		borderBottom: '1px solid #00000029',
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'space-between',
		},
	},
	commPlanText: {
		fontSize: 16,
		color: '#000000',
		paddingBlock: 0,
		fontWeight: 500,
		[theme.breakpoints.up('xs')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 12,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 14,
		},
	},
	patientsStatusTextOne: {
		fontSize: 11,
		color: '#FF0000',
		paddingBlock: 0,
		textTransform: 'uppercase',
	},
	patientsStatusTextTwo: {
		fontSize: 11,
		color: '#3B44F6',
		paddingBlock: 0,
		textTransform: 'uppercase',
	},
	patientsStatusText: {
		fontSize: 11,
		color: '#088424',
		paddingBlock: 0,
		textTransform: 'uppercase',
		[theme.breakpoints.up('xs')]: {
			fontSize: 10,
		},
		[theme.breakpoints.up('sm')]: {
			fontSize: 10,
		},
		[theme.breakpoints.up('md')]: {
			fontSize: 11,
		},
	},
	addNotificationPart: {
		padding: 20,
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'space-around',
			gap: 12,
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-start',
			gap: 0,
		},
		[theme.breakpoints.up('md')]: {
			gap: 12,
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.up('lg')]: {
			gap: 0,
			justifyContent: 'flex-start',
		},
	},
	detailsImage: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 100,
		maxWidth: 260,
	},
	countDetailssection: {
		[theme.breakpoints.up('xs')]: {
			justifyContent: 'flex-end',
		},
		[theme.breakpoints.up('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	total: {
		fontSize: 13,
		color: '#000000',
		fontWeight: 500,
	},
	accordionSection: {
		padding: 16,
	},
}))
export default useStyles
