import { ACTION_TYPES } from '../../../constants'

const initialState = {
	showPrompt: false,
	promptText: '',
	successBtnText: '',
	cancelBtnText: '',
	successBtnCallback: undefined,
	cancelBtnCallback: undefined,
}

const Prompt = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SHOW_PROMPT: {
			return {
				...state,
				showPrompt: true,
				promptText: action.data.promptText,
				successBtnText: action.data.successBtnText,
				cancelBtnText: action.data.cancelBtnText,
				successBtnCallback: action.data.successBtnCallback,
				cancelBtnCallback: action.data.cancelBtnCallback,
			}
		}
		case ACTION_TYPES.CLOSE_PROMPT: {
			return {
				...state,
				showPrompt: false,
			}
		}
		default:
			return state
	}
}

export default Prompt
