import { makeStyles } from '@mui/styles'
import themesConfig from 'app/fuse-configs/themesConfig'
const useStyles = makeStyles((theme) => ({
	listSection: {
		border: '1px solid #C4C4C4',
		borderRadius: 4,
		paddingBlock: 0,
		paddingInline: 8,
		'& .MuiSvgIcon-root': {
			fill: themesConfig.greyDark.palette.primary.main,
		},
	},
	listItem: {
		height: 326,
		overflowY: 'auto',
	},
}))
export default useStyles
