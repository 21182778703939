import { useEffect, useState, useRef } from 'react'
import { Fade, Grid, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useStyles } from './style'
import { useCallback } from 'react'
import { useTimeout } from '@fuse/hooks'
import clsx from 'clsx'
import secureLocalStorage from 'react-secure-storage'
import ActiveChats from './ActiveChats'
import ChatArea from './ChatArea'
import ChatDetails from './ChatDetails'
import consultApi from '../../../../../services/consult/api'
import firebase from 'firebase/compat/app'
import 'firebase/database'
import _ from 'lodash'
import PracticeDropdown from '../onlineConsult/PracticeDropdown'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

let tempMediaDetails = {
	TenantChatDetails: {
		imageVideo: {
			totalCount: 0,
			storage: '0 KB',
		},
		link: {
			totalCount: 0,
			storage: '0 KB',
		},
		document: {
			totalCount: 0,
			storage: '0 KB',
		},
	},
}

const OnlineConsult = () => {
	const classes = useStyles()

	const theme = useTheme()
	const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'))
	const tabCheck1 = useMediaQuery(theme.breakpoints.up('md'))
	const tabCheck2 = useMediaQuery(theme.breakpoints.down('lg'))
	const isTabletDevice = tabCheck1 && tabCheck2
	const isLargeDevice = useMediaQuery(theme.breakpoints.up('lg'))

	let activeCustDetailsRef = useRef()
	const cancelExecutor = useRef()
	const [activeCustDetails, setActiveCustDetails] = useState()
	// const [indexVal, setIndexVal] = useState(0)
	const [appointList, setAppointList] = useState([])
	const [chatData, setChatData] = useState([])
	const [headerText, setHeaderText] = useState('')
	const [isChatMsgloading, setchatMsgLoading] = useState(false)
	const [loading, setLoading] = useState(true)
	const [isMediaLoading, setMediaLoading] = useState(true)
	const [mediaDetails, setMediaDetails] = useState(tempMediaDetails)
	const [isCircularLoading, setIsCircularLoading] = useState(false)
	const [tenantDetail, setTenantDetail] = useState({})
	const [searchVal, setSearchVal] = useState('')
	// const [active, setActive] = useState({})
	// const [seenStatusData, setSeenStatus] = useState([])
	const [searchParams, setSearchParams] = useState({
		searchKey: '',
	})
	// For mobile
	const [currentChatPatient, setCurrentChatPatient] = useState(null)
	const [currentPatientMediaOpen, setCurrentPatientMediaOpen] = useState(false)

	const tenantSearcher = _.debounce(
		(value) => {
			setSearchParams({
				searchKey: value,
			})
		},
		[300]
	)

	let tentUserUuid = secureLocalStorage.getItem('uuid')
	let [pageNum, setpageNum] = useState(1)
	let [snapData, setSnapData] = useState([])
	let totalActiveAppointment = useRef(undefined)

	const [chatMessage, setChatMessage] = useState('')
	const handleChange = useCallback((e) => {
		setChatMessage(e.target.value)
	}, [])

	let tempAppoint = [
		{
			appointmentUuid: '',
			tentUserUuid,
			custUuid: '',
			aidivaProfilePic: '',
			aidivaName: 'Aidiva',
			customerResponseVO: {},
			tenantUserResponseDTO: {},
		},
	]

	useEffect(() => {
		fetchAppointmentList(false, pageNum, appointList)
		if (tentUserUuid) {
			fetchTenantDetails()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (searchParams) {
			fetchAppointmentList(false, pageNum, appointList, searchParams)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	useEffect(() => {
		setAppointList(appointList)
	}, [appointList])

	useEffect(() => {
		fetchChatMessage()

		return () => {
			fetchChatMessage()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeCustDetails?.appointmentUuid])

	const fetchTenantDetails = () => {
		consultApi.getTenantDetails(tentUserUuid).then((resp) => {
			setTenantDetail(resp?.data?.data)
		})
	}

	const fetchChatMessage = () => {
		firebase
			.database()
			.ref(`OnlineConsultation`)
			.orderByChild('tentUserUuid')
			.equalTo(tentUserUuid)
			.on('value', function (snapshot) {
				let locData = []
				let iterate = true
				let locSnapData = _.orderBy(_.uniqWith(_.values(snapshot.val())))
				_.filter(locSnapData, (x) => {
					if (x.appointmentUuid === activeCustDetails?.appointmentUuid) {
						// setChatData(_.values(x?.messages))
						setChatData((temp) => {
							// _.find(users, ['chatId', ""]);
							let val = _.cloneDeep(temp)
							let index = _.indexOf(val, _.find(val, ['chatId', '']))
							let replaceObj = _.find(val, ['chatId', ''])
							let tobeReplace = _.values(x?.messages)[_.indexOf(val, _.find(val, ['chatId', '']))]
							if (replaceObj?.messageType === 'text' || replaceObj?.messageType === 'emoji') {
								// val.some(g => g.chatId !== "")
								if (val) {
									if (tobeReplace) {
										val[index] = tobeReplace
									}
									return val
								} else {
									return _.values(x?.messages)
								}
							} else {
								return _.values(x?.messages)
							}
						})
						setchatMsgLoading(false)
					}
					locData.push(_.values(x?.messages))
				})
				setSnapData((x) => {
					if (JSON.stringify(x) !== JSON.stringify(locData)) {
						if (x?.length !== locData?.length && x?.length !== 0) {
							fetchAppointmentList(true, 1)
						} else {
							_.map(locData, (val, xInd) => {
								if (x[xInd]?.length !== locData[xInd]?.length && x.length !== 0) {
									let currentPage = Math.trunc(xInd / 8 + 1)
									fetchAppointmentList(true, currentPage)
								} else if (x.length === 0 && iterate) {
									iterate = false
									return fetchAppointmentList(true, 1)
								}
							})
						}
					}
					return locData
				})
			})
	}

	const fetchUpdateMethod = (isUpdate, respData, respArr) => {
		// const prevChatData = secureLocalStorage.getItem('activeChat')
		setAppointList((temp) => {
			let tempAppArr = _.cloneDeep(temp)
			let tempData = _.cloneDeep(respData)
			// if (_.isArray(respArr) && respArr.length >= 1 && tempAppArr.some((x) => x.appointmentUuid === '')) {
			// 	_.remove(tempAppArr, (x) => x.appointmentUuid === '')
			// 	// activeCustDetailsRef.current = respData[0]
			// 	setActiveCustDetails(respData[0])
			// } else {
			// 	// activeCustDetailsRef.current = activeCustDetailsRef.current || respData[0]
			// 	setActiveCustDetails(activeCustDetails || respData[0])
			// }
			if (isUpdate && tempAppArr.length > 0) {
				tempData.map((x, appIndex) => {
					let index = _.findIndex(
						tempAppArr,
						(z) => {
							return z.custUuid === x.custUuid || z.appointmentUuid === x?.appointmentUuid
						},
						0
					)
					if (index !== -1) {
						tempAppArr.splice(index, 1, x)
					} else {
						tempAppArr.push(x)
					}
					if (x.appointmentUuid === activeCustDetails?.appointmentUuid) {
						// activeCustDetailsRef.current = x
						setActiveCustDetails(x)
					}
				})
				let orderedData = _.orderBy(_.uniqWith(tempAppArr, _.isEqual), ['expiryDate'], ['desc'])
				return orderedData
			} else {
				// let uniq = _.uniq(tempAppArr.concat(tempData))
				let orderedData = _.orderBy(_.uniqWith(tempData, _.isEqual), ['expiryDate'], ['desc'])
				return orderedData
			}
		})
		// if (isUpdate) {
		// setLoading(false)
		// handleActiveChatDetails(prevChatData)
		// setIndexVal(prevChatData?.customerResponseVO?.custName)
		// } else {
		// handleTenantMediaDetails(activeCustDetails || respData[0])
		setLoading(false)
		// handleTenantMediaDetails(activeCustDetails || respData[0])
		// }
	}

	const fetchAppointmentList = (isUpdate = false, page = pageNum, datao, searchParams) => {
		const body = { tentUserUuid, pageSize: 8, pageNum: page }
		let tempData = []
		!_.isEmpty(searchParams?.searchKey)
			? consultApi
					.getTenantAppointmentListSearch(body, { ...searchParams }, cancelExecutor)
					.then((resp) => {
						let data =
							_.isArray(resp?.data?.data?.tenantAppointmentList) && !_.isEmpty(resp?.data?.data?.tenantAppointmentList)
								? resp?.data?.data?.tenantAppointmentList
								: page === 1
								? tempAppoint
								: null
						totalActiveAppointment.current = resp?.data?.data?.totalActiveAppointment
						_.map(data, (x) => {
							tempData.push({ ...x, pageNum: page })
						})
						fetchUpdateMethod(isUpdate, tempData, resp?.data?.data?.tenantAppointmentList)
					})
					.catch((err) => {})
					.finally(() => {
						setpageNum(page)
					})
			: consultApi
					.getTenantAppointmentList(body)
					.then((resp) => {
						let data =
							_.isArray(resp?.data?.data?.tenantAppointmentList) && !_.isEmpty(resp?.data?.data?.tenantAppointmentList)
								? resp?.data?.data?.tenantAppointmentList
								: page === 1
								? tempAppoint
								: null
						totalActiveAppointment.current = resp?.data?.data?.totalActiveAppointment
						_.map(data, (x) => {
							tempData.push({ ...x, pageNum: page })
						})
						fetchUpdateMethod(isUpdate, tempData, resp?.data?.data?.tenantAppointmentList)
					})
					.catch((err) => {})
					.finally(() => {
						setpageNum(page)
					})
	}

	const handleActiveChatDetails = async (obj) => {
		setIsCircularLoading(true)
		setActiveCustDetails(_.isObject(obj) && !_.isEmpty(obj) ? obj : {})
		// handleTenantMediaDetails(obj)
		setchatMsgLoading(true)
		if (obj?.appointmentUuid && _.isEmpty(headerText)) {
			consultApi
				.readTenantMessage(obj?.appointmentUuid)
				.then((resp) => {
					setIsCircularLoading(false)
					setChatData(_.values(resp?.data?.data))
					// setSeenStatus(_.values(resp?.data?.data), activeCustDetails)
				})
				.catch((err) => {
					console.log(err, 'fetchappppppp')
				})
		} else {
			setChatData([])
		}
		setchatMsgLoading(false)
		setpageNum(activeCustDetails?.pageNum)
		// dispatch(hideLoader())
	}

	// ChatDetails handling chararea
	const handleActiveChatDetailsChange = async (obj) => {
		setIsCircularLoading(true)
		setActiveCustDetails(_.isObject(obj) && !_.isEmpty(obj) ? obj : {})
		// handleTenantMediaDetails(obj)
		setchatMsgLoading(true)
		if (obj?.appointmentUuid) {
			consultApi
				.readTenantMessage(obj?.appointmentUuid)
				.then((resp) => {
					setIsCircularLoading(false)
					setChatData(_.values(resp?.data?.data))
					// setSeenStatus(_.values(resp?.data?.data), activeCustDetails)
				})
				.catch((err) => {
					setIsCircularLoading(false)
					console.log(err, 'fetchappppppp')
				})
		} else {
			setChatData([])
		}
		setchatMsgLoading(false)
		setpageNum(activeCustDetails?.pageNum)
		// dispatch(hideLoader())
	}

	// const seenStatus = useCallback(
	// 	(data, appt) => {
	// 		let filteredData = _.filter(seenStatusData, (datas, i) => {
	// 			return !datas?.isSeen
	// 		})
	// 		let lastSeen = _.map(filteredData, (datas, i) => {
	// 			return datas?.chatId
	// 		})
	// 		const body = {
	// 			appointmentUuid: appt?.appointmentUuid,
	// 			chatUuids: lastSeen,
	// 		}
	// 		const onSuccess = (res) => {
	// 			if (res?.data?.status === 'success') {
	// 				setSeenStatus([])
	// 				fetchAppointmentList(false, pageNum, appointList)
	// 			}
	// 		}
	// 		const onFailure = () => {}
	// 		setTimeout(() => {
	// 			if (
	// 				data?.lastMessage?.isSeen !== null &&
	// 				!data?.lastMessage?.isSeen &&
	// 				!_.isEmpty(seenStatusData) &&
	// 				body?.appointmentUuid &&
	// 				!_.isEmpty(body?.chatUuids)
	// 			) {
	// 				consultApi.consultSeenStatus(body).then(onSuccess, onFailure)
	// 			}
	// 		}, [300])
	// 	},
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	[seenStatusData]
	// )

	// useEffect(() => {
	// 	if (seenStatusData) {
	// 		seenStatus(seenStatusData, activeCustDetails)
	// 	}
	// }, [activeCustDetails, seenStatus, seenStatusData])

	const handleTenantMediaDetails = (obj) => {
		setActiveCustDetails(obj)
		if (obj?.appointmentUuid && headerText === 'Chat Details') {
			consultApi
				.readTenantMediaDetails(obj?.appointmentUuid)
				.then((resp) => {
					if (_.values(resp?.data?.data).length > 0) {
						setMediaDetails(resp?.data?.data)
					} else {
						setMediaDetails(tempMediaDetails)
					}
				})
				.catch((err) => {
					console.log(err, 'fetchappppppp')
				})
		} else {
			setMediaDetails(tempMediaDetails)
		}
		// dispatch(hideLoader())
		setMediaLoading(false)
		setchatMsgLoading(false)
	}

	//chat Details Media access
	const handleTenantMediaChatDetails = (obj) => {
		setActiveCustDetails(obj)
		if (obj?.appointmentUuid) {
			consultApi
				.readTenantMediaDetails(obj?.appointmentUuid)
				.then((resp) => {
					if (_.values(resp?.data?.data).length > 0) {
						setMediaDetails(resp?.data?.data)
					} else {
						setMediaDetails(tempMediaDetails)
					}
				})
				.catch((err) => {
					console.log(err, 'fetchappppppp')
				})
		} else {
			setMediaDetails(tempMediaDetails)
		}
		// dispatch(hideLoader())
		setMediaLoading(false)
		setchatMsgLoading(false)
	}

	const clearChatAreaMessage = (appointmentUuid) => {
		setIsCircularLoading(true)
		consultApi
			.clearChatHistoryFromTenantSide(appointmentUuid)
			.then((resp) => {
				if (resp?.data?.status === 'success') {
					setChatData([])
					fetchAppointmentList(true, pageNum)
					setIsCircularLoading(false)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const ActiveChatsComponent = () => (
		<ActiveChats
			totalActiveAppointment={totalActiveAppointment}
			// data={appointList}
			data={_.cloneDeep(_.orderBy(appointList, (item) => item?.lastMessage?.currentDateTime, ['desc']))}
			loading={loading}
			activeCustDetails={activeCustDetails}
			setActiveCustDetails={setActiveCustDetails}
			handleActiveChatDetails={handleActiveChatDetails}
			fetchAppointmentList={fetchAppointmentList}
			pageNum={pageNum}
			setpageNum={setpageNum}
			// indexVal={indexVal}
			// setIndexVal={setIndexVal}
			searchVal={searchVal}
			setSearchVal={setSearchVal}
			tenantSearcher={tenantSearcher}
			// seenStatus={seenStatus}
			// setActive={setActive}
			chatData={chatData}
			isMobileDevice={isMobileDevice}
			isTabletDevice={isTabletDevice}
			isLargeDevice={isMobileDevice}
			setCurrentChatPatient={setCurrentChatPatient}
			handleTenantMediaDetails={handleTenantMediaDetails}
			setChatMessage={setChatMessage}
		/>
	)

	const ChatAreaComponent = () => (
		<ChatArea
			loading={isChatMsgloading}
			setHeaderText={setHeaderText}
			setChatData={setChatData}
			handleChange={handleChange}
			setChatMessage={setChatMessage}
			// onInputBlur={onInputBlur}
			chatMessage={chatMessage}
			clearChatAreaMessage={clearChatAreaMessage}
			fetchAppointmentList={fetchAppointmentList}
			activeCustDetails={activeCustDetails}
			data={chatData}
			handleActiveChatDetails={handleActiveChatDetails}
			setIsCircularLoading={setIsCircularLoading}
			isCircularLoading={isCircularLoading}
			tenantDetail={tenantDetail}
			// indexVal={indexVal}
			// setIndexVal={setIndexVal}
			isMobileDevice={isMobileDevice}
			isTabletDevice={isTabletDevice}
			isLargeDevice={isMobileDevice}
			setCurrentChatPatient={setCurrentChatPatient}
			setCurrentPatientMediaOpen={setCurrentPatientMediaOpen}
			handleTenantMediaChatDetails={handleTenantMediaChatDetails}
		/>
	)

	const ChatDetailsComponent = () => (
		<ChatDetails
			isMediaLoading={isMediaLoading}
			activeCustDetails={activeCustDetails}
			setHeaderText={setHeaderText}
			headerText={headerText}
			mediaDetails={mediaDetails}
			isMobileDevice={isMobileDevice}
			isTabletDevice={isTabletDevice}
			isLargeDevice={isMobileDevice}
			setCurrentPatientMediaOpen={setCurrentPatientMediaOpen}
			handleTenantMediaChatDetails={handleTenantMediaChatDetails}
			handleActiveChatDetailsChange={handleActiveChatDetailsChange}
		/>
	)

	return (
		<>
			<Grid
				constainer
				className={classes.headerSection}
				alignItems='center'
				style={{ display: isMobileDevice && currentChatPatient !== null ? 'none' : '' }}
			>
				<Grid container className={classes.header}>
					<Grid item>
						<Typography variant='h6'>Online consult</Typography>
					</Grid>
					{/* <PracticeDropdown /> */}
				</Grid>
			</Grid>

			{isMobileDevice && (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{currentChatPatient === null ? (
							<ActiveChatsComponent />
						) : currentPatientMediaOpen === false ? (
							<Fade in={currentChatPatient !== null}>
								<div style={{ width: '100%', height: '93vh' }}>
									<ChatAreaComponent />
								</div>
							</Fade>
						) : (
							<Fade in={currentPatientMediaOpen}>
								<div style={{ width: '100%', height: '93vh', background: '#EBDEF0' }}>
									<ChatDetailsComponent />
								</div>
							</Fade>
						)}
					</Grid>
				</Grid>
			)}

			{isTabletDevice && (
				<Grid container spacing={2} className={classes.root}>
					<Grid item xs={4}>
						<ActiveChatsComponent />
					</Grid>
					<Grid item xs={8}>
						{currentPatientMediaOpen === false ? <ChatAreaComponent /> : <ChatDetailsComponent />}
					</Grid>
				</Grid>
			)}

			{/* <Grid container spacing={2} className={'px-12 py-12'}>
				 	<Grid item xs={2.8}>
				 		<ActiveChatsComponent />
				 	</Grid>
				 	<Grid item xs={6.4}>
				 		<ChatAreaComponent />
				 	</Grid>
				 	<Grid item xs={2.8}>
				 		<ChatDetailsComponent />
				 	</Grid>
				 </Grid> */}
			{isLargeDevice && (
				<Grid container spacing={2} className={classes.root}>
					<Grid item xs={3}>
						<ActiveChatsComponent />
					</Grid>
					<Grid item xs={9}>
						{currentPatientMediaOpen === false ? <ChatAreaComponent /> : <ChatDetailsComponent />}
					</Grid>
				</Grid>
			)}
		</>
	)
}
export default OnlineConsult
