import RegistrationViewandEdit from './RegistrationViewandEdit'

function ProfileSetupViewandEdit({ history, match }) {
	return (
		<div>
			<RegistrationViewandEdit match={match} history={history} />
		</div>
	)
}
export default ProfileSetupViewandEdit
