import { ACTION_TYPES } from '../../../constants'

export const getErrData = () => ({
	type: ACTION_TYPES.ERROR_MESSAGES_START,
})

export const setErrorData = (payload) => ({
	type: ACTION_TYPES.ERROR_MESSAGES_SUCCESS,
	payload,
})
