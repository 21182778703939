import { Avatar, Button, Typography } from '@mui/material'
import _ from 'lodash'
import EventIcon from '@mui/icons-material/Event'
import { makeStyles } from '@mui/styles'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import HomeIcon from '@mui/icons-material/Home'
import VideocamIcon from '@mui/icons-material/Videocam'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import React from 'react'
import themesConfig from 'app/fuse-configs/themesConfig'

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	cardContainer: {
		width: '100%',
		padding: 12,
		display: 'flex',
		maxWidth: 1240,
		marginBlock: 12,
		boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
		borderRadius: '20px 20px 0px 20px',
		[theme.breakpoints.down('xs')]: {
			display: 'block',
		},
	},
	userDetails: {
		display: 'flex',
		gap: 18,
	},
	userDetailsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'& .MuiTypography-h6': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontSize: 16,
		},
		'& .MuiTypography-subtitle1:nth-child(2)': {
			color: '#727272',
			fontWeight: 500,
			fontSize: 14,
		},
		'& .MuiTypography-subtitle1:nth-child(3)': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 500,
			fontSize: 14,
		},
		'& .MuiTypography-subtitle1:nth-child(4)': {
			color: (props) => props?.colorTheme,
			fontWeight: 500,
			fontSize: 14,
		},
	},
	descriptionWrapper: {
		'& .MuiTypography-body1': {
			color: themesConfig.lyfngo.palette.dark.main,
			fontWeight: 500,
			fontSize: 16,
		},
		'& .MuiTypography-subtitle1': {
			color: '#727272',
			fontSize: 14,
		},
	},
	imgContainer: {
		'& .MuiAvatar-root': {
			width: 112,
			height: 112,
		},
	},
	rightSec: {
		width: '50%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	leftSec: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%',
		gap: 18,
		alignItems: 'flex-end',
		justifyContent: 'center',
		'& .MuiSvgIcon-root': {
			color: '#72C63F',
		},
		'& .MuiTypography-body1': {
			color: '#727272',
			fontWeight: 500,
			fontSize: 14,
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			display: 'flex',
			alignItems: 'flex-start',
		},
	},
	isAvail: {
		color: '#72C63F',
		fontWeight: 500,
		fontSize: 14,
	},
	isNotAvail: {
		color: themesConfig.lyfngo.palette.dark.main,
		fontWeight: 500,
		fontSize: 14,
	},
	availContainer: {
		display: 'flex',
	},
	bookBtn: {
		border: (props) => `1px solid ${props?.colorTheme}`,
		'& .MuiButton-text': {
			color: (props) => props?.colorTheme,
		},
		textTransform: 'capitalize',
		fontFamily: 'poppins',
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		padding: '8px',
		borderRadius: '20px 20px 0px 20px',
		'&.MuiSvgIcon-root': {
			color: (props) => props?.colorTheme,
			display: 'flex',
			alignItems: 'center',
		},
		'&:hover': {
			background: (props) => props?.colorTheme,
			transition: '.5s ease',
			'& .MuiSvgIcon-root': {
				color: '#fff',
				transition: '.5s ease',
			},
			'& .MuiButton-text': {
				color: '#FFF',
			},
		},
		'& .MuiSvgIcon-root': {
			color: (props) => props?.colorTheme,
			marginInlineEnd: 6,
		},
	},
	appointmentModeContainer: {
		display: 'flex',
		gap: 18,
		'& .MuiTypography-subtitle1': {
			display: 'flex',
			gap: 6,
			fontSize: 14,
			fontWeight: 500,
		},
	},
}))

const CustomTentCard = ({ profileTentList, colorTheme }) => {
	const classes = useStyles({ colorTheme: colorTheme })

	return (
		<>
			{!_.isEqual(profileTentList, []) ? (
				<div className={classes.cardRoot}>
					{profileTentList?.map((item, index) => (
						<div key={item?.tentUserDTO?.tentUserUuid} className={classes.cardContainer}>
							<div className={classes.rightSec}>
								<div className={classes.userDetails}>
									<div className={classes.imgContainer}>
										<Avatar src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/avatar.svg`} />
									</div>
									<div className={classes.userDetailsWrapper}>
										<Typography variant='h6'>
											{item?.tentUserDTO?.tentUserSalutation} {item?.tentUserDTO?.tentUserName}
										</Typography>
										<Typography variant='subtitle1'>
											{item?.tentUserDTO?.tentUserEducation?.map(
												(data2, index) => `${data2}${index === item?.tentUserDTO?.tentUserEducation?.length - 1 ? '' : ', '}`
											)}
										</Typography>
										{item?.tentUserDTO?.specialityListDTO?.length > 3 ? (
											<>
												<Typography variant='subtitle1'>
													{`${(item?.tentUserDTO?.specialityListDTO || [])[0]?.specialityName}`},{' '}
													{`${(item?.tentUserDTO?.specialityListDTO || [])[1]?.specialityName}`},{' '}
													{`${(item?.tentUserDTO?.specialityListDTO || [])[2]?.specialityName}`},{' '}
													<span style={{ color: colorTheme }}> + {(item?.tentUserDTO?.specialityListDTO || [])?.length - 3} more</span>
												</Typography>
											</>
										) : (
											<Typography variant='subtitle2' className={classes.dropDownTxt}>
												{(item?.tentUserDTO?.specialityListDTO || [])?.map((i, idx) => (
													<>
														{i?.specialityName || ''}
														{idx + 1 === item?.tentUserDTO?.specialityListDTO?.length ? '' : ', '}
													</>
												))}
											</Typography>
										)}
										{item?.tentUserDTO?.tentUserExperience && (
											<Typography variant='subtitle1'>{`${item?.tentUserDTO?.tentUserExperience} Years of Experience`}</Typography>
										)}
									</div>
								</div>
								<div className={classes.descriptionWrapper}>
									<Typography variant='body1'>Description</Typography>
									<Typography variant='subtitle1'>
										{item?.tentUserDTO?.aboutUs?.length > 150 ? `${item?.tentUserDTO?.aboutUs?.substring(0, 150)}...` : item?.tentUserDTO?.aboutUs}
										{item?.tentUserDTO?.aboutUs?.length > 150 && <span style={{ color: colorTheme }}>View More</span>}
									</Typography>
								</div>
							</div>
							<div className={classes.leftSec}>
								<div className={classes.availContainer}>
									<FiberManualRecordIcon />
									<Typography variant='body1'>
										Available at <span className={item?.timeSlots?.includes('Sun') ? classes.isAvail : classes.isNotAvail}>Sun</span>,{' '}
										<span className={item?.timeSlots?.includes('Mon') ? classes.isAvail : classes.isNotAvail}>Mon</span>,{' '}
										<span className={item?.timeSlots?.includes('Tue') ? classes.isAvail : classes.isNotAvail}>Tue</span>,{' '}
										<span className={item?.timeSlots?.includes('Wed') ? classes.isAvail : classes.isNotAvail}>Wed</span>,{' '}
										<span className={item?.timeSlots?.includes('Thu') ? classes.isAvail : classes.isNotAvail}>Thu</span>,{' '}
										<span className={item?.timeSlots?.includes('Fri') ? classes.isAvail : classes.isNotAvail}>Fri</span>,{' '}
										<span className={item?.timeSlots?.includes('Sat') ? classes.isAvail : classes.isNotAvail}>Sat</span>
									</Typography>
								</div>
								<div className={classes.appointmentModeContainer}>
									{item?.appointmentMode?.find((data) => data === 'In-person') && (
										<Typography variant='subtitle1'>
											<DomainAddIcon style={{ color: '#008DDF' }} />
											In-person
										</Typography>
									)}
									{item?.appointmentMode?.find((data) => data === 'Online') && (
										<Typography variant='subtitle1'>
											<VideocamIcon style={{ color: '#00A010' }} />
											online
										</Typography>
									)}
									{item?.appointmentMode?.find((data) => data === 'Home') && (
										<Typography variant='subtitle1'>
											<HomeIcon style={{ color: '#0066E5' }} />
											Home Service
										</Typography>
									)}
								</div>
								<div className={classes.bookBtn}>
									<Button>
										<EventIcon />
										Book Appointment Now
									</Button>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<img alt='no docters' src={`${process.env.REACT_APP_IMAGEKIT_URL}/Microsite/No_doctors_found.svg`} width={300} height={300} />
				</div>
			)}
		</>
	)
}

export default CustomTentCard
