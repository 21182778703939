import useStyles from './style'
import { React, useState, useEffect, useCallback } from 'react'
import { Grid, Typography, Avatar, Icon, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import GetProfile from '../../../../../services/ProfileSetup/profileDetails/api'
import { hideLoader, showLoader } from 'services/loader/action'
import _ from 'lodash'
import { getImgUrl } from 'utils'

function ProfileDetailsView({ moveBackTab, moveNextTab, match, history }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [profileDetails, setProfileDetails] = useState()
	const uuid = _.get(match, 'params.uuid', '')
	const mastuuid = _.get(match, 'params.mastuuid')
	const bearerToken = useSelector((state) => state?.Authentication?.bearerToken || '')
	const getProfileDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))
		const onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data.status === 'success') {
				setProfileDetails(res?.data.data)
			} else {
				setProfileDetails([])
			}
		}
		const onFailure = (err) => {
			dispatch(hideLoader())
		}
		GetProfile.getPracticeDetails(uuid).then(onSuccess, onFailure)
	}, [dispatch, uuid])
	useEffect(() => {
		getProfileDetails()
	}, [getProfileDetails])

	const onEditClick = () => {
		history.push(`/profilesetup/edit/${uuid}/${mastuuid}?tabId=profileDetails`)
	}

	return (
		<Grid container className={classes.establishmentviewMain} justifyContent={'space-between'}>
			<Grid item lg={6} sm={12}>
				<Grid container className={classes.datapart} spacing={2}>
					<Grid item lg={3}>
						<Avatar src={getImgUrl(profileDetails?.tentUserProfilePic, bearerToken) || ''} variant='rounded'></Avatar>
					</Grid>
					<Grid item lg={9} className={classes.establishmentDetails}>
						<Typography className={classes.establishmentName} variant='h6'>
							{profileDetails?.tentUserFirstName || ''}
						</Typography>
						<div className={classes.addressDetails}>
							<Typography>{`${profileDetails?.address}` || ''}</Typography>
							<Typography>{`, ${profileDetails?.city}` || ''}</Typography>
							<Typography>{`, ${profileDetails?.pincode}` || ''}</Typography>
						</div>
						<div className={classes.contactDetails}>
							<Typography>
								{profileDetails?.tentUserCountryCode || ''} {profileDetails?.tentUserPhone || ''}
							</Typography>
						</div>
						<div className={classes.contactDetails}>
							<Typography>{profileDetails?.tentUserEmail || ''}</Typography>
						</div>
						<div className={classes.edit}>
							<Button className={classes.editBtn} onClick={onEditClick}>
								<Icon color='error' className={classes.icon}>
									edit
								</Icon>
								<Typography>&nbsp; Edit</Typography>
							</Button>
							{/* <IconButton size='small' className={classes.editIcon}></IconButton> */}
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
export default ProfileDetailsView
