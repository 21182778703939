import React from 'react'
import { Grid, Modal, IconButton, Avatar, Typography } from '@mui/material'
import useStyles from './style'
import { getImageDownload, getImgUrl } from 'utils'
import { useSelector } from 'react-redux'
import DownloadIcon from '@mui/icons-material/Download'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DeleteIcon from '@mui/icons-material/Delete'
import _ from 'lodash'
import fileApi from 'services/fileupload/GetApi'
import CloseIcon from '@mui/icons-material/Close'

const ViewImage = (props) => {
	const { viewOpen, setViewOpen, fileSpecificData, imgResponse, isMulti, setTriggerGetApi, setImage } = props
	const classes = useStyles()

	const handleClose = () => {
		setViewOpen(false)
	}
	const token = useSelector((state) => state?.Authentication?.BearerToken)

	const deleteImage = (fileSpecificData) => {
		const onSuccess = (res) => {
			setTriggerGetApi('trigger')
			handleClose()
			if (isMulti) {
				const imgs = _.filter(imgResponse, (img) => !_.isEqual(img?.uuid, fileSpecificData?.uuid))
				setImage(imgs)
			} else {
				setImage()
			}
		}
		const onFailure = (err) => {}

		fileApi
			.deleteFile(fileSpecificData.uuid || fileSpecificData)
			.then(onSuccess)
			.catch(onFailure)
	}
	const downloadFile = (uuid) => {
		window.open(getImageDownload(uuid, token), '_blank')
	}
	return (
		<>
			<Modal open={viewOpen} onClose={handleClose} aria-labelledby='child-modal-title' aria-describedby='child-modal-description'>
				<Grid container className={classes.root}>
					<Grid container xs={12} alignItems='center'>
						<Grid item xs={3}></Grid>
						<Grid item xs={6} justifyContent='center' textAlign='center' gap={2} style={{ display: 'flex' }}>
							<IconButton className={classes.downloadIcon}>
								<DownloadIcon onClick={() => downloadFile(fileSpecificData?.uuid)} />
							</IconButton>

							<IconButton className={classes.deleteIcon} onClick={() => deleteImage(fileSpecificData)}>
								<DeleteIcon />
							</IconButton>
						</Grid>
						<Grid item xs={3} justifyContent='end' px={1} align='end'>
							<IconButton className={classes.closeIcon} onClick={() => handleClose()}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Grid item justifyContent='center' alignItems='center' width='100%'>
						{fileSpecificData?.documentExtension === 'jpg' ||
						fileSpecificData?.documentExtension === 'jpeg' ||
						fileSpecificData?.documentExtension === 'jpe' ||
						fileSpecificData?.documentExtension === 'png' ? (
							<Avatar src={fileSpecificData?.filePath || getImgUrl(fileSpecificData?.uuid, token)} className={classes.mainImg} />
						) : fileSpecificData?.documentExtension === 'pdf' ? (
							<div className={classes.pdfName}>
								<PictureAsPdfIcon color='red' />
								<Typography color='red'>{fileSpecificData?.documentName}</Typography>
							</div>
						) : null}{' '}
					</Grid>
				</Grid>
			</Modal>
		</>
	)
}

export default ViewImage
